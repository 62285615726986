import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {COIService} from 'src/app/shared/services/coi.service';
import {Account} from 'src/app/shared';
import * as moment from 'moment';
import {map} from 'rxjs/operators';

@Injectable()
export class EnergyGraphService extends COIService {
  getEnergyGraph(account_id, meter_id: number, resolutionUnit: string, resolution: number, data): Observable<any> {
    return of(dummyData);
    // return this.httpPost(`/energy_graph/meter_energy/${account_id}/${meter_id}/${resolutionUnit}/${resolution}`, data);
  }

  exportCSVEnergyGraph(meter_id: number, data): Observable<any> {
    return this.http.post(`${this.apiUrl}/energy_graph/meter_energy/download_csv/${meter_id}`, data,
      {
        responseType: 'blob', headers: this.getBasicHeaders()
      })
      .pipe(map((res) => {
        return new Blob([res], { type: 'text/plain' })
      }));
  }

  getPerformanceGraph(event_id: number, data): Observable<any> {
    return this.httpPost(`/reports/event_performance_graph/${event_id}`, data);
  }

  getWeather(account: Account, start?: Date, end?: Date): Observable<any> {
    return this.httpGet(`/energy_graph/weather/${account.service_address.latitude}/${account.service_address.longitude}`).pipe(map(r => {
      const data = r;
      const weathers = [];
      data.daily.data.forEach(e => {
        const weather = {tempHigh: 0, tempLow: 0, date: null, summary: '', icon: ''};
        weather.tempHigh = e.temperatureHigh;
        weather.tempLow = e.temperatureMin;
        weather.date = new Date(e.time * 1000);
        weather.summary = e.summary;
        weather.icon = e.icon;
        weathers.push(weather);
      });
      return weathers;
    }));
  }
}



const dummyData = {
  "type": "performanceDataPointListDto",
  "message": "Ok",
  "list": [
      {
          "time_stamp_utc_ms": 1733806470000,
          "time": "2024-12-10T04:58:00Z",
          "energy": 16.56000000052154,
          "capacity": 397.44000001251703
      },
      {
          "time_stamp_utc_ms": 1733806830000,
          "time": "2024-12-10T05:03:00Z",
          "energy": 39.72000000067055,
          "capacity": 397.2000000067055
      },
      {
          "time_stamp_utc_ms": 1733807160000,
          "time": "2024-12-10T05:08:00Z",
          "energy": 36.99999999906868,
          "capacity": 403.6363636262038
      },
      {
          "time_stamp_utc_ms": 1733807400000,
          "time": "2024-12-10T05:13:00Z",
          "energy": 26.71999999973923,
          "capacity": 400.79999999608845
      },
      {
          "time_stamp_utc_ms": 1733807760000,
          "time": "2024-12-10T05:18:00Z",
          "energy": 40.56000000052154,
          "capacity": 405.6000000052154
      },
      {
          "time_stamp_utc_ms": 1733808060000,
          "time": "2024-12-10T05:23:00Z",
          "energy": 34.16000000014901,
          "capacity": 409.92000000178814
      },
      {
          "time_stamp_utc_ms": 1733808360000,
          "time": "2024-12-10T05:28:00Z",
          "energy": 34.24000000022352,
          "capacity": 410.8800000026822
      },
      {
          "time_stamp_utc_ms": 1733808750000,
          "time": "2024-12-10T05:33:00Z",
          "energy": 46.55999999959022,
          "capacity": 429.7846153808328
      },
      {
          "time_stamp_utc_ms": 1733808975000,
          "time": "2024-12-10T05:38:00Z",
          "energy": 24.480000000447035,
          "capacity": 391.68000000715256
      },
      {
          "time_stamp_utc_ms": 1733809300000,
          "time": "2024-12-10T05:43:00Z",
          "energy": 37.75999999977648,
          "capacity": 418.26462765562354
      },
      {
          "time_stamp_utc_ms": 1733809520000,
          "time": "2024-12-10T05:48:00Z",
          "energy": 24.320000000298023,
          "capacity": 397.9636277428838
      },
      {
          "time_stamp_utc_ms": 1733809860000,
          "time": "2024-12-10T05:53:00Z",
          "energy": 36.959999999031425,
          "capacity": 391.34118743715743
      },
      {
          "time_stamp_utc_ms": 1733810280000,
          "time": "2024-12-10T05:58:00Z",
          "energy": 48.32000000029802,
          "capacity": 414.171428573983
      },
      {
          "time_stamp_utc_ms": 1733810490000,
          "time": "2024-12-10T06:03:00Z",
          "energy": 22.559999999590218,
          "capacity": 386.7428571358323
      },
      {
          "time_stamp_utc_ms": 1733810760000,
          "time": "2024-12-10T06:08:00Z",
          "energy": 31.6800000006333,
          "capacity": 422.40000000844395
      },
      {
          "time_stamp_utc_ms": 1733811075000,
          "time": "2024-12-10T06:13:00Z",
          "energy": 33.84000000078231,
          "capacity": 386.7428571517978
      },
      {
          "time_stamp_utc_ms": 1733811360000,
          "time": "2024-12-10T06:18:00Z",
          "energy": 31.59999999869615,
          "capacity": 399.1578947203724
      },
      {
          "time_stamp_utc_ms": 1733811750000,
          "time": "2024-12-10T06:23:00Z",
          "energy": 43.52000000048429,
          "capacity": 401.7230769275473
      },
      {
          "time_stamp_utc_ms": 1733811900000,
          "time": "2024-12-10T06:28:00Z",
          "energy": 16.320000000298023,
          "capacity": 391.6800000071526
      },
      {
          "time_stamp_utc_ms": 1733812230000,
          "time": "2024-12-10T06:33:00Z",
          "energy": 37.919999999925494,
          "capacity": 413.6727272719145
      },
      {
          "time_stamp_utc_ms": 1733812890000,
          "time": "2024-12-10T06:43:00Z",
          "energy": 74.87999999895692,
          "capacity": 408.4363636306741
      },
      {
          "time_stamp_utc_ms": 1733813100000,
          "time": "2024-12-10T06:48:00Z",
          "energy": 24.000000000931323,
          "capacity": 411.42857144453694
      },
      {
          "time_stamp_utc_ms": 1733813580000,
          "time": "2024-12-10T06:53:00Z",
          "energy": 52.799999999813735,
          "capacity": 395.999999998603
      },
      {
          "time_stamp_utc_ms": 1733813780000,
          "time": "2024-12-10T06:58:00Z",
          "energy": 21.760000000707805,
          "capacity": 391.68000935111957
      },
      {
          "time_stamp_utc_ms": 1733814360000,
          "time": "2024-12-10T07:08:00Z",
          "energy": 63.43999999947846,
          "capacity": 393.76550428903704
      },
      {
          "time_stamp_utc_ms": 1733814620000,
          "time": "2024-12-10T07:13:00Z",
          "energy": 29.040000000968575,
          "capacity": 402.0922929570614
      },
      {
          "time_stamp_utc_ms": 1733814940000,
          "time": "2024-12-10T07:18:00Z",
          "energy": 34.71999999880791,
          "capacity": 390.59998834580216
      },
      {
          "time_stamp_utc_ms": 1733815280000,
          "time": "2024-12-10T07:23:00Z",
          "energy": 38.56000000052154,
          "capacity": 408.28236439871057
      },
      {
          "time_stamp_utc_ms": 1733815580000,
          "time": "2024-12-10T07:28:00Z",
          "energy": 32.3199999993667,
          "capacity": 387.83999999240046
      },
      {
          "time_stamp_utc_ms": 1733816145000,
          "time": "2024-12-10T07:38:00Z",
          "energy": 63.360000000335276,
          "capacity": 403.7097208868232
      },
      {
          "time_stamp_utc_ms": 1733816460000,
          "time": "2024-12-10T07:43:00Z",
          "energy": 35.04000000003725,
          "capacity": 400.4571428575686
      },
      {
          "time_stamp_utc_ms": 1733816730000,
          "time": "2024-12-10T07:48:00Z",
          "energy": 30.59999999962747,
          "capacity": 407.9999999950329
      },
      {
          "time_stamp_utc_ms": 1733817060000,
          "time": "2024-12-10T07:53:00Z",
          "energy": 35.75999999977648,
          "capacity": 390.10909090665257
      },
      {
          "time_stamp_utc_ms": 1733817320000,
          "time": "2024-12-10T07:58:00Z",
          "energy": 29.800000000745058,
          "capacity": 412.61536949105925
      },
      {
          "time_stamp_utc_ms": 1733817660000,
          "time": "2024-12-10T08:03:00Z",
          "energy": 36.799999999813735,
          "capacity": 389.6470697508632
      },
      {
          "time_stamp_utc_ms": 1733818260000,
          "time": "2024-12-10T08:13:00Z",
          "energy": 69.43999999947846,
          "capacity": 416.63999999687076
      },
      {
          "time_stamp_utc_ms": 1733818590000,
          "time": "2024-12-10T08:18:00Z",
          "energy": 35.799999999813735,
          "capacity": 390.5454545434226
      },
      {
          "time_stamp_utc_ms": 1733818860000,
          "time": "2024-12-10T08:23:00Z",
          "energy": 30.888000001199543,
          "capacity": 411.84000001599384
      },
      {
          "time_stamp_utc_ms": 1733819190000,
          "time": "2024-12-10T08:28:00Z",
          "energy": 35.83199999947101,
          "capacity": 390.8945454487747
      },
      {
          "time_stamp_utc_ms": 1733819550000,
          "time": "2024-12-10T08:33:00Z",
          "energy": 40.919999999925494,
          "capacity": 409.1999999992549
      },
      {
          "time_stamp_utc_ms": 1733819760000,
          "time": "2024-12-10T08:38:00Z",
          "energy": 23.40000000037253,
          "capacity": 401.14285714924335
      },
      {
          "time_stamp_utc_ms": 1733820060000,
          "time": "2024-12-10T08:43:00Z",
          "energy": 32.519999999552965,
          "capacity": 390.23999999463564
      },
      {
          "time_stamp_utc_ms": 1733820360000,
          "time": "2024-12-10T08:48:00Z",
          "energy": 33.92000000085682,
          "capacity": 407.04000001028186
      },
      {
          "time_stamp_utc_ms": 1733820660000,
          "time": "2024-12-10T08:53:00Z",
          "energy": 32.3199999993667,
          "capacity": 387.83999999240046
      },
      {
          "time_stamp_utc_ms": 1733820930000,
          "time": "2024-12-10T08:58:00Z",
          "energy": 29.759999999776483,
          "capacity": 396.79999999701977
      },
      {
          "time_stamp_utc_ms": 1733821275000,
          "time": "2024-12-10T09:03:00Z",
          "energy": 38.52000000048429,
          "capacity": 401.94782609200996
      },
      {
          "time_stamp_utc_ms": 1733821530000,
          "time": "2024-12-10T09:08:00Z",
          "energy": 26.999999999068677,
          "capacity": 381.17647057508725
      },
      {
          "time_stamp_utc_ms": 1733821830000,
          "time": "2024-12-10T09:13:00Z",
          "energy": 34.80000000074506,
          "capacity": 417.60000000894075
      },
      {
          "time_stamp_utc_ms": 1733822175000,
          "time": "2024-12-10T09:18:00Z",
          "energy": 36.95999999996275,
          "capacity": 385.66956521700257
      },
      {
          "time_stamp_utc_ms": 1733822400000,
          "time": "2024-12-10T09:23:00Z",
          "energy": 25.59999999962747,
          "capacity": 409.59999999403954
      },
      {
          "time_stamp_utc_ms": 1733822730000,
          "time": "2024-12-10T09:28:00Z",
          "energy": 39.200000000186265,
          "capacity": 427.63636363839566
      },
      {
          "time_stamp_utc_ms": 1733823030000,
          "time": "2024-12-10T09:33:00Z",
          "energy": 35.87999999988824,
          "capacity": 430.5599999986589
      },
      {
          "time_stamp_utc_ms": 1733823450000,
          "time": "2024-12-10T09:38:00Z",
          "energy": 54.60000000055879,
          "capacity": 468.0000000047896
      },
      {
          "time_stamp_utc_ms": 1733823620000,
          "time": "2024-12-10T09:43:00Z",
          "energy": 21.359999999403954,
          "capacity": 452.3294244395824
      },
      {
          "time_stamp_utc_ms": 1733824020000,
          "time": "2024-12-10T09:48:00Z",
          "energy": 50.40000000037253,
          "capacity": 453.6000108180197
      },
      {
          "time_stamp_utc_ms": 1733824320000,
          "time": "2024-12-10T09:53:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1733824560000,
          "time": "2024-12-10T09:58:00Z",
          "energy": 30.527999999932945,
          "capacity": 457.9199999989942
      },
      {
          "time_stamp_utc_ms": 1733824860000,
          "time": "2024-12-10T10:03:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1733825160000,
          "time": "2024-12-10T10:08:00Z",
          "energy": 37.92000000085682,
          "capacity": 455.04000001028186
      },
      {
          "time_stamp_utc_ms": 1733825460000,
          "time": "2024-12-10T10:13:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1733825760000,
          "time": "2024-12-10T10:18:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1733826060000,
          "time": "2024-12-10T10:23:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1733826360000,
          "time": "2024-12-10T10:28:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1733826660000,
          "time": "2024-12-10T10:33:00Z",
          "energy": 37.91999999899417,
          "capacity": 455.03999998793006
      },
      {
          "time_stamp_utc_ms": 1733826960000,
          "time": "2024-12-10T10:38:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1733827260000,
          "time": "2024-12-10T10:43:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1733827560000,
          "time": "2024-12-10T10:48:00Z",
          "energy": 38.87999999895692,
          "capacity": 466.5599999874831
      },
      {
          "time_stamp_utc_ms": 1733827860000,
          "time": "2024-12-10T10:53:00Z",
          "energy": 38.880000000819564,
          "capacity": 466.56000000983477
      },
      {
          "time_stamp_utc_ms": 1733828160000,
          "time": "2024-12-10T10:58:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1733828460000,
          "time": "2024-12-10T11:03:00Z",
          "energy": 42.04800000041723,
          "capacity": 504.57600000500685
      },
      {
          "time_stamp_utc_ms": 1733828760000,
          "time": "2024-12-10T11:08:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1733829060000,
          "time": "2024-12-10T11:13:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1733829360000,
          "time": "2024-12-10T11:18:00Z",
          "energy": 41.47200000099838,
          "capacity": 497.6640000119806
      },
      {
          "time_stamp_utc_ms": 1733829660000,
          "time": "2024-12-10T11:23:00Z",
          "energy": 41.567999999038875,
          "capacity": 498.81599998846656
      },
      {
          "time_stamp_utc_ms": 1733829960000,
          "time": "2024-12-10T11:28:00Z",
          "energy": 41.95200000051409,
          "capacity": 503.42400000616914
      },
      {
          "time_stamp_utc_ms": 1733830260000,
          "time": "2024-12-10T11:33:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1733830560000,
          "time": "2024-12-10T11:38:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1733830860000,
          "time": "2024-12-10T11:43:00Z",
          "energy": 41.95200000051409,
          "capacity": 503.42400000616914
      },
      {
          "time_stamp_utc_ms": 1733831140000,
          "time": "2024-12-10T11:48:00Z",
          "energy": 39.42399999964982,
          "capacity": 506.8800172597283
      },
      {
          "time_stamp_utc_ms": 1733831445000,
          "time": "2024-12-10T11:53:00Z",
          "energy": 42.67999999970198,
          "capacity": 503.7639186710164
      },
      {
          "time_stamp_utc_ms": 1733831730000,
          "time": "2024-12-10T11:58:00Z",
          "energy": 39.24000000022352,
          "capacity": 495.66315789756015
      },
      {
          "time_stamp_utc_ms": 1733832030000,
          "time": "2024-12-10T12:03:00Z",
          "energy": 42.00000000093132,
          "capacity": 504.0000000111759
      },
      {
          "time_stamp_utc_ms": 1733832360000,
          "time": "2024-12-10T12:08:00Z",
          "energy": 46.9919999986887,
          "capacity": 512.6399999856949
      },
      {
          "time_stamp_utc_ms": 1733832720000,
          "time": "2024-12-10T12:13:00Z",
          "energy": 52.60800000000745,
          "capacity": 526.0800000000744
      },
      {
          "time_stamp_utc_ms": 1733832945000,
          "time": "2024-12-10T12:18:00Z",
          "energy": 32.40000000037253,
          "capacity": 518.4000000059605
      },
      {
          "time_stamp_utc_ms": 1733833260000,
          "time": "2024-12-10T12:23:00Z",
          "energy": 43.44000000040978,
          "capacity": 496.457142861826
      },
      {
          "time_stamp_utc_ms": 1733833650000,
          "time": "2024-12-10T12:28:00Z",
          "energy": 57.59999999962747,
          "capacity": 531.6923076888689
      },
      {
          "time_stamp_utc_ms": 1733833860000,
          "time": "2024-12-10T12:33:00Z",
          "energy": 29.28000000026077,
          "capacity": 501.94285714732746
      },
      {
          "time_stamp_utc_ms": 1733834220000,
          "time": "2024-12-10T12:38:00Z",
          "energy": 51.83999999985099,
          "capacity": 518.3999999985098
      },
      {
          "time_stamp_utc_ms": 1733834430000,
          "time": "2024-12-10T12:43:00Z",
          "energy": 30.71999999973923,
          "capacity": 526.628571424101
      },
      {
          "time_stamp_utc_ms": 1733834790000,
          "time": "2024-12-10T12:48:00Z",
          "energy": 51.60000000055879,
          "capacity": 516.0000000055879
      },
      {
          "time_stamp_utc_ms": 1733835020000,
          "time": "2024-12-10T12:53:00Z",
          "energy": 33.67999999970198,
          "capacity": 527.1652283158558
      },
      {
          "time_stamp_utc_ms": 1733835380000,
          "time": "2024-12-10T12:58:00Z",
          "energy": 50.40000000037253,
          "capacity": 504.00000000372523
      },
      {
          "time_stamp_utc_ms": 1733835640000,
          "time": "2024-12-10T13:03:00Z",
          "energy": 38.07999999914318,
          "capacity": 527.2615191098375
      },
      {
          "time_stamp_utc_ms": 1733835940000,
          "time": "2024-12-10T13:08:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1733836260000,
          "time": "2024-12-10T13:13:00Z",
          "energy": 45.360000000335276,
          "capacity": 510.29998479564716
      },
      {
          "time_stamp_utc_ms": 1733836470000,
          "time": "2024-12-10T13:18:00Z",
          "energy": 30.959999999031425,
          "capacity": 530.742857126253
      },
      {
          "time_stamp_utc_ms": 1733836770000,
          "time": "2024-12-10T13:23:00Z",
          "energy": 42.480000000447035,
          "capacity": 509.7600000053645
      },
      {
          "time_stamp_utc_ms": 1733837160000,
          "time": "2024-12-10T13:28:00Z",
          "energy": 57.12000000011176,
          "capacity": 527.2615384625701
      },
      {
          "time_stamp_utc_ms": 1733837550000,
          "time": "2024-12-10T13:33:00Z",
          "energy": 55.92000000085682,
          "capacity": 516.1846153925245
      },
      {
          "time_stamp_utc_ms": 1733837720000,
          "time": "2024-12-10T13:38:00Z",
          "energy": 25.119999999180436,
          "capacity": 531.9529560799937
      },
      {
          "time_stamp_utc_ms": 1733838030000,
          "time": "2024-12-10T13:43:00Z",
          "energy": 44.72000000067055,
          "capacity": 519.3290482423282
      },
      {
          "time_stamp_utc_ms": 1733838360000,
          "time": "2024-12-10T13:48:00Z",
          "energy": 47.27999999932945,
          "capacity": 515.7818181745031
      },
      {
          "time_stamp_utc_ms": 1733838720000,
          "time": "2024-12-10T13:53:00Z",
          "energy": 51.59999999962747,
          "capacity": 515.9999999962746
      },
      {
          "time_stamp_utc_ms": 1733838960000,
          "time": "2024-12-10T13:58:00Z",
          "energy": 34.03199999965727,
          "capacity": 510.4799999948591
      },
      {
          "time_stamp_utc_ms": 1733839260000,
          "time": "2024-12-10T14:03:00Z",
          "energy": 40.99200000055134,
          "capacity": 491.9040000066161
      },
      {
          "time_stamp_utc_ms": 1733839470000,
          "time": "2024-12-10T14:08:00Z",
          "energy": 29.856000000610948,
          "capacity": 511.8171428676162
      },
      {
          "time_stamp_utc_ms": 1733840160000,
          "time": "2024-12-10T14:18:00Z",
          "energy": 94.55999999959022,
          "capacity": 493.35652173699236
      },
      {
          "time_stamp_utc_ms": 1733840445000,
          "time": "2024-12-10T14:23:00Z",
          "energy": 39.24000000022352,
          "capacity": 495.66315789756015
      },
      {
          "time_stamp_utc_ms": 1733840740000,
          "time": "2024-12-10T14:28:00Z",
          "energy": 42.04000000003725,
          "capacity": 513.0305250602524
      },
      {
          "time_stamp_utc_ms": 1733841060000,
          "time": "2024-12-10T14:33:00Z",
          "energy": 44.95999999996275,
          "capacity": 505.79998492556666
      },
      {
          "time_stamp_utc_ms": 1733841360000,
          "time": "2024-12-10T14:38:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1733841660000,
          "time": "2024-12-10T14:43:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1733841960000,
          "time": "2024-12-10T14:48:00Z",
          "energy": 39.552000001072884,
          "capacity": 474.6240000128746
      },
      {
          "time_stamp_utc_ms": 1733842260000,
          "time": "2024-12-10T14:53:00Z",
          "energy": 40.79999999888241,
          "capacity": 489.59999998658895
      },
      {
          "time_stamp_utc_ms": 1733842560000,
          "time": "2024-12-10T14:58:00Z",
          "energy": 41.088000000454485,
          "capacity": 493.0560000054538
      },
      {
          "time_stamp_utc_ms": 1733842860000,
          "time": "2024-12-10T15:03:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1733843160000,
          "time": "2024-12-10T15:08:00Z",
          "energy": 43.104000001214445,
          "capacity": 517.2480000145733
      },
      {
          "time_stamp_utc_ms": 1733843460000,
          "time": "2024-12-10T15:13:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1733843760000,
          "time": "2024-12-10T15:18:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1733844060000,
          "time": "2024-12-10T15:23:00Z",
          "energy": 42.14399999938905,
          "capacity": 505.72799999266863
      },
      {
          "time_stamp_utc_ms": 1733844360000,
          "time": "2024-12-10T15:28:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1733844660000,
          "time": "2024-12-10T15:33:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1733844960000,
          "time": "2024-12-10T15:38:00Z",
          "energy": 40.511999999172986,
          "capacity": 486.1439999900758
      },
      {
          "time_stamp_utc_ms": 1733845260000,
          "time": "2024-12-10T15:43:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1733845560000,
          "time": "2024-12-10T15:48:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1733845860000,
          "time": "2024-12-10T15:53:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1733846160000,
          "time": "2024-12-10T15:58:00Z",
          "energy": 40.8960000006482,
          "capacity": 490.7520000077784
      },
      {
          "time_stamp_utc_ms": 1733846460000,
          "time": "2024-12-10T16:03:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1733846760000,
          "time": "2024-12-10T16:08:00Z",
          "energy": 40.03199999872595,
          "capacity": 480.3839999847114
      },
      {
          "time_stamp_utc_ms": 1733847060000,
          "time": "2024-12-10T16:13:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1733847360000,
          "time": "2024-12-10T16:18:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1733847660000,
          "time": "2024-12-10T16:23:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1733847960000,
          "time": "2024-12-10T16:28:00Z",
          "energy": 41.376000001095235,
          "capacity": 496.5120000131429
      },
      {
          "time_stamp_utc_ms": 1733848260000,
          "time": "2024-12-10T16:33:00Z",
          "energy": 39.74399999901652,
          "capacity": 476.92799998819834
      },
      {
          "time_stamp_utc_ms": 1733848560000,
          "time": "2024-12-10T16:38:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1733848860000,
          "time": "2024-12-10T16:43:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1733849160000,
          "time": "2024-12-10T16:48:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1733849460000,
          "time": "2024-12-10T16:53:00Z",
          "energy": 39.071999998763204,
          "capacity": 468.8639999851585
      },
      {
          "time_stamp_utc_ms": 1733849760000,
          "time": "2024-12-10T16:58:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1733850060000,
          "time": "2024-12-10T17:03:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1733850360000,
          "time": "2024-12-10T17:08:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1733850660000,
          "time": "2024-12-10T17:13:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1733850960000,
          "time": "2024-12-10T17:18:00Z",
          "energy": 36.86399999912828,
          "capacity": 442.3679999895394
      },
      {
          "time_stamp_utc_ms": 1733851260000,
          "time": "2024-12-10T17:23:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1733851560000,
          "time": "2024-12-10T17:28:00Z",
          "energy": 42.04800000041723,
          "capacity": 504.57600000500685
      },
      {
          "time_stamp_utc_ms": 1733851860000,
          "time": "2024-12-10T17:33:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1733852160000,
          "time": "2024-12-10T17:38:00Z",
          "energy": 40.8960000006482,
          "capacity": 490.7520000077784
      },
      {
          "time_stamp_utc_ms": 1733852460000,
          "time": "2024-12-10T17:43:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1733852760000,
          "time": "2024-12-10T17:48:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1733853030000,
          "time": "2024-12-10T17:53:00Z",
          "energy": 33.59999999962747,
          "capacity": 447.99999999503297
      },
      {
          "time_stamp_utc_ms": 1733853360000,
          "time": "2024-12-10T17:58:00Z",
          "energy": 42.24000000022352,
          "capacity": 460.8000000024384
      },
      {
          "time_stamp_utc_ms": 1733853720000,
          "time": "2024-12-10T18:03:00Z",
          "energy": 48.0,
          "capacity": 480.0
      },
      {
          "time_stamp_utc_ms": 1733853930000,
          "time": "2024-12-10T18:08:00Z",
          "energy": 27.360000000335276,
          "capacity": 469.028571434319
      },
      {
          "time_stamp_utc_ms": 1733854200000,
          "time": "2024-12-10T18:13:00Z",
          "energy": 35.519999999552965,
          "capacity": 473.5999999940395
      },
      {
          "time_stamp_utc_ms": 1733854620000,
          "time": "2024-12-10T18:18:00Z",
          "energy": 57.75999999977648,
          "capacity": 495.0857142837983
      },
      {
          "time_stamp_utc_ms": 1733854860000,
          "time": "2024-12-10T18:23:00Z",
          "energy": 31.040000000968575,
          "capacity": 465.60000001452863
      },
      {
          "time_stamp_utc_ms": 1733855160000,
          "time": "2024-12-10T18:28:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1733855460000,
          "time": "2024-12-10T18:33:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1733855760000,
          "time": "2024-12-10T18:38:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1733856060000,
          "time": "2024-12-10T18:43:00Z",
          "energy": 40.03199999872595,
          "capacity": 480.3839999847114
      },
      {
          "time_stamp_utc_ms": 1733856360000,
          "time": "2024-12-10T18:48:00Z",
          "energy": 39.84000000078231,
          "capacity": 478.08000000938773
      },
      {
          "time_stamp_utc_ms": 1733856660000,
          "time": "2024-12-10T18:53:00Z",
          "energy": 40.3199999993667,
          "capacity": 483.8399999924004
      },
      {
          "time_stamp_utc_ms": 1733856930000,
          "time": "2024-12-10T18:58:00Z",
          "energy": 33.360000000335276,
          "capacity": 444.80000000447035
      },
      {
          "time_stamp_utc_ms": 1733857200000,
          "time": "2024-12-10T19:03:00Z",
          "energy": 35.28000000026077,
          "capacity": 470.40000000347686
      },
      {
          "time_stamp_utc_ms": 1733857590000,
          "time": "2024-12-10T19:08:00Z",
          "energy": 51.12000000011176,
          "capacity": 471.87692307795476
      },
      {
          "time_stamp_utc_ms": 1733857800000,
          "time": "2024-12-10T19:13:00Z",
          "energy": 27.119999999180436,
          "capacity": 464.914285700236
      },
      {
          "time_stamp_utc_ms": 1733858190000,
          "time": "2024-12-10T19:18:00Z",
          "energy": 49.67999999970198,
          "capacity": 458.5846153818644
      },
      {
          "time_stamp_utc_ms": 1733858475000,
          "time": "2024-12-10T19:23:00Z",
          "energy": 37.08000000100583,
          "capacity": 468.3789473811262
      },
      {
          "time_stamp_utc_ms": 1733858775000,
          "time": "2024-12-10T19:28:00Z",
          "energy": 41.99999999906868,
          "capacity": 503.99999998882413
      },
      {
          "time_stamp_utc_ms": 1733859060000,
          "time": "2024-12-10T19:33:00Z",
          "energy": 37.51200000103563,
          "capacity": 473.83578948676586
      },
      {
          "time_stamp_utc_ms": 1733859360000,
          "time": "2024-12-10T19:38:00Z",
          "energy": 40.3199999993667,
          "capacity": 483.8399999924004
      },
      {
          "time_stamp_utc_ms": 1733859660000,
          "time": "2024-12-10T19:43:00Z",
          "energy": 40.51200000010431,
          "capacity": 486.14400000125175
      },
      {
          "time_stamp_utc_ms": 1733859960000,
          "time": "2024-12-10T19:48:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1733860260000,
          "time": "2024-12-10T19:53:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1733860470000,
          "time": "2024-12-10T19:58:00Z",
          "energy": 28.8960000006482,
          "capacity": 495.360000011112
      },
      {
          "time_stamp_utc_ms": 1733860890000,
          "time": "2024-12-10T20:03:00Z",
          "energy": 59.75999999977648,
          "capacity": 512.2285714266555
      },
      {
          "time_stamp_utc_ms": 1733861100000,
          "time": "2024-12-10T20:08:00Z",
          "energy": 29.759999999776483,
          "capacity": 510.1714285675968
      },
      {
          "time_stamp_utc_ms": 1733861480000,
          "time": "2024-12-10T20:13:00Z",
          "energy": 54.16000000014901,
          "capacity": 513.0947239665364
      },
      {
          "time_stamp_utc_ms": 1733861790000,
          "time": "2024-12-10T20:18:00Z",
          "energy": 45.919999999925494,
          "capacity": 533.2645325333506
      },
      {
          "time_stamp_utc_ms": 1733862075000,
          "time": "2024-12-10T20:23:00Z",
          "energy": 40.200000000186265,
          "capacity": 507.78947368656327
      },
      {
          "time_stamp_utc_ms": 1733862450000,
          "time": "2024-12-10T20:28:00Z",
          "energy": 55.799999999813735,
          "capacity": 535.6799999982118
      },
      {
          "time_stamp_utc_ms": 1733862690000,
          "time": "2024-12-10T20:33:00Z",
          "energy": 32.87999999988824,
          "capacity": 493.1999999983236
      },
      {
          "time_stamp_utc_ms": 1733862990000,
          "time": "2024-12-10T20:38:00Z",
          "energy": 46.200000000186265,
          "capacity": 554.4000000022353
      },
      {
          "time_stamp_utc_ms": 1733863230000,
          "time": "2024-12-10T20:43:00Z",
          "energy": 37.32000000029802,
          "capacity": 559.8000000044703
      },
      {
          "time_stamp_utc_ms": 1733863560000,
          "time": "2024-12-10T20:48:00Z",
          "energy": 48.239999999292195,
          "capacity": 526.254545446824
      },
      {
          "time_stamp_utc_ms": 1733863890000,
          "time": "2024-12-10T20:53:00Z",
          "energy": 51.60000000055879,
          "capacity": 562.9090909151869
      },
      {
          "time_stamp_utc_ms": 1733864140000,
          "time": "2024-12-10T20:58:00Z",
          "energy": 36.71999999973923,
          "capacity": 528.7680201671441
      },
      {
          "time_stamp_utc_ms": 1733864430000,
          "time": "2024-12-10T21:03:00Z",
          "energy": 43.200000000186265,
          "capacity": 536.2758444356743
      },
      {
          "time_stamp_utc_ms": 1733864820000,
          "time": "2024-12-10T21:08:00Z",
          "energy": 59.03999999910593,
          "capacity": 544.9846153763625
      },
      {
          "time_stamp_utc_ms": 1733865090000,
          "time": "2024-12-10T21:13:00Z",
          "energy": 41.040000000968575,
          "capacity": 547.2000000129143
      },
      {
          "time_stamp_utc_ms": 1733865375000,
          "time": "2024-12-10T21:18:00Z",
          "energy": 43.19999999925494,
          "capacity": 545.6842105169045
      },
      {
          "time_stamp_utc_ms": 1733865660000,
          "time": "2024-12-10T21:23:00Z",
          "energy": 42.95999999996275,
          "capacity": 542.6526315784768
      },
      {
          "time_stamp_utc_ms": 1733865930000,
          "time": "2024-12-10T21:28:00Z",
          "energy": 40.32000000029802,
          "capacity": 537.6000000039736
      },
      {
          "time_stamp_utc_ms": 1733866230000,
          "time": "2024-12-10T21:33:00Z",
          "energy": 46.799999999813735,
          "capacity": 561.5999999977648
      },
      {
          "time_stamp_utc_ms": 1733866620000,
          "time": "2024-12-10T21:38:00Z",
          "energy": 58.799999999813735,
          "capacity": 542.7692307675114
      },
      {
          "time_stamp_utc_ms": 1733866800000,
          "time": "2024-12-10T21:43:00Z",
          "energy": 27.360000000335276,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1733867220000,
          "time": "2024-12-10T21:48:00Z",
          "energy": 62.64000000059605,
          "capacity": 536.9142857193947
      },
      {
          "time_stamp_utc_ms": 1733867520000,
          "time": "2024-12-10T21:53:00Z",
          "energy": 44.39999999944121,
          "capacity": 532.7999999932945
      },
      {
          "time_stamp_utc_ms": 1733867760000,
          "time": "2024-12-10T21:58:00Z",
          "energy": 36.24000000022352,
          "capacity": 543.6000000033528
      },
      {
          "time_stamp_utc_ms": 1733868090000,
          "time": "2024-12-10T22:03:00Z",
          "energy": 47.04000000003725,
          "capacity": 513.1636363640428
      },
      {
          "time_stamp_utc_ms": 1733868660000,
          "time": "2024-12-10T22:13:00Z",
          "energy": 82.32000000029802,
          "capacity": 519.9157894755664
      },
      {
          "time_stamp_utc_ms": 1733868980000,
          "time": "2024-12-10T22:18:00Z",
          "energy": 46.239999999292195,
          "capacity": 520.1999844888696
      },
      {
          "time_stamp_utc_ms": 1733869280000,
          "time": "2024-12-10T22:23:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1733869545000,
          "time": "2024-12-10T22:28:00Z",
          "energy": 38.59999999962747,
          "capacity": 524.3773773566463
      },
      {
          "time_stamp_utc_ms": 1733869860000,
          "time": "2024-12-10T22:33:00Z",
          "energy": 46.77600000053644,
          "capacity": 534.5828571489878
      },
      {
          "time_stamp_utc_ms": 1733870100000,
          "time": "2024-12-10T22:38:00Z",
          "energy": 34.22399999946356,
          "capacity": 513.3599999919534
      },
      {
          "time_stamp_utc_ms": 1733870340000,
          "time": "2024-12-10T22:43:00Z",
          "energy": 33.84000000078231,
          "capacity": 507.60000001173466
      },
      {
          "time_stamp_utc_ms": 1733870780000,
          "time": "2024-12-10T22:48:00Z",
          "energy": 63.35999999940395,
          "capacity": 518.399988759106
      },
      {
          "time_stamp_utc_ms": 1733871090000,
          "time": "2024-12-10T22:53:00Z",
          "energy": 43.6800000006333,
          "capacity": 507.25162851551084
      },
      {
          "time_stamp_utc_ms": 1733871330000,
          "time": "2024-12-10T22:58:00Z",
          "energy": 34.79999999888241,
          "capacity": 521.9999999832362
      },
      {
          "time_stamp_utc_ms": 1733871660000,
          "time": "2024-12-10T23:03:00Z",
          "energy": 46.70399999991059,
          "capacity": 509.4981818172065
      },
      {
          "time_stamp_utc_ms": 1733872000000,
          "time": "2024-12-10T23:08:00Z",
          "energy": 48.096000000834465,
          "capacity": 509.25177899884557
      },
      {
          "time_stamp_utc_ms": 1733872260000,
          "time": "2024-12-10T23:13:00Z",
          "energy": 37.75999999977648,
          "capacity": 522.8307500503568
      },
      {
          "time_stamp_utc_ms": 1733872590000,
          "time": "2024-12-10T23:18:00Z",
          "energy": 45.87999999988824,
          "capacity": 500.5090909078717
      },
      {
          "time_stamp_utc_ms": 1733872860000,
          "time": "2024-12-10T23:23:00Z",
          "energy": 38.28000000026077,
          "capacity": 510.400000003477
      },
      {
          "time_stamp_utc_ms": 1733873160000,
          "time": "2024-12-10T23:28:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1733873460000,
          "time": "2024-12-10T23:33:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1733873760000,
          "time": "2024-12-10T23:38:00Z",
          "energy": 41.75999999884516,
          "capacity": 501.119999986142
      },
      {
          "time_stamp_utc_ms": 1733874060000,
          "time": "2024-12-10T23:43:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1733874360000,
          "time": "2024-12-10T23:48:00Z",
          "energy": 42.33600000105798,
          "capacity": 508.0320000126958
      },
      {
          "time_stamp_utc_ms": 1733874660000,
          "time": "2024-12-10T23:53:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1733874960000,
          "time": "2024-12-10T23:58:00Z",
          "energy": 42.14399999938905,
          "capacity": 505.72799999266863
      },
      {
          "time_stamp_utc_ms": 1733875260000,
          "time": "2024-12-11T00:03:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1733875560000,
          "time": "2024-12-11T00:08:00Z",
          "energy": 41.08799999859184,
          "capacity": 493.0559999831021
      },
      {
          "time_stamp_utc_ms": 1733875860000,
          "time": "2024-12-11T00:13:00Z",
          "energy": 41.56800000090152,
          "capacity": 498.8160000108183
      },
      {
          "time_stamp_utc_ms": 1733876145000,
          "time": "2024-12-11T00:18:00Z",
          "energy": 39.04800000041723,
          "capacity": 493.2378947421124
      },
      {
          "time_stamp_utc_ms": 1733876400000,
          "time": "2024-12-11T00:23:00Z",
          "energy": 35.16000000014901,
          "capacity": 496.376470590339
      },
      {
          "time_stamp_utc_ms": 1733876745000,
          "time": "2024-12-11T00:28:00Z",
          "energy": 47.519999999552965,
          "capacity": 495.8608695605526
      },
      {
          "time_stamp_utc_ms": 1733877060000,
          "time": "2024-12-11T00:33:00Z",
          "energy": 43.29600000008941,
          "capacity": 494.81142857245027
      },
      {
          "time_stamp_utc_ms": 1733877360000,
          "time": "2024-12-11T00:38:00Z",
          "energy": 41.90400000009686,
          "capacity": 502.8480000011623
      },
      {
          "time_stamp_utc_ms": 1733877720000,
          "time": "2024-12-11T00:43:00Z",
          "energy": 48.72000000067055,
          "capacity": 487.2000000067055
      },
      {
          "time_stamp_utc_ms": 1733877900000,
          "time": "2024-12-11T00:48:00Z",
          "energy": 25.43999999947846,
          "capacity": 508.79999998956913
      },
      {
          "time_stamp_utc_ms": 1733878240000,
          "time": "2024-12-11T00:53:00Z",
          "energy": 46.080000000074506,
          "capacity": 487.90589603912184
      },
      {
          "time_stamp_utc_ms": 1733878560000,
          "time": "2024-12-11T00:58:00Z",
          "energy": 41.27999999932945,
          "capacity": 464.3999861522582
      },
      {
          "time_stamp_utc_ms": 1733878860000,
          "time": "2024-12-11T01:03:00Z",
          "energy": 38.24000000115484,
          "capacity": 458.88000001385814
      },
      {
          "time_stamp_utc_ms": 1733879280000,
          "time": "2024-12-11T01:08:00Z",
          "energy": 51.519999999552965,
          "capacity": 441.59999999616826
      },
      {
          "time_stamp_utc_ms": 1733879445000,
          "time": "2024-12-11T01:13:00Z",
          "energy": 20.87999999988824,
          "capacity": 455.563636361198
      },
      {
          "time_stamp_utc_ms": 1733880120000,
          "time": "2024-12-11T01:23:00Z",
          "energy": 82.63999999966472,
          "capacity": 440.74666666487855
      },
      {
          "time_stamp_utc_ms": 1733880360000,
          "time": "2024-12-11T01:28:00Z",
          "energy": 28.959999999962747,
          "capacity": 434.3999999994412
      },
      {
          "time_stamp_utc_ms": 1733880660000,
          "time": "2024-12-11T01:33:00Z",
          "energy": 36.5760000012815,
          "capacity": 438.91200001537806
      },
      {
          "time_stamp_utc_ms": 1733880960000,
          "time": "2024-12-11T01:38:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1733881260000,
          "time": "2024-12-11T01:43:00Z",
          "energy": 36.09599999897182,
          "capacity": 433.15199998766184
      },
      {
          "time_stamp_utc_ms": 1733881560000,
          "time": "2024-12-11T01:48:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1733881860000,
          "time": "2024-12-11T01:53:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1733882160000,
          "time": "2024-12-11T01:58:00Z",
          "energy": 37.05600000079721,
          "capacity": 444.6720000095666
      },
      {
          "time_stamp_utc_ms": 1733882460000,
          "time": "2024-12-11T02:03:00Z",
          "energy": 37.82399999909103,
          "capacity": 453.88799998909235
      },
      {
          "time_stamp_utc_ms": 1733882760000,
          "time": "2024-12-11T02:08:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1733883060000,
          "time": "2024-12-11T02:13:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1733883360000,
          "time": "2024-12-11T02:18:00Z",
          "energy": 37.15199999883771,
          "capacity": 445.8239999860525
      },
      {
          "time_stamp_utc_ms": 1733883660000,
          "time": "2024-12-11T02:23:00Z",
          "energy": 35.712000001221895,
          "capacity": 428.54400001466274
      },
      {
          "time_stamp_utc_ms": 1733883960000,
          "time": "2024-12-11T02:28:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1733884260000,
          "time": "2024-12-11T02:33:00Z",
          "energy": 35.42399999964982,
          "capacity": 425.0879999957979
      },
      {
          "time_stamp_utc_ms": 1733884560000,
          "time": "2024-12-11T02:38:00Z",
          "energy": 37.2480000006035,
          "capacity": 446.976000007242
      },
      {
          "time_stamp_utc_ms": 1733884860000,
          "time": "2024-12-11T02:43:00Z",
          "energy": 35.61599999945611,
          "capacity": 427.3919999934733
      },
      {
          "time_stamp_utc_ms": 1733885160000,
          "time": "2024-12-11T02:48:00Z",
          "energy": 35.61600000038743,
          "capacity": 427.39200000464916
      },
      {
          "time_stamp_utc_ms": 1733885460000,
          "time": "2024-12-11T02:53:00Z",
          "energy": 36.28800000064075,
          "capacity": 435.456000007689
      },
      {
          "time_stamp_utc_ms": 1733885760000,
          "time": "2024-12-11T02:58:00Z",
          "energy": 34.27199999988079,
          "capacity": 411.26399999856955
      },
      {
          "time_stamp_utc_ms": 1733886060000,
          "time": "2024-12-11T03:03:00Z",
          "energy": 36.09599999990314,
          "capacity": 433.1519999988377
      },
      {
          "time_stamp_utc_ms": 1733886360000,
          "time": "2024-12-11T03:08:00Z",
          "energy": 33.50399999972433,
          "capacity": 402.047999996692
      },
      {
          "time_stamp_utc_ms": 1733886660000,
          "time": "2024-12-11T03:13:00Z",
          "energy": 34.84800000023097,
          "capacity": 418.1760000027716
      },
      {
          "time_stamp_utc_ms": 1733886960000,
          "time": "2024-12-11T03:18:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1733887260000,
          "time": "2024-12-11T03:23:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1733887560000,
          "time": "2024-12-11T03:28:00Z",
          "energy": 34.94399999920279,
          "capacity": 419.3279999904335
      },
      {
          "time_stamp_utc_ms": 1733887860000,
          "time": "2024-12-11T03:33:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1733888160000,
          "time": "2024-12-11T03:38:00Z",
          "energy": 34.27200000081211,
          "capacity": 411.26400000974536
      },
      {
          "time_stamp_utc_ms": 1733888460000,
          "time": "2024-12-11T03:43:00Z",
          "energy": 34.847999999299645,
          "capacity": 418.1759999915958
      },
      {
          "time_stamp_utc_ms": 1733888760000,
          "time": "2024-12-11T03:48:00Z",
          "energy": 33.98400000017136,
          "capacity": 407.8080000020564
      },
      {
          "time_stamp_utc_ms": 1733889060000,
          "time": "2024-12-11T03:53:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1733889360000,
          "time": "2024-12-11T03:58:00Z",
          "energy": 32.639999999664724,
          "capacity": 391.6799999959767
      },
      {
          "time_stamp_utc_ms": 1733889660000,
          "time": "2024-12-11T04:03:00Z",
          "energy": 32.83200000040233,
          "capacity": 393.98400000482803
      },
      {
          "time_stamp_utc_ms": 1733889960000,
          "time": "2024-12-11T04:08:00Z",
          "energy": 33.21599999908358,
          "capacity": 398.59199998900294
      },
      {
          "time_stamp_utc_ms": 1733890260000,
          "time": "2024-12-11T04:13:00Z",
          "energy": 32.544000000692904,
          "capacity": 390.5280000083149
      },
      {
          "time_stamp_utc_ms": 1733890560000,
          "time": "2024-12-11T04:18:00Z",
          "energy": 34.7519999993965,
          "capacity": 417.0239999927581
      },
      {
          "time_stamp_utc_ms": 1733890860000,
          "time": "2024-12-11T04:23:00Z",
          "energy": 32.06400000024587,
          "capacity": 384.76800000295043
      },
      {
          "time_stamp_utc_ms": 1733891160000,
          "time": "2024-12-11T04:28:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1733891460000,
          "time": "2024-12-11T04:33:00Z",
          "energy": 32.25600000098348,
          "capacity": 387.0720000118018
      },
      {
          "time_stamp_utc_ms": 1733891760000,
          "time": "2024-12-11T04:38:00Z",
          "energy": 32.639999999664724,
          "capacity": 391.6799999959767
      },
      {
          "time_stamp_utc_ms": 1733892060000,
          "time": "2024-12-11T04:43:00Z",
          "energy": 33.59999999962747,
          "capacity": 403.1999999955297
      },
      {
          "time_stamp_utc_ms": 1733892360000,
          "time": "2024-12-11T04:48:00Z",
          "energy": 31.967999999411404,
          "capacity": 383.61599999293685
      },
      {
          "time_stamp_utc_ms": 1733892660000,
          "time": "2024-12-11T04:53:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1733892960000,
          "time": "2024-12-11T04:58:00Z",
          "energy": 31.967999999411404,
          "capacity": 383.61599999293685
      },
      {
          "time_stamp_utc_ms": 1733893260000,
          "time": "2024-12-11T05:03:00Z",
          "energy": 33.312000000849366,
          "capacity": 399.74400001019245
      },
      {
          "time_stamp_utc_ms": 1733893560000,
          "time": "2024-12-11T05:08:00Z",
          "energy": 33.407999999821186,
          "capacity": 400.8959999978543
      },
      {
          "time_stamp_utc_ms": 1733893860000,
          "time": "2024-12-11T05:13:00Z",
          "energy": 32.639999999664724,
          "capacity": 391.6799999959767
      },
      {
          "time_stamp_utc_ms": 1733894160000,
          "time": "2024-12-11T05:18:00Z",
          "energy": 33.40800000075251,
          "capacity": 400.89600000903016
      },
      {
          "time_stamp_utc_ms": 1733894460000,
          "time": "2024-12-11T05:23:00Z",
          "energy": 32.6399999987334,
          "capacity": 391.6799999848008
      },
      {
          "time_stamp_utc_ms": 1733894760000,
          "time": "2024-12-11T05:28:00Z",
          "energy": 33.984000001102686,
          "capacity": 407.80800001323223
      },
      {
          "time_stamp_utc_ms": 1733895060000,
          "time": "2024-12-11T05:33:00Z",
          "energy": 34.94399999920279,
          "capacity": 419.3279999904335
      },
      {
          "time_stamp_utc_ms": 1733895400000,
          "time": "2024-12-11T05:38:00Z",
          "energy": 37.95200000051409,
          "capacity": 401.844717159237
      },
      {
          "time_stamp_utc_ms": 1733895660000,
          "time": "2024-12-11T05:43:00Z",
          "energy": 29.919999999925494,
          "capacity": 414.27690788030503
      },
      {
          "time_stamp_utc_ms": 1733896020000,
          "time": "2024-12-11T05:48:00Z",
          "energy": 39.04000000003725,
          "capacity": 390.40000000037253
      },
      {
          "time_stamp_utc_ms": 1733896280000,
          "time": "2024-12-11T05:53:00Z",
          "energy": 29.440000000409782,
          "capacity": 407.6307542846362
      },
      {
          "time_stamp_utc_ms": 1733896470000,
          "time": "2024-12-11T05:58:00Z",
          "energy": 21.759999999776483,
          "capacity": 412.2947264906362
      },
      {
          "time_stamp_utc_ms": 1733896920000,
          "time": "2024-12-11T06:03:00Z",
          "energy": 49.92000000085682,
          "capacity": 399.36000000685453
      },
      {
          "time_stamp_utc_ms": 1733897160000,
          "time": "2024-12-11T06:08:00Z",
          "energy": 27.071999999694526,
          "capacity": 406.0799999954179
      },
      {
          "time_stamp_utc_ms": 1733897460000,
          "time": "2024-12-11T06:13:00Z",
          "energy": 31.775999998673797,
          "capacity": 381.31199998408556
      },
      {
          "time_stamp_utc_ms": 1733897760000,
          "time": "2024-12-11T06:18:00Z",
          "energy": 33.60000000055879,
          "capacity": 403.2000000067056
      },
      {
          "time_stamp_utc_ms": 1733898030000,
          "time": "2024-12-11T06:23:00Z",
          "energy": 29.11199999973178,
          "capacity": 388.1599999964237
      },
      {
          "time_stamp_utc_ms": 1733898660000,
          "time": "2024-12-11T06:33:00Z",
          "energy": 68.52000000048429,
          "capacity": 391.54285714562445
      },
      {
          "time_stamp_utc_ms": 1733898990000,
          "time": "2024-12-11T06:38:00Z",
          "energy": 35.519999999552965,
          "capacity": 387.49090908603233
      },
      {
          "time_stamp_utc_ms": 1733899380000,
          "time": "2024-12-11T06:43:00Z",
          "energy": 43.200000000186265,
          "capacity": 398.7692307709502
      },
      {
          "time_stamp_utc_ms": 1733899590000,
          "time": "2024-12-11T06:48:00Z",
          "energy": 22.799999999813735,
          "capacity": 390.8571428539497
      },
      {
          "time_stamp_utc_ms": 1733899920000,
          "time": "2024-12-11T06:53:00Z",
          "energy": 37.83999999985099,
          "capacity": 412.7999999983744
      },
      {
          "time_stamp_utc_ms": 1733900180000,
          "time": "2024-12-11T06:58:00Z",
          "energy": 29.12000000011176,
          "capacity": 403.1999852122601
      },
      {
          "time_stamp_utc_ms": 1733900440000,
          "time": "2024-12-11T07:03:00Z",
          "energy": 28.16000000014901,
          "capacity": 389.9076780080272
      },
      {
          "time_stamp_utc_ms": 1733900760000,
          "time": "2024-12-11T07:08:00Z",
          "energy": 35.39199999999255,
          "capacity": 398.15998813382384
      },
      {
          "time_stamp_utc_ms": 1733901060000,
          "time": "2024-12-11T07:13:00Z",
          "energy": 32.54399999976158,
          "capacity": 390.527999997139
      },
      {
          "time_stamp_utc_ms": 1733901360000,
          "time": "2024-12-11T07:18:00Z",
          "energy": 32.73600000049919,
          "capacity": 392.8320000059903
      },
      {
          "time_stamp_utc_ms": 1733901660000,
          "time": "2024-12-11T07:23:00Z",
          "energy": 33.59999999869615,
          "capacity": 403.1999999843538
      },
      {
          "time_stamp_utc_ms": 1733901960000,
          "time": "2024-12-11T07:28:00Z",
          "energy": 31.96800000127405,
          "capacity": 383.61600001528865
      },
      {
          "time_stamp_utc_ms": 1733902260000,
          "time": "2024-12-11T07:33:00Z",
          "energy": 34.36799999885261,
          "capacity": 412.4159999862314
      },
      {
          "time_stamp_utc_ms": 1733902560000,
          "time": "2024-12-11T07:38:00Z",
          "energy": 32.160000001080334,
          "capacity": 385.92000001296407
      },
      {
          "time_stamp_utc_ms": 1733902860000,
          "time": "2024-12-11T07:43:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1733903160000,
          "time": "2024-12-11T07:48:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1733903460000,
          "time": "2024-12-11T07:53:00Z",
          "energy": 32.83199999947101,
          "capacity": 393.9839999936521
      },
      {
          "time_stamp_utc_ms": 1733903760000,
          "time": "2024-12-11T07:58:00Z",
          "energy": 33.60000000055879,
          "capacity": 403.2000000067056
      },
      {
          "time_stamp_utc_ms": 1733904060000,
          "time": "2024-12-11T08:03:00Z",
          "energy": 31.775999998673797,
          "capacity": 381.31199998408556
      },
      {
          "time_stamp_utc_ms": 1733904360000,
          "time": "2024-12-11T08:08:00Z",
          "energy": 33.024000000208616,
          "capacity": 396.28800000250345
      },
      {
          "time_stamp_utc_ms": 1733904660000,
          "time": "2024-12-11T08:13:00Z",
          "energy": 32.44800000078976,
          "capacity": 389.3760000094772
      },
      {
          "time_stamp_utc_ms": 1733904960000,
          "time": "2024-12-11T08:18:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1733905260000,
          "time": "2024-12-11T08:23:00Z",
          "energy": 33.69599999859929,
          "capacity": 404.3519999831915
      },
      {
          "time_stamp_utc_ms": 1733905540000,
          "time": "2024-12-11T08:28:00Z",
          "energy": 30.56000000052154,
          "capacity": 392.91429910357255
      },
      {
          "time_stamp_utc_ms": 1733905820000,
          "time": "2024-12-11T08:33:00Z",
          "energy": 31.360000000335276,
          "capacity": 403.2000137372213
      },
      {
          "time_stamp_utc_ms": 1733906160000,
          "time": "2024-12-11T08:38:00Z",
          "energy": 37.919999999925494,
          "capacity": 401.50589361408936
      },
      {
          "time_stamp_utc_ms": 1733906445000,
          "time": "2024-12-11T08:43:00Z",
          "energy": 31.080000000074506,
          "capacity": 392.58947368515163
      },
      {
          "time_stamp_utc_ms": 1733906670000,
          "time": "2024-12-11T08:48:00Z",
          "energy": 25.799999999813735,
          "capacity": 412.79999999701977
      },
      {
          "time_stamp_utc_ms": 1733907060000,
          "time": "2024-12-11T08:53:00Z",
          "energy": 40.84800000023097,
          "capacity": 377.0584615405936
      },
      {
          "time_stamp_utc_ms": 1733907360000,
          "time": "2024-12-11T08:58:00Z",
          "energy": 33.79200000036508,
          "capacity": 405.504000004381
      },
      {
          "time_stamp_utc_ms": 1733907660000,
          "time": "2024-12-11T09:03:00Z",
          "energy": 31.87199999950826,
          "capacity": 382.46399999409914
      },
      {
          "time_stamp_utc_ms": 1733907960000,
          "time": "2024-12-11T09:08:00Z",
          "energy": 32.54399999976158,
          "capacity": 390.527999997139
      },
      {
          "time_stamp_utc_ms": 1733908260000,
          "time": "2024-12-11T09:13:00Z",
          "energy": 33.60000000055879,
          "capacity": 403.2000000067056
      },
      {
          "time_stamp_utc_ms": 1733908560000,
          "time": "2024-12-11T09:18:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1733908860000,
          "time": "2024-12-11T09:23:00Z",
          "energy": 34.55999999959022,
          "capacity": 414.7199999950827
      },
      {
          "time_stamp_utc_ms": 1733909160000,
          "time": "2024-12-11T09:28:00Z",
          "energy": 35.52000000048429,
          "capacity": 426.24000000581145
      },
      {
          "time_stamp_utc_ms": 1733909460000,
          "time": "2024-12-11T09:33:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1733909760000,
          "time": "2024-12-11T09:38:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1733910060000,
          "time": "2024-12-11T09:43:00Z",
          "energy": 37.5359999993816,
          "capacity": 450.4319999925792
      },
      {
          "time_stamp_utc_ms": 1733910360000,
          "time": "2024-12-11T09:48:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1733910660000,
          "time": "2024-12-11T09:53:00Z",
          "energy": 37.43999999854714,
          "capacity": 449.27999998256564
      },
      {
          "time_stamp_utc_ms": 1733910960000,
          "time": "2024-12-11T09:58:00Z",
          "energy": 38.20800000149757,
          "capacity": 458.4960000179708
      },
      {
          "time_stamp_utc_ms": 1733911260000,
          "time": "2024-12-11T10:03:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1733911560000,
          "time": "2024-12-11T10:08:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1733911860000,
          "time": "2024-12-11T10:13:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1733912160000,
          "time": "2024-12-11T10:18:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1733912460000,
          "time": "2024-12-11T10:23:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1733912760000,
          "time": "2024-12-11T10:28:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1733913060000,
          "time": "2024-12-11T10:33:00Z",
          "energy": 37.91999999899417,
          "capacity": 455.03999998793006
      },
      {
          "time_stamp_utc_ms": 1733913360000,
          "time": "2024-12-11T10:38:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1733913630000,
          "time": "2024-12-11T10:43:00Z",
          "energy": 35.25599999912083,
          "capacity": 470.07999998827773
      },
      {
          "time_stamp_utc_ms": 1733914200000,
          "time": "2024-12-11T10:53:00Z",
          "energy": 75.71999999973923,
          "capacity": 478.2315789457214
      },
      {
          "time_stamp_utc_ms": 1733914680000,
          "time": "2024-12-11T10:58:00Z",
          "energy": 62.40000000037253,
          "capacity": 468.00000000279397
      },
      {
          "time_stamp_utc_ms": 1733914860000,
          "time": "2024-12-11T11:03:00Z",
          "energy": 24.320000000298023,
          "capacity": 486.4000000059604
      },
      {
          "time_stamp_utc_ms": 1733915100000,
          "time": "2024-12-11T11:08:00Z",
          "energy": 31.040000000037253,
          "capacity": 465.6000000005588
      },
      {
          "time_stamp_utc_ms": 1733915475000,
          "time": "2024-12-11T11:13:00Z",
          "energy": 51.55999999959022,
          "capacity": 494.97599999606615
      },
      {
          "time_stamp_utc_ms": 1733915640000,
          "time": "2024-12-11T11:18:00Z",
          "energy": 22.6800000006333,
          "capacity": 494.8363636501811
      },
      {
          "time_stamp_utc_ms": 1733916150000,
          "time": "2024-12-11T11:23:00Z",
          "energy": 69.83999999985099,
          "capacity": 492.9882352930658
      },
      {
          "time_stamp_utc_ms": 1733916360000,
          "time": "2024-12-11T11:28:00Z",
          "energy": 29.040000000037253,
          "capacity": 497.82857142921006
      },
      {
          "time_stamp_utc_ms": 1733916660000,
          "time": "2024-12-11T11:33:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1733917560000,
          "time": "2024-12-11T11:48:00Z",
          "energy": 126.8160000005737,
          "capacity": 507.2640000022948
      },
      {
          "time_stamp_utc_ms": 1733917860000,
          "time": "2024-12-11T11:53:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1733918160000,
          "time": "2024-12-11T11:58:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1733918460000,
          "time": "2024-12-11T12:03:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1733918760000,
          "time": "2024-12-11T12:08:00Z",
          "energy": 43.032000000588596,
          "capacity": 516.3840000070632
      },
      {
          "time_stamp_utc_ms": 1733919060000,
          "time": "2024-12-11T12:13:00Z",
          "energy": 42.95999999996275,
          "capacity": 515.5199999995531
      },
      {
          "time_stamp_utc_ms": 1733919360000,
          "time": "2024-12-11T12:18:00Z",
          "energy": 44.47200000099838,
          "capacity": 533.6640000119805
      },
      {
          "time_stamp_utc_ms": 1733919630000,
          "time": "2024-12-11T12:23:00Z",
          "energy": 37.72800000011921,
          "capacity": 503.0400000015895
      },
      {
          "time_stamp_utc_ms": 1733920020000,
          "time": "2024-12-11T12:28:00Z",
          "energy": 57.35999999940395,
          "capacity": 529.4769230714211
      },
      {
          "time_stamp_utc_ms": 1733920350000,
          "time": "2024-12-11T12:33:00Z",
          "energy": 46.799999999813735,
          "capacity": 510.5454545434226
      },
      {
          "time_stamp_utc_ms": 1733920620000,
          "time": "2024-12-11T12:38:00Z",
          "energy": 40.799999999813735,
          "capacity": 543.9999999975164
      },
      {
          "time_stamp_utc_ms": 1733920880000,
          "time": "2024-12-11T12:43:00Z",
          "energy": 38.24000000022352,
          "capacity": 529.4769036589208
      },
      {
          "time_stamp_utc_ms": 1733921130000,
          "time": "2024-12-11T12:48:00Z",
          "energy": 36.16000000014901,
          "capacity": 520.7040198654278
      },
      {
          "time_stamp_utc_ms": 1733921430000,
          "time": "2024-12-11T12:53:00Z",
          "energy": 44.64000000059605,
          "capacity": 535.6800000071527
      },
      {
          "time_stamp_utc_ms": 1733921760000,
          "time": "2024-12-11T12:58:00Z",
          "energy": 47.03999999910593,
          "capacity": 513.1636363538829
      },
      {
          "time_stamp_utc_ms": 1733922090000,
          "time": "2024-12-11T13:03:00Z",
          "energy": 48.83999999985099,
          "capacity": 532.7999999983745
      },
      {
          "time_stamp_utc_ms": 1733922360000,
          "time": "2024-12-11T13:08:00Z",
          "energy": 39.76800000015646,
          "capacity": 530.2400000020862
      },
      {
          "time_stamp_utc_ms": 1733922660000,
          "time": "2024-12-11T13:13:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1733922960000,
          "time": "2024-12-11T13:18:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1733923260000,
          "time": "2024-12-11T13:23:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1733923560000,
          "time": "2024-12-11T13:28:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1733923860000,
          "time": "2024-12-11T13:33:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1733924160000,
          "time": "2024-12-11T13:38:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1733924460000,
          "time": "2024-12-11T13:43:00Z",
          "energy": 44.64000000059605,
          "capacity": 535.6800000071527
      },
      {
          "time_stamp_utc_ms": 1733924760000,
          "time": "2024-12-11T13:48:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1733925060000,
          "time": "2024-12-11T13:53:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1733925360000,
          "time": "2024-12-11T13:58:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1733925660000,
          "time": "2024-12-11T14:03:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1733925960000,
          "time": "2024-12-11T14:08:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1733926260000,
          "time": "2024-12-11T14:13:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1733926560000,
          "time": "2024-12-11T14:18:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1733926860000,
          "time": "2024-12-11T14:23:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1733927160000,
          "time": "2024-12-11T14:28:00Z",
          "energy": 45.312000000849366,
          "capacity": 543.7440000101924
      },
      {
          "time_stamp_utc_ms": 1733927460000,
          "time": "2024-12-11T14:33:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1733927760000,
          "time": "2024-12-11T14:38:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1733928060000,
          "time": "2024-12-11T14:43:00Z",
          "energy": 45.312000000849366,
          "capacity": 543.7440000101924
      },
      {
          "time_stamp_utc_ms": 1733928360000,
          "time": "2024-12-11T14:48:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1733928660000,
          "time": "2024-12-11T14:53:00Z",
          "energy": 44.544000000692904,
          "capacity": 534.528000008315
      },
      {
          "time_stamp_utc_ms": 1733928975000,
          "time": "2024-12-11T14:58:00Z",
          "energy": 50.11199999880046,
          "capacity": 572.7085714148624
      },
      {
          "time_stamp_utc_ms": 1733929260000,
          "time": "2024-12-11T15:03:00Z",
          "energy": 44.639999999664724,
          "capacity": 563.8736842062913
      },
      {
          "time_stamp_utc_ms": 1733929560000,
          "time": "2024-12-11T15:08:00Z",
          "energy": 47.04000000003725,
          "capacity": 564.480000000447
      },
      {
          "time_stamp_utc_ms": 1733929860000,
          "time": "2024-12-11T15:13:00Z",
          "energy": 46.752000000327826,
          "capacity": 561.0240000039339
      },
      {
          "time_stamp_utc_ms": 1733930160000,
          "time": "2024-12-11T15:18:00Z",
          "energy": 45.69600000139326,
          "capacity": 548.3520000167191
      },
      {
          "time_stamp_utc_ms": 1733930460000,
          "time": "2024-12-11T15:23:00Z",
          "energy": 47.4239999987185,
          "capacity": 569.087999984622
      },
      {
          "time_stamp_utc_ms": 1733930760000,
          "time": "2024-12-11T15:28:00Z",
          "energy": 46.17600000090897,
          "capacity": 554.1120000109076
      },
      {
          "time_stamp_utc_ms": 1733931060000,
          "time": "2024-12-11T15:33:00Z",
          "energy": 49.05599999986589,
          "capacity": 588.6719999983907
      },
      {
          "time_stamp_utc_ms": 1733931360000,
          "time": "2024-12-11T15:38:00Z",
          "energy": 48.287999998778105,
          "capacity": 579.4559999853373
      },
      {
          "time_stamp_utc_ms": 1733931660000,
          "time": "2024-12-11T15:43:00Z",
          "energy": 49.05600000079721,
          "capacity": 588.6720000095667
      },
      {
          "time_stamp_utc_ms": 1733931960000,
          "time": "2024-12-11T15:48:00Z",
          "energy": 49.05599999893457,
          "capacity": 588.6719999872149
      },
      {
          "time_stamp_utc_ms": 1733932260000,
          "time": "2024-12-11T15:53:00Z",
          "energy": 48.57600000035018,
          "capacity": 582.9120000042021
      },
      {
          "time_stamp_utc_ms": 1733932560000,
          "time": "2024-12-11T15:58:00Z",
          "energy": 50.976000000722706,
          "capacity": 611.7120000086725
      },
      {
          "time_stamp_utc_ms": 1733932860000,
          "time": "2024-12-11T16:03:00Z",
          "energy": 49.05599999986589,
          "capacity": 588.6719999983907
      },
      {
          "time_stamp_utc_ms": 1733933160000,
          "time": "2024-12-11T16:08:00Z",
          "energy": 48.67200000025332,
          "capacity": 584.0640000030398
      },
      {
          "time_stamp_utc_ms": 1733933460000,
          "time": "2024-12-11T16:13:00Z",
          "energy": 50.01599999982864,
          "capacity": 600.1919999979438
      },
      {
          "time_stamp_utc_ms": 1733933760000,
          "time": "2024-12-11T16:18:00Z",
          "energy": 49.15199999883771,
          "capacity": 589.8239999860526
      },
      {
          "time_stamp_utc_ms": 1733934060000,
          "time": "2024-12-11T16:23:00Z",
          "energy": 49.72800000105053,
          "capacity": 596.7360000126064
      },
      {
          "time_stamp_utc_ms": 1733934360000,
          "time": "2024-12-11T16:28:00Z",
          "energy": 49.43999999947846,
          "capacity": 593.2799999937415
      },
      {
          "time_stamp_utc_ms": 1733934660000,
          "time": "2024-12-11T16:33:00Z",
          "energy": 48.67200000025332,
          "capacity": 584.0640000030398
      },
      {
          "time_stamp_utc_ms": 1733934960000,
          "time": "2024-12-11T16:38:00Z",
          "energy": 49.43999999947846,
          "capacity": 593.2799999937415
      },
      {
          "time_stamp_utc_ms": 1733935260000,
          "time": "2024-12-11T16:43:00Z",
          "energy": 48.19200000073761,
          "capacity": 578.3040000088513
      },
      {
          "time_stamp_utc_ms": 1733935560000,
          "time": "2024-12-11T16:48:00Z",
          "energy": 49.72800000011921,
          "capacity": 596.7360000014306
      },
      {
          "time_stamp_utc_ms": 1733935860000,
          "time": "2024-12-11T16:53:00Z",
          "energy": 48.38400000054389,
          "capacity": 580.6080000065267
      },
      {
          "time_stamp_utc_ms": 1733936160000,
          "time": "2024-12-11T16:58:00Z",
          "energy": 50.11199999973178,
          "capacity": 601.3439999967815
      },
      {
          "time_stamp_utc_ms": 1733936460000,
          "time": "2024-12-11T17:03:00Z",
          "energy": 50.015999998897314,
          "capacity": 600.1919999867678
      },
      {
          "time_stamp_utc_ms": 1733936760000,
          "time": "2024-12-11T17:08:00Z",
          "energy": 48.0,
          "capacity": 576.0000000000001
      },
      {
          "time_stamp_utc_ms": 1733937060000,
          "time": "2024-12-11T17:13:00Z",
          "energy": 49.92000000085682,
          "capacity": 599.0400000102818
      },
      {
          "time_stamp_utc_ms": 1733937360000,
          "time": "2024-12-11T17:18:00Z",
          "energy": 48.09599999897182,
          "capacity": 577.1519999876618
      },
      {
          "time_stamp_utc_ms": 1733937660000,
          "time": "2024-12-11T17:23:00Z",
          "energy": 49.92000000085682,
          "capacity": 599.0400000102818
      },
      {
          "time_stamp_utc_ms": 1733937960000,
          "time": "2024-12-11T17:28:00Z",
          "energy": 48.09599999990314,
          "capacity": 577.1519999988378
      },
      {
          "time_stamp_utc_ms": 1733938260000,
          "time": "2024-12-11T17:33:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1733938560000,
          "time": "2024-12-11T17:38:00Z",
          "energy": 45.696000000461936,
          "capacity": 548.3520000055432
      },
      {
          "time_stamp_utc_ms": 1733938860000,
          "time": "2024-12-11T17:43:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1733939160000,
          "time": "2024-12-11T17:48:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1733939460000,
          "time": "2024-12-11T17:53:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1733939760000,
          "time": "2024-12-11T17:58:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1733940060000,
          "time": "2024-12-11T18:03:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1733940360000,
          "time": "2024-12-11T18:08:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1733940660000,
          "time": "2024-12-11T18:13:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1733940960000,
          "time": "2024-12-11T18:18:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1733941260000,
          "time": "2024-12-11T18:23:00Z",
          "energy": 41.95200000051409,
          "capacity": 503.42400000616914
      },
      {
          "time_stamp_utc_ms": 1733941560000,
          "time": "2024-12-11T18:28:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1733941860000,
          "time": "2024-12-11T18:33:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1733942160000,
          "time": "2024-12-11T18:38:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1733942460000,
          "time": "2024-12-11T18:43:00Z",
          "energy": 43.6800000006333,
          "capacity": 524.1600000075996
      },
      {
          "time_stamp_utc_ms": 1733942760000,
          "time": "2024-12-11T18:48:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1733943060000,
          "time": "2024-12-11T18:53:00Z",
          "energy": 45.79200000036508,
          "capacity": 549.5040000043809
      },
      {
          "time_stamp_utc_ms": 1733943360000,
          "time": "2024-12-11T18:58:00Z",
          "energy": 45.8879999993369,
          "capacity": 550.6559999920429
      },
      {
          "time_stamp_utc_ms": 1733943660000,
          "time": "2024-12-11T19:03:00Z",
          "energy": 47.519999999552965,
          "capacity": 570.2399999946356
      },
      {
          "time_stamp_utc_ms": 1733943960000,
          "time": "2024-12-11T19:08:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1733944260000,
          "time": "2024-12-11T19:13:00Z",
          "energy": 46.65600000042468,
          "capacity": 559.8720000050962
      },
      {
          "time_stamp_utc_ms": 1733944560000,
          "time": "2024-12-11T19:18:00Z",
          "energy": 47.61600000038743,
          "capacity": 571.3920000046492
      },
      {
          "time_stamp_utc_ms": 1733944860000,
          "time": "2024-12-11T19:23:00Z",
          "energy": 47.13599999900907,
          "capacity": 565.6319999881089
      },
      {
          "time_stamp_utc_ms": 1733945160000,
          "time": "2024-12-11T19:28:00Z",
          "energy": 47.90400000009686,
          "capacity": 574.8480000011623
      },
      {
          "time_stamp_utc_ms": 1733945460000,
          "time": "2024-12-11T19:33:00Z",
          "energy": 47.52000000048429,
          "capacity": 570.2400000058115
      },
      {
          "time_stamp_utc_ms": 1733945760000,
          "time": "2024-12-11T19:38:00Z",
          "energy": 46.36799999978393,
          "capacity": 556.4159999974072
      },
      {
          "time_stamp_utc_ms": 1733946060000,
          "time": "2024-12-11T19:43:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1733946360000,
          "time": "2024-12-11T19:48:00Z",
          "energy": 44.25599999912083,
          "capacity": 531.07199998945
      },
      {
          "time_stamp_utc_ms": 1733946660000,
          "time": "2024-12-11T19:53:00Z",
          "energy": 47.23200000077486,
          "capacity": 566.7840000092983
      },
      {
          "time_stamp_utc_ms": 1733946960000,
          "time": "2024-12-11T19:58:00Z",
          "energy": 46.07999999914318,
          "capacity": 552.9599999897183
      },
      {
          "time_stamp_utc_ms": 1733947260000,
          "time": "2024-12-11T20:03:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1733947560000,
          "time": "2024-12-11T20:08:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1733947860000,
          "time": "2024-12-11T20:13:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1733948160000,
          "time": "2024-12-11T20:18:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1733948460000,
          "time": "2024-12-11T20:23:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1733948760000,
          "time": "2024-12-11T20:28:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1733949060000,
          "time": "2024-12-11T20:33:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1733949360000,
          "time": "2024-12-11T20:38:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1733949660000,
          "time": "2024-12-11T20:43:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1733949960000,
          "time": "2024-12-11T20:48:00Z",
          "energy": 43.00799999944866,
          "capacity": 516.0959999933839
      },
      {
          "time_stamp_utc_ms": 1733950260000,
          "time": "2024-12-11T20:53:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1733950560000,
          "time": "2024-12-11T20:58:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1733950860000,
          "time": "2024-12-11T21:03:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1733951160000,
          "time": "2024-12-11T21:08:00Z",
          "energy": 45.696000000461936,
          "capacity": 548.3520000055432
      },
      {
          "time_stamp_utc_ms": 1733951460000,
          "time": "2024-12-11T21:13:00Z",
          "energy": 43.39200000092387,
          "capacity": 520.7040000110865
      },
      {
          "time_stamp_utc_ms": 1733951760000,
          "time": "2024-12-11T21:18:00Z",
          "energy": 45.119999999180436,
          "capacity": 541.4399999901652
      },
      {
          "time_stamp_utc_ms": 1733952060000,
          "time": "2024-12-11T21:23:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1733952360000,
          "time": "2024-12-11T21:28:00Z",
          "energy": 43.6800000006333,
          "capacity": 524.1600000075996
      },
      {
          "time_stamp_utc_ms": 1733952660000,
          "time": "2024-12-11T21:33:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1733952960000,
          "time": "2024-12-11T21:38:00Z",
          "energy": 42.62399999983609,
          "capacity": 511.4879999980331
      },
      {
          "time_stamp_utc_ms": 1733953260000,
          "time": "2024-12-11T21:43:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1733953560000,
          "time": "2024-12-11T21:48:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1733953860000,
          "time": "2024-12-11T21:53:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1733954160000,
          "time": "2024-12-11T21:58:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1733954460000,
          "time": "2024-12-11T22:03:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1733954760000,
          "time": "2024-12-11T22:08:00Z",
          "energy": 44.447999998927116,
          "capacity": 533.3759999871254
      },
      {
          "time_stamp_utc_ms": 1733955060000,
          "time": "2024-12-11T22:13:00Z",
          "energy": 43.872000000439584,
          "capacity": 526.464000005275
      },
      {
          "time_stamp_utc_ms": 1733955360000,
          "time": "2024-12-11T22:18:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1733955660000,
          "time": "2024-12-11T22:23:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1733955960000,
          "time": "2024-12-11T22:28:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1733956260000,
          "time": "2024-12-11T22:33:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1733956560000,
          "time": "2024-12-11T22:38:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1733956860000,
          "time": "2024-12-11T22:43:00Z",
          "energy": 44.25600000098348,
          "capacity": 531.0720000118018
      },
      {
          "time_stamp_utc_ms": 1733957160000,
          "time": "2024-12-11T22:48:00Z",
          "energy": 43.48799999896437,
          "capacity": 521.8559999875724
      },
      {
          "time_stamp_utc_ms": 1733957460000,
          "time": "2024-12-11T22:53:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1733957760000,
          "time": "2024-12-11T22:58:00Z",
          "energy": 44.35200000088662,
          "capacity": 532.2240000106395
      },
      {
          "time_stamp_utc_ms": 1733958060000,
          "time": "2024-12-11T23:03:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1733958360000,
          "time": "2024-12-11T23:08:00Z",
          "energy": 44.06400000117719,
          "capacity": 528.7680000141264
      },
      {
          "time_stamp_utc_ms": 1733958660000,
          "time": "2024-12-11T23:13:00Z",
          "energy": 42.14399999845773,
          "capacity": 505.7279999814928
      },
      {
          "time_stamp_utc_ms": 1733958960000,
          "time": "2024-12-11T23:18:00Z",
          "energy": 42.62400000076741,
          "capacity": 511.4880000092089
      },
      {
          "time_stamp_utc_ms": 1733959260000,
          "time": "2024-12-11T23:23:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1733959560000,
          "time": "2024-12-11T23:28:00Z",
          "energy": 41.28000000026077,
          "capacity": 495.36000000312924
      },
      {
          "time_stamp_utc_ms": 1733959860000,
          "time": "2024-12-11T23:33:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1733960160000,
          "time": "2024-12-11T23:38:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1733960460000,
          "time": "2024-12-11T23:43:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1733960760000,
          "time": "2024-12-11T23:48:00Z",
          "energy": 42.14399999845773,
          "capacity": 505.7279999814928
      },
      {
          "time_stamp_utc_ms": 1733961060000,
          "time": "2024-12-11T23:53:00Z",
          "energy": 41.28000000119209,
          "capacity": 495.3600000143052
      },
      {
          "time_stamp_utc_ms": 1733961360000,
          "time": "2024-12-11T23:58:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1733961660000,
          "time": "2024-12-12T00:03:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1733961960000,
          "time": "2024-12-12T00:08:00Z",
          "energy": 42.04800000041723,
          "capacity": 504.57600000500685
      },
      {
          "time_stamp_utc_ms": 1733962260000,
          "time": "2024-12-12T00:13:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1733962560000,
          "time": "2024-12-12T00:18:00Z",
          "energy": 42.14399999938905,
          "capacity": 505.72799999266863
      },
      {
          "time_stamp_utc_ms": 1733962860000,
          "time": "2024-12-12T00:23:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1733963160000,
          "time": "2024-12-12T00:28:00Z",
          "energy": 40.51200000103563,
          "capacity": 486.14400001242757
      },
      {
          "time_stamp_utc_ms": 1733963460000,
          "time": "2024-12-12T00:33:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1733963760000,
          "time": "2024-12-12T00:38:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1733964060000,
          "time": "2024-12-12T00:43:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1733964360000,
          "time": "2024-12-12T00:48:00Z",
          "energy": 43.29600000102073,
          "capacity": 519.5520000122488
      },
      {
          "time_stamp_utc_ms": 1733964660000,
          "time": "2024-12-12T00:53:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1733964960000,
          "time": "2024-12-12T00:58:00Z",
          "energy": 40.51200000010431,
          "capacity": 486.14400000125175
      },
      {
          "time_stamp_utc_ms": 1733965260000,
          "time": "2024-12-12T01:03:00Z",
          "energy": 37.15199999883771,
          "capacity": 445.8239999860525
      },
      {
          "time_stamp_utc_ms": 1733965560000,
          "time": "2024-12-12T01:08:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1733965860000,
          "time": "2024-12-12T01:13:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1733966160000,
          "time": "2024-12-12T01:18:00Z",
          "energy": 37.53600000124425,
          "capacity": 450.432000014931
      },
      {
          "time_stamp_utc_ms": 1733966460000,
          "time": "2024-12-12T01:23:00Z",
          "energy": 37.343999998643994,
          "capacity": 448.12799998372793
      },
      {
          "time_stamp_utc_ms": 1733966760000,
          "time": "2024-12-12T01:28:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1733967060000,
          "time": "2024-12-12T01:33:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1733967360000,
          "time": "2024-12-12T01:38:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1733967660000,
          "time": "2024-12-12T01:43:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1733967960000,
          "time": "2024-12-12T01:48:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1733968260000,
          "time": "2024-12-12T01:53:00Z",
          "energy": 37.82399999909103,
          "capacity": 453.88799998909235
      },
      {
          "time_stamp_utc_ms": 1733968560000,
          "time": "2024-12-12T01:58:00Z",
          "energy": 38.01600000169128,
          "capacity": 456.1920000202954
      },
      {
          "time_stamp_utc_ms": 1733968860000,
          "time": "2024-12-12T02:03:00Z",
          "energy": 37.91999999899417,
          "capacity": 455.03999998793006
      },
      {
          "time_stamp_utc_ms": 1733969160000,
          "time": "2024-12-12T02:08:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1733969460000,
          "time": "2024-12-12T02:13:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1733969760000,
          "time": "2024-12-12T02:18:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1733970060000,
          "time": "2024-12-12T02:23:00Z",
          "energy": 37.63200000021607,
          "capacity": 451.58400000259286
      },
      {
          "time_stamp_utc_ms": 1733970360000,
          "time": "2024-12-12T02:28:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1733970660000,
          "time": "2024-12-12T02:33:00Z",
          "energy": 36.671999999322,
          "capacity": 440.06399999186397
      },
      {
          "time_stamp_utc_ms": 1733970960000,
          "time": "2024-12-12T02:38:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1733971260000,
          "time": "2024-12-12T02:43:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1733971560000,
          "time": "2024-12-12T02:48:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1733971860000,
          "time": "2024-12-12T02:53:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1733972160000,
          "time": "2024-12-12T02:58:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1733972460000,
          "time": "2024-12-12T03:03:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1733972760000,
          "time": "2024-12-12T03:08:00Z",
          "energy": 35.231999998912215,
          "capacity": 422.78399998694664
      },
      {
          "time_stamp_utc_ms": 1733973060000,
          "time": "2024-12-12T03:13:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1733973360000,
          "time": "2024-12-12T03:18:00Z",
          "energy": 35.23200000077486,
          "capacity": 422.7840000092983
      },
      {
          "time_stamp_utc_ms": 1733973660000,
          "time": "2024-12-12T03:23:00Z",
          "energy": 35.328000000678,
          "capacity": 423.93600000813603
      },
      {
          "time_stamp_utc_ms": 1733973960000,
          "time": "2024-12-12T03:28:00Z",
          "energy": 35.80799999926239,
          "capacity": 429.6959999911487
      },
      {
          "time_stamp_utc_ms": 1733974260000,
          "time": "2024-12-12T03:33:00Z",
          "energy": 33.98399999924004,
          "capacity": 407.80799999088055
      },
      {
          "time_stamp_utc_ms": 1733974560000,
          "time": "2024-12-12T03:38:00Z",
          "energy": 35.328000001609325,
          "capacity": 423.9360000193119
      },
      {
          "time_stamp_utc_ms": 1733974860000,
          "time": "2024-12-12T03:43:00Z",
          "energy": 33.79199999850243,
          "capacity": 405.5039999820292
      },
      {
          "time_stamp_utc_ms": 1733975160000,
          "time": "2024-12-12T03:48:00Z",
          "energy": 35.040000000968575,
          "capacity": 420.4800000116229
      },
      {
          "time_stamp_utc_ms": 1733975460000,
          "time": "2024-12-12T03:53:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1733975760000,
          "time": "2024-12-12T03:58:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1733976060000,
          "time": "2024-12-12T04:03:00Z",
          "energy": 34.65599999949336,
          "capacity": 415.8719999939204
      },
      {
          "time_stamp_utc_ms": 1733976360000,
          "time": "2024-12-12T04:08:00Z",
          "energy": 33.69599999953061,
          "capacity": 404.3519999943674
      },
      {
          "time_stamp_utc_ms": 1733976660000,
          "time": "2024-12-12T04:13:00Z",
          "energy": 35.23200000077486,
          "capacity": 422.7840000092983
      },
      {
          "time_stamp_utc_ms": 1733976960000,
          "time": "2024-12-12T04:18:00Z",
          "energy": 33.407999999821186,
          "capacity": 400.8959999978543
      },
      {
          "time_stamp_utc_ms": 1733977260000,
          "time": "2024-12-12T04:23:00Z",
          "energy": 34.94400000013411,
          "capacity": 419.3280000016093
      },
      {
          "time_stamp_utc_ms": 1733977560000,
          "time": "2024-12-12T04:28:00Z",
          "energy": 35.32799999974668,
          "capacity": 423.93599999696016
      },
      {
          "time_stamp_utc_ms": 1733977860000,
          "time": "2024-12-12T04:33:00Z",
          "energy": 33.98400000017136,
          "capacity": 407.8080000020564
      },
      {
          "time_stamp_utc_ms": 1733978160000,
          "time": "2024-12-12T04:38:00Z",
          "energy": 35.23199999984354,
          "capacity": 422.78399999812245
      },
      {
          "time_stamp_utc_ms": 1733978460000,
          "time": "2024-12-12T04:43:00Z",
          "energy": 33.79200000036508,
          "capacity": 405.504000004381
      },
      {
          "time_stamp_utc_ms": 1733978760000,
          "time": "2024-12-12T04:48:00Z",
          "energy": 33.98400000017136,
          "capacity": 407.8080000020564
      },
      {
          "time_stamp_utc_ms": 1733979060000,
          "time": "2024-12-12T04:53:00Z",
          "energy": 33.21599999908358,
          "capacity": 398.59199998900294
      },
      {
          "time_stamp_utc_ms": 1733979360000,
          "time": "2024-12-12T04:58:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1733979660000,
          "time": "2024-12-12T05:03:00Z",
          "energy": 34.36799999885261,
          "capacity": 412.4159999862314
      },
      {
          "time_stamp_utc_ms": 1733979960000,
          "time": "2024-12-12T05:08:00Z",
          "energy": 33.12000000104308,
          "capacity": 397.44000001251703
      },
      {
          "time_stamp_utc_ms": 1733980260000,
          "time": "2024-12-12T05:13:00Z",
          "energy": 34.56000000052154,
          "capacity": 414.7200000062585
      },
      {
          "time_stamp_utc_ms": 1733980560000,
          "time": "2024-12-12T05:18:00Z",
          "energy": 35.32799999974668,
          "capacity": 423.93599999696016
      },
      {
          "time_stamp_utc_ms": 1733980860000,
          "time": "2024-12-12T05:23:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1733981160000,
          "time": "2024-12-12T05:28:00Z",
          "energy": 35.03999999910593,
          "capacity": 420.4799999892712
      },
      {
          "time_stamp_utc_ms": 1733981460000,
          "time": "2024-12-12T05:33:00Z",
          "energy": 32.64000000059605,
          "capacity": 391.6800000071526
      },
      {
          "time_stamp_utc_ms": 1733981760000,
          "time": "2024-12-12T05:38:00Z",
          "energy": 34.94400000013411,
          "capacity": 419.3280000016093
      },
      {
          "time_stamp_utc_ms": 1733982060000,
          "time": "2024-12-12T05:43:00Z",
          "energy": 33.50399999972433,
          "capacity": 402.047999996692
      },
      {
          "time_stamp_utc_ms": 1733982360000,
          "time": "2024-12-12T05:48:00Z",
          "energy": 33.791999999433756,
          "capacity": 405.5039999932051
      },
      {
          "time_stamp_utc_ms": 1733982660000,
          "time": "2024-12-12T05:53:00Z",
          "energy": 35.23200000077486,
          "capacity": 422.7840000092983
      },
      {
          "time_stamp_utc_ms": 1733982960000,
          "time": "2024-12-12T05:58:00Z",
          "energy": 33.2160000000149,
          "capacity": 398.59200000017887
      },
      {
          "time_stamp_utc_ms": 1733983260000,
          "time": "2024-12-12T06:03:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1733983560000,
          "time": "2024-12-12T06:08:00Z",
          "energy": 33.59999999962747,
          "capacity": 403.1999999955297
      },
      {
          "time_stamp_utc_ms": 1733983860000,
          "time": "2024-12-12T06:13:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1733984160000,
          "time": "2024-12-12T06:18:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1733984460000,
          "time": "2024-12-12T06:23:00Z",
          "energy": 33.98400000017136,
          "capacity": 407.8080000020564
      },
      {
          "time_stamp_utc_ms": 1733984760000,
          "time": "2024-12-12T06:28:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1733985060000,
          "time": "2024-12-12T06:33:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1733985360000,
          "time": "2024-12-12T06:38:00Z",
          "energy": 35.04000000003725,
          "capacity": 420.48000000044703
      },
      {
          "time_stamp_utc_ms": 1733985660000,
          "time": "2024-12-12T06:43:00Z",
          "energy": 35.03999999910593,
          "capacity": 420.4799999892712
      },
      {
          "time_stamp_utc_ms": 1733985960000,
          "time": "2024-12-12T06:48:00Z",
          "energy": 33.79200000036508,
          "capacity": 405.504000004381
      },
      {
          "time_stamp_utc_ms": 1733986260000,
          "time": "2024-12-12T06:53:00Z",
          "energy": 35.040000000968575,
          "capacity": 420.4800000116229
      },
      {
          "time_stamp_utc_ms": 1733986560000,
          "time": "2024-12-12T06:58:00Z",
          "energy": 33.69599999953061,
          "capacity": 404.3519999943674
      },
      {
          "time_stamp_utc_ms": 1733986860000,
          "time": "2024-12-12T07:03:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1733987160000,
          "time": "2024-12-12T07:08:00Z",
          "energy": 33.98400000017136,
          "capacity": 407.8080000020564
      },
      {
          "time_stamp_utc_ms": 1733987460000,
          "time": "2024-12-12T07:13:00Z",
          "energy": 33.59999999962747,
          "capacity": 403.1999999955297
      },
      {
          "time_stamp_utc_ms": 1733987760000,
          "time": "2024-12-12T07:18:00Z",
          "energy": 35.04000000003725,
          "capacity": 420.48000000044703
      },
      {
          "time_stamp_utc_ms": 1733988060000,
          "time": "2024-12-12T07:23:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1733988360000,
          "time": "2024-12-12T07:28:00Z",
          "energy": 35.23200000077486,
          "capacity": 422.7840000092983
      },
      {
          "time_stamp_utc_ms": 1733988660000,
          "time": "2024-12-12T07:33:00Z",
          "energy": 34.36799999885261,
          "capacity": 412.4159999862314
      },
      {
          "time_stamp_utc_ms": 1733988960000,
          "time": "2024-12-12T07:38:00Z",
          "energy": 33.8879999993369,
          "capacity": 406.65599999204284
      },
      {
          "time_stamp_utc_ms": 1733989260000,
          "time": "2024-12-12T07:43:00Z",
          "energy": 34.75200000125915,
          "capacity": 417.0240000151098
      },
      {
          "time_stamp_utc_ms": 1733989560000,
          "time": "2024-12-12T07:48:00Z",
          "energy": 33.119999999180436,
          "capacity": 397.43999999016523
      },
      {
          "time_stamp_utc_ms": 1733989860000,
          "time": "2024-12-12T07:53:00Z",
          "energy": 36.00000000093132,
          "capacity": 432.00000001117587
      },
      {
          "time_stamp_utc_ms": 1733990160000,
          "time": "2024-12-12T07:58:00Z",
          "energy": 33.98400000017136,
          "capacity": 407.8080000020564
      },
      {
          "time_stamp_utc_ms": 1733990460000,
          "time": "2024-12-12T08:03:00Z",
          "energy": 34.847999999299645,
          "capacity": 418.1759999915958
      },
      {
          "time_stamp_utc_ms": 1733990760000,
          "time": "2024-12-12T08:08:00Z",
          "energy": 34.56000000052154,
          "capacity": 414.7200000062585
      },
      {
          "time_stamp_utc_ms": 1733991060000,
          "time": "2024-12-12T08:13:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1733991360000,
          "time": "2024-12-12T08:18:00Z",
          "energy": 35.23199999984354,
          "capacity": 422.78399999812245
      },
      {
          "time_stamp_utc_ms": 1733991660000,
          "time": "2024-12-12T08:23:00Z",
          "energy": 35.03999999910593,
          "capacity": 420.4799999892712
      },
      {
          "time_stamp_utc_ms": 1733991960000,
          "time": "2024-12-12T08:28:00Z",
          "energy": 35.80800000205636,
          "capacity": 429.6960000246764
      },
      {
          "time_stamp_utc_ms": 1733992260000,
          "time": "2024-12-12T08:33:00Z",
          "energy": 34.65599999949336,
          "capacity": 415.8719999939204
      },
      {
          "time_stamp_utc_ms": 1733992560000,
          "time": "2024-12-12T08:38:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1733992860000,
          "time": "2024-12-12T08:43:00Z",
          "energy": 35.13599999900907,
          "capacity": 421.63199998810893
      },
      {
          "time_stamp_utc_ms": 1733993160000,
          "time": "2024-12-12T08:48:00Z",
          "energy": 33.79200000036508,
          "capacity": 405.504000004381
      },
      {
          "time_stamp_utc_ms": 1733993460000,
          "time": "2024-12-12T08:53:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1733993760000,
          "time": "2024-12-12T08:58:00Z",
          "energy": 34.46400000154972,
          "capacity": 413.56800001859665
      },
      {
          "time_stamp_utc_ms": 1733994060000,
          "time": "2024-12-12T09:03:00Z",
          "energy": 34.27199999988079,
          "capacity": 411.26399999856955
      },
      {
          "time_stamp_utc_ms": 1733994360000,
          "time": "2024-12-12T09:08:00Z",
          "energy": 35.231999998912215,
          "capacity": 422.78399998694664
      },
      {
          "time_stamp_utc_ms": 1733994660000,
          "time": "2024-12-12T09:13:00Z",
          "energy": 33.024000000208616,
          "capacity": 396.28800000250345
      },
      {
          "time_stamp_utc_ms": 1733994960000,
          "time": "2024-12-12T09:18:00Z",
          "energy": 35.52000000048429,
          "capacity": 426.24000000581145
      },
      {
          "time_stamp_utc_ms": 1733995260000,
          "time": "2024-12-12T09:23:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1733995560000,
          "time": "2024-12-12T09:28:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1733995860000,
          "time": "2024-12-12T09:33:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1733996160000,
          "time": "2024-12-12T09:38:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1733996460000,
          "time": "2024-12-12T09:43:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1733996760000,
          "time": "2024-12-12T09:48:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1733997060000,
          "time": "2024-12-12T09:53:00Z",
          "energy": 38.783999999053776,
          "capacity": 465.4079999886454
      },
      {
          "time_stamp_utc_ms": 1733997360000,
          "time": "2024-12-12T09:58:00Z",
          "energy": 39.3600000012666,
          "capacity": 472.3200000151992
      },
      {
          "time_stamp_utc_ms": 1733997660000,
          "time": "2024-12-12T10:03:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1733997960000,
          "time": "2024-12-12T10:08:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1733998260000,
          "time": "2024-12-12T10:13:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1733998560000,
          "time": "2024-12-12T10:18:00Z",
          "energy": 41.18400000128895,
          "capacity": 494.20800001546746
      },
      {
          "time_stamp_utc_ms": 1733998860000,
          "time": "2024-12-12T10:23:00Z",
          "energy": 40.79999999888241,
          "capacity": 489.59999998658895
      },
      {
          "time_stamp_utc_ms": 1733999160000,
          "time": "2024-12-12T10:28:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1733999460000,
          "time": "2024-12-12T10:33:00Z",
          "energy": 41.760000000707805,
          "capacity": 501.1200000084937
      },
      {
          "time_stamp_utc_ms": 1733999760000,
          "time": "2024-12-12T10:38:00Z",
          "energy": 41.183999999426305,
          "capacity": 494.2079999931157
      },
      {
          "time_stamp_utc_ms": 1734000060000,
          "time": "2024-12-12T10:43:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1734000360000,
          "time": "2024-12-12T10:48:00Z",
          "energy": 42.14399999938905,
          "capacity": 505.72799999266863
      },
      {
          "time_stamp_utc_ms": 1734000660000,
          "time": "2024-12-12T10:53:00Z",
          "energy": 41.472000000067055,
          "capacity": 497.66400000080466
      },
      {
          "time_stamp_utc_ms": 1734000960000,
          "time": "2024-12-12T10:58:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1734001260000,
          "time": "2024-12-12T11:03:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1734001560000,
          "time": "2024-12-12T11:08:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1734001860000,
          "time": "2024-12-12T11:13:00Z",
          "energy": 43.29600000102073,
          "capacity": 519.5520000122488
      },
      {
          "time_stamp_utc_ms": 1734002160000,
          "time": "2024-12-12T11:18:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1734002460000,
          "time": "2024-12-12T11:23:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1734002760000,
          "time": "2024-12-12T11:28:00Z",
          "energy": 43.58399999886751,
          "capacity": 523.0079999864101
      },
      {
          "time_stamp_utc_ms": 1734003060000,
          "time": "2024-12-12T11:33:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1734003360000,
          "time": "2024-12-12T11:38:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1734003660000,
          "time": "2024-12-12T11:43:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1734003960000,
          "time": "2024-12-12T11:48:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1734004260000,
          "time": "2024-12-12T11:53:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1734004560000,
          "time": "2024-12-12T11:58:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1734004860000,
          "time": "2024-12-12T12:03:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1734005160000,
          "time": "2024-12-12T12:08:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1734005460000,
          "time": "2024-12-12T12:13:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1734005760000,
          "time": "2024-12-12T12:18:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1734006060000,
          "time": "2024-12-12T12:23:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1734006360000,
          "time": "2024-12-12T12:28:00Z",
          "energy": 44.544000000692904,
          "capacity": 534.528000008315
      },
      {
          "time_stamp_utc_ms": 1734006660000,
          "time": "2024-12-12T12:33:00Z",
          "energy": 45.119999999180436,
          "capacity": 541.4399999901652
      },
      {
          "time_stamp_utc_ms": 1734006960000,
          "time": "2024-12-12T12:38:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1734007260000,
          "time": "2024-12-12T12:43:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1734007560000,
          "time": "2024-12-12T12:48:00Z",
          "energy": 45.79200000036508,
          "capacity": 549.5040000043809
      },
      {
          "time_stamp_utc_ms": 1734007860000,
          "time": "2024-12-12T12:53:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1734008160000,
          "time": "2024-12-12T12:58:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1734008460000,
          "time": "2024-12-12T13:03:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1734008760000,
          "time": "2024-12-12T13:08:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1734009060000,
          "time": "2024-12-12T13:13:00Z",
          "energy": 46.27200000081211,
          "capacity": 555.2640000097454
      },
      {
          "time_stamp_utc_ms": 1734009360000,
          "time": "2024-12-12T13:18:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1734009660000,
          "time": "2024-12-12T13:23:00Z",
          "energy": 46.463999999687076,
          "capacity": 557.5679999962449
      },
      {
          "time_stamp_utc_ms": 1734009960000,
          "time": "2024-12-12T13:28:00Z",
          "energy": 43.872000000439584,
          "capacity": 526.464000005275
      },
      {
          "time_stamp_utc_ms": 1734010260000,
          "time": "2024-12-12T13:33:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1734010560000,
          "time": "2024-12-12T13:38:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1734010860000,
          "time": "2024-12-12T13:43:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1734011160000,
          "time": "2024-12-12T13:48:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1734011460000,
          "time": "2024-12-12T13:53:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1734011760000,
          "time": "2024-12-12T13:58:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1734012060000,
          "time": "2024-12-12T14:03:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1734012360000,
          "time": "2024-12-12T14:08:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1734012660000,
          "time": "2024-12-12T14:13:00Z",
          "energy": 43.0080000013113,
          "capacity": 516.0960000157356
      },
      {
          "time_stamp_utc_ms": 1734012960000,
          "time": "2024-12-12T14:18:00Z",
          "energy": 42.71999999880791,
          "capacity": 512.6399999856949
      },
      {
          "time_stamp_utc_ms": 1734013260000,
          "time": "2024-12-12T14:23:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1734013560000,
          "time": "2024-12-12T14:28:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1734013860000,
          "time": "2024-12-12T14:33:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1734014160000,
          "time": "2024-12-12T14:38:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1734014445000,
          "time": "2024-12-12T14:43:00Z",
          "energy": 38.87999999988824,
          "capacity": 491.1157894722725
      },
      {
          "time_stamp_utc_ms": 1734014760000,
          "time": "2024-12-12T14:48:00Z",
          "energy": 44.639999999664724,
          "capacity": 510.1714285675968
      },
      {
          "time_stamp_utc_ms": 1734015060000,
          "time": "2024-12-12T14:53:00Z",
          "energy": 41.12000000011176,
          "capacity": 493.4400000013411
      },
      {
          "time_stamp_utc_ms": 1734015360000,
          "time": "2024-12-12T14:58:00Z",
          "energy": 41.92000000085682,
          "capacity": 503.0400000102818
      },
      {
          "time_stamp_utc_ms": 1734015660000,
          "time": "2024-12-12T15:03:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1734015930000,
          "time": "2024-12-12T15:08:00Z",
          "energy": 37.200000000186265,
          "capacity": 496.0000000024835
      },
      {
          "time_stamp_utc_ms": 1734016240000,
          "time": "2024-12-12T15:13:00Z",
          "energy": 43.75999999977648,
          "capacity": 508.1806607922057
      },
      {
          "time_stamp_utc_ms": 1734016560000,
          "time": "2024-12-12T15:18:00Z",
          "energy": 43.200000000186265,
          "capacity": 485.9999855181672
      },
      {
          "time_stamp_utc_ms": 1734016740000,
          "time": "2024-12-12T15:23:00Z",
          "energy": 24.16000000014901,
          "capacity": 483.2000000029802
      },
      {
          "time_stamp_utc_ms": 1734017160000,
          "time": "2024-12-12T15:28:00Z",
          "energy": 57.12000000011176,
          "capacity": 489.60000000095783
      },
      {
          "time_stamp_utc_ms": 1734017440000,
          "time": "2024-12-12T15:33:00Z",
          "energy": 37.59999999962747,
          "capacity": 483.4285878892614
      },
      {
          "time_stamp_utc_ms": 1734017760000,
          "time": "2024-12-12T15:38:00Z",
          "energy": 43.80800000112504,
          "capacity": 492.83998532488056
      },
      {
          "time_stamp_utc_ms": 1734018030000,
          "time": "2024-12-12T15:43:00Z",
          "energy": 36.43199999909848,
          "capacity": 485.75999998797977
      },
      {
          "time_stamp_utc_ms": 1734018390000,
          "time": "2024-12-12T15:48:00Z",
          "energy": 48.95999999996275,
          "capacity": 489.5999999996274
      },
      {
          "time_stamp_utc_ms": 1734018600000,
          "time": "2024-12-12T15:53:00Z",
          "energy": 27.59999999962747,
          "capacity": 473.1428571364709
      },
      {
          "time_stamp_utc_ms": 1734018900000,
          "time": "2024-12-12T15:58:00Z",
          "energy": 39.040000000968575,
          "capacity": 468.4800000116229
      },
      {
          "time_stamp_utc_ms": 1734019380000,
          "time": "2024-12-12T16:03:00Z",
          "energy": 62.71999999973923,
          "capacity": 470.3999999980442
      },
      {
          "time_stamp_utc_ms": 1734019650000,
          "time": "2024-12-12T16:08:00Z",
          "energy": 34.080000000074506,
          "capacity": 454.4000000009934
      },
      {
          "time_stamp_utc_ms": 1734019860000,
          "time": "2024-12-12T16:13:00Z",
          "energy": 27.648000000044703,
          "capacity": 473.9657142864806
      },
      {
          "time_stamp_utc_ms": 1734020180000,
          "time": "2024-12-12T16:18:00Z",
          "energy": 39.71200000029057,
          "capacity": 446.7599866887838
      },
      {
          "time_stamp_utc_ms": 1734020500000,
          "time": "2024-12-12T16:23:00Z",
          "energy": 42.55999999959022,
          "capacity": 478.7999857260384
      },
      {
          "time_stamp_utc_ms": 1734020850000,
          "time": "2024-12-12T16:28:00Z",
          "energy": 43.67999999970198,
          "capacity": 449.27998775502647
      },
      {
          "time_stamp_utc_ms": 1734021080000,
          "time": "2024-12-12T16:33:00Z",
          "energy": 29.12000000011176,
          "capacity": 455.7913137990638
      },
      {
          "time_stamp_utc_ms": 1734021570000,
          "time": "2024-12-12T16:43:00Z",
          "energy": 63.04000000003725,
          "capacity": 463.1510023800608
      },
      {
          "time_stamp_utc_ms": 1734021940000,
          "time": "2024-12-12T16:48:00Z",
          "energy": 46.87999999988824,
          "capacity": 456.1297414853781
      },
      {
          "time_stamp_utc_ms": 1734022170000,
          "time": "2024-12-12T16:53:00Z",
          "energy": 29.199999999254942,
          "capacity": 457.0434877246564
      },
      {
          "time_stamp_utc_ms": 1734022560000,
          "time": "2024-12-12T16:58:00Z",
          "energy": 49.440000001341105,
          "capacity": 456.3692307816102
      },
      {
          "time_stamp_utc_ms": 1734022890000,
          "time": "2024-12-12T17:03:00Z",
          "energy": 40.79999999888241,
          "capacity": 445.09090907871723
      },
      {
          "time_stamp_utc_ms": 1734023140000,
          "time": "2024-12-12T17:08:00Z",
          "energy": 30.320000000298023,
          "capacity": 436.6080166595656
      },
      {
          "time_stamp_utc_ms": 1734023550000,
          "time": "2024-12-12T17:13:00Z",
          "energy": 52.0,
          "capacity": 456.5853552333237
      },
      {
          "time_stamp_utc_ms": 1734023880000,
          "time": "2024-12-12T17:18:00Z",
          "energy": 40.80000000074506,
          "capacity": 445.09090909903705
      },
      {
          "time_stamp_utc_ms": 1734024040000,
          "time": "2024-12-12T17:23:00Z",
          "energy": 19.200000000186265,
          "capacity": 431.9999871295881
      },
      {
          "time_stamp_utc_ms": 1734024360000,
          "time": "2024-12-12T17:28:00Z",
          "energy": 40.07999999914318,
          "capacity": 450.89998655249406
      },
      {
          "time_stamp_utc_ms": 1734024675000,
          "time": "2024-12-12T17:33:00Z",
          "energy": 37.919999999925494,
          "capacity": 433.37142857057705
      },
      {
          "time_stamp_utc_ms": 1734024945000,
          "time": "2024-12-12T17:38:00Z",
          "energy": 33.72000000067055,
          "capacity": 449.6000000089407
      },
      {
          "time_stamp_utc_ms": 1734025300000,
          "time": "2024-12-12T17:43:00Z",
          "energy": 42.200000000186265,
          "capacity": 427.9436734700266
      },
      {
          "time_stamp_utc_ms": 1734025540000,
          "time": "2024-12-12T17:48:00Z",
          "energy": 28.959999999031425,
          "capacity": 434.39999998547137
      },
      {
          "time_stamp_utc_ms": 1734025860000,
          "time": "2024-12-12T17:53:00Z",
          "energy": 39.360000000335276,
          "capacity": 442.79998680730387
      },
      {
          "time_stamp_utc_ms": 1734026220000,
          "time": "2024-12-12T17:58:00Z",
          "energy": 42.0,
          "capacity": 420.0
      },
      {
          "time_stamp_utc_ms": 1734026430000,
          "time": "2024-12-12T18:03:00Z",
          "energy": 26.16000000014901,
          "capacity": 448.45714285969734
      },
      {
          "time_stamp_utc_ms": 1734026760000,
          "time": "2024-12-12T18:08:00Z",
          "energy": 39.04000000003725,
          "capacity": 425.8909090913155
      },
      {
          "time_stamp_utc_ms": 1734027060000,
          "time": "2024-12-12T18:13:00Z",
          "energy": 37.75999999884516,
          "capacity": 453.1199999861419
      },
      {
          "time_stamp_utc_ms": 1734027345000,
          "time": "2024-12-12T18:18:00Z",
          "energy": 34.68000000156462,
          "capacity": 438.0631579145005
      },
      {
          "time_stamp_utc_ms": 1734027720000,
          "time": "2024-12-12T18:23:00Z",
          "energy": 44.99999999906868,
          "capacity": 431.9999999910593
      },
      {
          "time_stamp_utc_ms": 1734027870000,
          "time": "2024-12-12T18:28:00Z",
          "energy": 18.480000000447035,
          "capacity": 443.5200000107289
      },
      {
          "time_stamp_utc_ms": 1734028290000,
          "time": "2024-12-12T18:33:00Z",
          "energy": 49.919999999925494,
          "capacity": 427.88571428507566
      },
      {
          "time_stamp_utc_ms": 1734028620000,
          "time": "2024-12-12T18:38:00Z",
          "energy": 40.39999999944121,
          "capacity": 440.7272727211768
      },
      {
          "time_stamp_utc_ms": 1734029520000,
          "time": "2024-12-12T18:53:00Z",
          "energy": 109.2800000011921,
          "capacity": 437.1200000047684
      },
      {
          "time_stamp_utc_ms": 1734029640000,
          "time": "2024-12-12T18:58:00Z",
          "energy": 14.239999999292195,
          "capacity": 427.19999997876585
      },
      {
          "time_stamp_utc_ms": 1734030060000,
          "time": "2024-12-12T19:03:00Z",
          "energy": 50.72000000067055,
          "capacity": 434.7428571486047
      },
      {
          "time_stamp_utc_ms": 1734030390000,
          "time": "2024-12-12T19:08:00Z",
          "energy": 40.239999999292195,
          "capacity": 438.9818181740967
      },
      {
          "time_stamp_utc_ms": 1734030680000,
          "time": "2024-12-12T19:13:00Z",
          "energy": 34.80000000074506,
          "capacity": 431.99998580279066
      },
      {
          "time_stamp_utc_ms": 1734030900000,
          "time": "2024-12-12T19:18:00Z",
          "energy": 27.679999999701977,
          "capacity": 452.9454447232497
      },
      {
          "time_stamp_utc_ms": 1734031275000,
          "time": "2024-12-12T19:23:00Z",
          "energy": 44.080000000074506,
          "capacity": 423.1680000007152
      },
      {
          "time_stamp_utc_ms": 1734031560000,
          "time": "2024-12-12T19:28:00Z",
          "energy": 36.04799999948591,
          "capacity": 455.343157888243
      },
      {
          "time_stamp_utc_ms": 1734031860000,
          "time": "2024-12-12T19:33:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1734032160000,
          "time": "2024-12-12T19:38:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1734032460000,
          "time": "2024-12-12T19:43:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1734032760000,
          "time": "2024-12-12T19:48:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1734033060000,
          "time": "2024-12-12T19:53:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1734033360000,
          "time": "2024-12-12T19:58:00Z",
          "energy": 37.343999998643994,
          "capacity": 448.12799998372793
      },
      {
          "time_stamp_utc_ms": 1734033660000,
          "time": "2024-12-12T20:03:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1734033900000,
          "time": "2024-12-12T20:08:00Z",
          "energy": 31.872000000439584,
          "capacity": 478.08000000659376
      },
      {
          "time_stamp_utc_ms": 1734034300000,
          "time": "2024-12-12T20:13:00Z",
          "energy": 52.95999999996275,
          "capacity": 476.64001136364817
      },
      {
          "time_stamp_utc_ms": 1734034560000,
          "time": "2024-12-12T20:18:00Z",
          "energy": 34.72000000067055,
          "capacity": 480.7384439143651
      },
      {
          "time_stamp_utc_ms": 1734034860000,
          "time": "2024-12-12T20:23:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1734035130000,
          "time": "2024-12-12T20:28:00Z",
          "energy": 36.40000000037253,
          "capacity": 485.33333333830035
      },
      {
          "time_stamp_utc_ms": 1734035440000,
          "time": "2024-12-12T20:33:00Z",
          "energy": 41.6800000006333,
          "capacity": 484.02582134938655
      },
      {
          "time_stamp_utc_ms": 1734035700000,
          "time": "2024-12-12T20:38:00Z",
          "energy": 34.15999999921769,
          "capacity": 472.9845980248117
      },
      {
          "time_stamp_utc_ms": 1734036090000,
          "time": "2024-12-12T20:43:00Z",
          "energy": 53.28000000026077,
          "capacity": 491.8153846177918
      },
      {
          "time_stamp_utc_ms": 1734036420000,
          "time": "2024-12-12T20:48:00Z",
          "energy": 43.919999999925494,
          "capacity": 479.1272727264599
      },
      {
          "time_stamp_utc_ms": 1734036700000,
          "time": "2024-12-12T20:53:00Z",
          "energy": 40.3199999993667,
          "capacity": 518.4000176484569
      },
      {
          "time_stamp_utc_ms": 1734037080000,
          "time": "2024-12-12T20:58:00Z",
          "energy": 52.80000000074506,
          "capacity": 500.2105137692185
      },
      {
          "time_stamp_utc_ms": 1734037290000,
          "time": "2024-12-12T21:03:00Z",
          "energy": 29.759999999776483,
          "capacity": 510.1714285675968
      },
      {
          "time_stamp_utc_ms": 1734037560000,
          "time": "2024-12-12T21:08:00Z",
          "energy": 39.07200000062585,
          "capacity": 520.9600000083447
      },
      {
          "time_stamp_utc_ms": 1734037860000,
          "time": "2024-12-12T21:13:00Z",
          "energy": 41.183999999426305,
          "capacity": 494.2079999931157
      },
      {
          "time_stamp_utc_ms": 1734038160000,
          "time": "2024-12-12T21:18:00Z",
          "energy": 42.62399999983609,
          "capacity": 511.4879999980331
      },
      {
          "time_stamp_utc_ms": 1734038460000,
          "time": "2024-12-12T21:23:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1734038740000,
          "time": "2024-12-12T21:28:00Z",
          "energy": 38.62400000076741,
          "capacity": 496.59430263805353
      },
      {
          "time_stamp_utc_ms": 1734039040000,
          "time": "2024-12-12T21:33:00Z",
          "energy": 41.44000000040978,
          "capacity": 497.2800000049174
      },
      {
          "time_stamp_utc_ms": 1734039270000,
          "time": "2024-12-12T21:38:00Z",
          "energy": 30.959999999031425,
          "capacity": 484.5913143792373
      },
      {
          "time_stamp_utc_ms": 1734039630000,
          "time": "2024-12-12T21:43:00Z",
          "energy": 50.40000000037253,
          "capacity": 504.00000000372523
      },
      {
          "time_stamp_utc_ms": 1734039930000,
          "time": "2024-12-12T21:48:00Z",
          "energy": 41.04000000003725,
          "capacity": 492.48000000044703
      },
      {
          "time_stamp_utc_ms": 1734040230000,
          "time": "2024-12-12T21:53:00Z",
          "energy": 41.639999999664724,
          "capacity": 499.67999999597674
      },
      {
          "time_stamp_utc_ms": 1734040530000,
          "time": "2024-12-12T21:58:00Z",
          "energy": 41.87999999988824,
          "capacity": 502.55999999865895
      },
      {
          "time_stamp_utc_ms": 1734040830000,
          "time": "2024-12-12T22:03:00Z",
          "energy": 41.28000000026077,
          "capacity": 495.36000000312924
      },
      {
          "time_stamp_utc_ms": 1734041250000,
          "time": "2024-12-12T22:08:00Z",
          "energy": 58.55999999959022,
          "capacity": 501.9428571393447
      },
      {
          "time_stamp_utc_ms": 1734041580000,
          "time": "2024-12-12T22:13:00Z",
          "energy": 45.12000000011176,
          "capacity": 492.21818181940097
      },
      {
          "time_stamp_utc_ms": 1734041700000,
          "time": "2024-12-12T22:18:00Z",
          "energy": 17.040000000037253,
          "capacity": 511.2000000011176
      },
      {
          "time_stamp_utc_ms": 1734042060000,
          "time": "2024-12-12T22:23:00Z",
          "energy": 49.919999999925494,
          "capacity": 499.1999999992549
      },
      {
          "time_stamp_utc_ms": 1734042380000,
          "time": "2024-12-12T22:28:00Z",
          "energy": 44.08000000100583,
          "capacity": 495.89998523234436
      },
      {
          "time_stamp_utc_ms": 1734042600000,
          "time": "2024-12-12T22:33:00Z",
          "energy": 31.839999998919666,
          "capacity": 521.0181705077389
      },
      {
          "time_stamp_utc_ms": 1734042930000,
          "time": "2024-12-12T22:38:00Z",
          "energy": 44.56000000052154,
          "capacity": 486.10909091478044
      },
      {
          "time_stamp_utc_ms": 1734043280000,
          "time": "2024-12-12T22:43:00Z",
          "energy": 50.15999999921769,
          "capacity": 515.9314145053665
      },
      {
          "time_stamp_utc_ms": 1734043590000,
          "time": "2024-12-12T22:48:00Z",
          "energy": 42.60000000055879,
          "capacity": 494.70969264493846
      },
      {
          "time_stamp_utc_ms": 1734043800000,
          "time": "2024-12-12T22:53:00Z",
          "energy": 28.919999999925494,
          "capacity": 495.77142857015133
      },
      {
          "time_stamp_utc_ms": 1734044220000,
          "time": "2024-12-12T22:58:00Z",
          "energy": 58.72000000067055,
          "capacity": 503.3142857200333
      },
      {
          "time_stamp_utc_ms": 1734044475000,
          "time": "2024-12-12T23:03:00Z",
          "energy": 35.239999999292195,
          "capacity": 497.5058823429486
      },
      {
          "time_stamp_utc_ms": 1734044730000,
          "time": "2024-12-12T23:08:00Z",
          "energy": 36.360000000335276,
          "capacity": 513.3176470635569
      },
      {
          "time_stamp_utc_ms": 1734044970000,
          "time": "2024-12-12T23:13:00Z",
          "energy": 33.12000000011176,
          "capacity": 496.8000000016764
      },
      {
          "time_stamp_utc_ms": 1734045360000,
          "time": "2024-12-12T23:18:00Z",
          "energy": 54.59999999962747,
          "capacity": 503.99999999656126
      },
      {
          "time_stamp_utc_ms": 1734045660000,
          "time": "2024-12-12T23:23:00Z",
          "energy": 41.480000000447035,
          "capacity": 497.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734046260000,
          "time": "2024-12-12T23:33:00Z",
          "energy": 83.56000000052154,
          "capacity": 501.36000000312924
      },
      {
          "time_stamp_utc_ms": 1734046620000,
          "time": "2024-12-12T23:38:00Z",
          "energy": 49.07999999914318,
          "capacity": 490.7999999914318
      },
      {
          "time_stamp_utc_ms": 1734046770000,
          "time": "2024-12-12T23:43:00Z",
          "energy": 21.119999999180436,
          "capacity": 506.8799999803305
      },
      {
          "time_stamp_utc_ms": 1734047180000,
          "time": "2024-12-12T23:48:00Z",
          "energy": 56.95999999996275,
          "capacity": 500.13657373217524
      },
      {
          "time_stamp_utc_ms": 1734047400000,
          "time": "2024-12-12T23:53:00Z",
          "energy": 29.920000000856817,
          "capacity": 489.5999894022267
      },
      {
          "time_stamp_utc_ms": 1734047745000,
          "time": "2024-12-12T23:58:00Z",
          "energy": 48.72000000067055,
          "capacity": 508.38260870264924
      },
      {
          "time_stamp_utc_ms": 1734048090000,
          "time": "2024-12-13T00:03:00Z",
          "energy": 48.71999999880791,
          "capacity": 508.38260868321294
      },
      {
          "time_stamp_utc_ms": 1734048360000,
          "time": "2024-12-13T00:08:00Z",
          "energy": 37.824000000953674,
          "capacity": 504.3200000127157
      },
      {
          "time_stamp_utc_ms": 1734048660000,
          "time": "2024-12-13T00:13:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1734048960000,
          "time": "2024-12-13T00:18:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1734049260000,
          "time": "2024-12-13T00:23:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1734049560000,
          "time": "2024-12-13T00:28:00Z",
          "energy": 40.51200000103563,
          "capacity": 486.14400001242757
      },
      {
          "time_stamp_utc_ms": 1734049860000,
          "time": "2024-12-13T00:33:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1734050160000,
          "time": "2024-12-13T00:38:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1734050460000,
          "time": "2024-12-13T00:43:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1734050760000,
          "time": "2024-12-13T00:48:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1734051060000,
          "time": "2024-12-13T00:53:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1734051360000,
          "time": "2024-12-13T00:58:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1734051660000,
          "time": "2024-12-13T01:03:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1734051960000,
          "time": "2024-12-13T01:08:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1734052260000,
          "time": "2024-12-13T01:13:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1734052560000,
          "time": "2024-12-13T01:18:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1734052860000,
          "time": "2024-12-13T01:23:00Z",
          "energy": 38.97599999886006,
          "capacity": 467.7119999863208
      },
      {
          "time_stamp_utc_ms": 1734053160000,
          "time": "2024-12-13T01:28:00Z",
          "energy": 37.440000001341105,
          "capacity": 449.2800000160933
      },
      {
          "time_stamp_utc_ms": 1734053460000,
          "time": "2024-12-13T01:33:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1734053760000,
          "time": "2024-12-13T01:38:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1734054060000,
          "time": "2024-12-13T01:43:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1734054360000,
          "time": "2024-12-13T01:48:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1734054660000,
          "time": "2024-12-13T01:53:00Z",
          "energy": 37.152000000700355,
          "capacity": 445.8240000084043
      },
      {
          "time_stamp_utc_ms": 1734054960000,
          "time": "2024-12-13T01:58:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1734055260000,
          "time": "2024-12-13T02:03:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1734055560000,
          "time": "2024-12-13T02:08:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1734055860000,
          "time": "2024-12-13T02:13:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1734056160000,
          "time": "2024-12-13T02:18:00Z",
          "energy": 36.09599999990314,
          "capacity": 433.1519999988377
      },
      {
          "time_stamp_utc_ms": 1734056460000,
          "time": "2024-12-13T02:23:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1734056760000,
          "time": "2024-12-13T02:28:00Z",
          "energy": 37.152000000700355,
          "capacity": 445.8240000084043
      },
      {
          "time_stamp_utc_ms": 1734057060000,
          "time": "2024-12-13T02:33:00Z",
          "energy": 36.575999999418855,
          "capacity": 438.91199999302626
      },
      {
          "time_stamp_utc_ms": 1734057360000,
          "time": "2024-12-13T02:38:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1734057660000,
          "time": "2024-12-13T02:43:00Z",
          "energy": 35.80799999926239,
          "capacity": 429.6959999911487
      },
      {
          "time_stamp_utc_ms": 1734057960000,
          "time": "2024-12-13T02:48:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1734058260000,
          "time": "2024-12-13T02:53:00Z",
          "energy": 35.61599999945611,
          "capacity": 427.3919999934733
      },
      {
          "time_stamp_utc_ms": 1734058560000,
          "time": "2024-12-13T02:58:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1734058860000,
          "time": "2024-12-13T03:03:00Z",
          "energy": 35.90400000102818,
          "capacity": 430.84800001233816
      },
      {
          "time_stamp_utc_ms": 1734059160000,
          "time": "2024-12-13T03:08:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1734059460000,
          "time": "2024-12-13T03:13:00Z",
          "energy": 36.38399999961257,
          "capacity": 436.60799999535084
      },
      {
          "time_stamp_utc_ms": 1734059760000,
          "time": "2024-12-13T03:18:00Z",
          "energy": 35.61600000038743,
          "capacity": 427.39200000464916
      },
      {
          "time_stamp_utc_ms": 1734060060000,
          "time": "2024-12-13T03:23:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1734060360000,
          "time": "2024-12-13T03:28:00Z",
          "energy": 36.575999999418855,
          "capacity": 438.91199999302626
      },
      {
          "time_stamp_utc_ms": 1734060660000,
          "time": "2024-12-13T03:33:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1734060960000,
          "time": "2024-12-13T03:38:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1734061260000,
          "time": "2024-12-13T03:43:00Z",
          "energy": 35.42399999964982,
          "capacity": 425.0879999957979
      },
      {
          "time_stamp_utc_ms": 1734061560000,
          "time": "2024-12-13T03:48:00Z",
          "energy": 35.80800000112504,
          "capacity": 429.69600001350045
      },
      {
          "time_stamp_utc_ms": 1734061860000,
          "time": "2024-12-13T03:53:00Z",
          "energy": 35.71199999842793,
          "capacity": 428.5439999811352
      },
      {
          "time_stamp_utc_ms": 1734062160000,
          "time": "2024-12-13T03:58:00Z",
          "energy": 34.65600000042468,
          "capacity": 415.8720000050962
      },
      {
          "time_stamp_utc_ms": 1734062460000,
          "time": "2024-12-13T04:03:00Z",
          "energy": 36.28800000064075,
          "capacity": 435.456000007689
      },
      {
          "time_stamp_utc_ms": 1734062760000,
          "time": "2024-12-13T04:08:00Z",
          "energy": 34.368000000715256,
          "capacity": 412.41600000858307
      },
      {
          "time_stamp_utc_ms": 1734063060000,
          "time": "2024-12-13T04:13:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1734063360000,
          "time": "2024-12-13T04:18:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1734063660000,
          "time": "2024-12-13T04:23:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1734063960000,
          "time": "2024-12-13T04:28:00Z",
          "energy": 35.99999999906868,
          "capacity": 431.99999998882413
      },
      {
          "time_stamp_utc_ms": 1734064260000,
          "time": "2024-12-13T04:33:00Z",
          "energy": 34.65600000042468,
          "capacity": 415.8720000050962
      },
      {
          "time_stamp_utc_ms": 1734064560000,
          "time": "2024-12-13T04:38:00Z",
          "energy": 36.768000001087785,
          "capacity": 441.2160000130535
      },
      {
          "time_stamp_utc_ms": 1734064820000,
          "time": "2024-12-13T04:43:00Z",
          "energy": 30.240000000223517,
          "capacity": 418.70767695268114
      },
      {
          "time_stamp_utc_ms": 1734065140000,
          "time": "2024-12-13T04:48:00Z",
          "energy": 38.399999998509884,
          "capacity": 431.9999871086333
      },
      {
          "time_stamp_utc_ms": 1734065480000,
          "time": "2024-12-13T04:53:00Z",
          "energy": 41.760000000707805,
          "capacity": 442.16471829223366
      },
      {
          "time_stamp_utc_ms": 1734065760000,
          "time": "2024-12-13T04:58:00Z",
          "energy": 34.55999999959022,
          "capacity": 444.34287227181653
      },
      {
          "time_stamp_utc_ms": 1734066000000,
          "time": "2024-12-13T05:03:00Z",
          "energy": 30.080000000074506,
          "capacity": 451.2000000011176
      },
      {
          "time_stamp_utc_ms": 1734066480000,
          "time": "2024-12-13T05:08:00Z",
          "energy": 58.72000000067055,
          "capacity": 440.40000000502914
      },
      {
          "time_stamp_utc_ms": 1734066680000,
          "time": "2024-12-13T05:13:00Z",
          "energy": 25.91999999899417,
          "capacity": 466.5600111055526
      },
      {
          "time_stamp_utc_ms": 1734066990000,
          "time": "2024-12-13T05:18:00Z",
          "energy": 36.96000000089407,
          "capacity": 429.21291644036125
      },
      {
          "time_stamp_utc_ms": 1734067230000,
          "time": "2024-12-13T05:23:00Z",
          "energy": 28.799999999813735,
          "capacity": 431.99999999720603
      },
      {
          "time_stamp_utc_ms": 1734067650000,
          "time": "2024-12-13T05:28:00Z",
          "energy": 50.39999999944121,
          "capacity": 431.9999999952103
      },
      {
          "time_stamp_utc_ms": 1734067875000,
          "time": "2024-12-13T05:33:00Z",
          "energy": 26.40000000037253,
          "capacity": 422.40000000596046
      },
      {
          "time_stamp_utc_ms": 1734068140000,
          "time": "2024-12-13T05:38:00Z",
          "energy": 32.16000000014901,
          "capacity": 436.8905817624518
      },
      {
          "time_stamp_utc_ms": 1734068460000,
          "time": "2024-12-13T05:43:00Z",
          "energy": 38.40000000037253,
          "capacity": 431.9999871295881
      },
      {
          "time_stamp_utc_ms": 1734068790000,
          "time": "2024-12-13T05:48:00Z",
          "energy": 39.35999999940395,
          "capacity": 429.38181817531586
      },
      {
          "time_stamp_utc_ms": 1734069090000,
          "time": "2024-12-13T05:53:00Z",
          "energy": 37.200000000186265,
          "capacity": 446.4000000022352
      },
      {
          "time_stamp_utc_ms": 1734069360000,
          "time": "2024-12-13T05:58:00Z",
          "energy": 30.959999999962747,
          "capacity": 412.79999999950326
      },
      {
          "time_stamp_utc_ms": 1734069630000,
          "time": "2024-12-13T06:03:00Z",
          "energy": 33.24000000022352,
          "capacity": 443.20000000298023
      },
      {
          "time_stamp_utc_ms": 1734069990000,
          "time": "2024-12-13T06:08:00Z",
          "energy": 43.080000000074506,
          "capacity": 430.80000000074506
      },
      {
          "time_stamp_utc_ms": 1734070290000,
          "time": "2024-12-13T06:13:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1734070590000,
          "time": "2024-12-13T06:18:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1734070840000,
          "time": "2024-12-13T06:23:00Z",
          "energy": 29.43999999947846,
          "capacity": 423.9360161643654
      },
      {
          "time_stamp_utc_ms": 1734071160000,
          "time": "2024-12-13T06:28:00Z",
          "energy": 39.872000000439584,
          "capacity": 448.559986636816
      },
      {
          "time_stamp_utc_ms": 1734071490000,
          "time": "2024-12-13T06:33:00Z",
          "energy": 38.5679999999702,
          "capacity": 420.7418181814931
      },
      {
          "time_stamp_utc_ms": 1734071790000,
          "time": "2024-12-13T06:38:00Z",
          "energy": 37.799999999813735,
          "capacity": 453.5999999977649
      },
      {
          "time_stamp_utc_ms": 1734072040000,
          "time": "2024-12-13T06:43:00Z",
          "energy": 29.360000000335276,
          "capacity": 422.78401613275827
      },
      {
          "time_stamp_utc_ms": 1734072390000,
          "time": "2024-12-13T06:48:00Z",
          "energy": 42.87999999988824,
          "capacity": 441.0514165525816
      },
      {
          "time_stamp_utc_ms": 1734072750000,
          "time": "2024-12-13T06:53:00Z",
          "energy": 44.40000000037253,
          "capacity": 444.00000000372523
      },
      {
          "time_stamp_utc_ms": 1734072900000,
          "time": "2024-12-13T06:58:00Z",
          "energy": 17.999999999068677,
          "capacity": 431.9999999776483
      },
      {
          "time_stamp_utc_ms": 1734073230000,
          "time": "2024-12-13T07:03:00Z",
          "energy": 40.55999999959022,
          "capacity": 442.47272726825696
      },
      {
          "time_stamp_utc_ms": 1734073590000,
          "time": "2024-12-13T07:08:00Z",
          "energy": 42.720000001601875,
          "capacity": 427.2000000160187
      },
      {
          "time_stamp_utc_ms": 1734073860000,
          "time": "2024-12-13T07:13:00Z",
          "energy": 32.59199999924749,
          "capacity": 434.5599999899665
      },
      {
          "time_stamp_utc_ms": 1734074460000,
          "time": "2024-12-13T07:23:00Z",
          "energy": 72.52799999993294,
          "capacity": 435.16799999959767
      },
      {
          "time_stamp_utc_ms": 1734074700000,
          "time": "2024-12-13T07:28:00Z",
          "energy": 29.520000000484288,
          "capacity": 442.8000000072643
      },
      {
          "time_stamp_utc_ms": 1734075060000,
          "time": "2024-12-13T07:33:00Z",
          "energy": 43.43999999947846,
          "capacity": 434.39999999478454
      },
      {
          "time_stamp_utc_ms": 1734075360000,
          "time": "2024-12-13T07:38:00Z",
          "energy": 35.75999999977648,
          "capacity": 429.11999999731785
      },
      {
          "time_stamp_utc_ms": 1734075720000,
          "time": "2024-12-13T07:43:00Z",
          "energy": 44.16000000014901,
          "capacity": 441.60000000149006
      },
      {
          "time_stamp_utc_ms": 1734075960000,
          "time": "2024-12-13T07:48:00Z",
          "energy": 29.28000000026077,
          "capacity": 439.20000000391155
      },
      {
          "time_stamp_utc_ms": 1734076260000,
          "time": "2024-12-13T07:53:00Z",
          "energy": 37.63200000021607,
          "capacity": 451.58400000259286
      },
      {
          "time_stamp_utc_ms": 1734076500000,
          "time": "2024-12-13T07:58:00Z",
          "energy": 28.847999999299645,
          "capacity": 432.7199999894947
      },
      {
          "time_stamp_utc_ms": 1734076840000,
          "time": "2024-12-13T08:03:00Z",
          "energy": 40.71999999973923,
          "capacity": 431.1529532672241
      },
      {
          "time_stamp_utc_ms": 1734077180000,
          "time": "2024-12-13T08:08:00Z",
          "energy": 42.08000000100583,
          "capacity": 445.5529536845443
      },
      {
          "time_stamp_utc_ms": 1734077430000,
          "time": "2024-12-13T08:13:00Z",
          "energy": 30.119999999180436,
          "capacity": 433.7280165336091
      },
      {
          "time_stamp_utc_ms": 1734077760000,
          "time": "2024-12-13T08:18:00Z",
          "energy": 41.08000000100583,
          "capacity": 448.14545455642724
      },
      {
          "time_stamp_utc_ms": 1734078000000,
          "time": "2024-12-13T08:23:00Z",
          "energy": 28.16000000014901,
          "capacity": 422.4000000022352
      },
      {
          "time_stamp_utc_ms": 1734078340000,
          "time": "2024-12-13T08:28:00Z",
          "energy": 42.07999999914318,
          "capacity": 445.5529536648222
      },
      {
          "time_stamp_utc_ms": 1734078640000,
          "time": "2024-12-13T08:33:00Z",
          "energy": 35.83999999985099,
          "capacity": 430.0799999982119
      },
      {
          "time_stamp_utc_ms": 1734078900000,
          "time": "2024-12-13T08:38:00Z",
          "energy": 31.519999999552965,
          "capacity": 436.4307532163949
      },
      {
          "time_stamp_utc_ms": 1734079350000,
          "time": "2024-12-13T08:43:00Z",
          "energy": 55.20000000111759,
          "capacity": 441.6000000089407
      },
      {
          "time_stamp_utc_ms": 1734079920000,
          "time": "2024-12-13T08:53:00Z",
          "energy": 69.59999999962747,
          "capacity": 439.5789473660682
      },
      {
          "time_stamp_utc_ms": 1734080145000,
          "time": "2024-12-13T08:58:00Z",
          "energy": 27.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1734080460000,
          "time": "2024-12-13T09:03:00Z",
          "energy": 37.3199999993667,
          "capacity": 426.514285707048
      },
      {
          "time_stamp_utc_ms": 1734080760000,
          "time": "2024-12-13T09:08:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734081060000,
          "time": "2024-12-13T09:13:00Z",
          "energy": 35.23199999984354,
          "capacity": 422.78399999812245
      },
      {
          "time_stamp_utc_ms": 1734081360000,
          "time": "2024-12-13T09:18:00Z",
          "energy": 36.096000000834465,
          "capacity": 433.1520000100136
      },
      {
          "time_stamp_utc_ms": 1734081660000,
          "time": "2024-12-13T09:23:00Z",
          "energy": 35.903999999165535,
          "capacity": 430.8479999899864
      },
      {
          "time_stamp_utc_ms": 1734081960000,
          "time": "2024-12-13T09:28:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1734082260000,
          "time": "2024-12-13T09:33:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1734082560000,
          "time": "2024-12-13T09:38:00Z",
          "energy": 39.52000000048429,
          "capacity": 474.24000000581145
      },
      {
          "time_stamp_utc_ms": 1734082840000,
          "time": "2024-12-13T09:43:00Z",
          "energy": 38.56000000052154,
          "capacity": 495.77144546400893
      },
      {
          "time_stamp_utc_ms": 1734083100000,
          "time": "2024-12-13T09:48:00Z",
          "energy": 35.43999999947846,
          "capacity": 490.70767430142075
      },
      {
          "time_stamp_utc_ms": 1734083520000,
          "time": "2024-12-13T09:53:00Z",
          "energy": 57.360000000335276,
          "capacity": 491.6571428600166
      },
      {
          "time_stamp_utc_ms": 1734083790000,
          "time": "2024-12-13T09:58:00Z",
          "energy": 37.55999999959022,
          "capacity": 500.7999999945363
      },
      {
          "time_stamp_utc_ms": 1734084030000,
          "time": "2024-12-13T10:03:00Z",
          "energy": 32.760000000707805,
          "capacity": 491.4000000106171
      },
      {
          "time_stamp_utc_ms": 1734084240000,
          "time": "2024-12-13T10:08:00Z",
          "energy": 31.91999999899417,
          "capacity": 547.1999999827572
      },
      {
          "time_stamp_utc_ms": 1734084630000,
          "time": "2024-12-13T10:13:00Z",
          "energy": 54.95999999996275,
          "capacity": 507.32307692273304
      },
      {
          "time_stamp_utc_ms": 1734085000000,
          "time": "2024-12-13T10:18:00Z",
          "energy": 54.80000000074506,
          "capacity": 533.1892029393803
      },
      {
          "time_stamp_utc_ms": 1734085260000,
          "time": "2024-12-13T10:23:00Z",
          "energy": 36.87999999895692,
          "capacity": 510.64613510132256
      },
      {
          "time_stamp_utc_ms": 1734085580000,
          "time": "2024-12-13T10:28:00Z",
          "energy": 46.64000000152737,
          "capacity": 524.6999843799048
      },
      {
          "time_stamp_utc_ms": 1734085860000,
          "time": "2024-12-13T10:33:00Z",
          "energy": 41.43999999947846,
          "capacity": 532.800018140355
      },
      {
          "time_stamp_utc_ms": 1734086070000,
          "time": "2024-12-13T10:38:00Z",
          "energy": 31.679999999701977,
          "capacity": 543.0857142806053
      },
      {
          "time_stamp_utc_ms": 1734086460000,
          "time": "2024-12-13T10:43:00Z",
          "energy": 58.08000000100583,
          "capacity": 536.1230769323615
      },
      {
          "time_stamp_utc_ms": 1734086820000,
          "time": "2024-12-13T10:48:00Z",
          "energy": 51.839999998919666,
          "capacity": 518.3999999891967
      },
      {
          "time_stamp_utc_ms": 1734087060000,
          "time": "2024-12-13T10:53:00Z",
          "energy": 34.56000000052154,
          "capacity": 518.4000000078231
      },
      {
          "time_stamp_utc_ms": 1734087360000,
          "time": "2024-12-13T10:58:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1734087630000,
          "time": "2024-12-13T11:03:00Z",
          "energy": 38.16000000014901,
          "capacity": 508.8000000019868
      },
      {
          "time_stamp_utc_ms": 1734087960000,
          "time": "2024-12-13T11:08:00Z",
          "energy": 47.60000000055879,
          "capacity": 519.2727272788231
      },
      {
          "time_stamp_utc_ms": 1734088280000,
          "time": "2024-12-13T11:13:00Z",
          "energy": 46.55999999959022,
          "capacity": 523.799984384934
      },
      {
          "time_stamp_utc_ms": 1734088560000,
          "time": "2024-12-13T11:18:00Z",
          "energy": 40.0,
          "capacity": 514.2857318021819
      },
      {
          "time_stamp_utc_ms": 1734088900000,
          "time": "2024-12-13T11:23:00Z",
          "energy": 51.200000000186265,
          "capacity": 542.1176622667866
      },
      {
          "time_stamp_utc_ms": 1734089160000,
          "time": "2024-12-13T11:28:00Z",
          "energy": 37.50400000065565,
          "capacity": 519.2861348079302
      },
      {
          "time_stamp_utc_ms": 1734089430000,
          "time": "2024-12-13T11:33:00Z",
          "energy": 40.175999999046326,
          "capacity": 535.6799999872843
      },
      {
          "time_stamp_utc_ms": 1734089730000,
          "time": "2024-12-13T11:38:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1734090120000,
          "time": "2024-12-13T11:43:00Z",
          "energy": 57.83999999985099,
          "capacity": 533.9076923063168
      },
      {
          "time_stamp_utc_ms": 1734090330000,
          "time": "2024-12-13T11:48:00Z",
          "energy": 31.43999999947846,
          "capacity": 538.9714285624879
      },
      {
          "time_stamp_utc_ms": 1734090720000,
          "time": "2024-12-13T11:53:00Z",
          "energy": 56.880000000819564,
          "capacity": 525.0461538537191
      },
      {
          "time_stamp_utc_ms": 1734090990000,
          "time": "2024-12-13T11:58:00Z",
          "energy": 42.119999999180436,
          "capacity": 561.5999999890724
      },
      {
          "time_stamp_utc_ms": 1734091300000,
          "time": "2024-12-13T12:03:00Z",
          "energy": 45.880000000819564,
          "capacity": 532.8000164004108
      },
      {
          "time_stamp_utc_ms": 1734091590000,
          "time": "2024-12-13T12:08:00Z",
          "energy": 44.71999999973923,
          "capacity": 555.1448093268543
      },
      {
          "time_stamp_utc_ms": 1734091840000,
          "time": "2024-12-13T12:13:00Z",
          "energy": 37.519999999552965,
          "capacity": 540.288020603915
      },
      {
          "time_stamp_utc_ms": 1734092160000,
          "time": "2024-12-13T12:18:00Z",
          "energy": 47.39200000092387,
          "capacity": 533.1599841209878
      },
      {
          "time_stamp_utc_ms": 1734092480000,
          "time": "2024-12-13T12:23:00Z",
          "energy": 49.5679999999702,
          "capacity": 557.6399833806981
      },
      {
          "time_stamp_utc_ms": 1734092720000,
          "time": "2024-12-13T12:28:00Z",
          "energy": 35.83999999985099,
          "capacity": 537.5999999977648
      },
      {
          "time_stamp_utc_ms": 1734093090000,
          "time": "2024-12-13T12:33:00Z",
          "energy": 57.919999999925494,
          "capacity": 563.5459604706077
      },
      {
          "time_stamp_utc_ms": 1734093420000,
          "time": "2024-12-13T12:38:00Z",
          "energy": 50.87999999988824,
          "capacity": 555.0545454533263
      },
      {
          "time_stamp_utc_ms": 1734093660000,
          "time": "2024-12-13T12:43:00Z",
          "energy": 38.208000000566244,
          "capacity": 573.1200000084937
      },
      {
          "time_stamp_utc_ms": 1734093945000,
          "time": "2024-12-13T12:48:00Z",
          "energy": 43.99199999962002,
          "capacity": 555.6884210478319
      },
      {
          "time_stamp_utc_ms": 1734094260000,
          "time": "2024-12-13T12:53:00Z",
          "energy": 48.360000000335276,
          "capacity": 552.685714289546
      },
      {
          "time_stamp_utc_ms": 1734094560000,
          "time": "2024-12-13T12:58:00Z",
          "energy": 46.463999999687076,
          "capacity": 557.5679999962449
      },
      {
          "time_stamp_utc_ms": 1734094860000,
          "time": "2024-12-13T13:03:00Z",
          "energy": 46.080000000074506,
          "capacity": 552.9600000008941
      },
      {
          "time_stamp_utc_ms": 1734095160000,
          "time": "2024-12-13T13:08:00Z",
          "energy": 46.94400000013411,
          "capacity": 563.3280000016093
      },
      {
          "time_stamp_utc_ms": 1734095460000,
          "time": "2024-12-13T13:13:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1734095760000,
          "time": "2024-12-13T13:18:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1734096060000,
          "time": "2024-12-13T13:23:00Z",
          "energy": 46.175999999046326,
          "capacity": 554.111999988556
      },
      {
          "time_stamp_utc_ms": 1734096360000,
          "time": "2024-12-13T13:28:00Z",
          "energy": 45.79200000036508,
          "capacity": 549.5040000043809
      },
      {
          "time_stamp_utc_ms": 1734096660000,
          "time": "2024-12-13T13:33:00Z",
          "energy": 44.735999998636544,
          "capacity": 536.8319999836385
      },
      {
          "time_stamp_utc_ms": 1734096960000,
          "time": "2024-12-13T13:38:00Z",
          "energy": 45.216000000946224,
          "capacity": 542.5920000113547
      },
      {
          "time_stamp_utc_ms": 1734097260000,
          "time": "2024-12-13T13:43:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1734097560000,
          "time": "2024-12-13T13:48:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1734097860000,
          "time": "2024-12-13T13:53:00Z",
          "energy": 43.48799999896437,
          "capacity": 521.8559999875724
      },
      {
          "time_stamp_utc_ms": 1734098160000,
          "time": "2024-12-13T13:58:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1734098460000,
          "time": "2024-12-13T14:03:00Z",
          "energy": 44.544000000692904,
          "capacity": 534.528000008315
      },
      {
          "time_stamp_utc_ms": 1734098760000,
          "time": "2024-12-13T14:08:00Z",
          "energy": 43.00799999944866,
          "capacity": 516.0959999933839
      },
      {
          "time_stamp_utc_ms": 1734099060000,
          "time": "2024-12-13T14:13:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1734099360000,
          "time": "2024-12-13T14:18:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1734099630000,
          "time": "2024-12-13T14:23:00Z",
          "energy": 39.33600000012666,
          "capacity": 524.4800000016888
      },
      {
          "time_stamp_utc_ms": 1734099960000,
          "time": "2024-12-13T14:28:00Z",
          "energy": 46.67999999970198,
          "capacity": 509.23636363311255
      },
      {
          "time_stamp_utc_ms": 1734100300000,
          "time": "2024-12-13T14:33:00Z",
          "energy": 48.32000000029802,
          "capacity": 511.62354376557414
      },
      {
          "time_stamp_utc_ms": 1734100860000,
          "time": "2024-12-13T14:43:00Z",
          "energy": 77.5359999993816,
          "capacity": 498.44573125869937
      },
      {
          "time_stamp_utc_ms": 1734101130000,
          "time": "2024-12-13T14:48:00Z",
          "energy": 38.30400000046939,
          "capacity": 510.7200000062585
      },
      {
          "time_stamp_utc_ms": 1734101760000,
          "time": "2024-12-13T14:58:00Z",
          "energy": 85.9199999999255,
          "capacity": 490.9714285710028
      },
      {
          "time_stamp_utc_ms": 1734102040000,
          "time": "2024-12-13T15:03:00Z",
          "energy": 37.28000000026077,
          "capacity": 479.31430204298636
      },
      {
          "time_stamp_utc_ms": 1734102360000,
          "time": "2024-12-13T15:08:00Z",
          "energy": 41.919999999925494,
          "capacity": 471.59998594438696
      },
      {
          "time_stamp_utc_ms": 1734102675000,
          "time": "2024-12-13T15:13:00Z",
          "energy": 42.83999999985099,
          "capacity": 489.59999999829694
      },
      {
          "time_stamp_utc_ms": 1734102960000,
          "time": "2024-12-13T15:18:00Z",
          "energy": 37.12800000049174,
          "capacity": 468.98526316410613
      },
      {
          "time_stamp_utc_ms": 1734103260000,
          "time": "2024-12-13T15:23:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1734103560000,
          "time": "2024-12-13T15:28:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1734103860000,
          "time": "2024-12-13T15:33:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1734104160000,
          "time": "2024-12-13T15:38:00Z",
          "energy": 40.99200000055134,
          "capacity": 491.9040000066161
      },
      {
          "time_stamp_utc_ms": 1734104460000,
          "time": "2024-12-13T15:43:00Z",
          "energy": 38.30399999860674,
          "capacity": 459.6479999832809
      },
      {
          "time_stamp_utc_ms": 1734104760000,
          "time": "2024-12-13T15:48:00Z",
          "energy": 41.4720000019297,
          "capacity": 497.6640000231564
      },
      {
          "time_stamp_utc_ms": 1734105180000,
          "time": "2024-12-13T15:53:00Z",
          "energy": 56.25599999912083,
          "capacity": 482.19428570674995
      },
      {
          "time_stamp_utc_ms": 1734105360000,
          "time": "2024-12-13T15:58:00Z",
          "energy": 23.520000000484288,
          "capacity": 470.40000000968575
      },
      {
          "time_stamp_utc_ms": 1734105660000,
          "time": "2024-12-13T16:03:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1734105960000,
          "time": "2024-12-13T16:08:00Z",
          "energy": 36.86399999912828,
          "capacity": 442.3679999895394
      },
      {
          "time_stamp_utc_ms": 1734106260000,
          "time": "2024-12-13T16:13:00Z",
          "energy": 37.92000000085682,
          "capacity": 455.04000001028186
      },
      {
          "time_stamp_utc_ms": 1734106560000,
          "time": "2024-12-13T16:18:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1734106860000,
          "time": "2024-12-13T16:23:00Z",
          "energy": 35.903999999165535,
          "capacity": 430.8479999899864
      },
      {
          "time_stamp_utc_ms": 1734107160000,
          "time": "2024-12-13T16:28:00Z",
          "energy": 35.328000001609325,
          "capacity": 423.9360000193119
      },
      {
          "time_stamp_utc_ms": 1734107460000,
          "time": "2024-12-13T16:33:00Z",
          "energy": 35.32799999881536,
          "capacity": 423.93599998578435
      },
      {
          "time_stamp_utc_ms": 1734107670000,
          "time": "2024-12-13T16:38:00Z",
          "energy": 25.39199999999255,
          "capacity": 435.2914285713008
      },
      {
          "time_stamp_utc_ms": 1734108075000,
          "time": "2024-12-13T16:43:00Z",
          "energy": 48.83999999985099,
          "capacity": 434.13333333200876
      },
      {
          "time_stamp_utc_ms": 1734108390000,
          "time": "2024-12-13T16:48:00Z",
          "energy": 37.080000000074506,
          "capacity": 423.77142857228006
      },
      {
          "time_stamp_utc_ms": 1734108660000,
          "time": "2024-12-13T16:53:00Z",
          "energy": 30.864000000059605,
          "capacity": 411.5200000007947
      },
      {
          "time_stamp_utc_ms": 1734108960000,
          "time": "2024-12-13T16:58:00Z",
          "energy": 33.98400000017136,
          "capacity": 407.8080000020564
      },
      {
          "time_stamp_utc_ms": 1734109260000,
          "time": "2024-12-13T17:03:00Z",
          "energy": 35.42399999964982,
          "capacity": 425.0879999957979
      },
      {
          "time_stamp_utc_ms": 1734109500000,
          "time": "2024-12-13T17:08:00Z",
          "energy": 28.928000000305474,
          "capacity": 433.9200000045821
      },
      {
          "time_stamp_utc_ms": 1734109830000,
          "time": "2024-12-13T17:13:00Z",
          "energy": 38.32000000029802,
          "capacity": 418.0363636396148
      },
      {
          "time_stamp_utc_ms": 1734110180000,
          "time": "2024-12-13T17:18:00Z",
          "energy": 40.40000000037253,
          "capacity": 415.5428458240449
      },
      {
          "time_stamp_utc_ms": 1734110490000,
          "time": "2024-12-13T17:23:00Z",
          "energy": 34.959999999031425,
          "capacity": 405.98710925260616
      },
      {
          "time_stamp_utc_ms": 1734110780000,
          "time": "2024-12-13T17:28:00Z",
          "energy": 34.32000000029802,
          "capacity": 426.0413653035373
      },
      {
          "time_stamp_utc_ms": 1734111000000,
          "time": "2024-12-13T17:33:00Z",
          "energy": 23.520000000484288,
          "capacity": 384.87271893876044
      },
      {
          "time_stamp_utc_ms": 1734111390000,
          "time": "2024-12-13T17:38:00Z",
          "energy": 43.19999999925494,
          "capacity": 398.7692307623533
      },
      {
          "time_stamp_utc_ms": 1734111690000,
          "time": "2024-12-13T17:43:00Z",
          "energy": 32.16000000014901,
          "capacity": 385.92000000178814
      },
      {
          "time_stamp_utc_ms": 1734111960000,
          "time": "2024-12-13T17:48:00Z",
          "energy": 31.104000000283122,
          "capacity": 414.72000000377494
      },
      {
          "time_stamp_utc_ms": 1734112260000,
          "time": "2024-12-13T17:53:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1734112560000,
          "time": "2024-12-13T17:58:00Z",
          "energy": 33.50399999972433,
          "capacity": 402.047999996692
      },
      {
          "time_stamp_utc_ms": 1734112860000,
          "time": "2024-12-13T18:03:00Z",
          "energy": 34.56000000052154,
          "capacity": 414.7200000062585
      },
      {
          "time_stamp_utc_ms": 1734113160000,
          "time": "2024-12-13T18:08:00Z",
          "energy": 33.119999999180436,
          "capacity": 397.43999999016523
      },
      {
          "time_stamp_utc_ms": 1734113460000,
          "time": "2024-12-13T18:13:00Z",
          "energy": 35.99999999906868,
          "capacity": 431.99999998882413
      },
      {
          "time_stamp_utc_ms": 1734113760000,
          "time": "2024-12-13T18:18:00Z",
          "energy": 33.984000001102686,
          "capacity": 407.80800001323223
      },
      {
          "time_stamp_utc_ms": 1734114060000,
          "time": "2024-12-13T18:23:00Z",
          "energy": 32.54399999976158,
          "capacity": 390.527999997139
      },
      {
          "time_stamp_utc_ms": 1734114360000,
          "time": "2024-12-13T18:28:00Z",
          "energy": 34.4640000006184,
          "capacity": 413.5680000074208
      },
      {
          "time_stamp_utc_ms": 1734114660000,
          "time": "2024-12-13T18:33:00Z",
          "energy": 31.77599999960512,
          "capacity": 381.31199999526143
      },
      {
          "time_stamp_utc_ms": 1734114840000,
          "time": "2024-12-13T18:38:00Z",
          "energy": 18.33600000012666,
          "capacity": 366.7200000025332
      },
      {
          "time_stamp_utc_ms": 1734115290000,
          "time": "2024-12-13T18:43:00Z",
          "energy": 50.639999999664724,
          "capacity": 405.11999999731773
      },
      {
          "time_stamp_utc_ms": 1734115560000,
          "time": "2024-12-13T18:48:00Z",
          "energy": 28.848000000230968,
          "capacity": 384.6400000030796
      },
      {
          "time_stamp_utc_ms": 1734115860000,
          "time": "2024-12-13T18:53:00Z",
          "energy": 33.4320000000298,
          "capacity": 401.1840000003576
      },
      {
          "time_stamp_utc_ms": 1734116160000,
          "time": "2024-12-13T18:58:00Z",
          "energy": 34.919999999925494,
          "capacity": 419.03999999910593
      },
      {
          "time_stamp_utc_ms": 1734116520000,
          "time": "2024-12-13T19:03:00Z",
          "energy": 50.39999999944121,
          "capacity": 503.99999999441206
      },
      {
          "time_stamp_utc_ms": 1734116670000,
          "time": "2024-12-13T19:08:00Z",
          "energy": 20.40000000037253,
          "capacity": 489.6000000089407
      },
      {
          "time_stamp_utc_ms": 1734117060000,
          "time": "2024-12-13T19:13:00Z",
          "energy": 44.64000000059605,
          "capacity": 412.06153846704046
      },
      {
          "time_stamp_utc_ms": 1734117360000,
          "time": "2024-12-13T19:18:00Z",
          "energy": 34.41599999926984,
          "capacity": 412.9919999912381
      },
      {
          "time_stamp_utc_ms": 1734117620000,
          "time": "2024-12-13T19:23:00Z",
          "energy": 27.904000000096858,
          "capacity": 386.36306275270533
      },
      {
          "time_stamp_utc_ms": 1734118050000,
          "time": "2024-12-13T19:28:00Z",
          "energy": 53.360000000335276,
          "capacity": 446.73489363163475
      },
      {
          "time_stamp_utc_ms": 1734118260000,
          "time": "2024-12-13T19:33:00Z",
          "energy": 24.87999999988824,
          "capacity": 426.5142857123698
      },
      {
          "time_stamp_utc_ms": 1734118590000,
          "time": "2024-12-13T19:38:00Z",
          "energy": 43.40000000037253,
          "capacity": 473.45454545860946
      },
      {
          "time_stamp_utc_ms": 1734118980000,
          "time": "2024-12-13T19:43:00Z",
          "energy": 52.919999999925494,
          "capacity": 488.49230769162
      },
      {
          "time_stamp_utc_ms": 1734119130000,
          "time": "2024-12-13T19:48:00Z",
          "energy": 20.15999999921769,
          "capacity": 483.83999998122454
      },
      {
          "time_stamp_utc_ms": 1734119460000,
          "time": "2024-12-13T19:53:00Z",
          "energy": 43.6800000006333,
          "capacity": 476.50909091599965
      },
      {
          "time_stamp_utc_ms": 1734119730000,
          "time": "2024-12-13T19:58:00Z",
          "energy": 32.15999999921769,
          "capacity": 428.7999999895692
      },
      {
          "time_stamp_utc_ms": 1734120040000,
          "time": "2024-12-13T20:03:00Z",
          "energy": 36.480000000447035,
          "capacity": 423.63872271530005
      },
      {
          "time_stamp_utc_ms": 1734120400000,
          "time": "2024-12-13T20:08:00Z",
          "energy": 45.92000000085682,
          "capacity": 459.2000000085681
      },
      {
          "time_stamp_utc_ms": 1734120600000,
          "time": "2024-12-13T20:13:00Z",
          "energy": 25.119999999180436,
          "capacity": 452.16001076558257
      },
      {
          "time_stamp_utc_ms": 1734121020000,
          "time": "2024-12-13T20:18:00Z",
          "energy": 50.87999999988824,
          "capacity": 436.1142857133278
      },
      {
          "time_stamp_utc_ms": 1734121320000,
          "time": "2024-12-13T20:23:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1734121440000,
          "time": "2024-12-13T20:28:00Z",
          "energy": 15.360000000335276,
          "capacity": 460.8000000100583
      },
      {
          "time_stamp_utc_ms": 1734121860000,
          "time": "2024-12-13T20:33:00Z",
          "energy": 58.17600000090897,
          "capacity": 498.6514285792197
      },
      {
          "time_stamp_utc_ms": 1734122040000,
          "time": "2024-12-13T20:38:00Z",
          "energy": 23.4239999987185,
          "capacity": 468.47999997437
      },
      {
          "time_stamp_utc_ms": 1734122580000,
          "time": "2024-12-13T20:43:00Z",
          "energy": 72.95999999996275,
          "capacity": 486.39999999975157
      },
      {
          "time_stamp_utc_ms": 1734122740000,
          "time": "2024-12-13T20:48:00Z",
          "energy": 20.480000000447035,
          "capacity": 460.79998627714855
      },
      {
          "time_stamp_utc_ms": 1734122970000,
          "time": "2024-12-13T20:53:00Z",
          "energy": 30.15999999921769,
          "capacity": 472.06957499211666
      },
      {
          "time_stamp_utc_ms": 1734123375000,
          "time": "2024-12-13T20:58:00Z",
          "energy": 52.6800000006333,
          "capacity": 468.26666667229597
      },
      {
          "time_stamp_utc_ms": 1734123660000,
          "time": "2024-12-13T21:03:00Z",
          "energy": 37.41599999926984,
          "capacity": 472.62315788551376
      },
      {
          "time_stamp_utc_ms": 1734124020000,
          "time": "2024-12-13T21:08:00Z",
          "energy": 49.10400000028312,
          "capacity": 491.04000000283116
      },
      {
          "time_stamp_utc_ms": 1734124320000,
          "time": "2024-12-13T21:13:00Z",
          "energy": 40.56000000052154,
          "capacity": 486.7200000062585
      },
      {
          "time_stamp_utc_ms": 1734124470000,
          "time": "2024-12-13T21:18:00Z",
          "energy": 20.639999999664724,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1734124800000,
          "time": "2024-12-13T21:23:00Z",
          "energy": 45.12000000011176,
          "capacity": 492.21818181940097
      },
      {
          "time_stamp_utc_ms": 1734125160000,
          "time": "2024-12-13T21:28:00Z",
          "energy": 48.95999999996275,
          "capacity": 489.5999999996274
      },
      {
          "time_stamp_utc_ms": 1734125520000,
          "time": "2024-12-13T21:33:00Z",
          "energy": 48.639999999664724,
          "capacity": 486.3999999966472
      },
      {
          "time_stamp_utc_ms": 1734125760000,
          "time": "2024-12-13T21:38:00Z",
          "energy": 32.28800000064075,
          "capacity": 484.32000000961125
      },
      {
          "time_stamp_utc_ms": 1734126060000,
          "time": "2024-12-13T21:43:00Z",
          "energy": 41.75999999884516,
          "capacity": 501.119999986142
      },
      {
          "time_stamp_utc_ms": 1734126360000,
          "time": "2024-12-13T21:48:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1734126660000,
          "time": "2024-12-13T21:53:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1734126960000,
          "time": "2024-12-13T21:58:00Z",
          "energy": 40.511999999172986,
          "capacity": 486.1439999900758
      },
      {
          "time_stamp_utc_ms": 1734127260000,
          "time": "2024-12-13T22:03:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1734127560000,
          "time": "2024-12-13T22:08:00Z",
          "energy": 41.28000000119209,
          "capacity": 495.3600000143052
      },
      {
          "time_stamp_utc_ms": 1734127860000,
          "time": "2024-12-13T22:13:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1734128160000,
          "time": "2024-12-13T22:18:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1734128460000,
          "time": "2024-12-13T22:23:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1734128760000,
          "time": "2024-12-13T22:28:00Z",
          "energy": 40.704000000841916,
          "capacity": 488.448000010103
      },
      {
          "time_stamp_utc_ms": 1734129060000,
          "time": "2024-12-13T22:33:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1734129360000,
          "time": "2024-12-13T22:38:00Z",
          "energy": 40.60799999907613,
          "capacity": 487.29599998891354
      },
      {
          "time_stamp_utc_ms": 1734129660000,
          "time": "2024-12-13T22:43:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1734129960000,
          "time": "2024-12-13T22:48:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1734130260000,
          "time": "2024-12-13T22:53:00Z",
          "energy": 40.99200000055134,
          "capacity": 491.9040000066161
      },
      {
          "time_stamp_utc_ms": 1734130560000,
          "time": "2024-12-13T22:58:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1734130800000,
          "time": "2024-12-13T23:03:00Z",
          "energy": 33.50400000065565,
          "capacity": 502.56000000983477
      },
      {
          "time_stamp_utc_ms": 1734131280000,
          "time": "2024-12-13T23:08:00Z",
          "energy": 65.75999999977648,
          "capacity": 493.1999999983236
      },
      {
          "time_stamp_utc_ms": 1734131490000,
          "time": "2024-12-13T23:13:00Z",
          "energy": 28.320000000298023,
          "capacity": 485.48571429082324
      },
      {
          "time_stamp_utc_ms": 1734131740000,
          "time": "2024-12-13T23:18:00Z",
          "energy": 33.27999999932945,
          "capacity": 479.2320182715947
      },
      {
          "time_stamp_utc_ms": 1734132075000,
          "time": "2024-12-13T23:23:00Z",
          "energy": 45.56000000052154,
          "capacity": 489.5999860677261
      },
      {
          "time_stamp_utc_ms": 1734132360000,
          "time": "2024-12-13T23:28:00Z",
          "energy": 37.79999999888241,
          "capacity": 477.4736841964094
      },
      {
          "time_stamp_utc_ms": 1734132690000,
          "time": "2024-12-13T23:33:00Z",
          "energy": 45.3600000012666,
          "capacity": 494.8363636501811
      },
      {
          "time_stamp_utc_ms": 1734132930000,
          "time": "2024-12-13T23:38:00Z",
          "energy": 32.16000000014901,
          "capacity": 482.4000000022352
      },
      {
          "time_stamp_utc_ms": 1734133290000,
          "time": "2024-12-13T23:43:00Z",
          "energy": 48.12000000011176,
          "capacity": 481.2000000011176
      },
      {
          "time_stamp_utc_ms": 1734133680000,
          "time": "2024-12-13T23:48:00Z",
          "energy": 53.39999999944121,
          "capacity": 492.9230769179189
      },
      {
          "time_stamp_utc_ms": 1734133860000,
          "time": "2024-12-13T23:53:00Z",
          "energy": 23.519999999552965,
          "capacity": 470.3999999910593
      },
      {
          "time_stamp_utc_ms": 1734134145000,
          "time": "2024-12-13T23:58:00Z",
          "energy": 39.12000000011176,
          "capacity": 494.1473684224643
      },
      {
          "time_stamp_utc_ms": 1734134460000,
          "time": "2024-12-14T00:03:00Z",
          "energy": 41.52000000048429,
          "capacity": 474.5142857198204
      },
      {
          "time_stamp_utc_ms": 1734134700000,
          "time": "2024-12-14T00:08:00Z",
          "energy": 31.919999999925494,
          "capacity": 478.7999999988824
      },
      {
          "time_stamp_utc_ms": 1734135060000,
          "time": "2024-12-14T00:13:00Z",
          "energy": 48.959999999031425,
          "capacity": 489.5999999903142
      },
      {
          "time_stamp_utc_ms": 1734135240000,
          "time": "2024-12-14T00:18:00Z",
          "energy": 23.760000000707805,
          "capacity": 475.2000000141561
      },
      {
          "time_stamp_utc_ms": 1734135620000,
          "time": "2024-12-14T00:23:00Z",
          "energy": 52.47999999951571,
          "capacity": 497.1789348862862
      },
      {
          "time_stamp_utc_ms": 1734136220000,
          "time": "2024-12-14T00:33:00Z",
          "energy": 82.08000000100583,
          "capacity": 492.48000000603497
      },
      {
          "time_stamp_utc_ms": 1734136470000,
          "time": "2024-12-14T00:38:00Z",
          "energy": 35.119999999180436,
          "capacity": 505.7280192801911
      },
      {
          "time_stamp_utc_ms": 1734136920000,
          "time": "2024-12-14T00:43:00Z",
          "energy": 60.95999999996275,
          "capacity": 487.679999999702
      },
      {
          "time_stamp_utc_ms": 1734137130000,
          "time": "2024-12-14T00:48:00Z",
          "energy": 29.28000000026077,
          "capacity": 501.94285714732746
      },
      {
          "time_stamp_utc_ms": 1734137460000,
          "time": "2024-12-14T00:53:00Z",
          "energy": 43.55999999959022,
          "capacity": 475.19999999552965
      },
      {
          "time_stamp_utc_ms": 1734137730000,
          "time": "2024-12-14T00:58:00Z",
          "energy": 34.919999999925494,
          "capacity": 465.5999999990065
      },
      {
          "time_stamp_utc_ms": 1734138060000,
          "time": "2024-12-14T01:03:00Z",
          "energy": 40.80000000074506,
          "capacity": 445.09090909903705
      },
      {
          "time_stamp_utc_ms": 1734138360000,
          "time": "2024-12-14T01:08:00Z",
          "energy": 36.287999998778105,
          "capacity": 435.45599998533726
      },
      {
          "time_stamp_utc_ms": 1734138660000,
          "time": "2024-12-14T01:13:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1734138960000,
          "time": "2024-12-14T01:18:00Z",
          "energy": 35.61600000131875,
          "capacity": 427.39200001582503
      },
      {
          "time_stamp_utc_ms": 1734139260000,
          "time": "2024-12-14T01:23:00Z",
          "energy": 36.623999998904765,
          "capacity": 439.48799998685723
      },
      {
          "time_stamp_utc_ms": 1734139560000,
          "time": "2024-12-14T01:28:00Z",
          "energy": 36.91200000047684,
          "capacity": 442.94400000572205
      },
      {
          "time_stamp_utc_ms": 1734139860000,
          "time": "2024-12-14T01:33:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1734140160000,
          "time": "2024-12-14T01:38:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734140400000,
          "time": "2024-12-14T01:43:00Z",
          "energy": 27.16800000052899,
          "capacity": 407.52000000793487
      },
      {
          "time_stamp_utc_ms": 1734140780000,
          "time": "2024-12-14T01:48:00Z",
          "energy": 46.87999999895692,
          "capacity": 444.1263046334905
      },
      {
          "time_stamp_utc_ms": 1734141020000,
          "time": "2024-12-14T01:53:00Z",
          "energy": 28.160000001080334,
          "capacity": 422.400000016205
      },
      {
          "time_stamp_utc_ms": 1734141360000,
          "time": "2024-12-14T01:58:00Z",
          "energy": 40.79999999888241,
          "capacity": 432.0000121054407
      },
      {
          "time_stamp_utc_ms": 1734141660000,
          "time": "2024-12-14T02:03:00Z",
          "energy": 36.3199999993667,
          "capacity": 435.83999999240046
      },
      {
          "time_stamp_utc_ms": 1734141960000,
          "time": "2024-12-14T02:08:00Z",
          "energy": 35.328000001609325,
          "capacity": 423.9360000193119
      },
      {
          "time_stamp_utc_ms": 1734142260000,
          "time": "2024-12-14T02:13:00Z",
          "energy": 36.86399999912828,
          "capacity": 442.3679999895394
      },
      {
          "time_stamp_utc_ms": 1734142560000,
          "time": "2024-12-14T02:18:00Z",
          "energy": 35.32799999974668,
          "capacity": 423.93599999696016
      },
      {
          "time_stamp_utc_ms": 1734142860000,
          "time": "2024-12-14T02:23:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1734143100000,
          "time": "2024-12-14T02:28:00Z",
          "energy": 28.000000000931323,
          "capacity": 420.00000001396984
      },
      {
          "time_stamp_utc_ms": 1734143460000,
          "time": "2024-12-14T02:33:00Z",
          "energy": 41.88800000026822,
          "capacity": 418.8800000026822
      },
      {
          "time_stamp_utc_ms": 1734143760000,
          "time": "2024-12-14T02:38:00Z",
          "energy": 35.42399999964982,
          "capacity": 425.0879999957979
      },
      {
          "time_stamp_utc_ms": 1734144060000,
          "time": "2024-12-14T02:43:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1734144360000,
          "time": "2024-12-14T02:48:00Z",
          "energy": 35.71200000029057,
          "capacity": 428.5440000034869
      },
      {
          "time_stamp_utc_ms": 1734144660000,
          "time": "2024-12-14T02:53:00Z",
          "energy": 35.52000000048429,
          "capacity": 426.24000000581145
      },
      {
          "time_stamp_utc_ms": 1734144960000,
          "time": "2024-12-14T02:58:00Z",
          "energy": 35.71199999935925,
          "capacity": 428.543999992311
      },
      {
          "time_stamp_utc_ms": 1734145260000,
          "time": "2024-12-14T03:03:00Z",
          "energy": 37.82399999909103,
          "capacity": 453.88799998909235
      },
      {
          "time_stamp_utc_ms": 1734145560000,
          "time": "2024-12-14T03:08:00Z",
          "energy": 35.040000000968575,
          "capacity": 420.4800000116229
      },
      {
          "time_stamp_utc_ms": 1734145860000,
          "time": "2024-12-14T03:13:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1734146160000,
          "time": "2024-12-14T03:18:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1734146460000,
          "time": "2024-12-14T03:23:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1734147090000,
          "time": "2024-12-14T03:33:00Z",
          "energy": 73.77600000053644,
          "capacity": 421.5771428602082
      },
      {
          "time_stamp_utc_ms": 1734147360000,
          "time": "2024-12-14T03:38:00Z",
          "energy": 32.87999999988824,
          "capacity": 438.3999999985099
      },
      {
          "time_stamp_utc_ms": 1734147700000,
          "time": "2024-12-14T03:43:00Z",
          "energy": 38.71999999880791,
          "capacity": 409.97648207514374
      },
      {
          "time_stamp_utc_ms": 1734147960000,
          "time": "2024-12-14T03:48:00Z",
          "energy": 30.880000000819564,
          "capacity": 427.56921509743324
      },
      {
          "time_stamp_utc_ms": 1734148620000,
          "time": "2024-12-14T03:58:00Z",
          "energy": 77.75999999977648,
          "capacity": 424.14545454423535
      },
      {
          "time_stamp_utc_ms": 1734149180000,
          "time": "2024-12-14T04:08:00Z",
          "energy": 64.63999999966472,
          "capacity": 415.5428712940077
      },
      {
          "time_stamp_utc_ms": 1734149420000,
          "time": "2024-12-14T04:13:00Z",
          "energy": 28.800000000745058,
          "capacity": 432.00000001117587
      },
      {
          "time_stamp_utc_ms": 1734150040000,
          "time": "2024-12-14T04:23:00Z",
          "energy": 71.20000000018626,
          "capacity": 413.41936755809553
      },
      {
          "time_stamp_utc_ms": 1734150360000,
          "time": "2024-12-14T04:28:00Z",
          "energy": 37.2480000006035,
          "capacity": 419.03998751842454
      },
      {
          "time_stamp_utc_ms": 1734150660000,
          "time": "2024-12-14T04:33:00Z",
          "energy": 34.175999999046326,
          "capacity": 410.11199998855597
      },
      {
          "time_stamp_utc_ms": 1734150960000,
          "time": "2024-12-14T04:38:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1734151260000,
          "time": "2024-12-14T04:43:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1734151560000,
          "time": "2024-12-14T04:48:00Z",
          "energy": 34.7519999993965,
          "capacity": 417.0239999927581
      },
      {
          "time_stamp_utc_ms": 1734151860000,
          "time": "2024-12-14T04:53:00Z",
          "energy": 35.23199999984354,
          "capacity": 422.78399999812245
      },
      {
          "time_stamp_utc_ms": 1734152160000,
          "time": "2024-12-14T04:58:00Z",
          "energy": 34.4640000006184,
          "capacity": 413.5680000074208
      },
      {
          "time_stamp_utc_ms": 1734152460000,
          "time": "2024-12-14T05:03:00Z",
          "energy": 36.09599999990314,
          "capacity": 433.1519999988377
      },
      {
          "time_stamp_utc_ms": 1734152760000,
          "time": "2024-12-14T05:08:00Z",
          "energy": 35.13599999900907,
          "capacity": 421.63199998810893
      },
      {
          "time_stamp_utc_ms": 1734153060000,
          "time": "2024-12-14T05:13:00Z",
          "energy": 36.19200000073761,
          "capacity": 434.3040000088513
      },
      {
          "time_stamp_utc_ms": 1734153360000,
          "time": "2024-12-14T05:18:00Z",
          "energy": 35.71200000029057,
          "capacity": 428.5440000034869
      },
      {
          "time_stamp_utc_ms": 1734153660000,
          "time": "2024-12-14T05:23:00Z",
          "energy": 34.94399999920279,
          "capacity": 419.3279999904335
      },
      {
          "time_stamp_utc_ms": 1734153960000,
          "time": "2024-12-14T05:28:00Z",
          "energy": 36.00000000093132,
          "capacity": 432.00000001117587
      },
      {
          "time_stamp_utc_ms": 1734154260000,
          "time": "2024-12-14T05:33:00Z",
          "energy": 35.04000000003725,
          "capacity": 420.48000000044703
      },
      {
          "time_stamp_utc_ms": 1734154560000,
          "time": "2024-12-14T05:38:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1734154860000,
          "time": "2024-12-14T05:43:00Z",
          "energy": 35.23200000077486,
          "capacity": 422.7840000092983
      },
      {
          "time_stamp_utc_ms": 1734155160000,
          "time": "2024-12-14T05:48:00Z",
          "energy": 36.671999999322,
          "capacity": 440.06399999186397
      },
      {
          "time_stamp_utc_ms": 1734155460000,
          "time": "2024-12-14T05:53:00Z",
          "energy": 35.71199999935925,
          "capacity": 428.543999992311
      },
      {
          "time_stamp_utc_ms": 1734155760000,
          "time": "2024-12-14T05:58:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1734156060000,
          "time": "2024-12-14T06:03:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1734156360000,
          "time": "2024-12-14T06:08:00Z",
          "energy": 35.32799999974668,
          "capacity": 423.93599999696016
      },
      {
          "time_stamp_utc_ms": 1734156660000,
          "time": "2024-12-14T06:13:00Z",
          "energy": 36.00000000093132,
          "capacity": 432.00000001117587
      },
      {
          "time_stamp_utc_ms": 1734156960000,
          "time": "2024-12-14T06:18:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1734157260000,
          "time": "2024-12-14T06:23:00Z",
          "energy": 35.23199999984354,
          "capacity": 422.78399999812245
      },
      {
          "time_stamp_utc_ms": 1734157560000,
          "time": "2024-12-14T06:28:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1734157860000,
          "time": "2024-12-14T06:33:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1734158160000,
          "time": "2024-12-14T06:38:00Z",
          "energy": 35.42399999964982,
          "capacity": 425.0879999957979
      },
      {
          "time_stamp_utc_ms": 1734158460000,
          "time": "2024-12-14T06:43:00Z",
          "energy": 35.61599999945611,
          "capacity": 427.3919999934733
      },
      {
          "time_stamp_utc_ms": 1734158760000,
          "time": "2024-12-14T06:48:00Z",
          "energy": 34.368000000715256,
          "capacity": 412.41600000858307
      },
      {
          "time_stamp_utc_ms": 1734159060000,
          "time": "2024-12-14T06:53:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734159360000,
          "time": "2024-12-14T06:58:00Z",
          "energy": 34.84800000023097,
          "capacity": 418.1760000027716
      },
      {
          "time_stamp_utc_ms": 1734159660000,
          "time": "2024-12-14T07:03:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1734159960000,
          "time": "2024-12-14T07:08:00Z",
          "energy": 35.71200000029057,
          "capacity": 428.5440000034869
      },
      {
          "time_stamp_utc_ms": 1734160260000,
          "time": "2024-12-14T07:13:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1734160560000,
          "time": "2024-12-14T07:18:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1734160860000,
          "time": "2024-12-14T07:23:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1734161040000,
          "time": "2024-12-14T07:28:00Z",
          "energy": 21.984000000171363,
          "capacity": 439.68000000342727
      },
      {
          "time_stamp_utc_ms": 1734161460000,
          "time": "2024-12-14T07:33:00Z",
          "energy": 50.01599999982864,
          "capacity": 428.7085714271026
      },
      {
          "time_stamp_utc_ms": 1734161760000,
          "time": "2024-12-14T07:38:00Z",
          "energy": 35.71200000029057,
          "capacity": 428.5440000034869
      },
      {
          "time_stamp_utc_ms": 1734162060000,
          "time": "2024-12-14T07:43:00Z",
          "energy": 35.61599999945611,
          "capacity": 427.3919999934733
      },
      {
          "time_stamp_utc_ms": 1734162360000,
          "time": "2024-12-14T07:48:00Z",
          "energy": 35.32799999974668,
          "capacity": 423.93599999696016
      },
      {
          "time_stamp_utc_ms": 1734162660000,
          "time": "2024-12-14T07:53:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1734162960000,
          "time": "2024-12-14T07:58:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1734163260000,
          "time": "2024-12-14T08:03:00Z",
          "energy": 37.152000000700355,
          "capacity": 445.8240000084043
      },
      {
          "time_stamp_utc_ms": 1734163560000,
          "time": "2024-12-14T08:08:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1734163860000,
          "time": "2024-12-14T08:13:00Z",
          "energy": 35.32799999974668,
          "capacity": 423.93599999696016
      },
      {
          "time_stamp_utc_ms": 1734164160000,
          "time": "2024-12-14T08:18:00Z",
          "energy": 36.57599999848753,
          "capacity": 438.9119999818504
      },
      {
          "time_stamp_utc_ms": 1734164460000,
          "time": "2024-12-14T08:23:00Z",
          "energy": 35.90400000102818,
          "capacity": 430.84800001233816
      },
      {
          "time_stamp_utc_ms": 1734164760000,
          "time": "2024-12-14T08:28:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1734165075000,
          "time": "2024-12-14T08:33:00Z",
          "energy": 40.080000000074506,
          "capacity": 458.05714285799434
      },
      {
          "time_stamp_utc_ms": 1734165360000,
          "time": "2024-12-14T08:38:00Z",
          "energy": 33.26399999950081,
          "capacity": 420.1768420989576
      },
      {
          "time_stamp_utc_ms": 1734165630000,
          "time": "2024-12-14T08:43:00Z",
          "energy": 33.57600000035018,
          "capacity": 447.680000004669
      },
      {
          "time_stamp_utc_ms": 1734165930000,
          "time": "2024-12-14T08:48:00Z",
          "energy": 34.67999999970198,
          "capacity": 416.1599999964238
      },
      {
          "time_stamp_utc_ms": 1734166260000,
          "time": "2024-12-14T08:53:00Z",
          "energy": 40.200000000186265,
          "capacity": 438.54545454748654
      },
      {
          "time_stamp_utc_ms": 1734166440000,
          "time": "2024-12-14T08:58:00Z",
          "energy": 22.200000000186265,
          "capacity": 444.00000000372523
      },
      {
          "time_stamp_utc_ms": 1734166830000,
          "time": "2024-12-14T09:03:00Z",
          "energy": 45.12000000011176,
          "capacity": 416.49230769333934
      },
      {
          "time_stamp_utc_ms": 1734167130000,
          "time": "2024-12-14T09:08:00Z",
          "energy": 36.59999999962747,
          "capacity": 439.19999999552965
      },
      {
          "time_stamp_utc_ms": 1734167420000,
          "time": "2024-12-14T09:13:00Z",
          "energy": 33.799999999813735,
          "capacity": 419.5861930960127
      },
      {
          "time_stamp_utc_ms": 1734167775000,
          "time": "2024-12-14T09:18:00Z",
          "energy": 43.12000000011176,
          "capacity": 437.2732511846902
      },
      {
          "time_stamp_utc_ms": 1734168060000,
          "time": "2024-12-14T09:23:00Z",
          "energy": 35.472000000067055,
          "capacity": 448.0673684218996
      },
      {
          "time_stamp_utc_ms": 1734168360000,
          "time": "2024-12-14T09:28:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734168660000,
          "time": "2024-12-14T09:33:00Z",
          "energy": 36.09599999897182,
          "capacity": 433.15199998766184
      },
      {
          "time_stamp_utc_ms": 1734168960000,
          "time": "2024-12-14T09:38:00Z",
          "energy": 35.13600000087172,
          "capacity": 421.6320000104606
      },
      {
          "time_stamp_utc_ms": 1734169260000,
          "time": "2024-12-14T09:43:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1734169560000,
          "time": "2024-12-14T09:48:00Z",
          "energy": 35.328000000678,
          "capacity": 423.93600000813603
      },
      {
          "time_stamp_utc_ms": 1734169860000,
          "time": "2024-12-14T09:53:00Z",
          "energy": 35.4239999987185,
          "capacity": 425.08799998462206
      },
      {
          "time_stamp_utc_ms": 1734170160000,
          "time": "2024-12-14T09:58:00Z",
          "energy": 36.768000001087785,
          "capacity": 441.2160000130535
      },
      {
          "time_stamp_utc_ms": 1734170460000,
          "time": "2024-12-14T10:03:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1734170760000,
          "time": "2024-12-14T10:08:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1734171060000,
          "time": "2024-12-14T10:13:00Z",
          "energy": 37.055999998003244,
          "capacity": 444.67199997603893
      },
      {
          "time_stamp_utc_ms": 1734171360000,
          "time": "2024-12-14T10:18:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1734171660000,
          "time": "2024-12-14T10:23:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1734171960000,
          "time": "2024-12-14T10:28:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1734172260000,
          "time": "2024-12-14T10:33:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1734172530000,
          "time": "2024-12-14T10:38:00Z",
          "energy": 31.559999999590218,
          "capacity": 420.7999999945362
      },
      {
          "time_stamp_utc_ms": 1734172980000,
          "time": "2024-12-14T10:43:00Z",
          "energy": 56.28000000026077,
          "capacity": 450.24000000208616
      },
      {
          "time_stamp_utc_ms": 1734173490000,
          "time": "2024-12-14T10:53:00Z",
          "energy": 61.43999999947846,
          "capacity": 433.69411764337735
      },
      {
          "time_stamp_utc_ms": 1734173700000,
          "time": "2024-12-14T10:58:00Z",
          "energy": 26.080000000074506,
          "capacity": 447.0857142869915
      },
      {
          "time_stamp_utc_ms": 1734174030000,
          "time": "2024-12-14T11:03:00Z",
          "energy": 40.40000000037253,
          "capacity": 440.7272727313367
      },
      {
          "time_stamp_utc_ms": 1734174390000,
          "time": "2024-12-14T11:08:00Z",
          "energy": 44.75999999977648,
          "capacity": 447.5999999977648
      },
      {
          "time_stamp_utc_ms": 1734174660000,
          "time": "2024-12-14T11:13:00Z",
          "energy": 33.71999999973923,
          "capacity": 449.599999996523
      },
      {
          "time_stamp_utc_ms": 1734174960000,
          "time": "2024-12-14T11:18:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1734175260000,
          "time": "2024-12-14T11:23:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1734175560000,
          "time": "2024-12-14T11:28:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1734175860000,
          "time": "2024-12-14T11:33:00Z",
          "energy": 37.5359999993816,
          "capacity": 450.4319999925792
      },
      {
          "time_stamp_utc_ms": 1734176160000,
          "time": "2024-12-14T11:38:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1734176460000,
          "time": "2024-12-14T11:43:00Z",
          "energy": 36.28800000064075,
          "capacity": 435.456000007689
      },
      {
          "time_stamp_utc_ms": 1734176760000,
          "time": "2024-12-14T11:48:00Z",
          "energy": 37.5359999993816,
          "capacity": 450.4319999925792
      },
      {
          "time_stamp_utc_ms": 1734177060000,
          "time": "2024-12-14T11:53:00Z",
          "energy": 35.42400000151247,
          "capacity": 425.0880000181496
      },
      {
          "time_stamp_utc_ms": 1734177360000,
          "time": "2024-12-14T11:58:00Z",
          "energy": 37.72799999918789,
          "capacity": 452.73599999025464
      },
      {
          "time_stamp_utc_ms": 1734177660000,
          "time": "2024-12-14T12:03:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1734177960000,
          "time": "2024-12-14T12:08:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1734178170000,
          "time": "2024-12-14T12:13:00Z",
          "energy": 25.77599999960512,
          "capacity": 441.8742857075163
      },
      {
          "time_stamp_utc_ms": 1734178500000,
          "time": "2024-12-14T12:18:00Z",
          "energy": 39.12000000011176,
          "capacity": 426.7636363648556
      },
      {
          "time_stamp_utc_ms": 1734179160000,
          "time": "2024-12-14T12:28:00Z",
          "energy": 82.0800000000745,
          "capacity": 447.7090909094973
      },
      {
          "time_stamp_utc_ms": 1734179460000,
          "time": "2024-12-14T12:33:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1734179775000,
          "time": "2024-12-14T12:38:00Z",
          "energy": 39.0,
          "capacity": 445.71428571428567
      },
      {
          "time_stamp_utc_ms": 1734180060000,
          "time": "2024-12-14T12:43:00Z",
          "energy": 34.82400000002235,
          "capacity": 439.8821052634402
      },
      {
          "time_stamp_utc_ms": 1734180360000,
          "time": "2024-12-14T12:48:00Z",
          "energy": 36.575999999418855,
          "capacity": 438.91199999302626
      },
      {
          "time_stamp_utc_ms": 1734180660000,
          "time": "2024-12-14T12:53:00Z",
          "energy": 35.52000000048429,
          "capacity": 426.24000000581145
      },
      {
          "time_stamp_utc_ms": 1734180960000,
          "time": "2024-12-14T12:58:00Z",
          "energy": 37.05599999893457,
          "capacity": 444.6719999872148
      },
      {
          "time_stamp_utc_ms": 1734181260000,
          "time": "2024-12-14T13:03:00Z",
          "energy": 36.00000000093132,
          "capacity": 432.00000001117587
      },
      {
          "time_stamp_utc_ms": 1734181560000,
          "time": "2024-12-14T13:08:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1734181860000,
          "time": "2024-12-14T13:13:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1734182160000,
          "time": "2024-12-14T13:18:00Z",
          "energy": 34.7519999993965,
          "capacity": 417.0239999927581
      },
      {
          "time_stamp_utc_ms": 1734182460000,
          "time": "2024-12-14T13:23:00Z",
          "energy": 36.28800000064075,
          "capacity": 435.456000007689
      },
      {
          "time_stamp_utc_ms": 1734182760000,
          "time": "2024-12-14T13:28:00Z",
          "energy": 35.32799999974668,
          "capacity": 423.93599999696016
      },
      {
          "time_stamp_utc_ms": 1734183040000,
          "time": "2024-12-14T13:33:00Z",
          "energy": 32.639999999664724,
          "capacity": 419.65715714626975
      },
      {
          "time_stamp_utc_ms": 1734183360000,
          "time": "2024-12-14T13:38:00Z",
          "energy": 38.880000000819564,
          "capacity": 437.3999869736847
      },
      {
          "time_stamp_utc_ms": 1734183630000,
          "time": "2024-12-14T13:43:00Z",
          "energy": 30.600000000558794,
          "capacity": 408.0000000074506
      },
      {
          "time_stamp_utc_ms": 1734183945000,
          "time": "2024-12-14T13:48:00Z",
          "energy": 38.39999999944121,
          "capacity": 438.85714285075665
      },
      {
          "time_stamp_utc_ms": 1734184290000,
          "time": "2024-12-14T13:53:00Z",
          "energy": 40.3199999993667,
          "capacity": 420.7304347760004
      },
      {
          "time_stamp_utc_ms": 1734184580000,
          "time": "2024-12-14T13:58:00Z",
          "energy": 33.24000000022352,
          "capacity": 412.6344691917783
      },
      {
          "time_stamp_utc_ms": 1734184800000,
          "time": "2024-12-14T14:03:00Z",
          "energy": 25.440000000409782,
          "capacity": 416.2909000747523
      },
      {
          "time_stamp_utc_ms": 1734185100000,
          "time": "2024-12-14T14:08:00Z",
          "energy": 33.59999999962747,
          "capacity": 403.1999999955297
      },
      {
          "time_stamp_utc_ms": 1734185460000,
          "time": "2024-12-14T14:13:00Z",
          "energy": 40.89599999971688,
          "capacity": 408.9599999971688
      },
      {
          "time_stamp_utc_ms": 1734185700000,
          "time": "2024-12-14T14:18:00Z",
          "energy": 26.463999999687076,
          "capacity": 396.95999999530613
      },
      {
          "time_stamp_utc_ms": 1734186075000,
          "time": "2024-12-14T14:23:00Z",
          "energy": 41.3600000012666,
          "capacity": 397.05600001215936
      },
      {
          "time_stamp_utc_ms": 1734186450000,
          "time": "2024-12-14T14:28:00Z",
          "energy": 41.519999999552965,
          "capacity": 398.5919999957085
      },
      {
          "time_stamp_utc_ms": 1734186700000,
          "time": "2024-12-14T14:33:00Z",
          "energy": 26.71999999973923,
          "capacity": 384.76801467397985
      },
      {
          "time_stamp_utc_ms": 1734186990000,
          "time": "2024-12-14T14:38:00Z",
          "energy": 32.32000000029802,
          "capacity": 401.21377991310385
      },
      {
          "time_stamp_utc_ms": 1734187230000,
          "time": "2024-12-14T14:43:00Z",
          "energy": 25.43999999947846,
          "capacity": 381.5999999921769
      },
      {
          "time_stamp_utc_ms": 1734187600000,
          "time": "2024-12-14T14:48:00Z",
          "energy": 39.040000000968575,
          "capacity": 379.8486584486648
      },
      {
          "time_stamp_utc_ms": 1734187840000,
          "time": "2024-12-14T14:53:00Z",
          "energy": 24.3199999993667,
          "capacity": 364.7999999905005
      },
      {
          "time_stamp_utc_ms": 1734188160000,
          "time": "2024-12-14T14:58:00Z",
          "energy": 30.720000000670552,
          "capacity": 345.59998970786137
      },
      {
          "time_stamp_utc_ms": 1734188460000,
          "time": "2024-12-14T15:03:00Z",
          "energy": 32.480000000447035,
          "capacity": 389.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734188640000,
          "time": "2024-12-14T15:08:00Z",
          "energy": 17.91999999899417,
          "capacity": 358.39999997988343
      },
      {
          "time_stamp_utc_ms": 1734189120000,
          "time": "2024-12-14T15:13:00Z",
          "energy": 49.67999999970198,
          "capacity": 372.5999999977648
      },
      {
          "time_stamp_utc_ms": 1734189360000,
          "time": "2024-12-14T15:18:00Z",
          "energy": 24.33600000012666,
          "capacity": 365.0400000018999
      },
      {
          "time_stamp_utc_ms": 1734189660000,
          "time": "2024-12-14T15:23:00Z",
          "energy": 31.00800000037998,
          "capacity": 372.09600000455976
      },
      {
          "time_stamp_utc_ms": 1734189960000,
          "time": "2024-12-14T15:28:00Z",
          "energy": 31.967999999411404,
          "capacity": 383.61599999293685
      },
      {
          "time_stamp_utc_ms": 1734190260000,
          "time": "2024-12-14T15:33:00Z",
          "energy": 30.62400000076741,
          "capacity": 367.4880000092089
      },
      {
          "time_stamp_utc_ms": 1734190560000,
          "time": "2024-12-14T15:38:00Z",
          "energy": 31.39199999999255,
          "capacity": 376.7039999999106
      },
      {
          "time_stamp_utc_ms": 1734190860000,
          "time": "2024-12-14T15:43:00Z",
          "energy": 29.28000000026077,
          "capacity": 351.3600000031293
      },
      {
          "time_stamp_utc_ms": 1734191160000,
          "time": "2024-12-14T15:48:00Z",
          "energy": 28.415999999269843,
          "capacity": 340.9919999912381
      },
      {
          "time_stamp_utc_ms": 1734191460000,
          "time": "2024-12-14T15:53:00Z",
          "energy": 29.184000000357628,
          "capacity": 350.2080000042916
      },
      {
          "time_stamp_utc_ms": 1734191760000,
          "time": "2024-12-14T15:58:00Z",
          "energy": 28.415999999269843,
          "capacity": 340.9919999912381
      },
      {
          "time_stamp_utc_ms": 1734192060000,
          "time": "2024-12-14T16:03:00Z",
          "energy": 28.416000000201166,
          "capacity": 340.99200000241404
      },
      {
          "time_stamp_utc_ms": 1734192360000,
          "time": "2024-12-14T16:08:00Z",
          "energy": 27.84000000078231,
          "capacity": 334.0800000093878
      },
      {
          "time_stamp_utc_ms": 1734192660000,
          "time": "2024-12-14T16:13:00Z",
          "energy": 28.223999999463558,
          "capacity": 338.6879999935627
      },
      {
          "time_stamp_utc_ms": 1734192960000,
          "time": "2024-12-14T16:18:00Z",
          "energy": 29.472000000067055,
          "capacity": 353.6640000008047
      },
      {
          "time_stamp_utc_ms": 1734193260000,
          "time": "2024-12-14T16:23:00Z",
          "energy": 26.688000000081956,
          "capacity": 320.2560000009835
      },
      {
          "time_stamp_utc_ms": 1734193530000,
          "time": "2024-12-14T16:28:00Z",
          "energy": 26.376000000163913,
          "capacity": 351.68000000218547
      },
      {
          "time_stamp_utc_ms": 1734193950000,
          "time": "2024-12-14T16:33:00Z",
          "energy": 37.080000000074506,
          "capacity": 317.82857142921
      },
      {
          "time_stamp_utc_ms": 1734194190000,
          "time": "2024-12-14T16:38:00Z",
          "energy": 22.799999999813735,
          "capacity": 341.99999999720603
      },
      {
          "time_stamp_utc_ms": 1734194520000,
          "time": "2024-12-14T16:43:00Z",
          "energy": 29.759999999776483,
          "capacity": 324.6545454521071
      },
      {
          "time_stamp_utc_ms": 1734194740000,
          "time": "2024-12-14T16:48:00Z",
          "energy": 20.40000000037253,
          "capacity": 333.8181745889637
      },
      {
          "time_stamp_utc_ms": 1734195040000,
          "time": "2024-12-14T16:53:00Z",
          "energy": 28.799999999813735,
          "capacity": 345.5999999977649
      },
      {
          "time_stamp_utc_ms": 1734195660000,
          "time": "2024-12-14T17:03:00Z",
          "energy": 52.080000000074506,
          "capacity": 302.40000930337203
      },
      {
          "time_stamp_utc_ms": 1734196020000,
          "time": "2024-12-14T17:08:00Z",
          "energy": 30.959999999962747,
          "capacity": 309.59999999962747
      },
      {
          "time_stamp_utc_ms": 1734196200000,
          "time": "2024-12-14T17:13:00Z",
          "energy": 14.399999999441206,
          "capacity": 287.99999998882413
      },
      {
          "time_stamp_utc_ms": 1734196560000,
          "time": "2024-12-14T17:18:00Z",
          "energy": 31.968000000342727,
          "capacity": 319.68000000342727
      },
      {
          "time_stamp_utc_ms": 1734196860000,
          "time": "2024-12-14T17:23:00Z",
          "energy": 28.800000000745058,
          "capacity": 345.60000000894075
      },
      {
          "time_stamp_utc_ms": 1734197070000,
          "time": "2024-12-14T17:28:00Z",
          "energy": 19.87199999950826,
          "capacity": 340.6628571344273
      },
      {
          "time_stamp_utc_ms": 1734197520000,
          "time": "2024-12-14T17:33:00Z",
          "energy": 41.75999999977648,
          "capacity": 334.07999999821186
      },
      {
          "time_stamp_utc_ms": 1734197760000,
          "time": "2024-12-14T17:38:00Z",
          "energy": 23.200000000186265,
          "capacity": 348.00000000279397
      },
      {
          "time_stamp_utc_ms": 1734198020000,
          "time": "2024-12-14T17:43:00Z",
          "energy": 28.16000000014901,
          "capacity": 389.9076780080272
      },
      {
          "time_stamp_utc_ms": 1734198240000,
          "time": "2024-12-14T17:48:00Z",
          "energy": 21.59999999962747,
          "capacity": 353.4545377875287
      },
      {
          "time_stamp_utc_ms": 1734198660000,
          "time": "2024-12-14T17:53:00Z",
          "energy": 38.40000000037253,
          "capacity": 329.14285714605023
      },
      {
          "time_stamp_utc_ms": 1734199020000,
          "time": "2024-12-14T17:58:00Z",
          "energy": 31.200000000186265,
          "capacity": 312.00000000186265
      },
      {
          "time_stamp_utc_ms": 1734199260000,
          "time": "2024-12-14T18:03:00Z",
          "energy": 22.799999998882413,
          "capacity": 341.9999999832362
      },
      {
          "time_stamp_utc_ms": 1734199620000,
          "time": "2024-12-14T18:08:00Z",
          "energy": 35.28000000026077,
          "capacity": 352.80000000260765
      },
      {
          "time_stamp_utc_ms": 1734199770000,
          "time": "2024-12-14T18:13:00Z",
          "energy": 13.6800000006333,
          "capacity": 328.32000001519924
      },
      {
          "time_stamp_utc_ms": 1734200070000,
          "time": "2024-12-14T18:18:00Z",
          "energy": 30.480000000447035,
          "capacity": 365.7600000053645
      },
      {
          "time_stamp_utc_ms": 1734200460000,
          "time": "2024-12-14T18:23:00Z",
          "energy": 37.43999999947846,
          "capacity": 345.59999999518584
      },
      {
          "time_stamp_utc_ms": 1734200820000,
          "time": "2024-12-14T18:28:00Z",
          "energy": 30.0,
          "capacity": 300.0
      },
      {
          "time_stamp_utc_ms": 1734201000000,
          "time": "2024-12-14T18:33:00Z",
          "energy": 14.639999999664724,
          "capacity": 292.7999999932945
      },
      {
          "time_stamp_utc_ms": 1734201400000,
          "time": "2024-12-14T18:38:00Z",
          "energy": 37.28000000026077,
          "capacity": 335.5200080017673
      },
      {
          "time_stamp_utc_ms": 1734201660000,
          "time": "2024-12-14T18:43:00Z",
          "energy": 26.94400000013411,
          "capacity": 373.0707555484724
      },
      {
          "time_stamp_utc_ms": 1734201960000,
          "time": "2024-12-14T18:48:00Z",
          "energy": 29.951999999582767,
          "capacity": 359.42399999499327
      },
      {
          "time_stamp_utc_ms": 1734202260000,
          "time": "2024-12-14T18:53:00Z",
          "energy": 30.33600000012666,
          "capacity": 364.0320000015199
      },
      {
          "time_stamp_utc_ms": 1734202560000,
          "time": "2024-12-14T18:58:00Z",
          "energy": 30.432000000029802,
          "capacity": 365.1840000003576
      },
      {
          "time_stamp_utc_ms": 1734202860000,
          "time": "2024-12-14T19:03:00Z",
          "energy": 30.04799999948591,
          "capacity": 360.575999993831
      },
      {
          "time_stamp_utc_ms": 1734203160000,
          "time": "2024-12-14T19:08:00Z",
          "energy": 30.816000000573695,
          "capacity": 369.79200000688434
      },
      {
          "time_stamp_utc_ms": 1734203480000,
          "time": "2024-12-14T19:13:00Z",
          "energy": 31.55199999921024,
          "capacity": 354.95998941248314
      },
      {
          "time_stamp_utc_ms": 1734203730000,
          "time": "2024-12-14T19:18:00Z",
          "energy": 25.28000000026077,
          "capacity": 364.03201389047433
      },
      {
          "time_stamp_utc_ms": 1734204020000,
          "time": "2024-12-14T19:23:00Z",
          "energy": 27.84000000078231,
          "capacity": 345.5999886445448
      },
      {
          "time_stamp_utc_ms": 1734204540000,
          "time": "2024-12-14T19:33:00Z",
          "energy": 49.919999999925494,
          "capacity": 345.5999873229522
      },
      {
          "time_stamp_utc_ms": 1734204930000,
          "time": "2024-12-14T19:38:00Z",
          "energy": 41.52000000048429,
          "capacity": 383.26153846600886
      },
      {
          "time_stamp_utc_ms": 1734205260000,
          "time": "2024-12-14T19:43:00Z",
          "energy": 37.19999999925494,
          "capacity": 405.81818181005394
      },
      {
          "time_stamp_utc_ms": 1734205520000,
          "time": "2024-12-14T19:48:00Z",
          "energy": 27.200000000186265,
          "capacity": 376.6153708037942
      },
      {
          "time_stamp_utc_ms": 1734205840000,
          "time": "2024-12-14T19:53:00Z",
          "energy": 33.91999999899417,
          "capacity": 381.59998861611854
      },
      {
          "time_stamp_utc_ms": 1734206120000,
          "time": "2024-12-14T19:58:00Z",
          "energy": 30.24000000115484,
          "capacity": 388.80001325729745
      },
      {
          "time_stamp_utc_ms": 1734206440000,
          "time": "2024-12-14T20:03:00Z",
          "energy": 31.520000000484288,
          "capacity": 354.59998943754505
      },
      {
          "time_stamp_utc_ms": 1734206820000,
          "time": "2024-12-14T20:08:00Z",
          "energy": 40.55999999959022,
          "capacity": 384.2526219315953
      },
      {
          "time_stamp_utc_ms": 1734206970000,
          "time": "2024-12-14T20:13:00Z",
          "energy": 14.879999999888241,
          "capacity": 357.11999999731785
      },
      {
          "time_stamp_utc_ms": 1734207360000,
          "time": "2024-12-14T20:18:00Z",
          "energy": 42.38399999961257,
          "capacity": 391.2369230733468
      },
      {
          "time_stamp_utc_ms": 1734207660000,
          "time": "2024-12-14T20:23:00Z",
          "energy": 34.27199999988079,
          "capacity": 411.26399999856955
      },
      {
          "time_stamp_utc_ms": 1734207960000,
          "time": "2024-12-14T20:28:00Z",
          "energy": 33.2160000000149,
          "capacity": 398.59200000017887
      },
      {
          "time_stamp_utc_ms": 1734208260000,
          "time": "2024-12-14T20:33:00Z",
          "energy": 32.639999999664724,
          "capacity": 391.6799999959767
      },
      {
          "time_stamp_utc_ms": 1734208520000,
          "time": "2024-12-14T20:38:00Z",
          "energy": 26.68800000101328,
          "capacity": 369.5261403060458
      },
      {
          "time_stamp_utc_ms": 1734208840000,
          "time": "2024-12-14T20:43:00Z",
          "energy": 33.43999999947846,
          "capacity": 376.19998878249936
      },
      {
          "time_stamp_utc_ms": 1734209130000,
          "time": "2024-12-14T20:48:00Z",
          "energy": 29.919999999925494,
          "capacity": 371.4206774399592
      },
      {
          "time_stamp_utc_ms": 1734209400000,
          "time": "2024-12-14T20:53:00Z",
          "energy": 27.360000000335276,
          "capacity": 364.80000000447035
      },
      {
          "time_stamp_utc_ms": 1734209760000,
          "time": "2024-12-14T20:58:00Z",
          "energy": 37.19999999925494,
          "capacity": 371.99999999254936
      },
      {
          "time_stamp_utc_ms": 1734210000000,
          "time": "2024-12-14T21:03:00Z",
          "energy": 23.760000000707805,
          "capacity": 356.4000000106171
      },
      {
          "time_stamp_utc_ms": 1734210420000,
          "time": "2024-12-14T21:08:00Z",
          "energy": 41.28000000026077,
          "capacity": 353.82857143080656
      },
      {
          "time_stamp_utc_ms": 1734210750000,
          "time": "2024-12-14T21:13:00Z",
          "energy": 32.40000000037253,
          "capacity": 353.45454545860946
      },
      {
          "time_stamp_utc_ms": 1734210960000,
          "time": "2024-12-14T21:18:00Z",
          "energy": 20.2079999987036,
          "capacity": 346.4228571206331
      },
      {
          "time_stamp_utc_ms": 1734211260000,
          "time": "2024-12-14T21:23:00Z",
          "energy": 30.432000000961125,
          "capacity": 365.1840000115335
      },
      {
          "time_stamp_utc_ms": 1734211560000,
          "time": "2024-12-14T21:28:00Z",
          "energy": 28.895999998785555,
          "capacity": 346.75199998542666
      },
      {
          "time_stamp_utc_ms": 1734211830000,
          "time": "2024-12-14T21:33:00Z",
          "energy": 27.74400000087917,
          "capacity": 369.9200000117222
      },
      {
          "time_stamp_utc_ms": 1734212220000,
          "time": "2024-12-14T21:38:00Z",
          "energy": 38.87999999988824,
          "capacity": 358.8923076912761
      },
      {
          "time_stamp_utc_ms": 1734212580000,
          "time": "2024-12-14T21:43:00Z",
          "energy": 37.44000000040978,
          "capacity": 374.4000000040978
      },
      {
          "time_stamp_utc_ms": 1734212700000,
          "time": "2024-12-14T21:48:00Z",
          "energy": 12.959999999962747,
          "capacity": 388.7999999988824
      },
      {
          "time_stamp_utc_ms": 1734213090000,
          "time": "2024-12-14T21:53:00Z",
          "energy": 40.080000000074506,
          "capacity": 369.9692307699185
      },
      {
          "time_stamp_utc_ms": 1734213360000,
          "time": "2024-12-14T21:58:00Z",
          "energy": 28.463999999687076,
          "capacity": 379.5199999958276
      },
      {
          "time_stamp_utc_ms": 1734213660000,
          "time": "2024-12-14T22:03:00Z",
          "energy": 30.240000000223517,
          "capacity": 362.8800000026822
      },
      {
          "time_stamp_utc_ms": 1734213960000,
          "time": "2024-12-14T22:08:00Z",
          "energy": 31.39199999999255,
          "capacity": 376.7039999999106
      },
      {
          "time_stamp_utc_ms": 1734214260000,
          "time": "2024-12-14T22:13:00Z",
          "energy": 32.54399999976158,
          "capacity": 390.527999997139
      },
      {
          "time_stamp_utc_ms": 1734214560000,
          "time": "2024-12-14T22:18:00Z",
          "energy": 31.872000000439584,
          "capacity": 382.464000005275
      },
      {
          "time_stamp_utc_ms": 1734214860000,
          "time": "2024-12-14T22:23:00Z",
          "energy": 32.06400000024587,
          "capacity": 384.76800000295043
      },
      {
          "time_stamp_utc_ms": 1734215160000,
          "time": "2024-12-14T22:28:00Z",
          "energy": 30.815999999642372,
          "capacity": 369.79199999570847
      },
      {
          "time_stamp_utc_ms": 1734215460000,
          "time": "2024-12-14T22:33:00Z",
          "energy": 31.104000000283122,
          "capacity": 373.24800000339746
      },
      {
          "time_stamp_utc_ms": 1734215760000,
          "time": "2024-12-14T22:38:00Z",
          "energy": 31.583999999798834,
          "capacity": 379.007999997586
      },
      {
          "time_stamp_utc_ms": 1734216060000,
          "time": "2024-12-14T22:43:00Z",
          "energy": 30.623999998904765,
          "capacity": 367.48799998685723
      },
      {
          "time_stamp_utc_ms": 1734216360000,
          "time": "2024-12-14T22:48:00Z",
          "energy": 32.06400000024587,
          "capacity": 384.76800000295043
      },
      {
          "time_stamp_utc_ms": 1734216660000,
          "time": "2024-12-14T22:53:00Z",
          "energy": 31.104000000283122,
          "capacity": 373.24800000339746
      },
      {
          "time_stamp_utc_ms": 1734216960000,
          "time": "2024-12-14T22:58:00Z",
          "energy": 31.967999999411404,
          "capacity": 383.61599999293685
      },
      {
          "time_stamp_utc_ms": 1734217260000,
          "time": "2024-12-14T23:03:00Z",
          "energy": 32.35200000088662,
          "capacity": 388.2240000106395
      },
      {
          "time_stamp_utc_ms": 1734217560000,
          "time": "2024-12-14T23:08:00Z",
          "energy": 31.1039999993518,
          "capacity": 373.2479999922216
      },
      {
          "time_stamp_utc_ms": 1734217860000,
          "time": "2024-12-14T23:13:00Z",
          "energy": 32.73600000143051,
          "capacity": 392.8320000171662
      },
      {
          "time_stamp_utc_ms": 1734218160000,
          "time": "2024-12-14T23:18:00Z",
          "energy": 30.623999998904765,
          "capacity": 367.48799998685723
      },
      {
          "time_stamp_utc_ms": 1734218460000,
          "time": "2024-12-14T23:23:00Z",
          "energy": 32.44800000078976,
          "capacity": 389.3760000094772
      },
      {
          "time_stamp_utc_ms": 1734218760000,
          "time": "2024-12-14T23:28:00Z",
          "energy": 30.911999999545515,
          "capacity": 370.9439999945462
      },
      {
          "time_stamp_utc_ms": 1734219060000,
          "time": "2024-12-14T23:33:00Z",
          "energy": 31.296000000089407,
          "capacity": 375.5520000010729
      },
      {
          "time_stamp_utc_ms": 1734219360000,
          "time": "2024-12-14T23:38:00Z",
          "energy": 31.584000000730157,
          "capacity": 379.0080000087619
      },
      {
          "time_stamp_utc_ms": 1734219660000,
          "time": "2024-12-14T23:43:00Z",
          "energy": 30.911999998614192,
          "capacity": 370.94399998337036
      },
      {
          "time_stamp_utc_ms": 1734219960000,
          "time": "2024-12-14T23:48:00Z",
          "energy": 33.024000000208616,
          "capacity": 396.28800000250345
      },
      {
          "time_stamp_utc_ms": 1734220260000,
          "time": "2024-12-14T23:53:00Z",
          "energy": 31.104000000283122,
          "capacity": 373.24800000339746
      },
      {
          "time_stamp_utc_ms": 1734220560000,
          "time": "2024-12-14T23:58:00Z",
          "energy": 32.06399999931455,
          "capacity": 384.76799999177456
      },
      {
          "time_stamp_utc_ms": 1734220860000,
          "time": "2024-12-15T00:03:00Z",
          "energy": 33.024000000208616,
          "capacity": 396.28800000250345
      },
      {
          "time_stamp_utc_ms": 1734221160000,
          "time": "2024-12-15T00:08:00Z",
          "energy": 31.968000000342727,
          "capacity": 383.6160000041127
      },
      {
          "time_stamp_utc_ms": 1734221460000,
          "time": "2024-12-15T00:13:00Z",
          "energy": 32.928000000305474,
          "capacity": 395.13600000366574
      },
      {
          "time_stamp_utc_ms": 1734221760000,
          "time": "2024-12-15T00:18:00Z",
          "energy": 32.16000000014901,
          "capacity": 385.92000000178814
      },
      {
          "time_stamp_utc_ms": 1734222060000,
          "time": "2024-12-15T00:23:00Z",
          "energy": 32.54399999883026,
          "capacity": 390.5279999859631
      },
      {
          "time_stamp_utc_ms": 1734222360000,
          "time": "2024-12-15T00:28:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1734222660000,
          "time": "2024-12-15T00:33:00Z",
          "energy": 32.06400000024587,
          "capacity": 384.76800000295043
      },
      {
          "time_stamp_utc_ms": 1734222960000,
          "time": "2024-12-15T00:38:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1734223260000,
          "time": "2024-12-15T00:43:00Z",
          "energy": 31.872000000439584,
          "capacity": 382.464000005275
      },
      {
          "time_stamp_utc_ms": 1734223560000,
          "time": "2024-12-15T00:48:00Z",
          "energy": 33.311999999918044,
          "capacity": 399.7439999990166
      },
      {
          "time_stamp_utc_ms": 1734223860000,
          "time": "2024-12-15T00:53:00Z",
          "energy": 32.735999999567866,
          "capacity": 392.8319999948144
      },
      {
          "time_stamp_utc_ms": 1734224160000,
          "time": "2024-12-15T00:58:00Z",
          "energy": 32.639999999664724,
          "capacity": 391.6799999959767
      },
      {
          "time_stamp_utc_ms": 1734224460000,
          "time": "2024-12-15T01:03:00Z",
          "energy": 35.23199999984354,
          "capacity": 422.78399999812245
      },
      {
          "time_stamp_utc_ms": 1734224760000,
          "time": "2024-12-15T01:08:00Z",
          "energy": 32.160000001080334,
          "capacity": 385.92000001296407
      },
      {
          "time_stamp_utc_ms": 1734225060000,
          "time": "2024-12-15T01:13:00Z",
          "energy": 33.50399999972433,
          "capacity": 402.047999996692
      },
      {
          "time_stamp_utc_ms": 1734225360000,
          "time": "2024-12-15T01:18:00Z",
          "energy": 32.16000000014901,
          "capacity": 385.92000000178814
      },
      {
          "time_stamp_utc_ms": 1734225660000,
          "time": "2024-12-15T01:23:00Z",
          "energy": 32.73600000049919,
          "capacity": 392.8320000059903
      },
      {
          "time_stamp_utc_ms": 1734225960000,
          "time": "2024-12-15T01:28:00Z",
          "energy": 33.21599999908358,
          "capacity": 398.59199998900294
      },
      {
          "time_stamp_utc_ms": 1734226260000,
          "time": "2024-12-15T01:33:00Z",
          "energy": 32.25600000098348,
          "capacity": 387.0720000118018
      },
      {
          "time_stamp_utc_ms": 1734226560000,
          "time": "2024-12-15T01:38:00Z",
          "energy": 33.21599999908358,
          "capacity": 398.59199998900294
      },
      {
          "time_stamp_utc_ms": 1734226860000,
          "time": "2024-12-15T01:43:00Z",
          "energy": 32.73600000049919,
          "capacity": 392.8320000059903
      },
      {
          "time_stamp_utc_ms": 1734227160000,
          "time": "2024-12-15T01:48:00Z",
          "energy": 32.447999998927116,
          "capacity": 389.3759999871254
      },
      {
          "time_stamp_utc_ms": 1734227460000,
          "time": "2024-12-15T01:53:00Z",
          "energy": 34.4640000006184,
          "capacity": 413.5680000074208
      },
      {
          "time_stamp_utc_ms": 1734227760000,
          "time": "2024-12-15T01:58:00Z",
          "energy": 33.024000000208616,
          "capacity": 396.28800000250345
      },
      {
          "time_stamp_utc_ms": 1734228060000,
          "time": "2024-12-15T02:03:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1734228360000,
          "time": "2024-12-15T02:08:00Z",
          "energy": 33.407999999821186,
          "capacity": 400.8959999978543
      },
      {
          "time_stamp_utc_ms": 1734228660000,
          "time": "2024-12-15T02:13:00Z",
          "energy": 32.92799999937415,
          "capacity": 395.1359999924898
      },
      {
          "time_stamp_utc_ms": 1734228960000,
          "time": "2024-12-15T02:18:00Z",
          "energy": 33.216000000946224,
          "capacity": 398.59200001135474
      },
      {
          "time_stamp_utc_ms": 1734229260000,
          "time": "2024-12-15T02:23:00Z",
          "energy": 31.48799999896437,
          "capacity": 377.85599998757243
      },
      {
          "time_stamp_utc_ms": 1734229560000,
          "time": "2024-12-15T02:28:00Z",
          "energy": 33.984000001102686,
          "capacity": 407.80800001323223
      },
      {
          "time_stamp_utc_ms": 1734229860000,
          "time": "2024-12-15T02:33:00Z",
          "energy": 31.967999999411404,
          "capacity": 383.61599999293685
      },
      {
          "time_stamp_utc_ms": 1734230160000,
          "time": "2024-12-15T02:38:00Z",
          "energy": 33.59999999962747,
          "capacity": 403.1999999955297
      },
      {
          "time_stamp_utc_ms": 1734230460000,
          "time": "2024-12-15T02:43:00Z",
          "energy": 32.928000000305474,
          "capacity": 395.13600000366574
      },
      {
          "time_stamp_utc_ms": 1734230760000,
          "time": "2024-12-15T02:48:00Z",
          "energy": 32.735999999567866,
          "capacity": 392.8319999948144
      },
      {
          "time_stamp_utc_ms": 1734231060000,
          "time": "2024-12-15T02:53:00Z",
          "energy": 33.50399999972433,
          "capacity": 402.047999996692
      },
      {
          "time_stamp_utc_ms": 1734231360000,
          "time": "2024-12-15T02:58:00Z",
          "energy": 32.35200000181794,
          "capacity": 388.22400002181536
      },
      {
          "time_stamp_utc_ms": 1734231660000,
          "time": "2024-12-15T03:03:00Z",
          "energy": 32.831999998539686,
          "capacity": 393.98399998247623
      },
      {
          "time_stamp_utc_ms": 1734231960000,
          "time": "2024-12-15T03:08:00Z",
          "energy": 32.92799999937415,
          "capacity": 395.1359999924898
      },
      {
          "time_stamp_utc_ms": 1734232260000,
          "time": "2024-12-15T03:13:00Z",
          "energy": 31.776000000536442,
          "capacity": 381.3120000064373
      },
      {
          "time_stamp_utc_ms": 1734232560000,
          "time": "2024-12-15T03:18:00Z",
          "energy": 33.12000000104308,
          "capacity": 397.44000001251703
      },
      {
          "time_stamp_utc_ms": 1734232860000,
          "time": "2024-12-15T03:23:00Z",
          "energy": 32.16000000014901,
          "capacity": 385.92000000178814
      },
      {
          "time_stamp_utc_ms": 1734233160000,
          "time": "2024-12-15T03:28:00Z",
          "energy": 32.831999998539686,
          "capacity": 393.98399998247623
      },
      {
          "time_stamp_utc_ms": 1734233460000,
          "time": "2024-12-15T03:33:00Z",
          "energy": 32.928000001236796,
          "capacity": 395.1360000148416
      },
      {
          "time_stamp_utc_ms": 1734233760000,
          "time": "2024-12-15T03:38:00Z",
          "energy": 31.967999999411404,
          "capacity": 383.61599999293685
      },
      {
          "time_stamp_utc_ms": 1734234060000,
          "time": "2024-12-15T03:43:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1734234360000,
          "time": "2024-12-15T03:48:00Z",
          "energy": 32.639999999664724,
          "capacity": 391.6799999959767
      },
      {
          "time_stamp_utc_ms": 1734234660000,
          "time": "2024-12-15T03:53:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1734234960000,
          "time": "2024-12-15T03:58:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1734235260000,
          "time": "2024-12-15T04:03:00Z",
          "energy": 35.13599999900907,
          "capacity": 421.63199998810893
      },
      {
          "time_stamp_utc_ms": 1734235560000,
          "time": "2024-12-15T04:08:00Z",
          "energy": 35.61600000131875,
          "capacity": 427.39200001582503
      },
      {
          "time_stamp_utc_ms": 1734235860000,
          "time": "2024-12-15T04:13:00Z",
          "energy": 32.735999999567866,
          "capacity": 392.8319999948144
      },
      {
          "time_stamp_utc_ms": 1734236160000,
          "time": "2024-12-15T04:18:00Z",
          "energy": 34.65599999949336,
          "capacity": 415.8719999939204
      },
      {
          "time_stamp_utc_ms": 1734236460000,
          "time": "2024-12-15T04:23:00Z",
          "energy": 33.40800000075251,
          "capacity": 400.89600000903016
      },
      {
          "time_stamp_utc_ms": 1734236760000,
          "time": "2024-12-15T04:28:00Z",
          "energy": 33.69599999953061,
          "capacity": 404.3519999943674
      },
      {
          "time_stamp_utc_ms": 1734237060000,
          "time": "2024-12-15T04:33:00Z",
          "energy": 33.98400000017136,
          "capacity": 407.8080000020564
      },
      {
          "time_stamp_utc_ms": 1734237360000,
          "time": "2024-12-15T04:38:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1734237660000,
          "time": "2024-12-15T04:43:00Z",
          "energy": 34.27199999988079,
          "capacity": 411.26399999856955
      },
      {
          "time_stamp_utc_ms": 1734237960000,
          "time": "2024-12-15T04:48:00Z",
          "energy": 34.4640000006184,
          "capacity": 413.5680000074208
      },
      {
          "time_stamp_utc_ms": 1734238260000,
          "time": "2024-12-15T04:53:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1734238560000,
          "time": "2024-12-15T04:58:00Z",
          "energy": 35.231999998912215,
          "capacity": 422.78399998694664
      },
      {
          "time_stamp_utc_ms": 1734238860000,
          "time": "2024-12-15T05:03:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1734239160000,
          "time": "2024-12-15T05:08:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1734239460000,
          "time": "2024-12-15T05:13:00Z",
          "energy": 34.84800000023097,
          "capacity": 418.1760000027716
      },
      {
          "time_stamp_utc_ms": 1734239760000,
          "time": "2024-12-15T05:18:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1734240060000,
          "time": "2024-12-15T05:23:00Z",
          "energy": 35.4239999987185,
          "capacity": 425.08799998462206
      },
      {
          "time_stamp_utc_ms": 1734240360000,
          "time": "2024-12-15T05:28:00Z",
          "energy": 34.84800000116229,
          "capacity": 418.1760000139475
      },
      {
          "time_stamp_utc_ms": 1734240660000,
          "time": "2024-12-15T05:33:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1734240960000,
          "time": "2024-12-15T05:38:00Z",
          "energy": 34.65600000042468,
          "capacity": 415.8720000050962
      },
      {
          "time_stamp_utc_ms": 1734241260000,
          "time": "2024-12-15T05:43:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1734241560000,
          "time": "2024-12-15T05:48:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1734241860000,
          "time": "2024-12-15T05:53:00Z",
          "energy": 35.328000000678,
          "capacity": 423.93600000813603
      },
      {
          "time_stamp_utc_ms": 1734242160000,
          "time": "2024-12-15T05:58:00Z",
          "energy": 36.47999999858439,
          "capacity": 437.7599999830127
      },
      {
          "time_stamp_utc_ms": 1734242460000,
          "time": "2024-12-15T06:03:00Z",
          "energy": 35.71200000029057,
          "capacity": 428.5440000034869
      },
      {
          "time_stamp_utc_ms": 1734242760000,
          "time": "2024-12-15T06:08:00Z",
          "energy": 36.86400000099093,
          "capacity": 442.3680000118912
      },
      {
          "time_stamp_utc_ms": 1734243060000,
          "time": "2024-12-15T06:13:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1734243360000,
          "time": "2024-12-15T06:18:00Z",
          "energy": 35.231999998912215,
          "capacity": 422.78399998694664
      },
      {
          "time_stamp_utc_ms": 1734243660000,
          "time": "2024-12-15T06:23:00Z",
          "energy": 36.00000000093132,
          "capacity": 432.00000001117587
      },
      {
          "time_stamp_utc_ms": 1734243960000,
          "time": "2024-12-15T06:28:00Z",
          "energy": 34.847999999299645,
          "capacity": 418.1759999915958
      },
      {
          "time_stamp_utc_ms": 1734244260000,
          "time": "2024-12-15T06:33:00Z",
          "energy": 36.28800000064075,
          "capacity": 435.456000007689
      },
      {
          "time_stamp_utc_ms": 1734244560000,
          "time": "2024-12-15T06:38:00Z",
          "energy": 35.32799999974668,
          "capacity": 423.93599999696016
      },
      {
          "time_stamp_utc_ms": 1734244860000,
          "time": "2024-12-15T06:43:00Z",
          "energy": 35.61599999945611,
          "capacity": 427.3919999934733
      },
      {
          "time_stamp_utc_ms": 1734245160000,
          "time": "2024-12-15T06:48:00Z",
          "energy": 36.768000001087785,
          "capacity": 441.2160000130535
      },
      {
          "time_stamp_utc_ms": 1734245460000,
          "time": "2024-12-15T06:53:00Z",
          "energy": 34.75199999846518,
          "capacity": 417.0239999815822
      },
      {
          "time_stamp_utc_ms": 1734245760000,
          "time": "2024-12-15T06:58:00Z",
          "energy": 37.05600000079721,
          "capacity": 444.6720000095666
      },
      {
          "time_stamp_utc_ms": 1734246060000,
          "time": "2024-12-15T07:03:00Z",
          "energy": 34.847999999299645,
          "capacity": 418.1759999915958
      },
      {
          "time_stamp_utc_ms": 1734246360000,
          "time": "2024-12-15T07:08:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1734246660000,
          "time": "2024-12-15T07:13:00Z",
          "energy": 35.23200000077486,
          "capacity": 422.7840000092983
      },
      {
          "time_stamp_utc_ms": 1734246960000,
          "time": "2024-12-15T07:18:00Z",
          "energy": 34.94399999920279,
          "capacity": 419.3279999904335
      },
      {
          "time_stamp_utc_ms": 1734247260000,
          "time": "2024-12-15T07:23:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1734247560000,
          "time": "2024-12-15T07:28:00Z",
          "energy": 34.56000000052154,
          "capacity": 414.7200000062585
      },
      {
          "time_stamp_utc_ms": 1734247860000,
          "time": "2024-12-15T07:33:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1734248160000,
          "time": "2024-12-15T07:38:00Z",
          "energy": 35.71200000029057,
          "capacity": 428.5440000034869
      },
      {
          "time_stamp_utc_ms": 1734248460000,
          "time": "2024-12-15T07:43:00Z",
          "energy": 36.19200000073761,
          "capacity": 434.3040000088513
      },
      {
          "time_stamp_utc_ms": 1734248760000,
          "time": "2024-12-15T07:48:00Z",
          "energy": 37.05599999893457,
          "capacity": 444.6719999872148
      },
      {
          "time_stamp_utc_ms": 1734249060000,
          "time": "2024-12-15T07:53:00Z",
          "energy": 35.42399999964982,
          "capacity": 425.0879999957979
      },
      {
          "time_stamp_utc_ms": 1734249360000,
          "time": "2024-12-15T07:58:00Z",
          "energy": 36.00000000093132,
          "capacity": 432.00000001117587
      },
      {
          "time_stamp_utc_ms": 1734249660000,
          "time": "2024-12-15T08:03:00Z",
          "energy": 36.09599999897182,
          "capacity": 433.15199998766184
      },
      {
          "time_stamp_utc_ms": 1734249960000,
          "time": "2024-12-15T08:08:00Z",
          "energy": 35.712000001221895,
          "capacity": 428.54400001466274
      },
      {
          "time_stamp_utc_ms": 1734250260000,
          "time": "2024-12-15T08:13:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1734250560000,
          "time": "2024-12-15T08:18:00Z",
          "energy": 34.46399999875575,
          "capacity": 413.5679999850691
      },
      {
          "time_stamp_utc_ms": 1734250860000,
          "time": "2024-12-15T08:23:00Z",
          "energy": 36.5760000012815,
          "capacity": 438.91200001537806
      },
      {
          "time_stamp_utc_ms": 1734251160000,
          "time": "2024-12-15T08:28:00Z",
          "energy": 35.4239999987185,
          "capacity": 425.08799998462206
      },
      {
          "time_stamp_utc_ms": 1734251460000,
          "time": "2024-12-15T08:33:00Z",
          "energy": 35.61600000038743,
          "capacity": 427.39200000464916
      },
      {
          "time_stamp_utc_ms": 1734251760000,
          "time": "2024-12-15T08:38:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1734252060000,
          "time": "2024-12-15T08:43:00Z",
          "energy": 34.94400000013411,
          "capacity": 419.3280000016093
      },
      {
          "time_stamp_utc_ms": 1734252360000,
          "time": "2024-12-15T08:48:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1734252660000,
          "time": "2024-12-15T08:53:00Z",
          "energy": 34.65600000042468,
          "capacity": 415.8720000050962
      },
      {
          "time_stamp_utc_ms": 1734252960000,
          "time": "2024-12-15T08:58:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1734253260000,
          "time": "2024-12-15T09:03:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1734253560000,
          "time": "2024-12-15T09:08:00Z",
          "energy": 35.23199999984354,
          "capacity": 422.78399999812245
      },
      {
          "time_stamp_utc_ms": 1734253860000,
          "time": "2024-12-15T09:13:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1734254160000,
          "time": "2024-12-15T09:18:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1734254460000,
          "time": "2024-12-15T09:23:00Z",
          "energy": 35.80800000112504,
          "capacity": 429.69600001350045
      },
      {
          "time_stamp_utc_ms": 1734254760000,
          "time": "2024-12-15T09:28:00Z",
          "energy": 35.61599999945611,
          "capacity": 427.3919999934733
      },
      {
          "time_stamp_utc_ms": 1734255060000,
          "time": "2024-12-15T09:33:00Z",
          "energy": 35.61599999945611,
          "capacity": 427.3919999934733
      },
      {
          "time_stamp_utc_ms": 1734255360000,
          "time": "2024-12-15T09:38:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1734255660000,
          "time": "2024-12-15T09:43:00Z",
          "energy": 35.32799999974668,
          "capacity": 423.93599999696016
      },
      {
          "time_stamp_utc_ms": 1734255960000,
          "time": "2024-12-15T09:48:00Z",
          "energy": 36.096000000834465,
          "capacity": 433.1520000100136
      },
      {
          "time_stamp_utc_ms": 1734256260000,
          "time": "2024-12-15T09:53:00Z",
          "energy": 35.903999999165535,
          "capacity": 430.8479999899864
      },
      {
          "time_stamp_utc_ms": 1734256560000,
          "time": "2024-12-15T09:58:00Z",
          "energy": 35.040000000968575,
          "capacity": 420.4800000116229
      },
      {
          "time_stamp_utc_ms": 1734256860000,
          "time": "2024-12-15T10:03:00Z",
          "energy": 36.38399999868125,
          "capacity": 436.60799998417497
      },
      {
          "time_stamp_utc_ms": 1734257160000,
          "time": "2024-12-15T10:08:00Z",
          "energy": 34.27200000081211,
          "capacity": 411.26400000974536
      },
      {
          "time_stamp_utc_ms": 1734257460000,
          "time": "2024-12-15T10:13:00Z",
          "energy": 35.71200000029057,
          "capacity": 428.5440000034869
      },
      {
          "time_stamp_utc_ms": 1734257760000,
          "time": "2024-12-15T10:18:00Z",
          "energy": 34.94399999920279,
          "capacity": 419.3279999904335
      },
      {
          "time_stamp_utc_ms": 1734258060000,
          "time": "2024-12-15T10:23:00Z",
          "energy": 36.096000000834465,
          "capacity": 433.1520000100136
      },
      {
          "time_stamp_utc_ms": 1734258360000,
          "time": "2024-12-15T10:28:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1734258660000,
          "time": "2024-12-15T10:33:00Z",
          "energy": 35.23199999984354,
          "capacity": 422.78399999812245
      },
      {
          "time_stamp_utc_ms": 1734258960000,
          "time": "2024-12-15T10:38:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1734259260000,
          "time": "2024-12-15T10:43:00Z",
          "energy": 35.23199999984354,
          "capacity": 422.78399999812245
      },
      {
          "time_stamp_utc_ms": 1734259560000,
          "time": "2024-12-15T10:48:00Z",
          "energy": 35.903999999165535,
          "capacity": 430.8479999899864
      },
      {
          "time_stamp_utc_ms": 1734259860000,
          "time": "2024-12-15T10:53:00Z",
          "energy": 35.71200000029057,
          "capacity": 428.5440000034869
      },
      {
          "time_stamp_utc_ms": 1734260160000,
          "time": "2024-12-15T10:58:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1734260460000,
          "time": "2024-12-15T11:03:00Z",
          "energy": 36.28800000157207,
          "capacity": 435.4560000188649
      },
      {
          "time_stamp_utc_ms": 1734260760000,
          "time": "2024-12-15T11:08:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1734261060000,
          "time": "2024-12-15T11:13:00Z",
          "energy": 35.61599999945611,
          "capacity": 427.3919999934733
      },
      {
          "time_stamp_utc_ms": 1734261360000,
          "time": "2024-12-15T11:18:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1734261660000,
          "time": "2024-12-15T11:23:00Z",
          "energy": 35.42399999964982,
          "capacity": 425.0879999957979
      },
      {
          "time_stamp_utc_ms": 1734261960000,
          "time": "2024-12-15T11:28:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1734262260000,
          "time": "2024-12-15T11:33:00Z",
          "energy": 35.32799999974668,
          "capacity": 423.93599999696016
      },
      {
          "time_stamp_utc_ms": 1734262560000,
          "time": "2024-12-15T11:38:00Z",
          "energy": 36.287999998778105,
          "capacity": 435.45599998533726
      },
      {
          "time_stamp_utc_ms": 1734262860000,
          "time": "2024-12-15T11:43:00Z",
          "energy": 36.672000002115965,
          "capacity": 440.06400002539164
      },
      {
          "time_stamp_utc_ms": 1734263160000,
          "time": "2024-12-15T11:48:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1734263460000,
          "time": "2024-12-15T11:53:00Z",
          "energy": 36.671999999322,
          "capacity": 440.06399999186397
      },
      {
          "time_stamp_utc_ms": 1734263760000,
          "time": "2024-12-15T11:58:00Z",
          "energy": 34.7519999993965,
          "capacity": 417.0239999927581
      },
      {
          "time_stamp_utc_ms": 1734264060000,
          "time": "2024-12-15T12:03:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1734264360000,
          "time": "2024-12-15T12:08:00Z",
          "energy": 34.65600000042468,
          "capacity": 415.8720000050962
      },
      {
          "time_stamp_utc_ms": 1734264660000,
          "time": "2024-12-15T12:13:00Z",
          "energy": 35.71200000029057,
          "capacity": 428.5440000034869
      },
      {
          "time_stamp_utc_ms": 1734264960000,
          "time": "2024-12-15T12:18:00Z",
          "energy": 36.575999999418855,
          "capacity": 438.91199999302626
      },
      {
          "time_stamp_utc_ms": 1734265260000,
          "time": "2024-12-15T12:23:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1734265560000,
          "time": "2024-12-15T12:28:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1734265860000,
          "time": "2024-12-15T12:33:00Z",
          "energy": 35.13600000087172,
          "capacity": 421.6320000104606
      },
      {
          "time_stamp_utc_ms": 1734266160000,
          "time": "2024-12-15T12:38:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1734266460000,
          "time": "2024-12-15T12:43:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1734266760000,
          "time": "2024-12-15T12:48:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1734267060000,
          "time": "2024-12-15T12:53:00Z",
          "energy": 35.71199999935925,
          "capacity": 428.543999992311
      },
      {
          "time_stamp_utc_ms": 1734267360000,
          "time": "2024-12-15T12:58:00Z",
          "energy": 34.65599999949336,
          "capacity": 415.8719999939204
      },
      {
          "time_stamp_utc_ms": 1734267660000,
          "time": "2024-12-15T13:03:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1734267960000,
          "time": "2024-12-15T13:08:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1734268260000,
          "time": "2024-12-15T13:13:00Z",
          "energy": 34.56000000145286,
          "capacity": 414.72000001743436
      },
      {
          "time_stamp_utc_ms": 1734268560000,
          "time": "2024-12-15T13:18:00Z",
          "energy": 35.99999999906868,
          "capacity": 431.99999998882413
      },
      {
          "time_stamp_utc_ms": 1734268860000,
          "time": "2024-12-15T13:23:00Z",
          "energy": 33.60000000055879,
          "capacity": 403.2000000067056
      },
      {
          "time_stamp_utc_ms": 1734269160000,
          "time": "2024-12-15T13:28:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1734269460000,
          "time": "2024-12-15T13:33:00Z",
          "energy": 34.27199999894947,
          "capacity": 411.2639999873937
      },
      {
          "time_stamp_utc_ms": 1734269760000,
          "time": "2024-12-15T13:38:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1734270060000,
          "time": "2024-12-15T13:43:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1734270360000,
          "time": "2024-12-15T13:48:00Z",
          "energy": 33.024000000208616,
          "capacity": 396.28800000250345
      },
      {
          "time_stamp_utc_ms": 1734270660000,
          "time": "2024-12-15T13:53:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1734270960000,
          "time": "2024-12-15T13:58:00Z",
          "energy": 31.872000001370907,
          "capacity": 382.46400001645094
      },
      {
          "time_stamp_utc_ms": 1734271260000,
          "time": "2024-12-15T14:03:00Z",
          "energy": 32.351999999023974,
          "capacity": 388.2239999882877
      },
      {
          "time_stamp_utc_ms": 1734271560000,
          "time": "2024-12-15T14:08:00Z",
          "energy": 32.44799999985844,
          "capacity": 389.37599999830127
      },
      {
          "time_stamp_utc_ms": 1734271860000,
          "time": "2024-12-15T14:13:00Z",
          "energy": 31.104000000283122,
          "capacity": 373.24800000339746
      },
      {
          "time_stamp_utc_ms": 1734272160000,
          "time": "2024-12-15T14:18:00Z",
          "energy": 32.06400000024587,
          "capacity": 384.76800000295043
      },
      {
          "time_stamp_utc_ms": 1734272460000,
          "time": "2024-12-15T14:23:00Z",
          "energy": 31.96799999848008,
          "capacity": 383.615999981761
      },
      {
          "time_stamp_utc_ms": 1734272760000,
          "time": "2024-12-15T14:28:00Z",
          "energy": 32.06400000024587,
          "capacity": 384.76800000295043
      },
      {
          "time_stamp_utc_ms": 1734273060000,
          "time": "2024-12-15T14:33:00Z",
          "energy": 32.06400000024587,
          "capacity": 384.76800000295043
      },
      {
          "time_stamp_utc_ms": 1734273360000,
          "time": "2024-12-15T14:38:00Z",
          "energy": 31.200000001117587,
          "capacity": 374.40000001341105
      },
      {
          "time_stamp_utc_ms": 1734273660000,
          "time": "2024-12-15T14:43:00Z",
          "energy": 31.199999999254942,
          "capacity": 374.3999999910593
      },
      {
          "time_stamp_utc_ms": 1734273960000,
          "time": "2024-12-15T14:48:00Z",
          "energy": 29.567999999970198,
          "capacity": 354.81599999964243
      },
      {
          "time_stamp_utc_ms": 1734274260000,
          "time": "2024-12-15T14:53:00Z",
          "energy": 29.760000000707805,
          "capacity": 357.12000000849366
      },
      {
          "time_stamp_utc_ms": 1734274560000,
          "time": "2024-12-15T14:58:00Z",
          "energy": 30.04799999948591,
          "capacity": 360.575999993831
      },
      {
          "time_stamp_utc_ms": 1734274860000,
          "time": "2024-12-15T15:03:00Z",
          "energy": 28.512000000104308,
          "capacity": 342.14400000125175
      },
      {
          "time_stamp_utc_ms": 1734275160000,
          "time": "2024-12-15T15:08:00Z",
          "energy": 30.432000000029802,
          "capacity": 365.1840000003576
      },
      {
          "time_stamp_utc_ms": 1734275460000,
          "time": "2024-12-15T15:13:00Z",
          "energy": 28.41600000113249,
          "capacity": 340.9920000135899
      },
      {
          "time_stamp_utc_ms": 1734275760000,
          "time": "2024-12-15T15:18:00Z",
          "energy": 29.759999997913837,
          "capacity": 357.11999997496605
      },
      {
          "time_stamp_utc_ms": 1734276060000,
          "time": "2024-12-15T15:23:00Z",
          "energy": 28.8960000006482,
          "capacity": 346.75200000777846
      },
      {
          "time_stamp_utc_ms": 1734276360000,
          "time": "2024-12-15T15:28:00Z",
          "energy": 28.704000000841916,
          "capacity": 344.44800001010304
      },
      {
          "time_stamp_utc_ms": 1734276660000,
          "time": "2024-12-15T15:33:00Z",
          "energy": 29.183999999426305,
          "capacity": 350.2079999931157
      },
      {
          "time_stamp_utc_ms": 1734276960000,
          "time": "2024-12-15T15:38:00Z",
          "energy": 28.031999999657273,
          "capacity": 336.3839999958873
      },
      {
          "time_stamp_utc_ms": 1734277260000,
          "time": "2024-12-15T15:43:00Z",
          "energy": 28.895999999716878,
          "capacity": 346.7519999966026
      },
      {
          "time_stamp_utc_ms": 1734277560000,
          "time": "2024-12-15T15:48:00Z",
          "energy": 27.552000001072884,
          "capacity": 330.62400001287466
      },
      {
          "time_stamp_utc_ms": 1734277860000,
          "time": "2024-12-15T15:53:00Z",
          "energy": 27.64799999911338,
          "capacity": 331.77599998936057
      },
      {
          "time_stamp_utc_ms": 1734278160000,
          "time": "2024-12-15T15:58:00Z",
          "energy": 28.608000000938773,
          "capacity": 343.29600001126533
      },
      {
          "time_stamp_utc_ms": 1734278460000,
          "time": "2024-12-15T16:03:00Z",
          "energy": 27.93599999882281,
          "capacity": 335.2319999858737
      },
      {
          "time_stamp_utc_ms": 1734278760000,
          "time": "2024-12-15T16:08:00Z",
          "energy": 28.41600000113249,
          "capacity": 340.9920000135899
      },
      {
          "time_stamp_utc_ms": 1734279060000,
          "time": "2024-12-15T16:13:00Z",
          "energy": 28.703999999910593,
          "capacity": 344.4479999989272
      },
      {
          "time_stamp_utc_ms": 1734279360000,
          "time": "2024-12-15T16:18:00Z",
          "energy": 28.320000000298023,
          "capacity": 339.84000000357634
      },
      {
          "time_stamp_utc_ms": 1734279660000,
          "time": "2024-12-15T16:23:00Z",
          "energy": 28.991999998688698,
          "capacity": 347.9039999842644
      },
      {
          "time_stamp_utc_ms": 1734279960000,
          "time": "2024-12-15T16:28:00Z",
          "energy": 26.592000000178814,
          "capacity": 319.10400000214577
      },
      {
          "time_stamp_utc_ms": 1734280260000,
          "time": "2024-12-15T16:33:00Z",
          "energy": 29.28000000026077,
          "capacity": 351.3600000031293
      },
      {
          "time_stamp_utc_ms": 1734280560000,
          "time": "2024-12-15T16:38:00Z",
          "energy": 26.87999999988824,
          "capacity": 322.5599999986589
      },
      {
          "time_stamp_utc_ms": 1734280860000,
          "time": "2024-12-15T16:43:00Z",
          "energy": 26.208000000566244,
          "capacity": 314.49600000679493
      },
      {
          "time_stamp_utc_ms": 1734281160000,
          "time": "2024-12-15T16:48:00Z",
          "energy": 26.87999999988824,
          "capacity": 322.5599999986589
      },
      {
          "time_stamp_utc_ms": 1734281460000,
          "time": "2024-12-15T16:53:00Z",
          "energy": 24.959999999962747,
          "capacity": 299.519999999553
      },
      {
          "time_stamp_utc_ms": 1734281760000,
          "time": "2024-12-15T16:58:00Z",
          "energy": 26.49600000027567,
          "capacity": 317.95200000330806
      },
      {
          "time_stamp_utc_ms": 1734282060000,
          "time": "2024-12-15T17:03:00Z",
          "energy": 23.135999999009073,
          "capacity": 277.6319999881089
      },
      {
          "time_stamp_utc_ms": 1734282360000,
          "time": "2024-12-15T17:08:00Z",
          "energy": 25.247999999672174,
          "capacity": 302.97599999606615
      },
      {
          "time_stamp_utc_ms": 1734282660000,
          "time": "2024-12-15T17:13:00Z",
          "energy": 25.34400000050664,
          "capacity": 304.1280000060797
      },
      {
          "time_stamp_utc_ms": 1734282960000,
          "time": "2024-12-15T17:18:00Z",
          "energy": 24.384000000543892,
          "capacity": 292.60800000652677
      },
      {
          "time_stamp_utc_ms": 1734283260000,
          "time": "2024-12-15T17:23:00Z",
          "energy": 25.535999999381602,
          "capacity": 306.4319999925793
      },
      {
          "time_stamp_utc_ms": 1734283560000,
          "time": "2024-12-15T17:28:00Z",
          "energy": 23.90400000102818,
          "capacity": 286.8480000123382
      },
      {
          "time_stamp_utc_ms": 1734283860000,
          "time": "2024-12-15T17:33:00Z",
          "energy": 23.903999999165535,
          "capacity": 286.8479999899864
      },
      {
          "time_stamp_utc_ms": 1734284160000,
          "time": "2024-12-15T17:38:00Z",
          "energy": 23.03999999910593,
          "capacity": 276.47999998927116
      },
      {
          "time_stamp_utc_ms": 1734284460000,
          "time": "2024-12-15T17:43:00Z",
          "energy": 22.656000001356006,
          "capacity": 271.87200001627207
      },
      {
          "time_stamp_utc_ms": 1734284760000,
          "time": "2024-12-15T17:48:00Z",
          "energy": 25.343999999575317,
          "capacity": 304.12799999490386
      },
      {
          "time_stamp_utc_ms": 1734285060000,
          "time": "2024-12-15T17:53:00Z",
          "energy": 27.648000000976026,
          "capacity": 331.77600001171237
      },
      {
          "time_stamp_utc_ms": 1734285360000,
          "time": "2024-12-15T17:58:00Z",
          "energy": 27.64799999911338,
          "capacity": 331.77599998936057
      },
      {
          "time_stamp_utc_ms": 1734285660000,
          "time": "2024-12-15T18:03:00Z",
          "energy": 25.536000000312924,
          "capacity": 306.4320000037551
      },
      {
          "time_stamp_utc_ms": 1734285960000,
          "time": "2024-12-15T18:08:00Z",
          "energy": 22.943999999202788,
          "capacity": 275.32799999043345
      },
      {
          "time_stamp_utc_ms": 1734286260000,
          "time": "2024-12-15T18:13:00Z",
          "energy": 24.864000000059605,
          "capacity": 298.3680000007153
      },
      {
          "time_stamp_utc_ms": 1734286560000,
          "time": "2024-12-15T18:18:00Z",
          "energy": 22.848000000230968,
          "capacity": 274.1760000027716
      },
      {
          "time_stamp_utc_ms": 1734286860000,
          "time": "2024-12-15T18:23:00Z",
          "energy": 23.808000000193715,
          "capacity": 285.6960000023246
      },
      {
          "time_stamp_utc_ms": 1734287160000,
          "time": "2024-12-15T18:28:00Z",
          "energy": 22.56000000052154,
          "capacity": 270.7200000062585
      },
      {
          "time_stamp_utc_ms": 1734287460000,
          "time": "2024-12-15T18:33:00Z",
          "energy": 23.615999999456108,
          "capacity": 283.3919999934733
      },
      {
          "time_stamp_utc_ms": 1734287760000,
          "time": "2024-12-15T18:38:00Z",
          "energy": 25.440000000409782,
          "capacity": 305.2800000049174
      },
      {
          "time_stamp_utc_ms": 1734288060000,
          "time": "2024-12-15T18:43:00Z",
          "energy": 25.82400000002235,
          "capacity": 309.8880000002682
      },
      {
          "time_stamp_utc_ms": 1734288360000,
          "time": "2024-12-15T18:48:00Z",
          "energy": 25.439999998547137,
          "capacity": 305.2799999825657
      },
      {
          "time_stamp_utc_ms": 1734288660000,
          "time": "2024-12-15T18:53:00Z",
          "energy": 22.752000001259148,
          "capacity": 273.0240000151098
      },
      {
          "time_stamp_utc_ms": 1734288960000,
          "time": "2024-12-15T18:58:00Z",
          "energy": 24.959999999962747,
          "capacity": 299.519999999553
      },
      {
          "time_stamp_utc_ms": 1734289260000,
          "time": "2024-12-15T19:03:00Z",
          "energy": 25.343999999575317,
          "capacity": 304.12799999490386
      },
      {
          "time_stamp_utc_ms": 1734289560000,
          "time": "2024-12-15T19:08:00Z",
          "energy": 23.520000000484288,
          "capacity": 282.24000000581145
      },
      {
          "time_stamp_utc_ms": 1734289860000,
          "time": "2024-12-15T19:13:00Z",
          "energy": 24.38399999961257,
          "capacity": 292.6079999953509
      },
      {
          "time_stamp_utc_ms": 1734290160000,
          "time": "2024-12-15T19:18:00Z",
          "energy": 24.384000000543892,
          "capacity": 292.60800000652677
      },
      {
          "time_stamp_utc_ms": 1734290460000,
          "time": "2024-12-15T19:23:00Z",
          "energy": 27.45600000023842,
          "capacity": 329.472000002861
      },
      {
          "time_stamp_utc_ms": 1734290760000,
          "time": "2024-12-15T19:28:00Z",
          "energy": 24.575999999418855,
          "capacity": 294.9119999930263
      },
      {
          "time_stamp_utc_ms": 1734291060000,
          "time": "2024-12-15T19:33:00Z",
          "energy": 25.535999999381602,
          "capacity": 306.4319999925793
      },
      {
          "time_stamp_utc_ms": 1734291300000,
          "time": "2024-12-15T19:38:00Z",
          "energy": 21.74400000087917,
          "capacity": 326.1600000131875
      },
      {
          "time_stamp_utc_ms": 1734291660000,
          "time": "2024-12-15T19:43:00Z",
          "energy": 31.151999999769032,
          "capacity": 311.51999999769026
      },
      {
          "time_stamp_utc_ms": 1734291920000,
          "time": "2024-12-15T19:48:00Z",
          "energy": 26.20799999963492,
          "capacity": 362.87998668458647
      },
      {
          "time_stamp_utc_ms": 1734292290000,
          "time": "2024-12-15T19:53:00Z",
          "energy": 40.80000000074506,
          "capacity": 396.9729832121935
      },
      {
          "time_stamp_utc_ms": 1734292560000,
          "time": "2024-12-15T19:58:00Z",
          "energy": 26.399999999441206,
          "capacity": 351.9999999925494
      },
      {
          "time_stamp_utc_ms": 1734292875000,
          "time": "2024-12-15T20:03:00Z",
          "energy": 29.520000000484288,
          "capacity": 337.3714285769633
      },
      {
          "time_stamp_utc_ms": 1734293420000,
          "time": "2024-12-15T20:13:00Z",
          "energy": 49.67999999970198,
          "capacity": 328.16147937268005
      },
      {
          "time_stamp_utc_ms": 1734293790000,
          "time": "2024-12-15T20:18:00Z",
          "energy": 33.12000000011176,
          "capacity": 322.2486569556892
      },
      {
          "time_stamp_utc_ms": 1734294060000,
          "time": "2024-12-15T20:23:00Z",
          "energy": 25.600000000558794,
          "capacity": 341.3333333407839
      },
      {
          "time_stamp_utc_ms": 1734294340000,
          "time": "2024-12-15T20:28:00Z",
          "energy": 27.03999999910593,
          "capacity": 347.6571546867798
      },
      {
          "time_stamp_utc_ms": 1734294630000,
          "time": "2024-12-15T20:33:00Z",
          "energy": 27.28000000026077,
          "capacity": 338.64826472874915
      },
      {
          "time_stamp_utc_ms": 1734295260000,
          "time": "2024-12-15T20:43:00Z",
          "energy": 64.87999999988824,
          "capacity": 370.7428571422185
      },
      {
          "time_stamp_utc_ms": 1734295880000,
          "time": "2024-12-15T20:53:00Z",
          "energy": 62.87999999988824,
          "capacity": 365.10968865082634
      },
      {
          "time_stamp_utc_ms": 1734296100000,
          "time": "2024-12-15T20:58:00Z",
          "energy": 22.240000000223517,
          "capacity": 363.9272648430192
      },
      {
          "time_stamp_utc_ms": 1734296550000,
          "time": "2024-12-15T21:03:00Z",
          "energy": 47.75999999977648,
          "capacity": 382.07999999821186
      },
      {
          "time_stamp_utc_ms": 1734296790000,
          "time": "2024-12-15T21:08:00Z",
          "energy": 24.96000000089407,
          "capacity": 374.40000001341105
      },
      {
          "time_stamp_utc_ms": 1734297030000,
          "time": "2024-12-15T21:13:00Z",
          "energy": 25.43999999947846,
          "capacity": 381.5999999921769
      },
      {
          "time_stamp_utc_ms": 1734297300000,
          "time": "2024-12-15T21:18:00Z",
          "energy": 30.16000000014901,
          "capacity": 402.1333333353202
      },
      {
          "time_stamp_utc_ms": 1734297675000,
          "time": "2024-12-15T21:23:00Z",
          "energy": 39.43999999947846,
          "capacity": 378.6239999949932
      },
      {
          "time_stamp_utc_ms": 1734297945000,
          "time": "2024-12-15T21:28:00Z",
          "energy": 30.12000000011176,
          "capacity": 401.6000000014901
      },
      {
          "time_stamp_utc_ms": 1734298290000,
          "time": "2024-12-15T21:33:00Z",
          "energy": 36.0,
          "capacity": 375.65217391304344
      },
      {
          "time_stamp_utc_ms": 1734298500000,
          "time": "2024-12-15T21:38:00Z",
          "energy": 22.919999999925494,
          "capacity": 392.91428571300844
      },
      {
          "time_stamp_utc_ms": 1734298860000,
          "time": "2024-12-15T21:43:00Z",
          "energy": 39.360000000335276,
          "capacity": 393.60000000335276
      },
      {
          "time_stamp_utc_ms": 1734299520000,
          "time": "2024-12-15T21:53:00Z",
          "energy": 72.95999999996275,
          "capacity": 397.9636363634332
      },
      {
          "time_stamp_utc_ms": 1734299730000,
          "time": "2024-12-15T21:58:00Z",
          "energy": 22.799999999813735,
          "capacity": 390.8571428539497
      },
      {
          "time_stamp_utc_ms": 1734300060000,
          "time": "2024-12-15T22:03:00Z",
          "energy": 37.200000000186265,
          "capacity": 405.8181818202138
      },
      {
          "time_stamp_utc_ms": 1734300390000,
          "time": "2024-12-15T22:08:00Z",
          "energy": 36.0,
          "capacity": 392.72727272727275
      },
      {
          "time_stamp_utc_ms": 1734300660000,
          "time": "2024-12-15T22:13:00Z",
          "energy": 28.640000000596046,
          "capacity": 381.8666666746139
      },
      {
          "time_stamp_utc_ms": 1734300900000,
          "time": "2024-12-15T22:18:00Z",
          "energy": 26.559999999590218,
          "capacity": 398.39999999385327
      },
      {
          "time_stamp_utc_ms": 1734301275000,
          "time": "2024-12-15T22:23:00Z",
          "energy": 39.83999999985099,
          "capacity": 382.4639999985695
      },
      {
          "time_stamp_utc_ms": 1734301520000,
          "time": "2024-12-15T22:28:00Z",
          "energy": 26.399999999441206,
          "capacity": 387.91835223881895
      },
      {
          "time_stamp_utc_ms": 1734301860000,
          "time": "2024-12-15T22:33:00Z",
          "energy": 37.92000000085682,
          "capacity": 401.5058936239503
      },
      {
          "time_stamp_utc_ms": 1734302190000,
          "time": "2024-12-15T22:38:00Z",
          "energy": 35.75999999977648,
          "capacity": 390.10909090665257
      },
      {
          "time_stamp_utc_ms": 1734302370000,
          "time": "2024-12-15T22:43:00Z",
          "energy": 19.919999999925494,
          "capacity": 398.3999999985099
      },
      {
          "time_stamp_utc_ms": 1734302640000,
          "time": "2024-12-15T22:48:00Z",
          "energy": 29.28000000026077,
          "capacity": 390.4000000034769
      },
      {
          "time_stamp_utc_ms": 1734302970000,
          "time": "2024-12-15T22:53:00Z",
          "energy": 35.04000000003725,
          "capacity": 382.2545454549519
      },
      {
          "time_stamp_utc_ms": 1734303420000,
          "time": "2024-12-15T22:58:00Z",
          "energy": 48.47999999951571,
          "capacity": 387.8399999961257
      },
      {
          "time_stamp_utc_ms": 1734303780000,
          "time": "2024-12-15T23:03:00Z",
          "energy": 39.360000000335276,
          "capacity": 393.60000000335276
      },
      {
          "time_stamp_utc_ms": 1734303960000,
          "time": "2024-12-15T23:08:00Z",
          "energy": 20.639999999664724,
          "capacity": 412.7999999932945
      },
      {
          "time_stamp_utc_ms": 1734304290000,
          "time": "2024-12-15T23:13:00Z",
          "energy": 34.799999999813735,
          "capacity": 379.63636363433164
      },
      {
          "time_stamp_utc_ms": 1734304590000,
          "time": "2024-12-15T23:18:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1734304880000,
          "time": "2024-12-15T23:23:00Z",
          "energy": 29.679999999701977,
          "capacity": 368.44136719033247
      },
      {
          "time_stamp_utc_ms": 1734305175000,
          "time": "2024-12-15T23:28:00Z",
          "energy": 32.360000000335276,
          "capacity": 394.9017076857056
      },
      {
          "time_stamp_utc_ms": 1734305490000,
          "time": "2024-12-15T23:33:00Z",
          "energy": 33.95999999996275,
          "capacity": 388.11428571385994
      },
      {
          "time_stamp_utc_ms": 1734305760000,
          "time": "2024-12-15T23:38:00Z",
          "energy": 28.752000000327826,
          "capacity": 383.360000004371
      },
      {
          "time_stamp_utc_ms": 1734306060000,
          "time": "2024-12-15T23:43:00Z",
          "energy": 32.83199999947101,
          "capacity": 393.9839999936521
      },
      {
          "time_stamp_utc_ms": 1734306360000,
          "time": "2024-12-15T23:48:00Z",
          "energy": 31.872000000439584,
          "capacity": 382.464000005275
      },
      {
          "time_stamp_utc_ms": 1734306660000,
          "time": "2024-12-15T23:53:00Z",
          "energy": 32.544000000692904,
          "capacity": 390.5280000083149
      },
      {
          "time_stamp_utc_ms": 1734306900000,
          "time": "2024-12-15T23:58:00Z",
          "energy": 25.75999999884516,
          "capacity": 386.3999999826774
      },
      {
          "time_stamp_utc_ms": 1734307320000,
          "time": "2024-12-16T00:03:00Z",
          "energy": 44.80000000074506,
          "capacity": 384.0000000063862
      },
      {
          "time_stamp_utc_ms": 1734307560000,
          "time": "2024-12-16T00:08:00Z",
          "energy": 26.15999999921769,
          "capacity": 392.39999998826534
      },
      {
          "time_stamp_utc_ms": 1734307820000,
          "time": "2024-12-16T00:13:00Z",
          "energy": 27.760000000707805,
          "capacity": 384.3692166804523
      },
      {
          "time_stamp_utc_ms": 1734308220000,
          "time": "2024-12-16T00:18:00Z",
          "energy": 43.75999999977648,
          "capacity": 393.8400093878659
      },
      {
          "time_stamp_utc_ms": 1734308475000,
          "time": "2024-12-16T00:23:00Z",
          "energy": 28.079999999143183,
          "capacity": 396.4235293996685
      },
      {
          "time_stamp_utc_ms": 1734308760000,
          "time": "2024-12-16T00:28:00Z",
          "energy": 30.816000000573695,
          "capacity": 389.2547368493519
      },
      {
          "time_stamp_utc_ms": 1734309060000,
          "time": "2024-12-16T00:33:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1734309360000,
          "time": "2024-12-16T00:38:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1734309660000,
          "time": "2024-12-16T00:43:00Z",
          "energy": 33.69599999953061,
          "capacity": 404.3519999943674
      },
      {
          "time_stamp_utc_ms": 1734309960000,
          "time": "2024-12-16T00:48:00Z",
          "energy": 31.872000000439584,
          "capacity": 382.464000005275
      },
      {
          "time_stamp_utc_ms": 1734310260000,
          "time": "2024-12-16T00:53:00Z",
          "energy": 32.92799999937415,
          "capacity": 395.1359999924898
      },
      {
          "time_stamp_utc_ms": 1734310560000,
          "time": "2024-12-16T00:58:00Z",
          "energy": 32.73600000049919,
          "capacity": 392.8320000059903
      },
      {
          "time_stamp_utc_ms": 1734310860000,
          "time": "2024-12-16T01:03:00Z",
          "energy": 31.87199999950826,
          "capacity": 382.46399999409914
      },
      {
          "time_stamp_utc_ms": 1734311160000,
          "time": "2024-12-16T01:08:00Z",
          "energy": 33.60000000055879,
          "capacity": 403.2000000067056
      },
      {
          "time_stamp_utc_ms": 1734311550000,
          "time": "2024-12-16T01:13:00Z",
          "energy": 41.42399999964982,
          "capacity": 382.37538461215223
      },
      {
          "time_stamp_utc_ms": 1734311760000,
          "time": "2024-12-16T01:18:00Z",
          "energy": 23.520000000484288,
          "capacity": 403.200000008302
      },
      {
          "time_stamp_utc_ms": 1734311970000,
          "time": "2024-12-16T01:23:00Z",
          "energy": 22.559999999590218,
          "capacity": 386.7428571358323
      },
      {
          "time_stamp_utc_ms": 1734312360000,
          "time": "2024-12-16T01:28:00Z",
          "energy": 41.87999999988824,
          "capacity": 386.58461538358375
      },
      {
          "time_stamp_utc_ms": 1734312660000,
          "time": "2024-12-16T01:33:00Z",
          "energy": 33.24000000022352,
          "capacity": 398.8800000026822
      },
      {
          "time_stamp_utc_ms": 1734312960000,
          "time": "2024-12-16T01:38:00Z",
          "energy": 33.35999999940395,
          "capacity": 400.31999999284744
      },
      {
          "time_stamp_utc_ms": 1734313290000,
          "time": "2024-12-16T01:43:00Z",
          "energy": 37.919999999925494,
          "capacity": 413.6727272719145
      },
      {
          "time_stamp_utc_ms": 1734313580000,
          "time": "2024-12-16T01:48:00Z",
          "energy": 31.760000000707805,
          "capacity": 394.2620560088694
      },
      {
          "time_stamp_utc_ms": 1734313840000,
          "time": "2024-12-16T01:53:00Z",
          "energy": 27.83999999985099,
          "capacity": 385.4769089356511
      },
      {
          "time_stamp_utc_ms": 1734314100000,
          "time": "2024-12-16T01:58:00Z",
          "energy": 28.959999999962747,
          "capacity": 400.9846006760721
      },
      {
          "time_stamp_utc_ms": 1734314520000,
          "time": "2024-12-16T02:03:00Z",
          "energy": 44.16000000014901,
          "capacity": 378.51428571556295
      },
      {
          "time_stamp_utc_ms": 1734314740000,
          "time": "2024-12-16T02:08:00Z",
          "energy": 24.639999999664724,
          "capacity": 403.1999912553892
      },
      {
          "time_stamp_utc_ms": 1734315060000,
          "time": "2024-12-16T02:13:00Z",
          "energy": 34.30400000046939,
          "capacity": 385.9199885039687
      },
      {
          "time_stamp_utc_ms": 1734315390000,
          "time": "2024-12-16T02:18:00Z",
          "energy": 35.61599999945611,
          "capacity": 388.53818181224847
      },
      {
          "time_stamp_utc_ms": 1734315660000,
          "time": "2024-12-16T02:23:00Z",
          "energy": 29.664000000804663,
          "capacity": 395.52000001072884
      },
      {
          "time_stamp_utc_ms": 1734315960000,
          "time": "2024-12-16T02:28:00Z",
          "energy": 31.487999999895692,
          "capacity": 377.8559999987483
      },
      {
          "time_stamp_utc_ms": 1734316260000,
          "time": "2024-12-16T02:33:00Z",
          "energy": 33.407999999821186,
          "capacity": 400.8959999978543
      },
      {
          "time_stamp_utc_ms": 1734316560000,
          "time": "2024-12-16T02:38:00Z",
          "energy": 31.77599999960512,
          "capacity": 381.31199999526143
      },
      {
          "time_stamp_utc_ms": 1734316860000,
          "time": "2024-12-16T02:43:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1734317160000,
          "time": "2024-12-16T02:48:00Z",
          "energy": 34.17600000090897,
          "capacity": 410.11200001090765
      },
      {
          "time_stamp_utc_ms": 1734317460000,
          "time": "2024-12-16T02:53:00Z",
          "energy": 32.54399999976158,
          "capacity": 390.527999997139
      },
      {
          "time_stamp_utc_ms": 1734317730000,
          "time": "2024-12-16T02:58:00Z",
          "energy": 30.0,
          "capacity": 399.99999999999994
      },
      {
          "time_stamp_utc_ms": 1734318420000,
          "time": "2024-12-16T03:08:00Z",
          "energy": 74.63999999966472,
          "capacity": 389.4260869547725
      },
      {
          "time_stamp_utc_ms": 1734318660000,
          "time": "2024-12-16T03:13:00Z",
          "energy": 26.303999999538064,
          "capacity": 394.55999999307096
      },
      {
          "time_stamp_utc_ms": 1734318990000,
          "time": "2024-12-16T03:18:00Z",
          "energy": 35.85600000061095,
          "capacity": 391.15636364302856
      },
      {
          "time_stamp_utc_ms": 1734319230000,
          "time": "2024-12-16T03:23:00Z",
          "energy": 26.999999999068677,
          "capacity": 404.99999998603016
      },
      {
          "time_stamp_utc_ms": 1734319590000,
          "time": "2024-12-16T03:28:00Z",
          "energy": 38.76000000163913,
          "capacity": 387.6000000163913
      },
      {
          "time_stamp_utc_ms": 1734319880000,
          "time": "2024-12-16T03:33:00Z",
          "energy": 31.91999999899417,
          "capacity": 396.2482628188314
      },
      {
          "time_stamp_utc_ms": 1734320040000,
          "time": "2024-12-16T03:38:00Z",
          "energy": 17.760000000707805,
          "capacity": 399.599988106918
      },
      {
          "time_stamp_utc_ms": 1734320475000,
          "time": "2024-12-16T03:43:00Z",
          "energy": 47.639999999664724,
          "capacity": 394.26206896274255
      },
      {
          "time_stamp_utc_ms": 1734320775000,
          "time": "2024-12-16T03:48:00Z",
          "energy": 34.200000000186265,
          "capacity": 410.40000000223523
      },
      {
          "time_stamp_utc_ms": 1734321090000,
          "time": "2024-12-16T03:53:00Z",
          "energy": 34.19999999925494,
          "capacity": 390.8571428486279
      },
      {
          "time_stamp_utc_ms": 1734321360000,
          "time": "2024-12-16T03:58:00Z",
          "energy": 30.552000001072884,
          "capacity": 407.3600000143051
      },
      {
          "time_stamp_utc_ms": 1734321630000,
          "time": "2024-12-16T04:03:00Z",
          "energy": 29.807999999262393,
          "capacity": 397.43999999016523
      },
      {
          "time_stamp_utc_ms": 1734321990000,
          "time": "2024-12-16T04:08:00Z",
          "energy": 40.32000000029802,
          "capacity": 403.20000000298023
      },
      {
          "time_stamp_utc_ms": 1734322350000,
          "time": "2024-12-16T04:13:00Z",
          "energy": 40.3199999993667,
          "capacity": 403.19999999366695
      },
      {
          "time_stamp_utc_ms": 1734322520000,
          "time": "2024-12-16T04:18:00Z",
          "energy": 19.28000000026077,
          "capacity": 408.28236439871057
      },
      {
          "time_stamp_utc_ms": 1734322920000,
          "time": "2024-12-16T04:23:00Z",
          "energy": 46.24000000022352,
          "capacity": 416.16000992403946
      },
      {
          "time_stamp_utc_ms": 1734323190000,
          "time": "2024-12-16T04:28:00Z",
          "energy": 29.15999999921769,
          "capacity": 388.7999999895692
      },
      {
          "time_stamp_utc_ms": 1734323460000,
          "time": "2024-12-16T04:33:00Z",
          "energy": 30.07200000062585,
          "capacity": 400.96000000834465
      },
      {
          "time_stamp_utc_ms": 1734323760000,
          "time": "2024-12-16T04:38:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1734324060000,
          "time": "2024-12-16T04:43:00Z",
          "energy": 33.407999999821186,
          "capacity": 400.8959999978543
      },
      {
          "time_stamp_utc_ms": 1734324360000,
          "time": "2024-12-16T04:48:00Z",
          "energy": 34.175999999046326,
          "capacity": 410.11199998855597
      },
      {
          "time_stamp_utc_ms": 1734324660000,
          "time": "2024-12-16T04:53:00Z",
          "energy": 34.17600000090897,
          "capacity": 410.11200001090765
      },
      {
          "time_stamp_utc_ms": 1734324960000,
          "time": "2024-12-16T04:58:00Z",
          "energy": 33.59999999962747,
          "capacity": 403.1999999955297
      },
      {
          "time_stamp_utc_ms": 1734325260000,
          "time": "2024-12-16T05:03:00Z",
          "energy": 35.328000000678,
          "capacity": 423.93600000813603
      },
      {
          "time_stamp_utc_ms": 1734325560000,
          "time": "2024-12-16T05:08:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1734325860000,
          "time": "2024-12-16T05:13:00Z",
          "energy": 34.7519999993965,
          "capacity": 417.0239999927581
      },
      {
          "time_stamp_utc_ms": 1734326100000,
          "time": "2024-12-16T05:18:00Z",
          "energy": 26.84800000116229,
          "capacity": 402.72000001743436
      },
      {
          "time_stamp_utc_ms": 1734326490000,
          "time": "2024-12-16T05:23:00Z",
          "energy": 44.71999999973923,
          "capacity": 412.7999999975929
      },
      {
          "time_stamp_utc_ms": 1734326850000,
          "time": "2024-12-16T05:28:00Z",
          "energy": 40.799999999813735,
          "capacity": 407.9999999981373
      },
      {
          "time_stamp_utc_ms": 1734327040000,
          "time": "2024-12-16T05:33:00Z",
          "energy": 20.87999999988824,
          "capacity": 395.62104270068176
      },
      {
          "time_stamp_utc_ms": 1734327240000,
          "time": "2024-12-16T05:38:00Z",
          "energy": 23.040000000037253,
          "capacity": 414.72000988836606
      },
      {
          "time_stamp_utc_ms": 1734328050000,
          "time": "2024-12-16T05:48:00Z",
          "energy": 92.3999999994412,
          "capacity": 410.66666666418314
      },
      {
          "time_stamp_utc_ms": 1734328230000,
          "time": "2024-12-16T05:53:00Z",
          "energy": 20.40000000037253,
          "capacity": 408.0000000074506
      },
      {
          "time_stamp_utc_ms": 1734328580000,
          "time": "2024-12-16T05:58:00Z",
          "energy": 38.87999999988824,
          "capacity": 399.9085605307784
      },
      {
          "time_stamp_utc_ms": 1734328860000,
          "time": "2024-12-16T06:03:00Z",
          "energy": 31.776000000536442,
          "capacity": 408.5485853505504
      },
      {
          "time_stamp_utc_ms": 1734329040000,
          "time": "2024-12-16T06:08:00Z",
          "energy": 20.063999999314547,
          "capacity": 401.2799999862909
      },
      {
          "time_stamp_utc_ms": 1734329460000,
          "time": "2024-12-16T06:13:00Z",
          "energy": 46.27200000081211,
          "capacity": 396.6171428641038
      },
      {
          "time_stamp_utc_ms": 1734329760000,
          "time": "2024-12-16T06:18:00Z",
          "energy": 33.69599999953061,
          "capacity": 404.3519999943674
      },
      {
          "time_stamp_utc_ms": 1734330060000,
          "time": "2024-12-16T06:23:00Z",
          "energy": 32.83200000040233,
          "capacity": 393.98400000482803
      },
      {
          "time_stamp_utc_ms": 1734330360000,
          "time": "2024-12-16T06:28:00Z",
          "energy": 34.46399999875575,
          "capacity": 413.5679999850691
      },
      {
          "time_stamp_utc_ms": 1734330660000,
          "time": "2024-12-16T06:33:00Z",
          "energy": 33.02400000113994,
          "capacity": 396.2880000136793
      },
      {
          "time_stamp_utc_ms": 1734330960000,
          "time": "2024-12-16T06:38:00Z",
          "energy": 34.27199999988079,
          "capacity": 411.26399999856955
      },
      {
          "time_stamp_utc_ms": 1734331260000,
          "time": "2024-12-16T06:43:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1734331560000,
          "time": "2024-12-16T06:48:00Z",
          "energy": 33.79200000036508,
          "capacity": 405.504000004381
      },
      {
          "time_stamp_utc_ms": 1734331860000,
          "time": "2024-12-16T06:53:00Z",
          "energy": 34.84800000023097,
          "capacity": 418.1760000027716
      },
      {
          "time_stamp_utc_ms": 1734332160000,
          "time": "2024-12-16T06:58:00Z",
          "energy": 32.735999998636544,
          "capacity": 392.8319999836385
      },
      {
          "time_stamp_utc_ms": 1734332460000,
          "time": "2024-12-16T07:03:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734332760000,
          "time": "2024-12-16T07:08:00Z",
          "energy": 32.83200000040233,
          "capacity": 393.98400000482803
      },
      {
          "time_stamp_utc_ms": 1734333060000,
          "time": "2024-12-16T07:13:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1734333360000,
          "time": "2024-12-16T07:18:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1734333660000,
          "time": "2024-12-16T07:23:00Z",
          "energy": 33.69599999953061,
          "capacity": 404.3519999943674
      },
      {
          "time_stamp_utc_ms": 1734333960000,
          "time": "2024-12-16T07:28:00Z",
          "energy": 35.424000000581145,
          "capacity": 425.08800000697374
      },
      {
          "time_stamp_utc_ms": 1734334260000,
          "time": "2024-12-16T07:33:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1734334560000,
          "time": "2024-12-16T07:38:00Z",
          "energy": 34.175999999046326,
          "capacity": 410.11199998855597
      },
      {
          "time_stamp_utc_ms": 1734334860000,
          "time": "2024-12-16T07:43:00Z",
          "energy": 33.984000001102686,
          "capacity": 407.80800001323223
      },
      {
          "time_stamp_utc_ms": 1734335130000,
          "time": "2024-12-16T07:48:00Z",
          "energy": 30.240000000223517,
          "capacity": 403.20000000298023
      },
      {
          "time_stamp_utc_ms": 1734335550000,
          "time": "2024-12-16T07:53:00Z",
          "energy": 48.0,
          "capacity": 411.4285714285714
      },
      {
          "time_stamp_utc_ms": 1734335775000,
          "time": "2024-12-16T07:58:00Z",
          "energy": 24.959999999031425,
          "capacity": 399.3599999845028
      },
      {
          "time_stamp_utc_ms": 1734336045000,
          "time": "2024-12-16T08:03:00Z",
          "energy": 30.59999999962747,
          "capacity": 407.9999999950329
      },
      {
          "time_stamp_utc_ms": 1734336340000,
          "time": "2024-12-16T08:08:00Z",
          "energy": 33.40000000130385,
          "capacity": 407.59323353154525
      },
      {
          "time_stamp_utc_ms": 1734336750000,
          "time": "2024-12-16T08:13:00Z",
          "energy": 45.679999998770654,
          "capacity": 401.09267358647935
      },
      {
          "time_stamp_utc_ms": 1734336900000,
          "time": "2024-12-16T08:18:00Z",
          "energy": 17.84000000078231,
          "capacity": 428.1600000187755
      },
      {
          "time_stamp_utc_ms": 1734337260000,
          "time": "2024-12-16T08:23:00Z",
          "energy": 39.04000000003725,
          "capacity": 390.40000000037253
      },
      {
          "time_stamp_utc_ms": 1734337580000,
          "time": "2024-12-16T08:28:00Z",
          "energy": 36.799999999813735,
          "capacity": 413.9999876597434
      },
      {
          "time_stamp_utc_ms": 1734337830000,
          "time": "2024-12-16T08:33:00Z",
          "energy": 27.760000000707805,
          "capacity": 399.7440152592164
      },
      {
          "time_stamp_utc_ms": 1734338250000,
          "time": "2024-12-16T08:38:00Z",
          "energy": 47.27999999932945,
          "capacity": 405.25714285139526
      },
      {
          "time_stamp_utc_ms": 1734338460000,
          "time": "2024-12-16T08:43:00Z",
          "energy": 23.83999999985099,
          "capacity": 408.68571428315977
      },
      {
          "time_stamp_utc_ms": 1734338790000,
          "time": "2024-12-16T08:48:00Z",
          "energy": 36.64000000059605,
          "capacity": 399.70909091559326
      },
      {
          "time_stamp_utc_ms": 1734339060000,
          "time": "2024-12-16T08:53:00Z",
          "energy": 31.007999999448657,
          "capacity": 413.4399999926487
      },
      {
          "time_stamp_utc_ms": 1734339360000,
          "time": "2024-12-16T08:58:00Z",
          "energy": 33.311999999918044,
          "capacity": 399.7439999990166
      },
      {
          "time_stamp_utc_ms": 1734339630000,
          "time": "2024-12-16T09:03:00Z",
          "energy": 30.71999999973923,
          "capacity": 409.599999996523
      },
      {
          "time_stamp_utc_ms": 1734339900000,
          "time": "2024-12-16T09:08:00Z",
          "energy": 30.720000000670552,
          "capacity": 409.60000000894064
      },
      {
          "time_stamp_utc_ms": 1734340230000,
          "time": "2024-12-16T09:13:00Z",
          "energy": 36.72000000067055,
          "capacity": 400.5818181891333
      },
      {
          "time_stamp_utc_ms": 1734340500000,
          "time": "2024-12-16T09:18:00Z",
          "energy": 30.47999999858439,
          "capacity": 406.3999999811252
      },
      {
          "time_stamp_utc_ms": 1734340890000,
          "time": "2024-12-16T09:23:00Z",
          "energy": 43.440000001341105,
          "capacity": 400.9846153969948
      },
      {
          "time_stamp_utc_ms": 1734341160000,
          "time": "2024-12-16T09:28:00Z",
          "energy": 32.87999999895692,
          "capacity": 438.39999998609227
      },
      {
          "time_stamp_utc_ms": 1734341460000,
          "time": "2024-12-16T09:33:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1734341780000,
          "time": "2024-12-16T09:38:00Z",
          "energy": 40.89599999971688,
          "capacity": 460.0799862853628
      },
      {
          "time_stamp_utc_ms": 1734342060000,
          "time": "2024-12-16T09:43:00Z",
          "energy": 37.536000000312924,
          "capacity": 482.6057307271908
      },
      {
          "time_stamp_utc_ms": 1734342360000,
          "time": "2024-12-16T09:48:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1734342660000,
          "time": "2024-12-16T09:53:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1734342960000,
          "time": "2024-12-16T09:58:00Z",
          "energy": 40.99199999962002,
          "capacity": 491.9039999954403
      },
      {
          "time_stamp_utc_ms": 1734343260000,
          "time": "2024-12-16T10:03:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1734343560000,
          "time": "2024-12-16T10:08:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1734343860000,
          "time": "2024-12-16T10:13:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1734344160000,
          "time": "2024-12-16T10:18:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1734344460000,
          "time": "2024-12-16T10:23:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1734344760000,
          "time": "2024-12-16T10:28:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1734345060000,
          "time": "2024-12-16T10:33:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1734345360000,
          "time": "2024-12-16T10:38:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1734345660000,
          "time": "2024-12-16T10:43:00Z",
          "energy": 41.183999999426305,
          "capacity": 494.2079999931157
      },
      {
          "time_stamp_utc_ms": 1734345960000,
          "time": "2024-12-16T10:48:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1734346260000,
          "time": "2024-12-16T10:53:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1734346560000,
          "time": "2024-12-16T10:58:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1734346860000,
          "time": "2024-12-16T11:03:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1734347160000,
          "time": "2024-12-16T11:08:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1734347460000,
          "time": "2024-12-16T11:13:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1734347760000,
          "time": "2024-12-16T11:18:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1734348040000,
          "time": "2024-12-16T11:23:00Z",
          "energy": 37.66400000080466,
          "capacity": 484.25144507528023
      },
      {
          "time_stamp_utc_ms": 1734348320000,
          "time": "2024-12-16T11:28:00Z",
          "energy": 36.80000000074506,
          "capacity": 473.1428732675867
      },
      {
          "time_stamp_utc_ms": 1734348780000,
          "time": "2024-12-16T11:33:00Z",
          "energy": 63.67999999970198,
          "capacity": 498.36522772110504
      },
      {
          "time_stamp_utc_ms": 1734348960000,
          "time": "2024-12-16T11:38:00Z",
          "energy": 24.240000000223517,
          "capacity": 484.80000000447035
      },
      {
          "time_stamp_utc_ms": 1734349320000,
          "time": "2024-12-16T11:43:00Z",
          "energy": 50.15999999921769,
          "capacity": 501.5999999921769
      },
      {
          "time_stamp_utc_ms": 1734349650000,
          "time": "2024-12-16T11:48:00Z",
          "energy": 45.84000000078231,
          "capacity": 500.07272728126156
      },
      {
          "time_stamp_utc_ms": 1734349840000,
          "time": "2024-12-16T11:53:00Z",
          "energy": 25.519999999552965,
          "capacity": 483.53682996161785
      },
      {
          "time_stamp_utc_ms": 1734350250000,
          "time": "2024-12-16T11:58:00Z",
          "energy": 56.799999999813735,
          "capacity": 498.7316957147643
      },
      {
          "time_stamp_utc_ms": 1734350430000,
          "time": "2024-12-16T12:03:00Z",
          "energy": 23.759999999776483,
          "capacity": 475.19999999552965
      },
      {
          "time_stamp_utc_ms": 1734350700000,
          "time": "2024-12-16T12:08:00Z",
          "energy": 39.3600000012666,
          "capacity": 524.800000016888
      },
      {
          "time_stamp_utc_ms": 1734351000000,
          "time": "2024-12-16T12:13:00Z",
          "energy": 43.59999999962747,
          "capacity": 523.1999999955297
      },
      {
          "time_stamp_utc_ms": 1734351390000,
          "time": "2024-12-16T12:18:00Z",
          "energy": 53.35999999940395,
          "capacity": 492.55384614834423
      },
      {
          "time_stamp_utc_ms": 1734351750000,
          "time": "2024-12-16T12:23:00Z",
          "energy": 51.360000000335276,
          "capacity": 513.6000000033528
      },
      {
          "time_stamp_utc_ms": 1734351990000,
          "time": "2024-12-16T12:28:00Z",
          "energy": 33.83999999985099,
          "capacity": 507.5999999977648
      },
      {
          "time_stamp_utc_ms": 1734352260000,
          "time": "2024-12-16T12:33:00Z",
          "energy": 38.30400000046939,
          "capacity": 510.7200000062585
      },
      {
          "time_stamp_utc_ms": 1734352560000,
          "time": "2024-12-16T12:38:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1734352860000,
          "time": "2024-12-16T12:43:00Z",
          "energy": 42.623999998904765,
          "capacity": 511.4879999868572
      },
      {
          "time_stamp_utc_ms": 1734353160000,
          "time": "2024-12-16T12:48:00Z",
          "energy": 43.20000000111759,
          "capacity": 518.400000013411
      },
      {
          "time_stamp_utc_ms": 1734353460000,
          "time": "2024-12-16T12:53:00Z",
          "energy": 42.623999998904765,
          "capacity": 511.4879999868572
      },
      {
          "time_stamp_utc_ms": 1734353760000,
          "time": "2024-12-16T12:58:00Z",
          "energy": 42.62400000076741,
          "capacity": 511.4880000092089
      },
      {
          "time_stamp_utc_ms": 1734354060000,
          "time": "2024-12-16T13:03:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1734354360000,
          "time": "2024-12-16T13:08:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1734354660000,
          "time": "2024-12-16T13:13:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1734354960000,
          "time": "2024-12-16T13:18:00Z",
          "energy": 40.8960000006482,
          "capacity": 490.7520000077784
      },
      {
          "time_stamp_utc_ms": 1734355260000,
          "time": "2024-12-16T13:23:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1734355560000,
          "time": "2024-12-16T13:28:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1734355860000,
          "time": "2024-12-16T13:33:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1734356160000,
          "time": "2024-12-16T13:38:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1734356460000,
          "time": "2024-12-16T13:43:00Z",
          "energy": 41.28000000119209,
          "capacity": 495.3600000143052
      },
      {
          "time_stamp_utc_ms": 1734356760000,
          "time": "2024-12-16T13:48:00Z",
          "energy": 43.295999999158084,
          "capacity": 519.551999989897
      },
      {
          "time_stamp_utc_ms": 1734357060000,
          "time": "2024-12-16T13:53:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1734357360000,
          "time": "2024-12-16T13:58:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1734357660000,
          "time": "2024-12-16T14:03:00Z",
          "energy": 41.472000000067055,
          "capacity": 497.66400000080466
      },
      {
          "time_stamp_utc_ms": 1734357960000,
          "time": "2024-12-16T14:08:00Z",
          "energy": 40.3199999993667,
          "capacity": 483.8399999924004
      },
      {
          "time_stamp_utc_ms": 1734358260000,
          "time": "2024-12-16T14:13:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1734358560000,
          "time": "2024-12-16T14:18:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1734358860000,
          "time": "2024-12-16T14:23:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1734359160000,
          "time": "2024-12-16T14:28:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1734359460000,
          "time": "2024-12-16T14:33:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1734359760000,
          "time": "2024-12-16T14:38:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1734360060000,
          "time": "2024-12-16T14:43:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1734360360000,
          "time": "2024-12-16T14:48:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1734360660000,
          "time": "2024-12-16T14:53:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1734360960000,
          "time": "2024-12-16T14:58:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1734361260000,
          "time": "2024-12-16T15:03:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1734361560000,
          "time": "2024-12-16T15:08:00Z",
          "energy": 36.28800000064075,
          "capacity": 435.456000007689
      },
      {
          "time_stamp_utc_ms": 1734361860000,
          "time": "2024-12-16T15:13:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1734362160000,
          "time": "2024-12-16T15:18:00Z",
          "energy": 36.86400000099093,
          "capacity": 442.3680000118912
      },
      {
          "time_stamp_utc_ms": 1734362460000,
          "time": "2024-12-16T15:23:00Z",
          "energy": 38.30399999860674,
          "capacity": 459.6479999832809
      },
      {
          "time_stamp_utc_ms": 1734362760000,
          "time": "2024-12-16T15:28:00Z",
          "energy": 37.440000001341105,
          "capacity": 449.2800000160933
      },
      {
          "time_stamp_utc_ms": 1734363060000,
          "time": "2024-12-16T15:33:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1734363360000,
          "time": "2024-12-16T15:38:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1734363660000,
          "time": "2024-12-16T15:43:00Z",
          "energy": 36.38399999961257,
          "capacity": 436.60799999535084
      },
      {
          "time_stamp_utc_ms": 1734363960000,
          "time": "2024-12-16T15:48:00Z",
          "energy": 37.92000000085682,
          "capacity": 455.04000001028186
      },
      {
          "time_stamp_utc_ms": 1734364260000,
          "time": "2024-12-16T15:53:00Z",
          "energy": 36.09599999990314,
          "capacity": 433.1519999988377
      },
      {
          "time_stamp_utc_ms": 1734364560000,
          "time": "2024-12-16T15:58:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1734364860000,
          "time": "2024-12-16T16:03:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1734365160000,
          "time": "2024-12-16T16:08:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1734365400000,
          "time": "2024-12-16T16:13:00Z",
          "energy": 31.87199999950826,
          "capacity": 478.0799999926239
      },
      {
          "time_stamp_utc_ms": 1734365730000,
          "time": "2024-12-16T16:18:00Z",
          "energy": 42.24000000022352,
          "capacity": 460.8000000024384
      },
      {
          "time_stamp_utc_ms": 1734366080000,
          "time": "2024-12-16T16:23:00Z",
          "energy": 41.43999999947846,
          "capacity": 426.23998838051756
      },
      {
          "time_stamp_utc_ms": 1734366420000,
          "time": "2024-12-16T16:28:00Z",
          "energy": 42.55999999959022,
          "capacity": 450.63530675328815
      },
      {
          "time_stamp_utc_ms": 1734366600000,
          "time": "2024-12-16T16:33:00Z",
          "energy": 21.600000000558794,
          "capacity": 432.00000001117587
      },
      {
          "time_stamp_utc_ms": 1734366930000,
          "time": "2024-12-16T16:38:00Z",
          "energy": 42.959999999031425,
          "capacity": 468.65454544397915
      },
      {
          "time_stamp_utc_ms": 1734367260000,
          "time": "2024-12-16T16:43:00Z",
          "energy": 39.79200000036508,
          "capacity": 434.09454545852816
      },
      {
          "time_stamp_utc_ms": 1734367560000,
          "time": "2024-12-16T16:48:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1734367830000,
          "time": "2024-12-16T16:53:00Z",
          "energy": 36.816000000573695,
          "capacity": 490.8800000076492
      },
      {
          "time_stamp_utc_ms": 1734368160000,
          "time": "2024-12-16T16:58:00Z",
          "energy": 43.44000000040978,
          "capacity": 473.89090909537947
      },
      {
          "time_stamp_utc_ms": 1734368520000,
          "time": "2024-12-16T17:03:00Z",
          "energy": 46.56000000052154,
          "capacity": 465.6000000052154
      },
      {
          "time_stamp_utc_ms": 1734368790000,
          "time": "2024-12-16T17:08:00Z",
          "energy": 35.75999999977648,
          "capacity": 476.79999999701977
      },
      {
          "time_stamp_utc_ms": 1734369030000,
          "time": "2024-12-16T17:13:00Z",
          "energy": 32.16000000014901,
          "capacity": 482.4000000022352
      },
      {
          "time_stamp_utc_ms": 1734369300000,
          "time": "2024-12-16T17:18:00Z",
          "energy": 35.919999999925494,
          "capacity": 478.9333333323399
      },
      {
          "time_stamp_utc_ms": 1734369645000,
          "time": "2024-12-16T17:23:00Z",
          "energy": 45.55999999959022,
          "capacity": 475.4086956478979
      },
      {
          "time_stamp_utc_ms": 1734369930000,
          "time": "2024-12-16T17:28:00Z",
          "energy": 38.760000000707805,
          "capacity": 489.6000000089407
      },
      {
          "time_stamp_utc_ms": 1734370260000,
          "time": "2024-12-16T17:33:00Z",
          "energy": 44.6399999987334,
          "capacity": 486.9818181680008
      },
      {
          "time_stamp_utc_ms": 1734370545000,
          "time": "2024-12-16T17:38:00Z",
          "energy": 39.0,
          "capacity": 492.6315789473683
      },
      {
          "time_stamp_utc_ms": 1734370830000,
          "time": "2024-12-16T17:43:00Z",
          "energy": 38.28000000119209,
          "capacity": 483.5368421203211
      },
      {
          "time_stamp_utc_ms": 1734371160000,
          "time": "2024-12-16T17:48:00Z",
          "energy": 42.07999999914318,
          "capacity": 459.0545454451984
      },
      {
          "time_stamp_utc_ms": 1734371430000,
          "time": "2024-12-16T17:53:00Z",
          "energy": 37.83999999985099,
          "capacity": 504.53333333134645
      },
      {
          "time_stamp_utc_ms": 1734371740000,
          "time": "2024-12-16T17:58:00Z",
          "energy": 41.6800000006333,
          "capacity": 484.02582134938655
      },
      {
          "time_stamp_utc_ms": 1734372000000,
          "time": "2024-12-16T18:03:00Z",
          "energy": 33.919999999925494,
          "capacity": 469.66152123342493
      },
      {
          "time_stamp_utc_ms": 1734372270000,
          "time": "2024-12-16T18:08:00Z",
          "energy": 36.959999999031425,
          "capacity": 492.7999999870856
      },
      {
          "time_stamp_utc_ms": 1734372640000,
          "time": "2024-12-16T18:13:00Z",
          "energy": 48.160000001080334,
          "capacity": 468.5837958720339
      },
      {
          "time_stamp_utc_ms": 1734372920000,
          "time": "2024-12-16T18:18:00Z",
          "energy": 36.799999999813735,
          "capacity": 473.1428732556126
      },
      {
          "time_stamp_utc_ms": 1734373275000,
          "time": "2024-12-16T18:23:00Z",
          "energy": 45.71999999973923,
          "capacity": 463.6394490723143
      },
      {
          "time_stamp_utc_ms": 1734373560000,
          "time": "2024-12-16T18:28:00Z",
          "energy": 35.208000000566244,
          "capacity": 444.7326315860999
      },
      {
          "time_stamp_utc_ms": 1734373860000,
          "time": "2024-12-16T18:33:00Z",
          "energy": 38.49599999934435,
          "capacity": 461.95199999213224
      },
      {
          "time_stamp_utc_ms": 1734374160000,
          "time": "2024-12-16T18:38:00Z",
          "energy": 41.088000000454485,
          "capacity": 493.0560000054538
      },
      {
          "time_stamp_utc_ms": 1734374460000,
          "time": "2024-12-16T18:43:00Z",
          "energy": 41.8559999987483,
          "capacity": 502.2719999849797
      },
      {
          "time_stamp_utc_ms": 1734374760000,
          "time": "2024-12-16T18:48:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1734375060000,
          "time": "2024-12-16T18:53:00Z",
          "energy": 40.99200000055134,
          "capacity": 491.9040000066161
      },
      {
          "time_stamp_utc_ms": 1734375360000,
          "time": "2024-12-16T18:58:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1734375660000,
          "time": "2024-12-16T19:03:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1734375960000,
          "time": "2024-12-16T19:08:00Z",
          "energy": 37.92000000085682,
          "capacity": 455.04000001028186
      },
      {
          "time_stamp_utc_ms": 1734376260000,
          "time": "2024-12-16T19:13:00Z",
          "energy": 33.21599999908358,
          "capacity": 398.59199998900294
      },
      {
          "time_stamp_utc_ms": 1734376560000,
          "time": "2024-12-16T19:18:00Z",
          "energy": 34.55999999959022,
          "capacity": 414.7199999950827
      },
      {
          "time_stamp_utc_ms": 1734376860000,
          "time": "2024-12-16T19:23:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1734377160000,
          "time": "2024-12-16T19:28:00Z",
          "energy": 34.75200000125915,
          "capacity": 417.0240000151098
      },
      {
          "time_stamp_utc_ms": 1734377460000,
          "time": "2024-12-16T19:33:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1734377760000,
          "time": "2024-12-16T19:38:00Z",
          "energy": 35.61600000038743,
          "capacity": 427.39200000464916
      },
      {
          "time_stamp_utc_ms": 1734378060000,
          "time": "2024-12-16T19:43:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1734378360000,
          "time": "2024-12-16T19:48:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1734378660000,
          "time": "2024-12-16T19:53:00Z",
          "energy": 38.495999998413026,
          "capacity": 461.9519999809563
      },
      {
          "time_stamp_utc_ms": 1734378960000,
          "time": "2024-12-16T19:58:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1734379260000,
          "time": "2024-12-16T20:03:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1734379560000,
          "time": "2024-12-16T20:08:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1734379770000,
          "time": "2024-12-16T20:13:00Z",
          "energy": 28.848000000230968,
          "capacity": 494.5371428611022
      },
      {
          "time_stamp_utc_ms": 1734380160000,
          "time": "2024-12-16T20:18:00Z",
          "energy": 53.52000000048429,
          "capacity": 494.03076923523963
      },
      {
          "time_stamp_utc_ms": 1734380520000,
          "time": "2024-12-16T20:23:00Z",
          "energy": 48.639999999664724,
          "capacity": 486.3999999966472
      },
      {
          "time_stamp_utc_ms": 1734380760000,
          "time": "2024-12-16T20:28:00Z",
          "energy": 33.200000000186265,
          "capacity": 498.00000000279397
      },
      {
          "time_stamp_utc_ms": 1734381360000,
          "time": "2024-12-16T20:38:00Z",
          "energy": 83.75999999977648,
          "capacity": 502.55999999865895
      },
      {
          "time_stamp_utc_ms": 1734381600000,
          "time": "2024-12-16T20:43:00Z",
          "energy": 32.639999999664724,
          "capacity": 489.59999999497086
      },
      {
          "time_stamp_utc_ms": 1734381930000,
          "time": "2024-12-16T20:48:00Z",
          "energy": 44.40000000037253,
          "capacity": 484.3636363677003
      },
      {
          "time_stamp_utc_ms": 1734382320000,
          "time": "2024-12-16T20:53:00Z",
          "energy": 51.59999999962747,
          "capacity": 476.3076923042536
      },
      {
          "time_stamp_utc_ms": 1734382560000,
          "time": "2024-12-16T20:58:00Z",
          "energy": 32.28000000026077,
          "capacity": 484.20000000391155
      },
      {
          "time_stamp_utc_ms": 1734382920000,
          "time": "2024-12-16T21:03:00Z",
          "energy": 46.919999999925494,
          "capacity": 469.1999999992549
      },
      {
          "time_stamp_utc_ms": 1734383175000,
          "time": "2024-12-16T21:08:00Z",
          "energy": 34.92000000085682,
          "capacity": 492.98823530621394
      },
      {
          "time_stamp_utc_ms": 1734383480000,
          "time": "2024-12-16T21:13:00Z",
          "energy": 40.59999999869615,
          "capacity": 479.21309975466846
      },
      {
          "time_stamp_utc_ms": 1734384090000,
          "time": "2024-12-16T21:23:00Z",
          "energy": 83.36000000033528,
          "capacity": 491.96064035705314
      },
      {
          "time_stamp_utc_ms": 1734384345000,
          "time": "2024-12-16T21:28:00Z",
          "energy": 34.32000000029802,
          "capacity": 484.5176470630309
      },
      {
          "time_stamp_utc_ms": 1734384660000,
          "time": "2024-12-16T21:33:00Z",
          "energy": 44.87999999988824,
          "capacity": 512.9142857130084
      },
      {
          "time_stamp_utc_ms": 1734384990000,
          "time": "2024-12-16T21:38:00Z",
          "energy": 44.639999999664724,
          "capacity": 486.98181817816067
      },
      {
          "time_stamp_utc_ms": 1734385290000,
          "time": "2024-12-16T21:43:00Z",
          "energy": 42.480000000447035,
          "capacity": 509.7600000053645
      },
      {
          "time_stamp_utc_ms": 1734385560000,
          "time": "2024-12-16T21:48:00Z",
          "energy": 36.71999999973923,
          "capacity": 489.599999996523
      },
      {
          "time_stamp_utc_ms": 1734385800000,
          "time": "2024-12-16T21:53:00Z",
          "energy": 32.80000000074506,
          "capacity": 492.00000001117587
      },
      {
          "time_stamp_utc_ms": 1734386220000,
          "time": "2024-12-16T21:58:00Z",
          "energy": 60.47999999951571,
          "capacity": 518.3999999958489
      },
      {
          "time_stamp_utc_ms": 1734386520000,
          "time": "2024-12-16T22:03:00Z",
          "energy": 41.59999999962747,
          "capacity": 499.19999999552965
      },
      {
          "time_stamp_utc_ms": 1734386760000,
          "time": "2024-12-16T22:08:00Z",
          "energy": 34.080000000074506,
          "capacity": 511.2000000011176
      },
      {
          "time_stamp_utc_ms": 1734387030000,
          "time": "2024-12-16T22:13:00Z",
          "energy": 39.480000000447035,
          "capacity": 526.4000000059605
      },
      {
          "time_stamp_utc_ms": 1734387345000,
          "time": "2024-12-16T22:18:00Z",
          "energy": 44.87999999988824,
          "capacity": 512.9142857130084
      },
      {
          "time_stamp_utc_ms": 1734387600000,
          "time": "2024-12-16T22:23:00Z",
          "energy": 36.59999999962747,
          "capacity": 516.7058823476818
      },
      {
          "time_stamp_utc_ms": 1734388260000,
          "time": "2024-12-16T22:33:00Z",
          "energy": 93.40800000075251,
          "capacity": 509.4981818222865
      },
      {
          "time_stamp_utc_ms": 1734388560000,
          "time": "2024-12-16T22:38:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1734388860000,
          "time": "2024-12-16T22:43:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1734389160000,
          "time": "2024-12-16T22:48:00Z",
          "energy": 42.816000000573695,
          "capacity": 513.7920000068843
      },
      {
          "time_stamp_utc_ms": 1734389460000,
          "time": "2024-12-16T22:53:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1734389740000,
          "time": "2024-12-16T22:58:00Z",
          "energy": 39.93599999975413,
          "capacity": 513.4628746281373
      },
      {
          "time_stamp_utc_ms": 1734389970000,
          "time": "2024-12-16T23:03:00Z",
          "energy": 31.919999999925494,
          "capacity": 499.6174016612748
      },
      {
          "time_stamp_utc_ms": 1734390400000,
          "time": "2024-12-16T23:08:00Z",
          "energy": 60.24000000022352,
          "capacity": 504.33489490817914
      },
      {
          "time_stamp_utc_ms": 1734390660000,
          "time": "2024-12-16T23:13:00Z",
          "energy": 36.0,
          "capacity": 498.46152017807884
      },
      {
          "time_stamp_utc_ms": 1734391000000,
          "time": "2024-12-16T23:18:00Z",
          "energy": 47.19999999925494,
          "capacity": 499.76471989248694
      },
      {
          "time_stamp_utc_ms": 1734391260000,
          "time": "2024-12-16T23:23:00Z",
          "energy": 36.22400000039488,
          "capacity": 501.56305853132113
      },
      {
          "time_stamp_utc_ms": 1734391560000,
          "time": "2024-12-16T23:28:00Z",
          "energy": 41.05600000079721,
          "capacity": 492.67200000956655
      },
      {
          "time_stamp_utc_ms": 1734391800000,
          "time": "2024-12-16T23:33:00Z",
          "energy": 33.91999999899417,
          "capacity": 508.7999999849126
      },
      {
          "time_stamp_utc_ms": 1734392160000,
          "time": "2024-12-16T23:38:00Z",
          "energy": 49.92000000085682,
          "capacity": 499.2000000085681
      },
      {
          "time_stamp_utc_ms": 1734392445000,
          "time": "2024-12-16T23:43:00Z",
          "energy": 38.87999999895692,
          "capacity": 491.1157894605084
      },
      {
          "time_stamp_utc_ms": 1734392760000,
          "time": "2024-12-16T23:48:00Z",
          "energy": 44.16000000014901,
          "capacity": 504.6857142874172
      },
      {
          "time_stamp_utc_ms": 1734393320000,
          "time": "2024-12-16T23:58:00Z",
          "energy": 77.92000000085682,
          "capacity": 500.9143027808333
      },
      {
          "time_stamp_utc_ms": 1734393630000,
          "time": "2024-12-17T00:03:00Z",
          "energy": 45.43999999947846,
          "capacity": 527.6903388082892
      },
      {
          "time_stamp_utc_ms": 1734394050000,
          "time": "2024-12-17T00:08:00Z",
          "energy": 58.55999999959022,
          "capacity": 501.9428571393447
      },
      {
          "time_stamp_utc_ms": 1734394230000,
          "time": "2024-12-17T00:13:00Z",
          "energy": 25.919999999925494,
          "capacity": 518.3999999985098
      },
      {
          "time_stamp_utc_ms": 1734394580000,
          "time": "2024-12-17T00:18:00Z",
          "energy": 48.00000000093132,
          "capacity": 493.71427227121865
      },
      {
          "time_stamp_utc_ms": 1734394880000,
          "time": "2024-12-17T00:23:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1734395100000,
          "time": "2024-12-17T00:28:00Z",
          "energy": 30.71999999973923,
          "capacity": 502.6908981911101
      },
      {
          "time_stamp_utc_ms": 1734395460000,
          "time": "2024-12-17T00:33:00Z",
          "energy": 48.00000000093132,
          "capacity": 480.00000000931317
      },
      {
          "time_stamp_utc_ms": 1734395880000,
          "time": "2024-12-17T00:38:00Z",
          "energy": 58.07999999914318,
          "capacity": 497.82857142122725
      },
      {
          "time_stamp_utc_ms": 1734396075000,
          "time": "2024-12-17T00:43:00Z",
          "energy": 27.12000000011176,
          "capacity": 500.67692307898636
      },
      {
          "time_stamp_utc_ms": 1734396450000,
          "time": "2024-12-17T00:48:00Z",
          "energy": 52.799999999813735,
          "capacity": 506.87999999821193
      },
      {
          "time_stamp_utc_ms": 1734396570000,
          "time": "2024-12-17T00:53:00Z",
          "energy": 16.080000000074506,
          "capacity": 482.4000000022352
      },
      {
          "time_stamp_utc_ms": 1734396960000,
          "time": "2024-12-17T00:58:00Z",
          "energy": 50.87999999988824,
          "capacity": 469.6615384605069
      },
      {
          "time_stamp_utc_ms": 1734397260000,
          "time": "2024-12-17T01:03:00Z",
          "energy": 39.12000000104308,
          "capacity": 469.44000001251703
      },
      {
          "time_stamp_utc_ms": 1734397500000,
          "time": "2024-12-17T01:08:00Z",
          "energy": 29.519999999552965,
          "capacity": 442.7999999932945
      },
      {
          "time_stamp_utc_ms": 1734397800000,
          "time": "2024-12-17T01:13:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1734398180000,
          "time": "2024-12-17T01:18:00Z",
          "energy": 47.19999999925494,
          "capacity": 447.15788350759976
      },
      {
          "time_stamp_utc_ms": 1734398460000,
          "time": "2024-12-17T01:23:00Z",
          "energy": 35.04000000003725,
          "capacity": 450.51430105919036
      },
      {
          "time_stamp_utc_ms": 1734398740000,
          "time": "2024-12-17T01:28:00Z",
          "energy": 35.67999999970198,
          "capacity": 458.74287276371456
      },
      {
          "time_stamp_utc_ms": 1734399030000,
          "time": "2024-12-17T01:33:00Z",
          "energy": 35.20000000111759,
          "capacity": 436.9655028855019
      },
      {
          "time_stamp_utc_ms": 1734399360000,
          "time": "2024-12-17T01:38:00Z",
          "energy": 41.43999999947846,
          "capacity": 452.07272726703775
      },
      {
          "time_stamp_utc_ms": 1734399690000,
          "time": "2024-12-17T01:43:00Z",
          "energy": 39.55999999959022,
          "capacity": 431.563636359166
      },
      {
          "time_stamp_utc_ms": 1734399930000,
          "time": "2024-12-17T01:48:00Z",
          "energy": 29.40000000037253,
          "capacity": 441.00000000558794
      },
      {
          "time_stamp_utc_ms": 1734400290000,
          "time": "2024-12-17T01:53:00Z",
          "energy": 44.16000000014901,
          "capacity": 441.60000000149006
      },
      {
          "time_stamp_utc_ms": 1734400560000,
          "time": "2024-12-17T01:58:00Z",
          "energy": 32.32000000029802,
          "capacity": 430.933333337307
      },
      {
          "time_stamp_utc_ms": 1734400920000,
          "time": "2024-12-17T02:03:00Z",
          "energy": 44.95999999996275,
          "capacity": 449.5999999996274
      },
      {
          "time_stamp_utc_ms": 1734401160000,
          "time": "2024-12-17T02:08:00Z",
          "energy": 28.480000000447035,
          "capacity": 427.2000000067055
      },
      {
          "time_stamp_utc_ms": 1734401460000,
          "time": "2024-12-17T02:13:00Z",
          "energy": 37.639999999664724,
          "capacity": 451.6799999959767
      },
      {
          "time_stamp_utc_ms": 1734401730000,
          "time": "2024-12-17T02:18:00Z",
          "energy": 32.40000000037253,
          "capacity": 432.00000000496703
      },
      {
          "time_stamp_utc_ms": 1734402075000,
          "time": "2024-12-17T02:23:00Z",
          "energy": 41.6399999987334,
          "capacity": 434.50434781287026
      },
      {
          "time_stamp_utc_ms": 1734402360000,
          "time": "2024-12-17T02:28:00Z",
          "energy": 34.4640000006184,
          "capacity": 435.3347368499166
      },
      {
          "time_stamp_utc_ms": 1734402660000,
          "time": "2024-12-17T02:33:00Z",
          "energy": 35.71200000029057,
          "capacity": 428.5440000034869
      },
      {
          "time_stamp_utc_ms": 1734402960000,
          "time": "2024-12-17T02:38:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1734403260000,
          "time": "2024-12-17T02:43:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734403560000,
          "time": "2024-12-17T02:48:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1734403860000,
          "time": "2024-12-17T02:53:00Z",
          "energy": 36.47999999858439,
          "capacity": 437.7599999830127
      },
      {
          "time_stamp_utc_ms": 1734404160000,
          "time": "2024-12-17T02:58:00Z",
          "energy": 34.94400000106543,
          "capacity": 419.3280000127852
      },
      {
          "time_stamp_utc_ms": 1734404460000,
          "time": "2024-12-17T03:03:00Z",
          "energy": 35.71199999935925,
          "capacity": 428.543999992311
      },
      {
          "time_stamp_utc_ms": 1734404760000,
          "time": "2024-12-17T03:08:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1734405030000,
          "time": "2024-12-17T03:13:00Z",
          "energy": 31.583999999798834,
          "capacity": 421.11999999731773
      },
      {
          "time_stamp_utc_ms": 1734405330000,
          "time": "2024-12-17T03:18:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734405660000,
          "time": "2024-12-17T03:23:00Z",
          "energy": 39.360000000335276,
          "capacity": 429.3818181854757
      },
      {
          "time_stamp_utc_ms": 1734405960000,
          "time": "2024-12-17T03:28:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1734406260000,
          "time": "2024-12-17T03:33:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1734406560000,
          "time": "2024-12-17T03:38:00Z",
          "energy": 35.424000000581145,
          "capacity": 425.08800000697374
      },
      {
          "time_stamp_utc_ms": 1734406860000,
          "time": "2024-12-17T03:43:00Z",
          "energy": 34.94400000013411,
          "capacity": 419.3280000016093
      },
      {
          "time_stamp_utc_ms": 1734407140000,
          "time": "2024-12-17T03:48:00Z",
          "energy": 31.71199999935925,
          "capacity": 407.72572816453163
      },
      {
          "time_stamp_utc_ms": 1734407480000,
          "time": "2024-12-17T03:53:00Z",
          "energy": 40.16000000014901,
          "capacity": 425.2235413405416
      },
      {
          "time_stamp_utc_ms": 1734407640000,
          "time": "2024-12-17T03:58:00Z",
          "energy": 18.080000000074506,
          "capacity": 406.799987878092
      },
      {
          "time_stamp_utc_ms": 1734408060000,
          "time": "2024-12-17T04:03:00Z",
          "energy": 48.799999999813735,
          "capacity": 418.2857142841177
      },
      {
          "time_stamp_utc_ms": 1734408380000,
          "time": "2024-12-17T04:08:00Z",
          "energy": 35.20000000111759,
          "capacity": 395.99998821085353
      },
      {
          "time_stamp_utc_ms": 1734408660000,
          "time": "2024-12-17T04:13:00Z",
          "energy": 31.48799999896437,
          "capacity": 404.84572806136237
      },
      {
          "time_stamp_utc_ms": 1734408960000,
          "time": "2024-12-17T04:18:00Z",
          "energy": 34.17600000090897,
          "capacity": 410.11200001090765
      },
      {
          "time_stamp_utc_ms": 1734409260000,
          "time": "2024-12-17T04:23:00Z",
          "energy": 33.2160000000149,
          "capacity": 398.59200000017887
      },
      {
          "time_stamp_utc_ms": 1734409560000,
          "time": "2024-12-17T04:28:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1734409860000,
          "time": "2024-12-17T04:33:00Z",
          "energy": 32.92799999937415,
          "capacity": 395.1359999924898
      },
      {
          "time_stamp_utc_ms": 1734410160000,
          "time": "2024-12-17T04:38:00Z",
          "energy": 33.024000000208616,
          "capacity": 396.28800000250345
      },
      {
          "time_stamp_utc_ms": 1734410460000,
          "time": "2024-12-17T04:43:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1734410670000,
          "time": "2024-12-17T04:48:00Z",
          "energy": 22.127999999560416,
          "capacity": 379.3371428496071
      },
      {
          "time_stamp_utc_ms": 1734411060000,
          "time": "2024-12-17T04:53:00Z",
          "energy": 44.30400000046939,
          "capacity": 408.96000000433287
      },
      {
          "time_stamp_utc_ms": 1734411360000,
          "time": "2024-12-17T04:58:00Z",
          "energy": 32.735999999567866,
          "capacity": 392.8319999948144
      },
      {
          "time_stamp_utc_ms": 1734411720000,
          "time": "2024-12-17T05:03:00Z",
          "energy": 40.32000000029802,
          "capacity": 403.20000000298023
      },
      {
          "time_stamp_utc_ms": 1734411900000,
          "time": "2024-12-17T05:08:00Z",
          "energy": 20.639999999664724,
          "capacity": 412.7999999932945
      },
      {
          "time_stamp_utc_ms": 1734412320000,
          "time": "2024-12-17T05:13:00Z",
          "energy": 44.16000000014901,
          "capacity": 378.51428571556295
      },
      {
          "time_stamp_utc_ms": 1734412520000,
          "time": "2024-12-17T05:18:00Z",
          "energy": 23.999999999068677,
          "capacity": 432.00001028291905
      },
      {
          "time_stamp_utc_ms": 1734412845000,
          "time": "2024-12-17T05:23:00Z",
          "energy": 36.84000000078231,
          "capacity": 408.07385813695964
      },
      {
          "time_stamp_utc_ms": 1734413130000,
          "time": "2024-12-17T05:28:00Z",
          "energy": 31.799999999813735,
          "capacity": 401.684210523963
      },
      {
          "time_stamp_utc_ms": 1734413480000,
          "time": "2024-12-17T05:33:00Z",
          "energy": 39.52000000048429,
          "capacity": 406.49141750039763
      },
      {
          "time_stamp_utc_ms": 1734413775000,
          "time": "2024-12-17T05:38:00Z",
          "energy": 32.35999999940395,
          "capacity": 394.90170767434034
      },
      {
          "time_stamp_utc_ms": 1734414060000,
          "time": "2024-12-17T05:43:00Z",
          "energy": 32.08799999952316,
          "capacity": 405.3221052571347
      },
      {
          "time_stamp_utc_ms": 1734414360000,
          "time": "2024-12-17T05:48:00Z",
          "energy": 34.75200000125915,
          "capacity": 417.0240000151098
      },
      {
          "time_stamp_utc_ms": 1734414660000,
          "time": "2024-12-17T05:53:00Z",
          "energy": 34.07999999914318,
          "capacity": 408.95999998971826
      },
      {
          "time_stamp_utc_ms": 1734414960000,
          "time": "2024-12-17T05:58:00Z",
          "energy": 33.50399999972433,
          "capacity": 402.047999996692
      },
      {
          "time_stamp_utc_ms": 1734415260000,
          "time": "2024-12-17T06:03:00Z",
          "energy": 31.6800000006333,
          "capacity": 380.1600000075996
      },
      {
          "time_stamp_utc_ms": 1734415560000,
          "time": "2024-12-17T06:08:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1734415980000,
          "time": "2024-12-17T06:13:00Z",
          "energy": 46.07999999914318,
          "capacity": 394.9714285640844
      },
      {
          "time_stamp_utc_ms": 1734416130000,
          "time": "2024-12-17T06:18:00Z",
          "energy": 18.240000000223517,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734416500000,
          "time": "2024-12-17T06:23:00Z",
          "energy": 42.239999999292195,
          "capacity": 410.98379436999676
      },
      {
          "time_stamp_utc_ms": 1734416740000,
          "time": "2024-12-17T06:28:00Z",
          "energy": 26.560000001452863,
          "capacity": 398.40000002179295
      },
      {
          "time_stamp_utc_ms": 1734417000000,
          "time": "2024-12-17T06:33:00Z",
          "energy": 30.559999999590218,
          "capacity": 423.138446012162
      },
      {
          "time_stamp_utc_ms": 1734417390000,
          "time": "2024-12-17T06:38:00Z",
          "energy": 41.75999999977648,
          "capacity": 385.4769230748598
      },
      {
          "time_stamp_utc_ms": 1734417720000,
          "time": "2024-12-17T06:43:00Z",
          "energy": 37.919999999925494,
          "capacity": 413.6727272719145
      },
      {
          "time_stamp_utc_ms": 1734417960000,
          "time": "2024-12-17T06:48:00Z",
          "energy": 25.82400000002235,
          "capacity": 387.3600000003353
      },
      {
          "time_stamp_utc_ms": 1734418200000,
          "time": "2024-12-17T06:53:00Z",
          "energy": 26.33600000012666,
          "capacity": 395.0400000018999
      },
      {
          "time_stamp_utc_ms": 1734418575000,
          "time": "2024-12-17T06:58:00Z",
          "energy": 41.67999999970198,
          "capacity": 400.127999997139
      },
      {
          "time_stamp_utc_ms": 1734418860000,
          "time": "2024-12-17T07:03:00Z",
          "energy": 30.600000000558794,
          "capacity": 386.5263157965321
      },
      {
          "time_stamp_utc_ms": 1734419040000,
          "time": "2024-12-17T07:08:00Z",
          "energy": 20.519999999552965,
          "capacity": 410.3999999910593
      },
      {
          "time_stamp_utc_ms": 1734419520000,
          "time": "2024-12-17T07:13:00Z",
          "energy": 53.28000000026077,
          "capacity": 399.6000000019558
      },
      {
          "time_stamp_utc_ms": 1734419760000,
          "time": "2024-12-17T07:18:00Z",
          "energy": 27.26399999950081,
          "capacity": 408.95999999251217
      },
      {
          "time_stamp_utc_ms": 1734420060000,
          "time": "2024-12-17T07:23:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1734420360000,
          "time": "2024-12-17T07:28:00Z",
          "energy": 33.40800000075251,
          "capacity": 400.89600000903016
      },
      {
          "time_stamp_utc_ms": 1734420660000,
          "time": "2024-12-17T07:33:00Z",
          "energy": 34.27199999894947,
          "capacity": 411.2639999873937
      },
      {
          "time_stamp_utc_ms": 1734420960000,
          "time": "2024-12-17T07:38:00Z",
          "energy": 33.88800000119954,
          "capacity": 406.6560000143945
      },
      {
          "time_stamp_utc_ms": 1734421260000,
          "time": "2024-12-17T07:43:00Z",
          "energy": 34.36799999885261,
          "capacity": 412.4159999862314
      },
      {
          "time_stamp_utc_ms": 1734421560000,
          "time": "2024-12-17T07:48:00Z",
          "energy": 34.27199999988079,
          "capacity": 411.26399999856955
      },
      {
          "time_stamp_utc_ms": 1734421860000,
          "time": "2024-12-17T07:53:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1734422160000,
          "time": "2024-12-17T07:58:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1734422460000,
          "time": "2024-12-17T08:03:00Z",
          "energy": 32.928000000305474,
          "capacity": 395.13600000366574
      },
      {
          "time_stamp_utc_ms": 1734422760000,
          "time": "2024-12-17T08:08:00Z",
          "energy": 33.2160000000149,
          "capacity": 398.59200000017887
      },
      {
          "time_stamp_utc_ms": 1734423060000,
          "time": "2024-12-17T08:13:00Z",
          "energy": 33.98400000017136,
          "capacity": 407.8080000020564
      },
      {
          "time_stamp_utc_ms": 1734423360000,
          "time": "2024-12-17T08:18:00Z",
          "energy": 32.83199999947101,
          "capacity": 393.9839999936521
      },
      {
          "time_stamp_utc_ms": 1734423660000,
          "time": "2024-12-17T08:23:00Z",
          "energy": 34.17600000090897,
          "capacity": 410.11200001090765
      },
      {
          "time_stamp_utc_ms": 1734423960000,
          "time": "2024-12-17T08:28:00Z",
          "energy": 32.92799999937415,
          "capacity": 395.1359999924898
      },
      {
          "time_stamp_utc_ms": 1734424260000,
          "time": "2024-12-17T08:33:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1734424560000,
          "time": "2024-12-17T08:38:00Z",
          "energy": 33.216000000946224,
          "capacity": 398.59200001135474
      },
      {
          "time_stamp_utc_ms": 1734424860000,
          "time": "2024-12-17T08:43:00Z",
          "energy": 34.46399999875575,
          "capacity": 413.5679999850691
      },
      {
          "time_stamp_utc_ms": 1734425160000,
          "time": "2024-12-17T08:48:00Z",
          "energy": 33.88800000119954,
          "capacity": 406.6560000143945
      },
      {
          "time_stamp_utc_ms": 1734425460000,
          "time": "2024-12-17T08:53:00Z",
          "energy": 32.54399999976158,
          "capacity": 390.527999997139
      },
      {
          "time_stamp_utc_ms": 1734425760000,
          "time": "2024-12-17T08:58:00Z",
          "energy": 33.98399999924004,
          "capacity": 407.80799999088055
      },
      {
          "time_stamp_utc_ms": 1734426060000,
          "time": "2024-12-17T09:03:00Z",
          "energy": 33.02400000113994,
          "capacity": 396.2880000136793
      },
      {
          "time_stamp_utc_ms": 1734426360000,
          "time": "2024-12-17T09:08:00Z",
          "energy": 33.31199999898672,
          "capacity": 399.74399998784065
      },
      {
          "time_stamp_utc_ms": 1734426680000,
          "time": "2024-12-17T09:13:00Z",
          "energy": 36.03199999965727,
          "capacity": 405.35998791547524
      },
      {
          "time_stamp_utc_ms": 1734427000000,
          "time": "2024-12-17T09:18:00Z",
          "energy": 35.04000000003725,
          "capacity": 394.1999882523439
      },
      {
          "time_stamp_utc_ms": 1734427260000,
          "time": "2024-12-17T09:23:00Z",
          "energy": 29.536000000312924,
          "capacity": 408.95998500377
      },
      {
          "time_stamp_utc_ms": 1734427560000,
          "time": "2024-12-17T09:28:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1734427830000,
          "time": "2024-12-17T09:33:00Z",
          "energy": 32.688000000081956,
          "capacity": 435.84000000109273
      },
      {
          "time_stamp_utc_ms": 1734428420000,
          "time": "2024-12-17T09:43:00Z",
          "energy": 74.63999999966472,
          "capacity": 455.4305231956625
      },
      {
          "time_stamp_utc_ms": 1734428640000,
          "time": "2024-12-17T09:48:00Z",
          "energy": 27.83999999985099,
          "capacity": 455.5636264871223
      },
      {
          "time_stamp_utc_ms": 1734429060000,
          "time": "2024-12-17T09:53:00Z",
          "energy": 53.088000000454485,
          "capacity": 455.04000000389556
      },
      {
          "time_stamp_utc_ms": 1734429360000,
          "time": "2024-12-17T09:58:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1734429660000,
          "time": "2024-12-17T10:03:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1734429960000,
          "time": "2024-12-17T10:08:00Z",
          "energy": 39.74399999901652,
          "capacity": 476.92799998819834
      },
      {
          "time_stamp_utc_ms": 1734430260000,
          "time": "2024-12-17T10:13:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1734430560000,
          "time": "2024-12-17T10:18:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1734430860000,
          "time": "2024-12-17T10:23:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1734431160000,
          "time": "2024-12-17T10:28:00Z",
          "energy": 40.3199999993667,
          "capacity": 483.8399999924004
      },
      {
          "time_stamp_utc_ms": 1734431460000,
          "time": "2024-12-17T10:33:00Z",
          "energy": 38.49599999934435,
          "capacity": 461.95199999213224
      },
      {
          "time_stamp_utc_ms": 1734431760000,
          "time": "2024-12-17T10:38:00Z",
          "energy": 40.320000001229346,
          "capacity": 483.84000001475215
      },
      {
          "time_stamp_utc_ms": 1734432060000,
          "time": "2024-12-17T10:43:00Z",
          "energy": 38.59199999831617,
          "capacity": 463.103999979794
      },
      {
          "time_stamp_utc_ms": 1734432360000,
          "time": "2024-12-17T10:48:00Z",
          "energy": 39.84000000078231,
          "capacity": 478.08000000938773
      },
      {
          "time_stamp_utc_ms": 1734432660000,
          "time": "2024-12-17T10:53:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1734432960000,
          "time": "2024-12-17T10:58:00Z",
          "energy": 38.880000000819564,
          "capacity": 466.56000000983477
      },
      {
          "time_stamp_utc_ms": 1734433260000,
          "time": "2024-12-17T11:03:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1734433560000,
          "time": "2024-12-17T11:08:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1734433860000,
          "time": "2024-12-17T11:13:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1734434160000,
          "time": "2024-12-17T11:18:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1734434460000,
          "time": "2024-12-17T11:23:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1734434760000,
          "time": "2024-12-17T11:28:00Z",
          "energy": 42.04799999855459,
          "capacity": 504.5759999826551
      },
      {
          "time_stamp_utc_ms": 1734435060000,
          "time": "2024-12-17T11:33:00Z",
          "energy": 39.168000001460314,
          "capacity": 470.01600001752377
      },
      {
          "time_stamp_utc_ms": 1734435360000,
          "time": "2024-12-17T11:38:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1734435660000,
          "time": "2024-12-17T11:43:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1734435960000,
          "time": "2024-12-17T11:48:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1734436260000,
          "time": "2024-12-17T11:53:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1734436560000,
          "time": "2024-12-17T11:58:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1734436860000,
          "time": "2024-12-17T12:03:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1734437160000,
          "time": "2024-12-17T12:08:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1734437460000,
          "time": "2024-12-17T12:13:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1734437760000,
          "time": "2024-12-17T12:18:00Z",
          "energy": 40.79999999888241,
          "capacity": 489.59999998658895
      },
      {
          "time_stamp_utc_ms": 1734438060000,
          "time": "2024-12-17T12:23:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1734438360000,
          "time": "2024-12-17T12:28:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1734438660000,
          "time": "2024-12-17T12:33:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1734438960000,
          "time": "2024-12-17T12:38:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1734439260000,
          "time": "2024-12-17T12:43:00Z",
          "energy": 42.62400000076741,
          "capacity": 511.4880000092089
      },
      {
          "time_stamp_utc_ms": 1734439560000,
          "time": "2024-12-17T12:48:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1734439860000,
          "time": "2024-12-17T12:53:00Z",
          "energy": 42.91199999861419,
          "capacity": 514.9439999833703
      },
      {
          "time_stamp_utc_ms": 1734440160000,
          "time": "2024-12-17T12:58:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1734440460000,
          "time": "2024-12-17T13:03:00Z",
          "energy": 41.95200000051409,
          "capacity": 503.42400000616914
      },
      {
          "time_stamp_utc_ms": 1734440760000,
          "time": "2024-12-17T13:08:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1734441060000,
          "time": "2024-12-17T13:13:00Z",
          "energy": 41.183999999426305,
          "capacity": 494.2079999931157
      },
      {
          "time_stamp_utc_ms": 1734441360000,
          "time": "2024-12-17T13:18:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1734441660000,
          "time": "2024-12-17T13:23:00Z",
          "energy": 40.8960000006482,
          "capacity": 490.7520000077784
      },
      {
          "time_stamp_utc_ms": 1734441960000,
          "time": "2024-12-17T13:28:00Z",
          "energy": 41.75999999884516,
          "capacity": 501.119999986142
      },
      {
          "time_stamp_utc_ms": 1734442260000,
          "time": "2024-12-17T13:33:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1734442560000,
          "time": "2024-12-17T13:38:00Z",
          "energy": 40.80000000074506,
          "capacity": 489.6000000089407
      },
      {
          "time_stamp_utc_ms": 1734442860000,
          "time": "2024-12-17T13:43:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1734443160000,
          "time": "2024-12-17T13:48:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1734443500000,
          "time": "2024-12-17T13:53:00Z",
          "energy": 45.75999999977648,
          "capacity": 484.5176606468113
      },
      {
          "time_stamp_utc_ms": 1734443730000,
          "time": "2024-12-17T13:58:00Z",
          "energy": 30.200000000186265,
          "capacity": 472.69566197677875
      },
      {
          "time_stamp_utc_ms": 1734444060000,
          "time": "2024-12-17T14:03:00Z",
          "energy": 43.36799999978393,
          "capacity": 473.10545454309744
      },
      {
          "time_stamp_utc_ms": 1734444360000,
          "time": "2024-12-17T14:08:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1734444660000,
          "time": "2024-12-17T14:13:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1734444960000,
          "time": "2024-12-17T14:18:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1734445260000,
          "time": "2024-12-17T14:23:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1734445560000,
          "time": "2024-12-17T14:28:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1734445860000,
          "time": "2024-12-17T14:33:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1734446160000,
          "time": "2024-12-17T14:38:00Z",
          "energy": 37.63200000021607,
          "capacity": 451.58400000259286
      },
      {
          "time_stamp_utc_ms": 1734446760000,
          "time": "2024-12-17T14:48:00Z",
          "energy": 78.76800000015646,
          "capacity": 472.6080000009388
      },
      {
          "time_stamp_utc_ms": 1734447120000,
          "time": "2024-12-17T14:53:00Z",
          "energy": 47.27999999932945,
          "capacity": 472.7999999932945
      },
      {
          "time_stamp_utc_ms": 1734447360000,
          "time": "2024-12-17T14:58:00Z",
          "energy": 31.87199999950826,
          "capacity": 478.0799999926239
      },
      {
          "time_stamp_utc_ms": 1734447640000,
          "time": "2024-12-17T15:03:00Z",
          "energy": 36.76800000015646,
          "capacity": 472.7314446745773
      },
      {
          "time_stamp_utc_ms": 1734447930000,
          "time": "2024-12-17T15:08:00Z",
          "energy": 38.28000000026077,
          "capacity": 475.199984376133
      },
      {
          "time_stamp_utc_ms": 1734448290000,
          "time": "2024-12-17T15:13:00Z",
          "energy": 49.80000000074506,
          "capacity": 498.0000000074505
      },
      {
          "time_stamp_utc_ms": 1734448560000,
          "time": "2024-12-17T15:18:00Z",
          "energy": 37.1039999993518,
          "capacity": 494.71999999135727
      },
      {
          "time_stamp_utc_ms": 1734448860000,
          "time": "2024-12-17T15:23:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1734449160000,
          "time": "2024-12-17T15:28:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1734449460000,
          "time": "2024-12-17T15:33:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1734449760000,
          "time": "2024-12-17T15:38:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1734450060000,
          "time": "2024-12-17T15:43:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1734450360000,
          "time": "2024-12-17T15:48:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1734450660000,
          "time": "2024-12-17T15:53:00Z",
          "energy": 38.687999999150634,
          "capacity": 464.25599998980766
      },
      {
          "time_stamp_utc_ms": 1734450960000,
          "time": "2024-12-17T15:58:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1734451260000,
          "time": "2024-12-17T16:03:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1734451560000,
          "time": "2024-12-17T16:08:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1734451860000,
          "time": "2024-12-17T16:13:00Z",
          "energy": 39.26399999856949,
          "capacity": 471.1679999828339
      },
      {
          "time_stamp_utc_ms": 1734452160000,
          "time": "2024-12-17T16:18:00Z",
          "energy": 39.552000001072884,
          "capacity": 474.6240000128746
      },
      {
          "time_stamp_utc_ms": 1734452460000,
          "time": "2024-12-17T16:23:00Z",
          "energy": 36.38399999961257,
          "capacity": 436.60799999535084
      },
      {
          "time_stamp_utc_ms": 1734452760000,
          "time": "2024-12-17T16:28:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1734453030000,
          "time": "2024-12-17T16:33:00Z",
          "energy": 32.183999999426305,
          "capacity": 429.1199999923507
      },
      {
          "time_stamp_utc_ms": 1734453390000,
          "time": "2024-12-17T16:38:00Z",
          "energy": 41.760000000707805,
          "capacity": 417.600000007078
      },
      {
          "time_stamp_utc_ms": 1734453660000,
          "time": "2024-12-17T16:43:00Z",
          "energy": 30.8879999993369,
          "capacity": 411.83999999115866
      },
      {
          "time_stamp_utc_ms": 1734453960000,
          "time": "2024-12-17T16:48:00Z",
          "energy": 34.27199999988079,
          "capacity": 411.26399999856955
      },
      {
          "time_stamp_utc_ms": 1734454260000,
          "time": "2024-12-17T16:53:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1734454560000,
          "time": "2024-12-17T16:58:00Z",
          "energy": 37.72800000105053,
          "capacity": 452.73600001260644
      },
      {
          "time_stamp_utc_ms": 1734454860000,
          "time": "2024-12-17T17:03:00Z",
          "energy": 34.55999999959022,
          "capacity": 414.7199999950827
      },
      {
          "time_stamp_utc_ms": 1734455160000,
          "time": "2024-12-17T17:08:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1734455460000,
          "time": "2024-12-17T17:13:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1734455760000,
          "time": "2024-12-17T17:18:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1734456060000,
          "time": "2024-12-17T17:23:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1734456360000,
          "time": "2024-12-17T17:28:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1734456660000,
          "time": "2024-12-17T17:33:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1734456930000,
          "time": "2024-12-17T17:38:00Z",
          "energy": 31.295999999158084,
          "capacity": 417.2799999887745
      },
      {
          "time_stamp_utc_ms": 1734457260000,
          "time": "2024-12-17T17:43:00Z",
          "energy": 41.75999999977648,
          "capacity": 455.563636361198
      },
      {
          "time_stamp_utc_ms": 1734457560000,
          "time": "2024-12-17T17:48:00Z",
          "energy": 33.60000000055879,
          "capacity": 403.2000000067056
      },
      {
          "time_stamp_utc_ms": 1734457845000,
          "time": "2024-12-17T17:53:00Z",
          "energy": 33.0,
          "capacity": 416.84210526315786
      },
      {
          "time_stamp_utc_ms": 1734458040000,
          "time": "2024-12-17T17:58:00Z",
          "energy": 22.679999999701977,
          "capacity": 418.70769230219037
      },
      {
          "time_stamp_utc_ms": 1734458460000,
          "time": "2024-12-17T18:03:00Z",
          "energy": 48.191999999806285,
          "capacity": 413.0742857126253
      },
      {
          "time_stamp_utc_ms": 1734458745000,
          "time": "2024-12-17T18:08:00Z",
          "energy": 35.208000000566244,
          "capacity": 444.7326315860999
      },
      {
          "time_stamp_utc_ms": 1734459060000,
          "time": "2024-12-17T18:13:00Z",
          "energy": 36.79200000036508,
          "capacity": 420.48000000417227
      },
      {
          "time_stamp_utc_ms": 1734459360000,
          "time": "2024-12-17T18:18:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1734459660000,
          "time": "2024-12-17T18:23:00Z",
          "energy": 33.91999999899417,
          "capacity": 407.03999998793006
      },
      {
          "time_stamp_utc_ms": 1734459920000,
          "time": "2024-12-17T18:28:00Z",
          "energy": 28.16000000014901,
          "capacity": 389.9076780080272
      },
      {
          "time_stamp_utc_ms": 1734460290000,
          "time": "2024-12-17T18:33:00Z",
          "energy": 42.200000000186265,
          "capacity": 410.59460517947525
      },
      {
          "time_stamp_utc_ms": 1734460650000,
          "time": "2024-12-17T18:38:00Z",
          "energy": 39.72000000067055,
          "capacity": 397.2000000067055
      },
      {
          "time_stamp_utc_ms": 1734460890000,
          "time": "2024-12-17T18:43:00Z",
          "energy": 28.559999999590218,
          "capacity": 428.39999999385327
      },
      {
          "time_stamp_utc_ms": 1734461160000,
          "time": "2024-12-17T18:48:00Z",
          "energy": 29.808000000193715,
          "capacity": 397.4400000025828
      },
      {
          "time_stamp_utc_ms": 1734461460000,
          "time": "2024-12-17T18:53:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1734461760000,
          "time": "2024-12-17T18:58:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1734462060000,
          "time": "2024-12-17T19:03:00Z",
          "energy": 33.50399999972433,
          "capacity": 402.047999996692
      },
      {
          "time_stamp_utc_ms": 1734462360000,
          "time": "2024-12-17T19:08:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1734462660000,
          "time": "2024-12-17T19:13:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1734462960000,
          "time": "2024-12-17T19:18:00Z",
          "energy": 36.00000000093132,
          "capacity": 432.00000001117587
      },
      {
          "time_stamp_utc_ms": 1734463260000,
          "time": "2024-12-17T19:23:00Z",
          "energy": 35.03999999910593,
          "capacity": 420.4799999892712
      },
      {
          "time_stamp_utc_ms": 1734463560000,
          "time": "2024-12-17T19:28:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1734463860000,
          "time": "2024-12-17T19:33:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1734464160000,
          "time": "2024-12-17T19:38:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1734464460000,
          "time": "2024-12-17T19:43:00Z",
          "energy": 33.21599999908358,
          "capacity": 398.59199998900294
      },
      {
          "time_stamp_utc_ms": 1734464760000,
          "time": "2024-12-17T19:48:00Z",
          "energy": 35.040000000968575,
          "capacity": 420.4800000116229
      },
      {
          "time_stamp_utc_ms": 1734465060000,
          "time": "2024-12-17T19:53:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1734465360000,
          "time": "2024-12-17T19:58:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1734465660000,
          "time": "2024-12-17T20:03:00Z",
          "energy": 39.26399999856949,
          "capacity": 471.1679999828339
      },
      {
          "time_stamp_utc_ms": 1734465960000,
          "time": "2024-12-17T20:08:00Z",
          "energy": 37.2480000006035,
          "capacity": 446.976000007242
      },
      {
          "time_stamp_utc_ms": 1734466260000,
          "time": "2024-12-17T20:13:00Z",
          "energy": 35.90400000102818,
          "capacity": 430.84800001233816
      },
      {
          "time_stamp_utc_ms": 1734466560000,
          "time": "2024-12-17T20:18:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734466860000,
          "time": "2024-12-17T20:23:00Z",
          "energy": 40.03199999872595,
          "capacity": 480.3839999847114
      },
      {
          "time_stamp_utc_ms": 1734467160000,
          "time": "2024-12-17T20:28:00Z",
          "energy": 36.5760000012815,
          "capacity": 438.91200001537806
      },
      {
          "time_stamp_utc_ms": 1734467460000,
          "time": "2024-12-17T20:33:00Z",
          "energy": 38.30399999860674,
          "capacity": 459.6479999832809
      },
      {
          "time_stamp_utc_ms": 1734467760000,
          "time": "2024-12-17T20:38:00Z",
          "energy": 38.880000000819564,
          "capacity": 466.56000000983477
      },
      {
          "time_stamp_utc_ms": 1734468060000,
          "time": "2024-12-17T20:43:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1734468360000,
          "time": "2024-12-17T20:48:00Z",
          "energy": 40.41600000113249,
          "capacity": 484.99200001358986
      },
      {
          "time_stamp_utc_ms": 1734468660000,
          "time": "2024-12-17T20:53:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1734468960000,
          "time": "2024-12-17T20:58:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1734469260000,
          "time": "2024-12-17T21:03:00Z",
          "energy": 40.51200000103563,
          "capacity": 486.14400001242757
      },
      {
          "time_stamp_utc_ms": 1734469560000,
          "time": "2024-12-17T21:08:00Z",
          "energy": 41.567999999038875,
          "capacity": 498.81599998846656
      },
      {
          "time_stamp_utc_ms": 1734469860000,
          "time": "2024-12-17T21:13:00Z",
          "energy": 42.24000000022352,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1734470160000,
          "time": "2024-12-17T21:18:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1734470460000,
          "time": "2024-12-17T21:23:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1734470760000,
          "time": "2024-12-17T21:28:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1734471060000,
          "time": "2024-12-17T21:33:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1734471360000,
          "time": "2024-12-17T21:38:00Z",
          "energy": 41.376000001095235,
          "capacity": 496.5120000131429
      },
      {
          "time_stamp_utc_ms": 1734471660000,
          "time": "2024-12-17T21:43:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1734471960000,
          "time": "2024-12-17T21:48:00Z",
          "energy": 42.14399999938905,
          "capacity": 505.72799999266863
      },
      {
          "time_stamp_utc_ms": 1734472260000,
          "time": "2024-12-17T21:53:00Z",
          "energy": 41.47200000099838,
          "capacity": 497.6640000119806
      },
      {
          "time_stamp_utc_ms": 1734472560000,
          "time": "2024-12-17T21:58:00Z",
          "energy": 40.60799999907613,
          "capacity": 487.29599998891354
      },
      {
          "time_stamp_utc_ms": 1734472860000,
          "time": "2024-12-17T22:03:00Z",
          "energy": 40.80000000074506,
          "capacity": 489.6000000089407
      },
      {
          "time_stamp_utc_ms": 1734473160000,
          "time": "2024-12-17T22:08:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1734473460000,
          "time": "2024-12-17T22:13:00Z",
          "energy": 42.43199999909848,
          "capacity": 509.18399998918176
      },
      {
          "time_stamp_utc_ms": 1734473760000,
          "time": "2024-12-17T22:18:00Z",
          "energy": 41.376000001095235,
          "capacity": 496.5120000131429
      },
      {
          "time_stamp_utc_ms": 1734474060000,
          "time": "2024-12-17T22:23:00Z",
          "energy": 41.088000000454485,
          "capacity": 493.0560000054538
      },
      {
          "time_stamp_utc_ms": 1734474360000,
          "time": "2024-12-17T22:28:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1734474660000,
          "time": "2024-12-17T22:33:00Z",
          "energy": 40.99199999962002,
          "capacity": 491.9039999954403
      },
      {
          "time_stamp_utc_ms": 1734474960000,
          "time": "2024-12-17T22:38:00Z",
          "energy": 42.43199999909848,
          "capacity": 509.18399998918176
      },
      {
          "time_stamp_utc_ms": 1734475260000,
          "time": "2024-12-17T22:43:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1734475560000,
          "time": "2024-12-17T22:48:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1734475860000,
          "time": "2024-12-17T22:53:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1734476160000,
          "time": "2024-12-17T22:58:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1734476460000,
          "time": "2024-12-17T23:03:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1734476760000,
          "time": "2024-12-17T23:08:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1734477060000,
          "time": "2024-12-17T23:13:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1734477360000,
          "time": "2024-12-17T23:18:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1734477660000,
          "time": "2024-12-17T23:23:00Z",
          "energy": 41.95200000144541,
          "capacity": 503.42400001734495
      },
      {
          "time_stamp_utc_ms": 1734477960000,
          "time": "2024-12-17T23:28:00Z",
          "energy": 42.23999999836087,
          "capacity": 506.8799999803305
      },
      {
          "time_stamp_utc_ms": 1734478260000,
          "time": "2024-12-17T23:33:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1734478560000,
          "time": "2024-12-17T23:38:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1734478860000,
          "time": "2024-12-17T23:43:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1734479160000,
          "time": "2024-12-17T23:48:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1734479460000,
          "time": "2024-12-17T23:53:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1734479760000,
          "time": "2024-12-17T23:58:00Z",
          "energy": 40.704000000841916,
          "capacity": 488.448000010103
      },
      {
          "time_stamp_utc_ms": 1734480060000,
          "time": "2024-12-18T00:03:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1734480360000,
          "time": "2024-12-18T00:08:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1734480660000,
          "time": "2024-12-18T00:13:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1734480960000,
          "time": "2024-12-18T00:18:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1734481260000,
          "time": "2024-12-18T00:23:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1734481560000,
          "time": "2024-12-18T00:28:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1734481860000,
          "time": "2024-12-18T00:33:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1734482160000,
          "time": "2024-12-18T00:38:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1734482460000,
          "time": "2024-12-18T00:43:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1734482760000,
          "time": "2024-12-18T00:48:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1734483060000,
          "time": "2024-12-18T00:53:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1734483360000,
          "time": "2024-12-18T00:58:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1734483660000,
          "time": "2024-12-18T01:03:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1734483960000,
          "time": "2024-12-18T01:08:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1734484260000,
          "time": "2024-12-18T01:13:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1734484560000,
          "time": "2024-12-18T01:18:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1734484860000,
          "time": "2024-12-18T01:23:00Z",
          "energy": 35.61599999945611,
          "capacity": 427.3919999934733
      },
      {
          "time_stamp_utc_ms": 1734485160000,
          "time": "2024-12-18T01:28:00Z",
          "energy": 37.63200000114739,
          "capacity": 451.58400001376873
      },
      {
          "time_stamp_utc_ms": 1734485460000,
          "time": "2024-12-18T01:33:00Z",
          "energy": 36.09599999990314,
          "capacity": 433.1519999988377
      },
      {
          "time_stamp_utc_ms": 1734485760000,
          "time": "2024-12-18T01:38:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1734486060000,
          "time": "2024-12-18T01:43:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1734486360000,
          "time": "2024-12-18T01:48:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1734486660000,
          "time": "2024-12-18T01:53:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1734486960000,
          "time": "2024-12-18T01:58:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1734487260000,
          "time": "2024-12-18T02:03:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1734487560000,
          "time": "2024-12-18T02:08:00Z",
          "energy": 37.056000001728535,
          "capacity": 444.6720000207425
      },
      {
          "time_stamp_utc_ms": 1734487860000,
          "time": "2024-12-18T02:13:00Z",
          "energy": 35.903999999165535,
          "capacity": 430.8479999899864
      },
      {
          "time_stamp_utc_ms": 1734488160000,
          "time": "2024-12-18T02:18:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1734488460000,
          "time": "2024-12-18T02:23:00Z",
          "energy": 34.847999999299645,
          "capacity": 418.1759999915958
      },
      {
          "time_stamp_utc_ms": 1734488760000,
          "time": "2024-12-18T02:28:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1734489060000,
          "time": "2024-12-18T02:33:00Z",
          "energy": 34.84800000116229,
          "capacity": 418.1760000139475
      },
      {
          "time_stamp_utc_ms": 1734489360000,
          "time": "2024-12-18T02:38:00Z",
          "energy": 35.99999999906868,
          "capacity": 431.99999998882413
      },
      {
          "time_stamp_utc_ms": 1734489660000,
          "time": "2024-12-18T02:43:00Z",
          "energy": 36.00000000093132,
          "capacity": 432.00000001117587
      },
      {
          "time_stamp_utc_ms": 1734489960000,
          "time": "2024-12-18T02:48:00Z",
          "energy": 35.231999998912215,
          "capacity": 422.78399998694664
      },
      {
          "time_stamp_utc_ms": 1734490260000,
          "time": "2024-12-18T02:53:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1734490560000,
          "time": "2024-12-18T02:58:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1734490860000,
          "time": "2024-12-18T03:03:00Z",
          "energy": 35.61600000038743,
          "capacity": 427.39200000464916
      },
      {
          "time_stamp_utc_ms": 1734491160000,
          "time": "2024-12-18T03:08:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1734491460000,
          "time": "2024-12-18T03:13:00Z",
          "energy": 34.55999999959022,
          "capacity": 414.7199999950827
      },
      {
          "time_stamp_utc_ms": 1734491760000,
          "time": "2024-12-18T03:18:00Z",
          "energy": 35.04000000003725,
          "capacity": 420.48000000044703
      },
      {
          "time_stamp_utc_ms": 1734492060000,
          "time": "2024-12-18T03:23:00Z",
          "energy": 34.55999999959022,
          "capacity": 414.7199999950827
      },
      {
          "time_stamp_utc_ms": 1734492360000,
          "time": "2024-12-18T03:28:00Z",
          "energy": 34.94400000106543,
          "capacity": 419.3280000127852
      },
      {
          "time_stamp_utc_ms": 1734492660000,
          "time": "2024-12-18T03:33:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1734492960000,
          "time": "2024-12-18T03:38:00Z",
          "energy": 35.03999999910593,
          "capacity": 420.4799999892712
      },
      {
          "time_stamp_utc_ms": 1734493260000,
          "time": "2024-12-18T03:43:00Z",
          "energy": 34.65600000042468,
          "capacity": 415.8720000050962
      },
      {
          "time_stamp_utc_ms": 1734493560000,
          "time": "2024-12-18T03:48:00Z",
          "energy": 33.791999999433756,
          "capacity": 405.5039999932051
      },
      {
          "time_stamp_utc_ms": 1734493860000,
          "time": "2024-12-18T03:53:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734494160000,
          "time": "2024-12-18T03:58:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1734494460000,
          "time": "2024-12-18T04:03:00Z",
          "energy": 32.92799999937415,
          "capacity": 395.1359999924898
      },
      {
          "time_stamp_utc_ms": 1734494760000,
          "time": "2024-12-18T04:08:00Z",
          "energy": 32.73600000143051,
          "capacity": 392.8320000171662
      },
      {
          "time_stamp_utc_ms": 1734495060000,
          "time": "2024-12-18T04:13:00Z",
          "energy": 31.39199999999255,
          "capacity": 376.7039999999106
      },
      {
          "time_stamp_utc_ms": 1734495360000,
          "time": "2024-12-18T04:18:00Z",
          "energy": 33.023999999277294,
          "capacity": 396.2879999913275
      },
      {
          "time_stamp_utc_ms": 1734495660000,
          "time": "2024-12-18T04:23:00Z",
          "energy": 30.912000000476837,
          "capacity": 370.94400000572205
      },
      {
          "time_stamp_utc_ms": 1734495960000,
          "time": "2024-12-18T04:28:00Z",
          "energy": 32.928000000305474,
          "capacity": 395.13600000366574
      },
      {
          "time_stamp_utc_ms": 1734496260000,
          "time": "2024-12-18T04:33:00Z",
          "energy": 31.77599999960512,
          "capacity": 381.31199999526143
      },
      {
          "time_stamp_utc_ms": 1734496560000,
          "time": "2024-12-18T04:38:00Z",
          "energy": 32.16000000014901,
          "capacity": 385.92000000178814
      },
      {
          "time_stamp_utc_ms": 1734496860000,
          "time": "2024-12-18T04:43:00Z",
          "energy": 33.023999999277294,
          "capacity": 396.2879999913275
      },
      {
          "time_stamp_utc_ms": 1734497160000,
          "time": "2024-12-18T04:48:00Z",
          "energy": 32.06400000024587,
          "capacity": 384.76800000295043
      },
      {
          "time_stamp_utc_ms": 1734497460000,
          "time": "2024-12-18T04:53:00Z",
          "energy": 32.639999999664724,
          "capacity": 391.6799999959767
      },
      {
          "time_stamp_utc_ms": 1734497760000,
          "time": "2024-12-18T04:58:00Z",
          "energy": 31.77599999960512,
          "capacity": 381.31199999526143
      },
      {
          "time_stamp_utc_ms": 1734498060000,
          "time": "2024-12-18T05:03:00Z",
          "energy": 32.44800000078976,
          "capacity": 389.3760000094772
      },
      {
          "time_stamp_utc_ms": 1734498360000,
          "time": "2024-12-18T05:08:00Z",
          "energy": 32.16000000014901,
          "capacity": 385.92000000178814
      },
      {
          "time_stamp_utc_ms": 1734498660000,
          "time": "2024-12-18T05:13:00Z",
          "energy": 31.968000000342727,
          "capacity": 383.6160000041127
      },
      {
          "time_stamp_utc_ms": 1734498960000,
          "time": "2024-12-18T05:18:00Z",
          "energy": 32.73600000049919,
          "capacity": 392.8320000059903
      },
      {
          "time_stamp_utc_ms": 1734499260000,
          "time": "2024-12-18T05:23:00Z",
          "energy": 32.3519999999553,
          "capacity": 388.22399999946356
      },
      {
          "time_stamp_utc_ms": 1734499560000,
          "time": "2024-12-18T05:28:00Z",
          "energy": 26.399999999441206,
          "capacity": 316.7999999932945
      },
      {
          "time_stamp_utc_ms": 1734499860000,
          "time": "2024-12-18T05:33:00Z",
          "energy": 29.951999999582767,
          "capacity": 359.42399999499327
      },
      {
          "time_stamp_utc_ms": 1734500160000,
          "time": "2024-12-18T05:38:00Z",
          "energy": 32.83200000040233,
          "capacity": 393.98400000482803
      },
      {
          "time_stamp_utc_ms": 1734500460000,
          "time": "2024-12-18T05:43:00Z",
          "energy": 34.84800000023097,
          "capacity": 418.1760000027716
      },
      {
          "time_stamp_utc_ms": 1734500760000,
          "time": "2024-12-18T05:48:00Z",
          "energy": 33.21599999908358,
          "capacity": 398.59199998900294
      },
      {
          "time_stamp_utc_ms": 1734501060000,
          "time": "2024-12-18T05:53:00Z",
          "energy": 33.60000000055879,
          "capacity": 403.2000000067056
      },
      {
          "time_stamp_utc_ms": 1734501360000,
          "time": "2024-12-18T05:58:00Z",
          "energy": 34.27199999988079,
          "capacity": 411.26399999856955
      },
      {
          "time_stamp_utc_ms": 1734501660000,
          "time": "2024-12-18T06:03:00Z",
          "energy": 32.44799999985844,
          "capacity": 389.37599999830127
      },
      {
          "time_stamp_utc_ms": 1734501960000,
          "time": "2024-12-18T06:08:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1734502260000,
          "time": "2024-12-18T06:13:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1734502560000,
          "time": "2024-12-18T06:18:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1734502860000,
          "time": "2024-12-18T06:23:00Z",
          "energy": 32.92799999937415,
          "capacity": 395.1359999924898
      },
      {
          "time_stamp_utc_ms": 1734503160000,
          "time": "2024-12-18T06:28:00Z",
          "energy": 33.50399999972433,
          "capacity": 402.047999996692
      },
      {
          "time_stamp_utc_ms": 1734503460000,
          "time": "2024-12-18T06:33:00Z",
          "energy": 32.544000000692904,
          "capacity": 390.5280000083149
      },
      {
          "time_stamp_utc_ms": 1734503760000,
          "time": "2024-12-18T06:38:00Z",
          "energy": 33.312000000849366,
          "capacity": 399.74400001019245
      },
      {
          "time_stamp_utc_ms": 1734504060000,
          "time": "2024-12-18T06:43:00Z",
          "energy": 32.351999999023974,
          "capacity": 388.2239999882877
      },
      {
          "time_stamp_utc_ms": 1734504360000,
          "time": "2024-12-18T06:48:00Z",
          "energy": 33.60000000055879,
          "capacity": 403.2000000067056
      },
      {
          "time_stamp_utc_ms": 1734504660000,
          "time": "2024-12-18T06:53:00Z",
          "energy": 32.3519999999553,
          "capacity": 388.22399999946356
      },
      {
          "time_stamp_utc_ms": 1734504960000,
          "time": "2024-12-18T06:58:00Z",
          "energy": 34.65599999856204,
          "capacity": 415.8719999827445
      },
      {
          "time_stamp_utc_ms": 1734505260000,
          "time": "2024-12-18T07:03:00Z",
          "energy": 33.50400000065565,
          "capacity": 402.04800000786787
      },
      {
          "time_stamp_utc_ms": 1734505560000,
          "time": "2024-12-18T07:08:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1734505860000,
          "time": "2024-12-18T07:13:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1734506160000,
          "time": "2024-12-18T07:18:00Z",
          "energy": 33.311999999918044,
          "capacity": 399.7439999990166
      },
      {
          "time_stamp_utc_ms": 1734506460000,
          "time": "2024-12-18T07:23:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1734506760000,
          "time": "2024-12-18T07:28:00Z",
          "energy": 32.735999999567866,
          "capacity": 392.8319999948144
      },
      {
          "time_stamp_utc_ms": 1734507060000,
          "time": "2024-12-18T07:33:00Z",
          "energy": 33.59999999962747,
          "capacity": 403.1999999955297
      },
      {
          "time_stamp_utc_ms": 1734507360000,
          "time": "2024-12-18T07:38:00Z",
          "energy": 32.928000001236796,
          "capacity": 395.1360000148416
      },
      {
          "time_stamp_utc_ms": 1734507660000,
          "time": "2024-12-18T07:43:00Z",
          "energy": 33.023999999277294,
          "capacity": 396.2879999913275
      },
      {
          "time_stamp_utc_ms": 1734507960000,
          "time": "2024-12-18T07:48:00Z",
          "energy": 33.024000000208616,
          "capacity": 396.28800000250345
      },
      {
          "time_stamp_utc_ms": 1734508260000,
          "time": "2024-12-18T07:53:00Z",
          "energy": 33.21599999908358,
          "capacity": 398.59199998900294
      },
      {
          "time_stamp_utc_ms": 1734508560000,
          "time": "2024-12-18T07:58:00Z",
          "energy": 32.73600000143051,
          "capacity": 392.8320000171662
      },
      {
          "time_stamp_utc_ms": 1734508860000,
          "time": "2024-12-18T08:03:00Z",
          "energy": 33.98399999924004,
          "capacity": 407.80799999088055
      },
      {
          "time_stamp_utc_ms": 1734509160000,
          "time": "2024-12-18T08:08:00Z",
          "energy": 31.968000000342727,
          "capacity": 383.6160000041127
      },
      {
          "time_stamp_utc_ms": 1734509460000,
          "time": "2024-12-18T08:13:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1734509760000,
          "time": "2024-12-18T08:18:00Z",
          "energy": 32.256000000052154,
          "capacity": 387.07200000062585
      },
      {
          "time_stamp_utc_ms": 1734510060000,
          "time": "2024-12-18T08:23:00Z",
          "energy": 33.69599999953061,
          "capacity": 404.3519999943674
      },
      {
          "time_stamp_utc_ms": 1734510360000,
          "time": "2024-12-18T08:28:00Z",
          "energy": 32.83200000040233,
          "capacity": 393.98400000482803
      },
      {
          "time_stamp_utc_ms": 1734510660000,
          "time": "2024-12-18T08:33:00Z",
          "energy": 33.791999999433756,
          "capacity": 405.5039999932051
      },
      {
          "time_stamp_utc_ms": 1734510960000,
          "time": "2024-12-18T08:38:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1734511260000,
          "time": "2024-12-18T08:43:00Z",
          "energy": 32.64000000059605,
          "capacity": 391.6800000071526
      },
      {
          "time_stamp_utc_ms": 1734511560000,
          "time": "2024-12-18T08:48:00Z",
          "energy": 33.2160000000149,
          "capacity": 398.59200000017887
      },
      {
          "time_stamp_utc_ms": 1734511860000,
          "time": "2024-12-18T08:53:00Z",
          "energy": 33.98400000017136,
          "capacity": 407.8080000020564
      },
      {
          "time_stamp_utc_ms": 1734512160000,
          "time": "2024-12-18T08:58:00Z",
          "energy": 34.175999999046326,
          "capacity": 410.11199998855597
      },
      {
          "time_stamp_utc_ms": 1734512460000,
          "time": "2024-12-18T09:03:00Z",
          "energy": 34.4640000006184,
          "capacity": 413.5680000074208
      },
      {
          "time_stamp_utc_ms": 1734512760000,
          "time": "2024-12-18T09:08:00Z",
          "energy": 32.544000000692904,
          "capacity": 390.5280000083149
      },
      {
          "time_stamp_utc_ms": 1734513060000,
          "time": "2024-12-18T09:13:00Z",
          "energy": 33.59999999962747,
          "capacity": 403.1999999955297
      },
      {
          "time_stamp_utc_ms": 1734513360000,
          "time": "2024-12-18T09:18:00Z",
          "energy": 32.83199999947101,
          "capacity": 393.9839999936521
      },
      {
          "time_stamp_utc_ms": 1734513660000,
          "time": "2024-12-18T09:23:00Z",
          "energy": 34.94400000013411,
          "capacity": 419.3280000016093
      },
      {
          "time_stamp_utc_ms": 1734513960000,
          "time": "2024-12-18T09:28:00Z",
          "energy": 37.05600000079721,
          "capacity": 444.6720000095666
      },
      {
          "time_stamp_utc_ms": 1734514260000,
          "time": "2024-12-18T09:33:00Z",
          "energy": 37.72799999918789,
          "capacity": 452.73599999025464
      },
      {
          "time_stamp_utc_ms": 1734514560000,
          "time": "2024-12-18T09:38:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1734514860000,
          "time": "2024-12-18T09:43:00Z",
          "energy": 37.63200000021607,
          "capacity": 451.58400000259286
      },
      {
          "time_stamp_utc_ms": 1734515160000,
          "time": "2024-12-18T09:48:00Z",
          "energy": 37.5359999993816,
          "capacity": 450.4319999925792
      },
      {
          "time_stamp_utc_ms": 1734515460000,
          "time": "2024-12-18T09:53:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1734515760000,
          "time": "2024-12-18T09:58:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1734516060000,
          "time": "2024-12-18T10:03:00Z",
          "energy": 40.41600000113249,
          "capacity": 484.99200001358986
      },
      {
          "time_stamp_utc_ms": 1734516360000,
          "time": "2024-12-18T10:08:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1734516660000,
          "time": "2024-12-18T10:13:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1734516960000,
          "time": "2024-12-18T10:18:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1734517260000,
          "time": "2024-12-18T10:23:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1734517560000,
          "time": "2024-12-18T10:28:00Z",
          "energy": 39.84000000078231,
          "capacity": 478.08000000938773
      },
      {
          "time_stamp_utc_ms": 1734517820000,
          "time": "2024-12-18T10:33:00Z",
          "energy": 33.567999999038875,
          "capacity": 464.7876752460741
      },
      {
          "time_stamp_utc_ms": 1734518140000,
          "time": "2024-12-18T10:38:00Z",
          "energy": 42.080000000074506,
          "capacity": 473.3999858924192
      },
      {
          "time_stamp_utc_ms": 1734518480000,
          "time": "2024-12-18T10:43:00Z",
          "energy": 44.480000000447035,
          "capacity": 470.96471909729087
      },
      {
          "time_stamp_utc_ms": 1734518760000,
          "time": "2024-12-18T10:48:00Z",
          "energy": 36.06400000024587,
          "capacity": 463.6800157960084
      },
      {
          "time_stamp_utc_ms": 1734519000000,
          "time": "2024-12-18T10:53:00Z",
          "energy": 32.09599999990314,
          "capacity": 481.43999999854714
      },
      {
          "time_stamp_utc_ms": 1734519400000,
          "time": "2024-12-18T10:58:00Z",
          "energy": 51.67999999970198,
          "capacity": 465.120011086643
      },
      {
          "time_stamp_utc_ms": 1734519645000,
          "time": "2024-12-18T11:03:00Z",
          "energy": 32.72000000067055,
          "capacity": 480.7836547645051
      },
      {
          "time_stamp_utc_ms": 1734520000000,
          "time": "2024-12-18T11:08:00Z",
          "energy": 46.16000000014901,
          "capacity": 468.1014210273225
      },
      {
          "time_stamp_utc_ms": 1734520230000,
          "time": "2024-12-18T11:13:00Z",
          "energy": 29.3199999993667,
          "capacity": 458.92174863491107
      },
      {
          "time_stamp_utc_ms": 1734520470000,
          "time": "2024-12-18T11:18:00Z",
          "energy": 32.04000000003725,
          "capacity": 480.6000000005588
      },
      {
          "time_stamp_utc_ms": 1734520860000,
          "time": "2024-12-18T11:23:00Z",
          "energy": 50.760000000707805,
          "capacity": 468.55384616037975
      },
      {
          "time_stamp_utc_ms": 1734521160000,
          "time": "2024-12-18T11:28:00Z",
          "energy": 42.024000000208616,
          "capacity": 504.2880000025034
      },
      {
          "time_stamp_utc_ms": 1734521550000,
          "time": "2024-12-18T11:33:00Z",
          "energy": 50.97599999886006,
          "capacity": 470.5476922971698
      },
      {
          "time_stamp_utc_ms": 1734521760000,
          "time": "2024-12-18T11:38:00Z",
          "energy": 28.8960000006482,
          "capacity": 495.360000011112
      },
      {
          "time_stamp_utc_ms": 1734522120000,
          "time": "2024-12-18T11:43:00Z",
          "energy": 46.94399999920279,
          "capacity": 469.4399999920279
      },
      {
          "time_stamp_utc_ms": 1734522360000,
          "time": "2024-12-18T11:48:00Z",
          "energy": 32.16000000014901,
          "capacity": 482.4000000022352
      },
      {
          "time_stamp_utc_ms": 1734522960000,
          "time": "2024-12-18T11:58:00Z",
          "energy": 82.40000000037253,
          "capacity": 494.40000000223523
      },
      {
          "time_stamp_utc_ms": 1734523230000,
          "time": "2024-12-18T12:03:00Z",
          "energy": 36.39999999944121,
          "capacity": 485.33333332588273
      },
      {
          "time_stamp_utc_ms": 1734523560000,
          "time": "2024-12-18T12:08:00Z",
          "energy": 44.208000000566244,
          "capacity": 482.2690909152681
      },
      {
          "time_stamp_utc_ms": 1734523860000,
          "time": "2024-12-18T12:13:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1734524160000,
          "time": "2024-12-18T12:18:00Z",
          "energy": 41.472000000067055,
          "capacity": 497.66400000080466
      },
      {
          "time_stamp_utc_ms": 1734524460000,
          "time": "2024-12-18T12:23:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1734524730000,
          "time": "2024-12-18T12:28:00Z",
          "energy": 37.89599999971688,
          "capacity": 505.279999996225
      },
      {
          "time_stamp_utc_ms": 1734525180000,
          "time": "2024-12-18T12:33:00Z",
          "energy": 60.59999999962747,
          "capacity": 484.79999999701977
      },
      {
          "time_stamp_utc_ms": 1734525390000,
          "time": "2024-12-18T12:38:00Z",
          "energy": 30.240000000223517,
          "capacity": 518.4000000038318
      },
      {
          "time_stamp_utc_ms": 1734525660000,
          "time": "2024-12-18T12:43:00Z",
          "energy": 35.67999999970198,
          "capacity": 475.7333333293597
      },
      {
          "time_stamp_utc_ms": 1734525870000,
          "time": "2024-12-18T12:48:00Z",
          "energy": 28.880000000819564,
          "capacity": 495.08571429976394
      },
      {
          "time_stamp_utc_ms": 1734526260000,
          "time": "2024-12-18T12:53:00Z",
          "energy": 53.03999999910593,
          "capacity": 489.5999999917471
      },
      {
          "time_stamp_utc_ms": 1734526560000,
          "time": "2024-12-18T12:58:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1734526860000,
          "time": "2024-12-18T13:03:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1734527160000,
          "time": "2024-12-18T13:08:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1734527460000,
          "time": "2024-12-18T13:13:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1734527760000,
          "time": "2024-12-18T13:18:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1734528180000,
          "time": "2024-12-18T13:23:00Z",
          "energy": 54.91200000047684,
          "capacity": 470.67428571837286
      },
      {
          "time_stamp_utc_ms": 1734528330000,
          "time": "2024-12-18T13:28:00Z",
          "energy": 21.12000000011176,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1734528540000,
          "time": "2024-12-18T13:33:00Z",
          "energy": 28.799999999813735,
          "capacity": 493.7142857110926
      },
      {
          "time_stamp_utc_ms": 1734528900000,
          "time": "2024-12-18T13:38:00Z",
          "energy": 46.55999999959022,
          "capacity": 465.5999999959021
      },
      {
          "time_stamp_utc_ms": 1734529350000,
          "time": "2024-12-18T13:43:00Z",
          "energy": 63.83999999985099,
          "capacity": 510.7199999988079
      },
      {
          "time_stamp_utc_ms": 1734529560000,
          "time": "2024-12-18T13:48:00Z",
          "energy": 26.720000000670552,
          "capacity": 458.05714286863804
      },
      {
          "time_stamp_utc_ms": 1734529830000,
          "time": "2024-12-18T13:53:00Z",
          "energy": 37.59999999962747,
          "capacity": 501.3333333283662
      },
      {
          "time_stamp_utc_ms": 1734530140000,
          "time": "2024-12-18T13:58:00Z",
          "energy": 40.639999999664724,
          "capacity": 471.9484016117538
      },
      {
          "time_stamp_utc_ms": 1734530460000,
          "time": "2024-12-18T14:03:00Z",
          "energy": 41.72800000105053,
          "capacity": 469.4399860214167
      },
      {
          "time_stamp_utc_ms": 1734530760000,
          "time": "2024-12-18T14:08:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1734531060000,
          "time": "2024-12-18T14:13:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1734531360000,
          "time": "2024-12-18T14:18:00Z",
          "energy": 40.3199999993667,
          "capacity": 483.8399999924004
      },
      {
          "time_stamp_utc_ms": 1734531660000,
          "time": "2024-12-18T14:23:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1734531960000,
          "time": "2024-12-18T14:28:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1734532260000,
          "time": "2024-12-18T14:33:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1734532560000,
          "time": "2024-12-18T14:38:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1734532860000,
          "time": "2024-12-18T14:43:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1734533145000,
          "time": "2024-12-18T14:48:00Z",
          "energy": 34.29600000008941,
          "capacity": 433.2126315800767
      },
      {
          "time_stamp_utc_ms": 1734533490000,
          "time": "2024-12-18T14:53:00Z",
          "energy": 42.959999999031425,
          "capacity": 448.2782608594583
      },
      {
          "time_stamp_utc_ms": 1734533760000,
          "time": "2024-12-18T14:58:00Z",
          "energy": 33.33600000012666,
          "capacity": 444.4800000016887
      },
      {
          "time_stamp_utc_ms": 1734534060000,
          "time": "2024-12-18T15:03:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1734534360000,
          "time": "2024-12-18T15:08:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1734534600000,
          "time": "2024-12-18T15:13:00Z",
          "energy": 29.05599999986589,
          "capacity": 435.83999999798834
      },
      {
          "time_stamp_utc_ms": 1734534990000,
          "time": "2024-12-18T15:18:00Z",
          "energy": 48.80000000074506,
          "capacity": 450.46153846841594
      },
      {
          "time_stamp_utc_ms": 1734535240000,
          "time": "2024-12-18T15:23:00Z",
          "energy": 30.239999999292195,
          "capacity": 435.45601660113635
      },
      {
          "time_stamp_utc_ms": 1734535575000,
          "time": "2024-12-18T15:28:00Z",
          "energy": 40.200000000186265,
          "capacity": 431.9999877038735
      },
      {
          "time_stamp_utc_ms": 1734535860000,
          "time": "2024-12-18T15:33:00Z",
          "energy": 34.67999999970198,
          "capacity": 438.0631578909723
      },
      {
          "time_stamp_utc_ms": 1734536160000,
          "time": "2024-12-18T15:38:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1734536460000,
          "time": "2024-12-18T15:43:00Z",
          "energy": 37.5359999993816,
          "capacity": 450.4319999925792
      },
      {
          "time_stamp_utc_ms": 1734536760000,
          "time": "2024-12-18T15:48:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1734537060000,
          "time": "2024-12-18T15:53:00Z",
          "energy": 36.768000001087785,
          "capacity": 441.2160000130535
      },
      {
          "time_stamp_utc_ms": 1734537360000,
          "time": "2024-12-18T15:58:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1734537630000,
          "time": "2024-12-18T16:03:00Z",
          "energy": 32.61599999945611,
          "capacity": 434.879999992748
      },
      {
          "time_stamp_utc_ms": 1734538080000,
          "time": "2024-12-18T16:08:00Z",
          "energy": 54.360000000335276,
          "capacity": 434.8800000026822
      },
      {
          "time_stamp_utc_ms": 1734538245000,
          "time": "2024-12-18T16:13:00Z",
          "energy": 19.43999999947846,
          "capacity": 424.1454545340755
      },
      {
          "time_stamp_utc_ms": 1734538560000,
          "time": "2024-12-18T16:18:00Z",
          "energy": 38.256000000052154,
          "capacity": 437.2114285720246
      },
      {
          "time_stamp_utc_ms": 1734538860000,
          "time": "2024-12-18T16:23:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1734539160000,
          "time": "2024-12-18T16:28:00Z",
          "energy": 35.903999999165535,
          "capacity": 430.8479999899864
      },
      {
          "time_stamp_utc_ms": 1734539460000,
          "time": "2024-12-18T16:33:00Z",
          "energy": 35.90400000102818,
          "capacity": 430.84800001233816
      },
      {
          "time_stamp_utc_ms": 1734539760000,
          "time": "2024-12-18T16:38:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1734540060000,
          "time": "2024-12-18T16:43:00Z",
          "energy": 35.80799999926239,
          "capacity": 429.6959999911487
      },
      {
          "time_stamp_utc_ms": 1734540360000,
          "time": "2024-12-18T16:48:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1734540660000,
          "time": "2024-12-18T16:53:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1734540960000,
          "time": "2024-12-18T16:58:00Z",
          "energy": 35.61600000038743,
          "capacity": 427.39200000464916
      },
      {
          "time_stamp_utc_ms": 1734541260000,
          "time": "2024-12-18T17:03:00Z",
          "energy": 32.44799999985844,
          "capacity": 389.37599999830127
      },
      {
          "time_stamp_utc_ms": 1734541560000,
          "time": "2024-12-18T17:08:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1734541860000,
          "time": "2024-12-18T17:13:00Z",
          "energy": 39.93599999882281,
          "capacity": 479.23199998587376
      },
      {
          "time_stamp_utc_ms": 1734542160000,
          "time": "2024-12-18T17:18:00Z",
          "energy": 40.99200000055134,
          "capacity": 491.9040000066161
      },
      {
          "time_stamp_utc_ms": 1734542460000,
          "time": "2024-12-18T17:23:00Z",
          "energy": 41.567999999038875,
          "capacity": 498.81599998846656
      },
      {
          "time_stamp_utc_ms": 1734542760000,
          "time": "2024-12-18T17:28:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1734543060000,
          "time": "2024-12-18T17:33:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1734543360000,
          "time": "2024-12-18T17:38:00Z",
          "energy": 35.61599999945611,
          "capacity": 427.3919999934733
      },
      {
          "time_stamp_utc_ms": 1734543660000,
          "time": "2024-12-18T17:43:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1734543960000,
          "time": "2024-12-18T17:48:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1734544260000,
          "time": "2024-12-18T17:53:00Z",
          "energy": 32.25599999912083,
          "capacity": 387.07199998945
      },
      {
          "time_stamp_utc_ms": 1734544560000,
          "time": "2024-12-18T17:58:00Z",
          "energy": 33.50400000065565,
          "capacity": 402.04800000786787
      },
      {
          "time_stamp_utc_ms": 1734544860000,
          "time": "2024-12-18T18:03:00Z",
          "energy": 34.175999999046326,
          "capacity": 410.11199998855597
      },
      {
          "time_stamp_utc_ms": 1734545160000,
          "time": "2024-12-18T18:08:00Z",
          "energy": 35.04000000003725,
          "capacity": 420.48000000044703
      },
      {
          "time_stamp_utc_ms": 1734545460000,
          "time": "2024-12-18T18:13:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1734545760000,
          "time": "2024-12-18T18:18:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1734546060000,
          "time": "2024-12-18T18:23:00Z",
          "energy": 40.704000000841916,
          "capacity": 488.448000010103
      },
      {
          "time_stamp_utc_ms": 1734546360000,
          "time": "2024-12-18T18:28:00Z",
          "energy": 33.791999999433756,
          "capacity": 405.5039999932051
      },
      {
          "time_stamp_utc_ms": 1734546660000,
          "time": "2024-12-18T18:33:00Z",
          "energy": 34.27199999988079,
          "capacity": 411.26399999856955
      },
      {
          "time_stamp_utc_ms": 1734546960000,
          "time": "2024-12-18T18:38:00Z",
          "energy": 35.424000000581145,
          "capacity": 425.08800000697374
      },
      {
          "time_stamp_utc_ms": 1734547260000,
          "time": "2024-12-18T18:43:00Z",
          "energy": 34.56000000052154,
          "capacity": 414.7200000062585
      },
      {
          "time_stamp_utc_ms": 1734547560000,
          "time": "2024-12-18T18:48:00Z",
          "energy": 36.86399999912828,
          "capacity": 442.3679999895394
      },
      {
          "time_stamp_utc_ms": 1734547860000,
          "time": "2024-12-18T18:53:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1734548160000,
          "time": "2024-12-18T18:58:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1734548460000,
          "time": "2024-12-18T19:03:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1734548760000,
          "time": "2024-12-18T19:08:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1734549060000,
          "time": "2024-12-18T19:13:00Z",
          "energy": 41.472000000067055,
          "capacity": 497.66400000080466
      },
      {
          "time_stamp_utc_ms": 1734549360000,
          "time": "2024-12-18T19:18:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1734549660000,
          "time": "2024-12-18T19:23:00Z",
          "energy": 41.47200000099838,
          "capacity": 497.6640000119806
      },
      {
          "time_stamp_utc_ms": 1734549960000,
          "time": "2024-12-18T19:28:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1734550260000,
          "time": "2024-12-18T19:33:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1734550560000,
          "time": "2024-12-18T19:38:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1734550860000,
          "time": "2024-12-18T19:43:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1734551160000,
          "time": "2024-12-18T19:48:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1734551460000,
          "time": "2024-12-18T19:53:00Z",
          "energy": 40.51200000010431,
          "capacity": 486.14400000125175
      },
      {
          "time_stamp_utc_ms": 1734551760000,
          "time": "2024-12-18T19:58:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1734552060000,
          "time": "2024-12-18T20:03:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1734552360000,
          "time": "2024-12-18T20:08:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1734552660000,
          "time": "2024-12-18T20:13:00Z",
          "energy": 40.9919999986887,
          "capacity": 491.9039999842644
      },
      {
          "time_stamp_utc_ms": 1734552960000,
          "time": "2024-12-18T20:18:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1734553260000,
          "time": "2024-12-18T20:23:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1734553560000,
          "time": "2024-12-18T20:28:00Z",
          "energy": 40.60799999907613,
          "capacity": 487.29599998891354
      },
      {
          "time_stamp_utc_ms": 1734553770000,
          "time": "2024-12-18T20:33:00Z",
          "energy": 29.616000001318753,
          "capacity": 507.7028571654643
      },
      {
          "time_stamp_utc_ms": 1734554100000,
          "time": "2024-12-18T20:38:00Z",
          "energy": 45.67999999970198,
          "capacity": 498.32727272402155
      },
      {
          "time_stamp_utc_ms": 1734554430000,
          "time": "2024-12-18T20:43:00Z",
          "energy": 41.67999999970198,
          "capacity": 454.69090908765793
      },
      {
          "time_stamp_utc_ms": 1734554700000,
          "time": "2024-12-18T20:48:00Z",
          "energy": 36.71999999973923,
          "capacity": 489.599999996523
      },
      {
          "time_stamp_utc_ms": 1734555030000,
          "time": "2024-12-18T20:53:00Z",
          "energy": 45.3600000012666,
          "capacity": 494.8363636501811
      },
      {
          "time_stamp_utc_ms": 1734555360000,
          "time": "2024-12-18T20:58:00Z",
          "energy": 44.39999999944121,
          "capacity": 484.36363635754043
      },
      {
          "time_stamp_utc_ms": 1734555600000,
          "time": "2024-12-18T21:03:00Z",
          "energy": 32.16000000014901,
          "capacity": 482.4000000022352
      },
      {
          "time_stamp_utc_ms": 1734556020000,
          "time": "2024-12-18T21:08:00Z",
          "energy": 56.79999999888241,
          "capacity": 486.8571428475635
      },
      {
          "time_stamp_utc_ms": 1734556320000,
          "time": "2024-12-18T21:13:00Z",
          "energy": 43.52000000048429,
          "capacity": 522.2400000058115
      },
      {
          "time_stamp_utc_ms": 1734556560000,
          "time": "2024-12-18T21:18:00Z",
          "energy": 32.880000000819564,
          "capacity": 493.20000001229346
      },
      {
          "time_stamp_utc_ms": 1734556800000,
          "time": "2024-12-18T21:23:00Z",
          "energy": 32.87999999988824,
          "capacity": 493.1999999983236
      },
      {
          "time_stamp_utc_ms": 1734557120000,
          "time": "2024-12-18T21:28:00Z",
          "energy": 45.43999999947846,
          "capacity": 511.1999847591859
      },
      {
          "time_stamp_utc_ms": 1734557440000,
          "time": "2024-12-18T21:33:00Z",
          "energy": 43.200000000186265,
          "capacity": 485.9999855181672
      },
      {
          "time_stamp_utc_ms": 1734557780000,
          "time": "2024-12-18T21:38:00Z",
          "energy": 47.6800000006333,
          "capacity": 504.8470729908139
      },
      {
          "time_stamp_utc_ms": 1734558030000,
          "time": "2024-12-18T21:43:00Z",
          "energy": 33.59999999962747,
          "capacity": 483.8400184516675
      },
      {
          "time_stamp_utc_ms": 1734558375000,
          "time": "2024-12-18T21:48:00Z",
          "energy": 48.12000000011176,
          "capacity": 502.1217391316009
      },
      {
          "time_stamp_utc_ms": 1734558570000,
          "time": "2024-12-18T21:53:00Z",
          "energy": 27.959999999962747,
          "capacity": 516.1846153839276
      },
      {
          "time_stamp_utc_ms": 1734558960000,
          "time": "2024-12-18T21:58:00Z",
          "energy": 52.799999999813735,
          "capacity": 487.3846153828961
      },
      {
          "time_stamp_utc_ms": 1734559260000,
          "time": "2024-12-18T22:03:00Z",
          "energy": 42.32000000029802,
          "capacity": 507.8400000035763
      },
      {
          "time_stamp_utc_ms": 1734559580000,
          "time": "2024-12-18T22:08:00Z",
          "energy": 43.519999999552965,
          "capacity": 489.5999854037543
      },
      {
          "time_stamp_utc_ms": 1734559920000,
          "time": "2024-12-18T22:13:00Z",
          "energy": 50.0,
          "capacity": 529.4117795554829
      },
      {
          "time_stamp_utc_ms": 1734560130000,
          "time": "2024-12-18T22:18:00Z",
          "energy": 28.56000000052154,
          "capacity": 489.6000000089407
      },
      {
          "time_stamp_utc_ms": 1734560440000,
          "time": "2024-12-18T22:23:00Z",
          "energy": 43.519999999552965,
          "capacity": 505.3935639296751
      },
      {
          "time_stamp_utc_ms": 1734560640000,
          "time": "2024-12-18T22:28:00Z",
          "energy": 28.479999999515712,
          "capacity": 512.6400122135731
      },
      {
          "time_stamp_utc_ms": 1734561060000,
          "time": "2024-12-18T22:33:00Z",
          "energy": 59.52000000048429,
          "capacity": 510.1714285755795
      },
      {
          "time_stamp_utc_ms": 1734561480000,
          "time": "2024-12-18T22:38:00Z",
          "energy": 59.519999999552965,
          "capacity": 510.1714285675968
      },
      {
          "time_stamp_utc_ms": 1734561660000,
          "time": "2024-12-18T22:43:00Z",
          "energy": 24.799999999813735,
          "capacity": 495.9999999962747
      },
      {
          "time_stamp_utc_ms": 1734561990000,
          "time": "2024-12-18T22:48:00Z",
          "energy": 46.12000000104308,
          "capacity": 503.12727273865187
      },
      {
          "time_stamp_utc_ms": 1734562230000,
          "time": "2024-12-18T22:53:00Z",
          "energy": 33.71999999973923,
          "capacity": 505.79999999608845
      },
      {
          "time_stamp_utc_ms": 1734562560000,
          "time": "2024-12-18T22:58:00Z",
          "energy": 46.55999999959022,
          "capacity": 507.92727272280234
      },
      {
          "time_stamp_utc_ms": 1734562860000,
          "time": "2024-12-18T23:03:00Z",
          "energy": 42.480000000447035,
          "capacity": 509.7600000053645
      },
      {
          "time_stamp_utc_ms": 1734563100000,
          "time": "2024-12-18T23:08:00Z",
          "energy": 33.12000000011176,
          "capacity": 496.8000000016764
      },
      {
          "time_stamp_utc_ms": 1734563480000,
          "time": "2024-12-18T23:13:00Z",
          "energy": 52.239999999292195,
          "capacity": 494.9052507307043
      },
      {
          "time_stamp_utc_ms": 1734563820000,
          "time": "2024-12-18T23:18:00Z",
          "energy": 48.64000000059605,
          "capacity": 515.0117791578848
      },
      {
          "time_stamp_utc_ms": 1734564090000,
          "time": "2024-12-18T23:23:00Z",
          "energy": 36.639999999664724,
          "capacity": 488.5333333288629
      },
      {
          "time_stamp_utc_ms": 1734564400000,
          "time": "2024-12-18T23:28:00Z",
          "energy": 43.84000000078231,
          "capacity": 509.10969309053127
      },
      {
          "time_stamp_utc_ms": 1734564690000,
          "time": "2024-12-18T23:33:00Z",
          "energy": 38.959999999031425,
          "capacity": 483.64136339361954
      },
      {
          "time_stamp_utc_ms": 1734564960000,
          "time": "2024-12-18T23:38:00Z",
          "energy": 38.64000000059605,
          "capacity": 515.2000000079473
      },
      {
          "time_stamp_utc_ms": 1734565260000,
          "time": "2024-12-18T23:43:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1734565560000,
          "time": "2024-12-18T23:48:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1734565860000,
          "time": "2024-12-18T23:53:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1734566160000,
          "time": "2024-12-18T23:58:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1734566460000,
          "time": "2024-12-19T00:03:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1734566760000,
          "time": "2024-12-19T00:08:00Z",
          "energy": 42.04800000041723,
          "capacity": 504.57600000500685
      },
      {
          "time_stamp_utc_ms": 1734567060000,
          "time": "2024-12-19T00:13:00Z",
          "energy": 41.66399999894202,
          "capacity": 499.96799998730427
      },
      {
          "time_stamp_utc_ms": 1734567360000,
          "time": "2024-12-19T00:18:00Z",
          "energy": 40.51200000103563,
          "capacity": 486.14400001242757
      },
      {
          "time_stamp_utc_ms": 1734567660000,
          "time": "2024-12-19T00:23:00Z",
          "energy": 39.93599999882281,
          "capacity": 479.23199998587376
      },
      {
          "time_stamp_utc_ms": 1734567960000,
          "time": "2024-12-19T00:28:00Z",
          "energy": 41.28000000026077,
          "capacity": 495.36000000312924
      },
      {
          "time_stamp_utc_ms": 1734568260000,
          "time": "2024-12-19T00:33:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1734568560000,
          "time": "2024-12-19T00:38:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1734568860000,
          "time": "2024-12-19T00:43:00Z",
          "energy": 41.28000000026077,
          "capacity": 495.36000000312924
      },
      {
          "time_stamp_utc_ms": 1734569160000,
          "time": "2024-12-19T00:48:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1734569460000,
          "time": "2024-12-19T00:53:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1734569760000,
          "time": "2024-12-19T00:58:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1734570060000,
          "time": "2024-12-19T01:03:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1734570360000,
          "time": "2024-12-19T01:08:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1734570660000,
          "time": "2024-12-19T01:13:00Z",
          "energy": 35.71200000029057,
          "capacity": 428.5440000034869
      },
      {
          "time_stamp_utc_ms": 1734570960000,
          "time": "2024-12-19T01:18:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1734571260000,
          "time": "2024-12-19T01:23:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1734571560000,
          "time": "2024-12-19T01:28:00Z",
          "energy": 36.00000000093132,
          "capacity": 432.00000001117587
      },
      {
          "time_stamp_utc_ms": 1734571860000,
          "time": "2024-12-19T01:33:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1734572160000,
          "time": "2024-12-19T01:38:00Z",
          "energy": 35.42399999964982,
          "capacity": 425.0879999957979
      },
      {
          "time_stamp_utc_ms": 1734572460000,
          "time": "2024-12-19T01:43:00Z",
          "energy": 35.80799999926239,
          "capacity": 429.6959999911487
      },
      {
          "time_stamp_utc_ms": 1734572760000,
          "time": "2024-12-19T01:48:00Z",
          "energy": 34.94400000106543,
          "capacity": 419.3280000127852
      },
      {
          "time_stamp_utc_ms": 1734573100000,
          "time": "2024-12-19T01:53:00Z",
          "energy": 41.08799999952316,
          "capacity": 435.04942396246474
      },
      {
          "time_stamp_utc_ms": 1734573340000,
          "time": "2024-12-19T01:58:00Z",
          "energy": 28.3199999993667,
          "capacity": 424.7999999905005
      },
      {
          "time_stamp_utc_ms": 1734573690000,
          "time": "2024-12-19T02:03:00Z",
          "energy": 41.52000000048429,
          "capacity": 427.06284551129926
      },
      {
          "time_stamp_utc_ms": 1734573990000,
          "time": "2024-12-19T02:08:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1734574240000,
          "time": "2024-12-19T02:13:00Z",
          "energy": 29.83999999985099,
          "capacity": 429.6960163894564
      },
      {
          "time_stamp_utc_ms": 1734574860000,
          "time": "2024-12-19T02:23:00Z",
          "energy": 76.32000000029802,
          "capacity": 443.1484007313836
      },
      {
          "time_stamp_utc_ms": 1734575190000,
          "time": "2024-12-19T02:28:00Z",
          "energy": 39.760000000707805,
          "capacity": 433.74545455317605
      },
      {
          "time_stamp_utc_ms": 1734575580000,
          "time": "2024-12-19T02:33:00Z",
          "energy": 46.799999999813735,
          "capacity": 431.99999999828066
      },
      {
          "time_stamp_utc_ms": 1734575880000,
          "time": "2024-12-19T02:38:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1734576090000,
          "time": "2024-12-19T02:43:00Z",
          "energy": 27.59999999962747,
          "capacity": 473.1428571364709
      },
      {
          "time_stamp_utc_ms": 1734576360000,
          "time": "2024-12-19T02:48:00Z",
          "energy": 31.632000000216067,
          "capacity": 421.7600000028809
      },
      {
          "time_stamp_utc_ms": 1734576660000,
          "time": "2024-12-19T02:53:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1734576960000,
          "time": "2024-12-19T02:58:00Z",
          "energy": 35.61599999945611,
          "capacity": 427.3919999934733
      },
      {
          "time_stamp_utc_ms": 1734577260000,
          "time": "2024-12-19T03:03:00Z",
          "energy": 34.94400000013411,
          "capacity": 419.3280000016093
      },
      {
          "time_stamp_utc_ms": 1734577560000,
          "time": "2024-12-19T03:08:00Z",
          "energy": 35.61600000038743,
          "capacity": 427.39200000464916
      },
      {
          "time_stamp_utc_ms": 1734577860000,
          "time": "2024-12-19T03:13:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1734578160000,
          "time": "2024-12-19T03:18:00Z",
          "energy": 35.52000000048429,
          "capacity": 426.24000000581145
      },
      {
          "time_stamp_utc_ms": 1734578460000,
          "time": "2024-12-19T03:23:00Z",
          "energy": 34.7519999993965,
          "capacity": 417.0239999927581
      },
      {
          "time_stamp_utc_ms": 1734578760000,
          "time": "2024-12-19T03:28:00Z",
          "energy": 34.84800000023097,
          "capacity": 418.1760000027716
      },
      {
          "time_stamp_utc_ms": 1734579060000,
          "time": "2024-12-19T03:33:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1734579360000,
          "time": "2024-12-19T03:38:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1734579660000,
          "time": "2024-12-19T03:43:00Z",
          "energy": 34.84800000023097,
          "capacity": 418.1760000027716
      },
      {
          "time_stamp_utc_ms": 1734579960000,
          "time": "2024-12-19T03:48:00Z",
          "energy": 34.55999999959022,
          "capacity": 414.7199999950827
      },
      {
          "time_stamp_utc_ms": 1734580260000,
          "time": "2024-12-19T03:53:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1734580560000,
          "time": "2024-12-19T03:58:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1734580860000,
          "time": "2024-12-19T04:03:00Z",
          "energy": 32.160000001080334,
          "capacity": 385.92000001296407
      },
      {
          "time_stamp_utc_ms": 1734581160000,
          "time": "2024-12-19T04:08:00Z",
          "energy": 33.69599999859929,
          "capacity": 404.3519999831915
      },
      {
          "time_stamp_utc_ms": 1734581440000,
          "time": "2024-12-19T04:13:00Z",
          "energy": 30.33600000012666,
          "capacity": 390.0342990004033
      },
      {
          "time_stamp_utc_ms": 1734581760000,
          "time": "2024-12-19T04:18:00Z",
          "energy": 34.94400000013411,
          "capacity": 393.11998828562014
      },
      {
          "time_stamp_utc_ms": 1734582040000,
          "time": "2024-12-19T04:23:00Z",
          "energy": 31.296000000089407,
          "capacity": 402.37715656317664
      },
      {
          "time_stamp_utc_ms": 1734582420000,
          "time": "2024-12-19T04:28:00Z",
          "energy": 40.480000000447035,
          "capacity": 383.4947272218911
      },
      {
          "time_stamp_utc_ms": 1734582720000,
          "time": "2024-12-19T04:33:00Z",
          "energy": 33.43999999947846,
          "capacity": 401.2799999937415
      },
      {
          "time_stamp_utc_ms": 1734582960000,
          "time": "2024-12-19T04:38:00Z",
          "energy": 25.43999999947846,
          "capacity": 381.5999999921769
      },
      {
          "time_stamp_utc_ms": 1734583230000,
          "time": "2024-12-19T04:43:00Z",
          "energy": 29.87999999988824,
          "capacity": 398.3999999985099
      },
      {
          "time_stamp_utc_ms": 1734583560000,
          "time": "2024-12-19T04:48:00Z",
          "energy": 38.472000000067055,
          "capacity": 419.69454545527697
      },
      {
          "time_stamp_utc_ms": 1734583860000,
          "time": "2024-12-19T04:53:00Z",
          "energy": 34.04800000041723,
          "capacity": 408.5760000050068
      },
      {
          "time_stamp_utc_ms": 1734584250000,
          "time": "2024-12-19T04:58:00Z",
          "energy": 43.04000000003725,
          "capacity": 397.2923076926516
      },
      {
          "time_stamp_utc_ms": 1734584520000,
          "time": "2024-12-19T05:03:00Z",
          "energy": 29.28000000026077,
          "capacity": 390.4000000034769
      },
      {
          "time_stamp_utc_ms": 1734584880000,
          "time": "2024-12-19T05:08:00Z",
          "energy": 39.83999999985099,
          "capacity": 398.3999999985099
      },
      {
          "time_stamp_utc_ms": 1734585060000,
          "time": "2024-12-19T05:13:00Z",
          "energy": 19.200000000186265,
          "capacity": 384.00000000372523
      },
      {
          "time_stamp_utc_ms": 1734585360000,
          "time": "2024-12-19T05:18:00Z",
          "energy": 31.919999999925494,
          "capacity": 383.039999999106
      },
      {
          "time_stamp_utc_ms": 1734585720000,
          "time": "2024-12-19T05:23:00Z",
          "energy": 39.83999999985099,
          "capacity": 398.3999999985099
      },
      {
          "time_stamp_utc_ms": 1734585930000,
          "time": "2024-12-19T05:28:00Z",
          "energy": 22.080000000074506,
          "capacity": 378.51428571556295
      },
      {
          "time_stamp_utc_ms": 1734586260000,
          "time": "2024-12-19T05:33:00Z",
          "energy": 36.24000000022352,
          "capacity": 395.3454545478929
      },
      {
          "time_stamp_utc_ms": 1734586545000,
          "time": "2024-12-19T05:38:00Z",
          "energy": 31.080000000074506,
          "capacity": 392.58947368515163
      },
      {
          "time_stamp_utc_ms": 1734586830000,
          "time": "2024-12-19T05:43:00Z",
          "energy": 30.359999999403954,
          "capacity": 383.49473683457626
      },
      {
          "time_stamp_utc_ms": 1734587040000,
          "time": "2024-12-19T05:48:00Z",
          "energy": 24.0,
          "capacity": 411.4285714285714
      },
      {
          "time_stamp_utc_ms": 1734587460000,
          "time": "2024-12-19T05:53:00Z",
          "energy": 44.880000000819564,
          "capacity": 384.6857142927391
      },
      {
          "time_stamp_utc_ms": 1734587700000,
          "time": "2024-12-19T05:58:00Z",
          "energy": 26.87999999988824,
          "capacity": 403.1999999983236
      },
      {
          "time_stamp_utc_ms": 1734588030000,
          "time": "2024-12-19T06:03:00Z",
          "energy": 35.28000000026077,
          "capacity": 384.87272727557206
      },
      {
          "time_stamp_utc_ms": 1734588300000,
          "time": "2024-12-19T06:08:00Z",
          "energy": 29.279999999329448,
          "capacity": 390.39999999105925
      },
      {
          "time_stamp_utc_ms": 1734588600000,
          "time": "2024-12-19T06:13:00Z",
          "energy": 32.799999999813735,
          "capacity": 393.5999999977649
      },
      {
          "time_stamp_utc_ms": 1734589050000,
          "time": "2024-12-19T06:18:00Z",
          "energy": 47.60000000055879,
          "capacity": 380.80000000447035
      },
      {
          "time_stamp_utc_ms": 1734589260000,
          "time": "2024-12-19T06:23:00Z",
          "energy": 23.03999999910593,
          "capacity": 394.9714285561016
      },
      {
          "time_stamp_utc_ms": 1734589560000,
          "time": "2024-12-19T06:28:00Z",
          "energy": 31.760000000707805,
          "capacity": 381.1200000084937
      },
      {
          "time_stamp_utc_ms": 1734589840000,
          "time": "2024-12-19T06:33:00Z",
          "energy": 29.759999999776483,
          "capacity": 382.62858445794956
      },
      {
          "time_stamp_utc_ms": 1734590180000,
          "time": "2024-12-19T06:38:00Z",
          "energy": 36.80000000074506,
          "capacity": 389.64706976072426
      },
      {
          "time_stamp_utc_ms": 1734590520000,
          "time": "2024-12-19T06:43:00Z",
          "energy": 35.83999999985099,
          "capacity": 379.48236358379233
      },
      {
          "time_stamp_utc_ms": 1734590800000,
          "time": "2024-12-19T06:48:00Z",
          "energy": 31.040000000037253,
          "capacity": 399.08572787897214
      },
      {
          "time_stamp_utc_ms": 1734591030000,
          "time": "2024-12-19T06:53:00Z",
          "energy": 24.15999999921769,
          "capacity": 378.1565295668458
      },
      {
          "time_stamp_utc_ms": 1734591360000,
          "time": "2024-12-19T06:58:00Z",
          "energy": 36.480000000447035,
          "capacity": 397.9636363685131
      },
      {
          "time_stamp_utc_ms": 1734591645000,
          "time": "2024-12-19T07:03:00Z",
          "energy": 31.559999999590218,
          "capacity": 398.65263157377115
      },
      {
          "time_stamp_utc_ms": 1734591960000,
          "time": "2024-12-19T07:08:00Z",
          "energy": 33.480000000447035,
          "capacity": 382.62857143368035
      },
      {
          "time_stamp_utc_ms": 1734592260000,
          "time": "2024-12-19T07:13:00Z",
          "energy": 34.16000000014901,
          "capacity": 409.92000000178814
      },
      {
          "time_stamp_utc_ms": 1734592590000,
          "time": "2024-12-19T07:18:00Z",
          "energy": 34.71999999973923,
          "capacity": 378.76363636079157
      },
      {
          "time_stamp_utc_ms": 1734592860000,
          "time": "2024-12-19T07:23:00Z",
          "energy": 30.239999999292195,
          "capacity": 403.19999999056256
      },
      {
          "time_stamp_utc_ms": 1734593220000,
          "time": "2024-12-19T07:28:00Z",
          "energy": 40.48000000137836,
          "capacity": 404.8000000137836
      },
      {
          "time_stamp_utc_ms": 1734593340000,
          "time": "2024-12-19T07:33:00Z",
          "energy": 13.75999999884516,
          "capacity": 412.7999999653548
      },
      {
          "time_stamp_utc_ms": 1734593760000,
          "time": "2024-12-19T07:38:00Z",
          "energy": 46.87999999988824,
          "capacity": 401.8285714276135
      },
      {
          "time_stamp_utc_ms": 1734594080000,
          "time": "2024-12-19T07:43:00Z",
          "energy": 34.40000000037253,
          "capacity": 386.99998847069253
      },
      {
          "time_stamp_utc_ms": 1734594340000,
          "time": "2024-12-19T07:48:00Z",
          "energy": 28.320000000298023,
          "capacity": 392.1230625442152
      },
      {
          "time_stamp_utc_ms": 1734594720000,
          "time": "2024-12-19T07:53:00Z",
          "energy": 40.87999999988824,
          "capacity": 387.2842008057045
      },
      {
          "time_stamp_utc_ms": 1734594945000,
          "time": "2024-12-19T07:58:00Z",
          "energy": 24.480000000447035,
          "capacity": 391.68000000715256
      },
      {
          "time_stamp_utc_ms": 1734595275000,
          "time": "2024-12-19T08:03:00Z",
          "energy": 36.71999999973923,
          "capacity": 400.58181817897344
      },
      {
          "time_stamp_utc_ms": 1734595560000,
          "time": "2024-12-19T08:08:00Z",
          "energy": 31.679999999701977,
          "capacity": 400.16842104886706
      },
      {
          "time_stamp_utc_ms": 1734595860000,
          "time": "2024-12-19T08:13:00Z",
          "energy": 33.919999999925494,
          "capacity": 407.03999999910593
      },
      {
          "time_stamp_utc_ms": 1734596120000,
          "time": "2024-12-19T08:18:00Z",
          "energy": 27.83999999985099,
          "capacity": 385.4769089356511
      },
      {
          "time_stamp_utc_ms": 1734596445000,
          "time": "2024-12-19T08:23:00Z",
          "energy": 34.84000000078231,
          "capacity": 385.9200113330349
      },
      {
          "time_stamp_utc_ms": 1734596790000,
          "time": "2024-12-19T08:28:00Z",
          "energy": 38.519999999552965,
          "capacity": 401.9478260822918
      },
      {
          "time_stamp_utc_ms": 1734597120000,
          "time": "2024-12-19T08:33:00Z",
          "energy": 35.04000000003725,
          "capacity": 382.2545454549519
      },
      {
          "time_stamp_utc_ms": 1734597420000,
          "time": "2024-12-19T08:38:00Z",
          "energy": 34.16000000014901,
          "capacity": 409.92000000178814
      },
      {
          "time_stamp_utc_ms": 1734597640000,
          "time": "2024-12-19T08:43:00Z",
          "energy": 23.199999999254942,
          "capacity": 379.63635539577535
      },
      {
          "time_stamp_utc_ms": 1734597945000,
          "time": "2024-12-19T08:48:00Z",
          "energy": 33.72000000067055,
          "capacity": 398.00654494009115
      },
      {
          "time_stamp_utc_ms": 1734598200000,
          "time": "2024-12-19T08:53:00Z",
          "energy": 28.200000000186265,
          "capacity": 398.1176470614531
      },
      {
          "time_stamp_utc_ms": 1734598560000,
          "time": "2024-12-19T08:58:00Z",
          "energy": 38.639999999664724,
          "capacity": 386.39999999664724
      },
      {
          "time_stamp_utc_ms": 1734598875000,
          "time": "2024-12-19T09:03:00Z",
          "energy": 34.919999999925494,
          "capacity": 399.0857142848627
      },
      {
          "time_stamp_utc_ms": 1734599180000,
          "time": "2024-12-19T09:08:00Z",
          "energy": 33.16000000014901,
          "capacity": 391.39670907503785
      },
      {
          "time_stamp_utc_ms": 1734599490000,
          "time": "2024-12-19T09:13:00Z",
          "energy": 34.39999999944121,
          "capacity": 399.4838832508501
      },
      {
          "time_stamp_utc_ms": 1734599745000,
          "time": "2024-12-19T09:18:00Z",
          "energy": 28.08000000100583,
          "capacity": 396.4235294259646
      },
      {
          "time_stamp_utc_ms": 1734600030000,
          "time": "2024-12-19T09:23:00Z",
          "energy": 30.959999999962747,
          "capacity": 391.0736842100557
      },
      {
          "time_stamp_utc_ms": 1734600360000,
          "time": "2024-12-19T09:28:00Z",
          "energy": 39.03999999910593,
          "capacity": 425.89090908115566
      },
      {
          "time_stamp_utc_ms": 1734600600000,
          "time": "2024-12-19T09:33:00Z",
          "energy": 29.3600000012666,
          "capacity": 440.400000018999
      },
      {
          "time_stamp_utc_ms": 1734600960000,
          "time": "2024-12-19T09:38:00Z",
          "energy": 45.35999999940395,
          "capacity": 453.59999999403954
      },
      {
          "time_stamp_utc_ms": 1734601260000,
          "time": "2024-12-19T09:43:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1734601620000,
          "time": "2024-12-19T09:48:00Z",
          "energy": 44.32000000029802,
          "capacity": 443.20000000298023
      },
      {
          "time_stamp_utc_ms": 1734601980000,
          "time": "2024-12-19T09:53:00Z",
          "energy": 47.360000000335276,
          "capacity": 473.60000000335276
      },
      {
          "time_stamp_utc_ms": 1734602220000,
          "time": "2024-12-19T09:58:00Z",
          "energy": 29.759999999776483,
          "capacity": 446.39999999664724
      },
      {
          "time_stamp_utc_ms": 1734602430000,
          "time": "2024-12-19T10:03:00Z",
          "energy": 28.080000000074506,
          "capacity": 481.3714285727058
      },
      {
          "time_stamp_utc_ms": 1734602780000,
          "time": "2024-12-19T10:08:00Z",
          "energy": 45.35999999940395,
          "capacity": 466.5599872811184
      },
      {
          "time_stamp_utc_ms": 1734603150000,
          "time": "2024-12-19T10:13:00Z",
          "energy": 50.64000000059605,
          "capacity": 492.71352621899496
      },
      {
          "time_stamp_utc_ms": 1734603420000,
          "time": "2024-12-19T10:18:00Z",
          "energy": 33.67999999970198,
          "capacity": 449.066666662693
      },
      {
          "time_stamp_utc_ms": 1734603720000,
          "time": "2024-12-19T10:23:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1734603900000,
          "time": "2024-12-19T10:28:00Z",
          "energy": 23.520000000484288,
          "capacity": 470.40000000968575
      },
      {
          "time_stamp_utc_ms": 1734604300000,
          "time": "2024-12-19T10:33:00Z",
          "energy": 51.519999999552965,
          "capacity": 463.6800110509696
      },
      {
          "time_stamp_utc_ms": 1734604545000,
          "time": "2024-12-19T10:38:00Z",
          "energy": 31.080000000074506,
          "capacity": 456.68569651009807
      },
      {
          "time_stamp_utc_ms": 1734604845000,
          "time": "2024-12-19T10:43:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1734605160000,
          "time": "2024-12-19T10:48:00Z",
          "energy": 46.77600000053644,
          "capacity": 534.5828571489878
      },
      {
          "time_stamp_utc_ms": 1734605490000,
          "time": "2024-12-19T10:53:00Z",
          "energy": 51.743999999947846,
          "capacity": 564.479999999431
      },
      {
          "time_stamp_utc_ms": 1734605780000,
          "time": "2024-12-19T10:58:00Z",
          "energy": 42.87999999988824,
          "capacity": 532.3034307695053
      },
      {
          "time_stamp_utc_ms": 1734606020000,
          "time": "2024-12-19T11:03:00Z",
          "energy": 35.519999999552965,
          "capacity": 532.7999999932945
      },
      {
          "time_stamp_utc_ms": 1734606300000,
          "time": "2024-12-19T11:08:00Z",
          "energy": 41.60000000055879,
          "capacity": 534.8571610814537
      },
      {
          "time_stamp_utc_ms": 1734606645000,
          "time": "2024-12-19T11:13:00Z",
          "energy": 50.27999999932945,
          "capacity": 524.6608695582203
      },
      {
          "time_stamp_utc_ms": 1734606990000,
          "time": "2024-12-19T11:18:00Z",
          "energy": 50.040000000968575,
          "capacity": 522.1565217492373
      },
      {
          "time_stamp_utc_ms": 1734607350000,
          "time": "2024-12-19T11:23:00Z",
          "energy": 53.519999999552965,
          "capacity": 535.1999999955297
      },
      {
          "time_stamp_utc_ms": 1734607560000,
          "time": "2024-12-19T11:28:00Z",
          "energy": 29.87999999988824,
          "capacity": 512.2285714266555
      },
      {
          "time_stamp_utc_ms": 1734607845000,
          "time": "2024-12-19T11:33:00Z",
          "energy": 42.59999999962747,
          "capacity": 538.1052631531891
      },
      {
          "time_stamp_utc_ms": 1734608160000,
          "time": "2024-12-19T11:38:00Z",
          "energy": 46.80000000074506,
          "capacity": 534.8571428656578
      },
      {
          "time_stamp_utc_ms": 1734608400000,
          "time": "2024-12-19T11:43:00Z",
          "energy": 34.07999999914318,
          "capacity": 511.19999998714775
      },
      {
          "time_stamp_utc_ms": 1734608820000,
          "time": "2024-12-19T11:48:00Z",
          "energy": 64.0800000000745,
          "capacity": 549.2571428577814
      },
      {
          "time_stamp_utc_ms": 1734609040000,
          "time": "2024-12-19T11:53:00Z",
          "energy": 32.880000000819564,
          "capacity": 538.0363519881507
      },
      {
          "time_stamp_utc_ms": 1734609380000,
          "time": "2024-12-19T11:58:00Z",
          "energy": 52.3199999993667,
          "capacity": 553.9764861201517
      },
      {
          "time_stamp_utc_ms": 1734609660000,
          "time": "2024-12-19T12:03:00Z",
          "energy": 41.27999999932945,
          "capacity": 530.7428752112304
      },
      {
          "time_stamp_utc_ms": 1734609840000,
          "time": "2024-12-19T12:08:00Z",
          "energy": 27.84000000078231,
          "capacity": 556.8000000156462
      },
      {
          "time_stamp_utc_ms": 1734610220000,
          "time": "2024-12-19T12:13:00Z",
          "energy": 57.919999999925494,
          "capacity": 548.7157757020273
      },
      {
          "time_stamp_utc_ms": 1734610540000,
          "time": "2024-12-19T12:18:00Z",
          "energy": 48.16000000014901,
          "capacity": 541.7999838547786
      },
      {
          "time_stamp_utc_ms": 1734610920000,
          "time": "2024-12-19T12:23:00Z",
          "energy": 57.83999999985099,
          "capacity": 547.9578809835
      },
      {
          "time_stamp_utc_ms": 1734611190000,
          "time": "2024-12-19T12:28:00Z",
          "energy": 40.080000000074506,
          "capacity": 534.4000000009934
      },
      {
          "time_stamp_utc_ms": 1734611490000,
          "time": "2024-12-19T12:33:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1734611760000,
          "time": "2024-12-19T12:38:00Z",
          "energy": 40.3199999993667,
          "capacity": 537.5999999915559
      },
      {
          "time_stamp_utc_ms": 1734612060000,
          "time": "2024-12-19T12:43:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1734612360000,
          "time": "2024-12-19T12:48:00Z",
          "energy": 45.8879999993369,
          "capacity": 550.6559999920429
      },
      {
          "time_stamp_utc_ms": 1734612660000,
          "time": "2024-12-19T12:53:00Z",
          "energy": 44.44800000078976,
          "capacity": 533.3760000094773
      },
      {
          "time_stamp_utc_ms": 1734612960000,
          "time": "2024-12-19T12:58:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1734613260000,
          "time": "2024-12-19T13:03:00Z",
          "energy": 46.368000000715256,
          "capacity": 556.4160000085831
      },
      {
          "time_stamp_utc_ms": 1734613560000,
          "time": "2024-12-19T13:08:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1734613860000,
          "time": "2024-12-19T13:13:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1734614160000,
          "time": "2024-12-19T13:18:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1734614460000,
          "time": "2024-12-19T13:23:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1734614760000,
          "time": "2024-12-19T13:28:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1734615060000,
          "time": "2024-12-19T13:33:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1734615360000,
          "time": "2024-12-19T13:38:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1734615660000,
          "time": "2024-12-19T13:43:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1734615960000,
          "time": "2024-12-19T13:48:00Z",
          "energy": 46.752000000327826,
          "capacity": 561.0240000039339
      },
      {
          "time_stamp_utc_ms": 1734616260000,
          "time": "2024-12-19T13:53:00Z",
          "energy": 45.023999999277294,
          "capacity": 540.2879999913275
      },
      {
          "time_stamp_utc_ms": 1734616600000,
          "time": "2024-12-19T13:58:00Z",
          "energy": 52.06400000024587,
          "capacity": 551.2658978181365
      },
      {
          "time_stamp_utc_ms": 1734616920000,
          "time": "2024-12-19T14:03:00Z",
          "energy": 48.40000000037253,
          "capacity": 544.4999837768269
      },
      {
          "time_stamp_utc_ms": 1734617180000,
          "time": "2024-12-19T14:08:00Z",
          "energy": 39.59999999962747,
          "capacity": 548.3076721907287
      },
      {
          "time_stamp_utc_ms": 1734617400000,
          "time": "2024-12-19T14:13:00Z",
          "energy": 33.35999999940395,
          "capacity": 545.890897249289
      },
      {
          "time_stamp_utc_ms": 1734617760000,
          "time": "2024-12-19T14:18:00Z",
          "energy": 53.880000000819564,
          "capacity": 538.8000000081956
      },
      {
          "time_stamp_utc_ms": 1734618120000,
          "time": "2024-12-19T14:23:00Z",
          "energy": 54.35999999940395,
          "capacity": 543.5999999940394
      },
      {
          "time_stamp_utc_ms": 1734618380000,
          "time": "2024-12-19T14:28:00Z",
          "energy": 39.04000000003725,
          "capacity": 540.5538263269658
      },
      {
          "time_stamp_utc_ms": 1734618940000,
          "time": "2024-12-19T14:38:00Z",
          "energy": 85.12000000104308,
          "capacity": 547.2000186442272
      },
      {
          "time_stamp_utc_ms": 1734619230000,
          "time": "2024-12-19T14:43:00Z",
          "energy": 45.75999999977648,
          "capacity": 568.0551537303422
      },
      {
          "time_stamp_utc_ms": 1734619560000,
          "time": "2024-12-19T14:48:00Z",
          "energy": 50.11199999973178,
          "capacity": 546.6763636334376
      },
      {
          "time_stamp_utc_ms": 1734619860000,
          "time": "2024-12-19T14:53:00Z",
          "energy": 45.64799999911338,
          "capacity": 547.7759999893606
      },
      {
          "time_stamp_utc_ms": 1734620160000,
          "time": "2024-12-19T14:58:00Z",
          "energy": 44.64000000059605,
          "capacity": 535.6800000071527
      },
      {
          "time_stamp_utc_ms": 1734620580000,
          "time": "2024-12-19T15:03:00Z",
          "energy": 60.24000000022352,
          "capacity": 516.342857144773
      },
      {
          "time_stamp_utc_ms": 1734620760000,
          "time": "2024-12-19T15:08:00Z",
          "energy": 22.3199999993667,
          "capacity": 446.399999987334
      },
      {
          "time_stamp_utc_ms": 1734621120000,
          "time": "2024-12-19T15:13:00Z",
          "energy": 45.360000000335276,
          "capacity": 453.60000000335276
      },
      {
          "time_stamp_utc_ms": 1734621360000,
          "time": "2024-12-19T15:18:00Z",
          "energy": 30.71999999973923,
          "capacity": 460.79999999608845
      },
      {
          "time_stamp_utc_ms": 1734621660000,
          "time": "2024-12-19T15:23:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1734621960000,
          "time": "2024-12-19T15:28:00Z",
          "energy": 38.080000000074506,
          "capacity": 456.96000000089407
      },
      {
          "time_stamp_utc_ms": 1734622200000,
          "time": "2024-12-19T15:33:00Z",
          "energy": 31.520000000484288,
          "capacity": 472.8000000072643
      },
      {
          "time_stamp_utc_ms": 1734622860000,
          "time": "2024-12-19T15:43:00Z",
          "energy": 85.343999998644,
          "capacity": 465.5127272653309
      },
      {
          "time_stamp_utc_ms": 1734623160000,
          "time": "2024-12-19T15:48:00Z",
          "energy": 39.552000001072884,
          "capacity": 474.6240000128746
      },
      {
          "time_stamp_utc_ms": 1734623460000,
          "time": "2024-12-19T15:53:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1734623760000,
          "time": "2024-12-19T15:58:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1734624060000,
          "time": "2024-12-19T16:03:00Z",
          "energy": 32.832000001333654,
          "capacity": 393.9840000160039
      },
      {
          "time_stamp_utc_ms": 1734624360000,
          "time": "2024-12-19T16:08:00Z",
          "energy": 37.055999998003244,
          "capacity": 444.67199997603893
      },
      {
          "time_stamp_utc_ms": 1734624660000,
          "time": "2024-12-19T16:13:00Z",
          "energy": 35.61600000131875,
          "capacity": 427.39200001582503
      },
      {
          "time_stamp_utc_ms": 1734624960000,
          "time": "2024-12-19T16:18:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1734625220000,
          "time": "2024-12-19T16:23:00Z",
          "energy": 33.40800000075251,
          "capacity": 462.5722907356766
      },
      {
          "time_stamp_utc_ms": 1734625560000,
          "time": "2024-12-19T16:28:00Z",
          "energy": 39.119999998249114,
          "capacity": 414.211776305671
      },
      {
          "time_stamp_utc_ms": 1734625920000,
          "time": "2024-12-19T16:33:00Z",
          "energy": 43.92000000085682,
          "capacity": 439.20000000856817
      },
      {
          "time_stamp_utc_ms": 1734626120000,
          "time": "2024-12-19T16:38:00Z",
          "energy": 22.399999999441206,
          "capacity": 403.20000960297904
      },
      {
          "time_stamp_utc_ms": 1734626400000,
          "time": "2024-12-19T16:43:00Z",
          "energy": 33.28000000026077,
          "capacity": 427.88572886276813
      },
      {
          "time_stamp_utc_ms": 1734626820000,
          "time": "2024-12-19T16:48:00Z",
          "energy": 50.87999999988824,
          "capacity": 436.1142857133278
      },
      {
          "time_stamp_utc_ms": 1734627060000,
          "time": "2024-12-19T16:53:00Z",
          "energy": 28.56000000052154,
          "capacity": 428.4000000078231
      },
      {
          "time_stamp_utc_ms": 1734627420000,
          "time": "2024-12-19T16:58:00Z",
          "energy": 43.27999999932945,
          "capacity": 432.7999999932945
      },
      {
          "time_stamp_utc_ms": 1734627660000,
          "time": "2024-12-19T17:03:00Z",
          "energy": 28.768000000156462,
          "capacity": 431.52000000234693
      },
      {
          "time_stamp_utc_ms": 1734627945000,
          "time": "2024-12-19T17:08:00Z",
          "energy": 36.4320000000298,
          "capacity": 460.19368421090275
      },
      {
          "time_stamp_utc_ms": 1734628290000,
          "time": "2024-12-19T17:13:00Z",
          "energy": 43.799999999813735,
          "capacity": 457.0434782589259
      },
      {
          "time_stamp_utc_ms": 1734628580000,
          "time": "2024-12-19T17:18:00Z",
          "energy": 30.519999999552965,
          "capacity": 378.86895305246475
      },
      {
          "time_stamp_utc_ms": 1734628800000,
          "time": "2024-12-19T17:23:00Z",
          "energy": 25.760000000707805,
          "capacity": 421.5272636024976
      },
      {
          "time_stamp_utc_ms": 1734629130000,
          "time": "2024-12-19T17:28:00Z",
          "energy": 38.15999999921769,
          "capacity": 416.2909090823748
      },
      {
          "time_stamp_utc_ms": 1734629490000,
          "time": "2024-12-19T17:33:00Z",
          "energy": 47.16000000014901,
          "capacity": 471.60000000149006
      },
      {
          "time_stamp_utc_ms": 1734629700000,
          "time": "2024-12-19T17:38:00Z",
          "energy": 24.600000000558794,
          "capacity": 421.71428572386503
      },
      {
          "time_stamp_utc_ms": 1734630080000,
          "time": "2024-12-19T17:43:00Z",
          "energy": 40.799999999813735,
          "capacity": 386.5263060871772
      },
      {
          "time_stamp_utc_ms": 1734630390000,
          "time": "2024-12-19T17:48:00Z",
          "energy": 33.12000000011176,
          "capacity": 384.6193666723176
      },
      {
          "time_stamp_utc_ms": 1734630750000,
          "time": "2024-12-19T17:53:00Z",
          "energy": 40.32000000029802,
          "capacity": 403.20000000298023
      },
      {
          "time_stamp_utc_ms": 1734631000000,
          "time": "2024-12-19T17:58:00Z",
          "energy": 33.59999999962747,
          "capacity": 483.8400184516675
      },
      {
          "time_stamp_utc_ms": 1734631140000,
          "time": "2024-12-19T18:03:00Z",
          "energy": 18.240000000223517,
          "capacity": 469.0285874093375
      },
      {
          "time_stamp_utc_ms": 1734631540000,
          "time": "2024-12-19T18:08:00Z",
          "energy": 47.519999999552965,
          "capacity": 427.68001019266273
      },
      {
          "time_stamp_utc_ms": 1734631920000,
          "time": "2024-12-19T18:13:00Z",
          "energy": 45.12000000104308,
          "capacity": 427.4526208611822
      },
      {
          "time_stamp_utc_ms": 1734632100000,
          "time": "2024-12-19T18:18:00Z",
          "energy": 23.519999999552965,
          "capacity": 470.3999999910593
      },
      {
          "time_stamp_utc_ms": 1734632460000,
          "time": "2024-12-19T18:23:00Z",
          "energy": 48.28799999970943,
          "capacity": 482.8799999970943
      },
      {
          "time_stamp_utc_ms": 1734632760000,
          "time": "2024-12-19T18:28:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1734633060000,
          "time": "2024-12-19T18:33:00Z",
          "energy": 34.17600000090897,
          "capacity": 410.11200001090765
      },
      {
          "time_stamp_utc_ms": 1734633360000,
          "time": "2024-12-19T18:38:00Z",
          "energy": 36.575999999418855,
          "capacity": 438.91199999302626
      },
      {
          "time_stamp_utc_ms": 1734633660000,
          "time": "2024-12-19T18:43:00Z",
          "energy": 32.256000000052154,
          "capacity": 387.07200000062585
      },
      {
          "time_stamp_utc_ms": 1734633960000,
          "time": "2024-12-19T18:48:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1734634140000,
          "time": "2024-12-19T18:53:00Z",
          "energy": 21.407999999821186,
          "capacity": 428.1599999964237
      },
      {
          "time_stamp_utc_ms": 1734634590000,
          "time": "2024-12-19T18:58:00Z",
          "energy": 46.80000000074506,
          "capacity": 374.40000000596046
      },
      {
          "time_stamp_utc_ms": 1734634860000,
          "time": "2024-12-19T19:03:00Z",
          "energy": 29.03999999910593,
          "capacity": 387.19999998807907
      },
      {
          "time_stamp_utc_ms": 1734635160000,
          "time": "2024-12-19T19:08:00Z",
          "energy": 34.56000000052154,
          "capacity": 414.7200000062585
      },
      {
          "time_stamp_utc_ms": 1734635480000,
          "time": "2024-12-19T19:13:00Z",
          "energy": 36.639999999664724,
          "capacity": 412.19998771171123
      },
      {
          "time_stamp_utc_ms": 1734635760000,
          "time": "2024-12-19T19:18:00Z",
          "energy": 30.40000000037253,
          "capacity": 390.85715617444794
      },
      {
          "time_stamp_utc_ms": 1734636060000,
          "time": "2024-12-19T19:23:00Z",
          "energy": 33.28000000026077,
          "capacity": 399.36000000312924
      },
      {
          "time_stamp_utc_ms": 1734636320000,
          "time": "2024-12-19T19:28:00Z",
          "energy": 28.479999999515712,
          "capacity": 394.338447067508
      },
      {
          "time_stamp_utc_ms": 1734636660000,
          "time": "2024-12-19T19:33:00Z",
          "energy": 36.480000000447035,
          "capacity": 386.2588343684136
      },
      {
          "time_stamp_utc_ms": 1734636980000,
          "time": "2024-12-19T19:38:00Z",
          "energy": 36.32000000029802,
          "capacity": 408.5999878261242
      },
      {
          "time_stamp_utc_ms": 1734637290000,
          "time": "2024-12-19T19:43:00Z",
          "energy": 35.04000000003725,
          "capacity": 406.91614155093174
      },
      {
          "time_stamp_utc_ms": 1734637560000,
          "time": "2024-12-19T19:48:00Z",
          "energy": 31.679999998770654,
          "capacity": 422.39999998360867
      },
      {
          "time_stamp_utc_ms": 1734637840000,
          "time": "2024-12-19T19:53:00Z",
          "energy": 32.96000000089407,
          "capacity": 423.7714430164931
      },
      {
          "time_stamp_utc_ms": 1734638040000,
          "time": "2024-12-19T19:58:00Z",
          "energy": 23.679999999701977,
          "capacity": 426.24001015698934
      },
      {
          "time_stamp_utc_ms": 1734638340000,
          "time": "2024-12-19T20:03:00Z",
          "energy": 34.55999999959022,
          "capacity": 414.7199999950827
      },
      {
          "time_stamp_utc_ms": 1734638740000,
          "time": "2024-12-19T20:08:00Z",
          "energy": 48.480000000447035,
          "capacity": 436.320010406703
      },
      {
          "time_stamp_utc_ms": 1734639100000,
          "time": "2024-12-19T20:13:00Z",
          "energy": 43.6800000006333,
          "capacity": 436.800000006333
      },
      {
          "time_stamp_utc_ms": 1734639360000,
          "time": "2024-12-19T20:18:00Z",
          "energy": 31.775999998673797,
          "capacity": 439.9753684588215
      },
      {
          "time_stamp_utc_ms": 1734639660000,
          "time": "2024-12-19T20:23:00Z",
          "energy": 37.2480000006035,
          "capacity": 446.976000007242
      },
      {
          "time_stamp_utc_ms": 1734639960000,
          "time": "2024-12-19T20:28:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1734640260000,
          "time": "2024-12-19T20:33:00Z",
          "energy": 35.52000000048429,
          "capacity": 426.24000000581145
      },
      {
          "time_stamp_utc_ms": 1734640560000,
          "time": "2024-12-19T20:38:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1734640890000,
          "time": "2024-12-19T20:43:00Z",
          "energy": 40.56000000052154,
          "capacity": 442.4727272784168
      },
      {
          "time_stamp_utc_ms": 1734641130000,
          "time": "2024-12-19T20:48:00Z",
          "energy": 29.15999999921769,
          "capacity": 437.39999998826534
      },
      {
          "time_stamp_utc_ms": 1734641480000,
          "time": "2024-12-19T20:53:00Z",
          "energy": 45.64000000059605,
          "capacity": 469.4399872149062
      },
      {
          "time_stamp_utc_ms": 1734641740000,
          "time": "2024-12-19T20:58:00Z",
          "energy": 32.6399999987334,
          "capacity": 451.9384449439207
      },
      {
          "time_stamp_utc_ms": 1734642060000,
          "time": "2024-12-19T21:03:00Z",
          "energy": 42.24000000022352,
          "capacity": 475.19998584045135
      },
      {
          "time_stamp_utc_ms": 1734642360000,
          "time": "2024-12-19T21:08:00Z",
          "energy": 40.40000000130385,
          "capacity": 484.8000000156463
      },
      {
          "time_stamp_utc_ms": 1734642720000,
          "time": "2024-12-19T21:13:00Z",
          "energy": 46.79999999888241,
          "capacity": 467.99999998882413
      },
      {
          "time_stamp_utc_ms": 1734642960000,
          "time": "2024-12-19T21:18:00Z",
          "energy": 31.919999999925494,
          "capacity": 478.7999999988824
      },
      {
          "time_stamp_utc_ms": 1734643140000,
          "time": "2024-12-19T21:23:00Z",
          "energy": 23.760000000707805,
          "capacity": 475.2000000141561
      },
      {
          "time_stamp_utc_ms": 1734643560000,
          "time": "2024-12-19T21:28:00Z",
          "energy": 54.81599999964237,
          "capacity": 469.8514285683632
      },
      {
          "time_stamp_utc_ms": 1734643860000,
          "time": "2024-12-19T21:33:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1734644190000,
          "time": "2024-12-19T21:38:00Z",
          "energy": 42.480000000447035,
          "capacity": 463.41818182305855
      },
      {
          "time_stamp_utc_ms": 1734644340000,
          "time": "2024-12-19T21:43:00Z",
          "energy": 19.919999999925494,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1734644760000,
          "time": "2024-12-19T21:48:00Z",
          "energy": 54.71999999973923,
          "capacity": 469.0285714263362
      },
      {
          "time_stamp_utc_ms": 1734645120000,
          "time": "2024-12-19T21:53:00Z",
          "energy": 48.95999999996275,
          "capacity": 489.5999999996274
      },
      {
          "time_stamp_utc_ms": 1734645600000,
          "time": "2024-12-19T22:03:00Z",
          "energy": 63.360000000335276,
          "capacity": 475.20000000251457
      },
      {
          "time_stamp_utc_ms": 1734645980000,
          "time": "2024-12-19T22:08:00Z",
          "energy": 53.28000000026077,
          "capacity": 504.75788207155915
      },
      {
          "time_stamp_utc_ms": 1734646290000,
          "time": "2024-12-19T22:13:00Z",
          "energy": 40.07999999914318,
          "capacity": 465.4451755991824
      },
      {
          "time_stamp_utc_ms": 1734646540000,
          "time": "2024-12-19T22:18:00Z",
          "energy": 36.24000000022352,
          "capacity": 521.856019910446
      },
      {
          "time_stamp_utc_ms": 1734646860000,
          "time": "2024-12-19T22:23:00Z",
          "energy": 44.16000000014901,
          "capacity": 496.7999851958831
      },
      {
          "time_stamp_utc_ms": 1734647190000,
          "time": "2024-12-19T22:28:00Z",
          "energy": 45.12000000011176,
          "capacity": 492.21818181940097
      },
      {
          "time_stamp_utc_ms": 1734647500000,
          "time": "2024-12-19T22:33:00Z",
          "energy": 42.24000000022352,
          "capacity": 490.5290473511426
      },
      {
          "time_stamp_utc_ms": 1734647670000,
          "time": "2024-12-19T22:38:00Z",
          "energy": 22.799999999813735,
          "capacity": 482.82354295065596
      },
      {
          "time_stamp_utc_ms": 1734648180000,
          "time": "2024-12-19T22:43:00Z",
          "energy": 70.32000000029802,
          "capacity": 496.376470590339
      },
      {
          "time_stamp_utc_ms": 1734648330000,
          "time": "2024-12-19T22:48:00Z",
          "energy": 20.6399999987334,
          "capacity": 495.35999996960163
      },
      {
          "time_stamp_utc_ms": 1734648630000,
          "time": "2024-12-19T22:53:00Z",
          "energy": 41.52000000141561,
          "capacity": 498.2400000169874
      },
      {
          "time_stamp_utc_ms": 1734649020000,
          "time": "2024-12-19T22:58:00Z",
          "energy": 54.47999999951571,
          "capacity": 502.89230768783733
      },
      {
          "time_stamp_utc_ms": 1734649260000,
          "time": "2024-12-19T23:03:00Z",
          "energy": 32.256000000052154,
          "capacity": 483.8400000007823
      },
      {
          "time_stamp_utc_ms": 1734649500000,
          "time": "2024-12-19T23:08:00Z",
          "energy": 33.264000000432134,
          "capacity": 498.960000006482
      },
      {
          "time_stamp_utc_ms": 1734649950000,
          "time": "2024-12-19T23:13:00Z",
          "energy": 62.639999999664724,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1734650130000,
          "time": "2024-12-19T23:18:00Z",
          "energy": 24.59999999962747,
          "capacity": 491.99999999254936
      },
      {
          "time_stamp_utc_ms": 1734650400000,
          "time": "2024-12-19T23:23:00Z",
          "energy": 38.28000000026077,
          "capacity": 510.400000003477
      },
      {
          "time_stamp_utc_ms": 1734650790000,
          "time": "2024-12-19T23:28:00Z",
          "energy": 52.55999999959022,
          "capacity": 485.1692307654482
      },
      {
          "time_stamp_utc_ms": 1734651080000,
          "time": "2024-12-19T23:33:00Z",
          "energy": 41.83999999985099,
          "capacity": 519.3930863660175
      },
      {
          "time_stamp_utc_ms": 1734651420000,
          "time": "2024-12-19T23:38:00Z",
          "energy": 46.080000000074506,
          "capacity": 487.90589603912184
      },
      {
          "time_stamp_utc_ms": 1734651680000,
          "time": "2024-12-19T23:43:00Z",
          "energy": 35.519999999552965,
          "capacity": 491.8153665695148
      },
      {
          "time_stamp_utc_ms": 1734652020000,
          "time": "2024-12-19T23:48:00Z",
          "energy": 45.28000000119209,
          "capacity": 479.43530757806747
      },
      {
          "time_stamp_utc_ms": 1734652300000,
          "time": "2024-12-19T23:53:00Z",
          "energy": 37.43999999947846,
          "capacity": 481.3714449601368
      },
      {
          "time_stamp_utc_ms": 1734652620000,
          "time": "2024-12-19T23:58:00Z",
          "energy": 43.200000000186265,
          "capacity": 485.9999855181672
      },
      {
          "time_stamp_utc_ms": 1734652980000,
          "time": "2024-12-20T00:03:00Z",
          "energy": 47.519999999552965,
          "capacity": 475.19999999552965
      },
      {
          "time_stamp_utc_ms": 1734653175000,
          "time": "2024-12-20T00:08:00Z",
          "energy": 26.520000000484288,
          "capacity": 489.6000000089407
      },
      {
          "time_stamp_utc_ms": 1734653475000,
          "time": "2024-12-20T00:13:00Z",
          "energy": 39.95999999996275,
          "capacity": 479.519999999553
      },
      {
          "time_stamp_utc_ms": 1734653775000,
          "time": "2024-12-20T00:18:00Z",
          "energy": 41.40000000037253,
          "capacity": 496.8000000044704
      },
      {
          "time_stamp_utc_ms": 1734654080000,
          "time": "2024-12-20T00:23:00Z",
          "energy": 42.67999999970198,
          "capacity": 503.7639186710164
      },
      {
          "time_stamp_utc_ms": 1734654360000,
          "time": "2024-12-20T00:28:00Z",
          "energy": 37.44000000040978,
          "capacity": 481.3714449721109
      },
      {
          "time_stamp_utc_ms": 1734654680000,
          "time": "2024-12-20T00:33:00Z",
          "energy": 42.71999999880791,
          "capacity": 480.59998566359326
      },
      {
          "time_stamp_utc_ms": 1734654980000,
          "time": "2024-12-20T00:38:00Z",
          "energy": 39.6800000006333,
          "capacity": 476.16000000759965
      },
      {
          "time_stamp_utc_ms": 1734655200000,
          "time": "2024-12-20T00:43:00Z",
          "energy": 28.16000000014901,
          "capacity": 460.79999001486755
      },
      {
          "time_stamp_utc_ms": 1734655500000,
          "time": "2024-12-20T00:48:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1734655880000,
          "time": "2024-12-20T00:53:00Z",
          "energy": 48.3199999993667,
          "capacity": 457.7684095581589
      },
      {
          "time_stamp_utc_ms": 1734656120000,
          "time": "2024-12-20T00:58:00Z",
          "energy": 30.080000000074506,
          "capacity": 451.2000000011176
      },
      {
          "time_stamp_utc_ms": 1734656520000,
          "time": "2024-12-20T01:03:00Z",
          "energy": 48.32000000029802,
          "capacity": 434.8800103710296
      },
      {
          "time_stamp_utc_ms": 1734656670000,
          "time": "2024-12-20T01:08:00Z",
          "energy": 17.520000000484288,
          "capacity": 420.4800000116229
      },
      {
          "time_stamp_utc_ms": 1734657060000,
          "time": "2024-12-20T01:13:00Z",
          "energy": 47.119999999180436,
          "capacity": 434.95384614628097
      },
      {
          "time_stamp_utc_ms": 1734657380000,
          "time": "2024-12-20T01:18:00Z",
          "energy": 36.96000000089407,
          "capacity": 415.799987618253
      },
      {
          "time_stamp_utc_ms": 1734657645000,
          "time": "2024-12-20T01:23:00Z",
          "energy": 32.19999999925494,
          "capacity": 437.43397799627655
      },
      {
          "time_stamp_utc_ms": 1734657990000,
          "time": "2024-12-20T01:28:00Z",
          "energy": 39.72000000067055,
          "capacity": 414.46956522438836
      },
      {
          "time_stamp_utc_ms": 1734658230000,
          "time": "2024-12-20T01:33:00Z",
          "energy": 28.559999999590218,
          "capacity": 428.39999999385327
      },
      {
          "time_stamp_utc_ms": 1734658600000,
          "time": "2024-12-20T01:38:00Z",
          "energy": 43.03999999910593,
          "capacity": 418.76757835259514
      },
      {
          "time_stamp_utc_ms": 1734658800000,
          "time": "2024-12-20T01:43:00Z",
          "energy": 22.96000000089407,
          "capacity": 413.2800098694565
      },
      {
          "time_stamp_utc_ms": 1734659160000,
          "time": "2024-12-20T01:48:00Z",
          "energy": 42.47999999951571,
          "capacity": 424.7999999951571
      },
      {
          "time_stamp_utc_ms": 1734659430000,
          "time": "2024-12-20T01:53:00Z",
          "energy": 31.320000000298023,
          "capacity": 417.6000000039736
      },
      {
          "time_stamp_utc_ms": 1734659740000,
          "time": "2024-12-20T01:58:00Z",
          "energy": 36.360000000335276,
          "capacity": 422.24517428403476
      },
      {
          "time_stamp_utc_ms": 1734659940000,
          "time": "2024-12-20T02:03:00Z",
          "energy": 23.040000000037253,
          "capacity": 414.72000988836606
      },
      {
          "time_stamp_utc_ms": 1734660360000,
          "time": "2024-12-20T02:08:00Z",
          "energy": 48.639999999664724,
          "capacity": 416.9142857114119
      },
      {
          "time_stamp_utc_ms": 1734660680000,
          "time": "2024-12-20T02:13:00Z",
          "energy": 38.87999999988824,
          "capacity": 437.3999869632073
      },
      {
          "time_stamp_utc_ms": 1734660960000,
          "time": "2024-12-20T02:18:00Z",
          "energy": 31.519999999552965,
          "capacity": 405.2571566543718
      },
      {
          "time_stamp_utc_ms": 1734661140000,
          "time": "2024-12-20T02:23:00Z",
          "energy": 20.640000000596046,
          "capacity": 412.80000001192093
      },
      {
          "time_stamp_utc_ms": 1734661560000,
          "time": "2024-12-20T02:28:00Z",
          "energy": 49.67999999970198,
          "capacity": 425.82857142601694
      },
      {
          "time_stamp_utc_ms": 1734661830000,
          "time": "2024-12-20T02:33:00Z",
          "energy": 31.200000000186265,
          "capacity": 416.00000000248355
      },
      {
          "time_stamp_utc_ms": 1734662160000,
          "time": "2024-12-20T02:38:00Z",
          "energy": 40.799999999813735,
          "capacity": 445.0909090888771
      },
      {
          "time_stamp_utc_ms": 1734662820000,
          "time": "2024-12-20T02:48:00Z",
          "energy": 77.67999999970198,
          "capacity": 423.70909090746534
      },
      {
          "time_stamp_utc_ms": 1734663060000,
          "time": "2024-12-20T02:53:00Z",
          "energy": 27.200000001117587,
          "capacity": 408.0000000167638
      },
      {
          "time_stamp_utc_ms": 1734663330000,
          "time": "2024-12-20T02:58:00Z",
          "energy": 31.799999999813735,
          "capacity": 423.9999999975164
      },
      {
          "time_stamp_utc_ms": 1734663630000,
          "time": "2024-12-20T03:03:00Z",
          "energy": 35.39999999944121,
          "capacity": 424.7999999932945
      },
      {
          "time_stamp_utc_ms": 1734663980000,
          "time": "2024-12-20T03:08:00Z",
          "energy": 39.52000000048429,
          "capacity": 406.49141750039763
      },
      {
          "time_stamp_utc_ms": 1734664200000,
          "time": "2024-12-20T03:13:00Z",
          "energy": 26.239999999292195,
          "capacity": 429.38180886363585
      },
      {
          "time_stamp_utc_ms": 1734664560000,
          "time": "2024-12-20T03:18:00Z",
          "energy": 40.99200000055134,
          "capacity": 409.9200000055134
      },
      {
          "time_stamp_utc_ms": 1734664980000,
          "time": "2024-12-20T03:23:00Z",
          "energy": 49.247999999672174,
          "capacity": 422.1257142829043
      },
      {
          "time_stamp_utc_ms": 1734665130000,
          "time": "2024-12-20T03:28:00Z",
          "energy": 16.799999999813735,
          "capacity": 403.1999999955297
      },
      {
          "time_stamp_utc_ms": 1734665520000,
          "time": "2024-12-20T03:33:00Z",
          "energy": 44.16000000014901,
          "capacity": 407.63076923214476
      },
      {
          "time_stamp_utc_ms": 1734665780000,
          "time": "2024-12-20T03:38:00Z",
          "energy": 29.759999999776483,
          "capacity": 412.061523344117
      },
      {
          "time_stamp_utc_ms": 1734666080000,
          "time": "2024-12-20T03:43:00Z",
          "energy": 33.43999999947846,
          "capacity": 401.2799999937415
      },
      {
          "time_stamp_utc_ms": 1734666340000,
          "time": "2024-12-20T03:48:00Z",
          "energy": 28.96000000089407,
          "capacity": 400.9846006889673
      },
      {
          "time_stamp_utc_ms": 1734666680000,
          "time": "2024-12-20T03:53:00Z",
          "energy": 39.360000000335276,
          "capacity": 416.7529528696261
      },
      {
          "time_stamp_utc_ms": 1734666975000,
          "time": "2024-12-20T03:58:00Z",
          "energy": 32.519999999552965,
          "capacity": 396.8542501121618
      },
      {
          "time_stamp_utc_ms": 1734667240000,
          "time": "2024-12-20T04:03:00Z",
          "energy": 29.56000000052154,
          "capacity": 401.5698257794183
      },
      {
          "time_stamp_utc_ms": 1734667540000,
          "time": "2024-12-20T04:08:00Z",
          "energy": 32.3199999993667,
          "capacity": 387.83999999240046
      },
      {
          "time_stamp_utc_ms": 1734667830000,
          "time": "2024-12-20T04:13:00Z",
          "energy": 31.600000000558794,
          "capacity": 392.27584917578497
      },
      {
          "time_stamp_utc_ms": 1734668160000,
          "time": "2024-12-20T04:18:00Z",
          "energy": 37.19999999925494,
          "capacity": 405.81818181005394
      },
      {
          "time_stamp_utc_ms": 1734668460000,
          "time": "2024-12-20T04:23:00Z",
          "energy": 34.80000000074506,
          "capacity": 417.60000000894075
      },
      {
          "time_stamp_utc_ms": 1734668640000,
          "time": "2024-12-20T04:28:00Z",
          "energy": 22.3199999993667,
          "capacity": 446.399999987334
      },
      {
          "time_stamp_utc_ms": 1734669000000,
          "time": "2024-12-20T04:33:00Z",
          "energy": 39.360000000335276,
          "capacity": 393.60000000335276
      },
      {
          "time_stamp_utc_ms": 1734669240000,
          "time": "2024-12-20T04:38:00Z",
          "energy": 25.919999999925494,
          "capacity": 388.7999999988824
      },
      {
          "time_stamp_utc_ms": 1734669990000,
          "time": "2024-12-20T04:48:00Z",
          "energy": 81.59999999962747,
          "capacity": 391.6799999982119
      },
      {
          "time_stamp_utc_ms": 1734670290000,
          "time": "2024-12-20T04:53:00Z",
          "energy": 32.40000000037253,
          "capacity": 388.80000000447035
      },
      {
          "time_stamp_utc_ms": 1734670560000,
          "time": "2024-12-20T04:58:00Z",
          "energy": 28.800000000745058,
          "capacity": 384.0000000099341
      },
      {
          "time_stamp_utc_ms": 1734670860000,
          "time": "2024-12-20T05:03:00Z",
          "energy": 32.55999999959022,
          "capacity": 390.7199999950826
      },
      {
          "time_stamp_utc_ms": 1734671180000,
          "time": "2024-12-20T05:08:00Z",
          "energy": 35.519999999552965,
          "capacity": 399.5999880859632
      },
      {
          "time_stamp_utc_ms": 1734671460000,
          "time": "2024-12-20T05:13:00Z",
          "energy": 30.3199999993667,
          "capacity": 389.8285846979115
      },
      {
          "time_stamp_utc_ms": 1734671740000,
          "time": "2024-12-20T05:18:00Z",
          "energy": 31.600000000558794,
          "capacity": 406.28572813090824
      },
      {
          "time_stamp_utc_ms": 1734672000000,
          "time": "2024-12-20T05:23:00Z",
          "energy": 28.640000000596046,
          "capacity": 396.55383161659125
      },
      {
          "time_stamp_utc_ms": 1734672375000,
          "time": "2024-12-20T05:28:00Z",
          "energy": 41.75999999977648,
          "capacity": 400.89599999785423
      },
      {
          "time_stamp_utc_ms": 1734672600000,
          "time": "2024-12-20T05:33:00Z",
          "energy": 26.399999999441206,
          "capacity": 422.3999999910593
      },
      {
          "time_stamp_utc_ms": 1734672940000,
          "time": "2024-12-20T05:38:00Z",
          "energy": 36.00000000093132,
          "capacity": 381.1764812898087
      },
      {
          "time_stamp_utc_ms": 1734673380000,
          "time": "2024-12-20T05:43:00Z",
          "energy": 48.47999999951571,
          "capacity": 396.65453685332756
      },
      {
          "time_stamp_utc_ms": 1734673560000,
          "time": "2024-12-20T05:48:00Z",
          "energy": 18.959999999962747,
          "capacity": 379.1999999992549
      },
      {
          "time_stamp_utc_ms": 1734673840000,
          "time": "2024-12-20T05:53:00Z",
          "energy": 30.639999999664724,
          "capacity": 393.9428705561607
      },
      {
          "time_stamp_utc_ms": 1734674160000,
          "time": "2024-12-20T05:58:00Z",
          "energy": 34.87999999988824,
          "capacity": 392.39998830431176
      },
      {
          "time_stamp_utc_ms": 1734674460000,
          "time": "2024-12-20T06:03:00Z",
          "energy": 32.44800000078976,
          "capacity": 389.3760000094772
      },
      {
          "time_stamp_utc_ms": 1734674760000,
          "time": "2024-12-20T06:08:00Z",
          "energy": 32.83199999947101,
          "capacity": 393.9839999936521
      },
      {
          "time_stamp_utc_ms": 1734675060000,
          "time": "2024-12-20T06:13:00Z",
          "energy": 32.06400000117719,
          "capacity": 384.76800001412636
      },
      {
          "time_stamp_utc_ms": 1734675360000,
          "time": "2024-12-20T06:18:00Z",
          "energy": 32.831999998539686,
          "capacity": 393.98399998247623
      },
      {
          "time_stamp_utc_ms": 1734675660000,
          "time": "2024-12-20T06:23:00Z",
          "energy": 32.544000000692904,
          "capacity": 390.5280000083149
      },
      {
          "time_stamp_utc_ms": 1734675960000,
          "time": "2024-12-20T06:28:00Z",
          "energy": 32.83199999947101,
          "capacity": 393.9839999936521
      },
      {
          "time_stamp_utc_ms": 1734676260000,
          "time": "2024-12-20T06:33:00Z",
          "energy": 33.50400000065565,
          "capacity": 402.04800000786787
      },
      {
          "time_stamp_utc_ms": 1734676560000,
          "time": "2024-12-20T06:38:00Z",
          "energy": 33.119999999180436,
          "capacity": 397.43999999016523
      },
      {
          "time_stamp_utc_ms": 1734676860000,
          "time": "2024-12-20T06:43:00Z",
          "energy": 34.08000000100583,
          "capacity": 408.96000001206994
      },
      {
          "time_stamp_utc_ms": 1734677160000,
          "time": "2024-12-20T06:48:00Z",
          "energy": 32.06399999931455,
          "capacity": 384.76799999177456
      },
      {
          "time_stamp_utc_ms": 1734677460000,
          "time": "2024-12-20T06:53:00Z",
          "energy": 32.35200000088662,
          "capacity": 388.2240000106395
      },
      {
          "time_stamp_utc_ms": 1734677760000,
          "time": "2024-12-20T06:58:00Z",
          "energy": 32.639999999664724,
          "capacity": 391.6799999959767
      },
      {
          "time_stamp_utc_ms": 1734678060000,
          "time": "2024-12-20T07:03:00Z",
          "energy": 32.3519999999553,
          "capacity": 388.22399999946356
      },
      {
          "time_stamp_utc_ms": 1734678360000,
          "time": "2024-12-20T07:08:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1734678660000,
          "time": "2024-12-20T07:13:00Z",
          "energy": 31.583999999798834,
          "capacity": 379.007999997586
      },
      {
          "time_stamp_utc_ms": 1734678960000,
          "time": "2024-12-20T07:18:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1734679260000,
          "time": "2024-12-20T07:23:00Z",
          "energy": 32.928000000305474,
          "capacity": 395.13600000366574
      },
      {
          "time_stamp_utc_ms": 1734679560000,
          "time": "2024-12-20T07:28:00Z",
          "energy": 32.06399999931455,
          "capacity": 384.76799999177456
      },
      {
          "time_stamp_utc_ms": 1734679860000,
          "time": "2024-12-20T07:33:00Z",
          "energy": 33.50400000065565,
          "capacity": 402.04800000786787
      },
      {
          "time_stamp_utc_ms": 1734680160000,
          "time": "2024-12-20T07:38:00Z",
          "energy": 32.83200000040233,
          "capacity": 393.98400000482803
      },
      {
          "time_stamp_utc_ms": 1734680460000,
          "time": "2024-12-20T07:43:00Z",
          "energy": 33.98399999830872,
          "capacity": 407.8079999797046
      },
      {
          "time_stamp_utc_ms": 1734680760000,
          "time": "2024-12-20T07:48:00Z",
          "energy": 32.64000000059605,
          "capacity": 391.6800000071526
      },
      {
          "time_stamp_utc_ms": 1734681060000,
          "time": "2024-12-20T07:53:00Z",
          "energy": 32.64000000059605,
          "capacity": 391.6800000071526
      },
      {
          "time_stamp_utc_ms": 1734681360000,
          "time": "2024-12-20T07:58:00Z",
          "energy": 32.64000000059605,
          "capacity": 391.6800000071526
      },
      {
          "time_stamp_utc_ms": 1734681660000,
          "time": "2024-12-20T08:03:00Z",
          "energy": 32.25599999912083,
          "capacity": 387.07199998945
      },
      {
          "time_stamp_utc_ms": 1734681960000,
          "time": "2024-12-20T08:08:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1734682260000,
          "time": "2024-12-20T08:13:00Z",
          "energy": 32.83199999947101,
          "capacity": 393.9839999936521
      },
      {
          "time_stamp_utc_ms": 1734682560000,
          "time": "2024-12-20T08:18:00Z",
          "energy": 31.87199999950826,
          "capacity": 382.46399999409914
      },
      {
          "time_stamp_utc_ms": 1734682860000,
          "time": "2024-12-20T08:23:00Z",
          "energy": 34.08000000100583,
          "capacity": 408.96000001206994
      },
      {
          "time_stamp_utc_ms": 1734683160000,
          "time": "2024-12-20T08:28:00Z",
          "energy": 31.583999999798834,
          "capacity": 379.007999997586
      },
      {
          "time_stamp_utc_ms": 1734683460000,
          "time": "2024-12-20T08:33:00Z",
          "energy": 33.50400000065565,
          "capacity": 402.04800000786787
      },
      {
          "time_stamp_utc_ms": 1734683760000,
          "time": "2024-12-20T08:38:00Z",
          "energy": 32.735999999567866,
          "capacity": 392.8319999948144
      },
      {
          "time_stamp_utc_ms": 1734684060000,
          "time": "2024-12-20T08:43:00Z",
          "energy": 33.2160000000149,
          "capacity": 398.59200000017887
      },
      {
          "time_stamp_utc_ms": 1734684360000,
          "time": "2024-12-20T08:48:00Z",
          "energy": 33.59999999962747,
          "capacity": 403.1999999955297
      },
      {
          "time_stamp_utc_ms": 1734684660000,
          "time": "2024-12-20T08:53:00Z",
          "energy": 31.87199999950826,
          "capacity": 382.46399999409914
      },
      {
          "time_stamp_utc_ms": 1734684960000,
          "time": "2024-12-20T08:58:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1734685260000,
          "time": "2024-12-20T09:03:00Z",
          "energy": 32.35200000088662,
          "capacity": 388.2240000106395
      },
      {
          "time_stamp_utc_ms": 1734685560000,
          "time": "2024-12-20T09:08:00Z",
          "energy": 33.024000000208616,
          "capacity": 396.28800000250345
      },
      {
          "time_stamp_utc_ms": 1734685860000,
          "time": "2024-12-20T09:13:00Z",
          "energy": 33.119999999180436,
          "capacity": 397.43999999016523
      },
      {
          "time_stamp_utc_ms": 1734686160000,
          "time": "2024-12-20T09:18:00Z",
          "energy": 33.407999999821186,
          "capacity": 400.8959999978543
      },
      {
          "time_stamp_utc_ms": 1734686460000,
          "time": "2024-12-20T09:23:00Z",
          "energy": 33.69599999953061,
          "capacity": 404.3519999943674
      },
      {
          "time_stamp_utc_ms": 1734686760000,
          "time": "2024-12-20T09:28:00Z",
          "energy": 35.42400000151247,
          "capacity": 425.0880000181496
      },
      {
          "time_stamp_utc_ms": 1734687060000,
          "time": "2024-12-20T09:33:00Z",
          "energy": 36.38399999961257,
          "capacity": 436.60799999535084
      },
      {
          "time_stamp_utc_ms": 1734687360000,
          "time": "2024-12-20T09:38:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1734687660000,
          "time": "2024-12-20T09:43:00Z",
          "energy": 35.99999999906868,
          "capacity": 431.99999998882413
      },
      {
          "time_stamp_utc_ms": 1734687960000,
          "time": "2024-12-20T09:48:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1734688260000,
          "time": "2024-12-20T09:53:00Z",
          "energy": 37.72799999918789,
          "capacity": 452.73599999025464
      },
      {
          "time_stamp_utc_ms": 1734688560000,
          "time": "2024-12-20T09:58:00Z",
          "energy": 37.72800000105053,
          "capacity": 452.73600001260644
      },
      {
          "time_stamp_utc_ms": 1734688860000,
          "time": "2024-12-20T10:03:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1734689160000,
          "time": "2024-12-20T10:08:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1734689460000,
          "time": "2024-12-20T10:13:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1734689760000,
          "time": "2024-12-20T10:18:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1734690060000,
          "time": "2024-12-20T10:23:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1734690360000,
          "time": "2024-12-20T10:28:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1734690660000,
          "time": "2024-12-20T10:33:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1734690960000,
          "time": "2024-12-20T10:38:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1734691260000,
          "time": "2024-12-20T10:43:00Z",
          "energy": 37.631999999284744,
          "capacity": 451.58399999141693
      },
      {
          "time_stamp_utc_ms": 1734691560000,
          "time": "2024-12-20T10:48:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1734691860000,
          "time": "2024-12-20T10:53:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1734692160000,
          "time": "2024-12-20T10:58:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1734692460000,
          "time": "2024-12-20T11:03:00Z",
          "energy": 38.68800000101328,
          "capacity": 464.25600001215935
      },
      {
          "time_stamp_utc_ms": 1734692760000,
          "time": "2024-12-20T11:08:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1734693060000,
          "time": "2024-12-20T11:13:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1734693360000,
          "time": "2024-12-20T11:18:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1734693660000,
          "time": "2024-12-20T11:23:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1734693960000,
          "time": "2024-12-20T11:28:00Z",
          "energy": 40.9919999986887,
          "capacity": 491.9039999842644
      },
      {
          "time_stamp_utc_ms": 1734694260000,
          "time": "2024-12-20T11:33:00Z",
          "energy": 40.03200000151992,
          "capacity": 480.384000018239
      },
      {
          "time_stamp_utc_ms": 1734694560000,
          "time": "2024-12-20T11:38:00Z",
          "energy": 40.60799999907613,
          "capacity": 487.29599998891354
      },
      {
          "time_stamp_utc_ms": 1734694860000,
          "time": "2024-12-20T11:43:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1734695160000,
          "time": "2024-12-20T11:48:00Z",
          "energy": 38.880000000819564,
          "capacity": 466.56000000983477
      },
      {
          "time_stamp_utc_ms": 1734695460000,
          "time": "2024-12-20T11:53:00Z",
          "energy": 39.26399999856949,
          "capacity": 471.1679999828339
      },
      {
          "time_stamp_utc_ms": 1734695760000,
          "time": "2024-12-20T11:58:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1734696060000,
          "time": "2024-12-20T12:03:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1734696360000,
          "time": "2024-12-20T12:08:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1734696660000,
          "time": "2024-12-20T12:13:00Z",
          "energy": 40.895999998785555,
          "capacity": 490.75199998542666
      },
      {
          "time_stamp_utc_ms": 1734696960000,
          "time": "2024-12-20T12:18:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1734697260000,
          "time": "2024-12-20T12:23:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1734697560000,
          "time": "2024-12-20T12:28:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1734697860000,
          "time": "2024-12-20T12:33:00Z",
          "energy": 38.78400000091642,
          "capacity": 465.40800001099706
      },
      {
          "time_stamp_utc_ms": 1734698160000,
          "time": "2024-12-20T12:38:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1734698460000,
          "time": "2024-12-20T12:43:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1734698760000,
          "time": "2024-12-20T12:48:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1734699060000,
          "time": "2024-12-20T12:53:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1734699360000,
          "time": "2024-12-20T12:58:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1734699660000,
          "time": "2024-12-20T13:03:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1734699960000,
          "time": "2024-12-20T13:08:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1734700260000,
          "time": "2024-12-20T13:13:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1734700560000,
          "time": "2024-12-20T13:18:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1734700860000,
          "time": "2024-12-20T13:23:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1734701160000,
          "time": "2024-12-20T13:28:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1734701460000,
          "time": "2024-12-20T13:33:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1734701760000,
          "time": "2024-12-20T13:38:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1734702060000,
          "time": "2024-12-20T13:43:00Z",
          "energy": 33.024000000208616,
          "capacity": 396.28800000250345
      },
      {
          "time_stamp_utc_ms": 1734702360000,
          "time": "2024-12-20T13:48:00Z",
          "energy": 33.407999999821186,
          "capacity": 400.8959999978543
      },
      {
          "time_stamp_utc_ms": 1734702660000,
          "time": "2024-12-20T13:53:00Z",
          "energy": 41.28000000119209,
          "capacity": 495.3600000143052
      },
      {
          "time_stamp_utc_ms": 1734702960000,
          "time": "2024-12-20T13:58:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1734703260000,
          "time": "2024-12-20T14:03:00Z",
          "energy": 44.25599999912083,
          "capacity": 531.07199998945
      },
      {
          "time_stamp_utc_ms": 1734703560000,
          "time": "2024-12-20T14:08:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1734703860000,
          "time": "2024-12-20T14:13:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1734704160000,
          "time": "2024-12-20T14:18:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1734704460000,
          "time": "2024-12-20T14:23:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1734704760000,
          "time": "2024-12-20T14:28:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1734705060000,
          "time": "2024-12-20T14:33:00Z",
          "energy": 37.53600000124425,
          "capacity": 450.432000014931
      },
      {
          "time_stamp_utc_ms": 1734705360000,
          "time": "2024-12-20T14:38:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1734705660000,
          "time": "2024-12-20T14:43:00Z",
          "energy": 36.47999999858439,
          "capacity": 437.7599999830127
      },
      {
          "time_stamp_utc_ms": 1734705960000,
          "time": "2024-12-20T14:48:00Z",
          "energy": 37.92000000085682,
          "capacity": 455.04000001028186
      },
      {
          "time_stamp_utc_ms": 1734706260000,
          "time": "2024-12-20T14:53:00Z",
          "energy": 37.631999999284744,
          "capacity": 451.58399999141693
      },
      {
          "time_stamp_utc_ms": 1734706560000,
          "time": "2024-12-20T14:58:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1734706860000,
          "time": "2024-12-20T15:03:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1734707160000,
          "time": "2024-12-20T15:08:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734707460000,
          "time": "2024-12-20T15:13:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1734707760000,
          "time": "2024-12-20T15:18:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1734708060000,
          "time": "2024-12-20T15:23:00Z",
          "energy": 36.096000000834465,
          "capacity": 433.1520000100136
      },
      {
          "time_stamp_utc_ms": 1734708360000,
          "time": "2024-12-20T15:28:00Z",
          "energy": 35.71199999842793,
          "capacity": 428.5439999811352
      },
      {
          "time_stamp_utc_ms": 1734708660000,
          "time": "2024-12-20T15:33:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1734708960000,
          "time": "2024-12-20T15:38:00Z",
          "energy": 37.53600000124425,
          "capacity": 450.432000014931
      },
      {
          "time_stamp_utc_ms": 1734709260000,
          "time": "2024-12-20T15:43:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1734709560000,
          "time": "2024-12-20T15:48:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1734709860000,
          "time": "2024-12-20T15:53:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1734710160000,
          "time": "2024-12-20T15:58:00Z",
          "energy": 37.91999999899417,
          "capacity": 455.03999998793006
      },
      {
          "time_stamp_utc_ms": 1734710460000,
          "time": "2024-12-20T16:03:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1734710760000,
          "time": "2024-12-20T16:08:00Z",
          "energy": 34.847999999299645,
          "capacity": 418.1759999915958
      },
      {
          "time_stamp_utc_ms": 1734711060000,
          "time": "2024-12-20T16:13:00Z",
          "energy": 34.84800000116229,
          "capacity": 418.1760000139475
      },
      {
          "time_stamp_utc_ms": 1734711360000,
          "time": "2024-12-20T16:18:00Z",
          "energy": 32.6399999987334,
          "capacity": 391.6799999848008
      },
      {
          "time_stamp_utc_ms": 1734711660000,
          "time": "2024-12-20T16:23:00Z",
          "energy": 32.64000000059605,
          "capacity": 391.6800000071526
      },
      {
          "time_stamp_utc_ms": 1734711960000,
          "time": "2024-12-20T16:28:00Z",
          "energy": 36.96000000089407,
          "capacity": 443.5200000107289
      },
      {
          "time_stamp_utc_ms": 1734712260000,
          "time": "2024-12-20T16:33:00Z",
          "energy": 36.38399999868125,
          "capacity": 436.60799998417497
      },
      {
          "time_stamp_utc_ms": 1734712560000,
          "time": "2024-12-20T16:38:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1734712860000,
          "time": "2024-12-20T16:43:00Z",
          "energy": 32.83199999947101,
          "capacity": 393.9839999936521
      },
      {
          "time_stamp_utc_ms": 1734713160000,
          "time": "2024-12-20T16:48:00Z",
          "energy": 32.3519999999553,
          "capacity": 388.22399999946356
      },
      {
          "time_stamp_utc_ms": 1734713460000,
          "time": "2024-12-20T16:53:00Z",
          "energy": 33.407999999821186,
          "capacity": 400.8959999978543
      },
      {
          "time_stamp_utc_ms": 1734713760000,
          "time": "2024-12-20T16:58:00Z",
          "energy": 31.96800000127405,
          "capacity": 383.61600001528865
      },
      {
          "time_stamp_utc_ms": 1734714060000,
          "time": "2024-12-20T17:03:00Z",
          "energy": 33.407999999821186,
          "capacity": 400.8959999978543
      },
      {
          "time_stamp_utc_ms": 1734714360000,
          "time": "2024-12-20T17:08:00Z",
          "energy": 32.3519999999553,
          "capacity": 388.22399999946356
      },
      {
          "time_stamp_utc_ms": 1734714660000,
          "time": "2024-12-20T17:13:00Z",
          "energy": 30.815999999642372,
          "capacity": 369.79199999570847
      },
      {
          "time_stamp_utc_ms": 1734714960000,
          "time": "2024-12-20T17:18:00Z",
          "energy": 32.639999999664724,
          "capacity": 391.6799999959767
      },
      {
          "time_stamp_utc_ms": 1734715260000,
          "time": "2024-12-20T17:23:00Z",
          "energy": 30.432000000029802,
          "capacity": 365.1840000003576
      },
      {
          "time_stamp_utc_ms": 1734715560000,
          "time": "2024-12-20T17:28:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1734715860000,
          "time": "2024-12-20T17:33:00Z",
          "energy": 34.17600000090897,
          "capacity": 410.11200001090765
      },
      {
          "time_stamp_utc_ms": 1734716160000,
          "time": "2024-12-20T17:38:00Z",
          "energy": 30.815999999642372,
          "capacity": 369.79199999570847
      },
      {
          "time_stamp_utc_ms": 1734716460000,
          "time": "2024-12-20T17:43:00Z",
          "energy": 32.831999998539686,
          "capacity": 393.98399998247623
      },
      {
          "time_stamp_utc_ms": 1734716760000,
          "time": "2024-12-20T17:48:00Z",
          "energy": 32.73600000143051,
          "capacity": 392.8320000171662
      },
      {
          "time_stamp_utc_ms": 1734717060000,
          "time": "2024-12-20T17:53:00Z",
          "energy": 31.679999999701977,
          "capacity": 380.1599999964237
      },
      {
          "time_stamp_utc_ms": 1734717360000,
          "time": "2024-12-20T17:58:00Z",
          "energy": 33.791999999433756,
          "capacity": 405.5039999932051
      },
      {
          "time_stamp_utc_ms": 1734717660000,
          "time": "2024-12-20T18:03:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734717960000,
          "time": "2024-12-20T18:08:00Z",
          "energy": 35.328000000678,
          "capacity": 423.93600000813603
      },
      {
          "time_stamp_utc_ms": 1734718260000,
          "time": "2024-12-20T18:13:00Z",
          "energy": 35.80799999926239,
          "capacity": 429.6959999911487
      },
      {
          "time_stamp_utc_ms": 1734718560000,
          "time": "2024-12-20T18:18:00Z",
          "energy": 33.79200000036508,
          "capacity": 405.504000004381
      },
      {
          "time_stamp_utc_ms": 1734718860000,
          "time": "2024-12-20T18:23:00Z",
          "energy": 36.86399999912828,
          "capacity": 442.3679999895394
      },
      {
          "time_stamp_utc_ms": 1734719160000,
          "time": "2024-12-20T18:28:00Z",
          "energy": 36.096000000834465,
          "capacity": 433.1520000100136
      },
      {
          "time_stamp_utc_ms": 1734719460000,
          "time": "2024-12-20T18:33:00Z",
          "energy": 32.639999999664724,
          "capacity": 391.6799999959767
      },
      {
          "time_stamp_utc_ms": 1734719760000,
          "time": "2024-12-20T18:38:00Z",
          "energy": 31.776000000536442,
          "capacity": 381.3120000064373
      },
      {
          "time_stamp_utc_ms": 1734720060000,
          "time": "2024-12-20T18:43:00Z",
          "energy": 35.04000000003725,
          "capacity": 420.48000000044703
      },
      {
          "time_stamp_utc_ms": 1734720360000,
          "time": "2024-12-20T18:48:00Z",
          "energy": 32.735999999567866,
          "capacity": 392.8319999948144
      },
      {
          "time_stamp_utc_ms": 1734720660000,
          "time": "2024-12-20T18:53:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1734720960000,
          "time": "2024-12-20T18:58:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1734721260000,
          "time": "2024-12-20T19:03:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1734721560000,
          "time": "2024-12-20T19:08:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1734721860000,
          "time": "2024-12-20T19:13:00Z",
          "energy": 35.040000000968575,
          "capacity": 420.4800000116229
      },
      {
          "time_stamp_utc_ms": 1734722160000,
          "time": "2024-12-20T19:18:00Z",
          "energy": 31.967999999411404,
          "capacity": 383.61599999293685
      },
      {
          "time_stamp_utc_ms": 1734722460000,
          "time": "2024-12-20T19:23:00Z",
          "energy": 31.392000000923872,
          "capacity": 376.70400001108646
      },
      {
          "time_stamp_utc_ms": 1734722760000,
          "time": "2024-12-20T19:28:00Z",
          "energy": 31.679999999701977,
          "capacity": 380.1599999964237
      },
      {
          "time_stamp_utc_ms": 1734723060000,
          "time": "2024-12-20T19:33:00Z",
          "energy": 32.44800000078976,
          "capacity": 389.3760000094772
      },
      {
          "time_stamp_utc_ms": 1734723360000,
          "time": "2024-12-20T19:38:00Z",
          "energy": 32.15999999828637,
          "capacity": 385.9199999794364
      },
      {
          "time_stamp_utc_ms": 1734723660000,
          "time": "2024-12-20T19:43:00Z",
          "energy": 33.312000000849366,
          "capacity": 399.74400001019245
      },
      {
          "time_stamp_utc_ms": 1734723960000,
          "time": "2024-12-20T19:48:00Z",
          "energy": 33.60000000055879,
          "capacity": 403.2000000067056
      },
      {
          "time_stamp_utc_ms": 1734724260000,
          "time": "2024-12-20T19:53:00Z",
          "energy": 33.31199999898672,
          "capacity": 399.74399998784065
      },
      {
          "time_stamp_utc_ms": 1734724560000,
          "time": "2024-12-20T19:58:00Z",
          "energy": 34.17600000090897,
          "capacity": 410.11200001090765
      },
      {
          "time_stamp_utc_ms": 1734724860000,
          "time": "2024-12-20T20:03:00Z",
          "energy": 33.50399999972433,
          "capacity": 402.047999996692
      },
      {
          "time_stamp_utc_ms": 1734725160000,
          "time": "2024-12-20T20:08:00Z",
          "energy": 34.847999999299645,
          "capacity": 418.1759999915958
      },
      {
          "time_stamp_utc_ms": 1734725460000,
          "time": "2024-12-20T20:13:00Z",
          "energy": 34.56000000052154,
          "capacity": 414.7200000062585
      },
      {
          "time_stamp_utc_ms": 1734725760000,
          "time": "2024-12-20T20:18:00Z",
          "energy": 34.368000000715256,
          "capacity": 412.41600000858307
      },
      {
          "time_stamp_utc_ms": 1734726060000,
          "time": "2024-12-20T20:23:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1734726360000,
          "time": "2024-12-20T20:28:00Z",
          "energy": 34.847999999299645,
          "capacity": 418.1759999915958
      },
      {
          "time_stamp_utc_ms": 1734726660000,
          "time": "2024-12-20T20:33:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1734726960000,
          "time": "2024-12-20T20:38:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1734727260000,
          "time": "2024-12-20T20:43:00Z",
          "energy": 37.92000000085682,
          "capacity": 455.04000001028186
      },
      {
          "time_stamp_utc_ms": 1734727560000,
          "time": "2024-12-20T20:48:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1734727860000,
          "time": "2024-12-20T20:53:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1734728160000,
          "time": "2024-12-20T20:58:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1734728460000,
          "time": "2024-12-20T21:03:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1734728760000,
          "time": "2024-12-20T21:08:00Z",
          "energy": 40.41600000113249,
          "capacity": 484.99200001358986
      },
      {
          "time_stamp_utc_ms": 1734729060000,
          "time": "2024-12-20T21:13:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1734729360000,
          "time": "2024-12-20T21:18:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1734729660000,
          "time": "2024-12-20T21:23:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1734729960000,
          "time": "2024-12-20T21:28:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1734730260000,
          "time": "2024-12-20T21:33:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1734730560000,
          "time": "2024-12-20T21:38:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1734730860000,
          "time": "2024-12-20T21:43:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1734731160000,
          "time": "2024-12-20T21:48:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1734731460000,
          "time": "2024-12-20T21:53:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1734731760000,
          "time": "2024-12-20T21:58:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1734732060000,
          "time": "2024-12-20T22:03:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1734732360000,
          "time": "2024-12-20T22:08:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1734732660000,
          "time": "2024-12-20T22:13:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1734732960000,
          "time": "2024-12-20T22:18:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1734733260000,
          "time": "2024-12-20T22:23:00Z",
          "energy": 40.70399999897927,
          "capacity": 488.44799998775125
      },
      {
          "time_stamp_utc_ms": 1734733560000,
          "time": "2024-12-20T22:28:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1734733860000,
          "time": "2024-12-20T22:33:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1734734160000,
          "time": "2024-12-20T22:38:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1734734460000,
          "time": "2024-12-20T22:43:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1734734760000,
          "time": "2024-12-20T22:48:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1734735060000,
          "time": "2024-12-20T22:53:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1734735360000,
          "time": "2024-12-20T22:58:00Z",
          "energy": 41.472000000067055,
          "capacity": 497.66400000080466
      },
      {
          "time_stamp_utc_ms": 1734735660000,
          "time": "2024-12-20T23:03:00Z",
          "energy": 41.567999999038875,
          "capacity": 498.81599998846656
      },
      {
          "time_stamp_utc_ms": 1734735960000,
          "time": "2024-12-20T23:08:00Z",
          "energy": 40.51200000103563,
          "capacity": 486.14400001242757
      },
      {
          "time_stamp_utc_ms": 1734736260000,
          "time": "2024-12-20T23:13:00Z",
          "energy": 40.99199999962002,
          "capacity": 491.9039999954403
      },
      {
          "time_stamp_utc_ms": 1734736560000,
          "time": "2024-12-20T23:18:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1734736860000,
          "time": "2024-12-20T23:23:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1734737160000,
          "time": "2024-12-20T23:28:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1734737460000,
          "time": "2024-12-20T23:33:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1734737760000,
          "time": "2024-12-20T23:38:00Z",
          "energy": 41.95200000051409,
          "capacity": 503.42400000616914
      },
      {
          "time_stamp_utc_ms": 1734738060000,
          "time": "2024-12-20T23:43:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1734738360000,
          "time": "2024-12-20T23:48:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1734738660000,
          "time": "2024-12-20T23:53:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1734738960000,
          "time": "2024-12-20T23:58:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1734739260000,
          "time": "2024-12-21T00:03:00Z",
          "energy": 41.95200000051409,
          "capacity": 503.42400000616914
      },
      {
          "time_stamp_utc_ms": 1734739560000,
          "time": "2024-12-21T00:08:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1734739860000,
          "time": "2024-12-21T00:13:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1734740160000,
          "time": "2024-12-21T00:18:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1734740460000,
          "time": "2024-12-21T00:23:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1734740760000,
          "time": "2024-12-21T00:28:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1734741060000,
          "time": "2024-12-21T00:33:00Z",
          "energy": 40.99200000055134,
          "capacity": 491.9040000066161
      },
      {
          "time_stamp_utc_ms": 1734741360000,
          "time": "2024-12-21T00:38:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1734741660000,
          "time": "2024-12-21T00:43:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1734741960000,
          "time": "2024-12-21T00:48:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1734742260000,
          "time": "2024-12-21T00:53:00Z",
          "energy": 42.71999999880791,
          "capacity": 512.6399999856949
      },
      {
          "time_stamp_utc_ms": 1734742560000,
          "time": "2024-12-21T00:58:00Z",
          "energy": 38.784000001847744,
          "capacity": 465.4080000221729
      },
      {
          "time_stamp_utc_ms": 1734742860000,
          "time": "2024-12-21T01:03:00Z",
          "energy": 38.49599999934435,
          "capacity": 461.95199999213224
      },
      {
          "time_stamp_utc_ms": 1734743160000,
          "time": "2024-12-21T01:08:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1734743460000,
          "time": "2024-12-21T01:13:00Z",
          "energy": 38.30399999860674,
          "capacity": 459.6479999832809
      },
      {
          "time_stamp_utc_ms": 1734743760000,
          "time": "2024-12-21T01:18:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1734744060000,
          "time": "2024-12-21T01:23:00Z",
          "energy": 35.71199999935925,
          "capacity": 428.543999992311
      },
      {
          "time_stamp_utc_ms": 1734744360000,
          "time": "2024-12-21T01:28:00Z",
          "energy": 37.92000000085682,
          "capacity": 455.04000001028186
      },
      {
          "time_stamp_utc_ms": 1734744660000,
          "time": "2024-12-21T01:33:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1734744960000,
          "time": "2024-12-21T01:38:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1734745260000,
          "time": "2024-12-21T01:43:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1734745560000,
          "time": "2024-12-21T01:48:00Z",
          "energy": 36.09599999897182,
          "capacity": 433.15199998766184
      },
      {
          "time_stamp_utc_ms": 1734745860000,
          "time": "2024-12-21T01:53:00Z",
          "energy": 38.40000000130385,
          "capacity": 460.8000000156462
      },
      {
          "time_stamp_utc_ms": 1734746160000,
          "time": "2024-12-21T01:58:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1734746460000,
          "time": "2024-12-21T02:03:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1734746760000,
          "time": "2024-12-21T02:08:00Z",
          "energy": 37.72800000105053,
          "capacity": 452.73600001260644
      },
      {
          "time_stamp_utc_ms": 1734747060000,
          "time": "2024-12-21T02:13:00Z",
          "energy": 36.57599999848753,
          "capacity": 438.9119999818504
      },
      {
          "time_stamp_utc_ms": 1734747360000,
          "time": "2024-12-21T02:18:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1734747660000,
          "time": "2024-12-21T02:23:00Z",
          "energy": 37.2480000006035,
          "capacity": 446.976000007242
      },
      {
          "time_stamp_utc_ms": 1734747960000,
          "time": "2024-12-21T02:28:00Z",
          "energy": 38.2079999987036,
          "capacity": 458.4959999844432
      },
      {
          "time_stamp_utc_ms": 1734748260000,
          "time": "2024-12-21T02:33:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1734748560000,
          "time": "2024-12-21T02:38:00Z",
          "energy": 36.96000000089407,
          "capacity": 443.5200000107289
      },
      {
          "time_stamp_utc_ms": 1734748860000,
          "time": "2024-12-21T02:43:00Z",
          "energy": 38.30399999860674,
          "capacity": 459.6479999832809
      },
      {
          "time_stamp_utc_ms": 1734749160000,
          "time": "2024-12-21T02:48:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1734749460000,
          "time": "2024-12-21T02:53:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1734749760000,
          "time": "2024-12-21T02:58:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1734750060000,
          "time": "2024-12-21T03:03:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734750360000,
          "time": "2024-12-21T03:08:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1734750660000,
          "time": "2024-12-21T03:13:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1734750960000,
          "time": "2024-12-21T03:18:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1734751260000,
          "time": "2024-12-21T03:23:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1734751560000,
          "time": "2024-12-21T03:28:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1734751860000,
          "time": "2024-12-21T03:33:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1734752160000,
          "time": "2024-12-21T03:38:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1734752490000,
          "time": "2024-12-21T03:43:00Z",
          "energy": 41.808000000193715,
          "capacity": 456.087272729386
      },
      {
          "time_stamp_utc_ms": 1734752775000,
          "time": "2024-12-21T03:48:00Z",
          "energy": 35.03999999910593,
          "capacity": 442.610526304496
      },
      {
          "time_stamp_utc_ms": 1734753120000,
          "time": "2024-12-21T03:53:00Z",
          "energy": 42.0,
          "capacity": 438.2608695652174
      },
      {
          "time_stamp_utc_ms": 1734753300000,
          "time": "2024-12-21T03:58:00Z",
          "energy": 22.56000000052154,
          "capacity": 451.20000001043076
      },
      {
          "time_stamp_utc_ms": 1734753600000,
          "time": "2024-12-21T04:03:00Z",
          "energy": 37.75999999977648,
          "capacity": 453.1199999973178
      },
      {
          "time_stamp_utc_ms": 1734753840000,
          "time": "2024-12-21T04:08:00Z",
          "energy": 28.480000000447035,
          "capacity": 427.2000000067055
      },
      {
          "time_stamp_utc_ms": 1734754230000,
          "time": "2024-12-21T04:13:00Z",
          "energy": 47.27999999932945,
          "capacity": 436.43076922457954
      },
      {
          "time_stamp_utc_ms": 1734754560000,
          "time": "2024-12-21T04:18:00Z",
          "energy": 38.25600000098348,
          "capacity": 417.3381818289107
      },
      {
          "time_stamp_utc_ms": 1734754900000,
          "time": "2024-12-21T04:23:00Z",
          "energy": 41.50399999972433,
          "capacity": 439.4541299704963
      },
      {
          "time_stamp_utc_ms": 1734755130000,
          "time": "2024-12-21T04:28:00Z",
          "energy": 26.3199999993667,
          "capacity": 411.96522592227564
      },
      {
          "time_stamp_utc_ms": 1734755460000,
          "time": "2024-12-21T04:33:00Z",
          "energy": 39.360000000335276,
          "capacity": 429.3818181854757
      },
      {
          "time_stamp_utc_ms": 1734755790000,
          "time": "2024-12-21T04:38:00Z",
          "energy": 38.639999999664724,
          "capacity": 421.52727272361517
      },
      {
          "time_stamp_utc_ms": 1734756080000,
          "time": "2024-12-21T04:43:00Z",
          "energy": 35.360000000335276,
          "capacity": 438.9517097070251
      },
      {
          "time_stamp_utc_ms": 1734756330000,
          "time": "2024-12-21T04:48:00Z",
          "energy": 31.12000000011176,
          "capacity": 448.1280170963366
      },
      {
          "time_stamp_utc_ms": 1734756645000,
          "time": "2024-12-21T04:53:00Z",
          "energy": 36.47999999951571,
          "capacity": 416.914285708751
      },
      {
          "time_stamp_utc_ms": 1734757020000,
          "time": "2024-12-21T04:58:00Z",
          "energy": 46.32000000029802,
          "capacity": 444.672000002861
      },
      {
          "time_stamp_utc_ms": 1734757230000,
          "time": "2024-12-21T05:03:00Z",
          "energy": 25.6800000006333,
          "capacity": 440.228571439428
      },
      {
          "time_stamp_utc_ms": 1734757545000,
          "time": "2024-12-21T05:08:00Z",
          "energy": 39.0,
          "capacity": 445.71428571428567
      },
      {
          "time_stamp_utc_ms": 1734757950000,
          "time": "2024-12-21T05:13:00Z",
          "energy": 49.32000000029802,
          "capacity": 438.4000000026491
      },
      {
          "time_stamp_utc_ms": 1734758145000,
          "time": "2024-12-21T05:18:00Z",
          "energy": 23.519999998621643,
          "capacity": 434.215384589938
      },
      {
          "time_stamp_utc_ms": 1734758520000,
          "time": "2024-12-21T05:23:00Z",
          "energy": 45.84000000078231,
          "capacity": 440.0640000075102
      },
      {
          "time_stamp_utc_ms": 1734758790000,
          "time": "2024-12-21T05:28:00Z",
          "energy": 32.16000000014901,
          "capacity": 428.80000000198675
      },
      {
          "time_stamp_utc_ms": 1734759030000,
          "time": "2024-12-21T05:33:00Z",
          "energy": 28.559999999590218,
          "capacity": 428.39999999385327
      },
      {
          "time_stamp_utc_ms": 1734759300000,
          "time": "2024-12-21T05:38:00Z",
          "energy": 33.12000000011176,
          "capacity": 441.60000000149006
      },
      {
          "time_stamp_utc_ms": 1734759645000,
          "time": "2024-12-21T05:43:00Z",
          "energy": 40.919999999925494,
          "capacity": 426.99130434704864
      },
      {
          "time_stamp_utc_ms": 1734760000000,
          "time": "2024-12-21T05:48:00Z",
          "energy": 43.3199999993667,
          "capacity": 439.30142024570404
      },
      {
          "time_stamp_utc_ms": 1734760275000,
          "time": "2024-12-21T05:53:00Z",
          "energy": 32.880000000819564,
          "capacity": 430.42907599294136
      },
      {
          "time_stamp_utc_ms": 1734760500000,
          "time": "2024-12-21T05:58:00Z",
          "energy": 26.800000000745058,
          "capacity": 428.80000001192093
      },
      {
          "time_stamp_utc_ms": 1734760800000,
          "time": "2024-12-21T06:03:00Z",
          "energy": 37.27999999932945,
          "capacity": 447.3599999919534
      },
      {
          "time_stamp_utc_ms": 1734761145000,
          "time": "2024-12-21T06:08:00Z",
          "energy": 40.32000000029802,
          "capacity": 420.7304347857185
      },
      {
          "time_stamp_utc_ms": 1734761400000,
          "time": "2024-12-21T06:13:00Z",
          "energy": 31.91999999899417,
          "capacity": 450.6352941034471
      },
      {
          "time_stamp_utc_ms": 1734761880000,
          "time": "2024-12-21T06:18:00Z",
          "energy": 56.40000000037253,
          "capacity": 423.00000000279397
      },
      {
          "time_stamp_utc_ms": 1734762060000,
          "time": "2024-12-21T06:23:00Z",
          "energy": 22.56000000052154,
          "capacity": 451.20000001043076
      },
      {
          "time_stamp_utc_ms": 1734762360000,
          "time": "2024-12-21T06:28:00Z",
          "energy": 35.6800000006333,
          "capacity": 428.16000000759965
      },
      {
          "time_stamp_utc_ms": 1734762780000,
          "time": "2024-12-21T06:33:00Z",
          "energy": 51.19999999925494,
          "capacity": 438.85714285075665
      },
      {
          "time_stamp_utc_ms": 1734762990000,
          "time": "2024-12-21T06:38:00Z",
          "energy": 24.959999999962747,
          "capacity": 427.88571428507566
      },
      {
          "time_stamp_utc_ms": 1734763240000,
          "time": "2024-12-21T06:43:00Z",
          "energy": 29.919999999925494,
          "capacity": 430.84801643447463
      },
      {
          "time_stamp_utc_ms": 1734763560000,
          "time": "2024-12-21T06:48:00Z",
          "energy": 39.00799999944866,
          "capacity": 438.8399869153466
      },
      {
          "time_stamp_utc_ms": 1734763875000,
          "time": "2024-12-21T06:53:00Z",
          "energy": 37.39200000092387,
          "capacity": 427.33714286770135
      },
      {
          "time_stamp_utc_ms": 1734764190000,
          "time": "2024-12-21T06:58:00Z",
          "energy": 39.83999999985099,
          "capacity": 455.31428571258266
      },
      {
          "time_stamp_utc_ms": 1734764490000,
          "time": "2024-12-21T07:03:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1734764760000,
          "time": "2024-12-21T07:08:00Z",
          "energy": 32.55999999959022,
          "capacity": 434.1333333278696
      },
      {
          "time_stamp_utc_ms": 1734765040000,
          "time": "2024-12-21T07:13:00Z",
          "energy": 34.24000000022352,
          "capacity": 440.22858642554155
      },
      {
          "time_stamp_utc_ms": 1734765240000,
          "time": "2024-12-21T07:18:00Z",
          "energy": 24.16000000014901,
          "capacity": 434.8800103710296
      },
      {
          "time_stamp_utc_ms": 1734765660000,
          "time": "2024-12-21T07:23:00Z",
          "energy": 50.55999999959022,
          "capacity": 433.37142856791615
      },
      {
          "time_stamp_utc_ms": 1734765960000,
          "time": "2024-12-21T07:28:00Z",
          "energy": 36.60800000000745,
          "capacity": 439.2960000000894
      },
      {
          "time_stamp_utc_ms": 1734766260000,
          "time": "2024-12-21T07:33:00Z",
          "energy": 35.23200000077486,
          "capacity": 422.7840000092983
      },
      {
          "time_stamp_utc_ms": 1734766560000,
          "time": "2024-12-21T07:38:00Z",
          "energy": 36.959999999031425,
          "capacity": 443.5199999883771
      },
      {
          "time_stamp_utc_ms": 1734766860000,
          "time": "2024-12-21T07:43:00Z",
          "energy": 35.42399999964982,
          "capacity": 425.0879999957979
      },
      {
          "time_stamp_utc_ms": 1734767160000,
          "time": "2024-12-21T07:48:00Z",
          "energy": 36.09599999990314,
          "capacity": 433.1519999988377
      },
      {
          "time_stamp_utc_ms": 1734767460000,
          "time": "2024-12-21T07:53:00Z",
          "energy": 36.19200000073761,
          "capacity": 434.3040000088513
      },
      {
          "time_stamp_utc_ms": 1734767760000,
          "time": "2024-12-21T07:58:00Z",
          "energy": 35.42399999964982,
          "capacity": 425.0879999957979
      },
      {
          "time_stamp_utc_ms": 1734768060000,
          "time": "2024-12-21T08:03:00Z",
          "energy": 37.05600000079721,
          "capacity": 444.6720000095666
      },
      {
          "time_stamp_utc_ms": 1734768360000,
          "time": "2024-12-21T08:08:00Z",
          "energy": 35.231999998912215,
          "capacity": 422.78399998694664
      },
      {
          "time_stamp_utc_ms": 1734768660000,
          "time": "2024-12-21T08:13:00Z",
          "energy": 36.28800000064075,
          "capacity": 435.456000007689
      },
      {
          "time_stamp_utc_ms": 1734768960000,
          "time": "2024-12-21T08:18:00Z",
          "energy": 35.32799999881536,
          "capacity": 423.93599998578435
      },
      {
          "time_stamp_utc_ms": 1734769260000,
          "time": "2024-12-21T08:23:00Z",
          "energy": 35.80800000112504,
          "capacity": 429.69600001350045
      },
      {
          "time_stamp_utc_ms": 1734769560000,
          "time": "2024-12-21T08:28:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1734769860000,
          "time": "2024-12-21T08:33:00Z",
          "energy": 35.32799999974668,
          "capacity": 423.93599999696016
      },
      {
          "time_stamp_utc_ms": 1734770160000,
          "time": "2024-12-21T08:38:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1734770460000,
          "time": "2024-12-21T08:43:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734770760000,
          "time": "2024-12-21T08:48:00Z",
          "energy": 36.575999999418855,
          "capacity": 438.91199999302626
      },
      {
          "time_stamp_utc_ms": 1734771090000,
          "time": "2024-12-21T08:53:00Z",
          "energy": 39.45600000023842,
          "capacity": 430.42909091169184
      },
      {
          "time_stamp_utc_ms": 1734771340000,
          "time": "2024-12-21T08:58:00Z",
          "energy": 29.919999999925494,
          "capacity": 430.84801643447463
      },
      {
          "time_stamp_utc_ms": 1734771660000,
          "time": "2024-12-21T09:03:00Z",
          "energy": 39.67999999970198,
          "capacity": 446.3999866928909
      },
      {
          "time_stamp_utc_ms": 1734771960000,
          "time": "2024-12-21T09:08:00Z",
          "energy": 35.52000000048429,
          "capacity": 426.24000000581145
      },
      {
          "time_stamp_utc_ms": 1734772260000,
          "time": "2024-12-21T09:13:00Z",
          "energy": 35.61600000038743,
          "capacity": 427.39200000464916
      },
      {
          "time_stamp_utc_ms": 1734772560000,
          "time": "2024-12-21T09:18:00Z",
          "energy": 36.287999998778105,
          "capacity": 435.45599998533726
      },
      {
          "time_stamp_utc_ms": 1734772860000,
          "time": "2024-12-21T09:23:00Z",
          "energy": 35.23200000077486,
          "capacity": 422.7840000092983
      },
      {
          "time_stamp_utc_ms": 1734773140000,
          "time": "2024-12-21T09:28:00Z",
          "energy": 34.303999999538064,
          "capacity": 441.051443587612
      },
      {
          "time_stamp_utc_ms": 1734773480000,
          "time": "2024-12-21T09:33:00Z",
          "energy": 40.32000000029802,
          "capacity": 426.91765903669693
      },
      {
          "time_stamp_utc_ms": 1734773760000,
          "time": "2024-12-21T09:38:00Z",
          "energy": 33.75999999977648,
          "capacity": 434.0571576381678
      },
      {
          "time_stamp_utc_ms": 1734774045000,
          "time": "2024-12-21T09:43:00Z",
          "energy": 35.52000000048429,
          "capacity": 448.6736842166436
      },
      {
          "time_stamp_utc_ms": 1734774390000,
          "time": "2024-12-21T09:48:00Z",
          "energy": 42.0,
          "capacity": 438.2608695652174
      },
      {
          "time_stamp_utc_ms": 1734774600000,
          "time": "2024-12-21T09:53:00Z",
          "energy": 25.91999999899417,
          "capacity": 444.34285712561433
      },
      {
          "time_stamp_utc_ms": 1734774900000,
          "time": "2024-12-21T09:58:00Z",
          "energy": 35.760000000707805,
          "capacity": 429.1200000084937
      },
      {
          "time_stamp_utc_ms": 1734775230000,
          "time": "2024-12-21T10:03:00Z",
          "energy": 40.55999999959022,
          "capacity": 442.47272726825696
      },
      {
          "time_stamp_utc_ms": 1734775560000,
          "time": "2024-12-21T10:08:00Z",
          "energy": 39.84000000078231,
          "capacity": 434.6181818267161
      },
      {
          "time_stamp_utc_ms": 1734775840000,
          "time": "2024-12-21T10:13:00Z",
          "energy": 33.83999999985099,
          "capacity": 435.08572910273006
      },
      {
          "time_stamp_utc_ms": 1734776140000,
          "time": "2024-12-21T10:18:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1734776460000,
          "time": "2024-12-21T10:23:00Z",
          "energy": 39.04000000003725,
          "capacity": 439.1999869112395
      },
      {
          "time_stamp_utc_ms": 1734776760000,
          "time": "2024-12-21T10:28:00Z",
          "energy": 37.12000000011176,
          "capacity": 445.44000000134116
      },
      {
          "time_stamp_utc_ms": 1734777000000,
          "time": "2024-12-21T10:33:00Z",
          "energy": 28.959999999962747,
          "capacity": 434.3999999994412
      },
      {
          "time_stamp_utc_ms": 1734777480000,
          "time": "2024-12-21T10:38:00Z",
          "energy": 59.519999999552965,
          "capacity": 446.39999999664724
      },
      {
          "time_stamp_utc_ms": 1734777675000,
          "time": "2024-12-21T10:43:00Z",
          "energy": 23.87999999988824,
          "capacity": 440.86153845947524
      },
      {
          "time_stamp_utc_ms": 1734778080000,
          "time": "2024-12-21T10:48:00Z",
          "energy": 51.0,
          "capacity": 453.3333333333333
      },
      {
          "time_stamp_utc_ms": 1734778200000,
          "time": "2024-12-21T10:53:00Z",
          "energy": 15.360000000335276,
          "capacity": 460.8000000100583
      },
      {
          "time_stamp_utc_ms": 1734778520000,
          "time": "2024-12-21T10:58:00Z",
          "energy": 39.83999999985099,
          "capacity": 448.1999866409231
      },
      {
          "time_stamp_utc_ms": 1734778830000,
          "time": "2024-12-21T11:03:00Z",
          "energy": 39.60000000055879,
          "capacity": 459.8709818957519
      },
      {
          "time_stamp_utc_ms": 1734779175000,
          "time": "2024-12-21T11:08:00Z",
          "energy": 43.67999999970198,
          "capacity": 455.79130434471625
      },
      {
          "time_stamp_utc_ms": 1734779490000,
          "time": "2024-12-21T11:13:00Z",
          "energy": 38.16000000014901,
          "capacity": 436.1142857159887
      },
      {
          "time_stamp_utc_ms": 1734779760000,
          "time": "2024-12-21T11:18:00Z",
          "energy": 34.71999999973923,
          "capacity": 462.9333333298564
      },
      {
          "time_stamp_utc_ms": 1734780180000,
          "time": "2024-12-21T11:23:00Z",
          "energy": 50.71999999973923,
          "capacity": 434.74285714062194
      },
      {
          "time_stamp_utc_ms": 1734780450000,
          "time": "2024-12-21T11:28:00Z",
          "energy": 34.56000000052154,
          "capacity": 460.80000000695384
      },
      {
          "time_stamp_utc_ms": 1734780630000,
          "time": "2024-12-21T11:33:00Z",
          "energy": 21.83999999985099,
          "capacity": 436.7999999970197
      },
      {
          "time_stamp_utc_ms": 1734781080000,
          "time": "2024-12-21T11:38:00Z",
          "energy": 55.44000000040978,
          "capacity": 443.52000000327826
      },
      {
          "time_stamp_utc_ms": 1734781290000,
          "time": "2024-12-21T11:43:00Z",
          "energy": 26.399999999441206,
          "capacity": 452.57142856184925
      },
      {
          "time_stamp_utc_ms": 1734781500000,
          "time": "2024-12-21T11:48:00Z",
          "energy": 24.959999999962747,
          "capacity": 427.88571428507566
      },
      {
          "time_stamp_utc_ms": 1734781920000,
          "time": "2024-12-21T11:53:00Z",
          "energy": 52.64000000059605,
          "capacity": 451.20000000510896
      },
      {
          "time_stamp_utc_ms": 1734782160000,
          "time": "2024-12-21T11:58:00Z",
          "energy": 28.959999999962747,
          "capacity": 434.3999999994412
      },
      {
          "time_stamp_utc_ms": 1734782520000,
          "time": "2024-12-21T12:03:00Z",
          "energy": 44.40000000037253,
          "capacity": 444.00000000372523
      },
      {
          "time_stamp_utc_ms": 1734782820000,
          "time": "2024-12-21T12:08:00Z",
          "energy": 36.87999999895692,
          "capacity": 442.559999987483
      },
      {
          "time_stamp_utc_ms": 1734783180000,
          "time": "2024-12-21T12:13:00Z",
          "energy": 44.0,
          "capacity": 440.0
      },
      {
          "time_stamp_utc_ms": 1734783360000,
          "time": "2024-12-21T12:18:00Z",
          "energy": 22.656000001356006,
          "capacity": 453.1200000271201
      },
      {
          "time_stamp_utc_ms": 1734783660000,
          "time": "2024-12-21T12:23:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1734783960000,
          "time": "2024-12-21T12:28:00Z",
          "energy": 37.15199999883771,
          "capacity": 445.8239999860525
      },
      {
          "time_stamp_utc_ms": 1734784260000,
          "time": "2024-12-21T12:33:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1734784560000,
          "time": "2024-12-21T12:38:00Z",
          "energy": 36.67200000118464,
          "capacity": 440.06400001421576
      },
      {
          "time_stamp_utc_ms": 1734784770000,
          "time": "2024-12-21T12:43:00Z",
          "energy": 25.82399999909103,
          "capacity": 442.6971428415605
      },
      {
          "time_stamp_utc_ms": 1734785160000,
          "time": "2024-12-21T12:48:00Z",
          "energy": 48.00000000093132,
          "capacity": 443.0769230855199
      },
      {
          "time_stamp_utc_ms": 1734785400000,
          "time": "2024-12-21T12:53:00Z",
          "energy": 29.759999999776483,
          "capacity": 446.39999999664724
      },
      {
          "time_stamp_utc_ms": 1734785720000,
          "time": "2024-12-21T12:58:00Z",
          "energy": 40.639999999664724,
          "capacity": 457.1999863706068
      },
      {
          "time_stamp_utc_ms": 1734786080000,
          "time": "2024-12-21T13:03:00Z",
          "energy": 44.0,
          "capacity": 440.0
      },
      {
          "time_stamp_utc_ms": 1734786330000,
          "time": "2024-12-21T13:08:00Z",
          "energy": 32.480000000447035,
          "capacity": 467.71201784823484
      },
      {
          "time_stamp_utc_ms": 1734786600000,
          "time": "2024-12-21T13:13:00Z",
          "energy": 32.47999999951571,
          "capacity": 433.0666666602094
      },
      {
          "time_stamp_utc_ms": 1734786960000,
          "time": "2024-12-21T13:18:00Z",
          "energy": 44.0,
          "capacity": 440.0
      },
      {
          "time_stamp_utc_ms": 1734787290000,
          "time": "2024-12-21T13:23:00Z",
          "energy": 39.799999999813735,
          "capacity": 434.1818181797862
      },
      {
          "time_stamp_utc_ms": 1734787590000,
          "time": "2024-12-21T13:28:00Z",
          "energy": 36.24000000022352,
          "capacity": 434.88000000268227
      },
      {
          "time_stamp_utc_ms": 1734787840000,
          "time": "2024-12-21T13:33:00Z",
          "energy": 30.83999999985099,
          "capacity": 444.09601693877283
      },
      {
          "time_stamp_utc_ms": 1734788220000,
          "time": "2024-12-21T13:38:00Z",
          "energy": 44.64000000059605,
          "capacity": 422.9052525500184
      },
      {
          "time_stamp_utc_ms": 1734788340000,
          "time": "2024-12-21T13:43:00Z",
          "energy": 14.879999999888241,
          "capacity": 446.39999999664724
      },
      {
          "time_stamp_utc_ms": 1734788760000,
          "time": "2024-12-21T13:48:00Z",
          "energy": 50.16000000014901,
          "capacity": 429.94285714413434
      },
      {
          "time_stamp_utc_ms": 1734789090000,
          "time": "2024-12-21T13:53:00Z",
          "energy": 38.39999999944121,
          "capacity": 418.909090902995
      },
      {
          "time_stamp_utc_ms": 1734789360000,
          "time": "2024-12-21T13:58:00Z",
          "energy": 32.24000000022352,
          "capacity": 429.8666666696469
      },
      {
          "time_stamp_utc_ms": 1734789630000,
          "time": "2024-12-21T14:03:00Z",
          "energy": 30.87999999988824,
          "capacity": 411.7333333318432
      },
      {
          "time_stamp_utc_ms": 1734789870000,
          "time": "2024-12-21T14:08:00Z",
          "energy": 27.600000000558794,
          "capacity": 414.0000000083819
      },
      {
          "time_stamp_utc_ms": 1734790260000,
          "time": "2024-12-21T14:13:00Z",
          "energy": 44.11199999973178,
          "capacity": 407.18769230521644
      },
      {
          "time_stamp_utc_ms": 1734790560000,
          "time": "2024-12-21T14:18:00Z",
          "energy": 33.16799999959767,
          "capacity": 398.015999995172
      },
      {
          "time_stamp_utc_ms": 1734791190000,
          "time": "2024-12-21T14:28:00Z",
          "energy": 70.32000000029802,
          "capacity": 401.8285714302744
      },
      {
          "time_stamp_utc_ms": 1734791430000,
          "time": "2024-12-21T14:33:00Z",
          "energy": 27.59999999962747,
          "capacity": 413.99999999441206
      },
      {
          "time_stamp_utc_ms": 1734791790000,
          "time": "2024-12-21T14:38:00Z",
          "energy": 40.919999999925494,
          "capacity": 409.1999999992549
      },
      {
          "time_stamp_utc_ms": 1734792090000,
          "time": "2024-12-21T14:43:00Z",
          "energy": 33.72000000067055,
          "capacity": 404.6400000080467
      },
      {
          "time_stamp_utc_ms": 1734792360000,
          "time": "2024-12-21T14:48:00Z",
          "energy": 30.143999999389052,
          "capacity": 401.919999991854
      },
      {
          "time_stamp_utc_ms": 1734792660000,
          "time": "2024-12-21T14:53:00Z",
          "energy": 33.023999999277294,
          "capacity": 396.2879999913275
      },
      {
          "time_stamp_utc_ms": 1734792960000,
          "time": "2024-12-21T14:58:00Z",
          "energy": 35.71200000215322,
          "capacity": 428.54400002583867
      },
      {
          "time_stamp_utc_ms": 1734793260000,
          "time": "2024-12-21T15:03:00Z",
          "energy": 32.92799999937415,
          "capacity": 395.1359999924898
      },
      {
          "time_stamp_utc_ms": 1734793560000,
          "time": "2024-12-21T15:08:00Z",
          "energy": 34.55999999959022,
          "capacity": 414.7199999950827
      },
      {
          "time_stamp_utc_ms": 1734793860000,
          "time": "2024-12-21T15:13:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1734794160000,
          "time": "2024-12-21T15:18:00Z",
          "energy": 31.77599999960512,
          "capacity": 381.31199999526143
      },
      {
          "time_stamp_utc_ms": 1734794460000,
          "time": "2024-12-21T15:23:00Z",
          "energy": 32.83199999947101,
          "capacity": 393.9839999936521
      },
      {
          "time_stamp_utc_ms": 1734794760000,
          "time": "2024-12-21T15:28:00Z",
          "energy": 30.144000001251698,
          "capacity": 361.72800001502037
      },
      {
          "time_stamp_utc_ms": 1734795060000,
          "time": "2024-12-21T15:33:00Z",
          "energy": 32.92799999937415,
          "capacity": 395.1359999924898
      },
      {
          "time_stamp_utc_ms": 1734795360000,
          "time": "2024-12-21T15:38:00Z",
          "energy": 31.583999999798834,
          "capacity": 379.007999997586
      },
      {
          "time_stamp_utc_ms": 1734795660000,
          "time": "2024-12-21T15:43:00Z",
          "energy": 30.816000000573695,
          "capacity": 369.79200000688434
      },
      {
          "time_stamp_utc_ms": 1734795960000,
          "time": "2024-12-21T15:48:00Z",
          "energy": 31.200000000186265,
          "capacity": 374.4000000022352
      },
      {
          "time_stamp_utc_ms": 1734796260000,
          "time": "2024-12-21T15:53:00Z",
          "energy": 29.951999999582767,
          "capacity": 359.42399999499327
      },
      {
          "time_stamp_utc_ms": 1734796540000,
          "time": "2024-12-21T15:58:00Z",
          "energy": 28.480000000447035,
          "capacity": 366.17144104890116
      },
      {
          "time_stamp_utc_ms": 1734796920000,
          "time": "2024-12-21T16:03:00Z",
          "energy": 38.239999999292195,
          "capacity": 362.27367511194973
      },
      {
          "time_stamp_utc_ms": 1734797180000,
          "time": "2024-12-21T16:08:00Z",
          "energy": 25.440000000409782,
          "capacity": 352.2461409315163
      },
      {
          "time_stamp_utc_ms": 1734797490000,
          "time": "2024-12-21T16:13:00Z",
          "energy": 31.679999999701977,
          "capacity": 367.89678550794923
      },
      {
          "time_stamp_utc_ms": 1734797820000,
          "time": "2024-12-21T16:18:00Z",
          "energy": 31.200000000186265,
          "capacity": 340.36363636566836
      },
      {
          "time_stamp_utc_ms": 1734798060000,
          "time": "2024-12-21T16:23:00Z",
          "energy": 24.3199999993667,
          "capacity": 364.7999999905005
      },
      {
          "time_stamp_utc_ms": 1734798390000,
          "time": "2024-12-21T16:28:00Z",
          "energy": 32.200000000186265,
          "capacity": 351.27272727475923
      },
      {
          "time_stamp_utc_ms": 1734798690000,
          "time": "2024-12-21T16:33:00Z",
          "energy": 29.640000000596046,
          "capacity": 355.68000000715256
      },
      {
          "time_stamp_utc_ms": 1734799020000,
          "time": "2024-12-21T16:38:00Z",
          "energy": 33.119999999180436,
          "capacity": 361.3090909001502
      },
      {
          "time_stamp_utc_ms": 1734799140000,
          "time": "2024-12-21T16:43:00Z",
          "energy": 11.28000000026077,
          "capacity": 338.4000000078231
      },
      {
          "time_stamp_utc_ms": 1734799560000,
          "time": "2024-12-21T16:48:00Z",
          "energy": 41.95199999958277,
          "capacity": 359.5885714249951
      },
      {
          "time_stamp_utc_ms": 1734799920000,
          "time": "2024-12-21T16:53:00Z",
          "energy": 33.88800000026822,
          "capacity": 338.8800000026822
      },
      {
          "time_stamp_utc_ms": 1734800100000,
          "time": "2024-12-21T16:58:00Z",
          "energy": 17.520000000484288,
          "capacity": 350.40000000968575
      },
      {
          "time_stamp_utc_ms": 1734800520000,
          "time": "2024-12-21T17:03:00Z",
          "energy": 41.04000000003725,
          "capacity": 351.77142857174783
      },
      {
          "time_stamp_utc_ms": 1734800700000,
          "time": "2024-12-21T17:08:00Z",
          "energy": 16.799999999813735,
          "capacity": 335.9999999962747
      },
      {
          "time_stamp_utc_ms": 1734801075000,
          "time": "2024-12-21T17:13:00Z",
          "energy": 37.44000000040978,
          "capacity": 359.42400000393394
      },
      {
          "time_stamp_utc_ms": 1734801360000,
          "time": "2024-12-21T17:18:00Z",
          "energy": 25.59999999962747,
          "capacity": 323.36842104792595
      },
      {
          "time_stamp_utc_ms": 1734801680000,
          "time": "2024-12-21T17:23:00Z",
          "energy": 29.759999999776483,
          "capacity": 334.7999900196682
      },
      {
          "time_stamp_utc_ms": 1734801940000,
          "time": "2024-12-21T17:28:00Z",
          "energy": 24.480000000447035,
          "capacity": 338.9538337272834
      },
      {
          "time_stamp_utc_ms": 1734802245000,
          "time": "2024-12-21T17:33:00Z",
          "energy": 27.679999999701977,
          "capacity": 326.714743879123
      },
      {
          "time_stamp_utc_ms": 1734802545000,
          "time": "2024-12-21T17:38:00Z",
          "energy": 27.959999999962747,
          "capacity": 335.51999999955297
      },
      {
          "time_stamp_utc_ms": 1734802820000,
          "time": "2024-12-21T17:43:00Z",
          "energy": 25.0,
          "capacity": 327.2727159232152
      },
      {
          "time_stamp_utc_ms": 1734803160000,
          "time": "2024-12-21T17:48:00Z",
          "energy": 31.040000000037253,
          "capacity": 328.6588327484382
      },
      {
          "time_stamp_utc_ms": 1734803460000,
          "time": "2024-12-21T17:53:00Z",
          "energy": 27.83999999985099,
          "capacity": 334.07999999821186
      },
      {
          "time_stamp_utc_ms": 1734803740000,
          "time": "2024-12-21T17:58:00Z",
          "energy": 24.320000000298023,
          "capacity": 312.6857249395583
      },
      {
          "time_stamp_utc_ms": 1734804080000,
          "time": "2024-12-21T18:03:00Z",
          "energy": 32.0,
          "capacity": 338.823538915509
      },
      {
          "time_stamp_utc_ms": 1734804390000,
          "time": "2024-12-21T18:08:00Z",
          "energy": 26.959999999962747,
          "capacity": 313.083880598924
      },
      {
          "time_stamp_utc_ms": 1734804900000,
          "time": "2024-12-21T18:18:00Z",
          "energy": 48.24000000022352,
          "capacity": 340.51764706040126
      },
      {
          "time_stamp_utc_ms": 1734805280000,
          "time": "2024-12-21T18:23:00Z",
          "energy": 33.44000000040978,
          "capacity": 316.79999205325015
      },
      {
          "time_stamp_utc_ms": 1734805560000,
          "time": "2024-12-21T18:28:00Z",
          "energy": 25.43999999947846,
          "capacity": 327.0857254194822
      },
      {
          "time_stamp_utc_ms": 1734805890000,
          "time": "2024-12-21T18:33:00Z",
          "energy": 28.71999999973923,
          "capacity": 313.3090909062461
      },
      {
          "time_stamp_utc_ms": 1734806130000,
          "time": "2024-12-21T18:38:00Z",
          "energy": 21.83999999985099,
          "capacity": 327.5999999977648
      },
      {
          "time_stamp_utc_ms": 1734806400000,
          "time": "2024-12-21T18:43:00Z",
          "energy": 23.520000000484288,
          "capacity": 313.60000000645715
      },
      {
          "time_stamp_utc_ms": 1734806670000,
          "time": "2024-12-21T18:48:00Z",
          "energy": 26.399999999441206,
          "capacity": 351.9999999925494
      },
      {
          "time_stamp_utc_ms": 1734807030000,
          "time": "2024-12-21T18:53:00Z",
          "energy": 33.84000000078231,
          "capacity": 338.4000000078231
      },
      {
          "time_stamp_utc_ms": 1734807330000,
          "time": "2024-12-21T18:58:00Z",
          "energy": 26.399999999441206,
          "capacity": 316.7999999932945
      },
      {
          "time_stamp_utc_ms": 1734807690000,
          "time": "2024-12-21T19:03:00Z",
          "energy": 32.87999999988824,
          "capacity": 328.7999999988824
      },
      {
          "time_stamp_utc_ms": 1734807870000,
          "time": "2024-12-21T19:08:00Z",
          "energy": 16.08000000100583,
          "capacity": 321.60000002011657
      },
      {
          "time_stamp_utc_ms": 1734808260000,
          "time": "2024-12-21T19:13:00Z",
          "energy": 35.03999999910593,
          "capacity": 323.4461538379009
      },
      {
          "time_stamp_utc_ms": 1734808560000,
          "time": "2024-12-21T19:18:00Z",
          "energy": 28.84800000116229,
          "capacity": 346.1760000139475
      },
      {
          "time_stamp_utc_ms": 1734808860000,
          "time": "2024-12-21T19:23:00Z",
          "energy": 27.167999998666346,
          "capacity": 326.01599998399615
      },
      {
          "time_stamp_utc_ms": 1734809160000,
          "time": "2024-12-21T19:28:00Z",
          "energy": 27.55200000014156,
          "capacity": 330.62400000169873
      },
      {
          "time_stamp_utc_ms": 1734809460000,
          "time": "2024-12-21T19:33:00Z",
          "energy": 26.11199999973178,
          "capacity": 313.34399999678135
      },
      {
          "time_stamp_utc_ms": 1734809760000,
          "time": "2024-12-21T19:38:00Z",
          "energy": 27.648000000976026,
          "capacity": 331.77600001171237
      },
      {
          "time_stamp_utc_ms": 1734810060000,
          "time": "2024-12-21T19:43:00Z",
          "energy": 28.415999999269843,
          "capacity": 340.9919999912381
      },
      {
          "time_stamp_utc_ms": 1734810360000,
          "time": "2024-12-21T19:48:00Z",
          "energy": 27.84000000078231,
          "capacity": 334.0800000093878
      },
      {
          "time_stamp_utc_ms": 1734810660000,
          "time": "2024-12-21T19:53:00Z",
          "energy": 29.951999999582767,
          "capacity": 359.42399999499327
      },
      {
          "time_stamp_utc_ms": 1734810960000,
          "time": "2024-12-21T19:58:00Z",
          "energy": 27.83999999985099,
          "capacity": 334.07999999821186
      },
      {
          "time_stamp_utc_ms": 1734811260000,
          "time": "2024-12-21T20:03:00Z",
          "energy": 29.472000000067055,
          "capacity": 353.6640000008047
      },
      {
          "time_stamp_utc_ms": 1734811620000,
          "time": "2024-12-21T20:08:00Z",
          "energy": 33.79200000036508,
          "capacity": 337.9200000036508
      },
      {
          "time_stamp_utc_ms": 1734811840000,
          "time": "2024-12-21T20:13:00Z",
          "energy": 21.59999999962747,
          "capacity": 353.4545377875287
      },
      {
          "time_stamp_utc_ms": 1734812190000,
          "time": "2024-12-21T20:18:00Z",
          "energy": 34.32000000029802,
          "capacity": 353.0057046701375
      },
      {
          "time_stamp_utc_ms": 1734812520000,
          "time": "2024-12-21T20:23:00Z",
          "energy": 31.43999999947846,
          "capacity": 342.9818181761287
      },
      {
          "time_stamp_utc_ms": 1734812780000,
          "time": "2024-12-21T20:28:00Z",
          "energy": 25.919999999925494,
          "capacity": 358.8922945271852
      },
      {
          "time_stamp_utc_ms": 1734813090000,
          "time": "2024-12-21T20:33:00Z",
          "energy": 30.71999999973923,
          "capacity": 356.7483980686422
      },
      {
          "time_stamp_utc_ms": 1734813420000,
          "time": "2024-12-21T20:38:00Z",
          "energy": 33.60000000055879,
          "capacity": 366.5454545515505
      },
      {
          "time_stamp_utc_ms": 1734813720000,
          "time": "2024-12-21T20:43:00Z",
          "energy": 30.71999999973923,
          "capacity": 368.63999999687076
      },
      {
          "time_stamp_utc_ms": 1734813920000,
          "time": "2024-12-21T20:48:00Z",
          "energy": 20.640000000596046,
          "capacity": 371.5200088684561
      },
      {
          "time_stamp_utc_ms": 1734814200000,
          "time": "2024-12-21T20:53:00Z",
          "energy": 30.240000000223517,
          "capacity": 388.8000132453233
      },
      {
          "time_stamp_utc_ms": 1734814540000,
          "time": "2024-12-21T20:58:00Z",
          "energy": 36.79999999888241,
          "capacity": 389.6470697410021
      },
      {
          "time_stamp_utc_ms": 1734814845000,
          "time": "2024-12-21T21:03:00Z",
          "energy": 33.52000000048429,
          "capacity": 395.6458892740007
      },
      {
          "time_stamp_utc_ms": 1734815190000,
          "time": "2024-12-21T21:08:00Z",
          "energy": 40.19999999925494,
          "capacity": 419.47826086179066
      },
      {
          "time_stamp_utc_ms": 1734815460000,
          "time": "2024-12-21T21:13:00Z",
          "energy": 29.16000000014901,
          "capacity": 388.8000000019868
      },
      {
          "time_stamp_utc_ms": 1734815775000,
          "time": "2024-12-21T21:18:00Z",
          "energy": 35.52000000141561,
          "capacity": 405.9428571590355
      },
      {
          "time_stamp_utc_ms": 1734816120000,
          "time": "2024-12-21T21:23:00Z",
          "energy": 37.19999999925494,
          "capacity": 388.1739130357037
      },
      {
          "time_stamp_utc_ms": 1734816630000,
          "time": "2024-12-21T21:33:00Z",
          "energy": 57.83999999985099,
          "capacity": 408.2823529401246
      },
      {
          "time_stamp_utc_ms": 1734816975000,
          "time": "2024-12-21T21:38:00Z",
          "energy": 37.56000000052154,
          "capacity": 391.93043478805083
      },
      {
          "time_stamp_utc_ms": 1734817200000,
          "time": "2024-12-21T21:43:00Z",
          "energy": 25.559999999590218,
          "capacity": 408.9599999934435
      },
      {
          "time_stamp_utc_ms": 1734817545000,
          "time": "2024-12-21T21:48:00Z",
          "energy": 39.12000000011176,
          "capacity": 408.2086956533401
      },
      {
          "time_stamp_utc_ms": 1734817840000,
          "time": "2024-12-21T21:53:00Z",
          "energy": 33.28000000026077,
          "capacity": 406.12882669181386
      },
      {
          "time_stamp_utc_ms": 1734818220000,
          "time": "2024-12-21T21:58:00Z",
          "energy": 41.839999998919666,
          "capacity": 396.378937410386
      },
      {
          "time_stamp_utc_ms": 1734818460000,
          "time": "2024-12-21T22:03:00Z",
          "energy": 26.480000000447035,
          "capacity": 397.2000000067055
      },
      {
          "time_stamp_utc_ms": 1734818640000,
          "time": "2024-12-21T22:08:00Z",
          "energy": 20.320000000298023,
          "capacity": 406.40000000596046
      },
      {
          "time_stamp_utc_ms": 1734819090000,
          "time": "2024-12-21T22:13:00Z",
          "energy": 49.67999999970198,
          "capacity": 397.4399999976158
      },
      {
          "time_stamp_utc_ms": 1734819400000,
          "time": "2024-12-21T22:18:00Z",
          "energy": 35.12000000011176,
          "capacity": 407.84517383844195
      },
      {
          "time_stamp_utc_ms": 1734819660000,
          "time": "2024-12-21T22:23:00Z",
          "energy": 29.200000000186265,
          "capacity": 404.30767748035413
      },
      {
          "time_stamp_utc_ms": 1734819990000,
          "time": "2024-12-21T22:28:00Z",
          "energy": 36.480000000447035,
          "capacity": 397.9636363685131
      },
      {
          "time_stamp_utc_ms": 1734820350000,
          "time": "2024-12-21T22:33:00Z",
          "energy": 41.519999999552965,
          "capacity": 415.19999999552965
      },
      {
          "time_stamp_utc_ms": 1734820545000,
          "time": "2024-12-21T22:38:00Z",
          "energy": 20.639999999664724,
          "capacity": 381.0461538399641
      },
      {
          "time_stamp_utc_ms": 1734820830000,
          "time": "2024-12-21T22:43:00Z",
          "energy": 32.40000000037253,
          "capacity": 409.26315789944243
      },
      {
          "time_stamp_utc_ms": 1734821180000,
          "time": "2024-12-21T22:48:00Z",
          "energy": 39.12000000011176,
          "capacity": 402.3771318943856
      },
      {
          "time_stamp_utc_ms": 1734821520000,
          "time": "2024-12-21T22:53:00Z",
          "energy": 38.40000000037253,
          "capacity": 406.58824670255524
      },
      {
          "time_stamp_utc_ms": 1734821760000,
          "time": "2024-12-21T22:58:00Z",
          "energy": 28.031999999657273,
          "capacity": 420.4799999948591
      },
      {
          "time_stamp_utc_ms": 1734822060000,
          "time": "2024-12-21T23:03:00Z",
          "energy": 32.3519999999553,
          "capacity": 388.22399999946356
      },
      {
          "time_stamp_utc_ms": 1734822360000,
          "time": "2024-12-21T23:08:00Z",
          "energy": 34.847999999299645,
          "capacity": 418.1759999915958
      },
      {
          "time_stamp_utc_ms": 1734822660000,
          "time": "2024-12-21T23:13:00Z",
          "energy": 32.928000001236796,
          "capacity": 395.1360000148416
      },
      {
          "time_stamp_utc_ms": 1734822840000,
          "time": "2024-12-21T23:18:00Z",
          "energy": 19.679999999701977,
          "capacity": 393.59999999403954
      },
      {
          "time_stamp_utc_ms": 1734823290000,
          "time": "2024-12-21T23:23:00Z",
          "energy": 52.080000000074506,
          "capacity": 416.64000000059605
      },
      {
          "time_stamp_utc_ms": 1734823530000,
          "time": "2024-12-21T23:28:00Z",
          "energy": 28.799999998882413,
          "capacity": 431.9999999832362
      },
      {
          "time_stamp_utc_ms": 1734823920000,
          "time": "2024-12-21T23:33:00Z",
          "energy": 44.880000000819564,
          "capacity": 414.2769230844883
      },
      {
          "time_stamp_utc_ms": 1734824130000,
          "time": "2024-12-21T23:38:00Z",
          "energy": 23.640000000596046,
          "capacity": 405.25714286736076
      },
      {
          "time_stamp_utc_ms": 1734824430000,
          "time": "2024-12-21T23:43:00Z",
          "energy": 35.399999998509884,
          "capacity": 424.7999999821186
      },
      {
          "time_stamp_utc_ms": 1734824760000,
          "time": "2024-12-21T23:48:00Z",
          "energy": 39.84000000078231,
          "capacity": 434.6181818267161
      },
      {
          "time_stamp_utc_ms": 1734825090000,
          "time": "2024-12-21T23:53:00Z",
          "energy": 38.28000000026077,
          "capacity": 417.60000000284475
      },
      {
          "time_stamp_utc_ms": 1734825420000,
          "time": "2024-12-21T23:58:00Z",
          "energy": 38.75999999977648,
          "capacity": 422.8363636339253
      },
      {
          "time_stamp_utc_ms": 1734825630000,
          "time": "2024-12-22T00:03:00Z",
          "energy": 24.0,
          "capacity": 411.4285714285714
      },
      {
          "time_stamp_utc_ms": 1734825940000,
          "time": "2024-12-22T00:08:00Z",
          "energy": 36.239999999292195,
          "capacity": 420.85162584195405
      },
      {
          "time_stamp_utc_ms": 1734826350000,
          "time": "2024-12-22T00:13:00Z",
          "energy": 48.48000000137836,
          "capacity": 425.6780388911706
      },
      {
          "time_stamp_utc_ms": 1734826560000,
          "time": "2024-12-22T00:18:00Z",
          "energy": 24.959999999031425,
          "capacity": 427.8857142691101
      },
      {
          "time_stamp_utc_ms": 1734826800000,
          "time": "2024-12-22T00:23:00Z",
          "energy": 30.71999999973923,
          "capacity": 460.79999999608845
      },
      {
          "time_stamp_utc_ms": 1734827160000,
          "time": "2024-12-22T00:28:00Z",
          "energy": 40.56000000052154,
          "capacity": 405.6000000052154
      },
      {
          "time_stamp_utc_ms": 1734827500000,
          "time": "2024-12-22T00:33:00Z",
          "energy": 41.519999999552965,
          "capacity": 439.6235417381397
      },
      {
          "time_stamp_utc_ms": 1734827760000,
          "time": "2024-12-22T00:38:00Z",
          "energy": 29.919999999925494,
          "capacity": 414.27690788030503
      },
      {
          "time_stamp_utc_ms": 1734828000000,
          "time": "2024-12-22T00:43:00Z",
          "energy": 27.200000000186265,
          "capacity": 408.00000000279397
      },
      {
          "time_stamp_utc_ms": 1734828300000,
          "time": "2024-12-22T00:48:00Z",
          "energy": 36.32000000029802,
          "capacity": 435.8400000035763
      },
      {
          "time_stamp_utc_ms": 1734828660000,
          "time": "2024-12-22T00:53:00Z",
          "energy": 40.95999999996275,
          "capacity": 409.59999999962747
      },
      {
          "time_stamp_utc_ms": 1734828960000,
          "time": "2024-12-22T00:58:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1734829220000,
          "time": "2024-12-22T01:03:00Z",
          "energy": 30.079999999143183,
          "capacity": 416.49229240359784
      },
      {
          "time_stamp_utc_ms": 1734829590000,
          "time": "2024-12-22T01:08:00Z",
          "energy": 44.080000000074506,
          "capacity": 428.886497541753
      },
      {
          "time_stamp_utc_ms": 1734829860000,
          "time": "2024-12-22T01:13:00Z",
          "energy": 31.920000000856817,
          "capacity": 425.60000001142424
      },
      {
          "time_stamp_utc_ms": 1734830190000,
          "time": "2024-12-22T01:18:00Z",
          "energy": 39.24000000022352,
          "capacity": 428.0727272751657
      },
      {
          "time_stamp_utc_ms": 1734830430000,
          "time": "2024-12-22T01:23:00Z",
          "energy": 29.999999999068677,
          "capacity": 449.99999998603016
      },
      {
          "time_stamp_utc_ms": 1734830760000,
          "time": "2024-12-22T01:28:00Z",
          "energy": 38.5679999999702,
          "capacity": 420.7418181814931
      },
      {
          "time_stamp_utc_ms": 1734830970000,
          "time": "2024-12-22T01:33:00Z",
          "energy": 25.392000000923872,
          "capacity": 435.29142858726635
      },
      {
          "time_stamp_utc_ms": 1734831360000,
          "time": "2024-12-22T01:38:00Z",
          "energy": 46.3199999993667,
          "capacity": 427.56923076338495
      },
      {
          "time_stamp_utc_ms": 1734831640000,
          "time": "2024-12-22T01:43:00Z",
          "energy": 33.12000000011176,
          "capacity": 425.8285859336435
      },
      {
          "time_stamp_utc_ms": 1734832200000,
          "time": "2024-12-22T01:53:00Z",
          "energy": 66.72000000067055,
          "capacity": 428.9143003273304
      },
      {
          "time_stamp_utc_ms": 1734832500000,
          "time": "2024-12-22T01:58:00Z",
          "energy": 35.27999999932945,
          "capacity": 423.3599999919534
      },
      {
          "time_stamp_utc_ms": 1734832860000,
          "time": "2024-12-22T02:03:00Z",
          "energy": 43.67999999970198,
          "capacity": 436.7999999970197
      },
      {
          "time_stamp_utc_ms": 1734833220000,
          "time": "2024-12-22T02:08:00Z",
          "energy": 43.20000000111759,
          "capacity": 432.00000001117587
      },
      {
          "time_stamp_utc_ms": 1734833370000,
          "time": "2024-12-22T02:13:00Z",
          "energy": 18.479999999515712,
          "capacity": 443.5199999883771
      },
      {
          "time_stamp_utc_ms": 1734833745000,
          "time": "2024-12-22T02:18:00Z",
          "energy": 44.04000000003725,
          "capacity": 422.78400000035765
      },
      {
          "time_stamp_utc_ms": 1734834080000,
          "time": "2024-12-22T02:23:00Z",
          "energy": 39.639999999664724,
          "capacity": 425.9820774218249
      },
      {
          "time_stamp_utc_ms": 1734834340000,
          "time": "2024-12-22T02:28:00Z",
          "energy": 31.200000001117587,
          "capacity": 431.9999841698093
      },
      {
          "time_stamp_utc_ms": 1734834570000,
          "time": "2024-12-22T02:33:00Z",
          "energy": 26.959999999031425,
          "capacity": 421.98261742905675
      },
      {
          "time_stamp_utc_ms": 1734834900000,
          "time": "2024-12-22T02:38:00Z",
          "energy": 41.28000000026077,
          "capacity": 450.3272727301175
      },
      {
          "time_stamp_utc_ms": 1734835170000,
          "time": "2024-12-22T02:43:00Z",
          "energy": 31.679999999701977,
          "capacity": 422.39999999602634
      },
      {
          "time_stamp_utc_ms": 1734835590000,
          "time": "2024-12-22T02:48:00Z",
          "energy": 50.64000000059605,
          "capacity": 434.0571428622518
      },
      {
          "time_stamp_utc_ms": 1734835860000,
          "time": "2024-12-22T02:53:00Z",
          "energy": 32.639999999664724,
          "capacity": 435.19999999552965
      },
      {
          "time_stamp_utc_ms": 1734836160000,
          "time": "2024-12-22T02:58:00Z",
          "energy": 35.67999999970198,
          "capacity": 428.1599999964237
      },
      {
          "time_stamp_utc_ms": 1734836500000,
          "time": "2024-12-22T03:03:00Z",
          "energy": 41.75999999977648,
          "capacity": 442.16471828237263
      },
      {
          "time_stamp_utc_ms": 1734836760000,
          "time": "2024-12-22T03:08:00Z",
          "energy": 30.240000000223517,
          "capacity": 418.70767695268114
      },
      {
          "time_stamp_utc_ms": 1734837030000,
          "time": "2024-12-22T03:13:00Z",
          "energy": 33.6800000006333,
          "capacity": 449.06666667511064
      },
      {
          "time_stamp_utc_ms": 1734837330000,
          "time": "2024-12-22T03:18:00Z",
          "energy": 35.75999999977648,
          "capacity": 429.11999999731785
      },
      {
          "time_stamp_utc_ms": 1734837660000,
          "time": "2024-12-22T03:23:00Z",
          "energy": 40.15999999921769,
          "capacity": 438.1090909005566
      },
      {
          "time_stamp_utc_ms": 1734837990000,
          "time": "2024-12-22T03:28:00Z",
          "energy": 40.24000000115484,
          "capacity": 438.9818181944164
      },
      {
          "time_stamp_utc_ms": 1734838290000,
          "time": "2024-12-22T03:33:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1734838500000,
          "time": "2024-12-22T03:38:00Z",
          "energy": 25.679999999701977,
          "capacity": 440.2285714234624
      },
      {
          "time_stamp_utc_ms": 1734838880000,
          "time": "2024-12-22T03:43:00Z",
          "energy": 46.24000000022352,
          "capacity": 438.0631469029183
      },
      {
          "time_stamp_utc_ms": 1734839160000,
          "time": "2024-12-22T03:48:00Z",
          "energy": 33.44000000040978,
          "capacity": 429.9428717918927
      },
      {
          "time_stamp_utc_ms": 1734839440000,
          "time": "2024-12-22T03:53:00Z",
          "energy": 34.87999999895692,
          "capacity": 448.4571581180916
      },
      {
          "time_stamp_utc_ms": 1734839740000,
          "time": "2024-12-22T03:58:00Z",
          "energy": 37.12000000104308,
          "capacity": 445.440000012517
      },
      {
          "time_stamp_utc_ms": 1734840380000,
          "time": "2024-12-22T04:08:00Z",
          "energy": 78.07999999914318,
          "capacity": 439.1999869060008
      },
      {
          "time_stamp_utc_ms": 1734840600000,
          "time": "2024-12-22T04:13:00Z",
          "energy": 26.56000000052154,
          "capacity": 434.61817240662094
      },
      {
          "time_stamp_utc_ms": 1734840900000,
          "time": "2024-12-22T04:18:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1734841230000,
          "time": "2024-12-22T04:23:00Z",
          "energy": 38.87999999988824,
          "capacity": 424.14545454423535
      },
      {
          "time_stamp_utc_ms": 1734841440000,
          "time": "2024-12-22T04:28:00Z",
          "energy": 26.40000000037253,
          "capacity": 452.57142857781474
      },
      {
          "time_stamp_utc_ms": 1734841980000,
          "time": "2024-12-22T04:33:00Z",
          "energy": 65.27999999932945,
          "capacity": 435.19999999552965
      },
      {
          "time_stamp_utc_ms": 1734842175000,
          "time": "2024-12-22T04:38:00Z",
          "energy": 24.480000000447035,
          "capacity": 451.9384615467145
      },
      {
          "time_stamp_utc_ms": 1734842460000,
          "time": "2024-12-22T04:43:00Z",
          "energy": 35.28000000026077,
          "capacity": 445.64210526645184
      },
      {
          "time_stamp_utc_ms": 1734842820000,
          "time": "2024-12-22T04:48:00Z",
          "energy": 43.43999999947846,
          "capacity": 434.39999999478454
      },
      {
          "time_stamp_utc_ms": 1734843090000,
          "time": "2024-12-22T04:53:00Z",
          "energy": 33.84000000078231,
          "capacity": 451.20000001043076
      },
      {
          "time_stamp_utc_ms": 1734843380000,
          "time": "2024-12-22T04:58:00Z",
          "energy": 34.639999999664724,
          "capacity": 430.013778958145
      },
      {
          "time_stamp_utc_ms": 1734843660000,
          "time": "2024-12-22T05:03:00Z",
          "energy": 35.35999999940395,
          "capacity": 454.6285869054654
      },
      {
          "time_stamp_utc_ms": 1734843960000,
          "time": "2024-12-22T05:08:00Z",
          "energy": 37.28000000026077,
          "capacity": 447.36000000312924
      },
      {
          "time_stamp_utc_ms": 1734844260000,
          "time": "2024-12-22T05:13:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1734844560000,
          "time": "2024-12-22T05:18:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1734844880000,
          "time": "2024-12-22T05:23:00Z",
          "energy": 39.19999999925494,
          "capacity": 440.9999868487943
      },
      {
          "time_stamp_utc_ms": 1734845220000,
          "time": "2024-12-22T05:28:00Z",
          "energy": 43.60000000055879,
          "capacity": 461.6470717782977
      },
      {
          "time_stamp_utc_ms": 1734845400000,
          "time": "2024-12-22T05:33:00Z",
          "energy": 21.83999999985099,
          "capacity": 436.7999999970197
      },
      {
          "time_stamp_utc_ms": 1734845700000,
          "time": "2024-12-22T05:38:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1734846060000,
          "time": "2024-12-22T05:43:00Z",
          "energy": 44.256000000052154,
          "capacity": 442.56000000052154
      },
      {
          "time_stamp_utc_ms": 1734846360000,
          "time": "2024-12-22T05:48:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1734846700000,
          "time": "2024-12-22T05:53:00Z",
          "energy": 42.527999999932945,
          "capacity": 450.29648321800147
      },
      {
          "time_stamp_utc_ms": 1734846930000,
          "time": "2024-12-22T05:58:00Z",
          "energy": 28.559999999590218,
          "capacity": 447.02609621787536
      },
      {
          "time_stamp_utc_ms": 1734847170000,
          "time": "2024-12-22T06:03:00Z",
          "energy": 28.320000000298023,
          "capacity": 424.80000000447035
      },
      {
          "time_stamp_utc_ms": 1734847560000,
          "time": "2024-12-22T06:08:00Z",
          "energy": 48.08000000100583,
          "capacity": 443.8153846246692
      },
      {
          "time_stamp_utc_ms": 1734847880000,
          "time": "2024-12-22T06:13:00Z",
          "energy": 37.75999999884516,
          "capacity": 424.7999873269819
      },
      {
          "time_stamp_utc_ms": 1734848250000,
          "time": "2024-12-22T06:18:00Z",
          "energy": 46.40000000037253,
          "capacity": 451.4594710994436
      },
      {
          "time_stamp_utc_ms": 1734848460000,
          "time": "2024-12-22T06:23:00Z",
          "energy": 25.248000000603497,
          "capacity": 432.8228571532028
      },
      {
          "time_stamp_utc_ms": 1734848760000,
          "time": "2024-12-22T06:28:00Z",
          "energy": 36.671999999322,
          "capacity": 440.06399999186397
      },
      {
          "time_stamp_utc_ms": 1734849060000,
          "time": "2024-12-22T06:33:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1734849360000,
          "time": "2024-12-22T06:38:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1734849660000,
          "time": "2024-12-22T06:43:00Z",
          "energy": 37.82399999909103,
          "capacity": 453.88799998909235
      },
      {
          "time_stamp_utc_ms": 1734849960000,
          "time": "2024-12-22T06:48:00Z",
          "energy": 35.424000000581145,
          "capacity": 425.08800000697374
      },
      {
          "time_stamp_utc_ms": 1734850260000,
          "time": "2024-12-22T06:53:00Z",
          "energy": 36.959999999031425,
          "capacity": 443.5199999883771
      },
      {
          "time_stamp_utc_ms": 1734850560000,
          "time": "2024-12-22T06:58:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1734850860000,
          "time": "2024-12-22T07:03:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1734851160000,
          "time": "2024-12-22T07:08:00Z",
          "energy": 37.152000000700355,
          "capacity": 445.8240000084043
      },
      {
          "time_stamp_utc_ms": 1734851460000,
          "time": "2024-12-22T07:13:00Z",
          "energy": 35.42399999964982,
          "capacity": 425.0879999957979
      },
      {
          "time_stamp_utc_ms": 1734851760000,
          "time": "2024-12-22T07:18:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1734852060000,
          "time": "2024-12-22T07:23:00Z",
          "energy": 36.09599999990314,
          "capacity": 433.1519999988377
      },
      {
          "time_stamp_utc_ms": 1734852360000,
          "time": "2024-12-22T07:28:00Z",
          "energy": 36.86399999912828,
          "capacity": 442.3679999895394
      },
      {
          "time_stamp_utc_ms": 1734852660000,
          "time": "2024-12-22T07:33:00Z",
          "energy": 37.63200000114739,
          "capacity": 451.58400001376873
      },
      {
          "time_stamp_utc_ms": 1734852960000,
          "time": "2024-12-22T07:38:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1734853260000,
          "time": "2024-12-22T07:43:00Z",
          "energy": 37.53599999845028,
          "capacity": 450.43199998140335
      },
      {
          "time_stamp_utc_ms": 1734853560000,
          "time": "2024-12-22T07:48:00Z",
          "energy": 36.5760000012815,
          "capacity": 438.91200001537806
      },
      {
          "time_stamp_utc_ms": 1734853920000,
          "time": "2024-12-22T07:53:00Z",
          "energy": 45.2160000000149,
          "capacity": 452.16000000014895
      },
      {
          "time_stamp_utc_ms": 1734854175000,
          "time": "2024-12-22T07:58:00Z",
          "energy": 31.43999999947846,
          "capacity": 443.85882352204885
      },
      {
          "time_stamp_utc_ms": 1734854550000,
          "time": "2024-12-22T08:03:00Z",
          "energy": 44.64000000059605,
          "capacity": 428.54400000572207
      },
      {
          "time_stamp_utc_ms": 1734854730000,
          "time": "2024-12-22T08:08:00Z",
          "energy": 23.03999999910593,
          "capacity": 460.7999999821186
      },
      {
          "time_stamp_utc_ms": 1734854940000,
          "time": "2024-12-22T08:13:00Z",
          "energy": 26.16000000014901,
          "capacity": 448.45714285969734
      },
      {
          "time_stamp_utc_ms": 1734855400000,
          "time": "2024-12-22T08:18:00Z",
          "energy": 55.6800000006333,
          "capacity": 435.75653077821306
      },
      {
          "time_stamp_utc_ms": 1734855640000,
          "time": "2024-12-22T08:23:00Z",
          "energy": 29.759999999776483,
          "capacity": 446.39999999664724
      },
      {
          "time_stamp_utc_ms": 1734855990000,
          "time": "2024-12-22T08:28:00Z",
          "energy": 41.28000000026077,
          "capacity": 424.59427414769203
      },
      {
          "time_stamp_utc_ms": 1734856230000,
          "time": "2024-12-22T08:33:00Z",
          "energy": 30.240000000223517,
          "capacity": 453.60000000335276
      },
      {
          "time_stamp_utc_ms": 1734856620000,
          "time": "2024-12-22T08:38:00Z",
          "energy": 46.3199999993667,
          "capacity": 427.56923076338495
      },
      {
          "time_stamp_utc_ms": 1734856860000,
          "time": "2024-12-22T08:43:00Z",
          "energy": 30.287999999709427,
          "capacity": 454.3199999956414
      },
      {
          "time_stamp_utc_ms": 1734857190000,
          "time": "2024-12-22T08:48:00Z",
          "energy": 40.51200000010431,
          "capacity": 441.94909091022885
      },
      {
          "time_stamp_utc_ms": 1734857460000,
          "time": "2024-12-22T08:53:00Z",
          "energy": 33.12000000011176,
          "capacity": 441.60000000149006
      },
      {
          "time_stamp_utc_ms": 1734858120000,
          "time": "2024-12-22T09:03:00Z",
          "energy": 80.87999999988824,
          "capacity": 441.16363636302674
      },
      {
          "time_stamp_utc_ms": 1734858420000,
          "time": "2024-12-22T09:08:00Z",
          "energy": 38.16000000014901,
          "capacity": 457.92000000178814
      },
      {
          "time_stamp_utc_ms": 1734858780000,
          "time": "2024-12-22T09:13:00Z",
          "energy": 43.200000000186265,
          "capacity": 432.00000000186265
      },
      {
          "time_stamp_utc_ms": 1734858960000,
          "time": "2024-12-22T09:18:00Z",
          "energy": 23.28000000026077,
          "capacity": 465.6000000052154
      },
      {
          "time_stamp_utc_ms": 1734859290000,
          "time": "2024-12-22T09:23:00Z",
          "energy": 41.16000000014901,
          "capacity": 449.0181818198074
      },
      {
          "time_stamp_utc_ms": 1734859560000,
          "time": "2024-12-22T09:28:00Z",
          "energy": 32.99999999906868,
          "capacity": 439.9999999875823
      },
      {
          "time_stamp_utc_ms": 1734859860000,
          "time": "2024-12-22T09:33:00Z",
          "energy": 37.92000000085682,
          "capacity": 455.04000001028186
      },
      {
          "time_stamp_utc_ms": 1734860180000,
          "time": "2024-12-22T09:38:00Z",
          "energy": 38.87999999988824,
          "capacity": 437.3999869632073
      },
      {
          "time_stamp_utc_ms": 1734860340000,
          "time": "2024-12-22T09:43:00Z",
          "energy": 19.679999999701977,
          "capacity": 442.7999867968265
      },
      {
          "time_stamp_utc_ms": 1734860820000,
          "time": "2024-12-22T09:48:00Z",
          "energy": 61.44000000040978,
          "capacity": 460.80000000307336
      },
      {
          "time_stamp_utc_ms": 1734861060000,
          "time": "2024-12-22T09:53:00Z",
          "energy": 30.71999999973923,
          "capacity": 460.79999999608845
      },
      {
          "time_stamp_utc_ms": 1734861420000,
          "time": "2024-12-22T09:58:00Z",
          "energy": 46.080000000074506,
          "capacity": 460.80000000074506
      },
      {
          "time_stamp_utc_ms": 1734861640000,
          "time": "2024-12-22T10:03:00Z",
          "energy": 26.719999998807907,
          "capacity": 437.2363541400139
      },
      {
          "time_stamp_utc_ms": 1734861960000,
          "time": "2024-12-22T10:08:00Z",
          "energy": 40.24000000115484,
          "capacity": 452.699986521481
      },
      {
          "time_stamp_utc_ms": 1734862290000,
          "time": "2024-12-22T10:13:00Z",
          "energy": 40.799999999813735,
          "capacity": 445.0909090888771
      },
      {
          "time_stamp_utc_ms": 1734862580000,
          "time": "2024-12-22T10:18:00Z",
          "energy": 36.40000000037253,
          "capacity": 451.86205411051293
      },
      {
          "time_stamp_utc_ms": 1734862840000,
          "time": "2024-12-22T10:23:00Z",
          "energy": 32.79999999888241,
          "capacity": 454.1538294801087
      },
      {
          "time_stamp_utc_ms": 1734863100000,
          "time": "2024-12-22T10:28:00Z",
          "energy": 32.16000000014901,
          "capacity": 445.2922913611471
      },
      {
          "time_stamp_utc_ms": 1734863370000,
          "time": "2024-12-22T10:33:00Z",
          "energy": 33.52000000048429,
          "capacity": 446.93333333979047
      },
      {
          "time_stamp_utc_ms": 1734863820000,
          "time": "2024-12-22T10:38:00Z",
          "energy": 55.919999999925494,
          "capacity": 447.35999999940395
      },
      {
          "time_stamp_utc_ms": 1734864060000,
          "time": "2024-12-22T10:43:00Z",
          "energy": 29.519999999552965,
          "capacity": 442.7999999932945
      },
      {
          "time_stamp_utc_ms": 1734864375000,
          "time": "2024-12-22T10:48:00Z",
          "energy": 39.480000000447035,
          "capacity": 451.20000000510896
      },
      {
          "time_stamp_utc_ms": 1734864690000,
          "time": "2024-12-22T10:53:00Z",
          "energy": 38.40000000037253,
          "capacity": 438.8571428614003
      },
      {
          "time_stamp_utc_ms": 1734864960000,
          "time": "2024-12-22T10:58:00Z",
          "energy": 34.19999999925494,
          "capacity": 455.9999999900658
      },
      {
          "time_stamp_utc_ms": 1734865260000,
          "time": "2024-12-22T11:03:00Z",
          "energy": 37.200000000186265,
          "capacity": 446.4000000022352
      },
      {
          "time_stamp_utc_ms": 1734865590000,
          "time": "2024-12-22T11:08:00Z",
          "energy": 42.480000000447035,
          "capacity": 463.41818182305855
      },
      {
          "time_stamp_utc_ms": 1734865900000,
          "time": "2024-12-22T11:13:00Z",
          "energy": 38.87999999988824,
          "capacity": 451.5096913081601
      },
      {
          "time_stamp_utc_ms": 1734866180000,
          "time": "2024-12-22T11:18:00Z",
          "energy": 34.080000000074506,
          "capacity": 438.17144349641694
      },
      {
          "time_stamp_utc_ms": 1734866475000,
          "time": "2024-12-22T11:23:00Z",
          "energy": 37.799999999813735,
          "capacity": 461.2881505034443
      },
      {
          "time_stamp_utc_ms": 1734866820000,
          "time": "2024-12-22T11:28:00Z",
          "energy": 42.12000000011176,
          "capacity": 439.51304347942704
      },
      {
          "time_stamp_utc_ms": 1734867450000,
          "time": "2024-12-22T11:38:00Z",
          "energy": 78.95999999996275,
          "capacity": 451.19999999978705
      },
      {
          "time_stamp_utc_ms": 1734867680000,
          "time": "2024-12-22T11:43:00Z",
          "energy": 28.639999999664724,
          "capacity": 448.27827015804513
      },
      {
          "time_stamp_utc_ms": 1734867940000,
          "time": "2024-12-22T11:48:00Z",
          "energy": 32.95999999996275,
          "capacity": 456.369214029192
      },
      {
          "time_stamp_utc_ms": 1734868260000,
          "time": "2024-12-22T11:53:00Z",
          "energy": 38.87999999988824,
          "capacity": 437.3999869632073
      },
      {
          "time_stamp_utc_ms": 1734868540000,
          "time": "2024-12-22T11:58:00Z",
          "energy": 36.00000000093132,
          "capacity": 462.8571586339379
      },
      {
          "time_stamp_utc_ms": 1734868860000,
          "time": "2024-12-22T12:03:00Z",
          "energy": 41.59999999962747,
          "capacity": 467.99998604832257
      },
      {
          "time_stamp_utc_ms": 1734869040000,
          "time": "2024-12-22T12:08:00Z",
          "energy": 23.040000000037253,
          "capacity": 460.80000000074506
      },
      {
          "time_stamp_utc_ms": 1734869460000,
          "time": "2024-12-22T12:13:00Z",
          "energy": 53.760000000707805,
          "capacity": 460.80000000606685
      },
      {
          "time_stamp_utc_ms": 1734869760000,
          "time": "2024-12-22T12:18:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1734870000000,
          "time": "2024-12-22T12:23:00Z",
          "energy": 30.479999999515712,
          "capacity": 457.1999999927357
      },
      {
          "time_stamp_utc_ms": 1734870360000,
          "time": "2024-12-22T12:28:00Z",
          "energy": 45.59999999962747,
          "capacity": 455.9999999962747
      },
      {
          "time_stamp_utc_ms": 1734870720000,
          "time": "2024-12-22T12:33:00Z",
          "energy": 44.880000000819564,
          "capacity": 448.8000000081956
      },
      {
          "time_stamp_utc_ms": 1734871000000,
          "time": "2024-12-22T12:38:00Z",
          "energy": 35.200000000186265,
          "capacity": 452.57144398831497
      },
      {
          "time_stamp_utc_ms": 1734871200000,
          "time": "2024-12-22T12:43:00Z",
          "energy": 24.3199999993667,
          "capacity": 437.76001042561256
      },
      {
          "time_stamp_utc_ms": 1734871590000,
          "time": "2024-12-22T12:48:00Z",
          "energy": 48.480000000447035,
          "capacity": 447.5076923118188
      },
      {
          "time_stamp_utc_ms": 1734871860000,
          "time": "2024-12-22T12:53:00Z",
          "energy": 33.239999999292195,
          "capacity": 443.1999999905626
      },
      {
          "time_stamp_utc_ms": 1734872160000,
          "time": "2024-12-22T12:58:00Z",
          "energy": 36.360000000335276,
          "capacity": 436.32000000402337
      },
      {
          "time_stamp_utc_ms": 1734872520000,
          "time": "2024-12-22T13:03:00Z",
          "energy": 45.28000000026077,
          "capacity": 452.8000000026077
      },
      {
          "time_stamp_utc_ms": 1734872700000,
          "time": "2024-12-22T13:08:00Z",
          "energy": 20.799999999813735,
          "capacity": 415.99999999627465
      },
      {
          "time_stamp_utc_ms": 1734873020000,
          "time": "2024-12-22T13:13:00Z",
          "energy": 40.32000000029802,
          "capacity": 453.59998648501977
      },
      {
          "time_stamp_utc_ms": 1734873390000,
          "time": "2024-12-22T13:18:00Z",
          "energy": 44.55999999959022,
          "capacity": 433.55676792768736
      },
      {
          "time_stamp_utc_ms": 1734873660000,
          "time": "2024-12-22T13:23:00Z",
          "energy": 33.743999999947846,
          "capacity": 449.91999999930454
      },
      {
          "time_stamp_utc_ms": 1734873960000,
          "time": "2024-12-22T13:28:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1734874230000,
          "time": "2024-12-22T13:33:00Z",
          "energy": 32.5679999999702,
          "capacity": 434.23999999960256
      },
      {
          "time_stamp_utc_ms": 1734874540000,
          "time": "2024-12-22T13:38:00Z",
          "energy": 38.12000000011176,
          "capacity": 442.68388458762854
      },
      {
          "time_stamp_utc_ms": 1734874860000,
          "time": "2024-12-22T13:43:00Z",
          "energy": 37.44000000040978,
          "capacity": 421.19998745187223
      },
      {
          "time_stamp_utc_ms": 1734875220000,
          "time": "2024-12-22T13:48:00Z",
          "energy": 43.35999999940395,
          "capacity": 433.59999999403954
      },
      {
          "time_stamp_utc_ms": 1734875520000,
          "time": "2024-12-22T13:53:00Z",
          "energy": 35.83999999985099,
          "capacity": 430.0799999982119
      },
      {
          "time_stamp_utc_ms": 1734875775000,
          "time": "2024-12-22T13:58:00Z",
          "energy": 30.16000000014901,
          "capacity": 425.78823529622133
      },
      {
          "time_stamp_utc_ms": 1734876000000,
          "time": "2024-12-22T14:03:00Z",
          "energy": 26.640000000596046,
          "capacity": 426.24000000953674
      },
      {
          "time_stamp_utc_ms": 1734876380000,
          "time": "2024-12-22T14:08:00Z",
          "energy": 43.200000000186265,
          "capacity": 409.26314762535003
      },
      {
          "time_stamp_utc_ms": 1734876630000,
          "time": "2024-12-22T14:13:00Z",
          "energy": 29.279999999329448,
          "capacity": 421.63201607432904
      },
      {
          "time_stamp_utc_ms": 1734877240000,
          "time": "2024-12-22T14:23:00Z",
          "energy": 71.67999999970198,
          "capacity": 423.02949496767184
      },
      {
          "time_stamp_utc_ms": 1734877580000,
          "time": "2024-12-22T14:28:00Z",
          "energy": 39.200000000186265,
          "capacity": 415.05883517347075
      },
      {
          "time_stamp_utc_ms": 1734877890000,
          "time": "2024-12-22T14:33:00Z",
          "energy": 34.08000000100583,
          "capacity": 395.76775412244
      },
      {
          "time_stamp_utc_ms": 1734878160000,
          "time": "2024-12-22T14:38:00Z",
          "energy": 30.527999999001622,
          "capacity": 407.0399999866883
      },
      {
          "time_stamp_utc_ms": 1734878460000,
          "time": "2024-12-22T14:43:00Z",
          "energy": 33.024000000208616,
          "capacity": 396.28800000250345
      },
      {
          "time_stamp_utc_ms": 1734878760000,
          "time": "2024-12-22T14:48:00Z",
          "energy": 32.44799999985844,
          "capacity": 389.37599999830127
      },
      {
          "time_stamp_utc_ms": 1734879060000,
          "time": "2024-12-22T14:53:00Z",
          "energy": 33.79200000036508,
          "capacity": 405.504000004381
      },
      {
          "time_stamp_utc_ms": 1734879360000,
          "time": "2024-12-22T14:58:00Z",
          "energy": 32.44799999985844,
          "capacity": 389.37599999830127
      },
      {
          "time_stamp_utc_ms": 1734879720000,
          "time": "2024-12-22T15:03:00Z",
          "energy": 41.28000000026077,
          "capacity": 412.80000000260765
      },
      {
          "time_stamp_utc_ms": 1734879960000,
          "time": "2024-12-22T15:08:00Z",
          "energy": 25.679999999701977,
          "capacity": 385.19999999552965
      },
      {
          "time_stamp_utc_ms": 1734880290000,
          "time": "2024-12-22T15:13:00Z",
          "energy": 36.480000000447035,
          "capacity": 397.9636363685131
      },
      {
          "time_stamp_utc_ms": 1734880560000,
          "time": "2024-12-22T15:18:00Z",
          "energy": 29.519999999552965,
          "capacity": 393.59999999403954
      },
      {
          "time_stamp_utc_ms": 1734880770000,
          "time": "2024-12-22T15:23:00Z",
          "energy": 21.359999999403954,
          "capacity": 366.17142856121063
      },
      {
          "time_stamp_utc_ms": 1734881280000,
          "time": "2024-12-22T15:28:00Z",
          "energy": 54.96000000089407,
          "capacity": 387.9529411827816
      },
      {
          "time_stamp_utc_ms": 1734881460000,
          "time": "2024-12-22T15:33:00Z",
          "energy": 18.959999999031425,
          "capacity": 379.1999999806285
      },
      {
          "time_stamp_utc_ms": 1734881760000,
          "time": "2024-12-22T15:38:00Z",
          "energy": 31.920000000856817,
          "capacity": 383.0400000102818
      },
      {
          "time_stamp_utc_ms": 1734882120000,
          "time": "2024-12-22T15:43:00Z",
          "energy": 38.39999999944121,
          "capacity": 383.99999999441206
      },
      {
          "time_stamp_utc_ms": 1734882390000,
          "time": "2024-12-22T15:48:00Z",
          "energy": 27.84000000078231,
          "capacity": 371.2000000104308
      },
      {
          "time_stamp_utc_ms": 1734882660000,
          "time": "2024-12-22T15:53:00Z",
          "energy": 29.279999999329448,
          "capacity": 390.39999999105925
      },
      {
          "time_stamp_utc_ms": 1734882930000,
          "time": "2024-12-22T15:58:00Z",
          "energy": 27.84000000078231,
          "capacity": 371.2000000104308
      },
      {
          "time_stamp_utc_ms": 1734883260000,
          "time": "2024-12-22T16:03:00Z",
          "energy": 33.59999999962747,
          "capacity": 366.5454545413906
      },
      {
          "time_stamp_utc_ms": 1734883540000,
          "time": "2024-12-22T16:08:00Z",
          "energy": 29.440000000409782,
          "capacity": 378.5142986116745
      },
      {
          "time_stamp_utc_ms": 1734883890000,
          "time": "2024-12-22T16:13:00Z",
          "energy": 34.99999999906868,
          "capacity": 359.9999901811994
      },
      {
          "time_stamp_utc_ms": 1734884180000,
          "time": "2024-12-22T16:18:00Z",
          "energy": 30.28000000026077,
          "capacity": 375.8896428143993
      },
      {
          "time_stamp_utc_ms": 1734884370000,
          "time": "2024-12-22T16:23:00Z",
          "energy": 18.559999999590218,
          "capacity": 351.66314906139064
      },
      {
          "time_stamp_utc_ms": 1734884775000,
          "time": "2024-12-22T16:28:00Z",
          "energy": 40.80000000074506,
          "capacity": 362.66666667328934
      },
      {
          "time_stamp_utc_ms": 1734885060000,
          "time": "2024-12-22T16:33:00Z",
          "energy": 28.895999999716878,
          "capacity": 365.0021052595816
      },
      {
          "time_stamp_utc_ms": 1734885360000,
          "time": "2024-12-22T16:38:00Z",
          "energy": 31.007999999448657,
          "capacity": 372.0959999933839
      },
      {
          "time_stamp_utc_ms": 1734885660000,
          "time": "2024-12-22T16:43:00Z",
          "energy": 30.240000000223517,
          "capacity": 362.8800000026822
      },
      {
          "time_stamp_utc_ms": 1734885960000,
          "time": "2024-12-22T16:48:00Z",
          "energy": 27.93599999975413,
          "capacity": 335.23199999704957
      },
      {
          "time_stamp_utc_ms": 1734886230000,
          "time": "2024-12-22T16:53:00Z",
          "energy": 26.760000000707805,
          "capacity": 356.8000000094374
      },
      {
          "time_stamp_utc_ms": 1734886600000,
          "time": "2024-12-22T16:58:00Z",
          "energy": 34.84000000078231,
          "capacity": 338.9837925286965
      },
      {
          "time_stamp_utc_ms": 1734886890000,
          "time": "2024-12-22T17:03:00Z",
          "energy": 27.439999998547137,
          "capacity": 340.6344715387111
      },
      {
          "time_stamp_utc_ms": 1734887175000,
          "time": "2024-12-22T17:08:00Z",
          "energy": 27.12000000104308,
          "capacity": 342.56842106580734
      },
      {
          "time_stamp_utc_ms": 1734887460000,
          "time": "2024-12-22T17:13:00Z",
          "energy": 26.015999999828637,
          "capacity": 328.62315789257224
      },
      {
          "time_stamp_utc_ms": 1734887820000,
          "time": "2024-12-22T17:18:00Z",
          "energy": 33.50399999972433,
          "capacity": 335.0399999972432
      },
      {
          "time_stamp_utc_ms": 1734888080000,
          "time": "2024-12-22T17:23:00Z",
          "energy": 23.83999999985099,
          "capacity": 330.0922955825312
      },
      {
          "time_stamp_utc_ms": 1734888480000,
          "time": "2024-12-22T17:28:00Z",
          "energy": 36.16000000014901,
          "capacity": 325.4400077604355
      },
      {
          "time_stamp_utc_ms": 1734888660000,
          "time": "2024-12-22T17:33:00Z",
          "energy": 16.480000000447035,
          "capacity": 329.6000000089407
      },
      {
          "time_stamp_utc_ms": 1734888900000,
          "time": "2024-12-22T17:38:00Z",
          "energy": 20.799999999813735,
          "capacity": 311.99999999720603
      },
      {
          "time_stamp_utc_ms": 1734889275000,
          "time": "2024-12-22T17:43:00Z",
          "energy": 33.27999999932945,
          "capacity": 319.48799999356265
      },
      {
          "time_stamp_utc_ms": 1734889530000,
          "time": "2024-12-22T17:48:00Z",
          "energy": 22.320000000298023,
          "capacity": 315.1058823571485
      },
      {
          "time_stamp_utc_ms": 1734889920000,
          "time": "2024-12-22T17:53:00Z",
          "energy": 33.83999999985099,
          "capacity": 312.3692307678553
      },
      {
          "time_stamp_utc_ms": 1734890200000,
          "time": "2024-12-22T17:58:00Z",
          "energy": 25.600000000558794,
          "capacity": 329.1428683605809
      },
      {
          "time_stamp_utc_ms": 1734890445000,
          "time": "2024-12-22T18:03:00Z",
          "energy": 21.320000000298023,
          "capacity": 313.2734571978138
      },
      {
          "time_stamp_utc_ms": 1734890760000,
          "time": "2024-12-22T18:08:00Z",
          "energy": 29.16000000014901,
          "capacity": 333.25714285884584
      },
      {
          "time_stamp_utc_ms": 1734891090000,
          "time": "2024-12-22T18:13:00Z",
          "energy": 28.079999999143183,
          "capacity": 306.32727271792567
      },
      {
          "time_stamp_utc_ms": 1734891480000,
          "time": "2024-12-22T18:18:00Z",
          "energy": 36.0,
          "capacity": 332.3076923076923
      },
      {
          "time_stamp_utc_ms": 1734891660000,
          "time": "2024-12-22T18:23:00Z",
          "energy": 15.200000000186265,
          "capacity": 304.0000000037253
      },
      {
          "time_stamp_utc_ms": 1734891960000,
          "time": "2024-12-22T18:28:00Z",
          "energy": 25.919999999925494,
          "capacity": 311.03999999910593
      },
      {
          "time_stamp_utc_ms": 1734892320000,
          "time": "2024-12-22T18:33:00Z",
          "energy": 33.28000000026077,
          "capacity": 332.8000000026077
      },
      {
          "time_stamp_utc_ms": 1734892540000,
          "time": "2024-12-22T18:38:00Z",
          "energy": 18.559999999590218,
          "capacity": 303.70908431966825
      },
      {
          "time_stamp_utc_ms": 1734892800000,
          "time": "2024-12-22T18:43:00Z",
          "energy": 22.0,
          "capacity": 304.6153734421593
      },
      {
          "time_stamp_utc_ms": 1734893220000,
          "time": "2024-12-22T18:48:00Z",
          "energy": 35.75999999977648,
          "capacity": 306.5142857123699
      },
      {
          "time_stamp_utc_ms": 1734893550000,
          "time": "2024-12-22T18:53:00Z",
          "energy": 26.880000000819564,
          "capacity": 293.2363636453043
      },
      {
          "time_stamp_utc_ms": 1734893730000,
          "time": "2024-12-22T18:58:00Z",
          "energy": 15.120000000111759,
          "capacity": 302.4000000022352
      },
      {
          "time_stamp_utc_ms": 1734894080000,
          "time": "2024-12-22T19:03:00Z",
          "energy": 27.91999999899417,
          "capacity": 287.17713502184125
      },
      {
          "time_stamp_utc_ms": 1734894360000,
          "time": "2024-12-22T19:08:00Z",
          "energy": 23.440000000409782,
          "capacity": 301.37143884134724
      },
      {
          "time_stamp_utc_ms": 1734894660000,
          "time": "2024-12-22T19:13:00Z",
          "energy": 24.240000000223517,
          "capacity": 290.8800000026822
      },
      {
          "time_stamp_utc_ms": 1734894980000,
          "time": "2024-12-22T19:18:00Z",
          "energy": 26.079999999143183,
          "capacity": 293.39999124635966
      },
      {
          "time_stamp_utc_ms": 1734895260000,
          "time": "2024-12-22T19:23:00Z",
          "energy": 24.800000000745058,
          "capacity": 318.8571537269321
      },
      {
          "time_stamp_utc_ms": 1734895500000,
          "time": "2024-12-22T19:28:00Z",
          "energy": 19.999999999068677,
          "capacity": 299.99999998603016
      },
      {
          "time_stamp_utc_ms": 1734895800000,
          "time": "2024-12-22T19:33:00Z",
          "energy": 25.12000000104308,
          "capacity": 301.440000012517
      },
      {
          "time_stamp_utc_ms": 1734896250000,
          "time": "2024-12-22T19:38:00Z",
          "energy": 36.71999999973923,
          "capacity": 293.75999999791384
      },
      {
          "time_stamp_utc_ms": 1734896430000,
          "time": "2024-12-22T19:43:00Z",
          "energy": 15.599999999627471,
          "capacity": 311.99999999254936
      },
      {
          "time_stamp_utc_ms": 1734896760000,
          "time": "2024-12-22T19:48:00Z",
          "energy": 29.28000000026077,
          "capacity": 319.4181818210266
      },
      {
          "time_stamp_utc_ms": 1734897080000,
          "time": "2024-12-22T19:53:00Z",
          "energy": 27.040000000037253,
          "capacity": 304.1999909345529
      },
      {
          "time_stamp_utc_ms": 1734897330000,
          "time": "2024-12-22T19:58:00Z",
          "energy": 23.0,
          "capacity": 331.2000126342778
      },
      {
          "time_stamp_utc_ms": 1734897660000,
          "time": "2024-12-22T20:03:00Z",
          "energy": 28.87199999950826,
          "capacity": 314.96727272190833
      },
      {
          "time_stamp_utc_ms": 1734897960000,
          "time": "2024-12-22T20:08:00Z",
          "energy": 28.032000000588596,
          "capacity": 336.3840000070632
      },
      {
          "time_stamp_utc_ms": 1734898260000,
          "time": "2024-12-22T20:13:00Z",
          "energy": 28.320000000298023,
          "capacity": 339.84000000357634
      },
      {
          "time_stamp_utc_ms": 1734898560000,
          "time": "2024-12-22T20:18:00Z",
          "energy": 28.511999999172986,
          "capacity": 342.1439999900758
      },
      {
          "time_stamp_utc_ms": 1734898860000,
          "time": "2024-12-22T20:23:00Z",
          "energy": 30.528000000864267,
          "capacity": 366.3360000103712
      },
      {
          "time_stamp_utc_ms": 1734899160000,
          "time": "2024-12-22T20:28:00Z",
          "energy": 27.83999999985099,
          "capacity": 334.07999999821186
      },
      {
          "time_stamp_utc_ms": 1734899460000,
          "time": "2024-12-22T20:33:00Z",
          "energy": 28.703999999910593,
          "capacity": 344.4479999989272
      },
      {
          "time_stamp_utc_ms": 1734899760000,
          "time": "2024-12-22T20:38:00Z",
          "energy": 28.512000000104308,
          "capacity": 342.14400000125175
      },
      {
          "time_stamp_utc_ms": 1734900060000,
          "time": "2024-12-22T20:43:00Z",
          "energy": 28.415999999269843,
          "capacity": 340.9919999912381
      },
      {
          "time_stamp_utc_ms": 1734900360000,
          "time": "2024-12-22T20:48:00Z",
          "energy": 29.759999999776483,
          "capacity": 357.11999999731785
      },
      {
          "time_stamp_utc_ms": 1734900630000,
          "time": "2024-12-22T20:53:00Z",
          "energy": 26.78400000091642,
          "capacity": 357.1200000122189
      },
      {
          "time_stamp_utc_ms": 1734900940000,
          "time": "2024-12-22T20:58:00Z",
          "energy": 31.519999999552965,
          "capacity": 366.0387209329288
      },
      {
          "time_stamp_utc_ms": 1734901380000,
          "time": "2024-12-22T21:03:00Z",
          "energy": 45.28000000026077,
          "capacity": 370.4727192450809
      },
      {
          "time_stamp_utc_ms": 1734901530000,
          "time": "2024-12-22T21:08:00Z",
          "energy": 15.599999999627471,
          "capacity": 374.3999999910593
      },
      {
          "time_stamp_utc_ms": 1734901890000,
          "time": "2024-12-22T21:13:00Z",
          "energy": 38.64000000059605,
          "capacity": 386.4000000059604
      },
      {
          "time_stamp_utc_ms": 1734902160000,
          "time": "2024-12-22T21:18:00Z",
          "energy": 27.648000000044703,
          "capacity": 368.640000000596
      },
      {
          "time_stamp_utc_ms": 1734902550000,
          "time": "2024-12-22T21:23:00Z",
          "energy": 40.99200000055134,
          "capacity": 378.38769231278167
      },
      {
          "time_stamp_utc_ms": 1734902820000,
          "time": "2024-12-22T21:28:00Z",
          "energy": 27.359999999403954,
          "capacity": 364.79999999205273
      },
      {
          "time_stamp_utc_ms": 1734903090000,
          "time": "2024-12-22T21:33:00Z",
          "energy": 29.03999999910593,
          "capacity": 387.19999998807907
      },
      {
          "time_stamp_utc_ms": 1734903360000,
          "time": "2024-12-22T21:38:00Z",
          "energy": 29.424000000581145,
          "capacity": 392.3200000077486
      },
      {
          "time_stamp_utc_ms": 1734903570000,
          "time": "2024-12-22T21:43:00Z",
          "energy": 20.975999999791384,
          "capacity": 359.5885714249951
      },
      {
          "time_stamp_utc_ms": 1734903990000,
          "time": "2024-12-22T21:48:00Z",
          "energy": 44.40000000037253,
          "capacity": 380.5714285746217
      },
      {
          "time_stamp_utc_ms": 1734904200000,
          "time": "2024-12-22T21:53:00Z",
          "energy": 21.600000000558794,
          "capacity": 370.2857142952936
      },
      {
          "time_stamp_utc_ms": 1734904520000,
          "time": "2024-12-22T21:58:00Z",
          "energy": 33.43999999854714,
          "capacity": 376.199988772022
      },
      {
          "time_stamp_utc_ms": 1734904880000,
          "time": "2024-12-22T22:03:00Z",
          "energy": 38.56000000145286,
          "capacity": 385.6000000145286
      },
      {
          "time_stamp_utc_ms": 1734905130000,
          "time": "2024-12-22T22:08:00Z",
          "energy": 25.199999999254942,
          "capacity": 362.8800138320451
      },
      {
          "time_stamp_utc_ms": 1734905475000,
          "time": "2024-12-22T22:13:00Z",
          "energy": 38.16000000014901,
          "capacity": 398.19130434938097
      },
      {
          "time_stamp_utc_ms": 1734905760000,
          "time": "2024-12-22T22:18:00Z",
          "energy": 30.240000000223517,
          "capacity": 381.97894737124443
      },
      {
          "time_stamp_utc_ms": 1734906100000,
          "time": "2024-12-22T22:23:00Z",
          "energy": 37.28000000026077,
          "capacity": 394.7294228393291
      },
      {
          "time_stamp_utc_ms": 1734906360000,
          "time": "2024-12-22T22:28:00Z",
          "energy": 27.519999999552965,
          "capacity": 381.0461398632751
      },
      {
          "time_stamp_utc_ms": 1734906660000,
          "time": "2024-12-22T22:33:00Z",
          "energy": 31.200000000186265,
          "capacity": 374.4000000022352
      },
      {
          "time_stamp_utc_ms": 1734906980000,
          "time": "2024-12-22T22:38:00Z",
          "energy": 34.080000000074506,
          "capacity": 383.39998857462814
      },
      {
          "time_stamp_utc_ms": 1734907245000,
          "time": "2024-12-22T22:43:00Z",
          "energy": 27.359999999403954,
          "capacity": 371.6830322358485
      },
      {
          "time_stamp_utc_ms": 1734907545000,
          "time": "2024-12-22T22:48:00Z",
          "energy": 31.920000000856817,
          "capacity": 383.0400000102818
      },
      {
          "time_stamp_utc_ms": 1734907820000,
          "time": "2024-12-22T22:53:00Z",
          "energy": 30.639999999664724,
          "capacity": 401.10544063110336
      },
      {
          "time_stamp_utc_ms": 1734908220000,
          "time": "2024-12-22T22:58:00Z",
          "energy": 44.0,
          "capacity": 396.00000944137594
      },
      {
          "time_stamp_utc_ms": 1734908420000,
          "time": "2024-12-22T23:03:00Z",
          "energy": 23.360000000335276,
          "capacity": 420.48001003105963
      },
      {
          "time_stamp_utc_ms": 1734908790000,
          "time": "2024-12-22T23:08:00Z",
          "energy": 41.43999999947846,
          "capacity": 403.2000103873984
      },
      {
          "time_stamp_utc_ms": 1734909120000,
          "time": "2024-12-22T23:13:00Z",
          "energy": 37.67999999970198,
          "capacity": 411.0545454512943
      },
      {
          "time_stamp_utc_ms": 1734909360000,
          "time": "2024-12-22T23:18:00Z",
          "energy": 27.59999999962747,
          "capacity": 413.99999999441206
      },
      {
          "time_stamp_utc_ms": 1734909660000,
          "time": "2024-12-22T23:23:00Z",
          "energy": 33.60000000055879,
          "capacity": 403.2000000067056
      },
      {
          "time_stamp_utc_ms": 1734909900000,
          "time": "2024-12-22T23:28:00Z",
          "energy": 28.799999999813735,
          "capacity": 431.99999999720603
      },
      {
          "time_stamp_utc_ms": 1734910260000,
          "time": "2024-12-22T23:33:00Z",
          "energy": 40.70399999991059,
          "capacity": 407.03999999910593
      },
      {
          "time_stamp_utc_ms": 1734910500000,
          "time": "2024-12-22T23:38:00Z",
          "energy": 27.936000000685453,
          "capacity": 419.0400000102818
      },
      {
          "time_stamp_utc_ms": 1734910920000,
          "time": "2024-12-22T23:43:00Z",
          "energy": 48.0,
          "capacity": 411.4285714285714
      },
      {
          "time_stamp_utc_ms": 1734911160000,
          "time": "2024-12-22T23:48:00Z",
          "energy": 27.359999999403954,
          "capacity": 410.3999999910593
      },
      {
          "time_stamp_utc_ms": 1734911400000,
          "time": "2024-12-22T23:53:00Z",
          "energy": 28.480000000447035,
          "capacity": 427.2000000067055
      },
      {
          "time_stamp_utc_ms": 1734911640000,
          "time": "2024-12-22T23:58:00Z",
          "energy": 26.71999999973923,
          "capacity": 400.79999999608845
      },
      {
          "time_stamp_utc_ms": 1734912100000,
          "time": "2024-12-23T00:03:00Z",
          "energy": 53.59999999962747,
          "capacity": 419.4782695632944
      },
      {
          "time_stamp_utc_ms": 1734912420000,
          "time": "2024-12-23T00:08:00Z",
          "energy": 36.880000000819564,
          "capacity": 414.89998764423694
      },
      {
          "time_stamp_utc_ms": 1734912600000,
          "time": "2024-12-23T00:13:00Z",
          "energy": 20.87999999988824,
          "capacity": 417.5999999977648
      },
      {
          "time_stamp_utc_ms": 1734912980000,
          "time": "2024-12-23T00:18:00Z",
          "energy": 44.799999999813735,
          "capacity": 424.42104197824995
      },
      {
          "time_stamp_utc_ms": 1734913200000,
          "time": "2024-12-23T00:23:00Z",
          "energy": 25.759999999776483,
          "capacity": 421.5272635872578
      },
      {
          "time_stamp_utc_ms": 1734913590000,
          "time": "2024-12-23T00:28:00Z",
          "energy": 46.32000000029802,
          "capacity": 427.5692307719818
      },
      {
          "time_stamp_utc_ms": 1734913880000,
          "time": "2024-12-23T00:33:00Z",
          "energy": 33.200000000186265,
          "capacity": 412.1379174835072
      },
      {
          "time_stamp_utc_ms": 1734914160000,
          "time": "2024-12-23T00:38:00Z",
          "energy": 32.15999999921769,
          "capacity": 413.485728358896
      },
      {
          "time_stamp_utc_ms": 1734914440000,
          "time": "2024-12-23T00:43:00Z",
          "energy": 32.80000000074506,
          "capacity": 421.7143000873685
      },
      {
          "time_stamp_utc_ms": 1734914740000,
          "time": "2024-12-23T00:48:00Z",
          "energy": 33.75999999977648,
          "capacity": 405.1199999973178
      },
      {
          "time_stamp_utc_ms": 1734915000000,
          "time": "2024-12-23T00:53:00Z",
          "energy": 31.040000000037253,
          "capacity": 429.784599620726
      },
      {
          "time_stamp_utc_ms": 1734915360000,
          "time": "2024-12-23T00:58:00Z",
          "energy": 41.183999999426305,
          "capacity": 411.83999999426305
      },
      {
          "time_stamp_utc_ms": 1734915660000,
          "time": "2024-12-23T01:03:00Z",
          "energy": 35.80799999926239,
          "capacity": 429.6959999911487
      },
      {
          "time_stamp_utc_ms": 1734915960000,
          "time": "2024-12-23T01:08:00Z",
          "energy": 38.880000001750886,
          "capacity": 466.56000002101064
      },
      {
          "time_stamp_utc_ms": 1734916260000,
          "time": "2024-12-23T01:13:00Z",
          "energy": 35.42399999964982,
          "capacity": 425.0879999957979
      },
      {
          "time_stamp_utc_ms": 1734916560000,
          "time": "2024-12-23T01:18:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1734916860000,
          "time": "2024-12-23T01:23:00Z",
          "energy": 34.4640000006184,
          "capacity": 413.5680000074208
      },
      {
          "time_stamp_utc_ms": 1734917160000,
          "time": "2024-12-23T01:28:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1734917460000,
          "time": "2024-12-23T01:33:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1734917760000,
          "time": "2024-12-23T01:38:00Z",
          "energy": 35.04000000003725,
          "capacity": 420.48000000044703
      },
      {
          "time_stamp_utc_ms": 1734918060000,
          "time": "2024-12-23T01:43:00Z",
          "energy": 35.4239999987185,
          "capacity": 425.08799998462206
      },
      {
          "time_stamp_utc_ms": 1734918360000,
          "time": "2024-12-23T01:48:00Z",
          "energy": 34.368000000715256,
          "capacity": 412.41600000858307
      },
      {
          "time_stamp_utc_ms": 1734918660000,
          "time": "2024-12-23T01:53:00Z",
          "energy": 35.80799999926239,
          "capacity": 429.6959999911487
      },
      {
          "time_stamp_utc_ms": 1734918960000,
          "time": "2024-12-23T01:58:00Z",
          "energy": 35.23200000077486,
          "capacity": 422.7840000092983
      },
      {
          "time_stamp_utc_ms": 1734919230000,
          "time": "2024-12-23T02:03:00Z",
          "energy": 32.20799999963492,
          "capacity": 429.4399999951322
      },
      {
          "time_stamp_utc_ms": 1734919620000,
          "time": "2024-12-23T02:08:00Z",
          "energy": 46.32000000029802,
          "capacity": 427.5692307719818
      },
      {
          "time_stamp_utc_ms": 1734919840000,
          "time": "2024-12-23T02:13:00Z",
          "energy": 25.759999999776483,
          "capacity": 421.5272635872578
      },
      {
          "time_stamp_utc_ms": 1734920130000,
          "time": "2024-12-23T02:18:00Z",
          "energy": 34.72000000067055,
          "capacity": 431.00688238624844
      },
      {
          "time_stamp_utc_ms": 1734920430000,
          "time": "2024-12-23T02:23:00Z",
          "energy": 35.27999999932945,
          "capacity": 423.3599999919534
      },
      {
          "time_stamp_utc_ms": 1734920700000,
          "time": "2024-12-23T02:28:00Z",
          "energy": 32.40000000037253,
          "capacity": 432.00000000496703
      },
      {
          "time_stamp_utc_ms": 1734921060000,
          "time": "2024-12-23T02:33:00Z",
          "energy": 44.0,
          "capacity": 440.0
      },
      {
          "time_stamp_utc_ms": 1734921360000,
          "time": "2024-12-23T02:38:00Z",
          "energy": 34.72000000067055,
          "capacity": 416.6400000080466
      },
      {
          "time_stamp_utc_ms": 1734921690000,
          "time": "2024-12-23T02:43:00Z",
          "energy": 40.3199999993667,
          "capacity": 439.8545454476367
      },
      {
          "time_stamp_utc_ms": 1734922050000,
          "time": "2024-12-23T02:48:00Z",
          "energy": 41.28000000026077,
          "capacity": 412.80000000260765
      },
      {
          "time_stamp_utc_ms": 1734922170000,
          "time": "2024-12-23T02:53:00Z",
          "energy": 15.359999999403954,
          "capacity": 460.7999999821186
      },
      {
          "time_stamp_utc_ms": 1734922560000,
          "time": "2024-12-23T02:58:00Z",
          "energy": 48.0,
          "capacity": 443.0769230769231
      },
      {
          "time_stamp_utc_ms": 1734922830000,
          "time": "2024-12-23T03:03:00Z",
          "energy": 31.440000000409782,
          "capacity": 419.2000000054637
      },
      {
          "time_stamp_utc_ms": 1734923190000,
          "time": "2024-12-23T03:08:00Z",
          "energy": 44.639999999664724,
          "capacity": 446.39999999664724
      },
      {
          "time_stamp_utc_ms": 1734923370000,
          "time": "2024-12-23T03:13:00Z",
          "energy": 20.880000000819564,
          "capacity": 417.6000000163913
      },
      {
          "time_stamp_utc_ms": 1734923740000,
          "time": "2024-12-23T03:18:00Z",
          "energy": 44.799999999813735,
          "capacity": 435.89190312518537
      },
      {
          "time_stamp_utc_ms": 1734924040000,
          "time": "2024-12-23T03:23:00Z",
          "energy": 35.99999999906868,
          "capacity": 431.99999998882413
      },
      {
          "time_stamp_utc_ms": 1734924375000,
          "time": "2024-12-23T03:28:00Z",
          "energy": 40.52000000141561,
          "capacity": 435.43879358933805
      },
      {
          "time_stamp_utc_ms": 1734924750000,
          "time": "2024-12-23T03:33:00Z",
          "energy": 44.99999999906868,
          "capacity": 431.9999999910593
      },
      {
          "time_stamp_utc_ms": 1734925080000,
          "time": "2024-12-23T03:38:00Z",
          "energy": 39.12000000011176,
          "capacity": 426.7636363648556
      },
      {
          "time_stamp_utc_ms": 1734925140000,
          "time": "2024-12-23T03:43:00Z",
          "energy": 7.2000000001862645,
          "capacity": 432.00000001117587
      },
      {
          "time_stamp_utc_ms": 1734925520000,
          "time": "2024-12-23T03:48:00Z",
          "energy": 45.60000000055879,
          "capacity": 431.99998916352297
      },
      {
          "time_stamp_utc_ms": 1734925770000,
          "time": "2024-12-23T03:53:00Z",
          "energy": 30.239999999292195,
          "capacity": 435.45601660113635
      },
      {
          "time_stamp_utc_ms": 1734926130000,
          "time": "2024-12-23T03:58:00Z",
          "energy": 43.919999999925494,
          "capacity": 439.1999999992549
      },
      {
          "time_stamp_utc_ms": 1734926460000,
          "time": "2024-12-23T04:03:00Z",
          "energy": 40.080000000074506,
          "capacity": 437.2363636371764
      },
      {
          "time_stamp_utc_ms": 1734926720000,
          "time": "2024-12-23T04:08:00Z",
          "energy": 32.15999999921769,
          "capacity": 445.2922913482518
      },
      {
          "time_stamp_utc_ms": 1734927060000,
          "time": "2024-12-23T04:13:00Z",
          "energy": 40.440000001341105,
          "capacity": 428.1882473186745
      },
      {
          "time_stamp_utc_ms": 1734927380000,
          "time": "2024-12-23T04:18:00Z",
          "energy": 39.239999999292195,
          "capacity": 441.44998683580235
      },
      {
          "time_stamp_utc_ms": 1734927660000,
          "time": "2024-12-23T04:23:00Z",
          "energy": 34.080000000074506,
          "capacity": 438.17144349641694
      },
      {
          "time_stamp_utc_ms": 1734928080000,
          "time": "2024-12-23T04:28:00Z",
          "energy": 50.87999999988824,
          "capacity": 436.1142857133278
      },
      {
          "time_stamp_utc_ms": 1734928260000,
          "time": "2024-12-23T04:33:00Z",
          "energy": 22.27199999988079,
          "capacity": 445.4399999976158
      },
      {
          "time_stamp_utc_ms": 1734928560000,
          "time": "2024-12-23T04:38:00Z",
          "energy": 36.60800000000745,
          "capacity": 439.2960000000894
      },
      {
          "time_stamp_utc_ms": 1734928900000,
          "time": "2024-12-23T04:43:00Z",
          "energy": 41.59999999962747,
          "capacity": 440.4706005862173
      },
      {
          "time_stamp_utc_ms": 1734929190000,
          "time": "2024-12-23T04:48:00Z",
          "energy": 35.12000000011176,
          "capacity": 435.9723994573984
      },
      {
          "time_stamp_utc_ms": 1734929430000,
          "time": "2024-12-23T04:53:00Z",
          "energy": 29.040000000968575,
          "capacity": 435.60000001452863
      },
      {
          "time_stamp_utc_ms": 1734929760000,
          "time": "2024-12-23T04:58:00Z",
          "energy": 40.3199999993667,
          "capacity": 439.8545454476367
      },
      {
          "time_stamp_utc_ms": 1734930060000,
          "time": "2024-12-23T05:03:00Z",
          "energy": 34.55999999959022,
          "capacity": 414.7199999950827
      },
      {
          "time_stamp_utc_ms": 1734930240000,
          "time": "2024-12-23T05:08:00Z",
          "energy": 22.56000000052154,
          "capacity": 451.20000001043076
      },
      {
          "time_stamp_utc_ms": 1734930630000,
          "time": "2024-12-23T05:13:00Z",
          "energy": 47.04000000003725,
          "capacity": 434.2153846157285
      },
      {
          "time_stamp_utc_ms": 1734930930000,
          "time": "2024-12-23T05:18:00Z",
          "energy": 36.71999999973923,
          "capacity": 440.6399999968708
      },
      {
          "time_stamp_utc_ms": 1734931240000,
          "time": "2024-12-23T05:23:00Z",
          "energy": 38.80000000074506,
          "capacity": 450.58065903146525
      },
      {
          "time_stamp_utc_ms": 1734931560000,
          "time": "2024-12-23T05:28:00Z",
          "energy": 38.39999999944121,
          "capacity": 431.9999871191107
      },
      {
          "time_stamp_utc_ms": 1734931830000,
          "time": "2024-12-23T05:33:00Z",
          "energy": 33.919999999925494,
          "capacity": 452.26666666567326
      },
      {
          "time_stamp_utc_ms": 1734932220000,
          "time": "2024-12-23T05:38:00Z",
          "energy": 46.080000000074506,
          "capacity": 425.3538461545339
      },
      {
          "time_stamp_utc_ms": 1734932580000,
          "time": "2024-12-23T05:43:00Z",
          "energy": 44.639999999664724,
          "capacity": 446.39999999664724
      },
      {
          "time_stamp_utc_ms": 1734932850000,
          "time": "2024-12-23T05:48:00Z",
          "energy": 31.440000000409782,
          "capacity": 419.2000000054637
      },
      {
          "time_stamp_utc_ms": 1734933150000,
          "time": "2024-12-23T05:53:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1734933360000,
          "time": "2024-12-23T05:58:00Z",
          "energy": 25.200000000186265,
          "capacity": 432.00000000319307
      },
      {
          "time_stamp_utc_ms": 1734933660000,
          "time": "2024-12-23T06:03:00Z",
          "energy": 35.03999999910593,
          "capacity": 420.4799999892712
      },
      {
          "time_stamp_utc_ms": 1734933980000,
          "time": "2024-12-23T06:08:00Z",
          "energy": 39.040000000968575,
          "capacity": 439.19998692171686
      },
      {
          "time_stamp_utc_ms": 1734934280000,
          "time": "2024-12-23T06:13:00Z",
          "energy": 35.83999999985099,
          "capacity": 430.0799999982119
      },
      {
          "time_stamp_utc_ms": 1734934545000,
          "time": "2024-12-23T06:18:00Z",
          "energy": 31.559999999590218,
          "capacity": 428.73963806530037
      },
      {
          "time_stamp_utc_ms": 1734934770000,
          "time": "2024-12-23T06:23:00Z",
          "energy": 27.480000000447035,
          "capacity": 439.68000000715256
      },
      {
          "time_stamp_utc_ms": 1734935100000,
          "time": "2024-12-23T06:28:00Z",
          "energy": 39.52000000048429,
          "capacity": 431.12727273255587
      },
      {
          "time_stamp_utc_ms": 1734935460000,
          "time": "2024-12-23T06:33:00Z",
          "energy": 43.32799999974668,
          "capacity": 433.2799999974668
      },
      {
          "time_stamp_utc_ms": 1734935760000,
          "time": "2024-12-23T06:38:00Z",
          "energy": 36.671999999322,
          "capacity": 440.06399999186397
      },
      {
          "time_stamp_utc_ms": 1734936030000,
          "time": "2024-12-23T06:43:00Z",
          "energy": 31.6800000006333,
          "capacity": 422.40000000844395
      },
      {
          "time_stamp_utc_ms": 1734936720000,
          "time": "2024-12-23T06:53:00Z",
          "energy": 86.87999999988824,
          "capacity": 453.286956521156
      },
      {
          "time_stamp_utc_ms": 1734937050000,
          "time": "2024-12-23T06:58:00Z",
          "energy": 42.0,
          "capacity": 458.1818181818182
      },
      {
          "time_stamp_utc_ms": 1734937320000,
          "time": "2024-12-23T07:03:00Z",
          "energy": 31.679999999701977,
          "capacity": 422.39999999602634
      },
      {
          "time_stamp_utc_ms": 1734937540000,
          "time": "2024-12-23T07:08:00Z",
          "energy": 26.479999999515712,
          "capacity": 433.30908150944475
      },
      {
          "time_stamp_utc_ms": 1734938100000,
          "time": "2024-12-23T07:18:00Z",
          "energy": 66.56000000052154,
          "capacity": 427.88572886276813
      },
      {
          "time_stamp_utc_ms": 1734938440000,
          "time": "2024-12-23T07:23:00Z",
          "energy": 41.919999999925494,
          "capacity": 443.85883597852796
      },
      {
          "time_stamp_utc_ms": 1734938730000,
          "time": "2024-12-23T07:28:00Z",
          "energy": 34.24000000022352,
          "capacity": 425.0482618869951
      },
      {
          "time_stamp_utc_ms": 1734939120000,
          "time": "2024-12-23T07:33:00Z",
          "energy": 47.35999999940395,
          "capacity": 437.1692307637288
      },
      {
          "time_stamp_utc_ms": 1734939360000,
          "time": "2024-12-23T07:38:00Z",
          "energy": 28.096000000834465,
          "capacity": 421.440000012517
      },
      {
          "time_stamp_utc_ms": 1734939660000,
          "time": "2024-12-23T07:43:00Z",
          "energy": 35.52000000048429,
          "capacity": 426.24000000581145
      },
      {
          "time_stamp_utc_ms": 1734939960000,
          "time": "2024-12-23T07:48:00Z",
          "energy": 36.575999999418855,
          "capacity": 438.91199999302626
      },
      {
          "time_stamp_utc_ms": 1734940260000,
          "time": "2024-12-23T07:53:00Z",
          "energy": 35.61599999945611,
          "capacity": 427.3919999934733
      },
      {
          "time_stamp_utc_ms": 1734940560000,
          "time": "2024-12-23T07:58:00Z",
          "energy": 36.28800000064075,
          "capacity": 435.456000007689
      },
      {
          "time_stamp_utc_ms": 1734940860000,
          "time": "2024-12-23T08:03:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1734941160000,
          "time": "2024-12-23T08:08:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1734941460000,
          "time": "2024-12-23T08:13:00Z",
          "energy": 35.903999999165535,
          "capacity": 430.8479999899864
      },
      {
          "time_stamp_utc_ms": 1734941760000,
          "time": "2024-12-23T08:18:00Z",
          "energy": 35.61600000131875,
          "capacity": 427.39200001582503
      },
      {
          "time_stamp_utc_ms": 1734942060000,
          "time": "2024-12-23T08:23:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1734942360000,
          "time": "2024-12-23T08:28:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1734942660000,
          "time": "2024-12-23T08:33:00Z",
          "energy": 35.80799999926239,
          "capacity": 429.6959999911487
      },
      {
          "time_stamp_utc_ms": 1734942960000,
          "time": "2024-12-23T08:38:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1734943230000,
          "time": "2024-12-23T08:43:00Z",
          "energy": 31.799999999813735,
          "capacity": 423.9999999975164
      },
      {
          "time_stamp_utc_ms": 1734943530000,
          "time": "2024-12-23T08:48:00Z",
          "energy": 36.35999999940395,
          "capacity": 436.31999999284744
      },
      {
          "time_stamp_utc_ms": 1734944460000,
          "time": "2024-12-23T09:03:00Z",
          "energy": 112.80000000074506,
          "capacity": 436.6451612932067
      },
      {
          "time_stamp_utc_ms": 1734945300000,
          "time": "2024-12-23T09:18:00Z",
          "energy": 102.2399999992922,
          "capacity": 438.1714285683951
      },
      {
          "time_stamp_utc_ms": 1734945840000,
          "time": "2024-12-23T09:28:00Z",
          "energy": 68.16000000014901,
          "capacity": 454.4000000009934
      },
      {
          "time_stamp_utc_ms": 1734946140000,
          "time": "2024-12-23T09:33:00Z",
          "energy": 35.52000000048429,
          "capacity": 426.24000000581145
      },
      {
          "time_stamp_utc_ms": 1734948120000,
          "time": "2024-12-23T10:03:00Z",
          "energy": 248.1599999992177,
          "capacity": 451.19999999857765
      },
      {
          "time_stamp_utc_ms": 1734948480000,
          "time": "2024-12-23T10:08:00Z",
          "energy": 49.92000000085682,
          "capacity": 499.2000000085681
      },
      {
          "time_stamp_utc_ms": 1734949440000,
          "time": "2024-12-23T10:28:00Z",
          "energy": 135.35999999940395,
          "capacity": 507.5999999977648
      },
      {
          "time_stamp_utc_ms": 1734951600000,
          "time": "2024-12-23T11:03:00Z",
          "energy": 304.79999999981374,
          "capacity": 507.9999999996895
      },
      {
          "time_stamp_utc_ms": 1734956040000,
          "time": "2024-12-23T12:18:00Z",
          "energy": 641.2800000002608,
          "capacity": 519.9567567569683
      },
      {
          "time_stamp_utc_ms": 1734957600000,
          "time": "2024-12-23T12:43:00Z",
          "energy": 229.44000000040978,
          "capacity": 529.4769230778687
      },
      {
          "time_stamp_utc_ms": 1734959040000,
          "time": "2024-12-23T13:08:00Z",
          "energy": 211.19999999925494,
          "capacity": 527.9999999981374
      },
      {
          "time_stamp_utc_ms": 1734959460000,
          "time": "2024-12-23T13:13:00Z",
          "energy": 60.96000000089407,
          "capacity": 522.5142857219491
      },
      {
          "time_stamp_utc_ms": 1734959880000,
          "time": "2024-12-23T13:18:00Z",
          "energy": 60.95999999996275,
          "capacity": 522.5142857139664
      },
      {
          "time_stamp_utc_ms": 1734960780000,
          "time": "2024-12-23T13:33:00Z",
          "energy": 128.1599999992177,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1734960900000,
          "time": "2024-12-23T13:38:00Z",
          "energy": 17.28000000026077,
          "capacity": 518.4000000078231
      },
      {
          "time_stamp_utc_ms": 1734964560000,
          "time": "2024-12-23T14:38:00Z",
          "energy": 518.8799999998882,
          "capacity": 510.3737704916934
      },
      {
          "time_stamp_utc_ms": 1734965790000,
          "time": "2024-12-23T14:58:00Z",
          "energy": 167.40000000037253,
          "capacity": 489.9512195132855
      },
      {
          "time_stamp_utc_ms": 1734966060000,
          "time": "2024-12-23T15:03:00Z",
          "energy": 35.735999999567866,
          "capacity": 476.47999999423814
      },
      {
          "time_stamp_utc_ms": 1734966360000,
          "time": "2024-12-23T15:08:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1734966660000,
          "time": "2024-12-23T15:13:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1734966960000,
          "time": "2024-12-23T15:18:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1734967260000,
          "time": "2024-12-23T15:23:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1734967560000,
          "time": "2024-12-23T15:28:00Z",
          "energy": 37.92000000085682,
          "capacity": 455.04000001028186
      },
      {
          "time_stamp_utc_ms": 1734967860000,
          "time": "2024-12-23T15:33:00Z",
          "energy": 38.11199999880046,
          "capacity": 457.3439999856055
      },
      {
          "time_stamp_utc_ms": 1734968160000,
          "time": "2024-12-23T15:38:00Z",
          "energy": 37.2480000006035,
          "capacity": 446.976000007242
      },
      {
          "time_stamp_utc_ms": 1734968460000,
          "time": "2024-12-23T15:43:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1734968760000,
          "time": "2024-12-23T15:48:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1734969060000,
          "time": "2024-12-23T15:53:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1734969360000,
          "time": "2024-12-23T15:58:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1734969660000,
          "time": "2024-12-23T16:03:00Z",
          "energy": 36.67200000118464,
          "capacity": 440.06400001421576
      },
      {
          "time_stamp_utc_ms": 1734969960000,
          "time": "2024-12-23T16:08:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1734970260000,
          "time": "2024-12-23T16:13:00Z",
          "energy": 36.096000000834465,
          "capacity": 433.1520000100136
      },
      {
          "time_stamp_utc_ms": 1734970560000,
          "time": "2024-12-23T16:18:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1734970860000,
          "time": "2024-12-23T16:23:00Z",
          "energy": 35.04000000003725,
          "capacity": 420.48000000044703
      },
      {
          "time_stamp_utc_ms": 1734971160000,
          "time": "2024-12-23T16:28:00Z",
          "energy": 35.424000000581145,
          "capacity": 425.08800000697374
      },
      {
          "time_stamp_utc_ms": 1734971460000,
          "time": "2024-12-23T16:33:00Z",
          "energy": 35.32799999881536,
          "capacity": 423.93599998578435
      },
      {
          "time_stamp_utc_ms": 1734971760000,
          "time": "2024-12-23T16:38:00Z",
          "energy": 35.328000000678,
          "capacity": 423.93600000813603
      },
      {
          "time_stamp_utc_ms": 1734972060000,
          "time": "2024-12-23T16:43:00Z",
          "energy": 35.61599999945611,
          "capacity": 427.3919999934733
      },
      {
          "time_stamp_utc_ms": 1734972360000,
          "time": "2024-12-23T16:48:00Z",
          "energy": 35.52000000048429,
          "capacity": 426.24000000581145
      },
      {
          "time_stamp_utc_ms": 1734972660000,
          "time": "2024-12-23T16:53:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1734972960000,
          "time": "2024-12-23T16:58:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1734973260000,
          "time": "2024-12-23T17:03:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1734973560000,
          "time": "2024-12-23T17:08:00Z",
          "energy": 34.27199999988079,
          "capacity": 411.26399999856955
      },
      {
          "time_stamp_utc_ms": 1734973860000,
          "time": "2024-12-23T17:13:00Z",
          "energy": 32.83200000040233,
          "capacity": 393.98400000482803
      },
      {
          "time_stamp_utc_ms": 1734974160000,
          "time": "2024-12-23T17:18:00Z",
          "energy": 33.60000000055879,
          "capacity": 403.2000000067056
      },
      {
          "time_stamp_utc_ms": 1734974460000,
          "time": "2024-12-23T17:23:00Z",
          "energy": 33.69599999859929,
          "capacity": 404.3519999831915
      },
      {
          "time_stamp_utc_ms": 1734974760000,
          "time": "2024-12-23T17:28:00Z",
          "energy": 32.928000001236796,
          "capacity": 395.1360000148416
      },
      {
          "time_stamp_utc_ms": 1734975060000,
          "time": "2024-12-23T17:33:00Z",
          "energy": 33.023999999277294,
          "capacity": 396.2879999913275
      },
      {
          "time_stamp_utc_ms": 1734975360000,
          "time": "2024-12-23T17:38:00Z",
          "energy": 27.55200000014156,
          "capacity": 330.62400000169873
      },
      {
          "time_stamp_utc_ms": 1734975660000,
          "time": "2024-12-23T17:43:00Z",
          "energy": 27.936000000685453,
          "capacity": 335.2320000082255
      },
      {
          "time_stamp_utc_ms": 1734975960000,
          "time": "2024-12-23T17:48:00Z",
          "energy": 29.951999999582767,
          "capacity": 359.42399999499327
      },
      {
          "time_stamp_utc_ms": 1734976260000,
          "time": "2024-12-23T17:53:00Z",
          "energy": 31.104000000283122,
          "capacity": 373.24800000339746
      },
      {
          "time_stamp_utc_ms": 1734976560000,
          "time": "2024-12-23T17:58:00Z",
          "energy": 33.023999999277294,
          "capacity": 396.2879999913275
      },
      {
          "time_stamp_utc_ms": 1734976860000,
          "time": "2024-12-23T18:03:00Z",
          "energy": 33.50400000065565,
          "capacity": 402.04800000786787
      },
      {
          "time_stamp_utc_ms": 1734977160000,
          "time": "2024-12-23T18:08:00Z",
          "energy": 32.16000000014901,
          "capacity": 385.92000000178814
      },
      {
          "time_stamp_utc_ms": 1734977460000,
          "time": "2024-12-23T18:13:00Z",
          "energy": 32.351999999023974,
          "capacity": 388.2239999882877
      },
      {
          "time_stamp_utc_ms": 1734977760000,
          "time": "2024-12-23T18:18:00Z",
          "energy": 32.544000000692904,
          "capacity": 390.5280000083149
      },
      {
          "time_stamp_utc_ms": 1734978060000,
          "time": "2024-12-23T18:23:00Z",
          "energy": 32.06400000024587,
          "capacity": 384.76800000295043
      },
      {
          "time_stamp_utc_ms": 1734978360000,
          "time": "2024-12-23T18:28:00Z",
          "energy": 33.407999999821186,
          "capacity": 400.8959999978543
      },
      {
          "time_stamp_utc_ms": 1734978660000,
          "time": "2024-12-23T18:33:00Z",
          "energy": 29.087999999523163,
          "capacity": 349.055999994278
      },
      {
          "time_stamp_utc_ms": 1734978960000,
          "time": "2024-12-23T18:38:00Z",
          "energy": 25.536000000312924,
          "capacity": 306.4320000037551
      },
      {
          "time_stamp_utc_ms": 1734979260000,
          "time": "2024-12-23T18:43:00Z",
          "energy": 31.00800000037998,
          "capacity": 372.09600000455976
      },
      {
          "time_stamp_utc_ms": 1734979560000,
          "time": "2024-12-23T18:48:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1734979860000,
          "time": "2024-12-23T18:53:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1734980160000,
          "time": "2024-12-23T18:58:00Z",
          "energy": 31.776000000536442,
          "capacity": 381.3120000064373
      },
      {
          "time_stamp_utc_ms": 1734980460000,
          "time": "2024-12-23T19:03:00Z",
          "energy": 32.16000000014901,
          "capacity": 385.92000000178814
      },
      {
          "time_stamp_utc_ms": 1734980760000,
          "time": "2024-12-23T19:08:00Z",
          "energy": 33.59999999962747,
          "capacity": 403.1999999955297
      },
      {
          "time_stamp_utc_ms": 1734981060000,
          "time": "2024-12-23T19:13:00Z",
          "energy": 32.256000000052154,
          "capacity": 387.07200000062585
      },
      {
          "time_stamp_utc_ms": 1734981360000,
          "time": "2024-12-23T19:18:00Z",
          "energy": 31.199999999254942,
          "capacity": 374.3999999910593
      },
      {
          "time_stamp_utc_ms": 1734981660000,
          "time": "2024-12-23T19:23:00Z",
          "energy": 32.639999999664724,
          "capacity": 391.6799999959767
      },
      {
          "time_stamp_utc_ms": 1734981960000,
          "time": "2024-12-23T19:28:00Z",
          "energy": 34.46400000154972,
          "capacity": 413.56800001859665
      },
      {
          "time_stamp_utc_ms": 1734982260000,
          "time": "2024-12-23T19:33:00Z",
          "energy": 32.735999999567866,
          "capacity": 392.8319999948144
      },
      {
          "time_stamp_utc_ms": 1734982560000,
          "time": "2024-12-23T19:38:00Z",
          "energy": 31.87199999950826,
          "capacity": 382.46399999409914
      },
      {
          "time_stamp_utc_ms": 1734982860000,
          "time": "2024-12-23T19:43:00Z",
          "energy": 33.12000000104308,
          "capacity": 397.44000001251703
      },
      {
          "time_stamp_utc_ms": 1734983160000,
          "time": "2024-12-23T19:48:00Z",
          "energy": 37.631999999284744,
          "capacity": 451.58399999141693
      },
      {
          "time_stamp_utc_ms": 1734983460000,
          "time": "2024-12-23T19:53:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1734983760000,
          "time": "2024-12-23T19:58:00Z",
          "energy": 35.424000000581145,
          "capacity": 425.08800000697374
      },
      {
          "time_stamp_utc_ms": 1734984060000,
          "time": "2024-12-23T20:03:00Z",
          "energy": 35.99999999906868,
          "capacity": 431.99999998882413
      },
      {
          "time_stamp_utc_ms": 1734984360000,
          "time": "2024-12-23T20:08:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1734984660000,
          "time": "2024-12-23T20:13:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1734984960000,
          "time": "2024-12-23T20:18:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1734985260000,
          "time": "2024-12-23T20:23:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1734985560000,
          "time": "2024-12-23T20:28:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1734985860000,
          "time": "2024-12-23T20:33:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1734986160000,
          "time": "2024-12-23T20:38:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1734986460000,
          "time": "2024-12-23T20:43:00Z",
          "energy": 37.05599999893457,
          "capacity": 444.6719999872148
      },
      {
          "time_stamp_utc_ms": 1734986760000,
          "time": "2024-12-23T20:48:00Z",
          "energy": 37.2480000006035,
          "capacity": 446.976000007242
      },
      {
          "time_stamp_utc_ms": 1734987060000,
          "time": "2024-12-23T20:53:00Z",
          "energy": 37.152000000700355,
          "capacity": 445.8240000084043
      },
      {
          "time_stamp_utc_ms": 1734987360000,
          "time": "2024-12-23T20:58:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1734987660000,
          "time": "2024-12-23T21:03:00Z",
          "energy": 38.11199999880046,
          "capacity": 457.3439999856055
      },
      {
          "time_stamp_utc_ms": 1734987960000,
          "time": "2024-12-23T21:08:00Z",
          "energy": 38.59200000204146,
          "capacity": 463.1040000244975
      },
      {
          "time_stamp_utc_ms": 1734988260000,
          "time": "2024-12-23T21:13:00Z",
          "energy": 39.167999998666346,
          "capacity": 470.0159999839962
      },
      {
          "time_stamp_utc_ms": 1734988560000,
          "time": "2024-12-23T21:18:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1734988860000,
          "time": "2024-12-23T21:23:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1734989160000,
          "time": "2024-12-23T21:28:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1734989460000,
          "time": "2024-12-23T21:33:00Z",
          "energy": 38.496000001206994,
          "capacity": 461.95200001448393
      },
      {
          "time_stamp_utc_ms": 1734989760000,
          "time": "2024-12-23T21:38:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1734990060000,
          "time": "2024-12-23T21:43:00Z",
          "energy": 40.03199999872595,
          "capacity": 480.3839999847114
      },
      {
          "time_stamp_utc_ms": 1734990360000,
          "time": "2024-12-23T21:48:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1734990660000,
          "time": "2024-12-23T21:53:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1734990960000,
          "time": "2024-12-23T21:58:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1734991260000,
          "time": "2024-12-23T22:03:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1734991560000,
          "time": "2024-12-23T22:08:00Z",
          "energy": 40.3199999993667,
          "capacity": 483.8399999924004
      },
      {
          "time_stamp_utc_ms": 1734991860000,
          "time": "2024-12-23T22:13:00Z",
          "energy": 39.84000000078231,
          "capacity": 478.08000000938773
      },
      {
          "time_stamp_utc_ms": 1734992160000,
          "time": "2024-12-23T22:18:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1734992460000,
          "time": "2024-12-23T22:23:00Z",
          "energy": 40.8960000006482,
          "capacity": 490.7520000077784
      },
      {
          "time_stamp_utc_ms": 1734992760000,
          "time": "2024-12-23T22:28:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1734993060000,
          "time": "2024-12-23T22:33:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1734993360000,
          "time": "2024-12-23T22:38:00Z",
          "energy": 40.99200000055134,
          "capacity": 491.9040000066161
      },
      {
          "time_stamp_utc_ms": 1734993660000,
          "time": "2024-12-23T22:43:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1734993960000,
          "time": "2024-12-23T22:48:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1734994260000,
          "time": "2024-12-23T22:53:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1734994560000,
          "time": "2024-12-23T22:58:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1734994860000,
          "time": "2024-12-23T23:03:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1734995160000,
          "time": "2024-12-23T23:08:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1734995460000,
          "time": "2024-12-23T23:13:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1734995760000,
          "time": "2024-12-23T23:18:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1734996060000,
          "time": "2024-12-23T23:23:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1734996360000,
          "time": "2024-12-23T23:28:00Z",
          "energy": 38.687999999150634,
          "capacity": 464.25599998980766
      },
      {
          "time_stamp_utc_ms": 1734996660000,
          "time": "2024-12-23T23:33:00Z",
          "energy": 41.56800000090152,
          "capacity": 498.8160000108183
      },
      {
          "time_stamp_utc_ms": 1734996960000,
          "time": "2024-12-23T23:38:00Z",
          "energy": 38.97599999886006,
          "capacity": 467.7119999863208
      },
      {
          "time_stamp_utc_ms": 1734997260000,
          "time": "2024-12-23T23:43:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1734997560000,
          "time": "2024-12-23T23:48:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1734997860000,
          "time": "2024-12-23T23:53:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1734998160000,
          "time": "2024-12-23T23:58:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1734998460000,
          "time": "2024-12-24T00:03:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1734998760000,
          "time": "2024-12-24T00:08:00Z",
          "energy": 38.68800000101328,
          "capacity": 464.25600001215935
      },
      {
          "time_stamp_utc_ms": 1734999060000,
          "time": "2024-12-24T00:13:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1734999360000,
          "time": "2024-12-24T00:18:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1734999660000,
          "time": "2024-12-24T00:23:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1734999960000,
          "time": "2024-12-24T00:28:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735000260000,
          "time": "2024-12-24T00:33:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1735000560000,
          "time": "2024-12-24T00:38:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1735000860000,
          "time": "2024-12-24T00:43:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1735001160000,
          "time": "2024-12-24T00:48:00Z",
          "energy": 38.783999999053776,
          "capacity": 465.4079999886454
      },
      {
          "time_stamp_utc_ms": 1735001460000,
          "time": "2024-12-24T00:53:00Z",
          "energy": 37.152000000700355,
          "capacity": 445.8240000084043
      },
      {
          "time_stamp_utc_ms": 1735001760000,
          "time": "2024-12-24T00:58:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1735002060000,
          "time": "2024-12-24T01:03:00Z",
          "energy": 37.63200000021607,
          "capacity": 451.58400000259286
      },
      {
          "time_stamp_utc_ms": 1735002360000,
          "time": "2024-12-24T01:08:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735002660000,
          "time": "2024-12-24T01:13:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1735002960000,
          "time": "2024-12-24T01:18:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1735003260000,
          "time": "2024-12-24T01:23:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1735003560000,
          "time": "2024-12-24T01:28:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1735003860000,
          "time": "2024-12-24T01:33:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1735004160000,
          "time": "2024-12-24T01:38:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735004460000,
          "time": "2024-12-24T01:43:00Z",
          "energy": 38.78400000091642,
          "capacity": 465.40800001099706
      },
      {
          "time_stamp_utc_ms": 1735004760000,
          "time": "2024-12-24T01:48:00Z",
          "energy": 38.015999998897314,
          "capacity": 456.19199998676777
      },
      {
          "time_stamp_utc_ms": 1735005060000,
          "time": "2024-12-24T01:53:00Z",
          "energy": 38.78400000091642,
          "capacity": 465.40800001099706
      },
      {
          "time_stamp_utc_ms": 1735005360000,
          "time": "2024-12-24T01:58:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1735005660000,
          "time": "2024-12-24T02:03:00Z",
          "energy": 35.424000000581145,
          "capacity": 425.08800000697374
      },
      {
          "time_stamp_utc_ms": 1735005960000,
          "time": "2024-12-24T02:08:00Z",
          "energy": 36.287999998778105,
          "capacity": 435.45599998533726
      },
      {
          "time_stamp_utc_ms": 1735006260000,
          "time": "2024-12-24T02:13:00Z",
          "energy": 35.040000000968575,
          "capacity": 420.4800000116229
      },
      {
          "time_stamp_utc_ms": 1735006560000,
          "time": "2024-12-24T02:18:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1735006860000,
          "time": "2024-12-24T02:23:00Z",
          "energy": 35.23200000077486,
          "capacity": 422.7840000092983
      },
      {
          "time_stamp_utc_ms": 1735007160000,
          "time": "2024-12-24T02:28:00Z",
          "energy": 34.65599999949336,
          "capacity": 415.8719999939204
      },
      {
          "time_stamp_utc_ms": 1735007460000,
          "time": "2024-12-24T02:33:00Z",
          "energy": 35.23199999984354,
          "capacity": 422.78399999812245
      },
      {
          "time_stamp_utc_ms": 1735007760000,
          "time": "2024-12-24T02:38:00Z",
          "energy": 34.94399999920279,
          "capacity": 419.3279999904335
      },
      {
          "time_stamp_utc_ms": 1735008060000,
          "time": "2024-12-24T02:43:00Z",
          "energy": 33.88800000119954,
          "capacity": 406.6560000143945
      },
      {
          "time_stamp_utc_ms": 1735008360000,
          "time": "2024-12-24T02:48:00Z",
          "energy": 36.671999998390675,
          "capacity": 440.0639999806881
      },
      {
          "time_stamp_utc_ms": 1735008660000,
          "time": "2024-12-24T02:53:00Z",
          "energy": 35.040000000968575,
          "capacity": 420.4800000116229
      },
      {
          "time_stamp_utc_ms": 1735008960000,
          "time": "2024-12-24T02:58:00Z",
          "energy": 35.13600000087172,
          "capacity": 421.6320000104606
      },
      {
          "time_stamp_utc_ms": 1735009260000,
          "time": "2024-12-24T03:03:00Z",
          "energy": 35.80799999833107,
          "capacity": 429.6959999799729
      },
      {
          "time_stamp_utc_ms": 1735009560000,
          "time": "2024-12-24T03:08:00Z",
          "energy": 33.60000000055879,
          "capacity": 403.2000000067056
      },
      {
          "time_stamp_utc_ms": 1735009860000,
          "time": "2024-12-24T03:13:00Z",
          "energy": 35.13600000087172,
          "capacity": 421.6320000104606
      },
      {
          "time_stamp_utc_ms": 1735010160000,
          "time": "2024-12-24T03:18:00Z",
          "energy": 33.59999999869615,
          "capacity": 403.1999999843538
      },
      {
          "time_stamp_utc_ms": 1735010460000,
          "time": "2024-12-24T03:23:00Z",
          "energy": 35.04000000003725,
          "capacity": 420.48000000044703
      },
      {
          "time_stamp_utc_ms": 1735010760000,
          "time": "2024-12-24T03:28:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1735011060000,
          "time": "2024-12-24T03:33:00Z",
          "energy": 33.50400000065565,
          "capacity": 402.04800000786787
      },
      {
          "time_stamp_utc_ms": 1735011360000,
          "time": "2024-12-24T03:38:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1735011660000,
          "time": "2024-12-24T03:43:00Z",
          "energy": 34.46399999875575,
          "capacity": 413.5679999850691
      },
      {
          "time_stamp_utc_ms": 1735011960000,
          "time": "2024-12-24T03:48:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1735012260000,
          "time": "2024-12-24T03:53:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1735012560000,
          "time": "2024-12-24T03:58:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735012860000,
          "time": "2024-12-24T04:03:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1735013160000,
          "time": "2024-12-24T04:08:00Z",
          "energy": 34.94399999920279,
          "capacity": 419.3279999904335
      },
      {
          "time_stamp_utc_ms": 1735013460000,
          "time": "2024-12-24T04:13:00Z",
          "energy": 33.024000000208616,
          "capacity": 396.28800000250345
      },
      {
          "time_stamp_utc_ms": 1735013760000,
          "time": "2024-12-24T04:18:00Z",
          "energy": 33.79200000036508,
          "capacity": 405.504000004381
      },
      {
          "time_stamp_utc_ms": 1735014060000,
          "time": "2024-12-24T04:23:00Z",
          "energy": 33.50399999972433,
          "capacity": 402.047999996692
      },
      {
          "time_stamp_utc_ms": 1735014360000,
          "time": "2024-12-24T04:28:00Z",
          "energy": 33.312000000849366,
          "capacity": 399.74400001019245
      },
      {
          "time_stamp_utc_ms": 1735014660000,
          "time": "2024-12-24T04:33:00Z",
          "energy": 33.2160000000149,
          "capacity": 398.59200000017887
      },
      {
          "time_stamp_utc_ms": 1735014960000,
          "time": "2024-12-24T04:38:00Z",
          "energy": 33.21599999908358,
          "capacity": 398.59199998900294
      },
      {
          "time_stamp_utc_ms": 1735015260000,
          "time": "2024-12-24T04:43:00Z",
          "energy": 32.64000000059605,
          "capacity": 391.6800000071526
      },
      {
          "time_stamp_utc_ms": 1735015560000,
          "time": "2024-12-24T04:48:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1735015860000,
          "time": "2024-12-24T04:53:00Z",
          "energy": 33.024000000208616,
          "capacity": 396.28800000250345
      },
      {
          "time_stamp_utc_ms": 1735016160000,
          "time": "2024-12-24T04:58:00Z",
          "energy": 34.943999998271465,
          "capacity": 419.32799997925764
      },
      {
          "time_stamp_utc_ms": 1735016460000,
          "time": "2024-12-24T05:03:00Z",
          "energy": 35.52000000141561,
          "capacity": 426.2400000169873
      },
      {
          "time_stamp_utc_ms": 1735016760000,
          "time": "2024-12-24T05:08:00Z",
          "energy": 33.311999999918044,
          "capacity": 399.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735017060000,
          "time": "2024-12-24T05:13:00Z",
          "energy": 35.328000000678,
          "capacity": 423.93600000813603
      },
      {
          "time_stamp_utc_ms": 1735017360000,
          "time": "2024-12-24T05:18:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1735017660000,
          "time": "2024-12-24T05:23:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1735017960000,
          "time": "2024-12-24T05:28:00Z",
          "energy": 34.65600000042468,
          "capacity": 415.8720000050962
      },
      {
          "time_stamp_utc_ms": 1735018260000,
          "time": "2024-12-24T05:33:00Z",
          "energy": 33.59999999869615,
          "capacity": 403.1999999843538
      },
      {
          "time_stamp_utc_ms": 1735018560000,
          "time": "2024-12-24T05:38:00Z",
          "energy": 33.60000000055879,
          "capacity": 403.2000000067056
      },
      {
          "time_stamp_utc_ms": 1735018860000,
          "time": "2024-12-24T05:43:00Z",
          "energy": 34.56000000052154,
          "capacity": 414.7200000062585
      },
      {
          "time_stamp_utc_ms": 1735019160000,
          "time": "2024-12-24T05:48:00Z",
          "energy": 33.407999999821186,
          "capacity": 400.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735019460000,
          "time": "2024-12-24T05:53:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1735019760000,
          "time": "2024-12-24T05:58:00Z",
          "energy": 34.27199999988079,
          "capacity": 411.26399999856955
      },
      {
          "time_stamp_utc_ms": 1735020060000,
          "time": "2024-12-24T06:03:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735020360000,
          "time": "2024-12-24T06:08:00Z",
          "energy": 35.23199999984354,
          "capacity": 422.78399999812245
      },
      {
          "time_stamp_utc_ms": 1735020660000,
          "time": "2024-12-24T06:13:00Z",
          "energy": 33.407999999821186,
          "capacity": 400.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735020960000,
          "time": "2024-12-24T06:18:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1735021260000,
          "time": "2024-12-24T06:23:00Z",
          "energy": 34.94400000013411,
          "capacity": 419.3280000016093
      },
      {
          "time_stamp_utc_ms": 1735021560000,
          "time": "2024-12-24T06:28:00Z",
          "energy": 32.639999999664724,
          "capacity": 391.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735021860000,
          "time": "2024-12-24T06:33:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1735022160000,
          "time": "2024-12-24T06:38:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735022460000,
          "time": "2024-12-24T06:43:00Z",
          "energy": 32.447999998927116,
          "capacity": 389.3759999871254
      },
      {
          "time_stamp_utc_ms": 1735022760000,
          "time": "2024-12-24T06:48:00Z",
          "energy": 34.27199999988079,
          "capacity": 411.26399999856955
      },
      {
          "time_stamp_utc_ms": 1735023060000,
          "time": "2024-12-24T06:53:00Z",
          "energy": 33.7920000012964,
          "capacity": 405.5040000155568
      },
      {
          "time_stamp_utc_ms": 1735023360000,
          "time": "2024-12-24T06:58:00Z",
          "energy": 34.46399999875575,
          "capacity": 413.5679999850691
      },
      {
          "time_stamp_utc_ms": 1735023660000,
          "time": "2024-12-24T07:03:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1735023960000,
          "time": "2024-12-24T07:08:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1735024260000,
          "time": "2024-12-24T07:13:00Z",
          "energy": 35.328000000678,
          "capacity": 423.93600000813603
      },
      {
          "time_stamp_utc_ms": 1735024560000,
          "time": "2024-12-24T07:18:00Z",
          "energy": 33.69599999953061,
          "capacity": 404.3519999943674
      },
      {
          "time_stamp_utc_ms": 1735024860000,
          "time": "2024-12-24T07:23:00Z",
          "energy": 34.94400000013411,
          "capacity": 419.3280000016093
      },
      {
          "time_stamp_utc_ms": 1735025160000,
          "time": "2024-12-24T07:28:00Z",
          "energy": 33.98400000017136,
          "capacity": 407.8080000020564
      },
      {
          "time_stamp_utc_ms": 1735025460000,
          "time": "2024-12-24T07:33:00Z",
          "energy": 33.40800000075251,
          "capacity": 400.89600000903016
      },
      {
          "time_stamp_utc_ms": 1735025760000,
          "time": "2024-12-24T07:38:00Z",
          "energy": 33.40799999888986,
          "capacity": 400.89599998667836
      },
      {
          "time_stamp_utc_ms": 1735026060000,
          "time": "2024-12-24T07:43:00Z",
          "energy": 34.08000000100583,
          "capacity": 408.96000001206994
      },
      {
          "time_stamp_utc_ms": 1735026360000,
          "time": "2024-12-24T07:48:00Z",
          "energy": 32.928000000305474,
          "capacity": 395.13600000366574
      },
      {
          "time_stamp_utc_ms": 1735026660000,
          "time": "2024-12-24T07:53:00Z",
          "energy": 34.65599999949336,
          "capacity": 415.8719999939204
      },
      {
          "time_stamp_utc_ms": 1735026960000,
          "time": "2024-12-24T07:58:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1735027260000,
          "time": "2024-12-24T08:03:00Z",
          "energy": 32.83199999947101,
          "capacity": 393.9839999936521
      },
      {
          "time_stamp_utc_ms": 1735027560000,
          "time": "2024-12-24T08:08:00Z",
          "energy": 34.94400000013411,
          "capacity": 419.3280000016093
      },
      {
          "time_stamp_utc_ms": 1735027860000,
          "time": "2024-12-24T08:13:00Z",
          "energy": 33.407999999821186,
          "capacity": 400.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735028160000,
          "time": "2024-12-24T08:18:00Z",
          "energy": 33.791999999433756,
          "capacity": 405.5039999932051
      },
      {
          "time_stamp_utc_ms": 1735028460000,
          "time": "2024-12-24T08:23:00Z",
          "energy": 33.88800000119954,
          "capacity": 406.6560000143945
      },
      {
          "time_stamp_utc_ms": 1735028760000,
          "time": "2024-12-24T08:28:00Z",
          "energy": 32.92799999937415,
          "capacity": 395.1359999924898
      },
      {
          "time_stamp_utc_ms": 1735029060000,
          "time": "2024-12-24T08:33:00Z",
          "energy": 33.60000000055879,
          "capacity": 403.2000000067056
      },
      {
          "time_stamp_utc_ms": 1735029360000,
          "time": "2024-12-24T08:38:00Z",
          "energy": 33.59999999869615,
          "capacity": 403.1999999843538
      },
      {
          "time_stamp_utc_ms": 1735029660000,
          "time": "2024-12-24T08:43:00Z",
          "energy": 32.73600000049919,
          "capacity": 392.8320000059903
      },
      {
          "time_stamp_utc_ms": 1735029960000,
          "time": "2024-12-24T08:48:00Z",
          "energy": 34.56000000052154,
          "capacity": 414.7200000062585
      },
      {
          "time_stamp_utc_ms": 1735030260000,
          "time": "2024-12-24T08:53:00Z",
          "energy": 33.50399999972433,
          "capacity": 402.047999996692
      },
      {
          "time_stamp_utc_ms": 1735030560000,
          "time": "2024-12-24T08:58:00Z",
          "energy": 33.98400000017136,
          "capacity": 407.8080000020564
      },
      {
          "time_stamp_utc_ms": 1735030860000,
          "time": "2024-12-24T09:03:00Z",
          "energy": 34.4640000006184,
          "capacity": 413.5680000074208
      },
      {
          "time_stamp_utc_ms": 1735031160000,
          "time": "2024-12-24T09:08:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1735031460000,
          "time": "2024-12-24T09:13:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1735031760000,
          "time": "2024-12-24T09:18:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1735032060000,
          "time": "2024-12-24T09:23:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735032360000,
          "time": "2024-12-24T09:28:00Z",
          "energy": 34.7519999993965,
          "capacity": 417.0239999927581
      },
      {
          "time_stamp_utc_ms": 1735032660000,
          "time": "2024-12-24T09:33:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1735032960000,
          "time": "2024-12-24T09:38:00Z",
          "energy": 34.27199999894947,
          "capacity": 411.2639999873937
      },
      {
          "time_stamp_utc_ms": 1735033260000,
          "time": "2024-12-24T09:43:00Z",
          "energy": 34.75200000125915,
          "capacity": 417.0240000151098
      },
      {
          "time_stamp_utc_ms": 1735033560000,
          "time": "2024-12-24T09:48:00Z",
          "energy": 32.735999999567866,
          "capacity": 392.8319999948144
      },
      {
          "time_stamp_utc_ms": 1735033860000,
          "time": "2024-12-24T09:53:00Z",
          "energy": 35.231999998912215,
          "capacity": 422.78399998694664
      },
      {
          "time_stamp_utc_ms": 1735034160000,
          "time": "2024-12-24T09:58:00Z",
          "energy": 37.440000001341105,
          "capacity": 449.2800000160933
      },
      {
          "time_stamp_utc_ms": 1735034460000,
          "time": "2024-12-24T10:03:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1735034760000,
          "time": "2024-12-24T10:08:00Z",
          "energy": 38.97599999886006,
          "capacity": 467.7119999863208
      },
      {
          "time_stamp_utc_ms": 1735035060000,
          "time": "2024-12-24T10:13:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735035360000,
          "time": "2024-12-24T10:18:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735035660000,
          "time": "2024-12-24T10:23:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735035960000,
          "time": "2024-12-24T10:28:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735036260000,
          "time": "2024-12-24T10:33:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1735036560000,
          "time": "2024-12-24T10:38:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1735036860000,
          "time": "2024-12-24T10:43:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1735037160000,
          "time": "2024-12-24T10:48:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735037460000,
          "time": "2024-12-24T10:53:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1735037760000,
          "time": "2024-12-24T10:58:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735038060000,
          "time": "2024-12-24T11:03:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1735038360000,
          "time": "2024-12-24T11:08:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1735038660000,
          "time": "2024-12-24T11:13:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1735038960000,
          "time": "2024-12-24T11:18:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1735039260000,
          "time": "2024-12-24T11:23:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1735039560000,
          "time": "2024-12-24T11:28:00Z",
          "energy": 38.68799999821931,
          "capacity": 464.25599997863173
      },
      {
          "time_stamp_utc_ms": 1735039860000,
          "time": "2024-12-24T11:33:00Z",
          "energy": 41.47200000099838,
          "capacity": 497.6640000119806
      },
      {
          "time_stamp_utc_ms": 1735040160000,
          "time": "2024-12-24T11:38:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1735040460000,
          "time": "2024-12-24T11:43:00Z",
          "energy": 40.51200000103563,
          "capacity": 486.14400001242757
      },
      {
          "time_stamp_utc_ms": 1735040760000,
          "time": "2024-12-24T11:48:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1735041060000,
          "time": "2024-12-24T11:53:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735041360000,
          "time": "2024-12-24T11:58:00Z",
          "energy": 39.45600000116974,
          "capacity": 473.4720000140369
      },
      {
          "time_stamp_utc_ms": 1735041660000,
          "time": "2024-12-24T12:03:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1735041960000,
          "time": "2024-12-24T12:08:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735042260000,
          "time": "2024-12-24T12:13:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1735042560000,
          "time": "2024-12-24T12:18:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1735042860000,
          "time": "2024-12-24T12:23:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1735043160000,
          "time": "2024-12-24T12:28:00Z",
          "energy": 41.47199999913573,
          "capacity": 497.66399998962885
      },
      {
          "time_stamp_utc_ms": 1735043460000,
          "time": "2024-12-24T12:33:00Z",
          "energy": 40.51200000010431,
          "capacity": 486.14400000125175
      },
      {
          "time_stamp_utc_ms": 1735043760000,
          "time": "2024-12-24T12:38:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1735044060000,
          "time": "2024-12-24T12:43:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1735044360000,
          "time": "2024-12-24T12:48:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1735044660000,
          "time": "2024-12-24T12:53:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1735044960000,
          "time": "2024-12-24T12:58:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1735045260000,
          "time": "2024-12-24T13:03:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1735045560000,
          "time": "2024-12-24T13:08:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1735045860000,
          "time": "2024-12-24T13:13:00Z",
          "energy": 40.89600000157952,
          "capacity": 490.75200001895433
      },
      {
          "time_stamp_utc_ms": 1735046160000,
          "time": "2024-12-24T13:18:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735046460000,
          "time": "2024-12-24T13:23:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1735046760000,
          "time": "2024-12-24T13:28:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1735047060000,
          "time": "2024-12-24T13:33:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1735047360000,
          "time": "2024-12-24T13:38:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1735047660000,
          "time": "2024-12-24T13:43:00Z",
          "energy": 40.51200000103563,
          "capacity": 486.14400001242757
      },
      {
          "time_stamp_utc_ms": 1735047960000,
          "time": "2024-12-24T13:48:00Z",
          "energy": 41.279999998398125,
          "capacity": 495.3599999807775
      },
      {
          "time_stamp_utc_ms": 1735048260000,
          "time": "2024-12-24T13:53:00Z",
          "energy": 40.99200000055134,
          "capacity": 491.9040000066161
      },
      {
          "time_stamp_utc_ms": 1735048560000,
          "time": "2024-12-24T13:58:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1735048860000,
          "time": "2024-12-24T14:03:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1735049160000,
          "time": "2024-12-24T14:08:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1735049460000,
          "time": "2024-12-24T14:13:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1735049760000,
          "time": "2024-12-24T14:18:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1735050060000,
          "time": "2024-12-24T14:23:00Z",
          "energy": 44.831999998539686,
          "capacity": 537.9839999824762
      },
      {
          "time_stamp_utc_ms": 1735050360000,
          "time": "2024-12-24T14:28:00Z",
          "energy": 45.984000001102686,
          "capacity": 551.8080000132322
      },
      {
          "time_stamp_utc_ms": 1735050660000,
          "time": "2024-12-24T14:33:00Z",
          "energy": 46.463999999687076,
          "capacity": 557.5679999962449
      },
      {
          "time_stamp_utc_ms": 1735050960000,
          "time": "2024-12-24T14:38:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735051260000,
          "time": "2024-12-24T14:43:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1735051560000,
          "time": "2024-12-24T14:48:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735051860000,
          "time": "2024-12-24T14:53:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735052160000,
          "time": "2024-12-24T14:58:00Z",
          "energy": 45.023999999277294,
          "capacity": 540.2879999913275
      },
      {
          "time_stamp_utc_ms": 1735052460000,
          "time": "2024-12-24T15:03:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735052760000,
          "time": "2024-12-24T15:08:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1735053060000,
          "time": "2024-12-24T15:13:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1735053360000,
          "time": "2024-12-24T15:18:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735053660000,
          "time": "2024-12-24T15:23:00Z",
          "energy": 45.216000000946224,
          "capacity": 542.5920000113547
      },
      {
          "time_stamp_utc_ms": 1735053960000,
          "time": "2024-12-24T15:28:00Z",
          "energy": 43.007999998517334,
          "capacity": 516.095999982208
      },
      {
          "time_stamp_utc_ms": 1735054260000,
          "time": "2024-12-24T15:33:00Z",
          "energy": 44.35200000088662,
          "capacity": 532.2240000106395
      },
      {
          "time_stamp_utc_ms": 1735054560000,
          "time": "2024-12-24T15:38:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735054860000,
          "time": "2024-12-24T15:43:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1735055160000,
          "time": "2024-12-24T15:48:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1735055460000,
          "time": "2024-12-24T15:53:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1735055760000,
          "time": "2024-12-24T15:58:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1735056060000,
          "time": "2024-12-24T16:03:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1735056360000,
          "time": "2024-12-24T16:08:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1735056660000,
          "time": "2024-12-24T16:13:00Z",
          "energy": 40.704000000841916,
          "capacity": 488.448000010103
      },
      {
          "time_stamp_utc_ms": 1735056960000,
          "time": "2024-12-24T16:18:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1735057260000,
          "time": "2024-12-24T16:23:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1735057560000,
          "time": "2024-12-24T16:28:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1735057860000,
          "time": "2024-12-24T16:33:00Z",
          "energy": 38.880000000819564,
          "capacity": 466.56000000983477
      },
      {
          "time_stamp_utc_ms": 1735058160000,
          "time": "2024-12-24T16:38:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735058460000,
          "time": "2024-12-24T16:43:00Z",
          "energy": 37.152000000700355,
          "capacity": 445.8240000084043
      },
      {
          "time_stamp_utc_ms": 1735058760000,
          "time": "2024-12-24T16:48:00Z",
          "energy": 39.167999998666346,
          "capacity": 470.0159999839962
      },
      {
          "time_stamp_utc_ms": 1735059060000,
          "time": "2024-12-24T16:53:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1735059360000,
          "time": "2024-12-24T16:58:00Z",
          "energy": 37.05600000079721,
          "capacity": 444.6720000095666
      },
      {
          "time_stamp_utc_ms": 1735059660000,
          "time": "2024-12-24T17:03:00Z",
          "energy": 38.49599999934435,
          "capacity": 461.95199999213224
      },
      {
          "time_stamp_utc_ms": 1735059960000,
          "time": "2024-12-24T17:08:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1735060260000,
          "time": "2024-12-24T17:13:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1735060560000,
          "time": "2024-12-24T17:18:00Z",
          "energy": 37.5359999993816,
          "capacity": 450.4319999925792
      },
      {
          "time_stamp_utc_ms": 1735060860000,
          "time": "2024-12-24T17:23:00Z",
          "energy": 37.2480000006035,
          "capacity": 446.976000007242
      },
      {
          "time_stamp_utc_ms": 1735061160000,
          "time": "2024-12-24T17:28:00Z",
          "energy": 37.5359999993816,
          "capacity": 450.4319999925792
      },
      {
          "time_stamp_utc_ms": 1735061460000,
          "time": "2024-12-24T17:33:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1735061760000,
          "time": "2024-12-24T17:38:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1735062060000,
          "time": "2024-12-24T17:43:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735062360000,
          "time": "2024-12-24T17:48:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1735062660000,
          "time": "2024-12-24T17:53:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1735062960000,
          "time": "2024-12-24T17:58:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1735063260000,
          "time": "2024-12-24T18:03:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1735063560000,
          "time": "2024-12-24T18:08:00Z",
          "energy": 36.575999999418855,
          "capacity": 438.91199999302626
      },
      {
          "time_stamp_utc_ms": 1735063860000,
          "time": "2024-12-24T18:13:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1735064160000,
          "time": "2024-12-24T18:18:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1735064460000,
          "time": "2024-12-24T18:23:00Z",
          "energy": 36.09599999990314,
          "capacity": 433.1519999988377
      },
      {
          "time_stamp_utc_ms": 1735064760000,
          "time": "2024-12-24T18:28:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735065060000,
          "time": "2024-12-24T18:33:00Z",
          "energy": 36.09599999990314,
          "capacity": 433.1519999988377
      },
      {
          "time_stamp_utc_ms": 1735065360000,
          "time": "2024-12-24T18:38:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1735065660000,
          "time": "2024-12-24T18:43:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735065960000,
          "time": "2024-12-24T18:48:00Z",
          "energy": 37.2480000006035,
          "capacity": 446.976000007242
      },
      {
          "time_stamp_utc_ms": 1735066260000,
          "time": "2024-12-24T18:53:00Z",
          "energy": 37.91999999899417,
          "capacity": 455.03999998793006
      },
      {
          "time_stamp_utc_ms": 1735066560000,
          "time": "2024-12-24T18:58:00Z",
          "energy": 36.09599999990314,
          "capacity": 433.1519999988377
      },
      {
          "time_stamp_utc_ms": 1735066860000,
          "time": "2024-12-24T19:03:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1735067160000,
          "time": "2024-12-24T19:08:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1735067460000,
          "time": "2024-12-24T19:13:00Z",
          "energy": 36.96000000089407,
          "capacity": 443.5200000107289
      },
      {
          "time_stamp_utc_ms": 1735067760000,
          "time": "2024-12-24T19:18:00Z",
          "energy": 37.24799999874085,
          "capacity": 446.9759999848902
      },
      {
          "time_stamp_utc_ms": 1735068060000,
          "time": "2024-12-24T19:23:00Z",
          "energy": 37.2480000006035,
          "capacity": 446.976000007242
      },
      {
          "time_stamp_utc_ms": 1735068360000,
          "time": "2024-12-24T19:28:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735068660000,
          "time": "2024-12-24T19:33:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1735068960000,
          "time": "2024-12-24T19:38:00Z",
          "energy": 36.86399999912828,
          "capacity": 442.3679999895394
      },
      {
          "time_stamp_utc_ms": 1735069260000,
          "time": "2024-12-24T19:43:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1735069560000,
          "time": "2024-12-24T19:48:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1735069860000,
          "time": "2024-12-24T19:53:00Z",
          "energy": 38.015999998897314,
          "capacity": 456.19199998676777
      },
      {
          "time_stamp_utc_ms": 1735070160000,
          "time": "2024-12-24T19:58:00Z",
          "energy": 38.880000000819564,
          "capacity": 466.56000000983477
      },
      {
          "time_stamp_utc_ms": 1735070460000,
          "time": "2024-12-24T20:03:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1735070760000,
          "time": "2024-12-24T20:08:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1735071060000,
          "time": "2024-12-24T20:13:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1735071360000,
          "time": "2024-12-24T20:18:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1735071660000,
          "time": "2024-12-24T20:23:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735071960000,
          "time": "2024-12-24T20:28:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1735072260000,
          "time": "2024-12-24T20:33:00Z",
          "energy": 41.8559999987483,
          "capacity": 502.2719999849797
      },
      {
          "time_stamp_utc_ms": 1735072560000,
          "time": "2024-12-24T20:38:00Z",
          "energy": 42.04800000041723,
          "capacity": 504.57600000500685
      },
      {
          "time_stamp_utc_ms": 1735072860000,
          "time": "2024-12-24T20:43:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1735073160000,
          "time": "2024-12-24T20:48:00Z",
          "energy": 43.0080000013113,
          "capacity": 516.0960000157356
      },
      {
          "time_stamp_utc_ms": 1735073460000,
          "time": "2024-12-24T20:53:00Z",
          "energy": 42.62399999983609,
          "capacity": 511.4879999980331
      },
      {
          "time_stamp_utc_ms": 1735073760000,
          "time": "2024-12-24T20:58:00Z",
          "energy": 44.64000000059605,
          "capacity": 535.6800000071527
      },
      {
          "time_stamp_utc_ms": 1735074060000,
          "time": "2024-12-24T21:03:00Z",
          "energy": 43.7759999986738,
          "capacity": 525.3119999840856
      },
      {
          "time_stamp_utc_ms": 1735074360000,
          "time": "2024-12-24T21:08:00Z",
          "energy": 44.160000001080334,
          "capacity": 529.9200000129641
      },
      {
          "time_stamp_utc_ms": 1735074660000,
          "time": "2024-12-24T21:13:00Z",
          "energy": 45.59999999869615,
          "capacity": 547.1999999843538
      },
      {
          "time_stamp_utc_ms": 1735074960000,
          "time": "2024-12-24T21:18:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1735075260000,
          "time": "2024-12-24T21:23:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1735075560000,
          "time": "2024-12-24T21:28:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735075860000,
          "time": "2024-12-24T21:33:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735076160000,
          "time": "2024-12-24T21:38:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1735076460000,
          "time": "2024-12-24T21:43:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735076760000,
          "time": "2024-12-24T21:48:00Z",
          "energy": 45.98399999924004,
          "capacity": 551.8079999908806
      },
      {
          "time_stamp_utc_ms": 1735077060000,
          "time": "2024-12-24T21:53:00Z",
          "energy": 43.87200000137091,
          "capacity": 526.464000016451
      },
      {
          "time_stamp_utc_ms": 1735077360000,
          "time": "2024-12-24T21:58:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735077660000,
          "time": "2024-12-24T22:03:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735077960000,
          "time": "2024-12-24T22:08:00Z",
          "energy": 44.06399999931455,
          "capacity": 528.7679999917746
      },
      {
          "time_stamp_utc_ms": 1735078260000,
          "time": "2024-12-24T22:13:00Z",
          "energy": 44.928000001236796,
          "capacity": 539.1360000148416
      },
      {
          "time_stamp_utc_ms": 1735078560000,
          "time": "2024-12-24T22:18:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735078860000,
          "time": "2024-12-24T22:23:00Z",
          "energy": 44.54399999883026,
          "capacity": 534.5279999859631
      },
      {
          "time_stamp_utc_ms": 1735079160000,
          "time": "2024-12-24T22:28:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735079460000,
          "time": "2024-12-24T22:33:00Z",
          "energy": 44.544000000692904,
          "capacity": 534.528000008315
      },
      {
          "time_stamp_utc_ms": 1735079760000,
          "time": "2024-12-24T22:38:00Z",
          "energy": 46.56000000052154,
          "capacity": 558.7200000062585
      },
      {
          "time_stamp_utc_ms": 1735080060000,
          "time": "2024-12-24T22:43:00Z",
          "energy": 45.31199999898672,
          "capacity": 543.7439999878407
      },
      {
          "time_stamp_utc_ms": 1735080360000,
          "time": "2024-12-24T22:48:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1735080660000,
          "time": "2024-12-24T22:53:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735080960000,
          "time": "2024-12-24T22:58:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1735081260000,
          "time": "2024-12-24T23:03:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735081560000,
          "time": "2024-12-24T23:08:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1735081860000,
          "time": "2024-12-24T23:13:00Z",
          "energy": 44.44800000078976,
          "capacity": 533.3760000094773
      },
      {
          "time_stamp_utc_ms": 1735082160000,
          "time": "2024-12-24T23:18:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735082460000,
          "time": "2024-12-24T23:23:00Z",
          "energy": 43.6800000006333,
          "capacity": 524.1600000075996
      },
      {
          "time_stamp_utc_ms": 1735082760000,
          "time": "2024-12-24T23:28:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735083060000,
          "time": "2024-12-24T23:33:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735083360000,
          "time": "2024-12-24T23:38:00Z",
          "energy": 44.92799999937415,
          "capacity": 539.1359999924898
      },
      {
          "time_stamp_utc_ms": 1735083660000,
          "time": "2024-12-24T23:43:00Z",
          "energy": 43.872000000439584,
          "capacity": 526.464000005275
      },
      {
          "time_stamp_utc_ms": 1735083960000,
          "time": "2024-12-24T23:48:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735084260000,
          "time": "2024-12-24T23:53:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735084560000,
          "time": "2024-12-24T23:58:00Z",
          "energy": 43.967999999411404,
          "capacity": 527.6159999929368
      },
      {
          "time_stamp_utc_ms": 1735084860000,
          "time": "2024-12-25T00:03:00Z",
          "energy": 47.04000000003725,
          "capacity": 564.480000000447
      },
      {
          "time_stamp_utc_ms": 1735085160000,
          "time": "2024-12-25T00:08:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1735085460000,
          "time": "2024-12-25T00:13:00Z",
          "energy": 43.872000000439584,
          "capacity": 526.464000005275
      },
      {
          "time_stamp_utc_ms": 1735085760000,
          "time": "2024-12-25T00:18:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1735086060000,
          "time": "2024-12-25T00:23:00Z",
          "energy": 44.351999999023974,
          "capacity": 532.2239999882877
      },
      {
          "time_stamp_utc_ms": 1735086360000,
          "time": "2024-12-25T00:28:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735086660000,
          "time": "2024-12-25T00:33:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735086960000,
          "time": "2024-12-25T00:38:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735087260000,
          "time": "2024-12-25T00:43:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1735087560000,
          "time": "2024-12-25T00:48:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735087860000,
          "time": "2024-12-25T00:53:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1735088160000,
          "time": "2024-12-25T00:58:00Z",
          "energy": 44.25599999912083,
          "capacity": 531.07199998945
      },
      {
          "time_stamp_utc_ms": 1735088460000,
          "time": "2024-12-25T01:03:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1735088760000,
          "time": "2024-12-25T01:08:00Z",
          "energy": 45.31199999898672,
          "capacity": 543.7439999878407
      },
      {
          "time_stamp_utc_ms": 1735089060000,
          "time": "2024-12-25T01:13:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735089360000,
          "time": "2024-12-25T01:18:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1735089660000,
          "time": "2024-12-25T01:23:00Z",
          "energy": 46.080000000074506,
          "capacity": 552.9600000008941
      },
      {
          "time_stamp_utc_ms": 1735089960000,
          "time": "2024-12-25T01:28:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1735090260000,
          "time": "2024-12-25T01:33:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1735090560000,
          "time": "2024-12-25T01:38:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735090860000,
          "time": "2024-12-25T01:43:00Z",
          "energy": 45.40799999888986,
          "capacity": 544.8959999866784
      },
      {
          "time_stamp_utc_ms": 1735091160000,
          "time": "2024-12-25T01:48:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1735091460000,
          "time": "2024-12-25T01:53:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735091760000,
          "time": "2024-12-25T01:58:00Z",
          "energy": 42.72000000067055,
          "capacity": 512.6400000080466
      },
      {
          "time_stamp_utc_ms": 1735092060000,
          "time": "2024-12-25T02:03:00Z",
          "energy": 41.567999999038875,
          "capacity": 498.81599998846656
      },
      {
          "time_stamp_utc_ms": 1735092360000,
          "time": "2024-12-25T02:08:00Z",
          "energy": 43.6800000006333,
          "capacity": 524.1600000075996
      },
      {
          "time_stamp_utc_ms": 1735092660000,
          "time": "2024-12-25T02:13:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735092960000,
          "time": "2024-12-25T02:18:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1735093260000,
          "time": "2024-12-25T02:23:00Z",
          "energy": 41.088000000454485,
          "capacity": 493.0560000054538
      },
      {
          "time_stamp_utc_ms": 1735093560000,
          "time": "2024-12-25T02:28:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1735093860000,
          "time": "2024-12-25T02:33:00Z",
          "energy": 42.14399999938905,
          "capacity": 505.72799999266863
      },
      {
          "time_stamp_utc_ms": 1735094160000,
          "time": "2024-12-25T02:38:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1735094460000,
          "time": "2024-12-25T02:43:00Z",
          "energy": 41.8559999987483,
          "capacity": 502.2719999849797
      },
      {
          "time_stamp_utc_ms": 1735094760000,
          "time": "2024-12-25T02:48:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1735095060000,
          "time": "2024-12-25T02:53:00Z",
          "energy": 41.088000000454485,
          "capacity": 493.0560000054538
      },
      {
          "time_stamp_utc_ms": 1735095360000,
          "time": "2024-12-25T02:58:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1735095660000,
          "time": "2024-12-25T03:03:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1735095960000,
          "time": "2024-12-25T03:08:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1735096260000,
          "time": "2024-12-25T03:13:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1735096560000,
          "time": "2024-12-25T03:18:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735096860000,
          "time": "2024-12-25T03:23:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1735097160000,
          "time": "2024-12-25T03:28:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735097460000,
          "time": "2024-12-25T03:33:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1735097760000,
          "time": "2024-12-25T03:38:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1735098060000,
          "time": "2024-12-25T03:43:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735098360000,
          "time": "2024-12-25T03:48:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1735098660000,
          "time": "2024-12-25T03:53:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1735098960000,
          "time": "2024-12-25T03:58:00Z",
          "energy": 40.79999999888241,
          "capacity": 489.59999998658895
      },
      {
          "time_stamp_utc_ms": 1735099260000,
          "time": "2024-12-25T04:03:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1735099560000,
          "time": "2024-12-25T04:08:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1735099860000,
          "time": "2024-12-25T04:13:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1735100160000,
          "time": "2024-12-25T04:18:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1735100460000,
          "time": "2024-12-25T04:23:00Z",
          "energy": 41.088000000454485,
          "capacity": 493.0560000054538
      },
      {
          "time_stamp_utc_ms": 1735100760000,
          "time": "2024-12-25T04:28:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1735101060000,
          "time": "2024-12-25T04:33:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1735101360000,
          "time": "2024-12-25T04:38:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1735101660000,
          "time": "2024-12-25T04:43:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1735101960000,
          "time": "2024-12-25T04:48:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1735102260000,
          "time": "2024-12-25T04:53:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1735102560000,
          "time": "2024-12-25T04:58:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1735102860000,
          "time": "2024-12-25T05:03:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735103160000,
          "time": "2024-12-25T05:08:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735103460000,
          "time": "2024-12-25T05:13:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1735103760000,
          "time": "2024-12-25T05:18:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1735104060000,
          "time": "2024-12-25T05:23:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735104360000,
          "time": "2024-12-25T05:28:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1735104660000,
          "time": "2024-12-25T05:33:00Z",
          "energy": 39.552000001072884,
          "capacity": 474.6240000128746
      },
      {
          "time_stamp_utc_ms": 1735104960000,
          "time": "2024-12-25T05:38:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1735105260000,
          "time": "2024-12-25T05:43:00Z",
          "energy": 38.783999999053776,
          "capacity": 465.4079999886454
      },
      {
          "time_stamp_utc_ms": 1735105560000,
          "time": "2024-12-25T05:48:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1735105860000,
          "time": "2024-12-25T05:53:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1735106160000,
          "time": "2024-12-25T05:58:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1735106460000,
          "time": "2024-12-25T06:03:00Z",
          "energy": 40.511999999172986,
          "capacity": 486.1439999900758
      },
      {
          "time_stamp_utc_ms": 1735106760000,
          "time": "2024-12-25T06:08:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1735107060000,
          "time": "2024-12-25T06:13:00Z",
          "energy": 40.895999998785555,
          "capacity": 490.75199998542666
      },
      {
          "time_stamp_utc_ms": 1735107360000,
          "time": "2024-12-25T06:18:00Z",
          "energy": 39.264000001363456,
          "capacity": 471.1680000163615
      },
      {
          "time_stamp_utc_ms": 1735107660000,
          "time": "2024-12-25T06:23:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735107960000,
          "time": "2024-12-25T06:28:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1735108260000,
          "time": "2024-12-25T06:33:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1735108560000,
          "time": "2024-12-25T06:38:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1735108860000,
          "time": "2024-12-25T06:43:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1735109160000,
          "time": "2024-12-25T06:48:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735109460000,
          "time": "2024-12-25T06:53:00Z",
          "energy": 40.99199999962002,
          "capacity": 491.9039999954403
      },
      {
          "time_stamp_utc_ms": 1735109760000,
          "time": "2024-12-25T06:58:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1735110060000,
          "time": "2024-12-25T07:03:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1735110360000,
          "time": "2024-12-25T07:08:00Z",
          "energy": 40.80000000074506,
          "capacity": 489.6000000089407
      },
      {
          "time_stamp_utc_ms": 1735110660000,
          "time": "2024-12-25T07:13:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1735110960000,
          "time": "2024-12-25T07:18:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1735111260000,
          "time": "2024-12-25T07:23:00Z",
          "energy": 38.87999999895692,
          "capacity": 466.5599999874831
      },
      {
          "time_stamp_utc_ms": 1735111560000,
          "time": "2024-12-25T07:28:00Z",
          "energy": 41.088000000454485,
          "capacity": 493.0560000054538
      },
      {
          "time_stamp_utc_ms": 1735111860000,
          "time": "2024-12-25T07:33:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1735112160000,
          "time": "2024-12-25T07:38:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735112460000,
          "time": "2024-12-25T07:43:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1735112760000,
          "time": "2024-12-25T07:48:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1735113060000,
          "time": "2024-12-25T07:53:00Z",
          "energy": 40.9919999986887,
          "capacity": 491.9039999842644
      },
      {
          "time_stamp_utc_ms": 1735113360000,
          "time": "2024-12-25T07:58:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1735113660000,
          "time": "2024-12-25T08:03:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1735113960000,
          "time": "2024-12-25T08:08:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1735114260000,
          "time": "2024-12-25T08:13:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1735114560000,
          "time": "2024-12-25T08:18:00Z",
          "energy": 40.70399999897927,
          "capacity": 488.44799998775125
      },
      {
          "time_stamp_utc_ms": 1735114860000,
          "time": "2024-12-25T08:23:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1735115160000,
          "time": "2024-12-25T08:28:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1735115460000,
          "time": "2024-12-25T08:33:00Z",
          "energy": 40.99200000055134,
          "capacity": 491.9040000066161
      },
      {
          "time_stamp_utc_ms": 1735115760000,
          "time": "2024-12-25T08:38:00Z",
          "energy": 39.167999998666346,
          "capacity": 470.0159999839962
      },
      {
          "time_stamp_utc_ms": 1735116060000,
          "time": "2024-12-25T08:43:00Z",
          "energy": 40.99200000055134,
          "capacity": 491.9040000066161
      },
      {
          "time_stamp_utc_ms": 1735116360000,
          "time": "2024-12-25T08:48:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1735116660000,
          "time": "2024-12-25T08:53:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1735116960000,
          "time": "2024-12-25T08:58:00Z",
          "energy": 40.511999999172986,
          "capacity": 486.1439999900758
      },
      {
          "time_stamp_utc_ms": 1735117260000,
          "time": "2024-12-25T09:03:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1735117560000,
          "time": "2024-12-25T09:08:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1735117860000,
          "time": "2024-12-25T09:13:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1735118160000,
          "time": "2024-12-25T09:18:00Z",
          "energy": 39.93599999882281,
          "capacity": 479.23199998587376
      },
      {
          "time_stamp_utc_ms": 1735118460000,
          "time": "2024-12-25T09:23:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1735118760000,
          "time": "2024-12-25T09:28:00Z",
          "energy": 39.45600000116974,
          "capacity": 473.4720000140369
      },
      {
          "time_stamp_utc_ms": 1735119060000,
          "time": "2024-12-25T09:33:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735119360000,
          "time": "2024-12-25T09:38:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735119660000,
          "time": "2024-12-25T09:43:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1735119960000,
          "time": "2024-12-25T09:48:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1735120260000,
          "time": "2024-12-25T09:53:00Z",
          "energy": 41.183999999426305,
          "capacity": 494.2079999931157
      },
      {
          "time_stamp_utc_ms": 1735120560000,
          "time": "2024-12-25T09:58:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735120860000,
          "time": "2024-12-25T10:03:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1735121160000,
          "time": "2024-12-25T10:08:00Z",
          "energy": 45.02400000113994,
          "capacity": 540.2880000136793
      },
      {
          "time_stamp_utc_ms": 1735121460000,
          "time": "2024-12-25T10:13:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735121760000,
          "time": "2024-12-25T10:18:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1735122060000,
          "time": "2024-12-25T10:23:00Z",
          "energy": 47.519999999552965,
          "capacity": 570.2399999946356
      },
      {
          "time_stamp_utc_ms": 1735122360000,
          "time": "2024-12-25T10:28:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735122660000,
          "time": "2024-12-25T10:33:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1735122960000,
          "time": "2024-12-25T10:38:00Z",
          "energy": 45.312000000849366,
          "capacity": 543.7440000101924
      },
      {
          "time_stamp_utc_ms": 1735123260000,
          "time": "2024-12-25T10:43:00Z",
          "energy": 44.544000000692904,
          "capacity": 534.528000008315
      },
      {
          "time_stamp_utc_ms": 1735123560000,
          "time": "2024-12-25T10:48:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1735123860000,
          "time": "2024-12-25T10:53:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735124160000,
          "time": "2024-12-25T10:58:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1735124460000,
          "time": "2024-12-25T11:03:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735124760000,
          "time": "2024-12-25T11:08:00Z",
          "energy": 45.119999999180436,
          "capacity": 541.4399999901652
      },
      {
          "time_stamp_utc_ms": 1735125060000,
          "time": "2024-12-25T11:13:00Z",
          "energy": 46.080000000074506,
          "capacity": 552.9600000008941
      },
      {
          "time_stamp_utc_ms": 1735125360000,
          "time": "2024-12-25T11:18:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1735125660000,
          "time": "2024-12-25T11:23:00Z",
          "energy": 46.17600000090897,
          "capacity": 554.1120000109076
      },
      {
          "time_stamp_utc_ms": 1735125960000,
          "time": "2024-12-25T11:28:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1735126260000,
          "time": "2024-12-25T11:33:00Z",
          "energy": 45.119999999180436,
          "capacity": 541.4399999901652
      },
      {
          "time_stamp_utc_ms": 1735126560000,
          "time": "2024-12-25T11:38:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1735126860000,
          "time": "2024-12-25T11:43:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1735127160000,
          "time": "2024-12-25T11:48:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1735127460000,
          "time": "2024-12-25T11:53:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1735127760000,
          "time": "2024-12-25T11:58:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735128060000,
          "time": "2024-12-25T12:03:00Z",
          "energy": 47.231999998912215,
          "capacity": 566.7839999869466
      },
      {
          "time_stamp_utc_ms": 1735128360000,
          "time": "2024-12-25T12:08:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1735128660000,
          "time": "2024-12-25T12:13:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1735128960000,
          "time": "2024-12-25T12:18:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735129260000,
          "time": "2024-12-25T12:23:00Z",
          "energy": 45.791999999433756,
          "capacity": 549.5039999932052
      },
      {
          "time_stamp_utc_ms": 1735129560000,
          "time": "2024-12-25T12:28:00Z",
          "energy": 47.13600000180304,
          "capacity": 565.6320000216365
      },
      {
          "time_stamp_utc_ms": 1735129860000,
          "time": "2024-12-25T12:33:00Z",
          "energy": 47.03999999910593,
          "capacity": 564.4799999892712
      },
      {
          "time_stamp_utc_ms": 1735130160000,
          "time": "2024-12-25T12:38:00Z",
          "energy": 46.65599999949336,
          "capacity": 559.8719999939203
      },
      {
          "time_stamp_utc_ms": 1735130460000,
          "time": "2024-12-25T12:43:00Z",
          "energy": 45.88800000119954,
          "capacity": 550.6560000143945
      },
      {
          "time_stamp_utc_ms": 1735130760000,
          "time": "2024-12-25T12:48:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1735131060000,
          "time": "2024-12-25T12:53:00Z",
          "energy": 46.65599999949336,
          "capacity": 559.8719999939203
      },
      {
          "time_stamp_utc_ms": 1735131360000,
          "time": "2024-12-25T12:58:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1735131660000,
          "time": "2024-12-25T13:03:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735131960000,
          "time": "2024-12-25T13:08:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1735132260000,
          "time": "2024-12-25T13:13:00Z",
          "energy": 45.023999999277294,
          "capacity": 540.2879999913275
      },
      {
          "time_stamp_utc_ms": 1735132560000,
          "time": "2024-12-25T13:18:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1735132860000,
          "time": "2024-12-25T13:23:00Z",
          "energy": 43.87200000137091,
          "capacity": 526.464000016451
      },
      {
          "time_stamp_utc_ms": 1735133160000,
          "time": "2024-12-25T13:28:00Z",
          "energy": 45.023999999277294,
          "capacity": 540.2879999913275
      },
      {
          "time_stamp_utc_ms": 1735133460000,
          "time": "2024-12-25T13:33:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1735133760000,
          "time": "2024-12-25T13:38:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735134060000,
          "time": "2024-12-25T13:43:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735134360000,
          "time": "2024-12-25T13:48:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1735134660000,
          "time": "2024-12-25T13:53:00Z",
          "energy": 45.696000000461936,
          "capacity": 548.3520000055432
      },
      {
          "time_stamp_utc_ms": 1735134960000,
          "time": "2024-12-25T13:58:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1735135260000,
          "time": "2024-12-25T14:03:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1735135560000,
          "time": "2024-12-25T14:08:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735135860000,
          "time": "2024-12-25T14:13:00Z",
          "energy": 44.06399999931455,
          "capacity": 528.7679999917746
      },
      {
          "time_stamp_utc_ms": 1735136160000,
          "time": "2024-12-25T14:18:00Z",
          "energy": 45.12000000104308,
          "capacity": 541.440000012517
      },
      {
          "time_stamp_utc_ms": 1735136460000,
          "time": "2024-12-25T14:23:00Z",
          "energy": 44.15999999921769,
          "capacity": 529.9199999906123
      },
      {
          "time_stamp_utc_ms": 1735136760000,
          "time": "2024-12-25T14:28:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735137060000,
          "time": "2024-12-25T14:33:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735137360000,
          "time": "2024-12-25T14:38:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1735137660000,
          "time": "2024-12-25T14:43:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735137960000,
          "time": "2024-12-25T14:48:00Z",
          "energy": 40.8960000006482,
          "capacity": 490.7520000077784
      },
      {
          "time_stamp_utc_ms": 1735138260000,
          "time": "2024-12-25T14:53:00Z",
          "energy": 40.895999998785555,
          "capacity": 490.75199998542666
      },
      {
          "time_stamp_utc_ms": 1735138560000,
          "time": "2024-12-25T14:58:00Z",
          "energy": 40.704000000841916,
          "capacity": 488.448000010103
      },
      {
          "time_stamp_utc_ms": 1735138860000,
          "time": "2024-12-25T15:03:00Z",
          "energy": 40.99199999962002,
          "capacity": 491.9039999954403
      },
      {
          "time_stamp_utc_ms": 1735139160000,
          "time": "2024-12-25T15:08:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1735139460000,
          "time": "2024-12-25T15:13:00Z",
          "energy": 42.1440000012517,
          "capacity": 505.72800001502037
      },
      {
          "time_stamp_utc_ms": 1735139760000,
          "time": "2024-12-25T15:18:00Z",
          "energy": 42.52799999900162,
          "capacity": 510.33599998801947
      },
      {
          "time_stamp_utc_ms": 1735140060000,
          "time": "2024-12-25T15:23:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1735140360000,
          "time": "2024-12-25T15:28:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1735140660000,
          "time": "2024-12-25T15:33:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1735140960000,
          "time": "2024-12-25T15:38:00Z",
          "energy": 41.47200000099838,
          "capacity": 497.6640000119806
      },
      {
          "time_stamp_utc_ms": 1735141260000,
          "time": "2024-12-25T15:43:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1735141560000,
          "time": "2024-12-25T15:48:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1735141860000,
          "time": "2024-12-25T15:53:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1735142160000,
          "time": "2024-12-25T15:58:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1735142460000,
          "time": "2024-12-25T16:03:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735142760000,
          "time": "2024-12-25T16:08:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1735143060000,
          "time": "2024-12-25T16:13:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1735143360000,
          "time": "2024-12-25T16:18:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1735143660000,
          "time": "2024-12-25T16:23:00Z",
          "energy": 41.760000000707805,
          "capacity": 501.1200000084937
      },
      {
          "time_stamp_utc_ms": 1735143960000,
          "time": "2024-12-25T16:28:00Z",
          "energy": 41.37599999830127,
          "capacity": 496.5119999796152
      },
      {
          "time_stamp_utc_ms": 1735144260000,
          "time": "2024-12-25T16:33:00Z",
          "energy": 41.376000001095235,
          "capacity": 496.5120000131429
      },
      {
          "time_stamp_utc_ms": 1735144560000,
          "time": "2024-12-25T16:38:00Z",
          "energy": 41.28000000026077,
          "capacity": 495.36000000312924
      },
      {
          "time_stamp_utc_ms": 1735144860000,
          "time": "2024-12-25T16:43:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1735145160000,
          "time": "2024-12-25T16:48:00Z",
          "energy": 42.72000000067055,
          "capacity": 512.6400000080466
      },
      {
          "time_stamp_utc_ms": 1735145460000,
          "time": "2024-12-25T16:53:00Z",
          "energy": 40.70399999897927,
          "capacity": 488.44799998775125
      },
      {
          "time_stamp_utc_ms": 1735145760000,
          "time": "2024-12-25T16:58:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1735146060000,
          "time": "2024-12-25T17:03:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1735146360000,
          "time": "2024-12-25T17:08:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1735146660000,
          "time": "2024-12-25T17:13:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1735146960000,
          "time": "2024-12-25T17:18:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1735147260000,
          "time": "2024-12-25T17:23:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1735147560000,
          "time": "2024-12-25T17:28:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1735147860000,
          "time": "2024-12-25T17:33:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1735148160000,
          "time": "2024-12-25T17:38:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1735148460000,
          "time": "2024-12-25T17:43:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735148760000,
          "time": "2024-12-25T17:48:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1735149060000,
          "time": "2024-12-25T17:53:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1735149360000,
          "time": "2024-12-25T17:58:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1735149660000,
          "time": "2024-12-25T18:03:00Z",
          "energy": 42.23999999836087,
          "capacity": 506.8799999803305
      },
      {
          "time_stamp_utc_ms": 1735149960000,
          "time": "2024-12-25T18:08:00Z",
          "energy": 41.47200000099838,
          "capacity": 497.6640000119806
      },
      {
          "time_stamp_utc_ms": 1735150260000,
          "time": "2024-12-25T18:13:00Z",
          "energy": 42.14399999938905,
          "capacity": 505.72799999266863
      },
      {
          "time_stamp_utc_ms": 1735150560000,
          "time": "2024-12-25T18:18:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1735150860000,
          "time": "2024-12-25T18:23:00Z",
          "energy": 42.23999999836087,
          "capacity": 506.8799999803305
      },
      {
          "time_stamp_utc_ms": 1735151160000,
          "time": "2024-12-25T18:28:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735151460000,
          "time": "2024-12-25T18:33:00Z",
          "energy": 40.99200000055134,
          "capacity": 491.9040000066161
      },
      {
          "time_stamp_utc_ms": 1735151760000,
          "time": "2024-12-25T18:38:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735152060000,
          "time": "2024-12-25T18:43:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1735152360000,
          "time": "2024-12-25T18:48:00Z",
          "energy": 43.872000000439584,
          "capacity": 526.464000005275
      },
      {
          "time_stamp_utc_ms": 1735152660000,
          "time": "2024-12-25T18:53:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735152960000,
          "time": "2024-12-25T18:58:00Z",
          "energy": 41.47199999913573,
          "capacity": 497.66399998962885
      },
      {
          "time_stamp_utc_ms": 1735153260000,
          "time": "2024-12-25T19:03:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735153560000,
          "time": "2024-12-25T19:08:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1735153860000,
          "time": "2024-12-25T19:13:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1735154160000,
          "time": "2024-12-25T19:18:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1735154460000,
          "time": "2024-12-25T19:23:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1735154760000,
          "time": "2024-12-25T19:28:00Z",
          "energy": 41.47200000099838,
          "capacity": 497.6640000119806
      },
      {
          "time_stamp_utc_ms": 1735155060000,
          "time": "2024-12-25T19:33:00Z",
          "energy": 41.66399999894202,
          "capacity": 499.96799998730427
      },
      {
          "time_stamp_utc_ms": 1735155360000,
          "time": "2024-12-25T19:38:00Z",
          "energy": 42.04800000041723,
          "capacity": 504.57600000500685
      },
      {
          "time_stamp_utc_ms": 1735155660000,
          "time": "2024-12-25T19:43:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1735155960000,
          "time": "2024-12-25T19:48:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1735156260000,
          "time": "2024-12-25T19:53:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1735156560000,
          "time": "2024-12-25T19:58:00Z",
          "energy": 41.376000001095235,
          "capacity": 496.5120000131429
      },
      {
          "time_stamp_utc_ms": 1735156860000,
          "time": "2024-12-25T20:03:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1735157160000,
          "time": "2024-12-25T20:08:00Z",
          "energy": 41.95200000051409,
          "capacity": 503.42400000616914
      },
      {
          "time_stamp_utc_ms": 1735157460000,
          "time": "2024-12-25T20:13:00Z",
          "energy": 40.99199999962002,
          "capacity": 491.9039999954403
      },
      {
          "time_stamp_utc_ms": 1735157760000,
          "time": "2024-12-25T20:18:00Z",
          "energy": 42.816000000573695,
          "capacity": 513.7920000068843
      },
      {
          "time_stamp_utc_ms": 1735158060000,
          "time": "2024-12-25T20:23:00Z",
          "energy": 41.088000000454485,
          "capacity": 493.0560000054538
      },
      {
          "time_stamp_utc_ms": 1735158360000,
          "time": "2024-12-25T20:28:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1735158660000,
          "time": "2024-12-25T20:33:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1735158960000,
          "time": "2024-12-25T20:38:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1735159260000,
          "time": "2024-12-25T20:43:00Z",
          "energy": 42.33600000105798,
          "capacity": 508.0320000126958
      },
      {
          "time_stamp_utc_ms": 1735159560000,
          "time": "2024-12-25T20:48:00Z",
          "energy": 41.8559999987483,
          "capacity": 502.2719999849797
      },
      {
          "time_stamp_utc_ms": 1735159860000,
          "time": "2024-12-25T20:53:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735160160000,
          "time": "2024-12-25T20:58:00Z",
          "energy": 42.432000000961125,
          "capacity": 509.1840000115335
      },
      {
          "time_stamp_utc_ms": 1735160460000,
          "time": "2024-12-25T21:03:00Z",
          "energy": 42.239999999292195,
          "capacity": 506.87999999150634
      },
      {
          "time_stamp_utc_ms": 1735160760000,
          "time": "2024-12-25T21:08:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735161060000,
          "time": "2024-12-25T21:13:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735161360000,
          "time": "2024-12-25T21:18:00Z",
          "energy": 42.816000000573695,
          "capacity": 513.7920000068843
      },
      {
          "time_stamp_utc_ms": 1735161660000,
          "time": "2024-12-25T21:23:00Z",
          "energy": 44.063999998383224,
          "capacity": 528.7679999805987
      },
      {
          "time_stamp_utc_ms": 1735161960000,
          "time": "2024-12-25T21:28:00Z",
          "energy": 42.91200000140816,
          "capacity": 514.9440000168979
      },
      {
          "time_stamp_utc_ms": 1735162260000,
          "time": "2024-12-25T21:33:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1735162560000,
          "time": "2024-12-25T21:38:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735162860000,
          "time": "2024-12-25T21:43:00Z",
          "energy": 43.6800000006333,
          "capacity": 524.1600000075996
      },
      {
          "time_stamp_utc_ms": 1735163160000,
          "time": "2024-12-25T21:48:00Z",
          "energy": 44.06399999931455,
          "capacity": 528.7679999917746
      },
      {
          "time_stamp_utc_ms": 1735163460000,
          "time": "2024-12-25T21:53:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1735163760000,
          "time": "2024-12-25T21:58:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735164060000,
          "time": "2024-12-25T22:03:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1735164360000,
          "time": "2024-12-25T22:08:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1735164660000,
          "time": "2024-12-25T22:13:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1735164960000,
          "time": "2024-12-25T22:18:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1735165260000,
          "time": "2024-12-25T22:23:00Z",
          "energy": 43.6800000006333,
          "capacity": 524.1600000075996
      },
      {
          "time_stamp_utc_ms": 1735165560000,
          "time": "2024-12-25T22:28:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1735165860000,
          "time": "2024-12-25T22:33:00Z",
          "energy": 45.02400000113994,
          "capacity": 540.2880000136793
      },
      {
          "time_stamp_utc_ms": 1735166160000,
          "time": "2024-12-25T22:38:00Z",
          "energy": 44.351999999023974,
          "capacity": 532.2239999882877
      },
      {
          "time_stamp_utc_ms": 1735166460000,
          "time": "2024-12-25T22:43:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1735166760000,
          "time": "2024-12-25T22:48:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1735167060000,
          "time": "2024-12-25T22:53:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735167360000,
          "time": "2024-12-25T22:58:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735167660000,
          "time": "2024-12-25T23:03:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1735167960000,
          "time": "2024-12-25T23:08:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735168260000,
          "time": "2024-12-25T23:13:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735168560000,
          "time": "2024-12-25T23:18:00Z",
          "energy": 43.7759999986738,
          "capacity": 525.3119999840856
      },
      {
          "time_stamp_utc_ms": 1735168860000,
          "time": "2024-12-25T23:23:00Z",
          "energy": 44.928000001236796,
          "capacity": 539.1360000148416
      },
      {
          "time_stamp_utc_ms": 1735169160000,
          "time": "2024-12-25T23:28:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1735169460000,
          "time": "2024-12-25T23:33:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735169760000,
          "time": "2024-12-25T23:38:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1735170060000,
          "time": "2024-12-25T23:43:00Z",
          "energy": 43.872000000439584,
          "capacity": 526.464000005275
      },
      {
          "time_stamp_utc_ms": 1735170360000,
          "time": "2024-12-25T23:48:00Z",
          "energy": 46.36799999978393,
          "capacity": 556.4159999974072
      },
      {
          "time_stamp_utc_ms": 1735170660000,
          "time": "2024-12-25T23:53:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735170960000,
          "time": "2024-12-25T23:58:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1735171260000,
          "time": "2024-12-26T00:03:00Z",
          "energy": 45.023999999277294,
          "capacity": 540.2879999913275
      },
      {
          "time_stamp_utc_ms": 1735171560000,
          "time": "2024-12-26T00:08:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735171860000,
          "time": "2024-12-26T00:13:00Z",
          "energy": 45.12000000104308,
          "capacity": 541.440000012517
      },
      {
          "time_stamp_utc_ms": 1735172160000,
          "time": "2024-12-26T00:18:00Z",
          "energy": 45.119999999180436,
          "capacity": 541.4399999901652
      },
      {
          "time_stamp_utc_ms": 1735172460000,
          "time": "2024-12-26T00:23:00Z",
          "energy": 44.832000001333654,
          "capacity": 537.9840000160038
      },
      {
          "time_stamp_utc_ms": 1735172760000,
          "time": "2024-12-26T00:28:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735173060000,
          "time": "2024-12-26T00:33:00Z",
          "energy": 44.25599999912083,
          "capacity": 531.07199998945
      },
      {
          "time_stamp_utc_ms": 1735173360000,
          "time": "2024-12-26T00:38:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735173660000,
          "time": "2024-12-26T00:43:00Z",
          "energy": 45.40800000075251,
          "capacity": 544.8960000090301
      },
      {
          "time_stamp_utc_ms": 1735173960000,
          "time": "2024-12-26T00:48:00Z",
          "energy": 45.98399999924004,
          "capacity": 551.8079999908806
      },
      {
          "time_stamp_utc_ms": 1735174260000,
          "time": "2024-12-26T00:53:00Z",
          "energy": 45.98399999924004,
          "capacity": 551.8079999908806
      },
      {
          "time_stamp_utc_ms": 1735174560000,
          "time": "2024-12-26T00:58:00Z",
          "energy": 44.44800000078976,
          "capacity": 533.3760000094773
      },
      {
          "time_stamp_utc_ms": 1735174860000,
          "time": "2024-12-26T01:03:00Z",
          "energy": 47.135999999940395,
          "capacity": 565.6319999992847
      },
      {
          "time_stamp_utc_ms": 1735175160000,
          "time": "2024-12-26T01:08:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735175460000,
          "time": "2024-12-26T01:13:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1735175760000,
          "time": "2024-12-26T01:18:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735176060000,
          "time": "2024-12-26T01:23:00Z",
          "energy": 44.832000001333654,
          "capacity": 537.9840000160038
      },
      {
          "time_stamp_utc_ms": 1735176360000,
          "time": "2024-12-26T01:28:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1735176660000,
          "time": "2024-12-26T01:33:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735176960000,
          "time": "2024-12-26T01:38:00Z",
          "energy": 46.07999999914318,
          "capacity": 552.9599999897183
      },
      {
          "time_stamp_utc_ms": 1735177260000,
          "time": "2024-12-26T01:43:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1735177560000,
          "time": "2024-12-26T01:48:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735177860000,
          "time": "2024-12-26T01:53:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735178160000,
          "time": "2024-12-26T01:58:00Z",
          "energy": 41.28000000026077,
          "capacity": 495.36000000312924
      },
      {
          "time_stamp_utc_ms": 1735178460000,
          "time": "2024-12-26T02:03:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1735178760000,
          "time": "2024-12-26T02:08:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735179060000,
          "time": "2024-12-26T02:13:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1735179360000,
          "time": "2024-12-26T02:18:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1735179660000,
          "time": "2024-12-26T02:23:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1735179960000,
          "time": "2024-12-26T02:28:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1735180260000,
          "time": "2024-12-26T02:33:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1735180560000,
          "time": "2024-12-26T02:38:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1735180860000,
          "time": "2024-12-26T02:43:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1735181160000,
          "time": "2024-12-26T02:48:00Z",
          "energy": 41.760000000707805,
          "capacity": 501.1200000084937
      },
      {
          "time_stamp_utc_ms": 1735181460000,
          "time": "2024-12-26T02:53:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1735181760000,
          "time": "2024-12-26T02:58:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735182060000,
          "time": "2024-12-26T03:03:00Z",
          "energy": 41.18400000128895,
          "capacity": 494.20800001546746
      },
      {
          "time_stamp_utc_ms": 1735182360000,
          "time": "2024-12-26T03:08:00Z",
          "energy": 39.93599999882281,
          "capacity": 479.23199998587376
      },
      {
          "time_stamp_utc_ms": 1735182660000,
          "time": "2024-12-26T03:13:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1735182960000,
          "time": "2024-12-26T03:18:00Z",
          "energy": 39.26399999856949,
          "capacity": 471.1679999828339
      },
      {
          "time_stamp_utc_ms": 1735183260000,
          "time": "2024-12-26T03:23:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1735183560000,
          "time": "2024-12-26T03:28:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1735183860000,
          "time": "2024-12-26T03:33:00Z",
          "energy": 40.51200000010431,
          "capacity": 486.14400000125175
      },
      {
          "time_stamp_utc_ms": 1735184160000,
          "time": "2024-12-26T03:38:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735184460000,
          "time": "2024-12-26T03:43:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1735184760000,
          "time": "2024-12-26T03:48:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1735185060000,
          "time": "2024-12-26T03:53:00Z",
          "energy": 38.783999999053776,
          "capacity": 465.4079999886454
      },
      {
          "time_stamp_utc_ms": 1735185360000,
          "time": "2024-12-26T03:58:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1735185660000,
          "time": "2024-12-26T04:03:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735185960000,
          "time": "2024-12-26T04:08:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735186260000,
          "time": "2024-12-26T04:13:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1735186560000,
          "time": "2024-12-26T04:18:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1735186860000,
          "time": "2024-12-26T04:23:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1735187160000,
          "time": "2024-12-26T04:28:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735187460000,
          "time": "2024-12-26T04:33:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735187760000,
          "time": "2024-12-26T04:38:00Z",
          "energy": 38.49599999934435,
          "capacity": 461.95199999213224
      },
      {
          "time_stamp_utc_ms": 1735188060000,
          "time": "2024-12-26T04:43:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1735188360000,
          "time": "2024-12-26T04:48:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1735188660000,
          "time": "2024-12-26T04:53:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1735188960000,
          "time": "2024-12-26T04:58:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735189260000,
          "time": "2024-12-26T05:03:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1735189560000,
          "time": "2024-12-26T05:08:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1735189860000,
          "time": "2024-12-26T05:13:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1735190160000,
          "time": "2024-12-26T05:18:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1735190460000,
          "time": "2024-12-26T05:23:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735190760000,
          "time": "2024-12-26T05:28:00Z",
          "energy": 38.783999999053776,
          "capacity": 465.4079999886454
      },
      {
          "time_stamp_utc_ms": 1735191060000,
          "time": "2024-12-26T05:33:00Z",
          "energy": 40.51200000010431,
          "capacity": 486.14400000125175
      },
      {
          "time_stamp_utc_ms": 1735191360000,
          "time": "2024-12-26T05:38:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1735191660000,
          "time": "2024-12-26T05:43:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1735191960000,
          "time": "2024-12-26T05:48:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735192260000,
          "time": "2024-12-26T05:53:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735192560000,
          "time": "2024-12-26T05:58:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735192860000,
          "time": "2024-12-26T06:03:00Z",
          "energy": 38.687999999150634,
          "capacity": 464.25599998980766
      },
      {
          "time_stamp_utc_ms": 1735193160000,
          "time": "2024-12-26T06:08:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1735193460000,
          "time": "2024-12-26T06:13:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735193760000,
          "time": "2024-12-26T06:18:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1735194060000,
          "time": "2024-12-26T06:23:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1735194360000,
          "time": "2024-12-26T06:28:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1735194660000,
          "time": "2024-12-26T06:33:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1735194960000,
          "time": "2024-12-26T06:38:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1735195260000,
          "time": "2024-12-26T06:43:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1735195560000,
          "time": "2024-12-26T06:48:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1735195860000,
          "time": "2024-12-26T06:53:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735196160000,
          "time": "2024-12-26T06:58:00Z",
          "energy": 37.631999999284744,
          "capacity": 451.58399999141693
      },
      {
          "time_stamp_utc_ms": 1735196460000,
          "time": "2024-12-26T07:03:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735196760000,
          "time": "2024-12-26T07:08:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1735197060000,
          "time": "2024-12-26T07:13:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735197360000,
          "time": "2024-12-26T07:18:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1735197660000,
          "time": "2024-12-26T07:23:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735197960000,
          "time": "2024-12-26T07:28:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735198260000,
          "time": "2024-12-26T07:33:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1735198560000,
          "time": "2024-12-26T07:38:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1735198860000,
          "time": "2024-12-26T07:43:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735199160000,
          "time": "2024-12-26T07:48:00Z",
          "energy": 38.68800000101328,
          "capacity": 464.25600001215935
      },
      {
          "time_stamp_utc_ms": 1735199460000,
          "time": "2024-12-26T07:53:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1735199760000,
          "time": "2024-12-26T07:58:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735200060000,
          "time": "2024-12-26T08:03:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1735200360000,
          "time": "2024-12-26T08:08:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1735200660000,
          "time": "2024-12-26T08:13:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1735200960000,
          "time": "2024-12-26T08:18:00Z",
          "energy": 40.80000000074506,
          "capacity": 489.6000000089407
      },
      {
          "time_stamp_utc_ms": 1735201260000,
          "time": "2024-12-26T08:23:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1735201560000,
          "time": "2024-12-26T08:28:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1735201860000,
          "time": "2024-12-26T08:33:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1735202160000,
          "time": "2024-12-26T08:38:00Z",
          "energy": 38.49599999934435,
          "capacity": 461.95199999213224
      },
      {
          "time_stamp_utc_ms": 1735202460000,
          "time": "2024-12-26T08:43:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1735202760000,
          "time": "2024-12-26T08:48:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1735203060000,
          "time": "2024-12-26T08:53:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735203360000,
          "time": "2024-12-26T08:58:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735203660000,
          "time": "2024-12-26T09:03:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1735203960000,
          "time": "2024-12-26T09:08:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735204260000,
          "time": "2024-12-26T09:13:00Z",
          "energy": 38.687999999150634,
          "capacity": 464.25599998980766
      },
      {
          "time_stamp_utc_ms": 1735204560000,
          "time": "2024-12-26T09:18:00Z",
          "energy": 38.97600000165403,
          "capacity": 467.71200001984835
      },
      {
          "time_stamp_utc_ms": 1735204860000,
          "time": "2024-12-26T09:23:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1735205160000,
          "time": "2024-12-26T09:28:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1735205460000,
          "time": "2024-12-26T09:33:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1735205760000,
          "time": "2024-12-26T09:38:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735206060000,
          "time": "2024-12-26T09:43:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1735206360000,
          "time": "2024-12-26T09:48:00Z",
          "energy": 40.12800000142306,
          "capacity": 481.53600001707673
      },
      {
          "time_stamp_utc_ms": 1735206660000,
          "time": "2024-12-26T09:53:00Z",
          "energy": 38.399999998509884,
          "capacity": 460.7999999821186
      },
      {
          "time_stamp_utc_ms": 1735206960000,
          "time": "2024-12-26T09:58:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1735207260000,
          "time": "2024-12-26T10:03:00Z",
          "energy": 44.44800000078976,
          "capacity": 533.3760000094773
      },
      {
          "time_stamp_utc_ms": 1735207560000,
          "time": "2024-12-26T10:08:00Z",
          "energy": 46.65600000042468,
          "capacity": 559.8720000050962
      },
      {
          "time_stamp_utc_ms": 1735207860000,
          "time": "2024-12-26T10:13:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1735208160000,
          "time": "2024-12-26T10:18:00Z",
          "energy": 43.58400000073016,
          "capacity": 523.0080000087619
      },
      {
          "time_stamp_utc_ms": 1735208460000,
          "time": "2024-12-26T10:23:00Z",
          "energy": 44.6399999987334,
          "capacity": 535.6799999848008
      },
      {
          "time_stamp_utc_ms": 1735208760000,
          "time": "2024-12-26T10:28:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735209060000,
          "time": "2024-12-26T10:33:00Z",
          "energy": 44.06400000117719,
          "capacity": 528.7680000141264
      },
      {
          "time_stamp_utc_ms": 1735209360000,
          "time": "2024-12-26T10:38:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735209660000,
          "time": "2024-12-26T10:43:00Z",
          "energy": 44.06399999931455,
          "capacity": 528.7679999917746
      },
      {
          "time_stamp_utc_ms": 1735209960000,
          "time": "2024-12-26T10:48:00Z",
          "energy": 45.312000000849366,
          "capacity": 543.7440000101924
      },
      {
          "time_stamp_utc_ms": 1735210260000,
          "time": "2024-12-26T10:53:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1735210560000,
          "time": "2024-12-26T10:58:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735210860000,
          "time": "2024-12-26T11:03:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1735211160000,
          "time": "2024-12-26T11:08:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1735211460000,
          "time": "2024-12-26T11:13:00Z",
          "energy": 45.98399999924004,
          "capacity": 551.8079999908806
      },
      {
          "time_stamp_utc_ms": 1735211760000,
          "time": "2024-12-26T11:18:00Z",
          "energy": 44.44800000078976,
          "capacity": 533.3760000094773
      },
      {
          "time_stamp_utc_ms": 1735212060000,
          "time": "2024-12-26T11:23:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1735212360000,
          "time": "2024-12-26T11:28:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735212660000,
          "time": "2024-12-26T11:33:00Z",
          "energy": 44.44800000078976,
          "capacity": 533.3760000094773
      },
      {
          "time_stamp_utc_ms": 1735212960000,
          "time": "2024-12-26T11:38:00Z",
          "energy": 46.07999999914318,
          "capacity": 552.9599999897183
      },
      {
          "time_stamp_utc_ms": 1735213260000,
          "time": "2024-12-26T11:43:00Z",
          "energy": 44.83199999947101,
          "capacity": 537.9839999936521
      },
      {
          "time_stamp_utc_ms": 1735213560000,
          "time": "2024-12-26T11:48:00Z",
          "energy": 45.216000000946224,
          "capacity": 542.5920000113547
      },
      {
          "time_stamp_utc_ms": 1735213860000,
          "time": "2024-12-26T11:53:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1735214160000,
          "time": "2024-12-26T11:58:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735214460000,
          "time": "2024-12-26T12:03:00Z",
          "energy": 46.55999999959022,
          "capacity": 558.7199999950826
      },
      {
          "time_stamp_utc_ms": 1735214760000,
          "time": "2024-12-26T12:08:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735215060000,
          "time": "2024-12-26T12:13:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1735215360000,
          "time": "2024-12-26T12:18:00Z",
          "energy": 45.8879999993369,
          "capacity": 550.6559999920429
      },
      {
          "time_stamp_utc_ms": 1735215660000,
          "time": "2024-12-26T12:23:00Z",
          "energy": 45.7920000012964,
          "capacity": 549.5040000155568
      },
      {
          "time_stamp_utc_ms": 1735215960000,
          "time": "2024-12-26T12:28:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1735216260000,
          "time": "2024-12-26T12:33:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735216560000,
          "time": "2024-12-26T12:38:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735216860000,
          "time": "2024-12-26T12:43:00Z",
          "energy": 46.463999999687076,
          "capacity": 557.5679999962449
      },
      {
          "time_stamp_utc_ms": 1735217160000,
          "time": "2024-12-26T12:48:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735217460000,
          "time": "2024-12-26T12:53:00Z",
          "energy": 46.65600000042468,
          "capacity": 559.8720000050962
      },
      {
          "time_stamp_utc_ms": 1735217760000,
          "time": "2024-12-26T12:58:00Z",
          "energy": 44.92799999937415,
          "capacity": 539.1359999924898
      },
      {
          "time_stamp_utc_ms": 1735218060000,
          "time": "2024-12-26T13:03:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1735218360000,
          "time": "2024-12-26T13:08:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1735218660000,
          "time": "2024-12-26T13:13:00Z",
          "energy": 44.6399999987334,
          "capacity": 535.6799999848008
      },
      {
          "time_stamp_utc_ms": 1735218960000,
          "time": "2024-12-26T13:18:00Z",
          "energy": 48.28800000064075,
          "capacity": 579.456000007689
      },
      {
          "time_stamp_utc_ms": 1735219260000,
          "time": "2024-12-26T13:23:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1735219560000,
          "time": "2024-12-26T13:28:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1735219860000,
          "time": "2024-12-26T13:33:00Z",
          "energy": 46.175999999046326,
          "capacity": 554.111999988556
      },
      {
          "time_stamp_utc_ms": 1735220160000,
          "time": "2024-12-26T13:38:00Z",
          "energy": 46.368000000715256,
          "capacity": 556.4160000085831
      },
      {
          "time_stamp_utc_ms": 1735220460000,
          "time": "2024-12-26T13:43:00Z",
          "energy": 46.94400000013411,
          "capacity": 563.3280000016093
      },
      {
          "time_stamp_utc_ms": 1735220760000,
          "time": "2024-12-26T13:48:00Z",
          "energy": 45.98399999924004,
          "capacity": 551.8079999908806
      },
      {
          "time_stamp_utc_ms": 1735221060000,
          "time": "2024-12-26T13:53:00Z",
          "energy": 45.12000000104308,
          "capacity": 541.440000012517
      },
      {
          "time_stamp_utc_ms": 1735221360000,
          "time": "2024-12-26T13:58:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1735221660000,
          "time": "2024-12-26T14:03:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1735221960000,
          "time": "2024-12-26T14:08:00Z",
          "energy": 45.984000001102686,
          "capacity": 551.8080000132322
      },
      {
          "time_stamp_utc_ms": 1735222260000,
          "time": "2024-12-26T14:13:00Z",
          "energy": 45.503999998793006,
          "capacity": 546.0479999855161
      },
      {
          "time_stamp_utc_ms": 1735222560000,
          "time": "2024-12-26T14:18:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735222860000,
          "time": "2024-12-26T14:23:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1735223160000,
          "time": "2024-12-26T14:28:00Z",
          "energy": 45.023999999277294,
          "capacity": 540.2879999913275
      },
      {
          "time_stamp_utc_ms": 1735223460000,
          "time": "2024-12-26T14:33:00Z",
          "energy": 47.040000000968575,
          "capacity": 564.4800000116229
      },
      {
          "time_stamp_utc_ms": 1735223760000,
          "time": "2024-12-26T14:38:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1735224060000,
          "time": "2024-12-26T14:43:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735224360000,
          "time": "2024-12-26T14:48:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735224660000,
          "time": "2024-12-26T14:53:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735224960000,
          "time": "2024-12-26T14:58:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735225260000,
          "time": "2024-12-26T15:03:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1735225560000,
          "time": "2024-12-26T15:08:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1735225860000,
          "time": "2024-12-26T15:13:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1735226160000,
          "time": "2024-12-26T15:18:00Z",
          "energy": 41.8559999987483,
          "capacity": 502.2719999849797
      },
      {
          "time_stamp_utc_ms": 1735226460000,
          "time": "2024-12-26T15:23:00Z",
          "energy": 42.816000000573695,
          "capacity": 513.7920000068843
      },
      {
          "time_stamp_utc_ms": 1735226760000,
          "time": "2024-12-26T15:28:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735227060000,
          "time": "2024-12-26T15:33:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1735227360000,
          "time": "2024-12-26T15:38:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735227660000,
          "time": "2024-12-26T15:43:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1735227960000,
          "time": "2024-12-26T15:48:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735228260000,
          "time": "2024-12-26T15:53:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1735228560000,
          "time": "2024-12-26T15:58:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1735228860000,
          "time": "2024-12-26T16:03:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1735229160000,
          "time": "2024-12-26T16:08:00Z",
          "energy": 37.43999999854714,
          "capacity": 449.27999998256564
      },
      {
          "time_stamp_utc_ms": 1735229460000,
          "time": "2024-12-26T16:13:00Z",
          "energy": 41.76000000163913,
          "capacity": 501.12000001966953
      },
      {
          "time_stamp_utc_ms": 1735229760000,
          "time": "2024-12-26T16:18:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1735230060000,
          "time": "2024-12-26T16:23:00Z",
          "energy": 41.66399999894202,
          "capacity": 499.96799998730427
      },
      {
          "time_stamp_utc_ms": 1735230360000,
          "time": "2024-12-26T16:28:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1735230660000,
          "time": "2024-12-26T16:33:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1735230960000,
          "time": "2024-12-26T16:38:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1735231260000,
          "time": "2024-12-26T16:43:00Z",
          "energy": 38.49599999934435,
          "capacity": 461.95199999213224
      },
      {
          "time_stamp_utc_ms": 1735231560000,
          "time": "2024-12-26T16:48:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735231860000,
          "time": "2024-12-26T16:53:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1735232160000,
          "time": "2024-12-26T16:58:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1735232460000,
          "time": "2024-12-26T17:03:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1735232760000,
          "time": "2024-12-26T17:08:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1735233060000,
          "time": "2024-12-26T17:13:00Z",
          "energy": 38.880000000819564,
          "capacity": 466.56000000983477
      },
      {
          "time_stamp_utc_ms": 1735233360000,
          "time": "2024-12-26T17:18:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1735233660000,
          "time": "2024-12-26T17:23:00Z",
          "energy": 40.41599999833852,
          "capacity": 484.99199998006225
      },
      {
          "time_stamp_utc_ms": 1735233960000,
          "time": "2024-12-26T17:28:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1735234260000,
          "time": "2024-12-26T17:33:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1735234560000,
          "time": "2024-12-26T17:38:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735234860000,
          "time": "2024-12-26T17:43:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1735235160000,
          "time": "2024-12-26T17:48:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1735235460000,
          "time": "2024-12-26T17:53:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1735235760000,
          "time": "2024-12-26T17:58:00Z",
          "energy": 36.96000000089407,
          "capacity": 443.5200000107289
      },
      {
          "time_stamp_utc_ms": 1735236060000,
          "time": "2024-12-26T18:03:00Z",
          "energy": 36.96000000089407,
          "capacity": 443.5200000107289
      },
      {
          "time_stamp_utc_ms": 1735236360000,
          "time": "2024-12-26T18:08:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1735236660000,
          "time": "2024-12-26T18:13:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1735236960000,
          "time": "2024-12-26T18:18:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1735237260000,
          "time": "2024-12-26T18:23:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1735237560000,
          "time": "2024-12-26T18:28:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1735237860000,
          "time": "2024-12-26T18:33:00Z",
          "energy": 37.91999999899417,
          "capacity": 455.03999998793006
      },
      {
          "time_stamp_utc_ms": 1735238160000,
          "time": "2024-12-26T18:38:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1735238460000,
          "time": "2024-12-26T18:43:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1735238760000,
          "time": "2024-12-26T18:48:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1735239060000,
          "time": "2024-12-26T18:53:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735239360000,
          "time": "2024-12-26T18:58:00Z",
          "energy": 40.70399999897927,
          "capacity": 488.44799998775125
      },
      {
          "time_stamp_utc_ms": 1735239660000,
          "time": "2024-12-26T19:03:00Z",
          "energy": 41.56800000090152,
          "capacity": 498.8160000108183
      },
      {
          "time_stamp_utc_ms": 1735239960000,
          "time": "2024-12-26T19:08:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1735240260000,
          "time": "2024-12-26T19:13:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735240560000,
          "time": "2024-12-26T19:18:00Z",
          "energy": 41.472000000067055,
          "capacity": 497.66400000080466
      },
      {
          "time_stamp_utc_ms": 1735240860000,
          "time": "2024-12-26T19:23:00Z",
          "energy": 41.183999999426305,
          "capacity": 494.2079999931157
      },
      {
          "time_stamp_utc_ms": 1735241160000,
          "time": "2024-12-26T19:28:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1735241460000,
          "time": "2024-12-26T19:33:00Z",
          "energy": 40.992000001482666,
          "capacity": 491.90400001779204
      },
      {
          "time_stamp_utc_ms": 1735241760000,
          "time": "2024-12-26T19:38:00Z",
          "energy": 41.47199999913573,
          "capacity": 497.66399998962885
      },
      {
          "time_stamp_utc_ms": 1735242060000,
          "time": "2024-12-26T19:43:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1735242360000,
          "time": "2024-12-26T19:48:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735242660000,
          "time": "2024-12-26T19:53:00Z",
          "energy": 40.80000000074506,
          "capacity": 489.6000000089407
      },
      {
          "time_stamp_utc_ms": 1735242960000,
          "time": "2024-12-26T19:58:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1735243260000,
          "time": "2024-12-26T20:03:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1735243560000,
          "time": "2024-12-26T20:08:00Z",
          "energy": 43.488000000827014,
          "capacity": 521.8560000099242
      },
      {
          "time_stamp_utc_ms": 1735243860000,
          "time": "2024-12-26T20:13:00Z",
          "energy": 42.62399999983609,
          "capacity": 511.4879999980331
      },
      {
          "time_stamp_utc_ms": 1735244160000,
          "time": "2024-12-26T20:18:00Z",
          "energy": 40.51200000010431,
          "capacity": 486.14400000125175
      },
      {
          "time_stamp_utc_ms": 1735244460000,
          "time": "2024-12-26T20:23:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735244760000,
          "time": "2024-12-26T20:28:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1735245060000,
          "time": "2024-12-26T20:33:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1735245360000,
          "time": "2024-12-26T20:38:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1735245660000,
          "time": "2024-12-26T20:43:00Z",
          "energy": 43.39199999906123,
          "capacity": 520.7039999887347
      },
      {
          "time_stamp_utc_ms": 1735245960000,
          "time": "2024-12-26T20:48:00Z",
          "energy": 44.25600000098348,
          "capacity": 531.0720000118018
      },
      {
          "time_stamp_utc_ms": 1735246260000,
          "time": "2024-12-26T20:53:00Z",
          "energy": 42.24000000022352,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1735246560000,
          "time": "2024-12-26T20:58:00Z",
          "energy": 43.39199999906123,
          "capacity": 520.7039999887347
      },
      {
          "time_stamp_utc_ms": 1735246860000,
          "time": "2024-12-26T21:03:00Z",
          "energy": 43.39200000092387,
          "capacity": 520.7040000110865
      },
      {
          "time_stamp_utc_ms": 1735247160000,
          "time": "2024-12-26T21:08:00Z",
          "energy": 43.967999999411404,
          "capacity": 527.6159999929368
      },
      {
          "time_stamp_utc_ms": 1735247460000,
          "time": "2024-12-26T21:13:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735247760000,
          "time": "2024-12-26T21:18:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735248060000,
          "time": "2024-12-26T21:23:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1735248360000,
          "time": "2024-12-26T21:28:00Z",
          "energy": 43.87199999857694,
          "capacity": 526.4639999829233
      },
      {
          "time_stamp_utc_ms": 1735248660000,
          "time": "2024-12-26T21:33:00Z",
          "energy": 43.104000001214445,
          "capacity": 517.2480000145733
      },
      {
          "time_stamp_utc_ms": 1735248960000,
          "time": "2024-12-26T21:38:00Z",
          "energy": 45.21599999908358,
          "capacity": 542.5919999890029
      },
      {
          "time_stamp_utc_ms": 1735249260000,
          "time": "2024-12-26T21:43:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1735249560000,
          "time": "2024-12-26T21:48:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735249860000,
          "time": "2024-12-26T21:53:00Z",
          "energy": 43.488000000827014,
          "capacity": 521.8560000099242
      },
      {
          "time_stamp_utc_ms": 1735250160000,
          "time": "2024-12-26T21:58:00Z",
          "energy": 44.06399999931455,
          "capacity": 528.7679999917746
      },
      {
          "time_stamp_utc_ms": 1735250460000,
          "time": "2024-12-26T22:03:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735250760000,
          "time": "2024-12-26T22:08:00Z",
          "energy": 43.967999999411404,
          "capacity": 527.6159999929368
      },
      {
          "time_stamp_utc_ms": 1735251060000,
          "time": "2024-12-26T22:13:00Z",
          "energy": 45.216000000946224,
          "capacity": 542.5920000113547
      },
      {
          "time_stamp_utc_ms": 1735251360000,
          "time": "2024-12-26T22:18:00Z",
          "energy": 45.119999999180436,
          "capacity": 541.4399999901652
      },
      {
          "time_stamp_utc_ms": 1735251660000,
          "time": "2024-12-26T22:23:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1735251960000,
          "time": "2024-12-26T22:28:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1735252260000,
          "time": "2024-12-26T22:33:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735252560000,
          "time": "2024-12-26T22:38:00Z",
          "energy": 45.98399999924004,
          "capacity": 551.8079999908806
      },
      {
          "time_stamp_utc_ms": 1735252860000,
          "time": "2024-12-26T22:43:00Z",
          "energy": 46.17600000090897,
          "capacity": 554.1120000109076
      },
      {
          "time_stamp_utc_ms": 1735253160000,
          "time": "2024-12-26T22:48:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735253460000,
          "time": "2024-12-26T22:53:00Z",
          "energy": 46.65599999949336,
          "capacity": 559.8719999939203
      },
      {
          "time_stamp_utc_ms": 1735253760000,
          "time": "2024-12-26T22:58:00Z",
          "energy": 44.544000000692904,
          "capacity": 534.528000008315
      },
      {
          "time_stamp_utc_ms": 1735254060000,
          "time": "2024-12-26T23:03:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735254360000,
          "time": "2024-12-26T23:08:00Z",
          "energy": 45.023999999277294,
          "capacity": 540.2879999913275
      },
      {
          "time_stamp_utc_ms": 1735254660000,
          "time": "2024-12-26T23:13:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735254960000,
          "time": "2024-12-26T23:18:00Z",
          "energy": 46.27200000081211,
          "capacity": 555.2640000097454
      },
      {
          "time_stamp_utc_ms": 1735255260000,
          "time": "2024-12-26T23:23:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735255560000,
          "time": "2024-12-26T23:28:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1735255860000,
          "time": "2024-12-26T23:33:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735256160000,
          "time": "2024-12-26T23:38:00Z",
          "energy": 44.44800000078976,
          "capacity": 533.3760000094773
      },
      {
          "time_stamp_utc_ms": 1735256460000,
          "time": "2024-12-26T23:43:00Z",
          "energy": 45.119999999180436,
          "capacity": 541.4399999901652
      },
      {
          "time_stamp_utc_ms": 1735256760000,
          "time": "2024-12-26T23:48:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735257060000,
          "time": "2024-12-26T23:53:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735257360000,
          "time": "2024-12-26T23:58:00Z",
          "energy": 44.448000001721084,
          "capacity": 533.376000020653
      },
      {
          "time_stamp_utc_ms": 1735257660000,
          "time": "2024-12-27T00:03:00Z",
          "energy": 43.19999999832362,
          "capacity": 518.3999999798834
      },
      {
          "time_stamp_utc_ms": 1735257960000,
          "time": "2024-12-27T00:08:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735258260000,
          "time": "2024-12-27T00:13:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1735258560000,
          "time": "2024-12-27T00:18:00Z",
          "energy": 43.967999999411404,
          "capacity": 527.6159999929368
      },
      {
          "time_stamp_utc_ms": 1735258860000,
          "time": "2024-12-27T00:23:00Z",
          "energy": 47.04000000003725,
          "capacity": 564.480000000447
      },
      {
          "time_stamp_utc_ms": 1735259160000,
          "time": "2024-12-27T00:28:00Z",
          "energy": 43.6800000006333,
          "capacity": 524.1600000075996
      },
      {
          "time_stamp_utc_ms": 1735259460000,
          "time": "2024-12-27T00:33:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735259760000,
          "time": "2024-12-27T00:38:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1735260060000,
          "time": "2024-12-27T00:43:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735260360000,
          "time": "2024-12-27T00:48:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1735260660000,
          "time": "2024-12-27T00:53:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1735260960000,
          "time": "2024-12-27T00:58:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735261260000,
          "time": "2024-12-27T01:03:00Z",
          "energy": 43.00799999944866,
          "capacity": 516.0959999933839
      },
      {
          "time_stamp_utc_ms": 1735261560000,
          "time": "2024-12-27T01:08:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735261860000,
          "time": "2024-12-27T01:13:00Z",
          "energy": 43.00799999944866,
          "capacity": 516.0959999933839
      },
      {
          "time_stamp_utc_ms": 1735262160000,
          "time": "2024-12-27T01:18:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1735262460000,
          "time": "2024-12-27T01:23:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1735262760000,
          "time": "2024-12-27T01:28:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735263060000,
          "time": "2024-12-27T01:33:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1735263360000,
          "time": "2024-12-27T01:38:00Z",
          "energy": 43.967999999411404,
          "capacity": 527.6159999929368
      },
      {
          "time_stamp_utc_ms": 1735263660000,
          "time": "2024-12-27T01:43:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1735263960000,
          "time": "2024-12-27T01:48:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735264260000,
          "time": "2024-12-27T01:53:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1735264560000,
          "time": "2024-12-27T01:58:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1735264860000,
          "time": "2024-12-27T02:03:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1735265160000,
          "time": "2024-12-27T02:08:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1735265460000,
          "time": "2024-12-27T02:13:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1735265760000,
          "time": "2024-12-27T02:18:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735266060000,
          "time": "2024-12-27T02:23:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1735266360000,
          "time": "2024-12-27T02:28:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1735266660000,
          "time": "2024-12-27T02:33:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1735266960000,
          "time": "2024-12-27T02:38:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1735267260000,
          "time": "2024-12-27T02:43:00Z",
          "energy": 39.3600000012666,
          "capacity": 472.3200000151992
      },
      {
          "time_stamp_utc_ms": 1735267560000,
          "time": "2024-12-27T02:48:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1735267860000,
          "time": "2024-12-27T02:53:00Z",
          "energy": 40.51200000010431,
          "capacity": 486.14400000125175
      },
      {
          "time_stamp_utc_ms": 1735268160000,
          "time": "2024-12-27T02:58:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1735268460000,
          "time": "2024-12-27T03:03:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1735268760000,
          "time": "2024-12-27T03:08:00Z",
          "energy": 38.880000001750886,
          "capacity": 466.56000002101064
      },
      {
          "time_stamp_utc_ms": 1735269060000,
          "time": "2024-12-27T03:13:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1735269360000,
          "time": "2024-12-27T03:18:00Z",
          "energy": 38.87999999895692,
          "capacity": 466.5599999874831
      },
      {
          "time_stamp_utc_ms": 1735269660000,
          "time": "2024-12-27T03:23:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1735269960000,
          "time": "2024-12-27T03:28:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1735270260000,
          "time": "2024-12-27T03:33:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1735270560000,
          "time": "2024-12-27T03:38:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1735270860000,
          "time": "2024-12-27T03:43:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735271160000,
          "time": "2024-12-27T03:48:00Z",
          "energy": 38.496000001206994,
          "capacity": 461.95200001448393
      },
      {
          "time_stamp_utc_ms": 1735271460000,
          "time": "2024-12-27T03:53:00Z",
          "energy": 38.87999999895692,
          "capacity": 466.5599999874831
      },
      {
          "time_stamp_utc_ms": 1735271760000,
          "time": "2024-12-27T03:58:00Z",
          "energy": 37.152000000700355,
          "capacity": 445.8240000084043
      },
      {
          "time_stamp_utc_ms": 1735272060000,
          "time": "2024-12-27T04:03:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1735272360000,
          "time": "2024-12-27T04:08:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1735272660000,
          "time": "2024-12-27T04:13:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1735272960000,
          "time": "2024-12-27T04:18:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735273260000,
          "time": "2024-12-27T04:23:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1735273560000,
          "time": "2024-12-27T04:28:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1735273860000,
          "time": "2024-12-27T04:33:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1735274160000,
          "time": "2024-12-27T04:38:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1735274460000,
          "time": "2024-12-27T04:43:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1735274760000,
          "time": "2024-12-27T04:48:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1735275060000,
          "time": "2024-12-27T04:53:00Z",
          "energy": 39.93599999882281,
          "capacity": 479.23199998587376
      },
      {
          "time_stamp_utc_ms": 1735275360000,
          "time": "2024-12-27T04:58:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735275660000,
          "time": "2024-12-27T05:03:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735275960000,
          "time": "2024-12-27T05:08:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1735276260000,
          "time": "2024-12-27T05:13:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1735276560000,
          "time": "2024-12-27T05:18:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1735276860000,
          "time": "2024-12-27T05:23:00Z",
          "energy": 38.30399999860674,
          "capacity": 459.6479999832809
      },
      {
          "time_stamp_utc_ms": 1735277160000,
          "time": "2024-12-27T05:28:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1735277460000,
          "time": "2024-12-27T05:33:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1735277760000,
          "time": "2024-12-27T05:38:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1735278060000,
          "time": "2024-12-27T05:43:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1735278360000,
          "time": "2024-12-27T05:48:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1735278660000,
          "time": "2024-12-27T05:53:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1735278960000,
          "time": "2024-12-27T05:58:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1735279260000,
          "time": "2024-12-27T06:03:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1735279560000,
          "time": "2024-12-27T06:08:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1735279860000,
          "time": "2024-12-27T06:13:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1735280160000,
          "time": "2024-12-27T06:18:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1735280460000,
          "time": "2024-12-27T06:23:00Z",
          "energy": 39.35999999847263,
          "capacity": 472.31999998167163
      },
      {
          "time_stamp_utc_ms": 1735280760000,
          "time": "2024-12-27T06:28:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1735281060000,
          "time": "2024-12-27T06:33:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1735281360000,
          "time": "2024-12-27T06:38:00Z",
          "energy": 37.63200000021607,
          "capacity": 451.58400000259286
      },
      {
          "time_stamp_utc_ms": 1735281660000,
          "time": "2024-12-27T06:43:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1735281960000,
          "time": "2024-12-27T06:48:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1735282260000,
          "time": "2024-12-27T06:53:00Z",
          "energy": 37.92000000085682,
          "capacity": 455.04000001028186
      },
      {
          "time_stamp_utc_ms": 1735282560000,
          "time": "2024-12-27T06:58:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1735282860000,
          "time": "2024-12-27T07:03:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735283160000,
          "time": "2024-12-27T07:08:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1735283460000,
          "time": "2024-12-27T07:13:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1735283760000,
          "time": "2024-12-27T07:18:00Z",
          "energy": 37.91999999899417,
          "capacity": 455.03999998793006
      },
      {
          "time_stamp_utc_ms": 1735284060000,
          "time": "2024-12-27T07:23:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1735284360000,
          "time": "2024-12-27T07:28:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1735284660000,
          "time": "2024-12-27T07:33:00Z",
          "energy": 37.727999998256564,
          "capacity": 452.73599997907877
      },
      {
          "time_stamp_utc_ms": 1735284960000,
          "time": "2024-12-27T07:38:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1735285260000,
          "time": "2024-12-27T07:43:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735285560000,
          "time": "2024-12-27T07:48:00Z",
          "energy": 40.704000000841916,
          "capacity": 488.448000010103
      },
      {
          "time_stamp_utc_ms": 1735285860000,
          "time": "2024-12-27T07:53:00Z",
          "energy": 38.2079999987036,
          "capacity": 458.4959999844432
      },
      {
          "time_stamp_utc_ms": 1735286160000,
          "time": "2024-12-27T07:58:00Z",
          "energy": 39.168000001460314,
          "capacity": 470.01600001752377
      },
      {
          "time_stamp_utc_ms": 1735286460000,
          "time": "2024-12-27T08:03:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735286760000,
          "time": "2024-12-27T08:08:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735287060000,
          "time": "2024-12-27T08:13:00Z",
          "energy": 38.87999999895692,
          "capacity": 466.5599999874831
      },
      {
          "time_stamp_utc_ms": 1735287360000,
          "time": "2024-12-27T08:18:00Z",
          "energy": 38.20800000149757,
          "capacity": 458.4960000179708
      },
      {
          "time_stamp_utc_ms": 1735287660000,
          "time": "2024-12-27T08:23:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1735287960000,
          "time": "2024-12-27T08:28:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735288260000,
          "time": "2024-12-27T08:33:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735288560000,
          "time": "2024-12-27T08:38:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735288860000,
          "time": "2024-12-27T08:43:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1735289160000,
          "time": "2024-12-27T08:48:00Z",
          "energy": 38.01600000169128,
          "capacity": 456.1920000202954
      },
      {
          "time_stamp_utc_ms": 1735289460000,
          "time": "2024-12-27T08:53:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1735289760000,
          "time": "2024-12-27T08:58:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1735290060000,
          "time": "2024-12-27T09:03:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1735290360000,
          "time": "2024-12-27T09:08:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735290660000,
          "time": "2024-12-27T09:13:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1735290960000,
          "time": "2024-12-27T09:18:00Z",
          "energy": 38.59200000111014,
          "capacity": 463.10400001332164
      },
      {
          "time_stamp_utc_ms": 1735291260000,
          "time": "2024-12-27T09:23:00Z",
          "energy": 37.43999999854714,
          "capacity": 449.27999998256564
      },
      {
          "time_stamp_utc_ms": 1735291560000,
          "time": "2024-12-27T09:28:00Z",
          "energy": 39.264000001363456,
          "capacity": 471.1680000163615
      },
      {
          "time_stamp_utc_ms": 1735291860000,
          "time": "2024-12-27T09:33:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1735292160000,
          "time": "2024-12-27T09:38:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735292460000,
          "time": "2024-12-27T09:43:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1735292760000,
          "time": "2024-12-27T09:48:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1735293060000,
          "time": "2024-12-27T09:53:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1735293360000,
          "time": "2024-12-27T09:58:00Z",
          "energy": 42.33600000105798,
          "capacity": 508.0320000126958
      },
      {
          "time_stamp_utc_ms": 1735293660000,
          "time": "2024-12-27T10:03:00Z",
          "energy": 42.52799999900162,
          "capacity": 510.33599998801947
      },
      {
          "time_stamp_utc_ms": 1735293960000,
          "time": "2024-12-27T10:08:00Z",
          "energy": 45.216000000946224,
          "capacity": 542.5920000113547
      },
      {
          "time_stamp_utc_ms": 1735294260000,
          "time": "2024-12-27T10:13:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1735294560000,
          "time": "2024-12-27T10:18:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735294860000,
          "time": "2024-12-27T10:23:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735295160000,
          "time": "2024-12-27T10:28:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735295460000,
          "time": "2024-12-27T10:33:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735295760000,
          "time": "2024-12-27T10:38:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735296060000,
          "time": "2024-12-27T10:43:00Z",
          "energy": 44.83199999947101,
          "capacity": 537.9839999936521
      },
      {
          "time_stamp_utc_ms": 1735296360000,
          "time": "2024-12-27T10:48:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1735296660000,
          "time": "2024-12-27T10:53:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735296960000,
          "time": "2024-12-27T10:58:00Z",
          "energy": 45.023999999277294,
          "capacity": 540.2879999913275
      },
      {
          "time_stamp_utc_ms": 1735297260000,
          "time": "2024-12-27T11:03:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735297560000,
          "time": "2024-12-27T11:08:00Z",
          "energy": 45.696000000461936,
          "capacity": 548.3520000055432
      },
      {
          "time_stamp_utc_ms": 1735297860000,
          "time": "2024-12-27T11:13:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735298160000,
          "time": "2024-12-27T11:18:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735298460000,
          "time": "2024-12-27T11:23:00Z",
          "energy": 44.44800000078976,
          "capacity": 533.3760000094773
      },
      {
          "time_stamp_utc_ms": 1735298760000,
          "time": "2024-12-27T11:28:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1735299060000,
          "time": "2024-12-27T11:33:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735299360000,
          "time": "2024-12-27T11:38:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735299660000,
          "time": "2024-12-27T11:43:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735299960000,
          "time": "2024-12-27T11:48:00Z",
          "energy": 45.21599999908358,
          "capacity": 542.5919999890029
      },
      {
          "time_stamp_utc_ms": 1735300260000,
          "time": "2024-12-27T11:53:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735300560000,
          "time": "2024-12-27T11:58:00Z",
          "energy": 45.216000000946224,
          "capacity": 542.5920000113547
      },
      {
          "time_stamp_utc_ms": 1735300860000,
          "time": "2024-12-27T12:03:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1735301160000,
          "time": "2024-12-27T12:08:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1735301460000,
          "time": "2024-12-27T12:13:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735301760000,
          "time": "2024-12-27T12:18:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1735302060000,
          "time": "2024-12-27T12:23:00Z",
          "energy": 43.967999999411404,
          "capacity": 527.6159999929368
      },
      {
          "time_stamp_utc_ms": 1735302360000,
          "time": "2024-12-27T12:28:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735302660000,
          "time": "2024-12-27T12:33:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735302960000,
          "time": "2024-12-27T12:38:00Z",
          "energy": 45.119999999180436,
          "capacity": 541.4399999901652
      },
      {
          "time_stamp_utc_ms": 1735303260000,
          "time": "2024-12-27T12:43:00Z",
          "energy": 44.06400000117719,
          "capacity": 528.7680000141264
      },
      {
          "time_stamp_utc_ms": 1735303560000,
          "time": "2024-12-27T12:48:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735303860000,
          "time": "2024-12-27T12:53:00Z",
          "energy": 45.31199999898672,
          "capacity": 543.7439999878407
      },
      {
          "time_stamp_utc_ms": 1735304160000,
          "time": "2024-12-27T12:58:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735304460000,
          "time": "2024-12-27T13:03:00Z",
          "energy": 46.656000001356006,
          "capacity": 559.8720000162721
      },
      {
          "time_stamp_utc_ms": 1735304760000,
          "time": "2024-12-27T13:08:00Z",
          "energy": 44.6399999987334,
          "capacity": 535.6799999848008
      },
      {
          "time_stamp_utc_ms": 1735305060000,
          "time": "2024-12-27T13:13:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1735305360000,
          "time": "2024-12-27T13:18:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1735305660000,
          "time": "2024-12-27T13:23:00Z",
          "energy": 44.544000000692904,
          "capacity": 534.528000008315
      },
      {
          "time_stamp_utc_ms": 1735305960000,
          "time": "2024-12-27T13:28:00Z",
          "energy": 46.07999999914318,
          "capacity": 552.9599999897183
      },
      {
          "time_stamp_utc_ms": 1735306200000,
          "time": "2024-12-27T13:33:00Z",
          "energy": 35.00799999944866,
          "capacity": 525.1199999917299
      },
      {
          "time_stamp_utc_ms": 1735306500000,
          "time": "2024-12-27T13:38:00Z",
          "energy": 45.92000000085682,
          "capacity": 551.0400000102819
      },
      {
          "time_stamp_utc_ms": 1735306840000,
          "time": "2024-12-27T13:43:00Z",
          "energy": 52.480000000447035,
          "capacity": 555.6706038261681
      },
      {
          "time_stamp_utc_ms": 1735307220000,
          "time": "2024-12-27T13:48:00Z",
          "energy": 59.35999999940395,
          "capacity": 562.3578806178725
      },
      {
          "time_stamp_utc_ms": 1735307490000,
          "time": "2024-12-27T13:53:00Z",
          "energy": 41.519999999552965,
          "capacity": 553.5999999940395
      },
      {
          "time_stamp_utc_ms": 1735307760000,
          "time": "2024-12-27T13:58:00Z",
          "energy": 40.6800000006333,
          "capacity": 542.400000008444
      },
      {
          "time_stamp_utc_ms": 1735308060000,
          "time": "2024-12-27T14:03:00Z",
          "energy": 46.44000000040978,
          "capacity": 557.2800000049174
      },
      {
          "time_stamp_utc_ms": 1735308480000,
          "time": "2024-12-27T14:08:00Z",
          "energy": 65.75999999977648,
          "capacity": 563.657142855227
      },
      {
          "time_stamp_utc_ms": 1735309200000,
          "time": "2024-12-27T14:23:00Z",
          "energy": 109.9199999999255,
          "capacity": 549.5999999996274
      },
      {
          "time_stamp_utc_ms": 1735310100000,
          "time": "2024-12-27T14:38:00Z",
          "energy": 131.04000000003725,
          "capacity": 524.160000000149
      },
      {
          "time_stamp_utc_ms": 1735310580000,
          "time": "2024-12-27T14:43:00Z",
          "energy": 71.04000000003725,
          "capacity": 532.8000000002794
      },
      {
          "time_stamp_utc_ms": 1735314690000,
          "time": "2024-12-27T15:53:00Z",
          "energy": 608.160000000149,
          "capacity": 532.6948905110794
      },
      {
          "time_stamp_utc_ms": 1735320840000,
          "time": "2024-12-27T17:38:00Z",
          "energy": 862.0800000000745,
          "capacity": 504.6321951219948
      },
      {
          "time_stamp_utc_ms": 1735324320000,
          "time": "2024-12-27T18:33:00Z",
          "energy": 459.35999999940395,
          "capacity": 475.19999999938335
      },
      {
          "time_stamp_utc_ms": 1735325520000,
          "time": "2024-12-27T18:53:00Z",
          "energy": 163.6800000006333,
          "capacity": 491.0400000018999
      },
      {
          "time_stamp_utc_ms": 1735332180000,
          "time": "2024-12-27T20:43:00Z",
          "energy": 914.3999999994412,
          "capacity": 494.2702702699682
      },
      {
          "time_stamp_utc_ms": 1735333560000,
          "time": "2024-12-27T21:08:00Z",
          "energy": 199.6800000006333,
          "capacity": 520.904347827739
      },
      {
          "time_stamp_utc_ms": 1735335390000,
          "time": "2024-12-27T21:38:00Z",
          "energy": 269.1599999992177,
          "capacity": 529.4950819656741
      },
      {
          "time_stamp_utc_ms": 1735335660000,
          "time": "2024-12-27T21:43:00Z",
          "energy": 38.32799999974668,
          "capacity": 511.03999999662244
      },
      {
          "time_stamp_utc_ms": 1735335960000,
          "time": "2024-12-27T21:48:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1735336260000,
          "time": "2024-12-27T21:53:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735336560000,
          "time": "2024-12-27T21:58:00Z",
          "energy": 44.64000000059605,
          "capacity": 535.6800000071527
      },
      {
          "time_stamp_utc_ms": 1735336860000,
          "time": "2024-12-27T22:03:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735337160000,
          "time": "2024-12-27T22:08:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735337460000,
          "time": "2024-12-27T22:13:00Z",
          "energy": 46.4640000006184,
          "capacity": 557.5680000074208
      },
      {
          "time_stamp_utc_ms": 1735337760000,
          "time": "2024-12-27T22:18:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735338060000,
          "time": "2024-12-27T22:23:00Z",
          "energy": 46.84800000023097,
          "capacity": 562.1760000027716
      },
      {
          "time_stamp_utc_ms": 1735338360000,
          "time": "2024-12-27T22:28:00Z",
          "energy": 46.847999999299645,
          "capacity": 562.1759999915957
      },
      {
          "time_stamp_utc_ms": 1735338660000,
          "time": "2024-12-27T22:33:00Z",
          "energy": 45.88800000119954,
          "capacity": 550.6560000143945
      },
      {
          "time_stamp_utc_ms": 1735338960000,
          "time": "2024-12-27T22:38:00Z",
          "energy": 46.36799999978393,
          "capacity": 556.4159999974072
      },
      {
          "time_stamp_utc_ms": 1735339260000,
          "time": "2024-12-27T22:43:00Z",
          "energy": 46.463999999687076,
          "capacity": 557.5679999962449
      },
      {
          "time_stamp_utc_ms": 1735339560000,
          "time": "2024-12-27T22:48:00Z",
          "energy": 45.696000000461936,
          "capacity": 548.3520000055432
      },
      {
          "time_stamp_utc_ms": 1735339860000,
          "time": "2024-12-27T22:53:00Z",
          "energy": 46.36799999978393,
          "capacity": 556.4159999974072
      },
      {
          "time_stamp_utc_ms": 1735340160000,
          "time": "2024-12-27T22:58:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1735340460000,
          "time": "2024-12-27T23:03:00Z",
          "energy": 47.13599999900907,
          "capacity": 565.6319999881089
      },
      {
          "time_stamp_utc_ms": 1735340760000,
          "time": "2024-12-27T23:08:00Z",
          "energy": 45.02400000113994,
          "capacity": 540.2880000136793
      },
      {
          "time_stamp_utc_ms": 1735341060000,
          "time": "2024-12-27T23:13:00Z",
          "energy": 46.55999999959022,
          "capacity": 558.7199999950826
      },
      {
          "time_stamp_utc_ms": 1735341360000,
          "time": "2024-12-27T23:18:00Z",
          "energy": 47.13600000087172,
          "capacity": 565.6320000104606
      },
      {
          "time_stamp_utc_ms": 1735341660000,
          "time": "2024-12-27T23:23:00Z",
          "energy": 46.080000000074506,
          "capacity": 552.9600000008941
      },
      {
          "time_stamp_utc_ms": 1735341960000,
          "time": "2024-12-27T23:28:00Z",
          "energy": 46.55999999959022,
          "capacity": 558.7199999950826
      },
      {
          "time_stamp_utc_ms": 1735342260000,
          "time": "2024-12-27T23:33:00Z",
          "energy": 45.791999999433756,
          "capacity": 549.5039999932052
      },
      {
          "time_stamp_utc_ms": 1735342560000,
          "time": "2024-12-27T23:38:00Z",
          "energy": 46.752000000327826,
          "capacity": 561.0240000039339
      },
      {
          "time_stamp_utc_ms": 1735342860000,
          "time": "2024-12-27T23:43:00Z",
          "energy": 46.463999999687076,
          "capacity": 557.5679999962449
      },
      {
          "time_stamp_utc_ms": 1735343160000,
          "time": "2024-12-27T23:48:00Z",
          "energy": 46.08000000100583,
          "capacity": 552.9600000120699
      },
      {
          "time_stamp_utc_ms": 1735343460000,
          "time": "2024-12-27T23:53:00Z",
          "energy": 46.94399999920279,
          "capacity": 563.3279999904335
      },
      {
          "time_stamp_utc_ms": 1735343760000,
          "time": "2024-12-27T23:58:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735344060000,
          "time": "2024-12-28T00:03:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735344360000,
          "time": "2024-12-28T00:08:00Z",
          "energy": 45.98399999924004,
          "capacity": 551.8079999908806
      },
      {
          "time_stamp_utc_ms": 1735344660000,
          "time": "2024-12-28T00:13:00Z",
          "energy": 44.54400000162423,
          "capacity": 534.5280000194907
      },
      {
          "time_stamp_utc_ms": 1735344960000,
          "time": "2024-12-28T00:18:00Z",
          "energy": 46.080000000074506,
          "capacity": 552.9600000008941
      },
      {
          "time_stamp_utc_ms": 1735345260000,
          "time": "2024-12-28T00:23:00Z",
          "energy": 44.735999998636544,
          "capacity": 536.8319999836385
      },
      {
          "time_stamp_utc_ms": 1735345560000,
          "time": "2024-12-28T00:28:00Z",
          "energy": 45.023999999277294,
          "capacity": 540.2879999913275
      },
      {
          "time_stamp_utc_ms": 1735345860000,
          "time": "2024-12-28T00:33:00Z",
          "energy": 45.40800000075251,
          "capacity": 544.8960000090301
      },
      {
          "time_stamp_utc_ms": 1735346160000,
          "time": "2024-12-28T00:38:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735346460000,
          "time": "2024-12-28T00:43:00Z",
          "energy": 46.463999999687076,
          "capacity": 557.5679999962449
      },
      {
          "time_stamp_utc_ms": 1735346760000,
          "time": "2024-12-28T00:48:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1735347060000,
          "time": "2024-12-28T00:53:00Z",
          "energy": 47.61600000038743,
          "capacity": 571.3920000046492
      },
      {
          "time_stamp_utc_ms": 1735347360000,
          "time": "2024-12-28T00:58:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1735347660000,
          "time": "2024-12-28T01:03:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735347960000,
          "time": "2024-12-28T01:08:00Z",
          "energy": 45.02400000113994,
          "capacity": 540.2880000136793
      },
      {
          "time_stamp_utc_ms": 1735348260000,
          "time": "2024-12-28T01:13:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735348560000,
          "time": "2024-12-28T01:18:00Z",
          "energy": 44.92799999844283,
          "capacity": 539.135999981314
      },
      {
          "time_stamp_utc_ms": 1735348860000,
          "time": "2024-12-28T01:23:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1735349160000,
          "time": "2024-12-28T01:28:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735349460000,
          "time": "2024-12-28T01:33:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1735349760000,
          "time": "2024-12-28T01:38:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1735350060000,
          "time": "2024-12-28T01:43:00Z",
          "energy": 43.872000000439584,
          "capacity": 526.464000005275
      },
      {
          "time_stamp_utc_ms": 1735350360000,
          "time": "2024-12-28T01:48:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1735350660000,
          "time": "2024-12-28T01:53:00Z",
          "energy": 42.62399999983609,
          "capacity": 511.4879999980331
      },
      {
          "time_stamp_utc_ms": 1735350960000,
          "time": "2024-12-28T01:58:00Z",
          "energy": 42.04800000041723,
          "capacity": 504.57600000500685
      },
      {
          "time_stamp_utc_ms": 1735351260000,
          "time": "2024-12-28T02:03:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1735351560000,
          "time": "2024-12-28T02:08:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1735351860000,
          "time": "2024-12-28T02:13:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1735352160000,
          "time": "2024-12-28T02:18:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1735352460000,
          "time": "2024-12-28T02:23:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735352760000,
          "time": "2024-12-28T02:28:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1735353060000,
          "time": "2024-12-28T02:33:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735353360000,
          "time": "2024-12-28T02:38:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1735353660000,
          "time": "2024-12-28T02:43:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1735353960000,
          "time": "2024-12-28T02:48:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1735354260000,
          "time": "2024-12-28T02:53:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735354560000,
          "time": "2024-12-28T02:58:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1735354860000,
          "time": "2024-12-28T03:03:00Z",
          "energy": 41.183999999426305,
          "capacity": 494.2079999931157
      },
      {
          "time_stamp_utc_ms": 1735355160000,
          "time": "2024-12-28T03:08:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735355460000,
          "time": "2024-12-28T03:13:00Z",
          "energy": 41.664000001735985,
          "capacity": 499.9680000208318
      },
      {
          "time_stamp_utc_ms": 1735355760000,
          "time": "2024-12-28T03:18:00Z",
          "energy": 39.071999998763204,
          "capacity": 468.8639999851585
      },
      {
          "time_stamp_utc_ms": 1735356060000,
          "time": "2024-12-28T03:23:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1735356360000,
          "time": "2024-12-28T03:28:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735356660000,
          "time": "2024-12-28T03:33:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735356960000,
          "time": "2024-12-28T03:38:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735357260000,
          "time": "2024-12-28T03:43:00Z",
          "energy": 38.30399999860674,
          "capacity": 459.6479999832809
      },
      {
          "time_stamp_utc_ms": 1735357560000,
          "time": "2024-12-28T03:48:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1735357860000,
          "time": "2024-12-28T03:53:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1735358160000,
          "time": "2024-12-28T03:58:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1735358460000,
          "time": "2024-12-28T04:03:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1735358760000,
          "time": "2024-12-28T04:08:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735359060000,
          "time": "2024-12-28T04:13:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1735359360000,
          "time": "2024-12-28T04:18:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735359660000,
          "time": "2024-12-28T04:23:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735359960000,
          "time": "2024-12-28T04:28:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1735360260000,
          "time": "2024-12-28T04:33:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1735360560000,
          "time": "2024-12-28T04:38:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735360860000,
          "time": "2024-12-28T04:43:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1735361160000,
          "time": "2024-12-28T04:48:00Z",
          "energy": 36.09599999990314,
          "capacity": 433.1519999988377
      },
      {
          "time_stamp_utc_ms": 1735361460000,
          "time": "2024-12-28T04:53:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1735361760000,
          "time": "2024-12-28T04:58:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735362060000,
          "time": "2024-12-28T05:03:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735362360000,
          "time": "2024-12-28T05:08:00Z",
          "energy": 40.3199999993667,
          "capacity": 483.8399999924004
      },
      {
          "time_stamp_utc_ms": 1735362660000,
          "time": "2024-12-28T05:13:00Z",
          "energy": 38.30400000140071,
          "capacity": 459.6480000168085
      },
      {
          "time_stamp_utc_ms": 1735362960000,
          "time": "2024-12-28T05:18:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1735363260000,
          "time": "2024-12-28T05:23:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735363560000,
          "time": "2024-12-28T05:28:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735363860000,
          "time": "2024-12-28T05:33:00Z",
          "energy": 38.87999999895692,
          "capacity": 466.5599999874831
      },
      {
          "time_stamp_utc_ms": 1735364160000,
          "time": "2024-12-28T05:38:00Z",
          "energy": 38.40000000130385,
          "capacity": 460.8000000156462
      },
      {
          "time_stamp_utc_ms": 1735364460000,
          "time": "2024-12-28T05:43:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735364760000,
          "time": "2024-12-28T05:48:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735365060000,
          "time": "2024-12-28T05:53:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1735365360000,
          "time": "2024-12-28T05:58:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735365660000,
          "time": "2024-12-28T06:03:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1735365960000,
          "time": "2024-12-28T06:08:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735366260000,
          "time": "2024-12-28T06:13:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735366560000,
          "time": "2024-12-28T06:18:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1735366860000,
          "time": "2024-12-28T06:23:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735367160000,
          "time": "2024-12-28T06:28:00Z",
          "energy": 37.82399999909103,
          "capacity": 453.88799998909235
      },
      {
          "time_stamp_utc_ms": 1735367460000,
          "time": "2024-12-28T06:33:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735367760000,
          "time": "2024-12-28T06:38:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1735368060000,
          "time": "2024-12-28T06:43:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1735368360000,
          "time": "2024-12-28T06:48:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735368660000,
          "time": "2024-12-28T06:53:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1735368960000,
          "time": "2024-12-28T06:58:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1735369260000,
          "time": "2024-12-28T07:03:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1735369560000,
          "time": "2024-12-28T07:08:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735369860000,
          "time": "2024-12-28T07:13:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1735370160000,
          "time": "2024-12-28T07:18:00Z",
          "energy": 38.68800000101328,
          "capacity": 464.25600001215935
      },
      {
          "time_stamp_utc_ms": 1735370460000,
          "time": "2024-12-28T07:23:00Z",
          "energy": 39.93599999882281,
          "capacity": 479.23199998587376
      },
      {
          "time_stamp_utc_ms": 1735370760000,
          "time": "2024-12-28T07:28:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1735371060000,
          "time": "2024-12-28T07:33:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1735371360000,
          "time": "2024-12-28T07:38:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735371660000,
          "time": "2024-12-28T07:43:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1735371960000,
          "time": "2024-12-28T07:48:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735372260000,
          "time": "2024-12-28T07:53:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1735372560000,
          "time": "2024-12-28T07:58:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1735372860000,
          "time": "2024-12-28T08:03:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1735373160000,
          "time": "2024-12-28T08:08:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1735373460000,
          "time": "2024-12-28T08:13:00Z",
          "energy": 40.31999999843538,
          "capacity": 483.83999998122454
      },
      {
          "time_stamp_utc_ms": 1735373760000,
          "time": "2024-12-28T08:18:00Z",
          "energy": 38.784000001847744,
          "capacity": 465.4080000221729
      },
      {
          "time_stamp_utc_ms": 1735374060000,
          "time": "2024-12-28T08:23:00Z",
          "energy": 38.783999999053776,
          "capacity": 465.4079999886454
      },
      {
          "time_stamp_utc_ms": 1735374360000,
          "time": "2024-12-28T08:28:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1735374660000,
          "time": "2024-12-28T08:33:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735374960000,
          "time": "2024-12-28T08:38:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735375260000,
          "time": "2024-12-28T08:43:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1735375560000,
          "time": "2024-12-28T08:48:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1735375860000,
          "time": "2024-12-28T08:53:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735376160000,
          "time": "2024-12-28T08:58:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1735376460000,
          "time": "2024-12-28T09:03:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1735376760000,
          "time": "2024-12-28T09:08:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1735377060000,
          "time": "2024-12-28T09:13:00Z",
          "energy": 38.87999999895692,
          "capacity": 466.5599999874831
      },
      {
          "time_stamp_utc_ms": 1735377360000,
          "time": "2024-12-28T09:18:00Z",
          "energy": 40.99200000055134,
          "capacity": 491.9040000066161
      },
      {
          "time_stamp_utc_ms": 1735377660000,
          "time": "2024-12-28T09:23:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1735377960000,
          "time": "2024-12-28T09:28:00Z",
          "energy": 41.08800000138581,
          "capacity": 493.05600001662975
      },
      {
          "time_stamp_utc_ms": 1735378260000,
          "time": "2024-12-28T09:33:00Z",
          "energy": 38.783999999053776,
          "capacity": 465.4079999886454
      },
      {
          "time_stamp_utc_ms": 1735378560000,
          "time": "2024-12-28T09:38:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735378860000,
          "time": "2024-12-28T09:43:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1735379160000,
          "time": "2024-12-28T09:48:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735379460000,
          "time": "2024-12-28T09:53:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1735379760000,
          "time": "2024-12-28T09:58:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1735380060000,
          "time": "2024-12-28T10:03:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1735380360000,
          "time": "2024-12-28T10:08:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735380660000,
          "time": "2024-12-28T10:13:00Z",
          "energy": 39.84000000078231,
          "capacity": 478.08000000938773
      },
      {
          "time_stamp_utc_ms": 1735380960000,
          "time": "2024-12-28T10:18:00Z",
          "energy": 40.51199999824166,
          "capacity": 486.14399997889996
      },
      {
          "time_stamp_utc_ms": 1735381260000,
          "time": "2024-12-28T10:23:00Z",
          "energy": 40.03200000151992,
          "capacity": 480.384000018239
      },
      {
          "time_stamp_utc_ms": 1735381560000,
          "time": "2024-12-28T10:28:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1735381860000,
          "time": "2024-12-28T10:33:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1735382160000,
          "time": "2024-12-28T10:38:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735382460000,
          "time": "2024-12-28T10:43:00Z",
          "energy": 40.51200000010431,
          "capacity": 486.14400000125175
      },
      {
          "time_stamp_utc_ms": 1735382760000,
          "time": "2024-12-28T10:48:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1735383060000,
          "time": "2024-12-28T10:53:00Z",
          "energy": 39.74400000087917,
          "capacity": 476.92800001055
      },
      {
          "time_stamp_utc_ms": 1735383360000,
          "time": "2024-12-28T10:58:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1735383660000,
          "time": "2024-12-28T11:03:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1735383960000,
          "time": "2024-12-28T11:08:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1735384260000,
          "time": "2024-12-28T11:13:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1735384560000,
          "time": "2024-12-28T11:18:00Z",
          "energy": 40.51200000010431,
          "capacity": 486.14400000125175
      },
      {
          "time_stamp_utc_ms": 1735384860000,
          "time": "2024-12-28T11:23:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1735385160000,
          "time": "2024-12-28T11:28:00Z",
          "energy": 40.12800000142306,
          "capacity": 481.53600001707673
      },
      {
          "time_stamp_utc_ms": 1735385460000,
          "time": "2024-12-28T11:33:00Z",
          "energy": 43.007999998517334,
          "capacity": 516.095999982208
      },
      {
          "time_stamp_utc_ms": 1735385760000,
          "time": "2024-12-28T11:38:00Z",
          "energy": 40.8960000006482,
          "capacity": 490.7520000077784
      },
      {
          "time_stamp_utc_ms": 1735386060000,
          "time": "2024-12-28T11:43:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1735386360000,
          "time": "2024-12-28T11:48:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735386660000,
          "time": "2024-12-28T11:53:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1735386960000,
          "time": "2024-12-28T11:58:00Z",
          "energy": 40.60799999907613,
          "capacity": 487.29599998891354
      },
      {
          "time_stamp_utc_ms": 1735387260000,
          "time": "2024-12-28T12:03:00Z",
          "energy": 40.80000000074506,
          "capacity": 489.6000000089407
      },
      {
          "time_stamp_utc_ms": 1735387560000,
          "time": "2024-12-28T12:08:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1735387860000,
          "time": "2024-12-28T12:13:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1735388160000,
          "time": "2024-12-28T12:18:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735388460000,
          "time": "2024-12-28T12:23:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1735388760000,
          "time": "2024-12-28T12:28:00Z",
          "energy": 40.51200000103563,
          "capacity": 486.14400001242757
      },
      {
          "time_stamp_utc_ms": 1735389060000,
          "time": "2024-12-28T12:33:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735389360000,
          "time": "2024-12-28T12:38:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1735389660000,
          "time": "2024-12-28T12:43:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1735389960000,
          "time": "2024-12-28T12:48:00Z",
          "energy": 40.12799999862909,
          "capacity": 481.5359999835491
      },
      {
          "time_stamp_utc_ms": 1735390260000,
          "time": "2024-12-28T12:53:00Z",
          "energy": 40.12800000142306,
          "capacity": 481.53600001707673
      },
      {
          "time_stamp_utc_ms": 1735390560000,
          "time": "2024-12-28T12:58:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735390860000,
          "time": "2024-12-28T13:03:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1735391160000,
          "time": "2024-12-28T13:08:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1735391460000,
          "time": "2024-12-28T13:13:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1735391760000,
          "time": "2024-12-28T13:18:00Z",
          "energy": 39.84000000078231,
          "capacity": 478.08000000938773
      },
      {
          "time_stamp_utc_ms": 1735392060000,
          "time": "2024-12-28T13:23:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735392360000,
          "time": "2024-12-28T13:28:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735392660000,
          "time": "2024-12-28T13:33:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1735392960000,
          "time": "2024-12-28T13:38:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1735393260000,
          "time": "2024-12-28T13:43:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735393560000,
          "time": "2024-12-28T13:48:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1735393860000,
          "time": "2024-12-28T13:53:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1735394160000,
          "time": "2024-12-28T13:58:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1735394460000,
          "time": "2024-12-28T14:03:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1735394760000,
          "time": "2024-12-28T14:08:00Z",
          "energy": 38.783999999053776,
          "capacity": 465.4079999886454
      },
      {
          "time_stamp_utc_ms": 1735395060000,
          "time": "2024-12-28T14:13:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1735395360000,
          "time": "2024-12-28T14:18:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1735395660000,
          "time": "2024-12-28T14:23:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1735395960000,
          "time": "2024-12-28T14:28:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735396260000,
          "time": "2024-12-28T14:33:00Z",
          "energy": 37.05600000079721,
          "capacity": 444.6720000095666
      },
      {
          "time_stamp_utc_ms": 1735396560000,
          "time": "2024-12-28T14:38:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1735396860000,
          "time": "2024-12-28T14:43:00Z",
          "energy": 36.287999998778105,
          "capacity": 435.45599998533726
      },
      {
          "time_stamp_utc_ms": 1735397160000,
          "time": "2024-12-28T14:48:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1735397460000,
          "time": "2024-12-28T14:53:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1735397760000,
          "time": "2024-12-28T14:58:00Z",
          "energy": 36.38399999961257,
          "capacity": 436.60799999535084
      },
      {
          "time_stamp_utc_ms": 1735398060000,
          "time": "2024-12-28T15:03:00Z",
          "energy": 36.19200000073761,
          "capacity": 434.3040000088513
      },
      {
          "time_stamp_utc_ms": 1735398360000,
          "time": "2024-12-28T15:08:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1735398660000,
          "time": "2024-12-28T15:13:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1735398960000,
          "time": "2024-12-28T15:18:00Z",
          "energy": 35.13600000087172,
          "capacity": 421.6320000104606
      },
      {
          "time_stamp_utc_ms": 1735399260000,
          "time": "2024-12-28T15:23:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735399560000,
          "time": "2024-12-28T15:28:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1735399860000,
          "time": "2024-12-28T15:33:00Z",
          "energy": 37.82399999909103,
          "capacity": 453.88799998909235
      },
      {
          "time_stamp_utc_ms": 1735400160000,
          "time": "2024-12-28T15:38:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1735400460000,
          "time": "2024-12-28T15:43:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1735400760000,
          "time": "2024-12-28T15:48:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735401060000,
          "time": "2024-12-28T15:53:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1735401360000,
          "time": "2024-12-28T15:58:00Z",
          "energy": 33.98400000017136,
          "capacity": 407.8080000020564
      },
      {
          "time_stamp_utc_ms": 1735401660000,
          "time": "2024-12-28T16:03:00Z",
          "energy": 33.50400000065565,
          "capacity": 402.04800000786787
      },
      {
          "time_stamp_utc_ms": 1735401960000,
          "time": "2024-12-28T16:08:00Z",
          "energy": 33.311999999918044,
          "capacity": 399.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735402260000,
          "time": "2024-12-28T16:13:00Z",
          "energy": 36.575999999418855,
          "capacity": 438.91199999302626
      },
      {
          "time_stamp_utc_ms": 1735402560000,
          "time": "2024-12-28T16:18:00Z",
          "energy": 35.90400000102818,
          "capacity": 430.84800001233816
      },
      {
          "time_stamp_utc_ms": 1735402860000,
          "time": "2024-12-28T16:23:00Z",
          "energy": 36.19199999887496,
          "capacity": 434.30399998649955
      },
      {
          "time_stamp_utc_ms": 1735403160000,
          "time": "2024-12-28T16:28:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1735403460000,
          "time": "2024-12-28T16:33:00Z",
          "energy": 33.024000000208616,
          "capacity": 396.28800000250345
      },
      {
          "time_stamp_utc_ms": 1735403760000,
          "time": "2024-12-28T16:38:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1735404060000,
          "time": "2024-12-28T16:43:00Z",
          "energy": 33.79200000036508,
          "capacity": 405.504000004381
      },
      {
          "time_stamp_utc_ms": 1735404360000,
          "time": "2024-12-28T16:48:00Z",
          "energy": 35.80799999833107,
          "capacity": 429.6959999799729
      },
      {
          "time_stamp_utc_ms": 1735404660000,
          "time": "2024-12-28T16:53:00Z",
          "energy": 36.28800000157207,
          "capacity": 435.4560000188649
      },
      {
          "time_stamp_utc_ms": 1735404960000,
          "time": "2024-12-28T16:58:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1735405260000,
          "time": "2024-12-28T17:03:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1735405560000,
          "time": "2024-12-28T17:08:00Z",
          "energy": 33.119999999180436,
          "capacity": 397.43999999016523
      },
      {
          "time_stamp_utc_ms": 1735405860000,
          "time": "2024-12-28T17:13:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1735406160000,
          "time": "2024-12-28T17:18:00Z",
          "energy": 35.23199999984354,
          "capacity": 422.78399999812245
      },
      {
          "time_stamp_utc_ms": 1735406460000,
          "time": "2024-12-28T17:23:00Z",
          "energy": 33.98400000017136,
          "capacity": 407.8080000020564
      },
      {
          "time_stamp_utc_ms": 1735406760000,
          "time": "2024-12-28T17:28:00Z",
          "energy": 35.61600000038743,
          "capacity": 427.39200000464916
      },
      {
          "time_stamp_utc_ms": 1735407060000,
          "time": "2024-12-28T17:33:00Z",
          "energy": 34.65599999949336,
          "capacity": 415.8719999939204
      },
      {
          "time_stamp_utc_ms": 1735407360000,
          "time": "2024-12-28T17:38:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1735407660000,
          "time": "2024-12-28T17:43:00Z",
          "energy": 35.13600000087172,
          "capacity": 421.6320000104606
      },
      {
          "time_stamp_utc_ms": 1735407960000,
          "time": "2024-12-28T17:48:00Z",
          "energy": 32.15999999921769,
          "capacity": 385.91999999061227
      },
      {
          "time_stamp_utc_ms": 1735408260000,
          "time": "2024-12-28T17:53:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1735408560000,
          "time": "2024-12-28T17:58:00Z",
          "energy": 30.720000000670552,
          "capacity": 368.6400000080466
      },
      {
          "time_stamp_utc_ms": 1735408860000,
          "time": "2024-12-28T18:03:00Z",
          "energy": 33.50399999972433,
          "capacity": 402.047999996692
      },
      {
          "time_stamp_utc_ms": 1735409160000,
          "time": "2024-12-28T18:08:00Z",
          "energy": 34.94400000013411,
          "capacity": 419.3280000016093
      },
      {
          "time_stamp_utc_ms": 1735409460000,
          "time": "2024-12-28T18:13:00Z",
          "energy": 34.7519999993965,
          "capacity": 417.0239999927581
      },
      {
          "time_stamp_utc_ms": 1735409760000,
          "time": "2024-12-28T18:18:00Z",
          "energy": 35.61600000038743,
          "capacity": 427.39200000464916
      },
      {
          "time_stamp_utc_ms": 1735410060000,
          "time": "2024-12-28T18:23:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1735410360000,
          "time": "2024-12-28T18:28:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1735410660000,
          "time": "2024-12-28T18:33:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1735410960000,
          "time": "2024-12-28T18:38:00Z",
          "energy": 32.06400000117719,
          "capacity": 384.76800001412636
      },
      {
          "time_stamp_utc_ms": 1735411260000,
          "time": "2024-12-28T18:43:00Z",
          "energy": 33.599999997764826,
          "capacity": 403.1999999731779
      },
      {
          "time_stamp_utc_ms": 1735411560000,
          "time": "2024-12-28T18:48:00Z",
          "energy": 31.29600000102073,
          "capacity": 375.55200001224875
      },
      {
          "time_stamp_utc_ms": 1735411860000,
          "time": "2024-12-28T18:53:00Z",
          "energy": 30.33600000012666,
          "capacity": 364.0320000015199
      },
      {
          "time_stamp_utc_ms": 1735412160000,
          "time": "2024-12-28T18:58:00Z",
          "energy": 31.104000000283122,
          "capacity": 373.24800000339746
      },
      {
          "time_stamp_utc_ms": 1735412460000,
          "time": "2024-12-28T19:03:00Z",
          "energy": 29.855999998748302,
          "capacity": 358.2719999849797
      },
      {
          "time_stamp_utc_ms": 1735412760000,
          "time": "2024-12-28T19:08:00Z",
          "energy": 29.856000000610948,
          "capacity": 358.27200000733137
      },
      {
          "time_stamp_utc_ms": 1735413060000,
          "time": "2024-12-28T19:13:00Z",
          "energy": 31.87199999950826,
          "capacity": 382.46399999409914
      },
      {
          "time_stamp_utc_ms": 1735413360000,
          "time": "2024-12-28T19:18:00Z",
          "energy": 30.912000000476837,
          "capacity": 370.94400000572205
      },
      {
          "time_stamp_utc_ms": 1735413660000,
          "time": "2024-12-28T19:23:00Z",
          "energy": 31.967999999411404,
          "capacity": 383.61599999293685
      },
      {
          "time_stamp_utc_ms": 1735413960000,
          "time": "2024-12-28T19:28:00Z",
          "energy": 32.35200000088662,
          "capacity": 388.2240000106395
      },
      {
          "time_stamp_utc_ms": 1735414260000,
          "time": "2024-12-28T19:33:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1735414560000,
          "time": "2024-12-28T19:38:00Z",
          "energy": 35.328000000678,
          "capacity": 423.93600000813603
      },
      {
          "time_stamp_utc_ms": 1735414860000,
          "time": "2024-12-28T19:43:00Z",
          "energy": 33.40799999888986,
          "capacity": 400.89599998667836
      },
      {
          "time_stamp_utc_ms": 1735415160000,
          "time": "2024-12-28T19:48:00Z",
          "energy": 35.52000000048429,
          "capacity": 426.24000000581145
      },
      {
          "time_stamp_utc_ms": 1735415460000,
          "time": "2024-12-28T19:53:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1735415760000,
          "time": "2024-12-28T19:58:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1735416060000,
          "time": "2024-12-28T20:03:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1735416360000,
          "time": "2024-12-28T20:08:00Z",
          "energy": 35.90400000102818,
          "capacity": 430.84800001233816
      },
      {
          "time_stamp_utc_ms": 1735416660000,
          "time": "2024-12-28T20:13:00Z",
          "energy": 36.575999999418855,
          "capacity": 438.91199999302626
      },
      {
          "time_stamp_utc_ms": 1735416960000,
          "time": "2024-12-28T20:18:00Z",
          "energy": 34.368000000715256,
          "capacity": 412.41600000858307
      },
      {
          "time_stamp_utc_ms": 1735417260000,
          "time": "2024-12-28T20:23:00Z",
          "energy": 34.7519999993965,
          "capacity": 417.0239999927581
      },
      {
          "time_stamp_utc_ms": 1735417560000,
          "time": "2024-12-28T20:28:00Z",
          "energy": 34.84800000023097,
          "capacity": 418.1760000027716
      },
      {
          "time_stamp_utc_ms": 1735417860000,
          "time": "2024-12-28T20:33:00Z",
          "energy": 34.65599999949336,
          "capacity": 415.8719999939204
      },
      {
          "time_stamp_utc_ms": 1735418160000,
          "time": "2024-12-28T20:38:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1735418460000,
          "time": "2024-12-28T20:43:00Z",
          "energy": 35.80800000112504,
          "capacity": 429.69600001350045
      },
      {
          "time_stamp_utc_ms": 1735418760000,
          "time": "2024-12-28T20:48:00Z",
          "energy": 36.09599999897182,
          "capacity": 433.15199998766184
      },
      {
          "time_stamp_utc_ms": 1735419060000,
          "time": "2024-12-28T20:53:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1735419360000,
          "time": "2024-12-28T20:58:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1735419660000,
          "time": "2024-12-28T21:03:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1735419960000,
          "time": "2024-12-28T21:08:00Z",
          "energy": 34.847999999299645,
          "capacity": 418.1759999915958
      },
      {
          "time_stamp_utc_ms": 1735420260000,
          "time": "2024-12-28T21:13:00Z",
          "energy": 35.80800000112504,
          "capacity": 429.69600001350045
      },
      {
          "time_stamp_utc_ms": 1735420560000,
          "time": "2024-12-28T21:18:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735420860000,
          "time": "2024-12-28T21:23:00Z",
          "energy": 36.09599999897182,
          "capacity": 433.15199998766184
      },
      {
          "time_stamp_utc_ms": 1735421160000,
          "time": "2024-12-28T21:28:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1735421460000,
          "time": "2024-12-28T21:33:00Z",
          "energy": 35.040000000968575,
          "capacity": 420.4800000116229
      },
      {
          "time_stamp_utc_ms": 1735421760000,
          "time": "2024-12-28T21:38:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1735422060000,
          "time": "2024-12-28T21:43:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1735422360000,
          "time": "2024-12-28T21:48:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1735422660000,
          "time": "2024-12-28T21:53:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1735422960000,
          "time": "2024-12-28T21:58:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1735423260000,
          "time": "2024-12-28T22:03:00Z",
          "energy": 35.32799999974668,
          "capacity": 423.93599999696016
      },
      {
          "time_stamp_utc_ms": 1735423560000,
          "time": "2024-12-28T22:08:00Z",
          "energy": 37.72800000105053,
          "capacity": 452.73600001260644
      },
      {
          "time_stamp_utc_ms": 1735423860000,
          "time": "2024-12-28T22:13:00Z",
          "energy": 34.847999999299645,
          "capacity": 418.1759999915958
      },
      {
          "time_stamp_utc_ms": 1735424160000,
          "time": "2024-12-28T22:18:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1735424460000,
          "time": "2024-12-28T22:23:00Z",
          "energy": 36.38399999961257,
          "capacity": 436.60799999535084
      },
      {
          "time_stamp_utc_ms": 1735424760000,
          "time": "2024-12-28T22:28:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1735425060000,
          "time": "2024-12-28T22:33:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1735425360000,
          "time": "2024-12-28T22:38:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1735425660000,
          "time": "2024-12-28T22:43:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735425960000,
          "time": "2024-12-28T22:48:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1735426260000,
          "time": "2024-12-28T22:53:00Z",
          "energy": 37.055999998003244,
          "capacity": 444.67199997603893
      },
      {
          "time_stamp_utc_ms": 1735426560000,
          "time": "2024-12-28T22:58:00Z",
          "energy": 38.01600000169128,
          "capacity": 456.1920000202954
      },
      {
          "time_stamp_utc_ms": 1735426860000,
          "time": "2024-12-28T23:03:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1735427160000,
          "time": "2024-12-28T23:08:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1735427460000,
          "time": "2024-12-28T23:13:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1735427760000,
          "time": "2024-12-28T23:18:00Z",
          "energy": 36.671999999322,
          "capacity": 440.06399999186397
      },
      {
          "time_stamp_utc_ms": 1735428060000,
          "time": "2024-12-28T23:23:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735428360000,
          "time": "2024-12-28T23:28:00Z",
          "energy": 36.09599999990314,
          "capacity": 433.1519999988377
      },
      {
          "time_stamp_utc_ms": 1735428660000,
          "time": "2024-12-28T23:33:00Z",
          "energy": 37.72800000105053,
          "capacity": 452.73600001260644
      },
      {
          "time_stamp_utc_ms": 1735428960000,
          "time": "2024-12-28T23:38:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735429260000,
          "time": "2024-12-28T23:43:00Z",
          "energy": 36.959999999031425,
          "capacity": 443.5199999883771
      },
      {
          "time_stamp_utc_ms": 1735429560000,
          "time": "2024-12-28T23:48:00Z",
          "energy": 37.63200000114739,
          "capacity": 451.58400001376873
      },
      {
          "time_stamp_utc_ms": 1735429860000,
          "time": "2024-12-28T23:53:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1735430160000,
          "time": "2024-12-28T23:58:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1735430460000,
          "time": "2024-12-29T00:03:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1735430760000,
          "time": "2024-12-29T00:08:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1735431060000,
          "time": "2024-12-29T00:13:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735431360000,
          "time": "2024-12-29T00:18:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735431660000,
          "time": "2024-12-29T00:23:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1735431960000,
          "time": "2024-12-29T00:28:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735432260000,
          "time": "2024-12-29T00:33:00Z",
          "energy": 36.38399999868125,
          "capacity": 436.60799998417497
      },
      {
          "time_stamp_utc_ms": 1735432560000,
          "time": "2024-12-29T00:38:00Z",
          "energy": 37.63200000114739,
          "capacity": 451.58400001376873
      },
      {
          "time_stamp_utc_ms": 1735432860000,
          "time": "2024-12-29T00:43:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735433160000,
          "time": "2024-12-29T00:48:00Z",
          "energy": 36.671999999322,
          "capacity": 440.06399999186397
      },
      {
          "time_stamp_utc_ms": 1735433460000,
          "time": "2024-12-29T00:53:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1735433760000,
          "time": "2024-12-29T00:58:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1735434060000,
          "time": "2024-12-29T01:03:00Z",
          "energy": 37.631999999284744,
          "capacity": 451.58399999141693
      },
      {
          "time_stamp_utc_ms": 1735434360000,
          "time": "2024-12-29T01:08:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1735434660000,
          "time": "2024-12-29T01:13:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1735434960000,
          "time": "2024-12-29T01:18:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1735435260000,
          "time": "2024-12-29T01:23:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735435560000,
          "time": "2024-12-29T01:28:00Z",
          "energy": 38.399999998509884,
          "capacity": 460.7999999821186
      },
      {
          "time_stamp_utc_ms": 1735435860000,
          "time": "2024-12-29T01:33:00Z",
          "energy": 36.96000000089407,
          "capacity": 443.5200000107289
      },
      {
          "time_stamp_utc_ms": 1735436160000,
          "time": "2024-12-29T01:38:00Z",
          "energy": 37.152000000700355,
          "capacity": 445.8240000084043
      },
      {
          "time_stamp_utc_ms": 1735436460000,
          "time": "2024-12-29T01:43:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735436760000,
          "time": "2024-12-29T01:48:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1735437060000,
          "time": "2024-12-29T01:53:00Z",
          "energy": 37.24800000153482,
          "capacity": 446.9760000184179
      },
      {
          "time_stamp_utc_ms": 1735437360000,
          "time": "2024-12-29T01:58:00Z",
          "energy": 38.2079999987036,
          "capacity": 458.4959999844432
      },
      {
          "time_stamp_utc_ms": 1735437660000,
          "time": "2024-12-29T02:03:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735437960000,
          "time": "2024-12-29T02:08:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735438260000,
          "time": "2024-12-29T02:13:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735438560000,
          "time": "2024-12-29T02:18:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735438860000,
          "time": "2024-12-29T02:23:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1735439160000,
          "time": "2024-12-29T02:28:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1735439460000,
          "time": "2024-12-29T02:33:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1735439760000,
          "time": "2024-12-29T02:38:00Z",
          "energy": 36.959999999031425,
          "capacity": 443.5199999883771
      },
      {
          "time_stamp_utc_ms": 1735440060000,
          "time": "2024-12-29T02:43:00Z",
          "energy": 37.2480000006035,
          "capacity": 446.976000007242
      },
      {
          "time_stamp_utc_ms": 1735440360000,
          "time": "2024-12-29T02:48:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1735440660000,
          "time": "2024-12-29T02:53:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1735440960000,
          "time": "2024-12-29T02:58:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1735441260000,
          "time": "2024-12-29T03:03:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735441560000,
          "time": "2024-12-29T03:08:00Z",
          "energy": 36.575999999418855,
          "capacity": 438.91199999302626
      },
      {
          "time_stamp_utc_ms": 1735441860000,
          "time": "2024-12-29T03:13:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1735442160000,
          "time": "2024-12-29T03:18:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1735442460000,
          "time": "2024-12-29T03:23:00Z",
          "energy": 38.49599999934435,
          "capacity": 461.95199999213224
      },
      {
          "time_stamp_utc_ms": 1735442760000,
          "time": "2024-12-29T03:28:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1735443060000,
          "time": "2024-12-29T03:33:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1735443360000,
          "time": "2024-12-29T03:38:00Z",
          "energy": 39.167999998666346,
          "capacity": 470.0159999839962
      },
      {
          "time_stamp_utc_ms": 1735443660000,
          "time": "2024-12-29T03:43:00Z",
          "energy": 35.90400000102818,
          "capacity": 430.84800001233816
      },
      {
          "time_stamp_utc_ms": 1735443960000,
          "time": "2024-12-29T03:48:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1735444260000,
          "time": "2024-12-29T03:53:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1735444560000,
          "time": "2024-12-29T03:58:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1735444860000,
          "time": "2024-12-29T04:03:00Z",
          "energy": 37.92000000085682,
          "capacity": 455.04000001028186
      },
      {
          "time_stamp_utc_ms": 1735445160000,
          "time": "2024-12-29T04:08:00Z",
          "energy": 37.82399999909103,
          "capacity": 453.88799998909235
      },
      {
          "time_stamp_utc_ms": 1735445460000,
          "time": "2024-12-29T04:13:00Z",
          "energy": 38.59200000111014,
          "capacity": 463.10400001332164
      },
      {
          "time_stamp_utc_ms": 1735445760000,
          "time": "2024-12-29T04:18:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1735446060000,
          "time": "2024-12-29T04:23:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735446360000,
          "time": "2024-12-29T04:28:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1735446660000,
          "time": "2024-12-29T04:33:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735446960000,
          "time": "2024-12-29T04:38:00Z",
          "energy": 36.575999999418855,
          "capacity": 438.91199999302626
      },
      {
          "time_stamp_utc_ms": 1735447260000,
          "time": "2024-12-29T04:43:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1735447560000,
          "time": "2024-12-29T04:48:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1735447860000,
          "time": "2024-12-29T04:53:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1735448160000,
          "time": "2024-12-29T04:58:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1735448460000,
          "time": "2024-12-29T05:03:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735448760000,
          "time": "2024-12-29T05:08:00Z",
          "energy": 38.783999999053776,
          "capacity": 465.4079999886454
      },
      {
          "time_stamp_utc_ms": 1735449060000,
          "time": "2024-12-29T05:13:00Z",
          "energy": 38.01600000169128,
          "capacity": 456.1920000202954
      },
      {
          "time_stamp_utc_ms": 1735449360000,
          "time": "2024-12-29T05:18:00Z",
          "energy": 39.93599999882281,
          "capacity": 479.23199998587376
      },
      {
          "time_stamp_utc_ms": 1735449660000,
          "time": "2024-12-29T05:23:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1735449960000,
          "time": "2024-12-29T05:28:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1735450260000,
          "time": "2024-12-29T05:33:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1735450560000,
          "time": "2024-12-29T05:38:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1735450860000,
          "time": "2024-12-29T05:43:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1735451160000,
          "time": "2024-12-29T05:48:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1735451460000,
          "time": "2024-12-29T05:53:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1735451760000,
          "time": "2024-12-29T05:58:00Z",
          "energy": 41.472000000067055,
          "capacity": 497.66400000080466
      },
      {
          "time_stamp_utc_ms": 1735452060000,
          "time": "2024-12-29T06:03:00Z",
          "energy": 37.631999999284744,
          "capacity": 451.58399999141693
      },
      {
          "time_stamp_utc_ms": 1735452360000,
          "time": "2024-12-29T06:08:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1735452660000,
          "time": "2024-12-29T06:13:00Z",
          "energy": 38.68800000101328,
          "capacity": 464.25600001215935
      },
      {
          "time_stamp_utc_ms": 1735452960000,
          "time": "2024-12-29T06:18:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1735453260000,
          "time": "2024-12-29T06:23:00Z",
          "energy": 39.84000000078231,
          "capacity": 478.08000000938773
      },
      {
          "time_stamp_utc_ms": 1735453560000,
          "time": "2024-12-29T06:28:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1735453860000,
          "time": "2024-12-29T06:33:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735454160000,
          "time": "2024-12-29T06:38:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1735454460000,
          "time": "2024-12-29T06:43:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1735454760000,
          "time": "2024-12-29T06:48:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1735455060000,
          "time": "2024-12-29T06:53:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735455360000,
          "time": "2024-12-29T06:58:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1735455660000,
          "time": "2024-12-29T07:03:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1735455960000,
          "time": "2024-12-29T07:08:00Z",
          "energy": 37.63200000021607,
          "capacity": 451.58400000259286
      },
      {
          "time_stamp_utc_ms": 1735456260000,
          "time": "2024-12-29T07:13:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735456560000,
          "time": "2024-12-29T07:18:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1735456860000,
          "time": "2024-12-29T07:23:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1735457160000,
          "time": "2024-12-29T07:28:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1735457460000,
          "time": "2024-12-29T07:33:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1735457760000,
          "time": "2024-12-29T07:38:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1735458060000,
          "time": "2024-12-29T07:43:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1735458360000,
          "time": "2024-12-29T07:48:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1735458660000,
          "time": "2024-12-29T07:53:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1735458960000,
          "time": "2024-12-29T07:58:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1735459260000,
          "time": "2024-12-29T08:03:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1735459560000,
          "time": "2024-12-29T08:08:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1735459860000,
          "time": "2024-12-29T08:13:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1735460160000,
          "time": "2024-12-29T08:18:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1735460460000,
          "time": "2024-12-29T08:23:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1735460760000,
          "time": "2024-12-29T08:28:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1735461060000,
          "time": "2024-12-29T08:33:00Z",
          "energy": 38.40000000130385,
          "capacity": 460.8000000156462
      },
      {
          "time_stamp_utc_ms": 1735461360000,
          "time": "2024-12-29T08:38:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1735461660000,
          "time": "2024-12-29T08:43:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1735461960000,
          "time": "2024-12-29T08:48:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735462260000,
          "time": "2024-12-29T08:53:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1735462560000,
          "time": "2024-12-29T08:58:00Z",
          "energy": 38.687999999150634,
          "capacity": 464.25599998980766
      },
      {
          "time_stamp_utc_ms": 1735462860000,
          "time": "2024-12-29T09:03:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1735463160000,
          "time": "2024-12-29T09:08:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735463460000,
          "time": "2024-12-29T09:13:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1735463760000,
          "time": "2024-12-29T09:18:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1735464060000,
          "time": "2024-12-29T09:23:00Z",
          "energy": 37.82399999815971,
          "capacity": 453.8879999779165
      },
      {
          "time_stamp_utc_ms": 1735464360000,
          "time": "2024-12-29T09:28:00Z",
          "energy": 40.992000001482666,
          "capacity": 491.90400001779204
      },
      {
          "time_stamp_utc_ms": 1735464660000,
          "time": "2024-12-29T09:33:00Z",
          "energy": 38.87999999895692,
          "capacity": 466.5599999874831
      },
      {
          "time_stamp_utc_ms": 1735464960000,
          "time": "2024-12-29T09:38:00Z",
          "energy": 40.80000000074506,
          "capacity": 489.6000000089407
      },
      {
          "time_stamp_utc_ms": 1735465260000,
          "time": "2024-12-29T09:43:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735465560000,
          "time": "2024-12-29T09:48:00Z",
          "energy": 39.45600000116974,
          "capacity": 473.4720000140369
      },
      {
          "time_stamp_utc_ms": 1735465860000,
          "time": "2024-12-29T09:53:00Z",
          "energy": 40.60799999907613,
          "capacity": 487.29599998891354
      },
      {
          "time_stamp_utc_ms": 1735466160000,
          "time": "2024-12-29T09:58:00Z",
          "energy": 40.12799999862909,
          "capacity": 481.5359999835491
      },
      {
          "time_stamp_utc_ms": 1735466460000,
          "time": "2024-12-29T10:03:00Z",
          "energy": 38.68800000101328,
          "capacity": 464.25600001215935
      },
      {
          "time_stamp_utc_ms": 1735466760000,
          "time": "2024-12-29T10:08:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1735467060000,
          "time": "2024-12-29T10:13:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735467360000,
          "time": "2024-12-29T10:18:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1735467660000,
          "time": "2024-12-29T10:23:00Z",
          "energy": 40.41600000113249,
          "capacity": 484.99200001358986
      },
      {
          "time_stamp_utc_ms": 1735467960000,
          "time": "2024-12-29T10:28:00Z",
          "energy": 38.399999998509884,
          "capacity": 460.7999999821186
      },
      {
          "time_stamp_utc_ms": 1735468260000,
          "time": "2024-12-29T10:33:00Z",
          "energy": 40.51200000103563,
          "capacity": 486.14400001242757
      },
      {
          "time_stamp_utc_ms": 1735468560000,
          "time": "2024-12-29T10:38:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735468860000,
          "time": "2024-12-29T10:43:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1735469160000,
          "time": "2024-12-29T10:48:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1735469460000,
          "time": "2024-12-29T10:53:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1735469760000,
          "time": "2024-12-29T10:58:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1735470060000,
          "time": "2024-12-29T11:03:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735470360000,
          "time": "2024-12-29T11:08:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1735470660000,
          "time": "2024-12-29T11:13:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1735470960000,
          "time": "2024-12-29T11:18:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1735471260000,
          "time": "2024-12-29T11:23:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1735471560000,
          "time": "2024-12-29T11:28:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735471860000,
          "time": "2024-12-29T11:33:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1735472160000,
          "time": "2024-12-29T11:38:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1735472460000,
          "time": "2024-12-29T11:43:00Z",
          "energy": 38.30400000140071,
          "capacity": 459.6480000168085
      },
      {
          "time_stamp_utc_ms": 1735472760000,
          "time": "2024-12-29T11:48:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1735473060000,
          "time": "2024-12-29T11:53:00Z",
          "energy": 38.49599999934435,
          "capacity": 461.95199999213224
      },
      {
          "time_stamp_utc_ms": 1735473360000,
          "time": "2024-12-29T11:58:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1735473660000,
          "time": "2024-12-29T12:03:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735473960000,
          "time": "2024-12-29T12:08:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735474260000,
          "time": "2024-12-29T12:13:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1735474560000,
          "time": "2024-12-29T12:18:00Z",
          "energy": 38.2079999987036,
          "capacity": 458.4959999844432
      },
      {
          "time_stamp_utc_ms": 1735474860000,
          "time": "2024-12-29T12:23:00Z",
          "energy": 39.74400000087917,
          "capacity": 476.92800001055
      },
      {
          "time_stamp_utc_ms": 1735475160000,
          "time": "2024-12-29T12:28:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1735475460000,
          "time": "2024-12-29T12:33:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1735475760000,
          "time": "2024-12-29T12:38:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735476060000,
          "time": "2024-12-29T12:43:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1735476360000,
          "time": "2024-12-29T12:48:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735476660000,
          "time": "2024-12-29T12:53:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1735476960000,
          "time": "2024-12-29T12:58:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1735477260000,
          "time": "2024-12-29T13:03:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1735477560000,
          "time": "2024-12-29T13:08:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1735477860000,
          "time": "2024-12-29T13:13:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735478160000,
          "time": "2024-12-29T13:18:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735478460000,
          "time": "2024-12-29T13:23:00Z",
          "energy": 37.72799999918789,
          "capacity": 452.73599999025464
      },
      {
          "time_stamp_utc_ms": 1735478760000,
          "time": "2024-12-29T13:28:00Z",
          "energy": 39.264000001363456,
          "capacity": 471.1680000163615
      },
      {
          "time_stamp_utc_ms": 1735479060000,
          "time": "2024-12-29T13:33:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735479360000,
          "time": "2024-12-29T13:38:00Z",
          "energy": 40.223999998532236,
          "capacity": 482.6879999823868
      },
      {
          "time_stamp_utc_ms": 1735479660000,
          "time": "2024-12-29T13:43:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1735479960000,
          "time": "2024-12-29T13:48:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1735480260000,
          "time": "2024-12-29T13:53:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1735480560000,
          "time": "2024-12-29T13:58:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1735480860000,
          "time": "2024-12-29T14:03:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1735481160000,
          "time": "2024-12-29T14:08:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735481460000,
          "time": "2024-12-29T14:13:00Z",
          "energy": 37.43999999854714,
          "capacity": 449.27999998256564
      },
      {
          "time_stamp_utc_ms": 1735481760000,
          "time": "2024-12-29T14:18:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1735482060000,
          "time": "2024-12-29T14:23:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1735482360000,
          "time": "2024-12-29T14:28:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1735482660000,
          "time": "2024-12-29T14:33:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735482960000,
          "time": "2024-12-29T14:38:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735483260000,
          "time": "2024-12-29T14:43:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735483560000,
          "time": "2024-12-29T14:48:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1735483860000,
          "time": "2024-12-29T14:53:00Z",
          "energy": 36.09599999990314,
          "capacity": 433.1519999988377
      },
      {
          "time_stamp_utc_ms": 1735484160000,
          "time": "2024-12-29T14:58:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1735484460000,
          "time": "2024-12-29T15:03:00Z",
          "energy": 35.424000000581145,
          "capacity": 425.08800000697374
      },
      {
          "time_stamp_utc_ms": 1735484760000,
          "time": "2024-12-29T15:08:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1735485060000,
          "time": "2024-12-29T15:13:00Z",
          "energy": 36.287999998778105,
          "capacity": 435.45599998533726
      },
      {
          "time_stamp_utc_ms": 1735485360000,
          "time": "2024-12-29T15:18:00Z",
          "energy": 35.52000000141561,
          "capacity": 426.2400000169873
      },
      {
          "time_stamp_utc_ms": 1735485660000,
          "time": "2024-12-29T15:23:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1735485960000,
          "time": "2024-12-29T15:28:00Z",
          "energy": 30.815999999642372,
          "capacity": 369.79199999570847
      },
      {
          "time_stamp_utc_ms": 1735486260000,
          "time": "2024-12-29T15:33:00Z",
          "energy": 33.2160000000149,
          "capacity": 398.59200000017887
      },
      {
          "time_stamp_utc_ms": 1735486560000,
          "time": "2024-12-29T15:38:00Z",
          "energy": 32.54399999976158,
          "capacity": 390.527999997139
      },
      {
          "time_stamp_utc_ms": 1735486860000,
          "time": "2024-12-29T15:43:00Z",
          "energy": 32.35200000088662,
          "capacity": 388.2240000106395
      },
      {
          "time_stamp_utc_ms": 1735487160000,
          "time": "2024-12-29T15:48:00Z",
          "energy": 33.21599999908358,
          "capacity": 398.59199998900294
      },
      {
          "time_stamp_utc_ms": 1735487460000,
          "time": "2024-12-29T15:53:00Z",
          "energy": 32.160000001080334,
          "capacity": 385.92000001296407
      },
      {
          "time_stamp_utc_ms": 1735487760000,
          "time": "2024-12-29T15:58:00Z",
          "energy": 30.911999998614192,
          "capacity": 370.94399998337036
      },
      {
          "time_stamp_utc_ms": 1735488060000,
          "time": "2024-12-29T16:03:00Z",
          "energy": 33.407999999821186,
          "capacity": 400.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735488360000,
          "time": "2024-12-29T16:08:00Z",
          "energy": 30.048000000417233,
          "capacity": 360.5760000050068
      },
      {
          "time_stamp_utc_ms": 1735488660000,
          "time": "2024-12-29T16:13:00Z",
          "energy": 34.27199999988079,
          "capacity": 411.26399999856955
      },
      {
          "time_stamp_utc_ms": 1735488960000,
          "time": "2024-12-29T16:18:00Z",
          "energy": 35.23200000077486,
          "capacity": 422.7840000092983
      },
      {
          "time_stamp_utc_ms": 1735489260000,
          "time": "2024-12-29T16:23:00Z",
          "energy": 31.87199999950826,
          "capacity": 382.46399999409914
      },
      {
          "time_stamp_utc_ms": 1735489560000,
          "time": "2024-12-29T16:28:00Z",
          "energy": 33.50399999972433,
          "capacity": 402.047999996692
      },
      {
          "time_stamp_utc_ms": 1735489860000,
          "time": "2024-12-29T16:33:00Z",
          "energy": 30.336000001057982,
          "capacity": 364.0320000126958
      },
      {
          "time_stamp_utc_ms": 1735490160000,
          "time": "2024-12-29T16:38:00Z",
          "energy": 31.200000000186265,
          "capacity": 374.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735490460000,
          "time": "2024-12-29T16:43:00Z",
          "energy": 31.58399999886751,
          "capacity": 379.00799998641014
      },
      {
          "time_stamp_utc_ms": 1735490760000,
          "time": "2024-12-29T16:48:00Z",
          "energy": 33.696000000461936,
          "capacity": 404.3520000055433
      },
      {
          "time_stamp_utc_ms": 1735491060000,
          "time": "2024-12-29T16:53:00Z",
          "energy": 34.27199999988079,
          "capacity": 411.26399999856955
      },
      {
          "time_stamp_utc_ms": 1735491360000,
          "time": "2024-12-29T16:58:00Z",
          "energy": 31.6800000006333,
          "capacity": 380.1600000075996
      },
      {
          "time_stamp_utc_ms": 1735491660000,
          "time": "2024-12-29T17:03:00Z",
          "energy": 31.87199999857694,
          "capacity": 382.46399998292327
      },
      {
          "time_stamp_utc_ms": 1735491960000,
          "time": "2024-12-29T17:08:00Z",
          "energy": 32.16000000014901,
          "capacity": 385.92000000178814
      },
      {
          "time_stamp_utc_ms": 1735492260000,
          "time": "2024-12-29T17:13:00Z",
          "energy": 31.96800000127405,
          "capacity": 383.61600001528865
      },
      {
          "time_stamp_utc_ms": 1735492560000,
          "time": "2024-12-29T17:18:00Z",
          "energy": 33.69599999953061,
          "capacity": 404.3519999943674
      },
      {
          "time_stamp_utc_ms": 1735492860000,
          "time": "2024-12-29T17:23:00Z",
          "energy": 30.432000000029802,
          "capacity": 365.1840000003576
      },
      {
          "time_stamp_utc_ms": 1735493160000,
          "time": "2024-12-29T17:28:00Z",
          "energy": 31.392000000923872,
          "capacity": 376.70400001108646
      },
      {
          "time_stamp_utc_ms": 1735493460000,
          "time": "2024-12-29T17:33:00Z",
          "energy": 32.831999998539686,
          "capacity": 393.98399998247623
      },
      {
          "time_stamp_utc_ms": 1735493760000,
          "time": "2024-12-29T17:38:00Z",
          "energy": 29.56800000090152,
          "capacity": 354.81600001081824
      },
      {
          "time_stamp_utc_ms": 1735494060000,
          "time": "2024-12-29T17:43:00Z",
          "energy": 30.527999999001622,
          "capacity": 366.3359999880195
      },
      {
          "time_stamp_utc_ms": 1735494360000,
          "time": "2024-12-29T17:48:00Z",
          "energy": 28.704000000841916,
          "capacity": 344.44800001010304
      },
      {
          "time_stamp_utc_ms": 1735494660000,
          "time": "2024-12-29T17:53:00Z",
          "energy": 32.831999998539686,
          "capacity": 393.98399998247623
      },
      {
          "time_stamp_utc_ms": 1735494960000,
          "time": "2024-12-29T17:58:00Z",
          "energy": 33.12000000104308,
          "capacity": 397.44000001251703
      },
      {
          "time_stamp_utc_ms": 1735495260000,
          "time": "2024-12-29T18:03:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1735495560000,
          "time": "2024-12-29T18:08:00Z",
          "energy": 34.84800000023097,
          "capacity": 418.1760000027716
      },
      {
          "time_stamp_utc_ms": 1735495860000,
          "time": "2024-12-29T18:13:00Z",
          "energy": 33.8879999993369,
          "capacity": 406.65599999204284
      },
      {
          "time_stamp_utc_ms": 1735496160000,
          "time": "2024-12-29T18:18:00Z",
          "energy": 34.65600000042468,
          "capacity": 415.8720000050962
      },
      {
          "time_stamp_utc_ms": 1735496460000,
          "time": "2024-12-29T18:23:00Z",
          "energy": 34.463999999687076,
          "capacity": 413.56799999624496
      },
      {
          "time_stamp_utc_ms": 1735496760000,
          "time": "2024-12-29T18:28:00Z",
          "energy": 34.175999999046326,
          "capacity": 410.11199998855597
      },
      {
          "time_stamp_utc_ms": 1735497060000,
          "time": "2024-12-29T18:33:00Z",
          "energy": 34.368000000715256,
          "capacity": 412.41600000858307
      },
      {
          "time_stamp_utc_ms": 1735497360000,
          "time": "2024-12-29T18:38:00Z",
          "energy": 32.544000000692904,
          "capacity": 390.5280000083149
      },
      {
          "time_stamp_utc_ms": 1735497660000,
          "time": "2024-12-29T18:43:00Z",
          "energy": 32.15999999921769,
          "capacity": 385.91999999061227
      },
      {
          "time_stamp_utc_ms": 1735497960000,
          "time": "2024-12-29T18:48:00Z",
          "energy": 33.12000000104308,
          "capacity": 397.44000001251703
      },
      {
          "time_stamp_utc_ms": 1735498260000,
          "time": "2024-12-29T18:53:00Z",
          "energy": 30.623999998904765,
          "capacity": 367.48799998685723
      },
      {
          "time_stamp_utc_ms": 1735498560000,
          "time": "2024-12-29T18:58:00Z",
          "energy": 32.832000001333654,
          "capacity": 393.9840000160039
      },
      {
          "time_stamp_utc_ms": 1735498860000,
          "time": "2024-12-29T19:03:00Z",
          "energy": 31.296000000089407,
          "capacity": 375.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735499160000,
          "time": "2024-12-29T19:08:00Z",
          "energy": 30.81599999871105,
          "capacity": 369.79199998453265
      },
      {
          "time_stamp_utc_ms": 1735499460000,
          "time": "2024-12-29T19:13:00Z",
          "energy": 33.69599999953061,
          "capacity": 404.3519999943674
      },
      {
          "time_stamp_utc_ms": 1735499760000,
          "time": "2024-12-29T19:18:00Z",
          "energy": 32.64000000059605,
          "capacity": 391.6800000071526
      },
      {
          "time_stamp_utc_ms": 1735500060000,
          "time": "2024-12-29T19:23:00Z",
          "energy": 33.59999999962747,
          "capacity": 403.1999999955297
      },
      {
          "time_stamp_utc_ms": 1735500360000,
          "time": "2024-12-29T19:28:00Z",
          "energy": 30.816000000573695,
          "capacity": 369.79200000688434
      },
      {
          "time_stamp_utc_ms": 1735500660000,
          "time": "2024-12-29T19:33:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1735500960000,
          "time": "2024-12-29T19:38:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1735501260000,
          "time": "2024-12-29T19:43:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1735501560000,
          "time": "2024-12-29T19:48:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1735501860000,
          "time": "2024-12-29T19:53:00Z",
          "energy": 35.32799999974668,
          "capacity": 423.93599999696016
      },
      {
          "time_stamp_utc_ms": 1735502160000,
          "time": "2024-12-29T19:58:00Z",
          "energy": 35.61600000131875,
          "capacity": 427.39200001582503
      },
      {
          "time_stamp_utc_ms": 1735502460000,
          "time": "2024-12-29T20:03:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1735502760000,
          "time": "2024-12-29T20:08:00Z",
          "energy": 36.575999999418855,
          "capacity": 438.91199999302626
      },
      {
          "time_stamp_utc_ms": 1735503060000,
          "time": "2024-12-29T20:13:00Z",
          "energy": 36.287999998778105,
          "capacity": 435.45599998533726
      },
      {
          "time_stamp_utc_ms": 1735503360000,
          "time": "2024-12-29T20:18:00Z",
          "energy": 37.92000000085682,
          "capacity": 455.04000001028186
      },
      {
          "time_stamp_utc_ms": 1735503660000,
          "time": "2024-12-29T20:23:00Z",
          "energy": 35.23199999984354,
          "capacity": 422.78399999812245
      },
      {
          "time_stamp_utc_ms": 1735503960000,
          "time": "2024-12-29T20:28:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735504260000,
          "time": "2024-12-29T20:33:00Z",
          "energy": 35.32799999974668,
          "capacity": 423.93599999696016
      },
      {
          "time_stamp_utc_ms": 1735504560000,
          "time": "2024-12-29T20:38:00Z",
          "energy": 36.96000000089407,
          "capacity": 443.5200000107289
      },
      {
          "time_stamp_utc_ms": 1735504860000,
          "time": "2024-12-29T20:43:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1735505160000,
          "time": "2024-12-29T20:48:00Z",
          "energy": 35.42399999964982,
          "capacity": 425.0879999957979
      },
      {
          "time_stamp_utc_ms": 1735505460000,
          "time": "2024-12-29T20:53:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1735505760000,
          "time": "2024-12-29T20:58:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1735506060000,
          "time": "2024-12-29T21:03:00Z",
          "energy": 35.61600000038743,
          "capacity": 427.39200000464916
      },
      {
          "time_stamp_utc_ms": 1735506360000,
          "time": "2024-12-29T21:08:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735506660000,
          "time": "2024-12-29T21:13:00Z",
          "energy": 36.38399999961257,
          "capacity": 436.60799999535084
      },
      {
          "time_stamp_utc_ms": 1735506960000,
          "time": "2024-12-29T21:18:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735507260000,
          "time": "2024-12-29T21:23:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1735507560000,
          "time": "2024-12-29T21:28:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1735507860000,
          "time": "2024-12-29T21:33:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1735508160000,
          "time": "2024-12-29T21:38:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1735508460000,
          "time": "2024-12-29T21:43:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1735508760000,
          "time": "2024-12-29T21:48:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735509060000,
          "time": "2024-12-29T21:53:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735509360000,
          "time": "2024-12-29T21:58:00Z",
          "energy": 39.168000001460314,
          "capacity": 470.01600001752377
      },
      {
          "time_stamp_utc_ms": 1735509660000,
          "time": "2024-12-29T22:03:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1735509960000,
          "time": "2024-12-29T22:08:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1735510260000,
          "time": "2024-12-29T22:13:00Z",
          "energy": 37.63200000021607,
          "capacity": 451.58400000259286
      },
      {
          "time_stamp_utc_ms": 1735510560000,
          "time": "2024-12-29T22:18:00Z",
          "energy": 36.19200000073761,
          "capacity": 434.3040000088513
      },
      {
          "time_stamp_utc_ms": 1735510860000,
          "time": "2024-12-29T22:23:00Z",
          "energy": 37.91999999806285,
          "capacity": 455.0399999767542
      },
      {
          "time_stamp_utc_ms": 1735511160000,
          "time": "2024-12-29T22:28:00Z",
          "energy": 36.19200000073761,
          "capacity": 434.3040000088513
      },
      {
          "time_stamp_utc_ms": 1735511460000,
          "time": "2024-12-29T22:33:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1735511760000,
          "time": "2024-12-29T22:38:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735512060000,
          "time": "2024-12-29T22:43:00Z",
          "energy": 35.61600000038743,
          "capacity": 427.39200000464916
      },
      {
          "time_stamp_utc_ms": 1735512360000,
          "time": "2024-12-29T22:48:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1735512660000,
          "time": "2024-12-29T22:53:00Z",
          "energy": 36.287999998778105,
          "capacity": 435.45599998533726
      },
      {
          "time_stamp_utc_ms": 1735512960000,
          "time": "2024-12-29T22:58:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1735513260000,
          "time": "2024-12-29T23:03:00Z",
          "energy": 37.152000000700355,
          "capacity": 445.8240000084043
      },
      {
          "time_stamp_utc_ms": 1735513560000,
          "time": "2024-12-29T23:08:00Z",
          "energy": 37.5359999993816,
          "capacity": 450.4319999925792
      },
      {
          "time_stamp_utc_ms": 1735513860000,
          "time": "2024-12-29T23:13:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1735514160000,
          "time": "2024-12-29T23:18:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1735514460000,
          "time": "2024-12-29T23:23:00Z",
          "energy": 35.04000000003725,
          "capacity": 420.48000000044703
      },
      {
          "time_stamp_utc_ms": 1735514760000,
          "time": "2024-12-29T23:28:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1735515060000,
          "time": "2024-12-29T23:33:00Z",
          "energy": 34.84800000116229,
          "capacity": 418.1760000139475
      },
      {
          "time_stamp_utc_ms": 1735515360000,
          "time": "2024-12-29T23:38:00Z",
          "energy": 37.15199999883771,
          "capacity": 445.8239999860525
      },
      {
          "time_stamp_utc_ms": 1735515660000,
          "time": "2024-12-29T23:43:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1735515960000,
          "time": "2024-12-29T23:48:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1735516260000,
          "time": "2024-12-29T23:53:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1735516560000,
          "time": "2024-12-29T23:58:00Z",
          "energy": 36.096000000834465,
          "capacity": 433.1520000100136
      },
      {
          "time_stamp_utc_ms": 1735516860000,
          "time": "2024-12-30T00:03:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735517160000,
          "time": "2024-12-30T00:08:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1735517460000,
          "time": "2024-12-30T00:13:00Z",
          "energy": 36.38399999961257,
          "capacity": 436.60799999535084
      },
      {
          "time_stamp_utc_ms": 1735517760000,
          "time": "2024-12-30T00:18:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1735518060000,
          "time": "2024-12-30T00:23:00Z",
          "energy": 36.38399999961257,
          "capacity": 436.60799999535084
      },
      {
          "time_stamp_utc_ms": 1735518360000,
          "time": "2024-12-30T00:28:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1735518660000,
          "time": "2024-12-30T00:33:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1735518960000,
          "time": "2024-12-30T00:38:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1735519260000,
          "time": "2024-12-30T00:43:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1735519560000,
          "time": "2024-12-30T00:48:00Z",
          "energy": 36.09599999990314,
          "capacity": 433.1519999988377
      },
      {
          "time_stamp_utc_ms": 1735519860000,
          "time": "2024-12-30T00:53:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735520160000,
          "time": "2024-12-30T00:58:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1735520460000,
          "time": "2024-12-30T01:03:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1735520760000,
          "time": "2024-12-30T01:08:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1735521060000,
          "time": "2024-12-30T01:13:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1735521360000,
          "time": "2024-12-30T01:18:00Z",
          "energy": 36.287999998778105,
          "capacity": 435.45599998533726
      },
      {
          "time_stamp_utc_ms": 1735521660000,
          "time": "2024-12-30T01:23:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1735521960000,
          "time": "2024-12-30T01:28:00Z",
          "energy": 35.424000000581145,
          "capacity": 425.08800000697374
      },
      {
          "time_stamp_utc_ms": 1735522260000,
          "time": "2024-12-30T01:33:00Z",
          "energy": 37.05599999893457,
          "capacity": 444.6719999872148
      },
      {
          "time_stamp_utc_ms": 1735522560000,
          "time": "2024-12-30T01:38:00Z",
          "energy": 36.19200000073761,
          "capacity": 434.3040000088513
      },
      {
          "time_stamp_utc_ms": 1735522860000,
          "time": "2024-12-30T01:43:00Z",
          "energy": 35.80799999926239,
          "capacity": 429.6959999911487
      },
      {
          "time_stamp_utc_ms": 1735523160000,
          "time": "2024-12-30T01:48:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1735523460000,
          "time": "2024-12-30T01:53:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1735523760000,
          "time": "2024-12-30T01:58:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735524060000,
          "time": "2024-12-30T02:03:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1735524360000,
          "time": "2024-12-30T02:08:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1735524660000,
          "time": "2024-12-30T02:13:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1735524960000,
          "time": "2024-12-30T02:18:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1735525260000,
          "time": "2024-12-30T02:23:00Z",
          "energy": 37.05599999893457,
          "capacity": 444.6719999872148
      },
      {
          "time_stamp_utc_ms": 1735525560000,
          "time": "2024-12-30T02:28:00Z",
          "energy": 37.53600000124425,
          "capacity": 450.432000014931
      },
      {
          "time_stamp_utc_ms": 1735525860000,
          "time": "2024-12-30T02:33:00Z",
          "energy": 35.71199999935925,
          "capacity": 428.543999992311
      },
      {
          "time_stamp_utc_ms": 1735526160000,
          "time": "2024-12-30T02:38:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1735526460000,
          "time": "2024-12-30T02:43:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1735526760000,
          "time": "2024-12-30T02:48:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735527060000,
          "time": "2024-12-30T02:53:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735527360000,
          "time": "2024-12-30T02:58:00Z",
          "energy": 36.00000000093132,
          "capacity": 432.00000001117587
      },
      {
          "time_stamp_utc_ms": 1735527660000,
          "time": "2024-12-30T03:03:00Z",
          "energy": 36.57599999848753,
          "capacity": 438.9119999818504
      },
      {
          "time_stamp_utc_ms": 1735527960000,
          "time": "2024-12-30T03:08:00Z",
          "energy": 36.86400000099093,
          "capacity": 442.3680000118912
      },
      {
          "time_stamp_utc_ms": 1735528260000,
          "time": "2024-12-30T03:13:00Z",
          "energy": 35.71199999935925,
          "capacity": 428.543999992311
      },
      {
          "time_stamp_utc_ms": 1735528560000,
          "time": "2024-12-30T03:18:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1735528860000,
          "time": "2024-12-30T03:23:00Z",
          "energy": 35.90400000102818,
          "capacity": 430.84800001233816
      },
      {
          "time_stamp_utc_ms": 1735529160000,
          "time": "2024-12-30T03:28:00Z",
          "energy": 36.959999999031425,
          "capacity": 443.5199999883771
      },
      {
          "time_stamp_utc_ms": 1735529460000,
          "time": "2024-12-30T03:33:00Z",
          "energy": 36.671999999322,
          "capacity": 440.06399999186397
      },
      {
          "time_stamp_utc_ms": 1735529760000,
          "time": "2024-12-30T03:38:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1735530060000,
          "time": "2024-12-30T03:43:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1735530360000,
          "time": "2024-12-30T03:48:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735530660000,
          "time": "2024-12-30T03:53:00Z",
          "energy": 36.38399999961257,
          "capacity": 436.60799999535084
      },
      {
          "time_stamp_utc_ms": 1735530960000,
          "time": "2024-12-30T03:58:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735531260000,
          "time": "2024-12-30T04:03:00Z",
          "energy": 35.90400000102818,
          "capacity": 430.84800001233816
      },
      {
          "time_stamp_utc_ms": 1735531560000,
          "time": "2024-12-30T04:08:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735531860000,
          "time": "2024-12-30T04:13:00Z",
          "energy": 36.096000000834465,
          "capacity": 433.1520000100136
      },
      {
          "time_stamp_utc_ms": 1735532160000,
          "time": "2024-12-30T04:18:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1735532460000,
          "time": "2024-12-30T04:23:00Z",
          "energy": 36.86399999912828,
          "capacity": 442.3679999895394
      },
      {
          "time_stamp_utc_ms": 1735532760000,
          "time": "2024-12-30T04:28:00Z",
          "energy": 36.19200000073761,
          "capacity": 434.3040000088513
      },
      {
          "time_stamp_utc_ms": 1735533060000,
          "time": "2024-12-30T04:33:00Z",
          "energy": 36.287999998778105,
          "capacity": 435.45599998533726
      },
      {
          "time_stamp_utc_ms": 1735533360000,
          "time": "2024-12-30T04:38:00Z",
          "energy": 37.2480000006035,
          "capacity": 446.976000007242
      },
      {
          "time_stamp_utc_ms": 1735533660000,
          "time": "2024-12-30T04:43:00Z",
          "energy": 35.71199999935925,
          "capacity": 428.543999992311
      },
      {
          "time_stamp_utc_ms": 1735533960000,
          "time": "2024-12-30T04:48:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1735534260000,
          "time": "2024-12-30T04:53:00Z",
          "energy": 37.152000000700355,
          "capacity": 445.8240000084043
      },
      {
          "time_stamp_utc_ms": 1735534560000,
          "time": "2024-12-30T04:58:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735534860000,
          "time": "2024-12-30T05:03:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1735535160000,
          "time": "2024-12-30T05:08:00Z",
          "energy": 37.63200000021607,
          "capacity": 451.58400000259286
      },
      {
          "time_stamp_utc_ms": 1735535460000,
          "time": "2024-12-30T05:13:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735535760000,
          "time": "2024-12-30T05:18:00Z",
          "energy": 37.152000000700355,
          "capacity": 445.8240000084043
      },
      {
          "time_stamp_utc_ms": 1735536060000,
          "time": "2024-12-30T05:23:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1735536360000,
          "time": "2024-12-30T05:28:00Z",
          "energy": 37.91999999899417,
          "capacity": 455.03999998793006
      },
      {
          "time_stamp_utc_ms": 1735536660000,
          "time": "2024-12-30T05:33:00Z",
          "energy": 35.71200000029057,
          "capacity": 428.5440000034869
      },
      {
          "time_stamp_utc_ms": 1735536960000,
          "time": "2024-12-30T05:38:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735537260000,
          "time": "2024-12-30T05:43:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1735537560000,
          "time": "2024-12-30T05:48:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1735537860000,
          "time": "2024-12-30T05:53:00Z",
          "energy": 38.78400000091642,
          "capacity": 465.40800001099706
      },
      {
          "time_stamp_utc_ms": 1735538160000,
          "time": "2024-12-30T05:58:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1735538460000,
          "time": "2024-12-30T06:03:00Z",
          "energy": 36.959999999031425,
          "capacity": 443.5199999883771
      },
      {
          "time_stamp_utc_ms": 1735538760000,
          "time": "2024-12-30T06:08:00Z",
          "energy": 37.63200000021607,
          "capacity": 451.58400000259286
      },
      {
          "time_stamp_utc_ms": 1735539060000,
          "time": "2024-12-30T06:13:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1735539360000,
          "time": "2024-12-30T06:18:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735539660000,
          "time": "2024-12-30T06:23:00Z",
          "energy": 36.09599999990314,
          "capacity": 433.1519999988377
      },
      {
          "time_stamp_utc_ms": 1735539960000,
          "time": "2024-12-30T06:28:00Z",
          "energy": 36.575999999418855,
          "capacity": 438.91199999302626
      },
      {
          "time_stamp_utc_ms": 1735540260000,
          "time": "2024-12-30T06:33:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1735540560000,
          "time": "2024-12-30T06:38:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1735540860000,
          "time": "2024-12-30T06:43:00Z",
          "energy": 38.015999998897314,
          "capacity": 456.19199998676777
      },
      {
          "time_stamp_utc_ms": 1735541160000,
          "time": "2024-12-30T06:48:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1735541460000,
          "time": "2024-12-30T06:53:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735541760000,
          "time": "2024-12-30T06:58:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1735542060000,
          "time": "2024-12-30T07:03:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1735542360000,
          "time": "2024-12-30T07:08:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1735542660000,
          "time": "2024-12-30T07:13:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1735542960000,
          "time": "2024-12-30T07:18:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1735543260000,
          "time": "2024-12-30T07:23:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735543560000,
          "time": "2024-12-30T07:28:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735543860000,
          "time": "2024-12-30T07:33:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1735544160000,
          "time": "2024-12-30T07:38:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1735544460000,
          "time": "2024-12-30T07:43:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1735544760000,
          "time": "2024-12-30T07:48:00Z",
          "energy": 37.5359999993816,
          "capacity": 450.4319999925792
      },
      {
          "time_stamp_utc_ms": 1735545060000,
          "time": "2024-12-30T07:53:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1735545360000,
          "time": "2024-12-30T07:58:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1735545660000,
          "time": "2024-12-30T08:03:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1735545960000,
          "time": "2024-12-30T08:08:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1735546260000,
          "time": "2024-12-30T08:13:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1735546560000,
          "time": "2024-12-30T08:18:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1735546860000,
          "time": "2024-12-30T08:23:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735547160000,
          "time": "2024-12-30T08:28:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1735547460000,
          "time": "2024-12-30T08:33:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1735547760000,
          "time": "2024-12-30T08:38:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1735548060000,
          "time": "2024-12-30T08:43:00Z",
          "energy": 37.05600000079721,
          "capacity": 444.6720000095666
      },
      {
          "time_stamp_utc_ms": 1735548360000,
          "time": "2024-12-30T08:48:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1735548660000,
          "time": "2024-12-30T08:53:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1735548960000,
          "time": "2024-12-30T08:58:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1735549260000,
          "time": "2024-12-30T09:03:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735549560000,
          "time": "2024-12-30T09:08:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1735549860000,
          "time": "2024-12-30T09:13:00Z",
          "energy": 37.15199999883771,
          "capacity": 445.8239999860525
      },
      {
          "time_stamp_utc_ms": 1735550160000,
          "time": "2024-12-30T09:18:00Z",
          "energy": 38.30400000140071,
          "capacity": 459.6480000168085
      },
      {
          "time_stamp_utc_ms": 1735550460000,
          "time": "2024-12-30T09:23:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1735550760000,
          "time": "2024-12-30T09:28:00Z",
          "energy": 37.82399999909103,
          "capacity": 453.88799998909235
      },
      {
          "time_stamp_utc_ms": 1735551060000,
          "time": "2024-12-30T09:33:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1735551360000,
          "time": "2024-12-30T09:38:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735551660000,
          "time": "2024-12-30T09:43:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1735551960000,
          "time": "2024-12-30T09:48:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1735552260000,
          "time": "2024-12-30T09:53:00Z",
          "energy": 38.78400000091642,
          "capacity": 465.40800001099706
      },
      {
          "time_stamp_utc_ms": 1735552560000,
          "time": "2024-12-30T09:58:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1735552860000,
          "time": "2024-12-30T10:03:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1735553160000,
          "time": "2024-12-30T10:08:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735553460000,
          "time": "2024-12-30T10:13:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735553760000,
          "time": "2024-12-30T10:18:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735554060000,
          "time": "2024-12-30T10:23:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1735554360000,
          "time": "2024-12-30T10:28:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735554660000,
          "time": "2024-12-30T10:33:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1735554960000,
          "time": "2024-12-30T10:38:00Z",
          "energy": 43.87199999857694,
          "capacity": 526.4639999829233
      },
      {
          "time_stamp_utc_ms": 1735555260000,
          "time": "2024-12-30T10:43:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1735555560000,
          "time": "2024-12-30T10:48:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735555860000,
          "time": "2024-12-30T10:53:00Z",
          "energy": 42.72000000067055,
          "capacity": 512.6400000080466
      },
      {
          "time_stamp_utc_ms": 1735556160000,
          "time": "2024-12-30T10:58:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735556460000,
          "time": "2024-12-30T11:03:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735556760000,
          "time": "2024-12-30T11:08:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735557060000,
          "time": "2024-12-30T11:13:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735557360000,
          "time": "2024-12-30T11:18:00Z",
          "energy": 43.58400000073016,
          "capacity": 523.0080000087619
      },
      {
          "time_stamp_utc_ms": 1735557660000,
          "time": "2024-12-30T11:23:00Z",
          "energy": 44.063999998383224,
          "capacity": 528.7679999805987
      },
      {
          "time_stamp_utc_ms": 1735557960000,
          "time": "2024-12-30T11:28:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735558260000,
          "time": "2024-12-30T11:33:00Z",
          "energy": 42.62400000169873,
          "capacity": 511.4880000203848
      },
      {
          "time_stamp_utc_ms": 1735558560000,
          "time": "2024-12-30T11:38:00Z",
          "energy": 44.6399999987334,
          "capacity": 535.6799999848008
      },
      {
          "time_stamp_utc_ms": 1735558860000,
          "time": "2024-12-30T11:43:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1735559160000,
          "time": "2024-12-30T11:48:00Z",
          "energy": 43.58400000073016,
          "capacity": 523.0080000087619
      },
      {
          "time_stamp_utc_ms": 1735559460000,
          "time": "2024-12-30T11:53:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735559760000,
          "time": "2024-12-30T11:58:00Z",
          "energy": 43.00799999944866,
          "capacity": 516.0959999933839
      },
      {
          "time_stamp_utc_ms": 1735560060000,
          "time": "2024-12-30T12:03:00Z",
          "energy": 43.872000000439584,
          "capacity": 526.464000005275
      },
      {
          "time_stamp_utc_ms": 1735560360000,
          "time": "2024-12-30T12:08:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735560660000,
          "time": "2024-12-30T12:13:00Z",
          "energy": 44.15999999921769,
          "capacity": 529.9199999906123
      },
      {
          "time_stamp_utc_ms": 1735560960000,
          "time": "2024-12-30T12:18:00Z",
          "energy": 44.160000001080334,
          "capacity": 529.9200000129641
      },
      {
          "time_stamp_utc_ms": 1735561260000,
          "time": "2024-12-30T12:23:00Z",
          "energy": 43.39199999906123,
          "capacity": 520.7039999887347
      },
      {
          "time_stamp_utc_ms": 1735561560000,
          "time": "2024-12-30T12:28:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1735561860000,
          "time": "2024-12-30T12:33:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1735562160000,
          "time": "2024-12-30T12:38:00Z",
          "energy": 44.83199999947101,
          "capacity": 537.9839999936521
      },
      {
          "time_stamp_utc_ms": 1735562460000,
          "time": "2024-12-30T12:43:00Z",
          "energy": 45.12000000104308,
          "capacity": 541.440000012517
      },
      {
          "time_stamp_utc_ms": 1735562760000,
          "time": "2024-12-30T12:48:00Z",
          "energy": 43.00799999944866,
          "capacity": 516.0959999933839
      },
      {
          "time_stamp_utc_ms": 1735563060000,
          "time": "2024-12-30T12:53:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735563360000,
          "time": "2024-12-30T12:58:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1735563660000,
          "time": "2024-12-30T13:03:00Z",
          "energy": 44.44800000078976,
          "capacity": 533.3760000094773
      },
      {
          "time_stamp_utc_ms": 1735563960000,
          "time": "2024-12-30T13:08:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1735564260000,
          "time": "2024-12-30T13:13:00Z",
          "energy": 45.02399999834597,
          "capacity": 540.2879999801517
      },
      {
          "time_stamp_utc_ms": 1735564560000,
          "time": "2024-12-30T13:18:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1735564860000,
          "time": "2024-12-30T13:23:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735565160000,
          "time": "2024-12-30T13:28:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735565460000,
          "time": "2024-12-30T13:33:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1735565760000,
          "time": "2024-12-30T13:38:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735566060000,
          "time": "2024-12-30T13:43:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735566360000,
          "time": "2024-12-30T13:48:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735566660000,
          "time": "2024-12-30T13:53:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1735566960000,
          "time": "2024-12-30T13:58:00Z",
          "energy": 48.5760000012815,
          "capacity": 582.9120000153781
      },
      {
          "time_stamp_utc_ms": 1735567260000,
          "time": "2024-12-30T14:03:00Z",
          "energy": 45.69599999859929,
          "capacity": 548.3519999831915
      },
      {
          "time_stamp_utc_ms": 1735567560000,
          "time": "2024-12-30T14:08:00Z",
          "energy": 46.84800000116229,
          "capacity": 562.1760000139475
      },
      {
          "time_stamp_utc_ms": 1735567860000,
          "time": "2024-12-30T14:13:00Z",
          "energy": 46.55999999959022,
          "capacity": 558.7199999950826
      },
      {
          "time_stamp_utc_ms": 1735568160000,
          "time": "2024-12-30T14:18:00Z",
          "energy": 45.023999999277294,
          "capacity": 540.2879999913275
      },
      {
          "time_stamp_utc_ms": 1735568460000,
          "time": "2024-12-30T14:23:00Z",
          "energy": 47.61600000038743,
          "capacity": 571.3920000046492
      },
      {
          "time_stamp_utc_ms": 1735568760000,
          "time": "2024-12-30T14:28:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1735569060000,
          "time": "2024-12-30T14:33:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735569360000,
          "time": "2024-12-30T14:38:00Z",
          "energy": 45.12000000104308,
          "capacity": 541.440000012517
      },
      {
          "time_stamp_utc_ms": 1735569660000,
          "time": "2024-12-30T14:43:00Z",
          "energy": 45.023999999277294,
          "capacity": 540.2879999913275
      },
      {
          "time_stamp_utc_ms": 1735569960000,
          "time": "2024-12-30T14:48:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1735570260000,
          "time": "2024-12-30T14:53:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1735570560000,
          "time": "2024-12-30T14:58:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735570860000,
          "time": "2024-12-30T15:03:00Z",
          "energy": 45.791999999433756,
          "capacity": 549.5039999932052
      },
      {
          "time_stamp_utc_ms": 1735571160000,
          "time": "2024-12-30T15:08:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735571460000,
          "time": "2024-12-30T15:13:00Z",
          "energy": 45.12000000104308,
          "capacity": 541.440000012517
      },
      {
          "time_stamp_utc_ms": 1735571760000,
          "time": "2024-12-30T15:18:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735572060000,
          "time": "2024-12-30T15:23:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735572360000,
          "time": "2024-12-30T15:28:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735572660000,
          "time": "2024-12-30T15:33:00Z",
          "energy": 42.816000000573695,
          "capacity": 513.7920000068843
      },
      {
          "time_stamp_utc_ms": 1735572960000,
          "time": "2024-12-30T15:38:00Z",
          "energy": 42.239999999292195,
          "capacity": 506.87999999150634
      },
      {
          "time_stamp_utc_ms": 1735573260000,
          "time": "2024-12-30T15:43:00Z",
          "energy": 43.6800000006333,
          "capacity": 524.1600000075996
      },
      {
          "time_stamp_utc_ms": 1735573560000,
          "time": "2024-12-30T15:48:00Z",
          "energy": 40.511999999172986,
          "capacity": 486.1439999900758
      },
      {
          "time_stamp_utc_ms": 1735573860000,
          "time": "2024-12-30T15:53:00Z",
          "energy": 44.544000000692904,
          "capacity": 534.528000008315
      },
      {
          "time_stamp_utc_ms": 1735574160000,
          "time": "2024-12-30T15:58:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1735574460000,
          "time": "2024-12-30T16:03:00Z",
          "energy": 43.58400000073016,
          "capacity": 523.0080000087619
      },
      {
          "time_stamp_utc_ms": 1735574760000,
          "time": "2024-12-30T16:08:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1735575060000,
          "time": "2024-12-30T16:13:00Z",
          "energy": 43.87199999857694,
          "capacity": 526.4639999829233
      },
      {
          "time_stamp_utc_ms": 1735575360000,
          "time": "2024-12-30T16:18:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1735575660000,
          "time": "2024-12-30T16:23:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1735575960000,
          "time": "2024-12-30T16:28:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735576260000,
          "time": "2024-12-30T16:33:00Z",
          "energy": 44.06400000117719,
          "capacity": 528.7680000141264
      },
      {
          "time_stamp_utc_ms": 1735576560000,
          "time": "2024-12-30T16:38:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1735576860000,
          "time": "2024-12-30T16:43:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1735577160000,
          "time": "2024-12-30T16:48:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1735577460000,
          "time": "2024-12-30T16:53:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1735577760000,
          "time": "2024-12-30T16:58:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1735578060000,
          "time": "2024-12-30T17:03:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735578360000,
          "time": "2024-12-30T17:08:00Z",
          "energy": 41.28000000026077,
          "capacity": 495.36000000312924
      },
      {
          "time_stamp_utc_ms": 1735578660000,
          "time": "2024-12-30T17:13:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735578960000,
          "time": "2024-12-30T17:18:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735579260000,
          "time": "2024-12-30T17:23:00Z",
          "energy": 39.74400000087917,
          "capacity": 476.92800001055
      },
      {
          "time_stamp_utc_ms": 1735579560000,
          "time": "2024-12-30T17:28:00Z",
          "energy": 36.09599999897182,
          "capacity": 433.15199998766184
      },
      {
          "time_stamp_utc_ms": 1735579860000,
          "time": "2024-12-30T17:33:00Z",
          "energy": 38.30400000140071,
          "capacity": 459.6480000168085
      },
      {
          "time_stamp_utc_ms": 1735580160000,
          "time": "2024-12-30T17:38:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1735580460000,
          "time": "2024-12-30T17:43:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735580760000,
          "time": "2024-12-30T17:48:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735581060000,
          "time": "2024-12-30T17:53:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1735581360000,
          "time": "2024-12-30T17:58:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1735581660000,
          "time": "2024-12-30T18:03:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1735581960000,
          "time": "2024-12-30T18:08:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735582260000,
          "time": "2024-12-30T18:13:00Z",
          "energy": 37.5359999993816,
          "capacity": 450.4319999925792
      },
      {
          "time_stamp_utc_ms": 1735582560000,
          "time": "2024-12-30T18:18:00Z",
          "energy": 39.84000000078231,
          "capacity": 478.08000000938773
      },
      {
          "time_stamp_utc_ms": 1735582860000,
          "time": "2024-12-30T18:23:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1735583160000,
          "time": "2024-12-30T18:28:00Z",
          "energy": 44.06400000117719,
          "capacity": 528.7680000141264
      },
      {
          "time_stamp_utc_ms": 1735583460000,
          "time": "2024-12-30T18:33:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1735583760000,
          "time": "2024-12-30T18:38:00Z",
          "energy": 41.95200000144541,
          "capacity": 503.42400001734495
      },
      {
          "time_stamp_utc_ms": 1735584060000,
          "time": "2024-12-30T18:43:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1735584360000,
          "time": "2024-12-30T18:48:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1735584660000,
          "time": "2024-12-30T18:53:00Z",
          "energy": 41.567999999038875,
          "capacity": 498.81599998846656
      },
      {
          "time_stamp_utc_ms": 1735584960000,
          "time": "2024-12-30T18:58:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1735585260000,
          "time": "2024-12-30T19:03:00Z",
          "energy": 36.768000001087785,
          "capacity": 441.2160000130535
      },
      {
          "time_stamp_utc_ms": 1735585560000,
          "time": "2024-12-30T19:08:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735585860000,
          "time": "2024-12-30T19:13:00Z",
          "energy": 35.32799999881536,
          "capacity": 423.93599998578435
      },
      {
          "time_stamp_utc_ms": 1735586160000,
          "time": "2024-12-30T19:18:00Z",
          "energy": 36.67200000118464,
          "capacity": 440.06400001421576
      },
      {
          "time_stamp_utc_ms": 1735586460000,
          "time": "2024-12-30T19:23:00Z",
          "energy": 36.671999999322,
          "capacity": 440.06399999186397
      },
      {
          "time_stamp_utc_ms": 1735586760000,
          "time": "2024-12-30T19:28:00Z",
          "energy": 36.096000000834465,
          "capacity": 433.1520000100136
      },
      {
          "time_stamp_utc_ms": 1735587060000,
          "time": "2024-12-30T19:33:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1735587360000,
          "time": "2024-12-30T19:38:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1735587660000,
          "time": "2024-12-30T19:43:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1735587960000,
          "time": "2024-12-30T19:48:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735588260000,
          "time": "2024-12-30T19:53:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1735588560000,
          "time": "2024-12-30T19:58:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1735588860000,
          "time": "2024-12-30T20:03:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1735589160000,
          "time": "2024-12-30T20:08:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1735589460000,
          "time": "2024-12-30T20:13:00Z",
          "energy": 43.87200000137091,
          "capacity": 526.464000016451
      },
      {
          "time_stamp_utc_ms": 1735589760000,
          "time": "2024-12-30T20:18:00Z",
          "energy": 40.607999998144805,
          "capacity": 487.29599997773767
      },
      {
          "time_stamp_utc_ms": 1735590060000,
          "time": "2024-12-30T20:23:00Z",
          "energy": 42.52800000086427,
          "capacity": 510.3360000103712
      },
      {
          "time_stamp_utc_ms": 1735590360000,
          "time": "2024-12-30T20:28:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1735590660000,
          "time": "2024-12-30T20:33:00Z",
          "energy": 41.28000000026077,
          "capacity": 495.36000000312924
      },
      {
          "time_stamp_utc_ms": 1735590960000,
          "time": "2024-12-30T20:38:00Z",
          "energy": 42.24000000115484,
          "capacity": 506.8800000138581
      },
      {
          "time_stamp_utc_ms": 1735591260000,
          "time": "2024-12-30T20:43:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1735591560000,
          "time": "2024-12-30T20:48:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735591860000,
          "time": "2024-12-30T20:53:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1735592160000,
          "time": "2024-12-30T20:58:00Z",
          "energy": 45.312000000849366,
          "capacity": 543.7440000101924
      },
      {
          "time_stamp_utc_ms": 1735592460000,
          "time": "2024-12-30T21:03:00Z",
          "energy": 45.791999999433756,
          "capacity": 549.5039999932052
      },
      {
          "time_stamp_utc_ms": 1735592760000,
          "time": "2024-12-30T21:08:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735593060000,
          "time": "2024-12-30T21:13:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1735593360000,
          "time": "2024-12-30T21:18:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1735593660000,
          "time": "2024-12-30T21:23:00Z",
          "energy": 46.08000000100583,
          "capacity": 552.9600000120699
      },
      {
          "time_stamp_utc_ms": 1735593960000,
          "time": "2024-12-30T21:28:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735594260000,
          "time": "2024-12-30T21:33:00Z",
          "energy": 44.447999998927116,
          "capacity": 533.3759999871254
      },
      {
          "time_stamp_utc_ms": 1735594560000,
          "time": "2024-12-30T21:38:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735594860000,
          "time": "2024-12-30T21:43:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1735595160000,
          "time": "2024-12-30T21:48:00Z",
          "energy": 44.92799999937415,
          "capacity": 539.1359999924898
      },
      {
          "time_stamp_utc_ms": 1735595460000,
          "time": "2024-12-30T21:53:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1735595760000,
          "time": "2024-12-30T21:58:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1735596060000,
          "time": "2024-12-30T22:03:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1735596360000,
          "time": "2024-12-30T22:08:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735596660000,
          "time": "2024-12-30T22:13:00Z",
          "energy": 44.64000000059605,
          "capacity": 535.6800000071527
      },
      {
          "time_stamp_utc_ms": 1735596960000,
          "time": "2024-12-30T22:18:00Z",
          "energy": 46.27199999894947,
          "capacity": 555.2639999873937
      },
      {
          "time_stamp_utc_ms": 1735597260000,
          "time": "2024-12-30T22:23:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1735597560000,
          "time": "2024-12-30T22:28:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735597860000,
          "time": "2024-12-30T22:33:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1735598160000,
          "time": "2024-12-30T22:38:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735598460000,
          "time": "2024-12-30T22:43:00Z",
          "energy": 46.56000000052154,
          "capacity": 558.7200000062585
      },
      {
          "time_stamp_utc_ms": 1735598760000,
          "time": "2024-12-30T22:48:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1735599060000,
          "time": "2024-12-30T22:53:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735599360000,
          "time": "2024-12-30T22:58:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735599660000,
          "time": "2024-12-30T23:03:00Z",
          "energy": 44.544000000692904,
          "capacity": 534.528000008315
      },
      {
          "time_stamp_utc_ms": 1735599960000,
          "time": "2024-12-30T23:08:00Z",
          "energy": 46.46399999875575,
          "capacity": 557.5679999850692
      },
      {
          "time_stamp_utc_ms": 1735600260000,
          "time": "2024-12-30T23:13:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735600560000,
          "time": "2024-12-30T23:18:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1735600860000,
          "time": "2024-12-30T23:23:00Z",
          "energy": 46.463999999687076,
          "capacity": 557.5679999962449
      },
      {
          "time_stamp_utc_ms": 1735601160000,
          "time": "2024-12-30T23:28:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1735601460000,
          "time": "2024-12-30T23:33:00Z",
          "energy": 46.17600000090897,
          "capacity": 554.1120000109076
      },
      {
          "time_stamp_utc_ms": 1735601760000,
          "time": "2024-12-30T23:38:00Z",
          "energy": 45.696000000461936,
          "capacity": 548.3520000055432
      },
      {
          "time_stamp_utc_ms": 1735602060000,
          "time": "2024-12-30T23:43:00Z",
          "energy": 45.59999999869615,
          "capacity": 547.1999999843538
      },
      {
          "time_stamp_utc_ms": 1735602360000,
          "time": "2024-12-30T23:48:00Z",
          "energy": 47.71200000029057,
          "capacity": 572.5440000034869
      },
      {
          "time_stamp_utc_ms": 1735602660000,
          "time": "2024-12-30T23:53:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735602960000,
          "time": "2024-12-30T23:58:00Z",
          "energy": 46.65599999949336,
          "capacity": 559.8719999939203
      },
      {
          "time_stamp_utc_ms": 1735603260000,
          "time": "2024-12-31T00:03:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735603560000,
          "time": "2024-12-31T00:08:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735603860000,
          "time": "2024-12-31T00:13:00Z",
          "energy": 46.080000000074506,
          "capacity": 552.9600000008941
      },
      {
          "time_stamp_utc_ms": 1735604160000,
          "time": "2024-12-31T00:18:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735604460000,
          "time": "2024-12-31T00:23:00Z",
          "energy": 45.79200000036508,
          "capacity": 549.5040000043809
      },
      {
          "time_stamp_utc_ms": 1735604760000,
          "time": "2024-12-31T00:28:00Z",
          "energy": 45.98399999830872,
          "capacity": 551.8079999797046
      },
      {
          "time_stamp_utc_ms": 1735605060000,
          "time": "2024-12-31T00:33:00Z",
          "energy": 43.96800000127405,
          "capacity": 527.6160000152887
      },
      {
          "time_stamp_utc_ms": 1735605360000,
          "time": "2024-12-31T00:38:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1735605660000,
          "time": "2024-12-31T00:43:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735605960000,
          "time": "2024-12-31T00:48:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735606260000,
          "time": "2024-12-31T00:53:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735606560000,
          "time": "2024-12-31T00:58:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735606860000,
          "time": "2024-12-31T01:03:00Z",
          "energy": 45.79200000036508,
          "capacity": 549.5040000043809
      },
      {
          "time_stamp_utc_ms": 1735607160000,
          "time": "2024-12-31T01:08:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735607460000,
          "time": "2024-12-31T01:13:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735607760000,
          "time": "2024-12-31T01:18:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1735608060000,
          "time": "2024-12-31T01:23:00Z",
          "energy": 44.447999998927116,
          "capacity": 533.3759999871254
      },
      {
          "time_stamp_utc_ms": 1735608360000,
          "time": "2024-12-31T01:28:00Z",
          "energy": 45.02400000113994,
          "capacity": 540.2880000136793
      },
      {
          "time_stamp_utc_ms": 1735608660000,
          "time": "2024-12-31T01:33:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735608960000,
          "time": "2024-12-31T01:38:00Z",
          "energy": 44.92799999937415,
          "capacity": 539.1359999924898
      },
      {
          "time_stamp_utc_ms": 1735609260000,
          "time": "2024-12-31T01:43:00Z",
          "energy": 46.847999999299645,
          "capacity": 562.1759999915957
      },
      {
          "time_stamp_utc_ms": 1735609560000,
          "time": "2024-12-31T01:48:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735609860000,
          "time": "2024-12-31T01:53:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735610160000,
          "time": "2024-12-31T01:58:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1735610460000,
          "time": "2024-12-31T02:03:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1735610760000,
          "time": "2024-12-31T02:08:00Z",
          "energy": 42.14399999845773,
          "capacity": 505.7279999814928
      },
      {
          "time_stamp_utc_ms": 1735611060000,
          "time": "2024-12-31T02:13:00Z",
          "energy": 41.088000000454485,
          "capacity": 493.0560000054538
      },
      {
          "time_stamp_utc_ms": 1735611360000,
          "time": "2024-12-31T02:18:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735611660000,
          "time": "2024-12-31T02:23:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1735611960000,
          "time": "2024-12-31T02:28:00Z",
          "energy": 39.552000001072884,
          "capacity": 474.6240000128746
      },
      {
          "time_stamp_utc_ms": 1735612260000,
          "time": "2024-12-31T02:33:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735612560000,
          "time": "2024-12-31T02:38:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1735612860000,
          "time": "2024-12-31T02:43:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1735613160000,
          "time": "2024-12-31T02:48:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1735613460000,
          "time": "2024-12-31T02:53:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1735613760000,
          "time": "2024-12-31T02:58:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1735614060000,
          "time": "2024-12-31T03:03:00Z",
          "energy": 38.68800000101328,
          "capacity": 464.25600001215935
      },
      {
          "time_stamp_utc_ms": 1735614360000,
          "time": "2024-12-31T03:08:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1735614660000,
          "time": "2024-12-31T03:13:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1735614960000,
          "time": "2024-12-31T03:18:00Z",
          "energy": 38.112000001594424,
          "capacity": 457.3440000191331
      },
      {
          "time_stamp_utc_ms": 1735615260000,
          "time": "2024-12-31T03:23:00Z",
          "energy": 40.31999999843538,
          "capacity": 483.83999998122454
      },
      {
          "time_stamp_utc_ms": 1735615560000,
          "time": "2024-12-31T03:28:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1735615860000,
          "time": "2024-12-31T03:33:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1735616160000,
          "time": "2024-12-31T03:38:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1735616460000,
          "time": "2024-12-31T03:43:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735616760000,
          "time": "2024-12-31T03:48:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735617060000,
          "time": "2024-12-31T03:53:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1735617360000,
          "time": "2024-12-31T03:58:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735617660000,
          "time": "2024-12-31T04:03:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735617960000,
          "time": "2024-12-31T04:08:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1735618260000,
          "time": "2024-12-31T04:13:00Z",
          "energy": 37.63200000114739,
          "capacity": 451.58400001376873
      },
      {
          "time_stamp_utc_ms": 1735618560000,
          "time": "2024-12-31T04:18:00Z",
          "energy": 37.53599999845028,
          "capacity": 450.43199998140335
      },
      {
          "time_stamp_utc_ms": 1735618860000,
          "time": "2024-12-31T04:23:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1735619160000,
          "time": "2024-12-31T04:28:00Z",
          "energy": 39.07200000155717,
          "capacity": 468.86400001868606
      },
      {
          "time_stamp_utc_ms": 1735619460000,
          "time": "2024-12-31T04:33:00Z",
          "energy": 38.11199999880046,
          "capacity": 457.3439999856055
      },
      {
          "time_stamp_utc_ms": 1735619760000,
          "time": "2024-12-31T04:38:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1735620060000,
          "time": "2024-12-31T04:43:00Z",
          "energy": 37.631999999284744,
          "capacity": 451.58399999141693
      },
      {
          "time_stamp_utc_ms": 1735620360000,
          "time": "2024-12-31T04:48:00Z",
          "energy": 37.440000001341105,
          "capacity": 449.2800000160933
      },
      {
          "time_stamp_utc_ms": 1735620660000,
          "time": "2024-12-31T04:53:00Z",
          "energy": 38.87999999895692,
          "capacity": 466.5599999874831
      },
      {
          "time_stamp_utc_ms": 1735620960000,
          "time": "2024-12-31T04:58:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1735621260000,
          "time": "2024-12-31T05:03:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735621560000,
          "time": "2024-12-31T05:08:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1735621860000,
          "time": "2024-12-31T05:13:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1735622160000,
          "time": "2024-12-31T05:18:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735622460000,
          "time": "2024-12-31T05:23:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1735622760000,
          "time": "2024-12-31T05:28:00Z",
          "energy": 37.15199999883771,
          "capacity": 445.8239999860525
      },
      {
          "time_stamp_utc_ms": 1735623060000,
          "time": "2024-12-31T05:33:00Z",
          "energy": 38.68800000101328,
          "capacity": 464.25600001215935
      },
      {
          "time_stamp_utc_ms": 1735623360000,
          "time": "2024-12-31T05:38:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1735623660000,
          "time": "2024-12-31T05:43:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735623960000,
          "time": "2024-12-31T05:48:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1735624260000,
          "time": "2024-12-31T05:53:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1735624560000,
          "time": "2024-12-31T05:58:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1735624860000,
          "time": "2024-12-31T06:03:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1735625160000,
          "time": "2024-12-31T06:08:00Z",
          "energy": 37.72799999918789,
          "capacity": 452.73599999025464
      },
      {
          "time_stamp_utc_ms": 1735625460000,
          "time": "2024-12-31T06:13:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1735625760000,
          "time": "2024-12-31T06:18:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1735626060000,
          "time": "2024-12-31T06:23:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1735626360000,
          "time": "2024-12-31T06:28:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735626660000,
          "time": "2024-12-31T06:33:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1735626960000,
          "time": "2024-12-31T06:38:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1735627260000,
          "time": "2024-12-31T06:43:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1735627560000,
          "time": "2024-12-31T06:48:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735627860000,
          "time": "2024-12-31T06:53:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735628160000,
          "time": "2024-12-31T06:58:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1735628460000,
          "time": "2024-12-31T07:03:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735628760000,
          "time": "2024-12-31T07:08:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1735629060000,
          "time": "2024-12-31T07:13:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1735629360000,
          "time": "2024-12-31T07:18:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1735629660000,
          "time": "2024-12-31T07:23:00Z",
          "energy": 37.05600000079721,
          "capacity": 444.6720000095666
      },
      {
          "time_stamp_utc_ms": 1735629960000,
          "time": "2024-12-31T07:28:00Z",
          "energy": 38.687999999150634,
          "capacity": 464.25599998980766
      },
      {
          "time_stamp_utc_ms": 1735630260000,
          "time": "2024-12-31T07:33:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1735630560000,
          "time": "2024-12-31T07:38:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735630860000,
          "time": "2024-12-31T07:43:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735631160000,
          "time": "2024-12-31T07:48:00Z",
          "energy": 37.152000000700355,
          "capacity": 445.8240000084043
      },
      {
          "time_stamp_utc_ms": 1735631460000,
          "time": "2024-12-31T07:53:00Z",
          "energy": 37.82399999909103,
          "capacity": 453.88799998909235
      },
      {
          "time_stamp_utc_ms": 1735631760000,
          "time": "2024-12-31T07:58:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1735632060000,
          "time": "2024-12-31T08:03:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735632360000,
          "time": "2024-12-31T08:08:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1735632660000,
          "time": "2024-12-31T08:13:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1735632960000,
          "time": "2024-12-31T08:18:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1735633260000,
          "time": "2024-12-31T08:23:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1735633560000,
          "time": "2024-12-31T08:28:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1735633860000,
          "time": "2024-12-31T08:33:00Z",
          "energy": 37.152000000700355,
          "capacity": 445.8240000084043
      },
      {
          "time_stamp_utc_ms": 1735634160000,
          "time": "2024-12-31T08:38:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1735634460000,
          "time": "2024-12-31T08:43:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1735634760000,
          "time": "2024-12-31T08:48:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1735635060000,
          "time": "2024-12-31T08:53:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1735635360000,
          "time": "2024-12-31T08:58:00Z",
          "energy": 37.72799999918789,
          "capacity": 452.73599999025464
      },
      {
          "time_stamp_utc_ms": 1735635660000,
          "time": "2024-12-31T09:03:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1735635960000,
          "time": "2024-12-31T09:08:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1735636260000,
          "time": "2024-12-31T09:13:00Z",
          "energy": 37.92000000085682,
          "capacity": 455.04000001028186
      },
      {
          "time_stamp_utc_ms": 1735636560000,
          "time": "2024-12-31T09:18:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1735636860000,
          "time": "2024-12-31T09:23:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1735637160000,
          "time": "2024-12-31T09:28:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1735637460000,
          "time": "2024-12-31T09:33:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735637760000,
          "time": "2024-12-31T09:38:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735638060000,
          "time": "2024-12-31T09:43:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735638360000,
          "time": "2024-12-31T09:48:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1735638660000,
          "time": "2024-12-31T09:53:00Z",
          "energy": 37.15199999883771,
          "capacity": 445.8239999860525
      },
      {
          "time_stamp_utc_ms": 1735638960000,
          "time": "2024-12-31T09:58:00Z",
          "energy": 41.760000000707805,
          "capacity": 501.1200000084937
      },
      {
          "time_stamp_utc_ms": 1735639260000,
          "time": "2024-12-31T10:03:00Z",
          "energy": 42.62399999983609,
          "capacity": 511.4879999980331
      },
      {
          "time_stamp_utc_ms": 1735639560000,
          "time": "2024-12-31T10:08:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1735639860000,
          "time": "2024-12-31T10:13:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735640160000,
          "time": "2024-12-31T10:18:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735640460000,
          "time": "2024-12-31T10:23:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1735640760000,
          "time": "2024-12-31T10:28:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735641060000,
          "time": "2024-12-31T10:33:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735641360000,
          "time": "2024-12-31T10:38:00Z",
          "energy": 44.35200000181794,
          "capacity": 532.2240000218153
      },
      {
          "time_stamp_utc_ms": 1735641660000,
          "time": "2024-12-31T10:43:00Z",
          "energy": 42.81599999871105,
          "capacity": 513.7919999845326
      },
      {
          "time_stamp_utc_ms": 1735641960000,
          "time": "2024-12-31T10:48:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1735642260000,
          "time": "2024-12-31T10:53:00Z",
          "energy": 44.35200000088662,
          "capacity": 532.2240000106395
      },
      {
          "time_stamp_utc_ms": 1735642560000,
          "time": "2024-12-31T10:58:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735642860000,
          "time": "2024-12-31T11:03:00Z",
          "energy": 43.872000000439584,
          "capacity": 526.464000005275
      },
      {
          "time_stamp_utc_ms": 1735643160000,
          "time": "2024-12-31T11:08:00Z",
          "energy": 43.7759999986738,
          "capacity": 525.3119999840856
      },
      {
          "time_stamp_utc_ms": 1735643460000,
          "time": "2024-12-31T11:13:00Z",
          "energy": 43.39200000092387,
          "capacity": 520.7040000110865
      },
      {
          "time_stamp_utc_ms": 1735643760000,
          "time": "2024-12-31T11:18:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735644060000,
          "time": "2024-12-31T11:23:00Z",
          "energy": 42.24000000115484,
          "capacity": 506.8800000138581
      },
      {
          "time_stamp_utc_ms": 1735644360000,
          "time": "2024-12-31T11:28:00Z",
          "energy": 44.351999999023974,
          "capacity": 532.2239999882877
      },
      {
          "time_stamp_utc_ms": 1735644660000,
          "time": "2024-12-31T11:33:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735644960000,
          "time": "2024-12-31T11:38:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735645260000,
          "time": "2024-12-31T11:43:00Z",
          "energy": 44.351999999023974,
          "capacity": 532.2239999882877
      },
      {
          "time_stamp_utc_ms": 1735645560000,
          "time": "2024-12-31T11:48:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735645860000,
          "time": "2024-12-31T11:53:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1735646160000,
          "time": "2024-12-31T11:58:00Z",
          "energy": 44.25600000098348,
          "capacity": 531.0720000118018
      },
      {
          "time_stamp_utc_ms": 1735646460000,
          "time": "2024-12-31T12:03:00Z",
          "energy": 44.83199999947101,
          "capacity": 537.9839999936521
      },
      {
          "time_stamp_utc_ms": 1735646760000,
          "time": "2024-12-31T12:08:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1735647060000,
          "time": "2024-12-31T12:13:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1735647360000,
          "time": "2024-12-31T12:18:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735647660000,
          "time": "2024-12-31T12:23:00Z",
          "energy": 44.83199999947101,
          "capacity": 537.9839999936521
      },
      {
          "time_stamp_utc_ms": 1735647960000,
          "time": "2024-12-31T12:28:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1735648260000,
          "time": "2024-12-31T12:33:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1735648560000,
          "time": "2024-12-31T12:38:00Z",
          "energy": 43.967999999411404,
          "capacity": 527.6159999929368
      },
      {
          "time_stamp_utc_ms": 1735648860000,
          "time": "2024-12-31T12:43:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735649160000,
          "time": "2024-12-31T12:48:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735649460000,
          "time": "2024-12-31T12:53:00Z",
          "energy": 44.544000000692904,
          "capacity": 534.528000008315
      },
      {
          "time_stamp_utc_ms": 1735649760000,
          "time": "2024-12-31T12:58:00Z",
          "energy": 45.40799999888986,
          "capacity": 544.8959999866784
      },
      {
          "time_stamp_utc_ms": 1735650060000,
          "time": "2024-12-31T13:03:00Z",
          "energy": 45.02400000113994,
          "capacity": 540.2880000136793
      },
      {
          "time_stamp_utc_ms": 1735650360000,
          "time": "2024-12-31T13:08:00Z",
          "energy": 43.967999999411404,
          "capacity": 527.6159999929368
      },
      {
          "time_stamp_utc_ms": 1735650660000,
          "time": "2024-12-31T13:13:00Z",
          "energy": 46.463999999687076,
          "capacity": 557.5679999962449
      },
      {
          "time_stamp_utc_ms": 1735650960000,
          "time": "2024-12-31T13:18:00Z",
          "energy": 44.35200000088662,
          "capacity": 532.2240000106395
      },
      {
          "time_stamp_utc_ms": 1735651260000,
          "time": "2024-12-31T13:23:00Z",
          "energy": 44.83199999947101,
          "capacity": 537.9839999936521
      },
      {
          "time_stamp_utc_ms": 1735651560000,
          "time": "2024-12-31T13:28:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735651860000,
          "time": "2024-12-31T13:33:00Z",
          "energy": 44.35200000088662,
          "capacity": 532.2240000106395
      },
      {
          "time_stamp_utc_ms": 1735652160000,
          "time": "2024-12-31T13:38:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735652460000,
          "time": "2024-12-31T13:43:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735652760000,
          "time": "2024-12-31T13:48:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735653060000,
          "time": "2024-12-31T13:53:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735653360000,
          "time": "2024-12-31T13:58:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735653660000,
          "time": "2024-12-31T14:03:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1735653960000,
          "time": "2024-12-31T14:08:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1735654260000,
          "time": "2024-12-31T14:13:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1735654560000,
          "time": "2024-12-31T14:18:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1735654860000,
          "time": "2024-12-31T14:23:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1735655160000,
          "time": "2024-12-31T14:28:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1735655460000,
          "time": "2024-12-31T14:33:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1735655760000,
          "time": "2024-12-31T14:38:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735656060000,
          "time": "2024-12-31T14:43:00Z",
          "energy": 44.15999999921769,
          "capacity": 529.9199999906123
      },
      {
          "time_stamp_utc_ms": 1735656360000,
          "time": "2024-12-31T14:48:00Z",
          "energy": 44.064000002108514,
          "capacity": 528.7680000253022
      },
      {
          "time_stamp_utc_ms": 1735656660000,
          "time": "2024-12-31T14:53:00Z",
          "energy": 45.503999998793006,
          "capacity": 546.0479999855161
      },
      {
          "time_stamp_utc_ms": 1735656960000,
          "time": "2024-12-31T14:58:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735657260000,
          "time": "2024-12-31T15:03:00Z",
          "energy": 44.64000000059605,
          "capacity": 535.6800000071527
      },
      {
          "time_stamp_utc_ms": 1735657560000,
          "time": "2024-12-31T15:08:00Z",
          "energy": 44.15999999828637,
          "capacity": 529.9199999794364
      },
      {
          "time_stamp_utc_ms": 1735657860000,
          "time": "2024-12-31T15:13:00Z",
          "energy": 46.17600000090897,
          "capacity": 554.1120000109076
      },
      {
          "time_stamp_utc_ms": 1735658160000,
          "time": "2024-12-31T15:18:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1735658460000,
          "time": "2024-12-31T15:23:00Z",
          "energy": 43.6800000006333,
          "capacity": 524.1600000075996
      },
      {
          "time_stamp_utc_ms": 1735658760000,
          "time": "2024-12-31T15:28:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735659060000,
          "time": "2024-12-31T15:33:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1735659360000,
          "time": "2024-12-31T15:38:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735659660000,
          "time": "2024-12-31T15:43:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1735659960000,
          "time": "2024-12-31T15:48:00Z",
          "energy": 43.872000000439584,
          "capacity": 526.464000005275
      },
      {
          "time_stamp_utc_ms": 1735660260000,
          "time": "2024-12-31T15:53:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735660560000,
          "time": "2024-12-31T15:58:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735660860000,
          "time": "2024-12-31T16:03:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1735661160000,
          "time": "2024-12-31T16:08:00Z",
          "energy": 44.64000000059605,
          "capacity": 535.6800000071527
      },
      {
          "time_stamp_utc_ms": 1735661460000,
          "time": "2024-12-31T16:13:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735661760000,
          "time": "2024-12-31T16:18:00Z",
          "energy": 44.92799999937415,
          "capacity": 539.1359999924898
      },
      {
          "time_stamp_utc_ms": 1735662060000,
          "time": "2024-12-31T16:23:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1735662360000,
          "time": "2024-12-31T16:28:00Z",
          "energy": 43.967999999411404,
          "capacity": 527.6159999929368
      },
      {
          "time_stamp_utc_ms": 1735662660000,
          "time": "2024-12-31T16:33:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735662960000,
          "time": "2024-12-31T16:38:00Z",
          "energy": 42.62400000076741,
          "capacity": 511.4880000092089
      },
      {
          "time_stamp_utc_ms": 1735663260000,
          "time": "2024-12-31T16:43:00Z",
          "energy": 44.15999999921769,
          "capacity": 529.9199999906123
      },
      {
          "time_stamp_utc_ms": 1735663560000,
          "time": "2024-12-31T16:48:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735663860000,
          "time": "2024-12-31T16:53:00Z",
          "energy": 45.79200000036508,
          "capacity": 549.5040000043809
      },
      {
          "time_stamp_utc_ms": 1735664160000,
          "time": "2024-12-31T16:58:00Z",
          "energy": 44.83199999947101,
          "capacity": 537.9839999936521
      },
      {
          "time_stamp_utc_ms": 1735664460000,
          "time": "2024-12-31T17:03:00Z",
          "energy": 45.984000001102686,
          "capacity": 551.8080000132322
      },
      {
          "time_stamp_utc_ms": 1735664760000,
          "time": "2024-12-31T17:08:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735665060000,
          "time": "2024-12-31T17:13:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735665360000,
          "time": "2024-12-31T17:18:00Z",
          "energy": 43.295999999158084,
          "capacity": 519.551999989897
      },
      {
          "time_stamp_utc_ms": 1735665660000,
          "time": "2024-12-31T17:23:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1735665960000,
          "time": "2024-12-31T17:28:00Z",
          "energy": 44.44800000078976,
          "capacity": 533.3760000094773
      },
      {
          "time_stamp_utc_ms": 1735666260000,
          "time": "2024-12-31T17:33:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1735666560000,
          "time": "2024-12-31T17:38:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1735666860000,
          "time": "2024-12-31T17:43:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1735667160000,
          "time": "2024-12-31T17:48:00Z",
          "energy": 46.175999999046326,
          "capacity": 554.111999988556
      },
      {
          "time_stamp_utc_ms": 1735667460000,
          "time": "2024-12-31T17:53:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735667760000,
          "time": "2024-12-31T17:58:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735668060000,
          "time": "2024-12-31T18:03:00Z",
          "energy": 45.21599999908358,
          "capacity": 542.5919999890029
      },
      {
          "time_stamp_utc_ms": 1735668360000,
          "time": "2024-12-31T18:08:00Z",
          "energy": 43.104000001214445,
          "capacity": 517.2480000145733
      },
      {
          "time_stamp_utc_ms": 1735668660000,
          "time": "2024-12-31T18:13:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735668960000,
          "time": "2024-12-31T18:18:00Z",
          "energy": 44.06399999931455,
          "capacity": 528.7679999917746
      },
      {
          "time_stamp_utc_ms": 1735669260000,
          "time": "2024-12-31T18:23:00Z",
          "energy": 43.872000000439584,
          "capacity": 526.464000005275
      },
      {
          "time_stamp_utc_ms": 1735669560000,
          "time": "2024-12-31T18:28:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735669860000,
          "time": "2024-12-31T18:33:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1735670160000,
          "time": "2024-12-31T18:38:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1735670460000,
          "time": "2024-12-31T18:43:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1735670760000,
          "time": "2024-12-31T18:48:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1735671060000,
          "time": "2024-12-31T18:53:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1735671360000,
          "time": "2024-12-31T18:58:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735671660000,
          "time": "2024-12-31T19:03:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1735671960000,
          "time": "2024-12-31T19:08:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735672260000,
          "time": "2024-12-31T19:13:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735672560000,
          "time": "2024-12-31T19:18:00Z",
          "energy": 40.80000000074506,
          "capacity": 489.6000000089407
      },
      {
          "time_stamp_utc_ms": 1735672860000,
          "time": "2024-12-31T19:23:00Z",
          "energy": 43.58400000073016,
          "capacity": 523.0080000087619
      },
      {
          "time_stamp_utc_ms": 1735673160000,
          "time": "2024-12-31T19:28:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1735673460000,
          "time": "2024-12-31T19:33:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735673760000,
          "time": "2024-12-31T19:38:00Z",
          "energy": 42.23999999836087,
          "capacity": 506.8799999803305
      },
      {
          "time_stamp_utc_ms": 1735674060000,
          "time": "2024-12-31T19:43:00Z",
          "energy": 38.68800000101328,
          "capacity": 464.25600001215935
      },
      {
          "time_stamp_utc_ms": 1735674360000,
          "time": "2024-12-31T19:48:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1735674660000,
          "time": "2024-12-31T19:53:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1735674960000,
          "time": "2024-12-31T19:58:00Z",
          "energy": 40.51200000103563,
          "capacity": 486.14400001242757
      },
      {
          "time_stamp_utc_ms": 1735675260000,
          "time": "2024-12-31T20:03:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1735675560000,
          "time": "2024-12-31T20:08:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735675860000,
          "time": "2024-12-31T20:13:00Z",
          "energy": 40.895999998785555,
          "capacity": 490.75199998542666
      },
      {
          "time_stamp_utc_ms": 1735676160000,
          "time": "2024-12-31T20:18:00Z",
          "energy": 39.552000001072884,
          "capacity": 474.6240000128746
      },
      {
          "time_stamp_utc_ms": 1735676460000,
          "time": "2024-12-31T20:23:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735676760000,
          "time": "2024-12-31T20:28:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1735677060000,
          "time": "2024-12-31T20:33:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1735677360000,
          "time": "2024-12-31T20:38:00Z",
          "energy": 43.00799999944866,
          "capacity": 516.0959999933839
      },
      {
          "time_stamp_utc_ms": 1735677660000,
          "time": "2024-12-31T20:43:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1735677960000,
          "time": "2024-12-31T20:48:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1735678260000,
          "time": "2024-12-31T20:53:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1735678560000,
          "time": "2024-12-31T20:58:00Z",
          "energy": 43.20000000111759,
          "capacity": 518.400000013411
      },
      {
          "time_stamp_utc_ms": 1735678860000,
          "time": "2024-12-31T21:03:00Z",
          "energy": 42.71999999880791,
          "capacity": 512.6399999856949
      },
      {
          "time_stamp_utc_ms": 1735679160000,
          "time": "2024-12-31T21:08:00Z",
          "energy": 43.39200000092387,
          "capacity": 520.7040000110865
      },
      {
          "time_stamp_utc_ms": 1735679460000,
          "time": "2024-12-31T21:13:00Z",
          "energy": 44.54399999883026,
          "capacity": 534.5279999859631
      },
      {
          "time_stamp_utc_ms": 1735679760000,
          "time": "2024-12-31T21:18:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1735680060000,
          "time": "2024-12-31T21:23:00Z",
          "energy": 45.119999999180436,
          "capacity": 541.4399999901652
      },
      {
          "time_stamp_utc_ms": 1735680360000,
          "time": "2024-12-31T21:28:00Z",
          "energy": 43.488000000827014,
          "capacity": 521.8560000099242
      },
      {
          "time_stamp_utc_ms": 1735680660000,
          "time": "2024-12-31T21:33:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735680960000,
          "time": "2024-12-31T21:38:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735681260000,
          "time": "2024-12-31T21:43:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735681560000,
          "time": "2024-12-31T21:48:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735681860000,
          "time": "2024-12-31T21:53:00Z",
          "energy": 44.351999999023974,
          "capacity": 532.2239999882877
      },
      {
          "time_stamp_utc_ms": 1735682160000,
          "time": "2024-12-31T21:58:00Z",
          "energy": 47.424000000581145,
          "capacity": 569.0880000069737
      },
      {
          "time_stamp_utc_ms": 1735682460000,
          "time": "2024-12-31T22:03:00Z",
          "energy": 45.696000000461936,
          "capacity": 548.3520000055432
      },
      {
          "time_stamp_utc_ms": 1735682760000,
          "time": "2024-12-31T22:08:00Z",
          "energy": 45.59999999869615,
          "capacity": 547.1999999843538
      },
      {
          "time_stamp_utc_ms": 1735683060000,
          "time": "2024-12-31T22:13:00Z",
          "energy": 45.69600000139326,
          "capacity": 548.3520000167191
      },
      {
          "time_stamp_utc_ms": 1735683360000,
          "time": "2024-12-31T22:18:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1735683660000,
          "time": "2024-12-31T22:23:00Z",
          "energy": 46.55999999959022,
          "capacity": 558.7199999950826
      },
      {
          "time_stamp_utc_ms": 1735683960000,
          "time": "2024-12-31T22:28:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1735684260000,
          "time": "2024-12-31T22:33:00Z",
          "energy": 45.98399999924004,
          "capacity": 551.8079999908806
      },
      {
          "time_stamp_utc_ms": 1735684560000,
          "time": "2024-12-31T22:38:00Z",
          "energy": 46.27200000081211,
          "capacity": 555.2640000097454
      },
      {
          "time_stamp_utc_ms": 1735684860000,
          "time": "2024-12-31T22:43:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1735685160000,
          "time": "2024-12-31T22:48:00Z",
          "energy": 46.94400000106543,
          "capacity": 563.3280000127852
      },
      {
          "time_stamp_utc_ms": 1735685460000,
          "time": "2024-12-31T22:53:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1735685760000,
          "time": "2024-12-31T22:58:00Z",
          "energy": 48.38399999961257,
          "capacity": 580.607999995351
      },
      {
          "time_stamp_utc_ms": 1735686060000,
          "time": "2024-12-31T23:03:00Z",
          "energy": 46.752000000327826,
          "capacity": 561.0240000039339
      },
      {
          "time_stamp_utc_ms": 1735686360000,
          "time": "2024-12-31T23:08:00Z",
          "energy": 45.696000000461936,
          "capacity": 548.3520000055432
      },
      {
          "time_stamp_utc_ms": 1735686660000,
          "time": "2024-12-31T23:13:00Z",
          "energy": 45.79199999850243,
          "capacity": 549.5039999820292
      },
      {
          "time_stamp_utc_ms": 1735686960000,
          "time": "2024-12-31T23:18:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735687260000,
          "time": "2024-12-31T23:23:00Z",
          "energy": 45.79200000036508,
          "capacity": 549.5040000043809
      },
      {
          "time_stamp_utc_ms": 1735687560000,
          "time": "2024-12-31T23:28:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735687860000,
          "time": "2024-12-31T23:33:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1735688160000,
          "time": "2024-12-31T23:38:00Z",
          "energy": 45.503999998793006,
          "capacity": 546.0479999855161
      },
      {
          "time_stamp_utc_ms": 1735688460000,
          "time": "2024-12-31T23:43:00Z",
          "energy": 44.35200000088662,
          "capacity": 532.2240000106395
      },
      {
          "time_stamp_utc_ms": 1735688760000,
          "time": "2024-12-31T23:48:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1735689060000,
          "time": "2024-12-31T23:53:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1735689360000,
          "time": "2024-12-31T23:58:00Z",
          "energy": 46.080000000074506,
          "capacity": 552.9600000008941
      },
      {
          "time_stamp_utc_ms": 1735689660000,
          "time": "2025-01-01T00:03:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735689960000,
          "time": "2025-01-01T00:08:00Z",
          "energy": 45.98399999924004,
          "capacity": 551.8079999908806
      },
      {
          "time_stamp_utc_ms": 1735690260000,
          "time": "2025-01-01T00:13:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735690560000,
          "time": "2025-01-01T00:18:00Z",
          "energy": 44.544000000692904,
          "capacity": 534.528000008315
      },
      {
          "time_stamp_utc_ms": 1735690860000,
          "time": "2025-01-01T00:23:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1735691160000,
          "time": "2025-01-01T00:28:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1735691460000,
          "time": "2025-01-01T00:33:00Z",
          "energy": 46.27200000081211,
          "capacity": 555.2640000097454
      },
      {
          "time_stamp_utc_ms": 1735691760000,
          "time": "2025-01-01T00:38:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1735692060000,
          "time": "2025-01-01T00:43:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735692360000,
          "time": "2025-01-01T00:48:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1735692660000,
          "time": "2025-01-01T00:53:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735692960000,
          "time": "2025-01-01T00:58:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735693260000,
          "time": "2025-01-01T01:03:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735693560000,
          "time": "2025-01-01T01:08:00Z",
          "energy": 45.791999999433756,
          "capacity": 549.5039999932052
      },
      {
          "time_stamp_utc_ms": 1735693860000,
          "time": "2025-01-01T01:13:00Z",
          "energy": 43.872000000439584,
          "capacity": 526.464000005275
      },
      {
          "time_stamp_utc_ms": 1735694160000,
          "time": "2025-01-01T01:18:00Z",
          "energy": 45.02400000113994,
          "capacity": 540.2880000136793
      },
      {
          "time_stamp_utc_ms": 1735694460000,
          "time": "2025-01-01T01:23:00Z",
          "energy": 44.92799999844283,
          "capacity": 539.135999981314
      },
      {
          "time_stamp_utc_ms": 1735694760000,
          "time": "2025-01-01T01:28:00Z",
          "energy": 43.872000000439584,
          "capacity": 526.464000005275
      },
      {
          "time_stamp_utc_ms": 1735695060000,
          "time": "2025-01-01T01:33:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735695360000,
          "time": "2025-01-01T01:38:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735695660000,
          "time": "2025-01-01T01:43:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735695960000,
          "time": "2025-01-01T01:48:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1735696260000,
          "time": "2025-01-01T01:53:00Z",
          "energy": 43.00799999944866,
          "capacity": 516.0959999933839
      },
      {
          "time_stamp_utc_ms": 1735696560000,
          "time": "2025-01-01T01:58:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735696860000,
          "time": "2025-01-01T02:03:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735697160000,
          "time": "2025-01-01T02:08:00Z",
          "energy": 42.62399999983609,
          "capacity": 511.4879999980331
      },
      {
          "time_stamp_utc_ms": 1735697460000,
          "time": "2025-01-01T02:13:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1735697760000,
          "time": "2025-01-01T02:18:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1735698060000,
          "time": "2025-01-01T02:23:00Z",
          "energy": 40.8960000006482,
          "capacity": 490.7520000077784
      },
      {
          "time_stamp_utc_ms": 1735698360000,
          "time": "2025-01-01T02:28:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1735698660000,
          "time": "2025-01-01T02:33:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1735698960000,
          "time": "2025-01-01T02:38:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735699260000,
          "time": "2025-01-01T02:43:00Z",
          "energy": 41.47199999913573,
          "capacity": 497.66399998962885
      },
      {
          "time_stamp_utc_ms": 1735699560000,
          "time": "2025-01-01T02:48:00Z",
          "energy": 40.51200000196695,
          "capacity": 486.1440000236035
      },
      {
          "time_stamp_utc_ms": 1735699860000,
          "time": "2025-01-01T02:53:00Z",
          "energy": 39.74399999901652,
          "capacity": 476.92799998819834
      },
      {
          "time_stamp_utc_ms": 1735700160000,
          "time": "2025-01-01T02:58:00Z",
          "energy": 40.60799999907613,
          "capacity": 487.29599998891354
      },
      {
          "time_stamp_utc_ms": 1735700460000,
          "time": "2025-01-01T03:03:00Z",
          "energy": 39.552000001072884,
          "capacity": 474.6240000128746
      },
      {
          "time_stamp_utc_ms": 1735700760000,
          "time": "2025-01-01T03:08:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735701060000,
          "time": "2025-01-01T03:13:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1735701360000,
          "time": "2025-01-01T03:18:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735701660000,
          "time": "2025-01-01T03:23:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735701960000,
          "time": "2025-01-01T03:28:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1735702260000,
          "time": "2025-01-01T03:33:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735702560000,
          "time": "2025-01-01T03:38:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1735702860000,
          "time": "2025-01-01T03:43:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1735703160000,
          "time": "2025-01-01T03:48:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1735703460000,
          "time": "2025-01-01T03:53:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1735703760000,
          "time": "2025-01-01T03:58:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735704060000,
          "time": "2025-01-01T04:03:00Z",
          "energy": 36.671999999322,
          "capacity": 440.06399999186397
      },
      {
          "time_stamp_utc_ms": 1735704360000,
          "time": "2025-01-01T04:08:00Z",
          "energy": 37.92000000085682,
          "capacity": 455.04000001028186
      },
      {
          "time_stamp_utc_ms": 1735704660000,
          "time": "2025-01-01T04:13:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1735704960000,
          "time": "2025-01-01T04:18:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1735705260000,
          "time": "2025-01-01T04:23:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1735705560000,
          "time": "2025-01-01T04:28:00Z",
          "energy": 37.72799999918789,
          "capacity": 452.73599999025464
      },
      {
          "time_stamp_utc_ms": 1735705860000,
          "time": "2025-01-01T04:33:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1735706160000,
          "time": "2025-01-01T04:38:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735706460000,
          "time": "2025-01-01T04:43:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1735706760000,
          "time": "2025-01-01T04:48:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1735707060000,
          "time": "2025-01-01T04:53:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1735707360000,
          "time": "2025-01-01T04:58:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735707660000,
          "time": "2025-01-01T05:03:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1735707960000,
          "time": "2025-01-01T05:08:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1735708260000,
          "time": "2025-01-01T05:13:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1735708560000,
          "time": "2025-01-01T05:18:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1735708860000,
          "time": "2025-01-01T05:23:00Z",
          "energy": 37.15199999976903,
          "capacity": 445.8239999972284
      },
      {
          "time_stamp_utc_ms": 1735709160000,
          "time": "2025-01-01T05:28:00Z",
          "energy": 38.112000001594424,
          "capacity": 457.3440000191331
      },
      {
          "time_stamp_utc_ms": 1735709460000,
          "time": "2025-01-01T05:33:00Z",
          "energy": 38.015999998897314,
          "capacity": 456.19199998676777
      },
      {
          "time_stamp_utc_ms": 1735709760000,
          "time": "2025-01-01T05:38:00Z",
          "energy": 38.68800000101328,
          "capacity": 464.25600001215935
      },
      {
          "time_stamp_utc_ms": 1735710060000,
          "time": "2025-01-01T05:43:00Z",
          "energy": 38.87999999895692,
          "capacity": 466.5599999874831
      },
      {
          "time_stamp_utc_ms": 1735710360000,
          "time": "2025-01-01T05:48:00Z",
          "energy": 37.05600000079721,
          "capacity": 444.6720000095666
      },
      {
          "time_stamp_utc_ms": 1735710660000,
          "time": "2025-01-01T05:53:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1735710960000,
          "time": "2025-01-01T05:58:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1735711260000,
          "time": "2025-01-01T06:03:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1735711560000,
          "time": "2025-01-01T06:08:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735711860000,
          "time": "2025-01-01T06:13:00Z",
          "energy": 37.05599999893457,
          "capacity": 444.6719999872148
      },
      {
          "time_stamp_utc_ms": 1735712160000,
          "time": "2025-01-01T06:18:00Z",
          "energy": 38.30400000140071,
          "capacity": 459.6480000168085
      },
      {
          "time_stamp_utc_ms": 1735712460000,
          "time": "2025-01-01T06:23:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1735712760000,
          "time": "2025-01-01T06:28:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735713060000,
          "time": "2025-01-01T06:33:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1735713360000,
          "time": "2025-01-01T06:38:00Z",
          "energy": 38.49599999934435,
          "capacity": 461.95199999213224
      },
      {
          "time_stamp_utc_ms": 1735713660000,
          "time": "2025-01-01T06:43:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1735713960000,
          "time": "2025-01-01T06:48:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1735714260000,
          "time": "2025-01-01T06:53:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1735714560000,
          "time": "2025-01-01T06:58:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1735714860000,
          "time": "2025-01-01T07:03:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1735715160000,
          "time": "2025-01-01T07:08:00Z",
          "energy": 37.72799999918789,
          "capacity": 452.73599999025464
      },
      {
          "time_stamp_utc_ms": 1735715460000,
          "time": "2025-01-01T07:13:00Z",
          "energy": 37.2480000006035,
          "capacity": 446.976000007242
      },
      {
          "time_stamp_utc_ms": 1735715760000,
          "time": "2025-01-01T07:18:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1735716060000,
          "time": "2025-01-01T07:23:00Z",
          "energy": 36.38399999961257,
          "capacity": 436.60799999535084
      },
      {
          "time_stamp_utc_ms": 1735716360000,
          "time": "2025-01-01T07:28:00Z",
          "energy": 38.40000000130385,
          "capacity": 460.8000000156462
      },
      {
          "time_stamp_utc_ms": 1735716660000,
          "time": "2025-01-01T07:33:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1735716960000,
          "time": "2025-01-01T07:38:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735717260000,
          "time": "2025-01-01T07:43:00Z",
          "energy": 37.63200000021607,
          "capacity": 451.58400000259286
      },
      {
          "time_stamp_utc_ms": 1735717560000,
          "time": "2025-01-01T07:48:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1735717860000,
          "time": "2025-01-01T07:53:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735718160000,
          "time": "2025-01-01T07:58:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1735718460000,
          "time": "2025-01-01T08:03:00Z",
          "energy": 37.72799999918789,
          "capacity": 452.73599999025464
      },
      {
          "time_stamp_utc_ms": 1735718760000,
          "time": "2025-01-01T08:08:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1735719060000,
          "time": "2025-01-01T08:13:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735719360000,
          "time": "2025-01-01T08:18:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735719660000,
          "time": "2025-01-01T08:23:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1735719960000,
          "time": "2025-01-01T08:28:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1735720260000,
          "time": "2025-01-01T08:33:00Z",
          "energy": 36.096000000834465,
          "capacity": 433.1520000100136
      },
      {
          "time_stamp_utc_ms": 1735720560000,
          "time": "2025-01-01T08:38:00Z",
          "energy": 38.68799999821931,
          "capacity": 464.25599997863173
      },
      {
          "time_stamp_utc_ms": 1735720860000,
          "time": "2025-01-01T08:43:00Z",
          "energy": 36.5760000012815,
          "capacity": 438.91200001537806
      },
      {
          "time_stamp_utc_ms": 1735721160000,
          "time": "2025-01-01T08:48:00Z",
          "energy": 38.015999998897314,
          "capacity": 456.19199998676777
      },
      {
          "time_stamp_utc_ms": 1735721460000,
          "time": "2025-01-01T08:53:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1735721760000,
          "time": "2025-01-01T08:58:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1735722060000,
          "time": "2025-01-01T09:03:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1735722360000,
          "time": "2025-01-01T09:08:00Z",
          "energy": 36.96000000089407,
          "capacity": 443.5200000107289
      },
      {
          "time_stamp_utc_ms": 1735722660000,
          "time": "2025-01-01T09:13:00Z",
          "energy": 38.11199999880046,
          "capacity": 457.3439999856055
      },
      {
          "time_stamp_utc_ms": 1735722960000,
          "time": "2025-01-01T09:18:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1735723260000,
          "time": "2025-01-01T09:23:00Z",
          "energy": 36.38400000054389,
          "capacity": 436.6080000065267
      },
      {
          "time_stamp_utc_ms": 1735723560000,
          "time": "2025-01-01T09:28:00Z",
          "energy": 37.72800000011921,
          "capacity": 452.73600000143057
      },
      {
          "time_stamp_utc_ms": 1735723860000,
          "time": "2025-01-01T09:33:00Z",
          "energy": 36.57599999848753,
          "capacity": 438.9119999818504
      },
      {
          "time_stamp_utc_ms": 1735724160000,
          "time": "2025-01-01T09:38:00Z",
          "energy": 38.496000001206994,
          "capacity": 461.95200001448393
      },
      {
          "time_stamp_utc_ms": 1735724460000,
          "time": "2025-01-01T09:43:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1735724760000,
          "time": "2025-01-01T09:48:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1735725060000,
          "time": "2025-01-01T09:53:00Z",
          "energy": 37.631999999284744,
          "capacity": 451.58399999141693
      },
      {
          "time_stamp_utc_ms": 1735725360000,
          "time": "2025-01-01T09:58:00Z",
          "energy": 40.41600000113249,
          "capacity": 484.99200001358986
      },
      {
          "time_stamp_utc_ms": 1735725660000,
          "time": "2025-01-01T10:03:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1735725960000,
          "time": "2025-01-01T10:08:00Z",
          "energy": 42.24000000115484,
          "capacity": 506.8800000138581
      },
      {
          "time_stamp_utc_ms": 1735726260000,
          "time": "2025-01-01T10:13:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735726560000,
          "time": "2025-01-01T10:18:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1735726860000,
          "time": "2025-01-01T10:23:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735727160000,
          "time": "2025-01-01T10:28:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735727460000,
          "time": "2025-01-01T10:33:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1735727760000,
          "time": "2025-01-01T10:38:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735728060000,
          "time": "2025-01-01T10:43:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1735728360000,
          "time": "2025-01-01T10:48:00Z",
          "energy": 44.25600000098348,
          "capacity": 531.0720000118018
      },
      {
          "time_stamp_utc_ms": 1735728660000,
          "time": "2025-01-01T10:53:00Z",
          "energy": 42.71999999880791,
          "capacity": 512.6399999856949
      },
      {
          "time_stamp_utc_ms": 1735728960000,
          "time": "2025-01-01T10:58:00Z",
          "energy": 42.72000000067055,
          "capacity": 512.6400000080466
      },
      {
          "time_stamp_utc_ms": 1735729260000,
          "time": "2025-01-01T11:03:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1735729560000,
          "time": "2025-01-01T11:08:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1735729860000,
          "time": "2025-01-01T11:13:00Z",
          "energy": 43.58400000073016,
          "capacity": 523.0080000087619
      },
      {
          "time_stamp_utc_ms": 1735730160000,
          "time": "2025-01-01T11:18:00Z",
          "energy": 42.14399999938905,
          "capacity": 505.72799999266863
      },
      {
          "time_stamp_utc_ms": 1735730460000,
          "time": "2025-01-01T11:23:00Z",
          "energy": 43.00799999944866,
          "capacity": 516.0959999933839
      },
      {
          "time_stamp_utc_ms": 1735730760000,
          "time": "2025-01-01T11:28:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1735731060000,
          "time": "2025-01-01T11:33:00Z",
          "energy": 42.24000000115484,
          "capacity": 506.8800000138581
      },
      {
          "time_stamp_utc_ms": 1735731360000,
          "time": "2025-01-01T11:38:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1735731660000,
          "time": "2025-01-01T11:43:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735731960000,
          "time": "2025-01-01T11:48:00Z",
          "energy": 44.06399999931455,
          "capacity": 528.7679999917746
      },
      {
          "time_stamp_utc_ms": 1735732260000,
          "time": "2025-01-01T11:53:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735732560000,
          "time": "2025-01-01T11:58:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1735732860000,
          "time": "2025-01-01T12:03:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735733160000,
          "time": "2025-01-01T12:08:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735733460000,
          "time": "2025-01-01T12:13:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735733760000,
          "time": "2025-01-01T12:18:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735734060000,
          "time": "2025-01-01T12:23:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735734360000,
          "time": "2025-01-01T12:28:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735734660000,
          "time": "2025-01-01T12:33:00Z",
          "energy": 43.29600000102073,
          "capacity": 519.5520000122488
      },
      {
          "time_stamp_utc_ms": 1735734960000,
          "time": "2025-01-01T12:38:00Z",
          "energy": 44.35199999809265,
          "capacity": 532.2239999771118
      },
      {
          "time_stamp_utc_ms": 1735735260000,
          "time": "2025-01-01T12:43:00Z",
          "energy": 42.91200000140816,
          "capacity": 514.9440000168979
      },
      {
          "time_stamp_utc_ms": 1735735560000,
          "time": "2025-01-01T12:48:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735735860000,
          "time": "2025-01-01T12:53:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1735736160000,
          "time": "2025-01-01T12:58:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735736460000,
          "time": "2025-01-01T13:03:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1735736760000,
          "time": "2025-01-01T13:08:00Z",
          "energy": 43.295999999158084,
          "capacity": 519.551999989897
      },
      {
          "time_stamp_utc_ms": 1735737060000,
          "time": "2025-01-01T13:13:00Z",
          "energy": 43.6800000006333,
          "capacity": 524.1600000075996
      },
      {
          "time_stamp_utc_ms": 1735737360000,
          "time": "2025-01-01T13:18:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1735737660000,
          "time": "2025-01-01T13:23:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735737960000,
          "time": "2025-01-01T13:28:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735738260000,
          "time": "2025-01-01T13:33:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735738560000,
          "time": "2025-01-01T13:38:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1735738860000,
          "time": "2025-01-01T13:43:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1735739160000,
          "time": "2025-01-01T13:48:00Z",
          "energy": 43.20000000111759,
          "capacity": 518.400000013411
      },
      {
          "time_stamp_utc_ms": 1735739460000,
          "time": "2025-01-01T13:53:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1735739760000,
          "time": "2025-01-01T13:58:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1735740060000,
          "time": "2025-01-01T14:03:00Z",
          "energy": 41.47199999913573,
          "capacity": 497.66399998962885
      },
      {
          "time_stamp_utc_ms": 1735740360000,
          "time": "2025-01-01T14:08:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1735740660000,
          "time": "2025-01-01T14:13:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1735740960000,
          "time": "2025-01-01T14:18:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1735741260000,
          "time": "2025-01-01T14:23:00Z",
          "energy": 42.62399999983609,
          "capacity": 511.4879999980331
      },
      {
          "time_stamp_utc_ms": 1735741560000,
          "time": "2025-01-01T14:28:00Z",
          "energy": 39.74399999901652,
          "capacity": 476.92799998819834
      },
      {
          "time_stamp_utc_ms": 1735741860000,
          "time": "2025-01-01T14:33:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1735742160000,
          "time": "2025-01-01T14:38:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1735742460000,
          "time": "2025-01-01T14:43:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1735742760000,
          "time": "2025-01-01T14:48:00Z",
          "energy": 39.167999998666346,
          "capacity": 470.0159999839962
      },
      {
          "time_stamp_utc_ms": 1735743060000,
          "time": "2025-01-01T14:53:00Z",
          "energy": 38.01600000169128,
          "capacity": 456.1920000202954
      },
      {
          "time_stamp_utc_ms": 1735743360000,
          "time": "2025-01-01T14:58:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735743660000,
          "time": "2025-01-01T15:03:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1735743960000,
          "time": "2025-01-01T15:08:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735744260000,
          "time": "2025-01-01T15:13:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1735744560000,
          "time": "2025-01-01T15:18:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1735744860000,
          "time": "2025-01-01T15:23:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1735745160000,
          "time": "2025-01-01T15:28:00Z",
          "energy": 37.05599999893457,
          "capacity": 444.6719999872148
      },
      {
          "time_stamp_utc_ms": 1735745460000,
          "time": "2025-01-01T15:33:00Z",
          "energy": 37.72800000105053,
          "capacity": 452.73600001260644
      },
      {
          "time_stamp_utc_ms": 1735745760000,
          "time": "2025-01-01T15:38:00Z",
          "energy": 36.768000001087785,
          "capacity": 441.2160000130535
      },
      {
          "time_stamp_utc_ms": 1735746060000,
          "time": "2025-01-01T15:43:00Z",
          "energy": 37.05599999893457,
          "capacity": 444.6719999872148
      },
      {
          "time_stamp_utc_ms": 1735746360000,
          "time": "2025-01-01T15:48:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1735746660000,
          "time": "2025-01-01T15:53:00Z",
          "energy": 35.61600000038743,
          "capacity": 427.39200000464916
      },
      {
          "time_stamp_utc_ms": 1735746960000,
          "time": "2025-01-01T15:58:00Z",
          "energy": 37.63200000021607,
          "capacity": 451.58400000259286
      },
      {
          "time_stamp_utc_ms": 1735747260000,
          "time": "2025-01-01T16:03:00Z",
          "energy": 35.71200000029057,
          "capacity": 428.5440000034869
      },
      {
          "time_stamp_utc_ms": 1735747560000,
          "time": "2025-01-01T16:08:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1735747860000,
          "time": "2025-01-01T16:13:00Z",
          "energy": 37.15199999883771,
          "capacity": 445.8239999860525
      },
      {
          "time_stamp_utc_ms": 1735748160000,
          "time": "2025-01-01T16:18:00Z",
          "energy": 36.28800000064075,
          "capacity": 435.456000007689
      },
      {
          "time_stamp_utc_ms": 1735748460000,
          "time": "2025-01-01T16:23:00Z",
          "energy": 35.424000000581145,
          "capacity": 425.08800000697374
      },
      {
          "time_stamp_utc_ms": 1735748760000,
          "time": "2025-01-01T16:28:00Z",
          "energy": 34.94400000013411,
          "capacity": 419.3280000016093
      },
      {
          "time_stamp_utc_ms": 1735749060000,
          "time": "2025-01-01T16:33:00Z",
          "energy": 37.82399999909103,
          "capacity": 453.88799998909235
      },
      {
          "time_stamp_utc_ms": 1735749360000,
          "time": "2025-01-01T16:38:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1735749660000,
          "time": "2025-01-01T16:43:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1735749960000,
          "time": "2025-01-01T16:48:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1735750260000,
          "time": "2025-01-01T16:53:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1735750560000,
          "time": "2025-01-01T16:58:00Z",
          "energy": 37.05600000079721,
          "capacity": 444.6720000095666
      },
      {
          "time_stamp_utc_ms": 1735750860000,
          "time": "2025-01-01T17:03:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1735751160000,
          "time": "2025-01-01T17:08:00Z",
          "energy": 36.575999999418855,
          "capacity": 438.91199999302626
      },
      {
          "time_stamp_utc_ms": 1735751460000,
          "time": "2025-01-01T17:13:00Z",
          "energy": 34.368000000715256,
          "capacity": 412.41600000858307
      },
      {
          "time_stamp_utc_ms": 1735751760000,
          "time": "2025-01-01T17:18:00Z",
          "energy": 34.27199999988079,
          "capacity": 411.26399999856955
      },
      {
          "time_stamp_utc_ms": 1735752060000,
          "time": "2025-01-01T17:23:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1735752360000,
          "time": "2025-01-01T17:28:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1735752660000,
          "time": "2025-01-01T17:33:00Z",
          "energy": 35.13599999900907,
          "capacity": 421.63199998810893
      },
      {
          "time_stamp_utc_ms": 1735752960000,
          "time": "2025-01-01T17:38:00Z",
          "energy": 33.12000000104308,
          "capacity": 397.44000001251703
      },
      {
          "time_stamp_utc_ms": 1735753260000,
          "time": "2025-01-01T17:43:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1735753560000,
          "time": "2025-01-01T17:48:00Z",
          "energy": 34.46399999875575,
          "capacity": 413.5679999850691
      },
      {
          "time_stamp_utc_ms": 1735753860000,
          "time": "2025-01-01T17:53:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1735754160000,
          "time": "2025-01-01T17:58:00Z",
          "energy": 35.328000000678,
          "capacity": 423.93600000813603
      },
      {
          "time_stamp_utc_ms": 1735754460000,
          "time": "2025-01-01T18:03:00Z",
          "energy": 33.69599999953061,
          "capacity": 404.3519999943674
      },
      {
          "time_stamp_utc_ms": 1735754760000,
          "time": "2025-01-01T18:08:00Z",
          "energy": 34.94400000013411,
          "capacity": 419.3280000016093
      },
      {
          "time_stamp_utc_ms": 1735755060000,
          "time": "2025-01-01T18:13:00Z",
          "energy": 33.60000000055879,
          "capacity": 403.2000000067056
      },
      {
          "time_stamp_utc_ms": 1735755360000,
          "time": "2025-01-01T18:18:00Z",
          "energy": 33.50399999972433,
          "capacity": 402.047999996692
      },
      {
          "time_stamp_utc_ms": 1735755660000,
          "time": "2025-01-01T18:23:00Z",
          "energy": 32.54399999976158,
          "capacity": 390.527999997139
      },
      {
          "time_stamp_utc_ms": 1735755960000,
          "time": "2025-01-01T18:28:00Z",
          "energy": 33.50400000065565,
          "capacity": 402.04800000786787
      },
      {
          "time_stamp_utc_ms": 1735756260000,
          "time": "2025-01-01T18:33:00Z",
          "energy": 34.847999999299645,
          "capacity": 418.1759999915958
      },
      {
          "time_stamp_utc_ms": 1735756560000,
          "time": "2025-01-01T18:38:00Z",
          "energy": 34.368000000715256,
          "capacity": 412.41600000858307
      },
      {
          "time_stamp_utc_ms": 1735756860000,
          "time": "2025-01-01T18:43:00Z",
          "energy": 35.231999998912215,
          "capacity": 422.78399998694664
      },
      {
          "time_stamp_utc_ms": 1735757160000,
          "time": "2025-01-01T18:48:00Z",
          "energy": 33.69600000139326,
          "capacity": 404.3520000167191
      },
      {
          "time_stamp_utc_ms": 1735757460000,
          "time": "2025-01-01T18:53:00Z",
          "energy": 34.36799999885261,
          "capacity": 412.4159999862314
      },
      {
          "time_stamp_utc_ms": 1735757760000,
          "time": "2025-01-01T18:58:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1735758060000,
          "time": "2025-01-01T19:03:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1735758360000,
          "time": "2025-01-01T19:08:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735758660000,
          "time": "2025-01-01T19:13:00Z",
          "energy": 32.639999999664724,
          "capacity": 391.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735758960000,
          "time": "2025-01-01T19:18:00Z",
          "energy": 34.84800000023097,
          "capacity": 418.1760000027716
      },
      {
          "time_stamp_utc_ms": 1735759260000,
          "time": "2025-01-01T19:23:00Z",
          "energy": 34.847999999299645,
          "capacity": 418.1759999915958
      },
      {
          "time_stamp_utc_ms": 1735759560000,
          "time": "2025-01-01T19:28:00Z",
          "energy": 35.90400000102818,
          "capacity": 430.84800001233816
      },
      {
          "time_stamp_utc_ms": 1735759860000,
          "time": "2025-01-01T19:33:00Z",
          "energy": 34.65599999949336,
          "capacity": 415.8719999939204
      },
      {
          "time_stamp_utc_ms": 1735760160000,
          "time": "2025-01-01T19:38:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735760460000,
          "time": "2025-01-01T19:43:00Z",
          "energy": 36.28799999970943,
          "capacity": 435.4559999965131
      },
      {
          "time_stamp_utc_ms": 1735760760000,
          "time": "2025-01-01T19:48:00Z",
          "energy": 34.4640000006184,
          "capacity": 413.5680000074208
      },
      {
          "time_stamp_utc_ms": 1735761060000,
          "time": "2025-01-01T19:53:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1735761360000,
          "time": "2025-01-01T19:58:00Z",
          "energy": 34.55999999959022,
          "capacity": 414.7199999950827
      },
      {
          "time_stamp_utc_ms": 1735761660000,
          "time": "2025-01-01T20:03:00Z",
          "energy": 36.00000000093132,
          "capacity": 432.00000001117587
      },
      {
          "time_stamp_utc_ms": 1735761960000,
          "time": "2025-01-01T20:08:00Z",
          "energy": 35.03999999910593,
          "capacity": 420.4799999892712
      },
      {
          "time_stamp_utc_ms": 1735762260000,
          "time": "2025-01-01T20:13:00Z",
          "energy": 36.28800000064075,
          "capacity": 435.456000007689
      },
      {
          "time_stamp_utc_ms": 1735762560000,
          "time": "2025-01-01T20:18:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1735762860000,
          "time": "2025-01-01T20:23:00Z",
          "energy": 36.287999998778105,
          "capacity": 435.45599998533726
      },
      {
          "time_stamp_utc_ms": 1735763160000,
          "time": "2025-01-01T20:28:00Z",
          "energy": 37.440000001341105,
          "capacity": 449.2800000160933
      },
      {
          "time_stamp_utc_ms": 1735763460000,
          "time": "2025-01-01T20:33:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1735763760000,
          "time": "2025-01-01T20:38:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1735764060000,
          "time": "2025-01-01T20:43:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735764360000,
          "time": "2025-01-01T20:48:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735764660000,
          "time": "2025-01-01T20:53:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1735764960000,
          "time": "2025-01-01T20:58:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1735765260000,
          "time": "2025-01-01T21:03:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1735765560000,
          "time": "2025-01-01T21:08:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1735765860000,
          "time": "2025-01-01T21:13:00Z",
          "energy": 40.80000000074506,
          "capacity": 489.6000000089407
      },
      {
          "time_stamp_utc_ms": 1735766160000,
          "time": "2025-01-01T21:18:00Z",
          "energy": 41.28000000026077,
          "capacity": 495.36000000312924
      },
      {
          "time_stamp_utc_ms": 1735766460000,
          "time": "2025-01-01T21:23:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1735766760000,
          "time": "2025-01-01T21:28:00Z",
          "energy": 41.95200000051409,
          "capacity": 503.42400000616914
      },
      {
          "time_stamp_utc_ms": 1735767060000,
          "time": "2025-01-01T21:33:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1735767360000,
          "time": "2025-01-01T21:38:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735767660000,
          "time": "2025-01-01T21:43:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735767960000,
          "time": "2025-01-01T21:48:00Z",
          "energy": 43.39199999906123,
          "capacity": 520.7039999887347
      },
      {
          "time_stamp_utc_ms": 1735768260000,
          "time": "2025-01-01T21:53:00Z",
          "energy": 42.72000000067055,
          "capacity": 512.6400000080466
      },
      {
          "time_stamp_utc_ms": 1735768560000,
          "time": "2025-01-01T21:58:00Z",
          "energy": 41.75999999884516,
          "capacity": 501.119999986142
      },
      {
          "time_stamp_utc_ms": 1735768860000,
          "time": "2025-01-01T22:03:00Z",
          "energy": 42.24000000115484,
          "capacity": 506.8800000138581
      },
      {
          "time_stamp_utc_ms": 1735769160000,
          "time": "2025-01-01T22:08:00Z",
          "energy": 40.99199999962002,
          "capacity": 491.9039999954403
      },
      {
          "time_stamp_utc_ms": 1735769460000,
          "time": "2025-01-01T22:13:00Z",
          "energy": 42.62400000076741,
          "capacity": 511.4880000092089
      },
      {
          "time_stamp_utc_ms": 1735769760000,
          "time": "2025-01-01T22:18:00Z",
          "energy": 41.95200000051409,
          "capacity": 503.42400000616914
      },
      {
          "time_stamp_utc_ms": 1735770060000,
          "time": "2025-01-01T22:23:00Z",
          "energy": 42.239999999292195,
          "capacity": 506.87999999150634
      },
      {
          "time_stamp_utc_ms": 1735770360000,
          "time": "2025-01-01T22:28:00Z",
          "energy": 42.81599999871105,
          "capacity": 513.7919999845326
      },
      {
          "time_stamp_utc_ms": 1735770660000,
          "time": "2025-01-01T22:33:00Z",
          "energy": 41.56800000090152,
          "capacity": 498.8160000108183
      },
      {
          "time_stamp_utc_ms": 1735770960000,
          "time": "2025-01-01T22:38:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1735771260000,
          "time": "2025-01-01T22:43:00Z",
          "energy": 42.23999999836087,
          "capacity": 506.8799999803305
      },
      {
          "time_stamp_utc_ms": 1735771560000,
          "time": "2025-01-01T22:48:00Z",
          "energy": 43.48800000175834,
          "capacity": 521.8560000211002
      },
      {
          "time_stamp_utc_ms": 1735771860000,
          "time": "2025-01-01T22:53:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1735772160000,
          "time": "2025-01-01T22:58:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1735772460000,
          "time": "2025-01-01T23:03:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735772760000,
          "time": "2025-01-01T23:08:00Z",
          "energy": 43.00799999944866,
          "capacity": 516.0959999933839
      },
      {
          "time_stamp_utc_ms": 1735773060000,
          "time": "2025-01-01T23:13:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735773360000,
          "time": "2025-01-01T23:18:00Z",
          "energy": 42.432000000961125,
          "capacity": 509.1840000115335
      },
      {
          "time_stamp_utc_ms": 1735773660000,
          "time": "2025-01-01T23:23:00Z",
          "energy": 43.48799999803305,
          "capacity": 521.8559999763966
      },
      {
          "time_stamp_utc_ms": 1735773960000,
          "time": "2025-01-01T23:28:00Z",
          "energy": 43.39200000092387,
          "capacity": 520.7040000110865
      },
      {
          "time_stamp_utc_ms": 1735774260000,
          "time": "2025-01-01T23:33:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1735774560000,
          "time": "2025-01-01T23:38:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735774860000,
          "time": "2025-01-01T23:43:00Z",
          "energy": 42.816000000573695,
          "capacity": 513.7920000068843
      },
      {
          "time_stamp_utc_ms": 1735775160000,
          "time": "2025-01-01T23:48:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1735775460000,
          "time": "2025-01-01T23:53:00Z",
          "energy": 44.15999999828637,
          "capacity": 529.9199999794364
      },
      {
          "time_stamp_utc_ms": 1735775760000,
          "time": "2025-01-01T23:58:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735776060000,
          "time": "2025-01-02T00:03:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735776360000,
          "time": "2025-01-02T00:08:00Z",
          "energy": 43.488000000827014,
          "capacity": 521.8560000099242
      },
      {
          "time_stamp_utc_ms": 1735776660000,
          "time": "2025-01-02T00:13:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735776960000,
          "time": "2025-01-02T00:18:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1735777260000,
          "time": "2025-01-02T00:23:00Z",
          "energy": 44.25599999912083,
          "capacity": 531.07199998945
      },
      {
          "time_stamp_utc_ms": 1735777560000,
          "time": "2025-01-02T00:28:00Z",
          "energy": 43.872000000439584,
          "capacity": 526.464000005275
      },
      {
          "time_stamp_utc_ms": 1735777860000,
          "time": "2025-01-02T00:33:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735778160000,
          "time": "2025-01-02T00:38:00Z",
          "energy": 43.58399999886751,
          "capacity": 523.0079999864101
      },
      {
          "time_stamp_utc_ms": 1735778460000,
          "time": "2025-01-02T00:43:00Z",
          "energy": 43.29600000102073,
          "capacity": 519.5520000122488
      },
      {
          "time_stamp_utc_ms": 1735778760000,
          "time": "2025-01-02T00:48:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735779060000,
          "time": "2025-01-02T00:53:00Z",
          "energy": 43.39200000092387,
          "capacity": 520.7040000110865
      },
      {
          "time_stamp_utc_ms": 1735779360000,
          "time": "2025-01-02T00:58:00Z",
          "energy": 45.791999999433756,
          "capacity": 549.5039999932052
      },
      {
          "time_stamp_utc_ms": 1735779660000,
          "time": "2025-01-02T01:03:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735779960000,
          "time": "2025-01-02T01:08:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735780260000,
          "time": "2025-01-02T01:13:00Z",
          "energy": 44.06399999931455,
          "capacity": 528.7679999917746
      },
      {
          "time_stamp_utc_ms": 1735780560000,
          "time": "2025-01-02T01:18:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1735780860000,
          "time": "2025-01-02T01:23:00Z",
          "energy": 31.776000000536442,
          "capacity": 381.3120000064373
      },
      {
          "time_stamp_utc_ms": 1735781160000,
          "time": "2025-01-02T01:28:00Z",
          "energy": 27.93599999975413,
          "capacity": 335.23199999704957
      },
      {
          "time_stamp_utc_ms": 1735781460000,
          "time": "2025-01-02T01:33:00Z",
          "energy": 28.416000000201166,
          "capacity": 340.99200000241404
      },
      {
          "time_stamp_utc_ms": 1735781760000,
          "time": "2025-01-02T01:38:00Z",
          "energy": 28.703999999910593,
          "capacity": 344.4479999989272
      },
      {
          "time_stamp_utc_ms": 1735782060000,
          "time": "2025-01-02T01:43:00Z",
          "energy": 27.648000000044703,
          "capacity": 331.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735782360000,
          "time": "2025-01-02T01:48:00Z",
          "energy": 27.743999999947846,
          "capacity": 332.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735782660000,
          "time": "2025-01-02T01:53:00Z",
          "energy": 27.55200000014156,
          "capacity": 330.62400000169873
      },
      {
          "time_stamp_utc_ms": 1735782960000,
          "time": "2025-01-02T01:58:00Z",
          "energy": 25.151999999769032,
          "capacity": 301.82399999722844
      },
      {
          "time_stamp_utc_ms": 1735783260000,
          "time": "2025-01-02T02:03:00Z",
          "energy": 26.592000000178814,
          "capacity": 319.10400000214577
      },
      {
          "time_stamp_utc_ms": 1735783560000,
          "time": "2025-01-02T02:08:00Z",
          "energy": 25.536000000312924,
          "capacity": 306.4320000037551
      },
      {
          "time_stamp_utc_ms": 1735783860000,
          "time": "2025-01-02T02:13:00Z",
          "energy": 24.576000000350177,
          "capacity": 294.9120000042022
      },
      {
          "time_stamp_utc_ms": 1735784160000,
          "time": "2025-01-02T02:18:00Z",
          "energy": 24.191999999806285,
          "capacity": 290.3039999976755
      },
      {
          "time_stamp_utc_ms": 1735784460000,
          "time": "2025-01-02T02:23:00Z",
          "energy": 24.0,
          "capacity": 288.00000000000006
      },
      {
          "time_stamp_utc_ms": 1735784760000,
          "time": "2025-01-02T02:28:00Z",
          "energy": 24.0,
          "capacity": 288.00000000000006
      },
      {
          "time_stamp_utc_ms": 1735785060000,
          "time": "2025-01-02T02:33:00Z",
          "energy": 24.0,
          "capacity": 288.00000000000006
      },
      {
          "time_stamp_utc_ms": 1735785360000,
          "time": "2025-01-02T02:38:00Z",
          "energy": 23.615999999456108,
          "capacity": 283.3919999934733
      },
      {
          "time_stamp_utc_ms": 1735785660000,
          "time": "2025-01-02T02:43:00Z",
          "energy": 23.808000000193715,
          "capacity": 285.6960000023246
      },
      {
          "time_stamp_utc_ms": 1735785960000,
          "time": "2025-01-02T02:48:00Z",
          "energy": 24.384000000543892,
          "capacity": 292.60800000652677
      },
      {
          "time_stamp_utc_ms": 1735786260000,
          "time": "2025-01-02T02:53:00Z",
          "energy": 26.20799999963492,
          "capacity": 314.49599999561906
      },
      {
          "time_stamp_utc_ms": 1735786560000,
          "time": "2025-01-02T02:58:00Z",
          "energy": 30.144000000320375,
          "capacity": 361.7280000038445
      },
      {
          "time_stamp_utc_ms": 1735786860000,
          "time": "2025-01-02T03:03:00Z",
          "energy": 32.639999999664724,
          "capacity": 391.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735787160000,
          "time": "2025-01-02T03:08:00Z",
          "energy": 35.04000000003725,
          "capacity": 420.48000000044703
      },
      {
          "time_stamp_utc_ms": 1735787460000,
          "time": "2025-01-02T03:13:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1735787760000,
          "time": "2025-01-02T03:18:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1735788045000,
          "time": "2025-01-02T03:23:00Z",
          "energy": 32.95200000051409,
          "capacity": 416.23578948017797
      },
      {
          "time_stamp_utc_ms": 1735788300000,
          "time": "2025-01-02T03:28:00Z",
          "energy": 29.87999999895692,
          "capacity": 421.83529410292124
      },
      {
          "time_stamp_utc_ms": 1735788720000,
          "time": "2025-01-02T03:33:00Z",
          "energy": 57.84000000078231,
          "capacity": 495.7714285781341
      },
      {
          "time_stamp_utc_ms": 1735789140000,
          "time": "2025-01-02T03:43:00Z",
          "energy": 58.55999999959022,
          "capacity": 501.9428571393447
      },
      {
          "time_stamp_utc_ms": 1735790220000,
          "time": "2025-01-02T03:58:00Z",
          "energy": 149.28000000026077,
          "capacity": 497.6000000008693
      },
      {
          "time_stamp_utc_ms": 1735793520000,
          "time": "2025-01-02T04:53:00Z",
          "energy": 451.679999999702,
          "capacity": 492.74181818149304
      },
      {
          "time_stamp_utc_ms": 1735795140000,
          "time": "2025-01-02T05:23:00Z",
          "energy": 223.6800000006333,
          "capacity": 497.066666668074
      },
      {
          "time_stamp_utc_ms": 1735795560000,
          "time": "2025-01-02T05:28:00Z",
          "energy": 58.07999999914318,
          "capacity": 497.82857142122725
      },
      {
          "time_stamp_utc_ms": 1735797720000,
          "time": "2025-01-02T06:03:00Z",
          "energy": 301.4400000004098,
          "capacity": 502.40000000068295
      },
      {
          "time_stamp_utc_ms": 1735799580000,
          "time": "2025-01-02T06:33:00Z",
          "energy": 255.36000000033528,
          "capacity": 494.2451612909715
      },
      {
          "time_stamp_utc_ms": 1735801020000,
          "time": "2025-01-02T06:58:00Z",
          "energy": 198.71999999973923,
          "capacity": 496.799999999348
      },
      {
          "time_stamp_utc_ms": 1735801320000,
          "time": "2025-01-02T07:03:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735803180000,
          "time": "2025-01-02T07:33:00Z",
          "energy": 254.87999999988824,
          "capacity": 493.3161290320418
      },
      {
          "time_stamp_utc_ms": 1735804500000,
          "time": "2025-01-02T07:58:00Z",
          "energy": 184.32000000029802,
          "capacity": 502.6909090917219
      },
      {
          "time_stamp_utc_ms": 1735806420000,
          "time": "2025-01-02T08:28:00Z",
          "energy": 265.4400000004098,
          "capacity": 497.70000000076834
      },
      {
          "time_stamp_utc_ms": 1735810140000,
          "time": "2025-01-02T09:33:00Z",
          "energy": 510.71999999973923,
          "capacity": 494.24516129007026
      },
      {
          "time_stamp_utc_ms": 1735812000000,
          "time": "2025-01-02T10:03:00Z",
          "energy": 262.0800000000745,
          "capacity": 507.25161290336996
      },
      {
          "time_stamp_utc_ms": 1735817820000,
          "time": "2025-01-02T11:38:00Z",
          "energy": 924.9599999999627,
          "capacity": 572.140206185544
      },
      {
          "time_stamp_utc_ms": 1735820700000,
          "time": "2025-01-02T12:28:00Z",
          "energy": 476.6399999996647,
          "capacity": 595.7999999995809
      },
      {
          "time_stamp_utc_ms": 1735821120000,
          "time": "2025-01-02T12:33:00Z",
          "energy": 71.52000000048429,
          "capacity": 613.0285714327224
      },
      {
          "time_stamp_utc_ms": 1735821420000,
          "time": "2025-01-02T12:38:00Z",
          "energy": 49.43999999947846,
          "capacity": 593.2799999937415
      },
      {
          "time_stamp_utc_ms": 1735822080000,
          "time": "2025-01-02T12:48:00Z",
          "energy": 110.40000000037253,
          "capacity": 602.1818181838502
      },
      {
          "time_stamp_utc_ms": 1735823580000,
          "time": "2025-01-02T13:13:00Z",
          "energy": 250.0800000000745,
          "capacity": 600.1920000001788
      },
      {
          "time_stamp_utc_ms": 1735823760000,
          "time": "2025-01-02T13:18:00Z",
          "energy": 29.66399999987334,
          "capacity": 593.2799999974668
      },
      {
          "time_stamp_utc_ms": 1735824020000,
          "time": "2025-01-02T13:23:00Z",
          "energy": 43.77599999960512,
          "capacity": 606.1292085310763
      },
      {
          "time_stamp_utc_ms": 1735824360000,
          "time": "2025-01-02T13:28:00Z",
          "energy": 57.12000000011176,
          "capacity": 604.8000169653669
      },
      {
          "time_stamp_utc_ms": 1735824630000,
          "time": "2025-01-02T13:33:00Z",
          "energy": 44.64000000059605,
          "capacity": 595.2000000079472
      },
      {
          "time_stamp_utc_ms": 1735824975000,
          "time": "2025-01-02T13:38:00Z",
          "energy": 58.080000000074506,
          "capacity": 606.052173913821
      },
      {
          "time_stamp_utc_ms": 1735825260000,
          "time": "2025-01-02T13:43:00Z",
          "energy": 46.36799999978393,
          "capacity": 585.7010526288497
      },
      {
          "time_stamp_utc_ms": 1735825560000,
          "time": "2025-01-02T13:48:00Z",
          "energy": 49.15199999976903,
          "capacity": 589.8239999972284
      },
      {
          "time_stamp_utc_ms": 1735825860000,
          "time": "2025-01-02T13:53:00Z",
          "energy": 49.43999999947846,
          "capacity": 593.2799999937415
      },
      {
          "time_stamp_utc_ms": 1735826160000,
          "time": "2025-01-02T13:58:00Z",
          "energy": 47.61600000038743,
          "capacity": 571.3920000046492
      },
      {
          "time_stamp_utc_ms": 1735826460000,
          "time": "2025-01-02T14:03:00Z",
          "energy": 49.63200000021607,
          "capacity": 595.5840000025929
      },
      {
          "time_stamp_utc_ms": 1735826760000,
          "time": "2025-01-02T14:08:00Z",
          "energy": 48.76800000015646,
          "capacity": 585.2160000018775
      },
      {
          "time_stamp_utc_ms": 1735827060000,
          "time": "2025-01-02T14:13:00Z",
          "energy": 48.191999999806285,
          "capacity": 578.3039999976755
      },
      {
          "time_stamp_utc_ms": 1735827360000,
          "time": "2025-01-02T14:18:00Z",
          "energy": 47.90400000009686,
          "capacity": 574.8480000011623
      },
      {
          "time_stamp_utc_ms": 1735827660000,
          "time": "2025-01-02T14:23:00Z",
          "energy": 47.04000000003725,
          "capacity": 564.480000000447
      },
      {
          "time_stamp_utc_ms": 1735827960000,
          "time": "2025-01-02T14:28:00Z",
          "energy": 48.95999999996275,
          "capacity": 587.519999999553
      },
      {
          "time_stamp_utc_ms": 1735828260000,
          "time": "2025-01-02T14:33:00Z",
          "energy": 46.463999999687076,
          "capacity": 557.5679999962449
      },
      {
          "time_stamp_utc_ms": 1735828560000,
          "time": "2025-01-02T14:38:00Z",
          "energy": 47.23199999984354,
          "capacity": 566.7839999981225
      },
      {
          "time_stamp_utc_ms": 1735828860000,
          "time": "2025-01-02T14:43:00Z",
          "energy": 46.94400000106543,
          "capacity": 563.3280000127852
      },
      {
          "time_stamp_utc_ms": 1735829160000,
          "time": "2025-01-02T14:48:00Z",
          "energy": 45.98399999924004,
          "capacity": 551.8079999908806
      },
      {
          "time_stamp_utc_ms": 1735829460000,
          "time": "2025-01-02T14:53:00Z",
          "energy": 46.65599999949336,
          "capacity": 559.8719999939203
      },
      {
          "time_stamp_utc_ms": 1735829760000,
          "time": "2025-01-02T14:58:00Z",
          "energy": 44.64000000059605,
          "capacity": 535.6800000071527
      },
      {
          "time_stamp_utc_ms": 1735830060000,
          "time": "2025-01-02T15:03:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1735830360000,
          "time": "2025-01-02T15:08:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735830660000,
          "time": "2025-01-02T15:13:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1735830960000,
          "time": "2025-01-02T15:18:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735831260000,
          "time": "2025-01-02T15:23:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735831560000,
          "time": "2025-01-02T15:28:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735831860000,
          "time": "2025-01-02T15:33:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1735832160000,
          "time": "2025-01-02T15:38:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735832460000,
          "time": "2025-01-02T15:43:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1735832760000,
          "time": "2025-01-02T15:48:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1735833060000,
          "time": "2025-01-02T15:53:00Z",
          "energy": 44.928000001236796,
          "capacity": 539.1360000148416
      },
      {
          "time_stamp_utc_ms": 1735833360000,
          "time": "2025-01-02T15:58:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735833660000,
          "time": "2025-01-02T16:03:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1735833960000,
          "time": "2025-01-02T16:08:00Z",
          "energy": 44.25600000098348,
          "capacity": 531.0720000118018
      },
      {
          "time_stamp_utc_ms": 1735834260000,
          "time": "2025-01-02T16:13:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1735834560000,
          "time": "2025-01-02T16:18:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735834860000,
          "time": "2025-01-02T16:23:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1735835160000,
          "time": "2025-01-02T16:28:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1735835460000,
          "time": "2025-01-02T16:33:00Z",
          "energy": 42.81599999871105,
          "capacity": 513.7919999845326
      },
      {
          "time_stamp_utc_ms": 1735835760000,
          "time": "2025-01-02T16:38:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1735836060000,
          "time": "2025-01-02T16:43:00Z",
          "energy": 44.25600000098348,
          "capacity": 531.0720000118018
      },
      {
          "time_stamp_utc_ms": 1735836360000,
          "time": "2025-01-02T16:48:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735836660000,
          "time": "2025-01-02T16:53:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1735836960000,
          "time": "2025-01-02T16:58:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735837260000,
          "time": "2025-01-02T17:03:00Z",
          "energy": 41.47199999913573,
          "capacity": 497.66399998962885
      },
      {
          "time_stamp_utc_ms": 1735837560000,
          "time": "2025-01-02T17:08:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1735837860000,
          "time": "2025-01-02T17:13:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1735838160000,
          "time": "2025-01-02T17:18:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1735838460000,
          "time": "2025-01-02T17:23:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1735838760000,
          "time": "2025-01-02T17:28:00Z",
          "energy": 40.51200000010431,
          "capacity": 486.14400000125175
      },
      {
          "time_stamp_utc_ms": 1735839060000,
          "time": "2025-01-02T17:33:00Z",
          "energy": 41.472000000067055,
          "capacity": 497.66400000080466
      },
      {
          "time_stamp_utc_ms": 1735839360000,
          "time": "2025-01-02T17:38:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1735839660000,
          "time": "2025-01-02T17:43:00Z",
          "energy": 40.12800000142306,
          "capacity": 481.53600001707673
      },
      {
          "time_stamp_utc_ms": 1735839960000,
          "time": "2025-01-02T17:48:00Z",
          "energy": 40.99199999962002,
          "capacity": 491.9039999954403
      },
      {
          "time_stamp_utc_ms": 1735840260000,
          "time": "2025-01-02T17:53:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1735840560000,
          "time": "2025-01-02T17:58:00Z",
          "energy": 41.088000000454485,
          "capacity": 493.0560000054538
      },
      {
          "time_stamp_utc_ms": 1735840860000,
          "time": "2025-01-02T18:03:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1735841160000,
          "time": "2025-01-02T18:08:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1735841460000,
          "time": "2025-01-02T18:13:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1735841760000,
          "time": "2025-01-02T18:18:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1735842060000,
          "time": "2025-01-02T18:23:00Z",
          "energy": 40.895999998785555,
          "capacity": 490.75199998542666
      },
      {
          "time_stamp_utc_ms": 1735842360000,
          "time": "2025-01-02T18:28:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735842660000,
          "time": "2025-01-02T18:33:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1735842960000,
          "time": "2025-01-02T18:38:00Z",
          "energy": 40.2240000013262,
          "capacity": 482.68800001591444
      },
      {
          "time_stamp_utc_ms": 1735843260000,
          "time": "2025-01-02T18:43:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1735843560000,
          "time": "2025-01-02T18:48:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1735843860000,
          "time": "2025-01-02T18:53:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1735844160000,
          "time": "2025-01-02T18:58:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1735844460000,
          "time": "2025-01-02T19:03:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1735844760000,
          "time": "2025-01-02T19:08:00Z",
          "energy": 42.14399999938905,
          "capacity": 505.72799999266863
      },
      {
          "time_stamp_utc_ms": 1735845060000,
          "time": "2025-01-02T19:13:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735845360000,
          "time": "2025-01-02T19:18:00Z",
          "energy": 41.95200000051409,
          "capacity": 503.42400000616914
      },
      {
          "time_stamp_utc_ms": 1735845660000,
          "time": "2025-01-02T19:23:00Z",
          "energy": 41.27999999932945,
          "capacity": 495.35999999195343
      },
      {
          "time_stamp_utc_ms": 1735845960000,
          "time": "2025-01-02T19:28:00Z",
          "energy": 43.29600000102073,
          "capacity": 519.5520000122488
      },
      {
          "time_stamp_utc_ms": 1735846260000,
          "time": "2025-01-02T19:33:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1735846560000,
          "time": "2025-01-02T19:38:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1735846860000,
          "time": "2025-01-02T19:43:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1735847160000,
          "time": "2025-01-02T19:48:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1735847460000,
          "time": "2025-01-02T19:53:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735847760000,
          "time": "2025-01-02T19:58:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1735848060000,
          "time": "2025-01-02T20:03:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1735848360000,
          "time": "2025-01-02T20:08:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1735848660000,
          "time": "2025-01-02T20:13:00Z",
          "energy": 41.56800000090152,
          "capacity": 498.8160000108183
      },
      {
          "time_stamp_utc_ms": 1735848960000,
          "time": "2025-01-02T20:18:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735849260000,
          "time": "2025-01-02T20:23:00Z",
          "energy": 44.64000000059605,
          "capacity": 535.6800000071527
      },
      {
          "time_stamp_utc_ms": 1735849560000,
          "time": "2025-01-02T20:28:00Z",
          "energy": 43.39199999906123,
          "capacity": 520.7039999887347
      },
      {
          "time_stamp_utc_ms": 1735849860000,
          "time": "2025-01-02T20:33:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1735850160000,
          "time": "2025-01-02T20:38:00Z",
          "energy": 44.25600000098348,
          "capacity": 531.0720000118018
      },
      {
          "time_stamp_utc_ms": 1735850460000,
          "time": "2025-01-02T20:43:00Z",
          "energy": 44.83199999947101,
          "capacity": 537.9839999936521
      },
      {
          "time_stamp_utc_ms": 1735850760000,
          "time": "2025-01-02T20:48:00Z",
          "energy": 47.23199999984354,
          "capacity": 566.7839999981225
      },
      {
          "time_stamp_utc_ms": 1735851060000,
          "time": "2025-01-02T20:53:00Z",
          "energy": 46.080000000074506,
          "capacity": 552.9600000008941
      },
      {
          "time_stamp_utc_ms": 1735851360000,
          "time": "2025-01-02T20:58:00Z",
          "energy": 45.88800000119954,
          "capacity": 550.6560000143945
      },
      {
          "time_stamp_utc_ms": 1735851660000,
          "time": "2025-01-02T21:03:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1735851960000,
          "time": "2025-01-02T21:08:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735852260000,
          "time": "2025-01-02T21:13:00Z",
          "energy": 48.0,
          "capacity": 576.0000000000001
      },
      {
          "time_stamp_utc_ms": 1735852560000,
          "time": "2025-01-02T21:18:00Z",
          "energy": 47.32799999974668,
          "capacity": 567.9359999969602
      },
      {
          "time_stamp_utc_ms": 1735852860000,
          "time": "2025-01-02T21:23:00Z",
          "energy": 47.71199999935925,
          "capacity": 572.543999992311
      },
      {
          "time_stamp_utc_ms": 1735853160000,
          "time": "2025-01-02T21:28:00Z",
          "energy": 46.27200000081211,
          "capacity": 555.2640000097454
      },
      {
          "time_stamp_utc_ms": 1735853460000,
          "time": "2025-01-02T21:33:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1735853760000,
          "time": "2025-01-02T21:38:00Z",
          "energy": 47.519999999552965,
          "capacity": 570.2399999946356
      },
      {
          "time_stamp_utc_ms": 1735854060000,
          "time": "2025-01-02T21:43:00Z",
          "energy": 47.71200000029057,
          "capacity": 572.5440000034869
      },
      {
          "time_stamp_utc_ms": 1735854360000,
          "time": "2025-01-02T21:48:00Z",
          "energy": 47.80799999926239,
          "capacity": 573.6959999911487
      },
      {
          "time_stamp_utc_ms": 1735854660000,
          "time": "2025-01-02T21:53:00Z",
          "energy": 46.752000000327826,
          "capacity": 561.0240000039339
      },
      {
          "time_stamp_utc_ms": 1735854960000,
          "time": "2025-01-02T21:58:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1735855260000,
          "time": "2025-01-02T22:03:00Z",
          "energy": 47.52000000048429,
          "capacity": 570.2400000058115
      },
      {
          "time_stamp_utc_ms": 1735855560000,
          "time": "2025-01-02T22:08:00Z",
          "energy": 47.42399999964982,
          "capacity": 569.0879999957979
      },
      {
          "time_stamp_utc_ms": 1735855860000,
          "time": "2025-01-02T22:13:00Z",
          "energy": 48.09599999990314,
          "capacity": 577.1519999988378
      },
      {
          "time_stamp_utc_ms": 1735856160000,
          "time": "2025-01-02T22:18:00Z",
          "energy": 48.671999999322,
          "capacity": 584.0639999918641
      },
      {
          "time_stamp_utc_ms": 1735856460000,
          "time": "2025-01-02T22:23:00Z",
          "energy": 47.040000000968575,
          "capacity": 564.4800000116229
      },
      {
          "time_stamp_utc_ms": 1735856760000,
          "time": "2025-01-02T22:28:00Z",
          "energy": 48.95999999996275,
          "capacity": 587.519999999553
      },
      {
          "time_stamp_utc_ms": 1735857060000,
          "time": "2025-01-02T22:33:00Z",
          "energy": 47.71199999935925,
          "capacity": 572.543999992311
      },
      {
          "time_stamp_utc_ms": 1735857360000,
          "time": "2025-01-02T22:38:00Z",
          "energy": 48.768000001087785,
          "capacity": 585.2160000130535
      },
      {
          "time_stamp_utc_ms": 1735857660000,
          "time": "2025-01-02T22:43:00Z",
          "energy": 48.864000000059605,
          "capacity": 586.3680000007153
      },
      {
          "time_stamp_utc_ms": 1735857960000,
          "time": "2025-01-02T22:48:00Z",
          "energy": 48.76799999922514,
          "capacity": 585.2159999907018
      },
      {
          "time_stamp_utc_ms": 1735858260000,
          "time": "2025-01-02T22:53:00Z",
          "energy": 50.20799999963492,
          "capacity": 602.4959999956192
      },
      {
          "time_stamp_utc_ms": 1735858560000,
          "time": "2025-01-02T22:58:00Z",
          "energy": 49.919999999925494,
          "capacity": 599.039999999106
      },
      {
          "time_stamp_utc_ms": 1735858860000,
          "time": "2025-01-02T23:03:00Z",
          "energy": 48.76800000015646,
          "capacity": 585.2160000018775
      },
      {
          "time_stamp_utc_ms": 1735859160000,
          "time": "2025-01-02T23:08:00Z",
          "energy": 49.247999999672174,
          "capacity": 590.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735859460000,
          "time": "2025-01-02T23:13:00Z",
          "energy": 48.57600000035018,
          "capacity": 582.9120000042021
      },
      {
          "time_stamp_utc_ms": 1735859760000,
          "time": "2025-01-02T23:18:00Z",
          "energy": 49.44000000040978,
          "capacity": 593.2800000049175
      },
      {
          "time_stamp_utc_ms": 1735860060000,
          "time": "2025-01-02T23:23:00Z",
          "energy": 48.959999999031425,
          "capacity": 587.5199999883772
      },
      {
          "time_stamp_utc_ms": 1735860360000,
          "time": "2025-01-02T23:28:00Z",
          "energy": 48.0,
          "capacity": 576.0000000000001
      },
      {
          "time_stamp_utc_ms": 1735860660000,
          "time": "2025-01-02T23:33:00Z",
          "energy": 48.67200000118464,
          "capacity": 584.0640000142158
      },
      {
          "time_stamp_utc_ms": 1735860960000,
          "time": "2025-01-02T23:38:00Z",
          "energy": 48.671999999322,
          "capacity": 584.0639999918641
      },
      {
          "time_stamp_utc_ms": 1735861260000,
          "time": "2025-01-02T23:43:00Z",
          "energy": 49.247999999672174,
          "capacity": 590.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735861560000,
          "time": "2025-01-02T23:48:00Z",
          "energy": 50.01600000075996,
          "capacity": 600.1920000091195
      },
      {
          "time_stamp_utc_ms": 1735861860000,
          "time": "2025-01-02T23:53:00Z",
          "energy": 48.671999999322,
          "capacity": 584.0639999918641
      },
      {
          "time_stamp_utc_ms": 1735862160000,
          "time": "2025-01-02T23:58:00Z",
          "energy": 50.01600000075996,
          "capacity": 600.1920000091195
      },
      {
          "time_stamp_utc_ms": 1735862460000,
          "time": "2025-01-03T00:03:00Z",
          "energy": 48.959999999031425,
          "capacity": 587.5199999883772
      },
      {
          "time_stamp_utc_ms": 1735862760000,
          "time": "2025-01-03T00:08:00Z",
          "energy": 49.53600000124425,
          "capacity": 594.432000014931
      },
      {
          "time_stamp_utc_ms": 1735863060000,
          "time": "2025-01-03T00:13:00Z",
          "energy": 49.34399999957532,
          "capacity": 592.1279999949038
      },
      {
          "time_stamp_utc_ms": 1735863360000,
          "time": "2025-01-03T00:18:00Z",
          "energy": 49.05599999986589,
          "capacity": 588.6719999983907
      },
      {
          "time_stamp_utc_ms": 1735863660000,
          "time": "2025-01-03T00:23:00Z",
          "energy": 49.15199999976903,
          "capacity": 589.8239999972284
      },
      {
          "time_stamp_utc_ms": 1735863960000,
          "time": "2025-01-03T00:28:00Z",
          "energy": 48.480000000447035,
          "capacity": 581.7600000053644
      },
      {
          "time_stamp_utc_ms": 1735864260000,
          "time": "2025-01-03T00:33:00Z",
          "energy": 47.42399999964982,
          "capacity": 569.0879999957979
      },
      {
          "time_stamp_utc_ms": 1735864560000,
          "time": "2025-01-03T00:38:00Z",
          "energy": 49.247999999672174,
          "capacity": 590.9759999960661
      },
      {
          "time_stamp_utc_ms": 1735864860000,
          "time": "2025-01-03T00:43:00Z",
          "energy": 47.71200000029057,
          "capacity": 572.5440000034869
      },
      {
          "time_stamp_utc_ms": 1735865160000,
          "time": "2025-01-03T00:48:00Z",
          "energy": 49.919999999925494,
          "capacity": 599.039999999106
      },
      {
          "time_stamp_utc_ms": 1735865460000,
          "time": "2025-01-03T00:53:00Z",
          "energy": 47.328000000678,
          "capacity": 567.936000008136
      },
      {
          "time_stamp_utc_ms": 1735865760000,
          "time": "2025-01-03T00:58:00Z",
          "energy": 47.99999999906868,
          "capacity": 575.9999999888241
      },
      {
          "time_stamp_utc_ms": 1735866060000,
          "time": "2025-01-03T01:03:00Z",
          "energy": 48.38399999961257,
          "capacity": 580.607999995351
      },
      {
          "time_stamp_utc_ms": 1735866360000,
          "time": "2025-01-03T01:08:00Z",
          "energy": 47.52000000048429,
          "capacity": 570.2400000058115
      },
      {
          "time_stamp_utc_ms": 1735866660000,
          "time": "2025-01-03T01:13:00Z",
          "energy": 48.28800000064075,
          "capacity": 579.456000007689
      },
      {
          "time_stamp_utc_ms": 1735866960000,
          "time": "2025-01-03T01:18:00Z",
          "energy": 46.94400000013411,
          "capacity": 563.3280000016093
      },
      {
          "time_stamp_utc_ms": 1735867260000,
          "time": "2025-01-03T01:23:00Z",
          "energy": 48.28799999970943,
          "capacity": 579.4559999965132
      },
      {
          "time_stamp_utc_ms": 1735867560000,
          "time": "2025-01-03T01:28:00Z",
          "energy": 48.191999999806285,
          "capacity": 578.3039999976755
      },
      {
          "time_stamp_utc_ms": 1735867860000,
          "time": "2025-01-03T01:33:00Z",
          "energy": 47.135999999940395,
          "capacity": 565.6319999992847
      },
      {
          "time_stamp_utc_ms": 1735868160000,
          "time": "2025-01-03T01:38:00Z",
          "energy": 46.84800000023097,
          "capacity": 562.1760000027716
      },
      {
          "time_stamp_utc_ms": 1735868460000,
          "time": "2025-01-03T01:43:00Z",
          "energy": 48.480000000447035,
          "capacity": 581.7600000053644
      },
      {
          "time_stamp_utc_ms": 1735868760000,
          "time": "2025-01-03T01:48:00Z",
          "energy": 47.23199999984354,
          "capacity": 566.7839999981225
      },
      {
          "time_stamp_utc_ms": 1735869060000,
          "time": "2025-01-03T01:53:00Z",
          "energy": 47.80799999926239,
          "capacity": 573.6959999911487
      },
      {
          "time_stamp_utc_ms": 1735869360000,
          "time": "2025-01-03T01:58:00Z",
          "energy": 44.544000000692904,
          "capacity": 534.528000008315
      },
      {
          "time_stamp_utc_ms": 1735869660000,
          "time": "2025-01-03T02:03:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1735869960000,
          "time": "2025-01-03T02:08:00Z",
          "energy": 44.06400000117719,
          "capacity": 528.7680000141264
      },
      {
          "time_stamp_utc_ms": 1735870260000,
          "time": "2025-01-03T02:13:00Z",
          "energy": 43.39199999906123,
          "capacity": 520.7039999887347
      },
      {
          "time_stamp_utc_ms": 1735870560000,
          "time": "2025-01-03T02:18:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735870860000,
          "time": "2025-01-03T02:23:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735871160000,
          "time": "2025-01-03T02:28:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735871460000,
          "time": "2025-01-03T02:33:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735871760000,
          "time": "2025-01-03T02:38:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1735872060000,
          "time": "2025-01-03T02:43:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735872360000,
          "time": "2025-01-03T02:48:00Z",
          "energy": 43.39199999906123,
          "capacity": 520.7039999887347
      },
      {
          "time_stamp_utc_ms": 1735872660000,
          "time": "2025-01-03T02:53:00Z",
          "energy": 42.52800000086427,
          "capacity": 510.3360000103712
      },
      {
          "time_stamp_utc_ms": 1735872960000,
          "time": "2025-01-03T02:58:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735873260000,
          "time": "2025-01-03T03:03:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1735873560000,
          "time": "2025-01-03T03:08:00Z",
          "energy": 42.71999999880791,
          "capacity": 512.6399999856949
      },
      {
          "time_stamp_utc_ms": 1735873860000,
          "time": "2025-01-03T03:13:00Z",
          "energy": 43.0080000013113,
          "capacity": 516.0960000157356
      },
      {
          "time_stamp_utc_ms": 1735874160000,
          "time": "2025-01-03T03:18:00Z",
          "energy": 43.6800000006333,
          "capacity": 524.1600000075996
      },
      {
          "time_stamp_utc_ms": 1735874460000,
          "time": "2025-01-03T03:23:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1735874760000,
          "time": "2025-01-03T03:28:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735875060000,
          "time": "2025-01-03T03:33:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1735875360000,
          "time": "2025-01-03T03:38:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1735875660000,
          "time": "2025-01-03T03:43:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1735875960000,
          "time": "2025-01-03T03:48:00Z",
          "energy": 42.24000000022352,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1735876260000,
          "time": "2025-01-03T03:53:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1735876560000,
          "time": "2025-01-03T03:58:00Z",
          "energy": 41.376000001095235,
          "capacity": 496.5120000131429
      },
      {
          "time_stamp_utc_ms": 1735876860000,
          "time": "2025-01-03T04:03:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1735877160000,
          "time": "2025-01-03T04:08:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735877460000,
          "time": "2025-01-03T04:13:00Z",
          "energy": 43.10399999842048,
          "capacity": 517.2479999810457
      },
      {
          "time_stamp_utc_ms": 1735877760000,
          "time": "2025-01-03T04:18:00Z",
          "energy": 42.33600000105798,
          "capacity": 508.0320000126958
      },
      {
          "time_stamp_utc_ms": 1735878060000,
          "time": "2025-01-03T04:23:00Z",
          "energy": 42.816000000573695,
          "capacity": 513.7920000068843
      },
      {
          "time_stamp_utc_ms": 1735878360000,
          "time": "2025-01-03T04:28:00Z",
          "energy": 43.007999998517334,
          "capacity": 516.095999982208
      },
      {
          "time_stamp_utc_ms": 1735878660000,
          "time": "2025-01-03T04:33:00Z",
          "energy": 42.432000000961125,
          "capacity": 509.1840000115335
      },
      {
          "time_stamp_utc_ms": 1735878960000,
          "time": "2025-01-03T04:38:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1735879260000,
          "time": "2025-01-03T04:43:00Z",
          "energy": 43.0080000013113,
          "capacity": 516.0960000157356
      },
      {
          "time_stamp_utc_ms": 1735879560000,
          "time": "2025-01-03T04:48:00Z",
          "energy": 40.79999999888241,
          "capacity": 489.59999998658895
      },
      {
          "time_stamp_utc_ms": 1735879860000,
          "time": "2025-01-03T04:53:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1735880160000,
          "time": "2025-01-03T04:58:00Z",
          "energy": 42.04800000041723,
          "capacity": 504.57600000500685
      },
      {
          "time_stamp_utc_ms": 1735880460000,
          "time": "2025-01-03T05:03:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1735880760000,
          "time": "2025-01-03T05:08:00Z",
          "energy": 42.720000001601875,
          "capacity": 512.6400000192225
      },
      {
          "time_stamp_utc_ms": 1735881060000,
          "time": "2025-01-03T05:13:00Z",
          "energy": 42.14399999845773,
          "capacity": 505.7279999814928
      },
      {
          "time_stamp_utc_ms": 1735881360000,
          "time": "2025-01-03T05:18:00Z",
          "energy": 41.85600000154227,
          "capacity": 502.27200001850724
      },
      {
          "time_stamp_utc_ms": 1735881660000,
          "time": "2025-01-03T05:23:00Z",
          "energy": 42.52799999900162,
          "capacity": 510.33599998801947
      },
      {
          "time_stamp_utc_ms": 1735881960000,
          "time": "2025-01-03T05:28:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1735882260000,
          "time": "2025-01-03T05:33:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1735882560000,
          "time": "2025-01-03T05:38:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1735882860000,
          "time": "2025-01-03T05:43:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1735883160000,
          "time": "2025-01-03T05:48:00Z",
          "energy": 43.104000001214445,
          "capacity": 517.2480000145733
      },
      {
          "time_stamp_utc_ms": 1735883460000,
          "time": "2025-01-03T05:53:00Z",
          "energy": 41.47199999913573,
          "capacity": 497.66399998962885
      },
      {
          "time_stamp_utc_ms": 1735883760000,
          "time": "2025-01-03T05:58:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1735884060000,
          "time": "2025-01-03T06:03:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1735884360000,
          "time": "2025-01-03T06:08:00Z",
          "energy": 42.14399999938905,
          "capacity": 505.72799999266863
      },
      {
          "time_stamp_utc_ms": 1735884660000,
          "time": "2025-01-03T06:13:00Z",
          "energy": 42.72000000067055,
          "capacity": 512.6400000080466
      },
      {
          "time_stamp_utc_ms": 1735884960000,
          "time": "2025-01-03T06:18:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1735885260000,
          "time": "2025-01-03T06:23:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1735885560000,
          "time": "2025-01-03T06:28:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1735885860000,
          "time": "2025-01-03T06:33:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1735886160000,
          "time": "2025-01-03T06:38:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1735886460000,
          "time": "2025-01-03T06:43:00Z",
          "energy": 42.239999999292195,
          "capacity": 506.87999999150634
      },
      {
          "time_stamp_utc_ms": 1735886760000,
          "time": "2025-01-03T06:48:00Z",
          "energy": 42.33600000105798,
          "capacity": 508.0320000126958
      },
      {
          "time_stamp_utc_ms": 1735887060000,
          "time": "2025-01-03T06:53:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1735887360000,
          "time": "2025-01-03T06:58:00Z",
          "energy": 42.62400000076741,
          "capacity": 511.4880000092089
      },
      {
          "time_stamp_utc_ms": 1735887660000,
          "time": "2025-01-03T07:03:00Z",
          "energy": 43.679999998770654,
          "capacity": 524.1599999852479
      },
      {
          "time_stamp_utc_ms": 1735887960000,
          "time": "2025-01-03T07:08:00Z",
          "energy": 44.35200000088662,
          "capacity": 532.2240000106395
      },
      {
          "time_stamp_utc_ms": 1735888260000,
          "time": "2025-01-03T07:13:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1735888560000,
          "time": "2025-01-03T07:18:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1735888860000,
          "time": "2025-01-03T07:23:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1735889160000,
          "time": "2025-01-03T07:28:00Z",
          "energy": 41.472000000067055,
          "capacity": 497.66400000080466
      },
      {
          "time_stamp_utc_ms": 1735889460000,
          "time": "2025-01-03T07:33:00Z",
          "energy": 43.0080000013113,
          "capacity": 516.0960000157356
      },
      {
          "time_stamp_utc_ms": 1735889760000,
          "time": "2025-01-03T07:38:00Z",
          "energy": 41.8559999987483,
          "capacity": 502.2719999849797
      },
      {
          "time_stamp_utc_ms": 1735890060000,
          "time": "2025-01-03T07:43:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1735890360000,
          "time": "2025-01-03T07:48:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1735890660000,
          "time": "2025-01-03T07:53:00Z",
          "energy": 41.47199999913573,
          "capacity": 497.66399998962885
      },
      {
          "time_stamp_utc_ms": 1735890960000,
          "time": "2025-01-03T07:58:00Z",
          "energy": 41.28000000026077,
          "capacity": 495.36000000312924
      },
      {
          "time_stamp_utc_ms": 1735891260000,
          "time": "2025-01-03T08:03:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1735891560000,
          "time": "2025-01-03T08:08:00Z",
          "energy": 41.28000000026077,
          "capacity": 495.36000000312924
      },
      {
          "time_stamp_utc_ms": 1735891860000,
          "time": "2025-01-03T08:13:00Z",
          "energy": 42.04800000041723,
          "capacity": 504.57600000500685
      },
      {
          "time_stamp_utc_ms": 1735892160000,
          "time": "2025-01-03T08:18:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1735892460000,
          "time": "2025-01-03T08:23:00Z",
          "energy": 41.472000000067055,
          "capacity": 497.66400000080466
      },
      {
          "time_stamp_utc_ms": 1735892760000,
          "time": "2025-01-03T08:28:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1735893060000,
          "time": "2025-01-03T08:33:00Z",
          "energy": 42.43199999909848,
          "capacity": 509.18399998918176
      },
      {
          "time_stamp_utc_ms": 1735893360000,
          "time": "2025-01-03T08:38:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1735893660000,
          "time": "2025-01-03T08:43:00Z",
          "energy": 42.62400000076741,
          "capacity": 511.4880000092089
      },
      {
          "time_stamp_utc_ms": 1735893960000,
          "time": "2025-01-03T08:48:00Z",
          "energy": 42.239999999292195,
          "capacity": 506.87999999150634
      },
      {
          "time_stamp_utc_ms": 1735894260000,
          "time": "2025-01-03T08:53:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1735894560000,
          "time": "2025-01-03T08:58:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1735894860000,
          "time": "2025-01-03T09:03:00Z",
          "energy": 40.99200000055134,
          "capacity": 491.9040000066161
      },
      {
          "time_stamp_utc_ms": 1735895160000,
          "time": "2025-01-03T09:08:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1735895460000,
          "time": "2025-01-03T09:13:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735895760000,
          "time": "2025-01-03T09:18:00Z",
          "energy": 42.52800000086427,
          "capacity": 510.3360000103712
      },
      {
          "time_stamp_utc_ms": 1735896060000,
          "time": "2025-01-03T09:23:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1735896360000,
          "time": "2025-01-03T09:28:00Z",
          "energy": 41.95200000051409,
          "capacity": 503.42400000616914
      },
      {
          "time_stamp_utc_ms": 1735896660000,
          "time": "2025-01-03T09:33:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1735896960000,
          "time": "2025-01-03T09:38:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1735897260000,
          "time": "2025-01-03T09:43:00Z",
          "energy": 41.376000001095235,
          "capacity": 496.5120000131429
      },
      {
          "time_stamp_utc_ms": 1735897560000,
          "time": "2025-01-03T09:48:00Z",
          "energy": 43.39199999906123,
          "capacity": 520.7039999887347
      },
      {
          "time_stamp_utc_ms": 1735897860000,
          "time": "2025-01-03T09:53:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735898160000,
          "time": "2025-01-03T09:58:00Z",
          "energy": 45.8879999993369,
          "capacity": 550.6559999920429
      },
      {
          "time_stamp_utc_ms": 1735898460000,
          "time": "2025-01-03T10:03:00Z",
          "energy": 46.84800000023097,
          "capacity": 562.1760000027716
      },
      {
          "time_stamp_utc_ms": 1735898760000,
          "time": "2025-01-03T10:08:00Z",
          "energy": 47.13600000087172,
          "capacity": 565.6320000104606
      },
      {
          "time_stamp_utc_ms": 1735899060000,
          "time": "2025-01-03T10:13:00Z",
          "energy": 47.61599999945611,
          "capacity": 571.3919999934733
      },
      {
          "time_stamp_utc_ms": 1735899360000,
          "time": "2025-01-03T10:18:00Z",
          "energy": 48.0,
          "capacity": 576.0000000000001
      },
      {
          "time_stamp_utc_ms": 1735899660000,
          "time": "2025-01-03T10:23:00Z",
          "energy": 47.61599999945611,
          "capacity": 571.3919999934733
      },
      {
          "time_stamp_utc_ms": 1735899960000,
          "time": "2025-01-03T10:28:00Z",
          "energy": 48.191999999806285,
          "capacity": 578.3039999976755
      },
      {
          "time_stamp_utc_ms": 1735900260000,
          "time": "2025-01-03T10:33:00Z",
          "energy": 48.096000000834465,
          "capacity": 577.1520000100136
      },
      {
          "time_stamp_utc_ms": 1735900560000,
          "time": "2025-01-03T10:38:00Z",
          "energy": 48.0,
          "capacity": 576.0000000000001
      },
      {
          "time_stamp_utc_ms": 1735900860000,
          "time": "2025-01-03T10:43:00Z",
          "energy": 49.44000000040978,
          "capacity": 593.2800000049175
      },
      {
          "time_stamp_utc_ms": 1735901160000,
          "time": "2025-01-03T10:48:00Z",
          "energy": 48.19199999887496,
          "capacity": 578.3039999864995
      },
      {
          "time_stamp_utc_ms": 1735901460000,
          "time": "2025-01-03T10:53:00Z",
          "energy": 50.40000000037253,
          "capacity": 604.8000000044703
      },
      {
          "time_stamp_utc_ms": 1735901760000,
          "time": "2025-01-03T10:58:00Z",
          "energy": 48.09599999990314,
          "capacity": 577.1519999988378
      },
      {
          "time_stamp_utc_ms": 1735902060000,
          "time": "2025-01-03T11:03:00Z",
          "energy": 49.34399999957532,
          "capacity": 592.1279999949038
      },
      {
          "time_stamp_utc_ms": 1735902360000,
          "time": "2025-01-03T11:08:00Z",
          "energy": 48.768000001087785,
          "capacity": 585.2160000130535
      },
      {
          "time_stamp_utc_ms": 1735902660000,
          "time": "2025-01-03T11:13:00Z",
          "energy": 48.95999999996275,
          "capacity": 587.519999999553
      },
      {
          "time_stamp_utc_ms": 1735902960000,
          "time": "2025-01-03T11:18:00Z",
          "energy": 49.05599999986589,
          "capacity": 588.6719999983907
      },
      {
          "time_stamp_utc_ms": 1735903260000,
          "time": "2025-01-03T11:23:00Z",
          "energy": 49.72800000011921,
          "capacity": 596.7360000014306
      },
      {
          "time_stamp_utc_ms": 1735903560000,
          "time": "2025-01-03T11:28:00Z",
          "energy": 48.95999999996275,
          "capacity": 587.519999999553
      },
      {
          "time_stamp_utc_ms": 1735903860000,
          "time": "2025-01-03T11:33:00Z",
          "energy": 50.49599999934435,
          "capacity": 605.9519999921323
      },
      {
          "time_stamp_utc_ms": 1735904160000,
          "time": "2025-01-03T11:38:00Z",
          "energy": 48.96000000089407,
          "capacity": 587.520000010729
      },
      {
          "time_stamp_utc_ms": 1735904460000,
          "time": "2025-01-03T11:43:00Z",
          "energy": 51.16799999959767,
          "capacity": 614.015999995172
      },
      {
          "time_stamp_utc_ms": 1735904760000,
          "time": "2025-01-03T11:48:00Z",
          "energy": 50.87999999988824,
          "capacity": 610.5599999986589
      },
      {
          "time_stamp_utc_ms": 1735905060000,
          "time": "2025-01-03T11:53:00Z",
          "energy": 49.82400000002235,
          "capacity": 597.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735905360000,
          "time": "2025-01-03T11:58:00Z",
          "energy": 50.40000000037253,
          "capacity": 604.8000000044703
      },
      {
          "time_stamp_utc_ms": 1735905660000,
          "time": "2025-01-03T12:03:00Z",
          "energy": 50.49599999934435,
          "capacity": 605.9519999921323
      },
      {
          "time_stamp_utc_ms": 1735905960000,
          "time": "2025-01-03T12:08:00Z",
          "energy": 50.208000000566244,
          "capacity": 602.4960000067949
      },
      {
          "time_stamp_utc_ms": 1735906260000,
          "time": "2025-01-03T12:13:00Z",
          "energy": 50.687999999150634,
          "capacity": 608.2559999898077
      },
      {
          "time_stamp_utc_ms": 1735906560000,
          "time": "2025-01-03T12:18:00Z",
          "energy": 51.07200000062585,
          "capacity": 612.8640000075102
      },
      {
          "time_stamp_utc_ms": 1735906860000,
          "time": "2025-01-03T12:23:00Z",
          "energy": 51.74399999901652,
          "capacity": 620.9279999881983
      },
      {
          "time_stamp_utc_ms": 1735907160000,
          "time": "2025-01-03T12:28:00Z",
          "energy": 51.74400000087917,
          "capacity": 620.9280000105501
      },
      {
          "time_stamp_utc_ms": 1735907460000,
          "time": "2025-01-03T12:33:00Z",
          "energy": 52.3199999993667,
          "capacity": 627.8399999924004
      },
      {
          "time_stamp_utc_ms": 1735907760000,
          "time": "2025-01-03T12:38:00Z",
          "energy": 54.144000000320375,
          "capacity": 649.7280000038446
      },
      {
          "time_stamp_utc_ms": 1735908060000,
          "time": "2025-01-03T12:43:00Z",
          "energy": 51.83999999985099,
          "capacity": 622.0799999982119
      },
      {
          "time_stamp_utc_ms": 1735908360000,
          "time": "2025-01-03T12:48:00Z",
          "energy": 52.032000000588596,
          "capacity": 624.3840000070633
      },
      {
          "time_stamp_utc_ms": 1735908660000,
          "time": "2025-01-03T12:53:00Z",
          "energy": 51.55200000014156,
          "capacity": 618.6240000016987
      },
      {
          "time_stamp_utc_ms": 1735908960000,
          "time": "2025-01-03T12:58:00Z",
          "energy": 51.16799999959767,
          "capacity": 614.015999995172
      },
      {
          "time_stamp_utc_ms": 1735909260000,
          "time": "2025-01-03T13:03:00Z",
          "energy": 51.55200000014156,
          "capacity": 618.6240000016987
      },
      {
          "time_stamp_utc_ms": 1735909560000,
          "time": "2025-01-03T13:08:00Z",
          "energy": 51.743999999947846,
          "capacity": 620.9279999993742
      },
      {
          "time_stamp_utc_ms": 1735909860000,
          "time": "2025-01-03T13:13:00Z",
          "energy": 50.11199999973178,
          "capacity": 601.3439999967815
      },
      {
          "time_stamp_utc_ms": 1735910160000,
          "time": "2025-01-03T13:18:00Z",
          "energy": 51.93600000068545,
          "capacity": 623.2320000082256
      },
      {
          "time_stamp_utc_ms": 1735910460000,
          "time": "2025-01-03T13:23:00Z",
          "energy": 51.74399999901652,
          "capacity": 620.9279999881983
      },
      {
          "time_stamp_utc_ms": 1735910760000,
          "time": "2025-01-03T13:28:00Z",
          "energy": 51.648000000976026,
          "capacity": 619.7760000117124
      },
      {
          "time_stamp_utc_ms": 1735911060000,
          "time": "2025-01-03T13:33:00Z",
          "energy": 51.743999999947846,
          "capacity": 620.9279999993742
      },
      {
          "time_stamp_utc_ms": 1735911360000,
          "time": "2025-01-03T13:38:00Z",
          "energy": 52.127999999560416,
          "capacity": 625.535999994725
      },
      {
          "time_stamp_utc_ms": 1735911660000,
          "time": "2025-01-03T13:43:00Z",
          "energy": 52.12800000049174,
          "capacity": 625.536000005901
      },
      {
          "time_stamp_utc_ms": 1735911960000,
          "time": "2025-01-03T13:48:00Z",
          "energy": 52.511999999172986,
          "capacity": 630.1439999900758
      },
      {
          "time_stamp_utc_ms": 1735912260000,
          "time": "2025-01-03T13:53:00Z",
          "energy": 51.648000000976026,
          "capacity": 619.7760000117124
      },
      {
          "time_stamp_utc_ms": 1735912560000,
          "time": "2025-01-03T13:58:00Z",
          "energy": 51.55199999921024,
          "capacity": 618.6239999905229
      },
      {
          "time_stamp_utc_ms": 1735912860000,
          "time": "2025-01-03T14:03:00Z",
          "energy": 49.82400000002235,
          "capacity": 597.8880000002683
      },
      {
          "time_stamp_utc_ms": 1735913160000,
          "time": "2025-01-03T14:08:00Z",
          "energy": 51.35999999940395,
          "capacity": 616.3199999928474
      },
      {
          "time_stamp_utc_ms": 1735913460000,
          "time": "2025-01-03T14:13:00Z",
          "energy": 53.5679999999702,
          "capacity": 642.8159999996424
      },
      {
          "time_stamp_utc_ms": 1735913760000,
          "time": "2025-01-03T14:18:00Z",
          "energy": 51.3600000012666,
          "capacity": 616.3200000151993
      },
      {
          "time_stamp_utc_ms": 1735914060000,
          "time": "2025-01-03T14:23:00Z",
          "energy": 49.43999999947846,
          "capacity": 593.2799999937415
      },
      {
          "time_stamp_utc_ms": 1735914360000,
          "time": "2025-01-03T14:28:00Z",
          "energy": 48.38400000054389,
          "capacity": 580.6080000065267
      },
      {
          "time_stamp_utc_ms": 1735914660000,
          "time": "2025-01-03T14:33:00Z",
          "energy": 48.575999999418855,
          "capacity": 582.9119999930264
      },
      {
          "time_stamp_utc_ms": 1735914960000,
          "time": "2025-01-03T14:38:00Z",
          "energy": 48.864000000059605,
          "capacity": 586.3680000007153
      },
      {
          "time_stamp_utc_ms": 1735915260000,
          "time": "2025-01-03T14:43:00Z",
          "energy": 48.191999999806285,
          "capacity": 578.3039999976755
      },
      {
          "time_stamp_utc_ms": 1735915560000,
          "time": "2025-01-03T14:48:00Z",
          "energy": 48.47999999951571,
          "capacity": 581.7599999941887
      },
      {
          "time_stamp_utc_ms": 1735915860000,
          "time": "2025-01-03T14:53:00Z",
          "energy": 48.0,
          "capacity": 576.0000000000001
      },
      {
          "time_stamp_utc_ms": 1735916160000,
          "time": "2025-01-03T14:58:00Z",
          "energy": 47.135999999940395,
          "capacity": 565.6319999992847
      },
      {
          "time_stamp_utc_ms": 1735916460000,
          "time": "2025-01-03T15:03:00Z",
          "energy": 48.0,
          "capacity": 576.0000000000001
      },
      {
          "time_stamp_utc_ms": 1735916760000,
          "time": "2025-01-03T15:08:00Z",
          "energy": 47.61600000131875,
          "capacity": 571.392000015825
      },
      {
          "time_stamp_utc_ms": 1735917060000,
          "time": "2025-01-03T15:13:00Z",
          "energy": 46.55999999959022,
          "capacity": 558.7199999950826
      },
      {
          "time_stamp_utc_ms": 1735917360000,
          "time": "2025-01-03T15:18:00Z",
          "energy": 47.61600000038743,
          "capacity": 571.3920000046492
      },
      {
          "time_stamp_utc_ms": 1735917660000,
          "time": "2025-01-03T15:23:00Z",
          "energy": 46.463999999687076,
          "capacity": 557.5679999962449
      },
      {
          "time_stamp_utc_ms": 1735917960000,
          "time": "2025-01-03T15:28:00Z",
          "energy": 47.135999999940395,
          "capacity": 565.6319999992847
      },
      {
          "time_stamp_utc_ms": 1735918260000,
          "time": "2025-01-03T15:33:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1735918560000,
          "time": "2025-01-03T15:38:00Z",
          "energy": 45.79200000036508,
          "capacity": 549.5040000043809
      },
      {
          "time_stamp_utc_ms": 1735918860000,
          "time": "2025-01-03T15:43:00Z",
          "energy": 46.65599999949336,
          "capacity": 559.8719999939203
      },
      {
          "time_stamp_utc_ms": 1735919160000,
          "time": "2025-01-03T15:48:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1735919460000,
          "time": "2025-01-03T15:53:00Z",
          "energy": 44.15999999921769,
          "capacity": 529.9199999906123
      },
      {
          "time_stamp_utc_ms": 1735919760000,
          "time": "2025-01-03T15:58:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1735920060000,
          "time": "2025-01-03T16:03:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735920360000,
          "time": "2025-01-03T16:08:00Z",
          "energy": 45.023999999277294,
          "capacity": 540.2879999913275
      },
      {
          "time_stamp_utc_ms": 1735920660000,
          "time": "2025-01-03T16:13:00Z",
          "energy": 44.544000000692904,
          "capacity": 534.528000008315
      },
      {
          "time_stamp_utc_ms": 1735920960000,
          "time": "2025-01-03T16:18:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1735921260000,
          "time": "2025-01-03T16:23:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735921560000,
          "time": "2025-01-03T16:28:00Z",
          "energy": 42.24000000022352,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1735921860000,
          "time": "2025-01-03T16:33:00Z",
          "energy": 42.62399999983609,
          "capacity": 511.4879999980331
      },
      {
          "time_stamp_utc_ms": 1735922160000,
          "time": "2025-01-03T16:38:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1735922460000,
          "time": "2025-01-03T16:43:00Z",
          "energy": 42.62400000076741,
          "capacity": 511.4880000092089
      },
      {
          "time_stamp_utc_ms": 1735922760000,
          "time": "2025-01-03T16:48:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1735923060000,
          "time": "2025-01-03T16:53:00Z",
          "energy": 43.39199999906123,
          "capacity": 520.7039999887347
      },
      {
          "time_stamp_utc_ms": 1735923360000,
          "time": "2025-01-03T16:58:00Z",
          "energy": 42.816000000573695,
          "capacity": 513.7920000068843
      },
      {
          "time_stamp_utc_ms": 1735923660000,
          "time": "2025-01-03T17:03:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1735923960000,
          "time": "2025-01-03T17:08:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1735924260000,
          "time": "2025-01-03T17:13:00Z",
          "energy": 42.62399999983609,
          "capacity": 511.4879999980331
      },
      {
          "time_stamp_utc_ms": 1735924560000,
          "time": "2025-01-03T17:18:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1735924860000,
          "time": "2025-01-03T17:23:00Z",
          "energy": 42.24000000115484,
          "capacity": 506.8800000138581
      },
      {
          "time_stamp_utc_ms": 1735925160000,
          "time": "2025-01-03T17:28:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1735925460000,
          "time": "2025-01-03T17:33:00Z",
          "energy": 41.56800000090152,
          "capacity": 498.8160000108183
      },
      {
          "time_stamp_utc_ms": 1735925760000,
          "time": "2025-01-03T17:38:00Z",
          "energy": 41.66399999894202,
          "capacity": 499.96799998730427
      },
      {
          "time_stamp_utc_ms": 1735926060000,
          "time": "2025-01-03T17:43:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1735926360000,
          "time": "2025-01-03T17:48:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1735926660000,
          "time": "2025-01-03T17:53:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1735926960000,
          "time": "2025-01-03T17:58:00Z",
          "energy": 40.51200000010431,
          "capacity": 486.14400000125175
      },
      {
          "time_stamp_utc_ms": 1735927260000,
          "time": "2025-01-03T18:03:00Z",
          "energy": 39.93599999882281,
          "capacity": 479.23199998587376
      },
      {
          "time_stamp_utc_ms": 1735927560000,
          "time": "2025-01-03T18:08:00Z",
          "energy": 39.552000001072884,
          "capacity": 474.6240000128746
      },
      {
          "time_stamp_utc_ms": 1735927860000,
          "time": "2025-01-03T18:13:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1735928160000,
          "time": "2025-01-03T18:18:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735928460000,
          "time": "2025-01-03T18:23:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1735928760000,
          "time": "2025-01-03T18:28:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1735929060000,
          "time": "2025-01-03T18:33:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1735929360000,
          "time": "2025-01-03T18:38:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1735929660000,
          "time": "2025-01-03T18:43:00Z",
          "energy": 38.11199999973178,
          "capacity": 457.3439999967814
      },
      {
          "time_stamp_utc_ms": 1735929960000,
          "time": "2025-01-03T18:48:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1735930260000,
          "time": "2025-01-03T18:53:00Z",
          "energy": 37.63200000114739,
          "capacity": 451.58400001376873
      },
      {
          "time_stamp_utc_ms": 1735930560000,
          "time": "2025-01-03T18:58:00Z",
          "energy": 40.03199999872595,
          "capacity": 480.3839999847114
      },
      {
          "time_stamp_utc_ms": 1735930860000,
          "time": "2025-01-03T19:03:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1735931160000,
          "time": "2025-01-03T19:08:00Z",
          "energy": 39.264000001363456,
          "capacity": 471.1680000163615
      },
      {
          "time_stamp_utc_ms": 1735931460000,
          "time": "2025-01-03T19:13:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1735931760000,
          "time": "2025-01-03T19:18:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1735932060000,
          "time": "2025-01-03T19:23:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1735932360000,
          "time": "2025-01-03T19:28:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1735932660000,
          "time": "2025-01-03T19:33:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1735932960000,
          "time": "2025-01-03T19:38:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1735933260000,
          "time": "2025-01-03T19:43:00Z",
          "energy": 38.783999999053776,
          "capacity": 465.4079999886454
      },
      {
          "time_stamp_utc_ms": 1735933560000,
          "time": "2025-01-03T19:48:00Z",
          "energy": 40.8960000006482,
          "capacity": 490.7520000077784
      },
      {
          "time_stamp_utc_ms": 1735933860000,
          "time": "2025-01-03T19:53:00Z",
          "energy": 40.9919999986887,
          "capacity": 491.9039999842644
      },
      {
          "time_stamp_utc_ms": 1735934160000,
          "time": "2025-01-03T19:58:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1735934460000,
          "time": "2025-01-03T20:03:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1735934760000,
          "time": "2025-01-03T20:08:00Z",
          "energy": 41.088000000454485,
          "capacity": 493.0560000054538
      },
      {
          "time_stamp_utc_ms": 1735935060000,
          "time": "2025-01-03T20:13:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1735935360000,
          "time": "2025-01-03T20:18:00Z",
          "energy": 44.160000001080334,
          "capacity": 529.9200000129641
      },
      {
          "time_stamp_utc_ms": 1735935660000,
          "time": "2025-01-03T20:23:00Z",
          "energy": 44.15999999921769,
          "capacity": 529.9199999906123
      },
      {
          "time_stamp_utc_ms": 1735935960000,
          "time": "2025-01-03T20:28:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735936260000,
          "time": "2025-01-03T20:33:00Z",
          "energy": 43.6800000006333,
          "capacity": 524.1600000075996
      },
      {
          "time_stamp_utc_ms": 1735936560000,
          "time": "2025-01-03T20:38:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1735936860000,
          "time": "2025-01-03T20:43:00Z",
          "energy": 44.25600000098348,
          "capacity": 531.0720000118018
      },
      {
          "time_stamp_utc_ms": 1735937160000,
          "time": "2025-01-03T20:48:00Z",
          "energy": 43.87199999857694,
          "capacity": 526.4639999829233
      },
      {
          "time_stamp_utc_ms": 1735937460000,
          "time": "2025-01-03T20:53:00Z",
          "energy": 45.12000000104308,
          "capacity": 541.440000012517
      },
      {
          "time_stamp_utc_ms": 1735937760000,
          "time": "2025-01-03T20:58:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1735938060000,
          "time": "2025-01-03T21:03:00Z",
          "energy": 45.791999999433756,
          "capacity": 549.5039999932052
      },
      {
          "time_stamp_utc_ms": 1735938360000,
          "time": "2025-01-03T21:08:00Z",
          "energy": 46.752000000327826,
          "capacity": 561.0240000039339
      },
      {
          "time_stamp_utc_ms": 1735938660000,
          "time": "2025-01-03T21:13:00Z",
          "energy": 46.65600000042468,
          "capacity": 559.8720000050962
      },
      {
          "time_stamp_utc_ms": 1735938960000,
          "time": "2025-01-03T21:18:00Z",
          "energy": 46.94399999920279,
          "capacity": 563.3279999904335
      },
      {
          "time_stamp_utc_ms": 1735939260000,
          "time": "2025-01-03T21:23:00Z",
          "energy": 47.328000000678,
          "capacity": 567.936000008136
      },
      {
          "time_stamp_utc_ms": 1735939560000,
          "time": "2025-01-03T21:28:00Z",
          "energy": 47.519999999552965,
          "capacity": 570.2399999946356
      },
      {
          "time_stamp_utc_ms": 1735939860000,
          "time": "2025-01-03T21:33:00Z",
          "energy": 48.28799999970943,
          "capacity": 579.4559999965132
      },
      {
          "time_stamp_utc_ms": 1735940160000,
          "time": "2025-01-03T21:38:00Z",
          "energy": 47.424000000581145,
          "capacity": 569.0880000069737
      },
      {
          "time_stamp_utc_ms": 1735940460000,
          "time": "2025-01-03T21:43:00Z",
          "energy": 47.519999999552965,
          "capacity": 570.2399999946356
      },
      {
          "time_stamp_utc_ms": 1735940760000,
          "time": "2025-01-03T21:48:00Z",
          "energy": 46.65600000042468,
          "capacity": 559.8720000050962
      },
      {
          "time_stamp_utc_ms": 1735941060000,
          "time": "2025-01-03T21:53:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1735941360000,
          "time": "2025-01-03T21:58:00Z",
          "energy": 46.94399999920279,
          "capacity": 563.3279999904335
      },
      {
          "time_stamp_utc_ms": 1735941660000,
          "time": "2025-01-03T22:03:00Z",
          "energy": 47.135999999940395,
          "capacity": 565.6319999992847
      },
      {
          "time_stamp_utc_ms": 1735941960000,
          "time": "2025-01-03T22:08:00Z",
          "energy": 47.61600000131875,
          "capacity": 571.392000015825
      },
      {
          "time_stamp_utc_ms": 1735942260000,
          "time": "2025-01-03T22:13:00Z",
          "energy": 48.191999999806285,
          "capacity": 578.3039999976755
      },
      {
          "time_stamp_utc_ms": 1735942560000,
          "time": "2025-01-03T22:18:00Z",
          "energy": 48.28799999970943,
          "capacity": 579.4559999965132
      },
      {
          "time_stamp_utc_ms": 1735942860000,
          "time": "2025-01-03T22:23:00Z",
          "energy": 48.57600000035018,
          "capacity": 582.9120000042021
      },
      {
          "time_stamp_utc_ms": 1735943160000,
          "time": "2025-01-03T22:28:00Z",
          "energy": 47.99999999906868,
          "capacity": 575.9999999888241
      },
      {
          "time_stamp_utc_ms": 1735943460000,
          "time": "2025-01-03T22:33:00Z",
          "energy": 47.04000000003725,
          "capacity": 564.480000000447
      },
      {
          "time_stamp_utc_ms": 1735943760000,
          "time": "2025-01-03T22:38:00Z",
          "energy": 48.768000001087785,
          "capacity": 585.2160000130535
      },
      {
          "time_stamp_utc_ms": 1735944060000,
          "time": "2025-01-03T22:43:00Z",
          "energy": 47.615999998524785,
          "capacity": 571.3919999822974
      },
      {
          "time_stamp_utc_ms": 1735944360000,
          "time": "2025-01-03T22:48:00Z",
          "energy": 50.01600000075996,
          "capacity": 600.1920000091195
      },
      {
          "time_stamp_utc_ms": 1735944660000,
          "time": "2025-01-03T22:53:00Z",
          "energy": 49.63200000114739,
          "capacity": 595.5840000137687
      },
      {
          "time_stamp_utc_ms": 1735944960000,
          "time": "2025-01-03T22:58:00Z",
          "energy": 50.015999998897314,
          "capacity": 600.1919999867678
      },
      {
          "time_stamp_utc_ms": 1735945260000,
          "time": "2025-01-03T23:03:00Z",
          "energy": 49.631999999284744,
          "capacity": 595.5839999914169
      },
      {
          "time_stamp_utc_ms": 1735945560000,
          "time": "2025-01-03T23:08:00Z",
          "energy": 50.592000000178814,
          "capacity": 607.1040000021458
      },
      {
          "time_stamp_utc_ms": 1735945860000,
          "time": "2025-01-03T23:13:00Z",
          "energy": 48.480000000447035,
          "capacity": 581.7600000053644
      },
      {
          "time_stamp_utc_ms": 1735946160000,
          "time": "2025-01-03T23:18:00Z",
          "energy": 49.34399999957532,
          "capacity": 592.1279999949038
      },
      {
          "time_stamp_utc_ms": 1735946460000,
          "time": "2025-01-03T23:23:00Z",
          "energy": 49.34400000050664,
          "capacity": 592.1280000060798
      },
      {
          "time_stamp_utc_ms": 1735946760000,
          "time": "2025-01-03T23:28:00Z",
          "energy": 49.72800000105053,
          "capacity": 596.7360000126064
      },
      {
          "time_stamp_utc_ms": 1735947060000,
          "time": "2025-01-03T23:33:00Z",
          "energy": 50.59199999924749,
          "capacity": 607.10399999097
      },
      {
          "time_stamp_utc_ms": 1735947360000,
          "time": "2025-01-03T23:38:00Z",
          "energy": 48.76800000015646,
          "capacity": 585.2160000018775
      },
      {
          "time_stamp_utc_ms": 1735947660000,
          "time": "2025-01-03T23:43:00Z",
          "energy": 49.15199999883771,
          "capacity": 589.8239999860526
      },
      {
          "time_stamp_utc_ms": 1735947960000,
          "time": "2025-01-03T23:48:00Z",
          "energy": 49.53600000124425,
          "capacity": 594.432000014931
      },
      {
          "time_stamp_utc_ms": 1735948260000,
          "time": "2025-01-03T23:53:00Z",
          "energy": 48.959999999031425,
          "capacity": 587.5199999883772
      },
      {
          "time_stamp_utc_ms": 1735948560000,
          "time": "2025-01-03T23:58:00Z",
          "energy": 49.34400000050664,
          "capacity": 592.1280000060798
      },
      {
          "time_stamp_utc_ms": 1735948860000,
          "time": "2025-01-04T00:03:00Z",
          "energy": 48.76799999922514,
          "capacity": 585.2159999907018
      },
      {
          "time_stamp_utc_ms": 1735949160000,
          "time": "2025-01-04T00:08:00Z",
          "energy": 48.480000000447035,
          "capacity": 581.7600000053644
      },
      {
          "time_stamp_utc_ms": 1735949460000,
          "time": "2025-01-04T00:13:00Z",
          "energy": 49.44000000040978,
          "capacity": 593.2800000049175
      },
      {
          "time_stamp_utc_ms": 1735949760000,
          "time": "2025-01-04T00:18:00Z",
          "energy": 49.15199999976903,
          "capacity": 589.8239999972284
      },
      {
          "time_stamp_utc_ms": 1735950060000,
          "time": "2025-01-04T00:23:00Z",
          "energy": 49.63200000021607,
          "capacity": 595.5840000025929
      },
      {
          "time_stamp_utc_ms": 1735950360000,
          "time": "2025-01-04T00:28:00Z",
          "energy": 48.38399999961257,
          "capacity": 580.607999995351
      },
      {
          "time_stamp_utc_ms": 1735950660000,
          "time": "2025-01-04T00:33:00Z",
          "energy": 48.575999999418855,
          "capacity": 582.9119999930264
      },
      {
          "time_stamp_utc_ms": 1735950960000,
          "time": "2025-01-04T00:38:00Z",
          "energy": 48.67200000118464,
          "capacity": 584.0640000142158
      },
      {
          "time_stamp_utc_ms": 1735951260000,
          "time": "2025-01-04T00:43:00Z",
          "energy": 49.05599999986589,
          "capacity": 588.6719999983907
      },
      {
          "time_stamp_utc_ms": 1735951560000,
          "time": "2025-01-04T00:48:00Z",
          "energy": 48.287999998778105,
          "capacity": 579.4559999853373
      },
      {
          "time_stamp_utc_ms": 1735951860000,
          "time": "2025-01-04T00:53:00Z",
          "energy": 50.208000000566244,
          "capacity": 602.4960000067949
      },
      {
          "time_stamp_utc_ms": 1735952160000,
          "time": "2025-01-04T00:58:00Z",
          "energy": 47.328000000678,
          "capacity": 567.936000008136
      },
      {
          "time_stamp_utc_ms": 1735952460000,
          "time": "2025-01-04T01:03:00Z",
          "energy": 47.71199999935925,
          "capacity": 572.543999992311
      },
      {
          "time_stamp_utc_ms": 1735952760000,
          "time": "2025-01-04T01:08:00Z",
          "energy": 47.32799999974668,
          "capacity": 567.9359999969602
      },
      {
          "time_stamp_utc_ms": 1735953060000,
          "time": "2025-01-04T01:13:00Z",
          "energy": 47.80800000112504,
          "capacity": 573.6960000135005
      },
      {
          "time_stamp_utc_ms": 1735953360000,
          "time": "2025-01-04T01:18:00Z",
          "energy": 46.175999999046326,
          "capacity": 554.111999988556
      },
      {
          "time_stamp_utc_ms": 1735953660000,
          "time": "2025-01-04T01:23:00Z",
          "energy": 46.27200000081211,
          "capacity": 555.2640000097454
      },
      {
          "time_stamp_utc_ms": 1735953960000,
          "time": "2025-01-04T01:28:00Z",
          "energy": 45.791999999433756,
          "capacity": 549.5039999932052
      },
      {
          "time_stamp_utc_ms": 1735954260000,
          "time": "2025-01-04T01:33:00Z",
          "energy": 47.23200000077486,
          "capacity": 566.7840000092983
      },
      {
          "time_stamp_utc_ms": 1735954560000,
          "time": "2025-01-04T01:38:00Z",
          "energy": 46.463999999687076,
          "capacity": 557.5679999962449
      },
      {
          "time_stamp_utc_ms": 1735954860000,
          "time": "2025-01-04T01:43:00Z",
          "energy": 47.13599999900907,
          "capacity": 565.6319999881089
      },
      {
          "time_stamp_utc_ms": 1735955160000,
          "time": "2025-01-04T01:48:00Z",
          "energy": 47.42400000151247,
          "capacity": 569.0880000181496
      },
      {
          "time_stamp_utc_ms": 1735955460000,
          "time": "2025-01-04T01:53:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1735955760000,
          "time": "2025-01-04T01:58:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735956060000,
          "time": "2025-01-04T02:03:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1735956360000,
          "time": "2025-01-04T02:08:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735956660000,
          "time": "2025-01-04T02:13:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1735956960000,
          "time": "2025-01-04T02:18:00Z",
          "energy": 43.58400000073016,
          "capacity": 523.0080000087619
      },
      {
          "time_stamp_utc_ms": 1735957260000,
          "time": "2025-01-04T02:23:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1735957560000,
          "time": "2025-01-04T02:28:00Z",
          "energy": 43.20000000111759,
          "capacity": 518.400000013411
      },
      {
          "time_stamp_utc_ms": 1735957860000,
          "time": "2025-01-04T02:33:00Z",
          "energy": 43.96799999848008,
          "capacity": 527.615999981761
      },
      {
          "time_stamp_utc_ms": 1735958160000,
          "time": "2025-01-04T02:38:00Z",
          "energy": 43.96800000127405,
          "capacity": 527.6160000152887
      },
      {
          "time_stamp_utc_ms": 1735958460000,
          "time": "2025-01-04T02:43:00Z",
          "energy": 43.58399999886751,
          "capacity": 523.0079999864101
      },
      {
          "time_stamp_utc_ms": 1735958760000,
          "time": "2025-01-04T02:48:00Z",
          "energy": 43.96800000127405,
          "capacity": 527.6160000152887
      },
      {
          "time_stamp_utc_ms": 1735959060000,
          "time": "2025-01-04T02:53:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735959360000,
          "time": "2025-01-04T02:58:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735959660000,
          "time": "2025-01-04T03:03:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735959960000,
          "time": "2025-01-04T03:08:00Z",
          "energy": 42.52799999900162,
          "capacity": 510.33599998801947
      },
      {
          "time_stamp_utc_ms": 1735960260000,
          "time": "2025-01-04T03:13:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735960560000,
          "time": "2025-01-04T03:18:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1735960860000,
          "time": "2025-01-04T03:23:00Z",
          "energy": 43.967999999411404,
          "capacity": 527.6159999929368
      },
      {
          "time_stamp_utc_ms": 1735961160000,
          "time": "2025-01-04T03:28:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735961460000,
          "time": "2025-01-04T03:33:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1735961760000,
          "time": "2025-01-04T03:38:00Z",
          "energy": 42.52800000086427,
          "capacity": 510.3360000103712
      },
      {
          "time_stamp_utc_ms": 1735962060000,
          "time": "2025-01-04T03:43:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1735962360000,
          "time": "2025-01-04T03:48:00Z",
          "energy": 43.967999999411404,
          "capacity": 527.6159999929368
      },
      {
          "time_stamp_utc_ms": 1735962660000,
          "time": "2025-01-04T03:53:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1735962960000,
          "time": "2025-01-04T03:58:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1735963260000,
          "time": "2025-01-04T04:03:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735963560000,
          "time": "2025-01-04T04:08:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735963860000,
          "time": "2025-01-04T04:13:00Z",
          "energy": 42.52799999900162,
          "capacity": 510.33599998801947
      },
      {
          "time_stamp_utc_ms": 1735964160000,
          "time": "2025-01-04T04:18:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1735964460000,
          "time": "2025-01-04T04:23:00Z",
          "energy": 43.104000001214445,
          "capacity": 517.2480000145733
      },
      {
          "time_stamp_utc_ms": 1735964760000,
          "time": "2025-01-04T04:28:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1735965060000,
          "time": "2025-01-04T04:33:00Z",
          "energy": 43.6800000006333,
          "capacity": 524.1600000075996
      },
      {
          "time_stamp_utc_ms": 1735965360000,
          "time": "2025-01-04T04:38:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1735965660000,
          "time": "2025-01-04T04:43:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1735965960000,
          "time": "2025-01-04T04:48:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1735966260000,
          "time": "2025-01-04T04:53:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735966560000,
          "time": "2025-01-04T04:58:00Z",
          "energy": 44.160000001080334,
          "capacity": 529.9200000129641
      },
      {
          "time_stamp_utc_ms": 1735966860000,
          "time": "2025-01-04T05:03:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1735967160000,
          "time": "2025-01-04T05:08:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1735967460000,
          "time": "2025-01-04T05:13:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735967760000,
          "time": "2025-01-04T05:18:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1735968060000,
          "time": "2025-01-04T05:23:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735968360000,
          "time": "2025-01-04T05:28:00Z",
          "energy": 43.00799999944866,
          "capacity": 516.0959999933839
      },
      {
          "time_stamp_utc_ms": 1735968660000,
          "time": "2025-01-04T05:33:00Z",
          "energy": 43.0080000013113,
          "capacity": 516.0960000157356
      },
      {
          "time_stamp_utc_ms": 1735968960000,
          "time": "2025-01-04T05:38:00Z",
          "energy": 43.7759999986738,
          "capacity": 525.3119999840856
      },
      {
          "time_stamp_utc_ms": 1735969260000,
          "time": "2025-01-04T05:43:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1735969560000,
          "time": "2025-01-04T05:48:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735969860000,
          "time": "2025-01-04T05:53:00Z",
          "energy": 43.295999999158084,
          "capacity": 519.551999989897
      },
      {
          "time_stamp_utc_ms": 1735970160000,
          "time": "2025-01-04T05:58:00Z",
          "energy": 43.29600000102073,
          "capacity": 519.5520000122488
      },
      {
          "time_stamp_utc_ms": 1735970460000,
          "time": "2025-01-04T06:03:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735970760000,
          "time": "2025-01-04T06:08:00Z",
          "energy": 43.295999999158084,
          "capacity": 519.551999989897
      },
      {
          "time_stamp_utc_ms": 1735971060000,
          "time": "2025-01-04T06:13:00Z",
          "energy": 43.488000000827014,
          "capacity": 521.8560000099242
      },
      {
          "time_stamp_utc_ms": 1735971360000,
          "time": "2025-01-04T06:18:00Z",
          "energy": 43.58399999886751,
          "capacity": 523.0079999864101
      },
      {
          "time_stamp_utc_ms": 1735971660000,
          "time": "2025-01-04T06:23:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1735971960000,
          "time": "2025-01-04T06:28:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735972260000,
          "time": "2025-01-04T06:33:00Z",
          "energy": 42.62399999983609,
          "capacity": 511.4879999980331
      },
      {
          "time_stamp_utc_ms": 1735972560000,
          "time": "2025-01-04T06:38:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1735972860000,
          "time": "2025-01-04T06:43:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1735973160000,
          "time": "2025-01-04T06:48:00Z",
          "energy": 43.104000001214445,
          "capacity": 517.2480000145733
      },
      {
          "time_stamp_utc_ms": 1735973460000,
          "time": "2025-01-04T06:53:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1735973760000,
          "time": "2025-01-04T06:58:00Z",
          "energy": 43.295999999158084,
          "capacity": 519.551999989897
      },
      {
          "time_stamp_utc_ms": 1735974060000,
          "time": "2025-01-04T07:03:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1735974360000,
          "time": "2025-01-04T07:08:00Z",
          "energy": 42.52800000179559,
          "capacity": 510.3360000215471
      },
      {
          "time_stamp_utc_ms": 1735974660000,
          "time": "2025-01-04T07:13:00Z",
          "energy": 42.43199999909848,
          "capacity": 509.18399998918176
      },
      {
          "time_stamp_utc_ms": 1735974960000,
          "time": "2025-01-04T07:18:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735975260000,
          "time": "2025-01-04T07:23:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1735975560000,
          "time": "2025-01-04T07:28:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1735975860000,
          "time": "2025-01-04T07:33:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1735976160000,
          "time": "2025-01-04T07:38:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735976460000,
          "time": "2025-01-04T07:43:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735976760000,
          "time": "2025-01-04T07:48:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735977060000,
          "time": "2025-01-04T07:53:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735977360000,
          "time": "2025-01-04T07:58:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735977660000,
          "time": "2025-01-04T08:03:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735977960000,
          "time": "2025-01-04T08:08:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735978260000,
          "time": "2025-01-04T08:13:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1735978560000,
          "time": "2025-01-04T08:18:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1735978860000,
          "time": "2025-01-04T08:23:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735979160000,
          "time": "2025-01-04T08:28:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735979460000,
          "time": "2025-01-04T08:33:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735979760000,
          "time": "2025-01-04T08:38:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1735980060000,
          "time": "2025-01-04T08:43:00Z",
          "energy": 44.06399999931455,
          "capacity": 528.7679999917746
      },
      {
          "time_stamp_utc_ms": 1735980360000,
          "time": "2025-01-04T08:48:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1735980660000,
          "time": "2025-01-04T08:53:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1735980960000,
          "time": "2025-01-04T08:58:00Z",
          "energy": 45.119999999180436,
          "capacity": 541.4399999901652
      },
      {
          "time_stamp_utc_ms": 1735981260000,
          "time": "2025-01-04T09:03:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735981560000,
          "time": "2025-01-04T09:08:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735981860000,
          "time": "2025-01-04T09:13:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1735982160000,
          "time": "2025-01-04T09:18:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1735982460000,
          "time": "2025-01-04T09:23:00Z",
          "energy": 44.928000001236796,
          "capacity": 539.1360000148416
      },
      {
          "time_stamp_utc_ms": 1735982760000,
          "time": "2025-01-04T09:28:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1735983060000,
          "time": "2025-01-04T09:33:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735983360000,
          "time": "2025-01-04T09:38:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1735983660000,
          "time": "2025-01-04T09:43:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1735983960000,
          "time": "2025-01-04T09:48:00Z",
          "energy": 45.023999999277294,
          "capacity": 540.2879999913275
      },
      {
          "time_stamp_utc_ms": 1735984260000,
          "time": "2025-01-04T09:53:00Z",
          "energy": 46.94400000106543,
          "capacity": 563.3280000127852
      },
      {
          "time_stamp_utc_ms": 1735984560000,
          "time": "2025-01-04T09:58:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735984860000,
          "time": "2025-01-04T10:03:00Z",
          "energy": 45.312000000849366,
          "capacity": 543.7440000101924
      },
      {
          "time_stamp_utc_ms": 1735985160000,
          "time": "2025-01-04T10:08:00Z",
          "energy": 44.92799999937415,
          "capacity": 539.1359999924898
      },
      {
          "time_stamp_utc_ms": 1735985460000,
          "time": "2025-01-04T10:13:00Z",
          "energy": 45.21599999908358,
          "capacity": 542.5919999890029
      },
      {
          "time_stamp_utc_ms": 1735985760000,
          "time": "2025-01-04T10:18:00Z",
          "energy": 46.080000000074506,
          "capacity": 552.9600000008941
      },
      {
          "time_stamp_utc_ms": 1735986060000,
          "time": "2025-01-04T10:23:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735986360000,
          "time": "2025-01-04T10:28:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1735986660000,
          "time": "2025-01-04T10:33:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735986960000,
          "time": "2025-01-04T10:38:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1735987260000,
          "time": "2025-01-04T10:43:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1735987560000,
          "time": "2025-01-04T10:48:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735987860000,
          "time": "2025-01-04T10:53:00Z",
          "energy": 45.119999999180436,
          "capacity": 541.4399999901652
      },
      {
          "time_stamp_utc_ms": 1735988160000,
          "time": "2025-01-04T10:58:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1735988460000,
          "time": "2025-01-04T11:03:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735988760000,
          "time": "2025-01-04T11:08:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1735989060000,
          "time": "2025-01-04T11:13:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1735989360000,
          "time": "2025-01-04T11:18:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1735989660000,
          "time": "2025-01-04T11:23:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1735989960000,
          "time": "2025-01-04T11:28:00Z",
          "energy": 44.92799999937415,
          "capacity": 539.1359999924898
      },
      {
          "time_stamp_utc_ms": 1735990260000,
          "time": "2025-01-04T11:33:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1735990560000,
          "time": "2025-01-04T11:38:00Z",
          "energy": 44.35200000088662,
          "capacity": 532.2240000106395
      },
      {
          "time_stamp_utc_ms": 1735990860000,
          "time": "2025-01-04T11:43:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1735991160000,
          "time": "2025-01-04T11:48:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735991460000,
          "time": "2025-01-04T11:53:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1735991760000,
          "time": "2025-01-04T11:58:00Z",
          "energy": 45.8879999993369,
          "capacity": 550.6559999920429
      },
      {
          "time_stamp_utc_ms": 1735992060000,
          "time": "2025-01-04T12:03:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1735992360000,
          "time": "2025-01-04T12:08:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735992660000,
          "time": "2025-01-04T12:13:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1735992960000,
          "time": "2025-01-04T12:18:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1735993260000,
          "time": "2025-01-04T12:23:00Z",
          "energy": 46.94400000106543,
          "capacity": 563.3280000127852
      },
      {
          "time_stamp_utc_ms": 1735993560000,
          "time": "2025-01-04T12:28:00Z",
          "energy": 46.65599999949336,
          "capacity": 559.8719999939203
      },
      {
          "time_stamp_utc_ms": 1735993860000,
          "time": "2025-01-04T12:33:00Z",
          "energy": 47.03999999910593,
          "capacity": 564.4799999892712
      },
      {
          "time_stamp_utc_ms": 1735994160000,
          "time": "2025-01-04T12:38:00Z",
          "energy": 44.64000000059605,
          "capacity": 535.6800000071527
      },
      {
          "time_stamp_utc_ms": 1735994460000,
          "time": "2025-01-04T12:43:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1735994760000,
          "time": "2025-01-04T12:48:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1735995060000,
          "time": "2025-01-04T12:53:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1735995360000,
          "time": "2025-01-04T12:58:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1735995660000,
          "time": "2025-01-04T13:03:00Z",
          "energy": 45.312000000849366,
          "capacity": 543.7440000101924
      },
      {
          "time_stamp_utc_ms": 1735995960000,
          "time": "2025-01-04T13:08:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1735996260000,
          "time": "2025-01-04T13:13:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1735996560000,
          "time": "2025-01-04T13:18:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735996860000,
          "time": "2025-01-04T13:23:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1735997160000,
          "time": "2025-01-04T13:28:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1735997460000,
          "time": "2025-01-04T13:33:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1735997760000,
          "time": "2025-01-04T13:38:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1735998060000,
          "time": "2025-01-04T13:43:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1735998360000,
          "time": "2025-01-04T13:48:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1735998660000,
          "time": "2025-01-04T13:53:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1735998960000,
          "time": "2025-01-04T13:58:00Z",
          "energy": 42.62400000076741,
          "capacity": 511.4880000092089
      },
      {
          "time_stamp_utc_ms": 1735999260000,
          "time": "2025-01-04T14:03:00Z",
          "energy": 43.967999999411404,
          "capacity": 527.6159999929368
      },
      {
          "time_stamp_utc_ms": 1735999560000,
          "time": "2025-01-04T14:08:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1735999860000,
          "time": "2025-01-04T14:13:00Z",
          "energy": 42.62399999983609,
          "capacity": 511.4879999980331
      },
      {
          "time_stamp_utc_ms": 1736000160000,
          "time": "2025-01-04T14:18:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1736000460000,
          "time": "2025-01-04T14:23:00Z",
          "energy": 40.99200000055134,
          "capacity": 491.9040000066161
      },
      {
          "time_stamp_utc_ms": 1736000760000,
          "time": "2025-01-04T14:28:00Z",
          "energy": 41.95200000051409,
          "capacity": 503.42400000616914
      },
      {
          "time_stamp_utc_ms": 1736001060000,
          "time": "2025-01-04T14:33:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1736001360000,
          "time": "2025-01-04T14:38:00Z",
          "energy": 41.28000000026077,
          "capacity": 495.36000000312924
      },
      {
          "time_stamp_utc_ms": 1736001660000,
          "time": "2025-01-04T14:43:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1736001960000,
          "time": "2025-01-04T14:48:00Z",
          "energy": 41.088000000454485,
          "capacity": 493.0560000054538
      },
      {
          "time_stamp_utc_ms": 1736002260000,
          "time": "2025-01-04T14:53:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1736002560000,
          "time": "2025-01-04T14:58:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1736002860000,
          "time": "2025-01-04T15:03:00Z",
          "energy": 41.183999999426305,
          "capacity": 494.2079999931157
      },
      {
          "time_stamp_utc_ms": 1736003160000,
          "time": "2025-01-04T15:08:00Z",
          "energy": 42.04800000041723,
          "capacity": 504.57600000500685
      },
      {
          "time_stamp_utc_ms": 1736003460000,
          "time": "2025-01-04T15:13:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1736003760000,
          "time": "2025-01-04T15:18:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1736004060000,
          "time": "2025-01-04T15:23:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1736004360000,
          "time": "2025-01-04T15:28:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1736004660000,
          "time": "2025-01-04T15:33:00Z",
          "energy": 39.74400000087917,
          "capacity": 476.92800001055
      },
      {
          "time_stamp_utc_ms": 1736004960000,
          "time": "2025-01-04T15:38:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1736005260000,
          "time": "2025-01-04T15:43:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1736005560000,
          "time": "2025-01-04T15:48:00Z",
          "energy": 38.015999998897314,
          "capacity": 456.19199998676777
      },
      {
          "time_stamp_utc_ms": 1736005860000,
          "time": "2025-01-04T15:53:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1736006160000,
          "time": "2025-01-04T15:58:00Z",
          "energy": 38.59200000111014,
          "capacity": 463.10400001332164
      },
      {
          "time_stamp_utc_ms": 1736006460000,
          "time": "2025-01-04T16:03:00Z",
          "energy": 38.399999998509884,
          "capacity": 460.7999999821186
      },
      {
          "time_stamp_utc_ms": 1736006760000,
          "time": "2025-01-04T16:08:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1736007060000,
          "time": "2025-01-04T16:13:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1736007360000,
          "time": "2025-01-04T16:18:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1736007660000,
          "time": "2025-01-04T16:23:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1736007960000,
          "time": "2025-01-04T16:28:00Z",
          "energy": 36.671999999322,
          "capacity": 440.06399999186397
      },
      {
          "time_stamp_utc_ms": 1736008260000,
          "time": "2025-01-04T16:33:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1736008560000,
          "time": "2025-01-04T16:38:00Z",
          "energy": 36.96000000089407,
          "capacity": 443.5200000107289
      },
      {
          "time_stamp_utc_ms": 1736008860000,
          "time": "2025-01-04T16:43:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1736009160000,
          "time": "2025-01-04T16:48:00Z",
          "energy": 37.63200000021607,
          "capacity": 451.58400000259286
      },
      {
          "time_stamp_utc_ms": 1736009460000,
          "time": "2025-01-04T16:53:00Z",
          "energy": 36.959999999031425,
          "capacity": 443.5199999883771
      },
      {
          "time_stamp_utc_ms": 1736009760000,
          "time": "2025-01-04T16:58:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1736010060000,
          "time": "2025-01-04T17:03:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1736010360000,
          "time": "2025-01-04T17:08:00Z",
          "energy": 35.90400000009686,
          "capacity": 430.8480000011623
      },
      {
          "time_stamp_utc_ms": 1736010660000,
          "time": "2025-01-04T17:13:00Z",
          "energy": 36.38399999961257,
          "capacity": 436.60799999535084
      },
      {
          "time_stamp_utc_ms": 1736010960000,
          "time": "2025-01-04T17:18:00Z",
          "energy": 35.71200000029057,
          "capacity": 428.5440000034869
      },
      {
          "time_stamp_utc_ms": 1736011260000,
          "time": "2025-01-04T17:23:00Z",
          "energy": 35.808000000193715,
          "capacity": 429.6960000023246
      },
      {
          "time_stamp_utc_ms": 1736011560000,
          "time": "2025-01-04T17:28:00Z",
          "energy": 35.71200000029057,
          "capacity": 428.5440000034869
      },
      {
          "time_stamp_utc_ms": 1736011860000,
          "time": "2025-01-04T17:33:00Z",
          "energy": 34.7519999993965,
          "capacity": 417.0239999927581
      },
      {
          "time_stamp_utc_ms": 1736012160000,
          "time": "2025-01-04T17:38:00Z",
          "energy": 36.671999999322,
          "capacity": 440.06399999186397
      },
      {
          "time_stamp_utc_ms": 1736012460000,
          "time": "2025-01-04T17:43:00Z",
          "energy": 35.23200000077486,
          "capacity": 422.7840000092983
      },
      {
          "time_stamp_utc_ms": 1736012760000,
          "time": "2025-01-04T17:48:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1736013060000,
          "time": "2025-01-04T17:53:00Z",
          "energy": 34.94400000013411,
          "capacity": 419.3280000016093
      },
      {
          "time_stamp_utc_ms": 1736013360000,
          "time": "2025-01-04T17:58:00Z",
          "energy": 34.27199999894947,
          "capacity": 411.2639999873937
      },
      {
          "time_stamp_utc_ms": 1736013660000,
          "time": "2025-01-04T18:03:00Z",
          "energy": 35.13600000087172,
          "capacity": 421.6320000104606
      },
      {
          "time_stamp_utc_ms": 1736013960000,
          "time": "2025-01-04T18:08:00Z",
          "energy": 34.080000000074506,
          "capacity": 408.9600000008941
      },
      {
          "time_stamp_utc_ms": 1736014260000,
          "time": "2025-01-04T18:13:00Z",
          "energy": 34.175999999046326,
          "capacity": 410.11199998855597
      },
      {
          "time_stamp_utc_ms": 1736014560000,
          "time": "2025-01-04T18:18:00Z",
          "energy": 34.17599999997765,
          "capacity": 410.11199999973184
      },
      {
          "time_stamp_utc_ms": 1736014860000,
          "time": "2025-01-04T18:23:00Z",
          "energy": 32.928000000305474,
          "capacity": 395.13600000366574
      },
      {
          "time_stamp_utc_ms": 1736015160000,
          "time": "2025-01-04T18:28:00Z",
          "energy": 35.23200000077486,
          "capacity": 422.7840000092983
      },
      {
          "time_stamp_utc_ms": 1736015460000,
          "time": "2025-01-04T18:33:00Z",
          "energy": 33.887999998405576,
          "capacity": 406.6559999808669
      },
      {
          "time_stamp_utc_ms": 1736015760000,
          "time": "2025-01-04T18:38:00Z",
          "energy": 35.80800000112504,
          "capacity": 429.69600001350045
      },
      {
          "time_stamp_utc_ms": 1736016060000,
          "time": "2025-01-04T18:43:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1736016360000,
          "time": "2025-01-04T18:48:00Z",
          "energy": 35.23199999984354,
          "capacity": 422.78399999812245
      },
      {
          "time_stamp_utc_ms": 1736016660000,
          "time": "2025-01-04T18:53:00Z",
          "energy": 34.84800000023097,
          "capacity": 418.1760000027716
      },
      {
          "time_stamp_utc_ms": 1736016960000,
          "time": "2025-01-04T18:58:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1736017260000,
          "time": "2025-01-04T19:03:00Z",
          "energy": 34.55999999959022,
          "capacity": 414.7199999950827
      },
      {
          "time_stamp_utc_ms": 1736017560000,
          "time": "2025-01-04T19:08:00Z",
          "energy": 35.32799999974668,
          "capacity": 423.93599999696016
      },
      {
          "time_stamp_utc_ms": 1736017860000,
          "time": "2025-01-04T19:13:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1736018160000,
          "time": "2025-01-04T19:18:00Z",
          "energy": 35.04000000003725,
          "capacity": 420.48000000044703
      },
      {
          "time_stamp_utc_ms": 1736018460000,
          "time": "2025-01-04T19:23:00Z",
          "energy": 31.392000000923872,
          "capacity": 376.70400001108646
      },
      {
          "time_stamp_utc_ms": 1736018760000,
          "time": "2025-01-04T19:28:00Z",
          "energy": 32.351999999023974,
          "capacity": 388.2239999882877
      },
      {
          "time_stamp_utc_ms": 1736019060000,
          "time": "2025-01-04T19:33:00Z",
          "energy": 32.06400000024587,
          "capacity": 384.76800000295043
      },
      {
          "time_stamp_utc_ms": 1736019360000,
          "time": "2025-01-04T19:38:00Z",
          "energy": 31.968000000342727,
          "capacity": 383.6160000041127
      },
      {
          "time_stamp_utc_ms": 1736019660000,
          "time": "2025-01-04T19:43:00Z",
          "energy": 33.69599999953061,
          "capacity": 404.3519999943674
      },
      {
          "time_stamp_utc_ms": 1736019960000,
          "time": "2025-01-04T19:48:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1736020260000,
          "time": "2025-01-04T19:53:00Z",
          "energy": 34.94400000106543,
          "capacity": 419.3280000127852
      },
      {
          "time_stamp_utc_ms": 1736020560000,
          "time": "2025-01-04T19:58:00Z",
          "energy": 34.07999999914318,
          "capacity": 408.95999998971826
      },
      {
          "time_stamp_utc_ms": 1736020860000,
          "time": "2025-01-04T20:03:00Z",
          "energy": 33.12000000011176,
          "capacity": 397.44000000134116
      },
      {
          "time_stamp_utc_ms": 1736021160000,
          "time": "2025-01-04T20:08:00Z",
          "energy": 34.752000000327826,
          "capacity": 417.0240000039339
      },
      {
          "time_stamp_utc_ms": 1736021460000,
          "time": "2025-01-04T20:13:00Z",
          "energy": 33.79200000036508,
          "capacity": 405.504000004381
      },
      {
          "time_stamp_utc_ms": 1736021760000,
          "time": "2025-01-04T20:18:00Z",
          "energy": 35.135999999940395,
          "capacity": 421.63199999928474
      },
      {
          "time_stamp_utc_ms": 1736022060000,
          "time": "2025-01-04T20:23:00Z",
          "energy": 35.231999998912215,
          "capacity": 422.78399998694664
      },
      {
          "time_stamp_utc_ms": 1736022360000,
          "time": "2025-01-04T20:28:00Z",
          "energy": 36.38399999961257,
          "capacity": 436.60799999535084
      },
      {
          "time_stamp_utc_ms": 1736022660000,
          "time": "2025-01-04T20:33:00Z",
          "energy": 36.38399999961257,
          "capacity": 436.60799999535084
      },
      {
          "time_stamp_utc_ms": 1736022960000,
          "time": "2025-01-04T20:38:00Z",
          "energy": 35.42400000151247,
          "capacity": 425.0880000181496
      },
      {
          "time_stamp_utc_ms": 1736023260000,
          "time": "2025-01-04T20:43:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1736023560000,
          "time": "2025-01-04T20:48:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1736023860000,
          "time": "2025-01-04T20:53:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1736024160000,
          "time": "2025-01-04T20:58:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1736024460000,
          "time": "2025-01-04T21:03:00Z",
          "energy": 36.959999999031425,
          "capacity": 443.5199999883771
      },
      {
          "time_stamp_utc_ms": 1736024760000,
          "time": "2025-01-04T21:08:00Z",
          "energy": 37.53600000124425,
          "capacity": 450.432000014931
      },
      {
          "time_stamp_utc_ms": 1736025060000,
          "time": "2025-01-04T21:13:00Z",
          "energy": 38.015999998897314,
          "capacity": 456.19199998676777
      },
      {
          "time_stamp_utc_ms": 1736025360000,
          "time": "2025-01-04T21:18:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1736025660000,
          "time": "2025-01-04T21:23:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1736025960000,
          "time": "2025-01-04T21:28:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1736026260000,
          "time": "2025-01-04T21:33:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1736026560000,
          "time": "2025-01-04T21:38:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1736026860000,
          "time": "2025-01-04T21:43:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1736027160000,
          "time": "2025-01-04T21:48:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1736027460000,
          "time": "2025-01-04T21:53:00Z",
          "energy": 39.45599999837577,
          "capacity": 473.47199998050934
      },
      {
          "time_stamp_utc_ms": 1736027760000,
          "time": "2025-01-04T21:58:00Z",
          "energy": 40.03200000151992,
          "capacity": 480.384000018239
      },
      {
          "time_stamp_utc_ms": 1736028060000,
          "time": "2025-01-04T22:03:00Z",
          "energy": 40.60799999907613,
          "capacity": 487.29599998891354
      },
      {
          "time_stamp_utc_ms": 1736028360000,
          "time": "2025-01-04T22:08:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1736028660000,
          "time": "2025-01-04T22:13:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1736028960000,
          "time": "2025-01-04T22:18:00Z",
          "energy": 39.552000001072884,
          "capacity": 474.6240000128746
      },
      {
          "time_stamp_utc_ms": 1736029260000,
          "time": "2025-01-04T22:23:00Z",
          "energy": 40.51199999824166,
          "capacity": 486.14399997889996
      },
      {
          "time_stamp_utc_ms": 1736029560000,
          "time": "2025-01-04T22:28:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1736029860000,
          "time": "2025-01-04T22:33:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1736030160000,
          "time": "2025-01-04T22:38:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1736030460000,
          "time": "2025-01-04T22:43:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1736030760000,
          "time": "2025-01-04T22:48:00Z",
          "energy": 41.567999999038875,
          "capacity": 498.81599998846656
      },
      {
          "time_stamp_utc_ms": 1736031060000,
          "time": "2025-01-04T22:53:00Z",
          "energy": 41.28000000119209,
          "capacity": 495.3600000143052
      },
      {
          "time_stamp_utc_ms": 1736031360000,
          "time": "2025-01-04T22:58:00Z",
          "energy": 40.60799999907613,
          "capacity": 487.29599998891354
      },
      {
          "time_stamp_utc_ms": 1736031660000,
          "time": "2025-01-04T23:03:00Z",
          "energy": 41.472000000067055,
          "capacity": 497.66400000080466
      },
      {
          "time_stamp_utc_ms": 1736031960000,
          "time": "2025-01-04T23:08:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1736032260000,
          "time": "2025-01-04T23:13:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1736032560000,
          "time": "2025-01-04T23:18:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1736032860000,
          "time": "2025-01-04T23:23:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1736033160000,
          "time": "2025-01-04T23:28:00Z",
          "energy": 41.08800000138581,
          "capacity": 493.05600001662975
      },
      {
          "time_stamp_utc_ms": 1736033460000,
          "time": "2025-01-04T23:33:00Z",
          "energy": 41.47199999913573,
          "capacity": 497.66399998962885
      },
      {
          "time_stamp_utc_ms": 1736033760000,
          "time": "2025-01-04T23:38:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1736034060000,
          "time": "2025-01-04T23:43:00Z",
          "energy": 40.704000000841916,
          "capacity": 488.448000010103
      },
      {
          "time_stamp_utc_ms": 1736034360000,
          "time": "2025-01-04T23:48:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1736034660000,
          "time": "2025-01-04T23:53:00Z",
          "energy": 40.3199999993667,
          "capacity": 483.8399999924004
      },
      {
          "time_stamp_utc_ms": 1736034960000,
          "time": "2025-01-04T23:58:00Z",
          "energy": 42.239999999292195,
          "capacity": 506.87999999150634
      },
      {
          "time_stamp_utc_ms": 1736035260000,
          "time": "2025-01-05T00:03:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1736035560000,
          "time": "2025-01-05T00:08:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1736035860000,
          "time": "2025-01-05T00:13:00Z",
          "energy": 41.47199999913573,
          "capacity": 497.66399998962885
      },
      {
          "time_stamp_utc_ms": 1736036160000,
          "time": "2025-01-05T00:18:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1736036460000,
          "time": "2025-01-05T00:23:00Z",
          "energy": 42.04800000041723,
          "capacity": 504.57600000500685
      },
      {
          "time_stamp_utc_ms": 1736036760000,
          "time": "2025-01-05T00:28:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1736037060000,
          "time": "2025-01-05T00:33:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1736037360000,
          "time": "2025-01-05T00:38:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1736037660000,
          "time": "2025-01-05T00:43:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1736037960000,
          "time": "2025-01-05T00:48:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1736038260000,
          "time": "2025-01-05T00:53:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1736038560000,
          "time": "2025-01-05T00:58:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1736038860000,
          "time": "2025-01-05T01:03:00Z",
          "energy": 42.62400000076741,
          "capacity": 511.4880000092089
      },
      {
          "time_stamp_utc_ms": 1736039160000,
          "time": "2025-01-05T01:08:00Z",
          "energy": 41.66399999894202,
          "capacity": 499.96799998730427
      },
      {
          "time_stamp_utc_ms": 1736039460000,
          "time": "2025-01-05T01:13:00Z",
          "energy": 41.760000000707805,
          "capacity": 501.1200000084937
      },
      {
          "time_stamp_utc_ms": 1736039760000,
          "time": "2025-01-05T01:18:00Z",
          "energy": 42.239999999292195,
          "capacity": 506.87999999150634
      },
      {
          "time_stamp_utc_ms": 1736040060000,
          "time": "2025-01-05T01:23:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1736040360000,
          "time": "2025-01-05T01:28:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1736040660000,
          "time": "2025-01-05T01:33:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1736040960000,
          "time": "2025-01-05T01:38:00Z",
          "energy": 41.28000000026077,
          "capacity": 495.36000000312924
      },
      {
          "time_stamp_utc_ms": 1736041260000,
          "time": "2025-01-05T01:43:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1736041560000,
          "time": "2025-01-05T01:48:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1736041860000,
          "time": "2025-01-05T01:53:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1736042160000,
          "time": "2025-01-05T01:58:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1736042460000,
          "time": "2025-01-05T02:03:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1736042760000,
          "time": "2025-01-05T02:08:00Z",
          "energy": 42.43199999909848,
          "capacity": 509.18399998918176
      },
      {
          "time_stamp_utc_ms": 1736043060000,
          "time": "2025-01-05T02:13:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1736043360000,
          "time": "2025-01-05T02:18:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1736043660000,
          "time": "2025-01-05T02:23:00Z",
          "energy": 42.24000000022352,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1736043960000,
          "time": "2025-01-05T02:28:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1736044260000,
          "time": "2025-01-05T02:33:00Z",
          "energy": 42.62399999983609,
          "capacity": 511.4879999980331
      },
      {
          "time_stamp_utc_ms": 1736044560000,
          "time": "2025-01-05T02:38:00Z",
          "energy": 41.56800000090152,
          "capacity": 498.8160000108183
      },
      {
          "time_stamp_utc_ms": 1736044860000,
          "time": "2025-01-05T02:43:00Z",
          "energy": 42.23999999836087,
          "capacity": 506.8799999803305
      },
      {
          "time_stamp_utc_ms": 1736045160000,
          "time": "2025-01-05T02:48:00Z",
          "energy": 42.33600000105798,
          "capacity": 508.0320000126958
      },
      {
          "time_stamp_utc_ms": 1736045460000,
          "time": "2025-01-05T02:53:00Z",
          "energy": 42.14399999938905,
          "capacity": 505.72799999266863
      },
      {
          "time_stamp_utc_ms": 1736045760000,
          "time": "2025-01-05T02:58:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1736046060000,
          "time": "2025-01-05T03:03:00Z",
          "energy": 42.62400000076741,
          "capacity": 511.4880000092089
      },
      {
          "time_stamp_utc_ms": 1736046360000,
          "time": "2025-01-05T03:08:00Z",
          "energy": 42.14399999938905,
          "capacity": 505.72799999266863
      },
      {
          "time_stamp_utc_ms": 1736046660000,
          "time": "2025-01-05T03:13:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1736046960000,
          "time": "2025-01-05T03:18:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1736047260000,
          "time": "2025-01-05T03:23:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1736047560000,
          "time": "2025-01-05T03:28:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1736047860000,
          "time": "2025-01-05T03:33:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1736048160000,
          "time": "2025-01-05T03:38:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1736048460000,
          "time": "2025-01-05T03:43:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1736048760000,
          "time": "2025-01-05T03:48:00Z",
          "energy": 42.52799999900162,
          "capacity": 510.33599998801947
      },
      {
          "time_stamp_utc_ms": 1736049060000,
          "time": "2025-01-05T03:53:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1736049360000,
          "time": "2025-01-05T03:58:00Z",
          "energy": 42.72000000067055,
          "capacity": 512.6400000080466
      },
      {
          "time_stamp_utc_ms": 1736049660000,
          "time": "2025-01-05T04:03:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1736049960000,
          "time": "2025-01-05T04:08:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1736050260000,
          "time": "2025-01-05T04:13:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1736050560000,
          "time": "2025-01-05T04:18:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1736050860000,
          "time": "2025-01-05T04:23:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1736051160000,
          "time": "2025-01-05T04:28:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1736051460000,
          "time": "2025-01-05T04:33:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1736051760000,
          "time": "2025-01-05T04:38:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1736052060000,
          "time": "2025-01-05T04:43:00Z",
          "energy": 41.56800000090152,
          "capacity": 498.8160000108183
      },
      {
          "time_stamp_utc_ms": 1736052360000,
          "time": "2025-01-05T04:48:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1736052660000,
          "time": "2025-01-05T04:53:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1736052960000,
          "time": "2025-01-05T04:58:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1736053260000,
          "time": "2025-01-05T05:03:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1736053560000,
          "time": "2025-01-05T05:08:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1736053860000,
          "time": "2025-01-05T05:13:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1736054160000,
          "time": "2025-01-05T05:18:00Z",
          "energy": 43.776000001467764,
          "capacity": 525.3120000176133
      },
      {
          "time_stamp_utc_ms": 1736054460000,
          "time": "2025-01-05T05:23:00Z",
          "energy": 42.623999998904765,
          "capacity": 511.4879999868572
      },
      {
          "time_stamp_utc_ms": 1736054760000,
          "time": "2025-01-05T05:28:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1736055060000,
          "time": "2025-01-05T05:33:00Z",
          "energy": 42.43199999909848,
          "capacity": 509.18399998918176
      },
      {
          "time_stamp_utc_ms": 1736055360000,
          "time": "2025-01-05T05:38:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1736055660000,
          "time": "2025-01-05T05:43:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1736055960000,
          "time": "2025-01-05T05:48:00Z",
          "energy": 42.62400000076741,
          "capacity": 511.4880000092089
      },
      {
          "time_stamp_utc_ms": 1736056260000,
          "time": "2025-01-05T05:53:00Z",
          "energy": 44.25599999912083,
          "capacity": 531.07199998945
      },
      {
          "time_stamp_utc_ms": 1736056560000,
          "time": "2025-01-05T05:58:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1736056860000,
          "time": "2025-01-05T06:03:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1736057160000,
          "time": "2025-01-05T06:08:00Z",
          "energy": 43.29600000102073,
          "capacity": 519.5520000122488
      },
      {
          "time_stamp_utc_ms": 1736057460000,
          "time": "2025-01-05T06:13:00Z",
          "energy": 42.52799999900162,
          "capacity": 510.33599998801947
      },
      {
          "time_stamp_utc_ms": 1736057760000,
          "time": "2025-01-05T06:18:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1736058060000,
          "time": "2025-01-05T06:23:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1736058360000,
          "time": "2025-01-05T06:28:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1736058660000,
          "time": "2025-01-05T06:33:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1736058960000,
          "time": "2025-01-05T06:38:00Z",
          "energy": 42.52799999900162,
          "capacity": 510.33599998801947
      },
      {
          "time_stamp_utc_ms": 1736059260000,
          "time": "2025-01-05T06:43:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1736059560000,
          "time": "2025-01-05T06:48:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1736059860000,
          "time": "2025-01-05T06:53:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1736060160000,
          "time": "2025-01-05T06:58:00Z",
          "energy": 43.6800000006333,
          "capacity": 524.1600000075996
      },
      {
          "time_stamp_utc_ms": 1736060460000,
          "time": "2025-01-05T07:03:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1736060760000,
          "time": "2025-01-05T07:08:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1736061060000,
          "time": "2025-01-05T07:13:00Z",
          "energy": 43.872000000439584,
          "capacity": 526.464000005275
      },
      {
          "time_stamp_utc_ms": 1736061360000,
          "time": "2025-01-05T07:18:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1736061660000,
          "time": "2025-01-05T07:23:00Z",
          "energy": 44.16000000014901,
          "capacity": 529.9200000017881
      },
      {
          "time_stamp_utc_ms": 1736061960000,
          "time": "2025-01-05T07:28:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1736062260000,
          "time": "2025-01-05T07:33:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1736062560000,
          "time": "2025-01-05T07:38:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1736062860000,
          "time": "2025-01-05T07:43:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1736063160000,
          "time": "2025-01-05T07:48:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1736063460000,
          "time": "2025-01-05T07:53:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1736063760000,
          "time": "2025-01-05T07:58:00Z",
          "energy": 43.39199999906123,
          "capacity": 520.7039999887347
      },
      {
          "time_stamp_utc_ms": 1736064060000,
          "time": "2025-01-05T08:03:00Z",
          "energy": 43.104000001214445,
          "capacity": 517.2480000145733
      },
      {
          "time_stamp_utc_ms": 1736064360000,
          "time": "2025-01-05T08:08:00Z",
          "energy": 43.87199999950826,
          "capacity": 526.4639999940991
      },
      {
          "time_stamp_utc_ms": 1736064660000,
          "time": "2025-01-05T08:13:00Z",
          "energy": 43.29600000102073,
          "capacity": 519.5520000122488
      },
      {
          "time_stamp_utc_ms": 1736064960000,
          "time": "2025-01-05T08:18:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1736065260000,
          "time": "2025-01-05T08:23:00Z",
          "energy": 42.43199999909848,
          "capacity": 509.18399998918176
      },
      {
          "time_stamp_utc_ms": 1736065560000,
          "time": "2025-01-05T08:28:00Z",
          "energy": 43.58400000073016,
          "capacity": 523.0080000087619
      },
      {
          "time_stamp_utc_ms": 1736065860000,
          "time": "2025-01-05T08:33:00Z",
          "energy": 43.488000000827014,
          "capacity": 521.8560000099242
      },
      {
          "time_stamp_utc_ms": 1736066160000,
          "time": "2025-01-05T08:38:00Z",
          "energy": 42.91199999861419,
          "capacity": 514.9439999833703
      },
      {
          "time_stamp_utc_ms": 1736066460000,
          "time": "2025-01-05T08:43:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1736066760000,
          "time": "2025-01-05T08:48:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1736067060000,
          "time": "2025-01-05T08:53:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      },
      {
          "time_stamp_utc_ms": 1736067360000,
          "time": "2025-01-05T08:58:00Z",
          "energy": 44.35200000088662,
          "capacity": 532.2240000106395
      },
      {
          "time_stamp_utc_ms": 1736067660000,
          "time": "2025-01-05T09:03:00Z",
          "energy": 43.967999999411404,
          "capacity": 527.6159999929368
      },
      {
          "time_stamp_utc_ms": 1736067960000,
          "time": "2025-01-05T09:08:00Z",
          "energy": 44.35200000088662,
          "capacity": 532.2240000106395
      },
      {
          "time_stamp_utc_ms": 1736068260000,
          "time": "2025-01-05T09:13:00Z",
          "energy": 43.007999998517334,
          "capacity": 516.095999982208
      },
      {
          "time_stamp_utc_ms": 1736068560000,
          "time": "2025-01-05T09:18:00Z",
          "energy": 43.96800000127405,
          "capacity": 527.6160000152887
      },
      {
          "time_stamp_utc_ms": 1736068860000,
          "time": "2025-01-05T09:23:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1736069160000,
          "time": "2025-01-05T09:28:00Z",
          "energy": 43.295999999158084,
          "capacity": 519.551999989897
      },
      {
          "time_stamp_utc_ms": 1736069460000,
          "time": "2025-01-05T09:33:00Z",
          "energy": 44.3519999999553,
          "capacity": 532.2239999994636
      },
      {
          "time_stamp_utc_ms": 1736069760000,
          "time": "2025-01-05T09:38:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1736070060000,
          "time": "2025-01-05T09:43:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1736070360000,
          "time": "2025-01-05T09:48:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1736070660000,
          "time": "2025-01-05T09:53:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1736070960000,
          "time": "2025-01-05T09:58:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1736071260000,
          "time": "2025-01-05T10:03:00Z",
          "energy": 42.72000000067055,
          "capacity": 512.6400000080466
      },
      {
          "time_stamp_utc_ms": 1736071560000,
          "time": "2025-01-05T10:08:00Z",
          "energy": 43.48799999896437,
          "capacity": 521.8559999875724
      },
      {
          "time_stamp_utc_ms": 1736071860000,
          "time": "2025-01-05T10:13:00Z",
          "energy": 43.488000000827014,
          "capacity": 521.8560000099242
      },
      {
          "time_stamp_utc_ms": 1736072160000,
          "time": "2025-01-05T10:18:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1736072460000,
          "time": "2025-01-05T10:23:00Z",
          "energy": 43.87199999857694,
          "capacity": 526.4639999829233
      },
      {
          "time_stamp_utc_ms": 1736072760000,
          "time": "2025-01-05T10:28:00Z",
          "energy": 43.96800000127405,
          "capacity": 527.6160000152887
      },
      {
          "time_stamp_utc_ms": 1736073060000,
          "time": "2025-01-05T10:33:00Z",
          "energy": 44.544000000692904,
          "capacity": 534.528000008315
      },
      {
          "time_stamp_utc_ms": 1736073360000,
          "time": "2025-01-05T10:38:00Z",
          "energy": 44.06399999931455,
          "capacity": 528.7679999917746
      },
      {
          "time_stamp_utc_ms": 1736073660000,
          "time": "2025-01-05T10:43:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1736073960000,
          "time": "2025-01-05T10:48:00Z",
          "energy": 43.679999998770654,
          "capacity": 524.1599999852479
      },
      {
          "time_stamp_utc_ms": 1736074260000,
          "time": "2025-01-05T10:53:00Z",
          "energy": 43.48800000175834,
          "capacity": 521.8560000211002
      },
      {
          "time_stamp_utc_ms": 1736074560000,
          "time": "2025-01-05T10:58:00Z",
          "energy": 43.48799999896437,
          "capacity": 521.8559999875724
      },
      {
          "time_stamp_utc_ms": 1736074860000,
          "time": "2025-01-05T11:03:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1736075160000,
          "time": "2025-01-05T11:08:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1736075460000,
          "time": "2025-01-05T11:13:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1736075760000,
          "time": "2025-01-05T11:18:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1736076060000,
          "time": "2025-01-05T11:23:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1736076360000,
          "time": "2025-01-05T11:28:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1736076660000,
          "time": "2025-01-05T11:33:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1736076960000,
          "time": "2025-01-05T11:38:00Z",
          "energy": 43.29600000102073,
          "capacity": 519.5520000122488
      },
      {
          "time_stamp_utc_ms": 1736077260000,
          "time": "2025-01-05T11:43:00Z",
          "energy": 44.06399999931455,
          "capacity": 528.7679999917746
      },
      {
          "time_stamp_utc_ms": 1736077560000,
          "time": "2025-01-05T11:48:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1736077860000,
          "time": "2025-01-05T11:53:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1736078160000,
          "time": "2025-01-05T11:58:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1736078460000,
          "time": "2025-01-05T12:03:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1736078760000,
          "time": "2025-01-05T12:08:00Z",
          "energy": 44.160000001080334,
          "capacity": 529.9200000129641
      },
      {
          "time_stamp_utc_ms": 1736079060000,
          "time": "2025-01-05T12:13:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1736079360000,
          "time": "2025-01-05T12:18:00Z",
          "energy": 44.25599999912083,
          "capacity": 531.07199998945
      },
      {
          "time_stamp_utc_ms": 1736079660000,
          "time": "2025-01-05T12:23:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1736079960000,
          "time": "2025-01-05T12:28:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1736080260000,
          "time": "2025-01-05T12:33:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1736080560000,
          "time": "2025-01-05T12:38:00Z",
          "energy": 44.15999999921769,
          "capacity": 529.9199999906123
      },
      {
          "time_stamp_utc_ms": 1736080860000,
          "time": "2025-01-05T12:43:00Z",
          "energy": 42.432000000961125,
          "capacity": 509.1840000115335
      },
      {
          "time_stamp_utc_ms": 1736081160000,
          "time": "2025-01-05T12:48:00Z",
          "energy": 45.216000000946224,
          "capacity": 542.5920000113547
      },
      {
          "time_stamp_utc_ms": 1736081460000,
          "time": "2025-01-05T12:53:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1736081760000,
          "time": "2025-01-05T12:58:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1736082060000,
          "time": "2025-01-05T13:03:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1736082360000,
          "time": "2025-01-05T13:08:00Z",
          "energy": 42.43199999909848,
          "capacity": 509.18399998918176
      },
      {
          "time_stamp_utc_ms": 1736082660000,
          "time": "2025-01-05T13:13:00Z",
          "energy": 43.39200000092387,
          "capacity": 520.7040000110865
      },
      {
          "time_stamp_utc_ms": 1736082960000,
          "time": "2025-01-05T13:18:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1736083260000,
          "time": "2025-01-05T13:23:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1736083560000,
          "time": "2025-01-05T13:28:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1736083860000,
          "time": "2025-01-05T13:33:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1736084160000,
          "time": "2025-01-05T13:38:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1736084460000,
          "time": "2025-01-05T13:43:00Z",
          "energy": 42.04800000041723,
          "capacity": 504.57600000500685
      },
      {
          "time_stamp_utc_ms": 1736084760000,
          "time": "2025-01-05T13:48:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1736085060000,
          "time": "2025-01-05T13:53:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1736085360000,
          "time": "2025-01-05T13:58:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1736085660000,
          "time": "2025-01-05T14:03:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1736085960000,
          "time": "2025-01-05T14:08:00Z",
          "energy": 42.04800000041723,
          "capacity": 504.57600000500685
      },
      {
          "time_stamp_utc_ms": 1736086260000,
          "time": "2025-01-05T14:13:00Z",
          "energy": 40.511999999172986,
          "capacity": 486.1439999900758
      },
      {
          "time_stamp_utc_ms": 1736086560000,
          "time": "2025-01-05T14:18:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1736086860000,
          "time": "2025-01-05T14:23:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1736087160000,
          "time": "2025-01-05T14:28:00Z",
          "energy": 40.511999999172986,
          "capacity": 486.1439999900758
      },
      {
          "time_stamp_utc_ms": 1736087460000,
          "time": "2025-01-05T14:33:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1736087760000,
          "time": "2025-01-05T14:38:00Z",
          "energy": 40.03200000151992,
          "capacity": 480.384000018239
      },
      {
          "time_stamp_utc_ms": 1736088060000,
          "time": "2025-01-05T14:43:00Z",
          "energy": 38.687999999150634,
          "capacity": 464.25599998980766
      },
      {
          "time_stamp_utc_ms": 1736088360000,
          "time": "2025-01-05T14:48:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1736088660000,
          "time": "2025-01-05T14:53:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1736088960000,
          "time": "2025-01-05T14:58:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1736089260000,
          "time": "2025-01-05T15:03:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1736089560000,
          "time": "2025-01-05T15:08:00Z",
          "energy": 37.631999999284744,
          "capacity": 451.58399999141693
      },
      {
          "time_stamp_utc_ms": 1736089860000,
          "time": "2025-01-05T15:13:00Z",
          "energy": 39.84000000078231,
          "capacity": 478.08000000938773
      },
      {
          "time_stamp_utc_ms": 1736090160000,
          "time": "2025-01-05T15:18:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1736090460000,
          "time": "2025-01-05T15:23:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1736090760000,
          "time": "2025-01-05T15:28:00Z",
          "energy": 39.071999998763204,
          "capacity": 468.8639999851585
      },
      {
          "time_stamp_utc_ms": 1736091060000,
          "time": "2025-01-05T15:33:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1736091360000,
          "time": "2025-01-05T15:38:00Z",
          "energy": 38.01599999982864,
          "capacity": 456.1919999979437
      },
      {
          "time_stamp_utc_ms": 1736091660000,
          "time": "2025-01-05T15:43:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1736091960000,
          "time": "2025-01-05T15:48:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1736092260000,
          "time": "2025-01-05T15:53:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1736092560000,
          "time": "2025-01-05T15:58:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1736092860000,
          "time": "2025-01-05T16:03:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1736093160000,
          "time": "2025-01-05T16:08:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1736093460000,
          "time": "2025-01-05T16:13:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1736093760000,
          "time": "2025-01-05T16:18:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1736094060000,
          "time": "2025-01-05T16:23:00Z",
          "energy": 36.57600000035018,
          "capacity": 438.9120000042021
      },
      {
          "time_stamp_utc_ms": 1736094360000,
          "time": "2025-01-05T16:28:00Z",
          "energy": 36.09599999897182,
          "capacity": 433.15199998766184
      },
      {
          "time_stamp_utc_ms": 1736094660000,
          "time": "2025-01-05T16:33:00Z",
          "energy": 37.152000000700355,
          "capacity": 445.8240000084043
      },
      {
          "time_stamp_utc_ms": 1736094960000,
          "time": "2025-01-05T16:38:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1736095260000,
          "time": "2025-01-05T16:43:00Z",
          "energy": 35.52000000048429,
          "capacity": 426.24000000581145
      },
      {
          "time_stamp_utc_ms": 1736095560000,
          "time": "2025-01-05T16:48:00Z",
          "energy": 34.94400000013411,
          "capacity": 419.3280000016093
      },
      {
          "time_stamp_utc_ms": 1736095860000,
          "time": "2025-01-05T16:53:00Z",
          "energy": 32.15999999921769,
          "capacity": 385.91999999061227
      },
      {
          "time_stamp_utc_ms": 1736096160000,
          "time": "2025-01-05T16:58:00Z",
          "energy": 33.69599999953061,
          "capacity": 404.3519999943674
      },
      {
          "time_stamp_utc_ms": 1736096460000,
          "time": "2025-01-05T17:03:00Z",
          "energy": 31.968000000342727,
          "capacity": 383.6160000041127
      },
      {
          "time_stamp_utc_ms": 1736096760000,
          "time": "2025-01-05T17:08:00Z",
          "energy": 32.06400000024587,
          "capacity": 384.76800000295043
      },
      {
          "time_stamp_utc_ms": 1736097060000,
          "time": "2025-01-05T17:13:00Z",
          "energy": 32.44799999985844,
          "capacity": 389.37599999830127
      },
      {
          "time_stamp_utc_ms": 1736097360000,
          "time": "2025-01-05T17:18:00Z",
          "energy": 33.50400000065565,
          "capacity": 402.04800000786787
      },
      {
          "time_stamp_utc_ms": 1736097660000,
          "time": "2025-01-05T17:23:00Z",
          "energy": 33.2160000000149,
          "capacity": 398.59200000017887
      },
      {
          "time_stamp_utc_ms": 1736097960000,
          "time": "2025-01-05T17:28:00Z",
          "energy": 33.59999999962747,
          "capacity": 403.1999999955297
      },
      {
          "time_stamp_utc_ms": 1736098260000,
          "time": "2025-01-05T17:33:00Z",
          "energy": 31.487999999895692,
          "capacity": 377.8559999987483
      },
      {
          "time_stamp_utc_ms": 1736098560000,
          "time": "2025-01-05T17:38:00Z",
          "energy": 32.639999999664724,
          "capacity": 391.6799999959767
      },
      {
          "time_stamp_utc_ms": 1736098860000,
          "time": "2025-01-05T17:43:00Z",
          "energy": 31.6800000006333,
          "capacity": 380.1600000075996
      },
      {
          "time_stamp_utc_ms": 1736099160000,
          "time": "2025-01-05T17:48:00Z",
          "energy": 30.911999999545515,
          "capacity": 370.9439999945462
      },
      {
          "time_stamp_utc_ms": 1736099460000,
          "time": "2025-01-05T17:53:00Z",
          "energy": 31.488000000827014,
          "capacity": 377.8560000099242
      },
      {
          "time_stamp_utc_ms": 1736099760000,
          "time": "2025-01-05T17:58:00Z",
          "energy": 30.71999999973923,
          "capacity": 368.63999999687076
      },
      {
          "time_stamp_utc_ms": 1736100060000,
          "time": "2025-01-05T18:03:00Z",
          "energy": 29.951999999582767,
          "capacity": 359.42399999499327
      },
      {
          "time_stamp_utc_ms": 1736100360000,
          "time": "2025-01-05T18:08:00Z",
          "energy": 31.00800000037998,
          "capacity": 372.09600000455976
      },
      {
          "time_stamp_utc_ms": 1736100660000,
          "time": "2025-01-05T18:13:00Z",
          "energy": 29.471999999135733,
          "capacity": 353.66399998962885
      },
      {
          "time_stamp_utc_ms": 1736100960000,
          "time": "2025-01-05T18:18:00Z",
          "energy": 31.776000000536442,
          "capacity": 381.3120000064373
      },
      {
          "time_stamp_utc_ms": 1736101260000,
          "time": "2025-01-05T18:23:00Z",
          "energy": 31.487999999895692,
          "capacity": 377.8559999987483
      },
      {
          "time_stamp_utc_ms": 1736101560000,
          "time": "2025-01-05T18:28:00Z",
          "energy": 31.39199999999255,
          "capacity": 376.7039999999106
      },
      {
          "time_stamp_utc_ms": 1736101860000,
          "time": "2025-01-05T18:33:00Z",
          "energy": 31.584000000730157,
          "capacity": 379.0080000087619
      },
      {
          "time_stamp_utc_ms": 1736102160000,
          "time": "2025-01-05T18:38:00Z",
          "energy": 32.351999999023974,
          "capacity": 388.2239999882877
      },
      {
          "time_stamp_utc_ms": 1736102460000,
          "time": "2025-01-05T18:43:00Z",
          "energy": 31.872000000439584,
          "capacity": 382.464000005275
      },
      {
          "time_stamp_utc_ms": 1736102760000,
          "time": "2025-01-05T18:48:00Z",
          "energy": 30.432000000029802,
          "capacity": 365.1840000003576
      },
      {
          "time_stamp_utc_ms": 1736103060000,
          "time": "2025-01-05T18:53:00Z",
          "energy": 31.39199999999255,
          "capacity": 376.7039999999106
      },
      {
          "time_stamp_utc_ms": 1736103360000,
          "time": "2025-01-05T18:58:00Z",
          "energy": 30.911999999545515,
          "capacity": 370.9439999945462
      },
      {
          "time_stamp_utc_ms": 1736103660000,
          "time": "2025-01-05T19:03:00Z",
          "energy": 30.528000000864267,
          "capacity": 366.3360000103712
      },
      {
          "time_stamp_utc_ms": 1736103960000,
          "time": "2025-01-05T19:08:00Z",
          "energy": 30.71999999973923,
          "capacity": 368.63999999687076
      },
      {
          "time_stamp_utc_ms": 1736104260000,
          "time": "2025-01-05T19:13:00Z",
          "energy": 30.911999999545515,
          "capacity": 370.9439999945462
      },
      {
          "time_stamp_utc_ms": 1736104560000,
          "time": "2025-01-05T19:18:00Z",
          "energy": 30.144000000320375,
          "capacity": 361.7280000038445
      },
      {
          "time_stamp_utc_ms": 1736104860000,
          "time": "2025-01-05T19:23:00Z",
          "energy": 31.967999999411404,
          "capacity": 383.61599999293685
      },
      {
          "time_stamp_utc_ms": 1736105160000,
          "time": "2025-01-05T19:28:00Z",
          "energy": 31.872000000439584,
          "capacity": 382.464000005275
      },
      {
          "time_stamp_utc_ms": 1736105460000,
          "time": "2025-01-05T19:33:00Z",
          "energy": 32.256000000052154,
          "capacity": 387.07200000062585
      },
      {
          "time_stamp_utc_ms": 1736105760000,
          "time": "2025-01-05T19:38:00Z",
          "energy": 32.06400000024587,
          "capacity": 384.76800000295043
      },
      {
          "time_stamp_utc_ms": 1736106060000,
          "time": "2025-01-05T19:43:00Z",
          "energy": 31.487999999895692,
          "capacity": 377.8559999987483
      },
      {
          "time_stamp_utc_ms": 1736106360000,
          "time": "2025-01-05T19:48:00Z",
          "energy": 31.6800000006333,
          "capacity": 380.1600000075996
      },
      {
          "time_stamp_utc_ms": 1736106660000,
          "time": "2025-01-05T19:53:00Z",
          "energy": 32.735999999567866,
          "capacity": 392.8319999948144
      },
      {
          "time_stamp_utc_ms": 1736106960000,
          "time": "2025-01-05T19:58:00Z",
          "energy": 31.679999999701977,
          "capacity": 380.1599999964237
      },
      {
          "time_stamp_utc_ms": 1736107260000,
          "time": "2025-01-05T20:03:00Z",
          "energy": 34.36799999978393,
          "capacity": 412.41599999740725
      },
      {
          "time_stamp_utc_ms": 1736107560000,
          "time": "2025-01-05T20:08:00Z",
          "energy": 32.44800000078976,
          "capacity": 389.3760000094772
      },
      {
          "time_stamp_utc_ms": 1736107860000,
          "time": "2025-01-05T20:13:00Z",
          "energy": 33.119999999180436,
          "capacity": 397.43999999016523
      },
      {
          "time_stamp_utc_ms": 1736108160000,
          "time": "2025-01-05T20:18:00Z",
          "energy": 34.84800000116229,
          "capacity": 418.1760000139475
      },
      {
          "time_stamp_utc_ms": 1736108460000,
          "time": "2025-01-05T20:23:00Z",
          "energy": 34.847999999299645,
          "capacity": 418.1759999915958
      },
      {
          "time_stamp_utc_ms": 1736108760000,
          "time": "2025-01-05T20:28:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1736109060000,
          "time": "2025-01-05T20:33:00Z",
          "energy": 34.27199999894947,
          "capacity": 411.2639999873937
      },
      {
          "time_stamp_utc_ms": 1736109360000,
          "time": "2025-01-05T20:38:00Z",
          "energy": 34.7519999993965,
          "capacity": 417.0239999927581
      },
      {
          "time_stamp_utc_ms": 1736109660000,
          "time": "2025-01-05T20:43:00Z",
          "energy": 34.94400000106543,
          "capacity": 419.3280000127852
      },
      {
          "time_stamp_utc_ms": 1736109960000,
          "time": "2025-01-05T20:48:00Z",
          "energy": 36.76800000015646,
          "capacity": 441.21600000187755
      },
      {
          "time_stamp_utc_ms": 1736110260000,
          "time": "2025-01-05T20:53:00Z",
          "energy": 35.42399999964982,
          "capacity": 425.0879999957979
      },
      {
          "time_stamp_utc_ms": 1736110560000,
          "time": "2025-01-05T20:58:00Z",
          "energy": 37.05600000079721,
          "capacity": 444.6720000095666
      },
      {
          "time_stamp_utc_ms": 1736110860000,
          "time": "2025-01-05T21:03:00Z",
          "energy": 37.72799999918789,
          "capacity": 452.73599999025464
      },
      {
          "time_stamp_utc_ms": 1736111160000,
          "time": "2025-01-05T21:08:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1736111460000,
          "time": "2025-01-05T21:13:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1736111760000,
          "time": "2025-01-05T21:18:00Z",
          "energy": 38.49599999934435,
          "capacity": 461.95199999213224
      },
      {
          "time_stamp_utc_ms": 1736112060000,
          "time": "2025-01-05T21:23:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1736112360000,
          "time": "2025-01-05T21:28:00Z",
          "energy": 40.2240000013262,
          "capacity": 482.68800001591444
      },
      {
          "time_stamp_utc_ms": 1736112660000,
          "time": "2025-01-05T21:33:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1736112960000,
          "time": "2025-01-05T21:38:00Z",
          "energy": 40.51200000010431,
          "capacity": 486.14400000125175
      },
      {
          "time_stamp_utc_ms": 1736113260000,
          "time": "2025-01-05T21:43:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1736113560000,
          "time": "2025-01-05T21:48:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1736113860000,
          "time": "2025-01-05T21:53:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1736114160000,
          "time": "2025-01-05T21:58:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1736114460000,
          "time": "2025-01-05T22:03:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1736114760000,
          "time": "2025-01-05T22:08:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1736115060000,
          "time": "2025-01-05T22:13:00Z",
          "energy": 38.68800000101328,
          "capacity": 464.25600001215935
      },
      {
          "time_stamp_utc_ms": 1736115360000,
          "time": "2025-01-05T22:18:00Z",
          "energy": 39.74399999901652,
          "capacity": 476.92799998819834
      },
      {
          "time_stamp_utc_ms": 1736115660000,
          "time": "2025-01-05T22:23:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1736115960000,
          "time": "2025-01-05T22:28:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1736116260000,
          "time": "2025-01-05T22:33:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1736116560000,
          "time": "2025-01-05T22:38:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1736116860000,
          "time": "2025-01-05T22:43:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1736117160000,
          "time": "2025-01-05T22:48:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1736117460000,
          "time": "2025-01-05T22:53:00Z",
          "energy": 39.74400000087917,
          "capacity": 476.92800001055
      },
      {
          "time_stamp_utc_ms": 1736117760000,
          "time": "2025-01-05T22:58:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1736118060000,
          "time": "2025-01-05T23:03:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1736118360000,
          "time": "2025-01-05T23:08:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1736118660000,
          "time": "2025-01-05T23:13:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1736118960000,
          "time": "2025-01-05T23:18:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1736119260000,
          "time": "2025-01-05T23:23:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1736119560000,
          "time": "2025-01-05T23:28:00Z",
          "energy": 38.97599999886006,
          "capacity": 467.7119999863208
      },
      {
          "time_stamp_utc_ms": 1736119860000,
          "time": "2025-01-05T23:33:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1736120160000,
          "time": "2025-01-05T23:38:00Z",
          "energy": 41.088000000454485,
          "capacity": 493.0560000054538
      },
      {
          "time_stamp_utc_ms": 1736120460000,
          "time": "2025-01-05T23:43:00Z",
          "energy": 40.8960000006482,
          "capacity": 490.7520000077784
      },
      {
          "time_stamp_utc_ms": 1736120760000,
          "time": "2025-01-05T23:48:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1736121060000,
          "time": "2025-01-05T23:53:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1736121360000,
          "time": "2025-01-05T23:58:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1736121660000,
          "time": "2025-01-06T00:03:00Z",
          "energy": 42.623999998904765,
          "capacity": 511.4879999868572
      },
      {
          "time_stamp_utc_ms": 1736121960000,
          "time": "2025-01-06T00:08:00Z",
          "energy": 40.80000000074506,
          "capacity": 489.6000000089407
      },
      {
          "time_stamp_utc_ms": 1736122260000,
          "time": "2025-01-06T00:13:00Z",
          "energy": 42.33600000105798,
          "capacity": 508.0320000126958
      },
      {
          "time_stamp_utc_ms": 1736122560000,
          "time": "2025-01-06T00:18:00Z",
          "energy": 41.75999999884516,
          "capacity": 501.119999986142
      },
      {
          "time_stamp_utc_ms": 1736122860000,
          "time": "2025-01-06T00:23:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1736123160000,
          "time": "2025-01-06T00:28:00Z",
          "energy": 42.14399999845773,
          "capacity": 505.7279999814928
      },
      {
          "time_stamp_utc_ms": 1736123460000,
          "time": "2025-01-06T00:33:00Z",
          "energy": 41.76000000163913,
          "capacity": 501.12000001966953
      },
      {
          "time_stamp_utc_ms": 1736123760000,
          "time": "2025-01-06T00:38:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1736124060000,
          "time": "2025-01-06T00:43:00Z",
          "energy": 42.72000000067055,
          "capacity": 512.6400000080466
      },
      {
          "time_stamp_utc_ms": 1736124360000,
          "time": "2025-01-06T00:48:00Z",
          "energy": 42.52799999900162,
          "capacity": 510.33599998801947
      },
      {
          "time_stamp_utc_ms": 1736124660000,
          "time": "2025-01-06T00:53:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1736124960000,
          "time": "2025-01-06T00:58:00Z",
          "energy": 42.24000000022352,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1736125260000,
          "time": "2025-01-06T01:03:00Z",
          "energy": 41.95200000051409,
          "capacity": 503.42400000616914
      },
      {
          "time_stamp_utc_ms": 1736125560000,
          "time": "2025-01-06T01:08:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1736125860000,
          "time": "2025-01-06T01:13:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1736126160000,
          "time": "2025-01-06T01:18:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1736126460000,
          "time": "2025-01-06T01:23:00Z",
          "energy": 43.00799999944866,
          "capacity": 516.0959999933839
      },
      {
          "time_stamp_utc_ms": 1736126760000,
          "time": "2025-01-06T01:28:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1736127060000,
          "time": "2025-01-06T01:33:00Z",
          "energy": 42.24000000115484,
          "capacity": 506.8800000138581
      },
      {
          "time_stamp_utc_ms": 1736127360000,
          "time": "2025-01-06T01:38:00Z",
          "energy": 43.007999998517334,
          "capacity": 516.095999982208
      },
      {
          "time_stamp_utc_ms": 1736127660000,
          "time": "2025-01-06T01:43:00Z",
          "energy": 40.992000001482666,
          "capacity": 491.90400001779204
      },
      {
          "time_stamp_utc_ms": 1736127960000,
          "time": "2025-01-06T01:48:00Z",
          "energy": 43.39199999906123,
          "capacity": 520.7039999887347
      },
      {
          "time_stamp_utc_ms": 1736128260000,
          "time": "2025-01-06T01:53:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1736128560000,
          "time": "2025-01-06T01:58:00Z",
          "energy": 42.24000000022352,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1736128860000,
          "time": "2025-01-06T02:03:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1736129160000,
          "time": "2025-01-06T02:08:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1736129460000,
          "time": "2025-01-06T02:13:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1736129760000,
          "time": "2025-01-06T02:18:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1736130060000,
          "time": "2025-01-06T02:23:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1736130360000,
          "time": "2025-01-06T02:28:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1736130660000,
          "time": "2025-01-06T02:33:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1736130960000,
          "time": "2025-01-06T02:38:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1736131260000,
          "time": "2025-01-06T02:43:00Z",
          "energy": 42.91200000140816,
          "capacity": 514.9440000168979
      },
      {
          "time_stamp_utc_ms": 1736131560000,
          "time": "2025-01-06T02:48:00Z",
          "energy": 41.951999998651445,
          "capacity": 503.4239999838174
      },
      {
          "time_stamp_utc_ms": 1736131860000,
          "time": "2025-01-06T02:53:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1736132160000,
          "time": "2025-01-06T02:58:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1736132460000,
          "time": "2025-01-06T03:03:00Z",
          "energy": 42.24000000022352,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1736132760000,
          "time": "2025-01-06T03:08:00Z",
          "energy": 42.52799999900162,
          "capacity": 510.33599998801947
      },
      {
          "time_stamp_utc_ms": 1736133060000,
          "time": "2025-01-06T03:13:00Z",
          "energy": 42.24000000022352,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1736133360000,
          "time": "2025-01-06T03:18:00Z",
          "energy": 42.24000000022352,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1736133660000,
          "time": "2025-01-06T03:23:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1736133960000,
          "time": "2025-01-06T03:28:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1736134260000,
          "time": "2025-01-06T03:33:00Z",
          "energy": 42.72000000067055,
          "capacity": 512.6400000080466
      },
      {
          "time_stamp_utc_ms": 1736134560000,
          "time": "2025-01-06T03:38:00Z",
          "energy": 44.831999998539686,
          "capacity": 537.9839999824762
      },
      {
          "time_stamp_utc_ms": 1736134860000,
          "time": "2025-01-06T03:43:00Z",
          "energy": 42.43200000189245,
          "capacity": 509.18400002270937
      },
      {
          "time_stamp_utc_ms": 1736135160000,
          "time": "2025-01-06T03:48:00Z",
          "energy": 42.43199999909848,
          "capacity": 509.18399998918176
      },
      {
          "time_stamp_utc_ms": 1736135460000,
          "time": "2025-01-06T03:53:00Z",
          "energy": 42.81599999871105,
          "capacity": 513.7919999845326
      },
      {
          "time_stamp_utc_ms": 1736135760000,
          "time": "2025-01-06T03:58:00Z",
          "energy": 42.1440000012517,
          "capacity": 505.72800001502037
      },
      {
          "time_stamp_utc_ms": 1736136060000,
          "time": "2025-01-06T04:03:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1736136360000,
          "time": "2025-01-06T04:08:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1736136660000,
          "time": "2025-01-06T04:13:00Z",
          "energy": 42.52800000086427,
          "capacity": 510.3360000103712
      },
      {
          "time_stamp_utc_ms": 1736136960000,
          "time": "2025-01-06T04:18:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1736137260000,
          "time": "2025-01-06T04:23:00Z",
          "energy": 42.43199999909848,
          "capacity": 509.18399998918176
      },
      {
          "time_stamp_utc_ms": 1736137560000,
          "time": "2025-01-06T04:28:00Z",
          "energy": 43.39200000092387,
          "capacity": 520.7040000110865
      },
      {
          "time_stamp_utc_ms": 1736137860000,
          "time": "2025-01-06T04:33:00Z",
          "energy": 42.144000000320375,
          "capacity": 505.72800000384456
      },
      {
          "time_stamp_utc_ms": 1736138160000,
          "time": "2025-01-06T04:38:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1736138460000,
          "time": "2025-01-06T04:43:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1736138760000,
          "time": "2025-01-06T04:48:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1736139060000,
          "time": "2025-01-06T04:53:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1736139360000,
          "time": "2025-01-06T04:58:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1736139660000,
          "time": "2025-01-06T05:03:00Z",
          "energy": 42.52800000086427,
          "capacity": 510.3360000103712
      },
      {
          "time_stamp_utc_ms": 1736139960000,
          "time": "2025-01-06T05:08:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1736140260000,
          "time": "2025-01-06T05:13:00Z",
          "energy": 43.00799999944866,
          "capacity": 516.0959999933839
      },
      {
          "time_stamp_utc_ms": 1736140560000,
          "time": "2025-01-06T05:18:00Z",
          "energy": 43.488000000827014,
          "capacity": 521.8560000099242
      },
      {
          "time_stamp_utc_ms": 1736140860000,
          "time": "2025-01-06T05:23:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1736141160000,
          "time": "2025-01-06T05:28:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1736141460000,
          "time": "2025-01-06T05:33:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1736141760000,
          "time": "2025-01-06T05:38:00Z",
          "energy": 43.00799999944866,
          "capacity": 516.0959999933839
      },
      {
          "time_stamp_utc_ms": 1736142060000,
          "time": "2025-01-06T05:43:00Z",
          "energy": 42.72000000067055,
          "capacity": 512.6400000080466
      },
      {
          "time_stamp_utc_ms": 1736142360000,
          "time": "2025-01-06T05:48:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1736142660000,
          "time": "2025-01-06T05:53:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1736142960000,
          "time": "2025-01-06T05:58:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1736143260000,
          "time": "2025-01-06T06:03:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1736143560000,
          "time": "2025-01-06T06:08:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1736143860000,
          "time": "2025-01-06T06:13:00Z",
          "energy": 43.96800000034273,
          "capacity": 527.6160000041127
      },
      {
          "time_stamp_utc_ms": 1736144160000,
          "time": "2025-01-06T06:18:00Z",
          "energy": 42.239999999292195,
          "capacity": 506.87999999150634
      },
      {
          "time_stamp_utc_ms": 1736144460000,
          "time": "2025-01-06T06:23:00Z",
          "energy": 43.20000000111759,
          "capacity": 518.400000013411
      },
      {
          "time_stamp_utc_ms": 1736144760000,
          "time": "2025-01-06T06:28:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1736145060000,
          "time": "2025-01-06T06:33:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1736145360000,
          "time": "2025-01-06T06:38:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1736145660000,
          "time": "2025-01-06T06:43:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1736145960000,
          "time": "2025-01-06T06:48:00Z",
          "energy": 42.432000000961125,
          "capacity": 509.1840000115335
      },
      {
          "time_stamp_utc_ms": 1736146260000,
          "time": "2025-01-06T06:53:00Z",
          "energy": 43.58399999886751,
          "capacity": 523.0079999864101
      },
      {
          "time_stamp_utc_ms": 1736146560000,
          "time": "2025-01-06T06:58:00Z",
          "energy": 42.04800000041723,
          "capacity": 504.57600000500685
      },
      {
          "time_stamp_utc_ms": 1736146860000,
          "time": "2025-01-06T07:03:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1736147160000,
          "time": "2025-01-06T07:08:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1736147460000,
          "time": "2025-01-06T07:13:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1736147760000,
          "time": "2025-01-06T07:18:00Z",
          "energy": 42.81600000150502,
          "capacity": 513.7920000180602
      },
      {
          "time_stamp_utc_ms": 1736148060000,
          "time": "2025-01-06T07:23:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1736148360000,
          "time": "2025-01-06T07:28:00Z",
          "energy": 41.47200000099838,
          "capacity": 497.6640000119806
      },
      {
          "time_stamp_utc_ms": 1736148660000,
          "time": "2025-01-06T07:33:00Z",
          "energy": 44.83199999947101,
          "capacity": 537.9839999936521
      },
      {
          "time_stamp_utc_ms": 1736148960000,
          "time": "2025-01-06T07:38:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1736149260000,
          "time": "2025-01-06T07:43:00Z",
          "energy": 42.816000000573695,
          "capacity": 513.7920000068843
      },
      {
          "time_stamp_utc_ms": 1736149560000,
          "time": "2025-01-06T07:48:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1736149860000,
          "time": "2025-01-06T07:53:00Z",
          "energy": 42.43199999909848,
          "capacity": 509.18399998918176
      },
      {
          "time_stamp_utc_ms": 1736150160000,
          "time": "2025-01-06T07:58:00Z",
          "energy": 42.62400000076741,
          "capacity": 511.4880000092089
      },
      {
          "time_stamp_utc_ms": 1736150460000,
          "time": "2025-01-06T08:03:00Z",
          "energy": 42.62399999983609,
          "capacity": 511.4879999980331
      },
      {
          "time_stamp_utc_ms": 1736150760000,
          "time": "2025-01-06T08:08:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1736151060000,
          "time": "2025-01-06T08:13:00Z",
          "energy": 42.04800000041723,
          "capacity": 504.57600000500685
      },
      {
          "time_stamp_utc_ms": 1736151360000,
          "time": "2025-01-06T08:18:00Z",
          "energy": 42.911999999545515,
          "capacity": 514.9439999945462
      },
      {
          "time_stamp_utc_ms": 1736151660000,
          "time": "2025-01-06T08:23:00Z",
          "energy": 41.47200000099838,
          "capacity": 497.6640000119806
      },
      {
          "time_stamp_utc_ms": 1736151960000,
          "time": "2025-01-06T08:28:00Z",
          "energy": 42.52799999900162,
          "capacity": 510.33599998801947
      },
      {
          "time_stamp_utc_ms": 1736152260000,
          "time": "2025-01-06T08:33:00Z",
          "energy": 41.760000000707805,
          "capacity": 501.1200000084937
      },
      {
          "time_stamp_utc_ms": 1736152560000,
          "time": "2025-01-06T08:38:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1736152860000,
          "time": "2025-01-06T08:43:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1736153160000,
          "time": "2025-01-06T08:48:00Z",
          "energy": 41.95200000051409,
          "capacity": 503.42400000616914
      },
      {
          "time_stamp_utc_ms": 1736153460000,
          "time": "2025-01-06T08:53:00Z",
          "energy": 42.24000000022352,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1736153760000,
          "time": "2025-01-06T08:58:00Z",
          "energy": 43.00799999944866,
          "capacity": 516.0959999933839
      },
      {
          "time_stamp_utc_ms": 1736154060000,
          "time": "2025-01-06T09:03:00Z",
          "energy": 43.29600000102073,
          "capacity": 519.5520000122488
      },
      {
          "time_stamp_utc_ms": 1736154360000,
          "time": "2025-01-06T09:08:00Z",
          "energy": 43.58399999886751,
          "capacity": 523.0079999864101
      },
      {
          "time_stamp_utc_ms": 1736154660000,
          "time": "2025-01-06T09:13:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1736154960000,
          "time": "2025-01-06T09:18:00Z",
          "energy": 42.52800000086427,
          "capacity": 510.3360000103712
      },
      {
          "time_stamp_utc_ms": 1736155260000,
          "time": "2025-01-06T09:23:00Z",
          "energy": 43.19999999925494,
          "capacity": 518.3999999910593
      },
      {
          "time_stamp_utc_ms": 1736155560000,
          "time": "2025-01-06T09:28:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1736155860000,
          "time": "2025-01-06T09:33:00Z",
          "energy": 43.488000000827014,
          "capacity": 521.8560000099242
      },
      {
          "time_stamp_utc_ms": 1736156160000,
          "time": "2025-01-06T09:38:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1736156460000,
          "time": "2025-01-06T09:43:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1736156760000,
          "time": "2025-01-06T09:48:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1736157060000,
          "time": "2025-01-06T09:53:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1736157360000,
          "time": "2025-01-06T09:58:00Z",
          "energy": 45.791999999433756,
          "capacity": 549.5039999932052
      },
      {
          "time_stamp_utc_ms": 1736157660000,
          "time": "2025-01-06T10:03:00Z",
          "energy": 47.23200000077486,
          "capacity": 566.7840000092983
      },
      {
          "time_stamp_utc_ms": 1736157960000,
          "time": "2025-01-06T10:08:00Z",
          "energy": 47.61599999945611,
          "capacity": 571.3919999934733
      },
      {
          "time_stamp_utc_ms": 1736158260000,
          "time": "2025-01-06T10:13:00Z",
          "energy": 47.32799999974668,
          "capacity": 567.9359999969602
      },
      {
          "time_stamp_utc_ms": 1736158560000,
          "time": "2025-01-06T10:18:00Z",
          "energy": 48.864000000059605,
          "capacity": 586.3680000007153
      },
      {
          "time_stamp_utc_ms": 1736158860000,
          "time": "2025-01-06T10:23:00Z",
          "energy": 47.52000000048429,
          "capacity": 570.2400000058115
      },
      {
          "time_stamp_utc_ms": 1736159160000,
          "time": "2025-01-06T10:28:00Z",
          "energy": 48.28799999970943,
          "capacity": 579.4559999965132
      },
      {
          "time_stamp_utc_ms": 1736159460000,
          "time": "2025-01-06T10:33:00Z",
          "energy": 48.28799999970943,
          "capacity": 579.4559999965132
      },
      {
          "time_stamp_utc_ms": 1736159760000,
          "time": "2025-01-06T10:38:00Z",
          "energy": 48.0,
          "capacity": 576.0000000000001
      },
      {
          "time_stamp_utc_ms": 1736160060000,
          "time": "2025-01-06T10:43:00Z",
          "energy": 48.480000000447035,
          "capacity": 581.7600000053644
      },
      {
          "time_stamp_utc_ms": 1736160360000,
          "time": "2025-01-06T10:48:00Z",
          "energy": 49.44000000040978,
          "capacity": 593.2800000049175
      },
      {
          "time_stamp_utc_ms": 1736160660000,
          "time": "2025-01-06T10:53:00Z",
          "energy": 49.05599999986589,
          "capacity": 588.6719999983907
      },
      {
          "time_stamp_utc_ms": 1736160960000,
          "time": "2025-01-06T10:58:00Z",
          "energy": 49.631999999284744,
          "capacity": 595.5839999914169
      },
      {
          "time_stamp_utc_ms": 1736161260000,
          "time": "2025-01-06T11:03:00Z",
          "energy": 50.208000000566244,
          "capacity": 602.4960000067949
      },
      {
          "time_stamp_utc_ms": 1736161560000,
          "time": "2025-01-06T11:08:00Z",
          "energy": 50.39999999944121,
          "capacity": 604.7999999932946
      },
      {
          "time_stamp_utc_ms": 1736161860000,
          "time": "2025-01-06T11:13:00Z",
          "energy": 50.30400000046939,
          "capacity": 603.6480000056326
      },
      {
          "time_stamp_utc_ms": 1736162160000,
          "time": "2025-01-06T11:18:00Z",
          "energy": 52.22399999946356,
          "capacity": 626.6879999935627
      },
      {
          "time_stamp_utc_ms": 1736162460000,
          "time": "2025-01-06T11:23:00Z",
          "energy": 51.264000001363456,
          "capacity": 615.1680000163616
      },
      {
          "time_stamp_utc_ms": 1736162760000,
          "time": "2025-01-06T11:28:00Z",
          "energy": 50.975999999791384,
          "capacity": 611.7119999974966
      },
      {
          "time_stamp_utc_ms": 1736163060000,
          "time": "2025-01-06T11:33:00Z",
          "energy": 50.59199999924749,
          "capacity": 607.10399999097
      },
      {
          "time_stamp_utc_ms": 1736163360000,
          "time": "2025-01-06T11:38:00Z",
          "energy": 50.7839999999851,
          "capacity": 609.4079999998212
      },
      {
          "time_stamp_utc_ms": 1736163660000,
          "time": "2025-01-06T11:43:00Z",
          "energy": 51.45600000023842,
          "capacity": 617.472000002861
      },
      {
          "time_stamp_utc_ms": 1736163960000,
          "time": "2025-01-06T11:48:00Z",
          "energy": 51.743999999947846,
          "capacity": 620.9279999993742
      },
      {
          "time_stamp_utc_ms": 1736164260000,
          "time": "2025-01-06T11:53:00Z",
          "energy": 50.59199999924749,
          "capacity": 607.10399999097
      },
      {
          "time_stamp_utc_ms": 1736164560000,
          "time": "2025-01-06T11:58:00Z",
          "energy": 53.95199999958277,
          "capacity": 647.4239999949932
      },
      {
          "time_stamp_utc_ms": 1736164860000,
          "time": "2025-01-06T12:03:00Z",
          "energy": 52.89600000157952,
          "capacity": 634.7520000189544
      },
      {
          "time_stamp_utc_ms": 1736165160000,
          "time": "2025-01-06T12:08:00Z",
          "energy": 53.183999999426305,
          "capacity": 638.2079999931158
      },
      {
          "time_stamp_utc_ms": 1736165460000,
          "time": "2025-01-06T12:13:00Z",
          "energy": 52.99199999962002,
          "capacity": 635.9039999954404
      },
      {
          "time_stamp_utc_ms": 1736165760000,
          "time": "2025-01-06T12:18:00Z",
          "energy": 53.66400000080466,
          "capacity": 643.9680000096561
      },
      {
          "time_stamp_utc_ms": 1736166060000,
          "time": "2025-01-06T12:23:00Z",
          "energy": 54.623999998904765,
          "capacity": 655.4879999868572
      },
      {
          "time_stamp_utc_ms": 1736166360000,
          "time": "2025-01-06T12:28:00Z",
          "energy": 53.28000000026077,
          "capacity": 639.3600000031292
      },
      {
          "time_stamp_utc_ms": 1736166660000,
          "time": "2025-01-06T12:33:00Z",
          "energy": 53.5679999999702,
          "capacity": 642.8159999996424
      },
      {
          "time_stamp_utc_ms": 1736166960000,
          "time": "2025-01-06T12:38:00Z",
          "energy": 53.95199999958277,
          "capacity": 647.4239999949932
      },
      {
          "time_stamp_utc_ms": 1736167260000,
          "time": "2025-01-06T12:43:00Z",
          "energy": 54.24000000115484,
          "capacity": 650.8800000138581
      },
      {
          "time_stamp_utc_ms": 1736167560000,
          "time": "2025-01-06T12:48:00Z",
          "energy": 53.66399999894202,
          "capacity": 643.9679999873043
      },
      {
          "time_stamp_utc_ms": 1736167860000,
          "time": "2025-01-06T12:53:00Z",
          "energy": 53.47200000099838,
          "capacity": 641.6640000119806
      },
      {
          "time_stamp_utc_ms": 1736168160000,
          "time": "2025-01-06T12:58:00Z",
          "energy": 54.14399999938905,
          "capacity": 649.7279999926686
      },
      {
          "time_stamp_utc_ms": 1736168460000,
          "time": "2025-01-06T13:03:00Z",
          "energy": 53.66399999987334,
          "capacity": 643.9679999984801
      },
      {
          "time_stamp_utc_ms": 1736168760000,
          "time": "2025-01-06T13:08:00Z",
          "energy": 51.93600000068545,
          "capacity": 623.2320000082256
      },
      {
          "time_stamp_utc_ms": 1736169060000,
          "time": "2025-01-06T13:13:00Z",
          "energy": 54.33599999919534,
          "capacity": 652.031999990344
      },
      {
          "time_stamp_utc_ms": 1736169360000,
          "time": "2025-01-06T13:18:00Z",
          "energy": 53.18400000035763,
          "capacity": 638.2080000042915
      },
      {
          "time_stamp_utc_ms": 1736169660000,
          "time": "2025-01-06T13:23:00Z",
          "energy": 53.95199999958277,
          "capacity": 647.4239999949932
      },
      {
          "time_stamp_utc_ms": 1736169960000,
          "time": "2025-01-06T13:28:00Z",
          "energy": 54.4320000000298,
          "capacity": 653.1840000003577
      },
      {
          "time_stamp_utc_ms": 1736170260000,
          "time": "2025-01-06T13:33:00Z",
          "energy": 53.5679999999702,
          "capacity": 642.8159999996424
      },
      {
          "time_stamp_utc_ms": 1736170560000,
          "time": "2025-01-06T13:38:00Z",
          "energy": 54.33600000105798,
          "capacity": 652.0320000126958
      },
      {
          "time_stamp_utc_ms": 1736170860000,
          "time": "2025-01-06T13:43:00Z",
          "energy": 53.37600000016391,
          "capacity": 640.512000001967
      },
      {
          "time_stamp_utc_ms": 1736171160000,
          "time": "2025-01-06T13:48:00Z",
          "energy": 51.74399999901652,
          "capacity": 620.9279999881983
      },
      {
          "time_stamp_utc_ms": 1736171460000,
          "time": "2025-01-06T13:53:00Z",
          "energy": 51.55200000014156,
          "capacity": 618.6240000016987
      },
      {
          "time_stamp_utc_ms": 1736171760000,
          "time": "2025-01-06T13:58:00Z",
          "energy": 53.56800000090152,
          "capacity": 642.8160000108184
      },
      {
          "time_stamp_utc_ms": 1736172060000,
          "time": "2025-01-06T14:03:00Z",
          "energy": 52.127999999560416,
          "capacity": 625.535999994725
      },
      {
          "time_stamp_utc_ms": 1736172360000,
          "time": "2025-01-06T14:08:00Z",
          "energy": 51.743999999947846,
          "capacity": 620.9279999993742
      },
      {
          "time_stamp_utc_ms": 1736172660000,
          "time": "2025-01-06T14:13:00Z",
          "energy": 52.03199999965727,
          "capacity": 624.3839999958873
      },
      {
          "time_stamp_utc_ms": 1736172960000,
          "time": "2025-01-06T14:18:00Z",
          "energy": 49.44000000040978,
          "capacity": 593.2800000049175
      },
      {
          "time_stamp_utc_ms": 1736173260000,
          "time": "2025-01-06T14:23:00Z",
          "energy": 50.11199999973178,
          "capacity": 601.3439999967815
      },
      {
          "time_stamp_utc_ms": 1736173560000,
          "time": "2025-01-06T14:28:00Z",
          "energy": 49.2480000006035,
          "capacity": 590.9760000072421
      },
      {
          "time_stamp_utc_ms": 1736173860000,
          "time": "2025-01-06T14:33:00Z",
          "energy": 49.91999999899417,
          "capacity": 599.0399999879301
      },
      {
          "time_stamp_utc_ms": 1736174160000,
          "time": "2025-01-06T14:38:00Z",
          "energy": 49.72800000011921,
          "capacity": 596.7360000014306
      },
      {
          "time_stamp_utc_ms": 1736174460000,
          "time": "2025-01-06T14:43:00Z",
          "energy": 48.28800000064075,
          "capacity": 579.456000007689
      },
      {
          "time_stamp_utc_ms": 1736174760000,
          "time": "2025-01-06T14:48:00Z",
          "energy": 47.61599999945611,
          "capacity": 571.3919999934733
      },
      {
          "time_stamp_utc_ms": 1736175060000,
          "time": "2025-01-06T14:53:00Z",
          "energy": 48.38399999961257,
          "capacity": 580.607999995351
      },
      {
          "time_stamp_utc_ms": 1736175360000,
          "time": "2025-01-06T14:58:00Z",
          "energy": 48.28800000064075,
          "capacity": 579.456000007689
      },
      {
          "time_stamp_utc_ms": 1736175690000,
          "time": "2025-01-06T15:03:00Z",
          "energy": 53.519999999552965,
          "capacity": 583.8545454496686
      },
      {
          "time_stamp_utc_ms": 1736176050000,
          "time": "2025-01-06T15:08:00Z",
          "energy": 60.0,
          "capacity": 600.0
      },
      {
          "time_stamp_utc_ms": 1736176230000,
          "time": "2025-01-06T15:13:00Z",
          "energy": 29.040000000968575,
          "capacity": 580.8000000193715
      },
      {
          "time_stamp_utc_ms": 1736176590000,
          "time": "2025-01-06T15:18:00Z",
          "energy": 59.519999999552965,
          "capacity": 595.1999999955297
      },
      {
          "time_stamp_utc_ms": 1736176860000,
          "time": "2025-01-06T15:23:00Z",
          "energy": 43.48799999989569,
          "capacity": 579.8399999986092
      },
      {
          "time_stamp_utc_ms": 1736177040000,
          "time": "2025-01-06T15:28:00Z",
          "energy": 29.472000000067055,
          "capacity": 589.4400000013411
      },
      {
          "time_stamp_utc_ms": 1736178960000,
          "time": "2025-01-06T15:58:00Z",
          "energy": 308.160000000149,
          "capacity": 577.8000000002794
      },
      {
          "time_stamp_utc_ms": 1736184180000,
          "time": "2025-01-06T17:23:00Z",
          "energy": 814.0800000000745,
          "capacity": 561.4344827586721
      },
      {
          "time_stamp_utc_ms": 1736185260000,
          "time": "2025-01-06T17:43:00Z",
          "energy": 167.04000000003725,
          "capacity": 556.8000000001241
      },
      {
          "time_stamp_utc_ms": 1736187090000,
          "time": "2025-01-06T18:13:00Z",
          "energy": 282.71999999973923,
          "capacity": 556.1704918027657
      },
      {
          "time_stamp_utc_ms": 1736187360000,
          "time": "2025-01-06T18:18:00Z",
          "energy": 40.3199999993667,
          "capacity": 537.5999999915559
      },
      {
          "time_stamp_utc_ms": 1736187660000,
          "time": "2025-01-06T18:23:00Z",
          "energy": 45.312000000849366,
          "capacity": 543.7440000101924
      },
      {
          "time_stamp_utc_ms": 1736187960000,
          "time": "2025-01-06T18:28:00Z",
          "energy": 49.631999999284744,
          "capacity": 595.5839999914169
      },
      {
          "time_stamp_utc_ms": 1736188260000,
          "time": "2025-01-06T18:33:00Z",
          "energy": 51.74400000087917,
          "capacity": 620.9280000105501
      },
      {
          "time_stamp_utc_ms": 1736188560000,
          "time": "2025-01-06T18:38:00Z",
          "energy": 51.455999999307096,
          "capacity": 617.4719999916852
      },
      {
          "time_stamp_utc_ms": 1736188860000,
          "time": "2025-01-06T18:43:00Z",
          "energy": 50.40000000037253,
          "capacity": 604.8000000044703
      },
      {
          "time_stamp_utc_ms": 1736189160000,
          "time": "2025-01-06T18:48:00Z",
          "energy": 50.7839999999851,
          "capacity": 609.4079999998212
      },
      {
          "time_stamp_utc_ms": 1736189460000,
          "time": "2025-01-06T18:53:00Z",
          "energy": 48.47999999951571,
          "capacity": 581.7599999941887
      },
      {
          "time_stamp_utc_ms": 1736189760000,
          "time": "2025-01-06T18:58:00Z",
          "energy": 48.28800000064075,
          "capacity": 579.456000007689
      },
      {
          "time_stamp_utc_ms": 1736190060000,
          "time": "2025-01-06T19:03:00Z",
          "energy": 50.20799999963492,
          "capacity": 602.4959999956192
      },
      {
          "time_stamp_utc_ms": 1736190360000,
          "time": "2025-01-06T19:08:00Z",
          "energy": 50.303999999538064,
          "capacity": 603.6479999944569
      },
      {
          "time_stamp_utc_ms": 1736190660000,
          "time": "2025-01-06T19:13:00Z",
          "energy": 47.135999999940395,
          "capacity": 565.6319999992847
      },
      {
          "time_stamp_utc_ms": 1736190960000,
          "time": "2025-01-06T19:18:00Z",
          "energy": 49.34400000050664,
          "capacity": 592.1280000060798
      },
      {
          "time_stamp_utc_ms": 1736191260000,
          "time": "2025-01-06T19:23:00Z",
          "energy": 52.3199999993667,
          "capacity": 627.8399999924004
      },
      {
          "time_stamp_utc_ms": 1736191560000,
          "time": "2025-01-06T19:28:00Z",
          "energy": 52.32000000029802,
          "capacity": 627.8400000035764
      },
      {
          "time_stamp_utc_ms": 1736191860000,
          "time": "2025-01-06T19:33:00Z",
          "energy": 47.424000000581145,
          "capacity": 569.0880000069737
      },
      {
          "time_stamp_utc_ms": 1736192160000,
          "time": "2025-01-06T19:38:00Z",
          "energy": 48.28799999970943,
          "capacity": 579.4559999965132
      },
      {
          "time_stamp_utc_ms": 1736192460000,
          "time": "2025-01-06T19:43:00Z",
          "energy": 50.592000000178814,
          "capacity": 607.1040000021458
      },
      {
          "time_stamp_utc_ms": 1736192760000,
          "time": "2025-01-06T19:48:00Z",
          "energy": 47.52000000048429,
          "capacity": 570.2400000058115
      },
      {
          "time_stamp_utc_ms": 1736193060000,
          "time": "2025-01-06T19:53:00Z",
          "energy": 50.49599999934435,
          "capacity": 605.9519999921323
      },
      {
          "time_stamp_utc_ms": 1736193360000,
          "time": "2025-01-06T19:58:00Z",
          "energy": 50.40000000037253,
          "capacity": 604.8000000044703
      },
      {
          "time_stamp_utc_ms": 1736193660000,
          "time": "2025-01-06T20:03:00Z",
          "energy": 51.35999999940395,
          "capacity": 616.3199999928474
      },
      {
          "time_stamp_utc_ms": 1736193960000,
          "time": "2025-01-06T20:08:00Z",
          "energy": 48.96000000089407,
          "capacity": 587.520000010729
      },
      {
          "time_stamp_utc_ms": 1736194260000,
          "time": "2025-01-06T20:13:00Z",
          "energy": 50.59199999924749,
          "capacity": 607.10399999097
      },
      {
          "time_stamp_utc_ms": 1736194560000,
          "time": "2025-01-06T20:18:00Z",
          "energy": 49.63200000021607,
          "capacity": 595.5840000025929
      },
      {
          "time_stamp_utc_ms": 1736194860000,
          "time": "2025-01-06T20:23:00Z",
          "energy": 52.03199999965727,
          "capacity": 624.3839999958873
      },
      {
          "time_stamp_utc_ms": 1736195160000,
          "time": "2025-01-06T20:28:00Z",
          "energy": 49.63200000021607,
          "capacity": 595.5840000025929
      },
      {
          "time_stamp_utc_ms": 1736195460000,
          "time": "2025-01-06T20:33:00Z",
          "energy": 48.95999999996275,
          "capacity": 587.519999999553
      },
      {
          "time_stamp_utc_ms": 1736195760000,
          "time": "2025-01-06T20:38:00Z",
          "energy": 50.208000000566244,
          "capacity": 602.4960000067949
      },
      {
          "time_stamp_utc_ms": 1736196060000,
          "time": "2025-01-06T20:43:00Z",
          "energy": 49.5359999993816,
          "capacity": 594.4319999925792
      },
      {
          "time_stamp_utc_ms": 1736196360000,
          "time": "2025-01-06T20:48:00Z",
          "energy": 50.01599999982864,
          "capacity": 600.1919999979438
      },
      {
          "time_stamp_utc_ms": 1736196660000,
          "time": "2025-01-06T20:53:00Z",
          "energy": 51.264000000432134,
          "capacity": 615.1680000051856
      },
      {
          "time_stamp_utc_ms": 1736196960000,
          "time": "2025-01-06T20:58:00Z",
          "energy": 50.39999999944121,
          "capacity": 604.7999999932946
      },
      {
          "time_stamp_utc_ms": 1736197260000,
          "time": "2025-01-06T21:03:00Z",
          "energy": 52.320000001229346,
          "capacity": 627.8400000147521
      },
      {
          "time_stamp_utc_ms": 1736197560000,
          "time": "2025-01-06T21:08:00Z",
          "energy": 52.41599999926984,
          "capacity": 628.9919999912381
      },
      {
          "time_stamp_utc_ms": 1736197860000,
          "time": "2025-01-06T21:13:00Z",
          "energy": 52.22399999946356,
          "capacity": 626.6879999935627
      },
      {
          "time_stamp_utc_ms": 1736198160000,
          "time": "2025-01-06T21:18:00Z",
          "energy": 52.51200000103563,
          "capacity": 630.1440000124276
      },
      {
          "time_stamp_utc_ms": 1736198460000,
          "time": "2025-01-06T21:23:00Z",
          "energy": 52.03199999872595,
          "capacity": 624.3839999847114
      },
      {
          "time_stamp_utc_ms": 1736198760000,
          "time": "2025-01-06T21:28:00Z",
          "energy": 52.51200000103563,
          "capacity": 630.1440000124276
      },
      {
          "time_stamp_utc_ms": 1736199060000,
          "time": "2025-01-06T21:33:00Z",
          "energy": 51.83999999985099,
          "capacity": 622.0799999982119
      },
      {
          "time_stamp_utc_ms": 1736199360000,
          "time": "2025-01-06T21:38:00Z",
          "energy": 51.07200000062585,
          "capacity": 612.8640000075102
      },
      {
          "time_stamp_utc_ms": 1736199660000,
          "time": "2025-01-06T21:43:00Z",
          "energy": 51.743999999947846,
          "capacity": 620.9279999993742
      },
      {
          "time_stamp_utc_ms": 1736199960000,
          "time": "2025-01-06T21:48:00Z",
          "energy": 51.93599999975413,
          "capacity": 623.2319999970496
      },
      {
          "time_stamp_utc_ms": 1736200260000,
          "time": "2025-01-06T21:53:00Z",
          "energy": 50.303999999538064,
          "capacity": 603.6479999944569
      },
      {
          "time_stamp_utc_ms": 1736200560000,
          "time": "2025-01-06T21:58:00Z",
          "energy": 52.032000000588596,
          "capacity": 624.3840000070633
      },
      {
          "time_stamp_utc_ms": 1736200860000,
          "time": "2025-01-06T22:03:00Z",
          "energy": 50.687999999150634,
          "capacity": 608.2559999898077
      },
      {
          "time_stamp_utc_ms": 1736201160000,
          "time": "2025-01-06T22:08:00Z",
          "energy": 51.6480000000447,
          "capacity": 619.7760000005364
      },
      {
          "time_stamp_utc_ms": 1736201460000,
          "time": "2025-01-06T22:13:00Z",
          "energy": 50.592000000178814,
          "capacity": 607.1040000021458
      },
      {
          "time_stamp_utc_ms": 1736201760000,
          "time": "2025-01-06T22:18:00Z",
          "energy": 50.39999999944121,
          "capacity": 604.7999999932946
      },
      {
          "time_stamp_utc_ms": 1736202060000,
          "time": "2025-01-06T22:23:00Z",
          "energy": 50.1120000006631,
          "capacity": 601.3440000079572
      },
      {
          "time_stamp_utc_ms": 1736202360000,
          "time": "2025-01-06T22:28:00Z",
          "energy": 50.87999999988824,
          "capacity": 610.5599999986589
      },
      {
          "time_stamp_utc_ms": 1736202660000,
          "time": "2025-01-06T22:33:00Z",
          "energy": 49.34400000050664,
          "capacity": 592.1280000060798
      },
      {
          "time_stamp_utc_ms": 1736202960000,
          "time": "2025-01-06T22:38:00Z",
          "energy": 49.34399999957532,
          "capacity": 592.1279999949038
      },
      {
          "time_stamp_utc_ms": 1736203260000,
          "time": "2025-01-06T22:43:00Z",
          "energy": 49.536000000312924,
          "capacity": 594.4320000037552
      },
      {
          "time_stamp_utc_ms": 1736203560000,
          "time": "2025-01-06T22:48:00Z",
          "energy": 49.15199999976903,
          "capacity": 589.8239999972284
      },
      {
          "time_stamp_utc_ms": 1736203860000,
          "time": "2025-01-06T22:53:00Z",
          "energy": 50.20799999963492,
          "capacity": 602.4959999956192
      },
      {
          "time_stamp_utc_ms": 1736204160000,
          "time": "2025-01-06T22:58:00Z",
          "energy": 49.247999999672174,
          "capacity": 590.9759999960661
      },
      {
          "time_stamp_utc_ms": 1736204460000,
          "time": "2025-01-06T23:03:00Z",
          "energy": 49.05600000079721,
          "capacity": 588.6720000095667
      },
      {
          "time_stamp_utc_ms": 1736204760000,
          "time": "2025-01-06T23:08:00Z",
          "energy": 48.671999999322,
          "capacity": 584.0639999918641
      },
      {
          "time_stamp_utc_ms": 1736205060000,
          "time": "2025-01-06T23:13:00Z",
          "energy": 49.247999999672174,
          "capacity": 590.9759999960661
      },
      {
          "time_stamp_utc_ms": 1736205360000,
          "time": "2025-01-06T23:18:00Z",
          "energy": 47.712000001221895,
          "capacity": 572.5440000146627
      },
      {
          "time_stamp_utc_ms": 1736205660000,
          "time": "2025-01-06T23:23:00Z",
          "energy": 49.72800000011921,
          "capacity": 596.7360000014306
      },
      {
          "time_stamp_utc_ms": 1736205960000,
          "time": "2025-01-06T23:28:00Z",
          "energy": 47.61599999945611,
          "capacity": 571.3919999934733
      },
      {
          "time_stamp_utc_ms": 1736206260000,
          "time": "2025-01-06T23:33:00Z",
          "energy": 48.671999999322,
          "capacity": 584.0639999918641
      },
      {
          "time_stamp_utc_ms": 1736206560000,
          "time": "2025-01-06T23:38:00Z",
          "energy": 47.90400000009686,
          "capacity": 574.8480000011623
      },
      {
          "time_stamp_utc_ms": 1736206860000,
          "time": "2025-01-06T23:43:00Z",
          "energy": 47.80800000112504,
          "capacity": 573.6960000135005
      },
      {
          "time_stamp_utc_ms": 1736207160000,
          "time": "2025-01-06T23:48:00Z",
          "energy": 48.19199999887496,
          "capacity": 578.3039999864995
      },
      {
          "time_stamp_utc_ms": 1736207460000,
          "time": "2025-01-06T23:53:00Z",
          "energy": 47.040000000968575,
          "capacity": 564.4800000116229
      },
      {
          "time_stamp_utc_ms": 1736207760000,
          "time": "2025-01-06T23:58:00Z",
          "energy": 46.7519999993965,
          "capacity": 561.023999992758
      },
      {
          "time_stamp_utc_ms": 1736208060000,
          "time": "2025-01-07T00:03:00Z",
          "energy": 47.71200000029057,
          "capacity": 572.5440000034869
      },
      {
          "time_stamp_utc_ms": 1736208360000,
          "time": "2025-01-07T00:08:00Z",
          "energy": 47.808000000193715,
          "capacity": 573.6960000023246
      },
      {
          "time_stamp_utc_ms": 1736208660000,
          "time": "2025-01-07T00:13:00Z",
          "energy": 48.0,
          "capacity": 576.0000000000001
      },
      {
          "time_stamp_utc_ms": 1736208960000,
          "time": "2025-01-07T00:18:00Z",
          "energy": 48.76800000015646,
          "capacity": 585.2160000018775
      },
      {
          "time_stamp_utc_ms": 1736209260000,
          "time": "2025-01-07T00:23:00Z",
          "energy": 48.38399999961257,
          "capacity": 580.607999995351
      },
      {
          "time_stamp_utc_ms": 1736209560000,
          "time": "2025-01-07T00:28:00Z",
          "energy": 49.05599999986589,
          "capacity": 588.6719999983907
      },
      {
          "time_stamp_utc_ms": 1736209860000,
          "time": "2025-01-07T00:33:00Z",
          "energy": 48.95999999996275,
          "capacity": 587.519999999553
      },
      {
          "time_stamp_utc_ms": 1736210160000,
          "time": "2025-01-07T00:38:00Z",
          "energy": 48.47999999951571,
          "capacity": 581.7599999941887
      },
      {
          "time_stamp_utc_ms": 1736210460000,
          "time": "2025-01-07T00:43:00Z",
          "energy": 48.76800000015646,
          "capacity": 585.2160000018775
      },
      {
          "time_stamp_utc_ms": 1736210760000,
          "time": "2025-01-07T00:48:00Z",
          "energy": 49.72800000011921,
          "capacity": 596.7360000014306
      },
      {
          "time_stamp_utc_ms": 1736211060000,
          "time": "2025-01-07T00:53:00Z",
          "energy": 48.09599999990314,
          "capacity": 577.1519999988378
      },
      {
          "time_stamp_utc_ms": 1736211360000,
          "time": "2025-01-07T00:58:00Z",
          "energy": 48.67200000025332,
          "capacity": 584.0640000030398
      },
      {
          "time_stamp_utc_ms": 1736211660000,
          "time": "2025-01-07T01:03:00Z",
          "energy": 47.328000000678,
          "capacity": 567.936000008136
      },
      {
          "time_stamp_utc_ms": 1736211960000,
          "time": "2025-01-07T01:08:00Z",
          "energy": 48.191999999806285,
          "capacity": 578.3039999976755
      },
      {
          "time_stamp_utc_ms": 1736212260000,
          "time": "2025-01-07T01:13:00Z",
          "energy": 48.19199999887496,
          "capacity": 578.3039999864995
      },
      {
          "time_stamp_utc_ms": 1736212560000,
          "time": "2025-01-07T01:18:00Z",
          "energy": 48.19200000073761,
          "capacity": 578.3040000088513
      },
      {
          "time_stamp_utc_ms": 1736212860000,
          "time": "2025-01-07T01:23:00Z",
          "energy": 47.80799999926239,
          "capacity": 573.6959999911487
      },
      {
          "time_stamp_utc_ms": 1736213160000,
          "time": "2025-01-07T01:28:00Z",
          "energy": 49.05600000079721,
          "capacity": 588.6720000095667
      },
      {
          "time_stamp_utc_ms": 1736213460000,
          "time": "2025-01-07T01:33:00Z",
          "energy": 47.23199999984354,
          "capacity": 566.7839999981225
      },
      {
          "time_stamp_utc_ms": 1736213760000,
          "time": "2025-01-07T01:38:00Z",
          "energy": 49.05599999986589,
          "capacity": 588.6719999983907
      },
      {
          "time_stamp_utc_ms": 1736214060000,
          "time": "2025-01-07T01:43:00Z",
          "energy": 48.28800000064075,
          "capacity": 579.456000007689
      },
      {
          "time_stamp_utc_ms": 1736214360000,
          "time": "2025-01-07T01:48:00Z",
          "energy": 48.67200000025332,
          "capacity": 584.0640000030398
      },
      {
          "time_stamp_utc_ms": 1736214660000,
          "time": "2025-01-07T01:53:00Z",
          "energy": 48.671999999322,
          "capacity": 584.0639999918641
      },
      {
          "time_stamp_utc_ms": 1736214960000,
          "time": "2025-01-07T01:58:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1736215260000,
          "time": "2025-01-07T02:03:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1736215560000,
          "time": "2025-01-07T02:08:00Z",
          "energy": 44.6399999987334,
          "capacity": 535.6799999848008
      },
      {
          "time_stamp_utc_ms": 1736215860000,
          "time": "2025-01-07T02:13:00Z",
          "energy": 43.488000000827014,
          "capacity": 521.8560000099242
      },
      {
          "time_stamp_utc_ms": 1736216160000,
          "time": "2025-01-07T02:18:00Z",
          "energy": 43.77600000053644,
          "capacity": 525.3120000064373
      },
      {
          "time_stamp_utc_ms": 1736216460000,
          "time": "2025-01-07T02:23:00Z",
          "energy": 44.25599999912083,
          "capacity": 531.07199998945
      },
      {
          "time_stamp_utc_ms": 1736216760000,
          "time": "2025-01-07T02:28:00Z",
          "energy": 43.58400000073016,
          "capacity": 523.0080000087619
      },
      {
          "time_stamp_utc_ms": 1736217060000,
          "time": "2025-01-07T02:33:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1736217360000,
          "time": "2025-01-07T02:38:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1736217660000,
          "time": "2025-01-07T02:43:00Z",
          "energy": 43.58400000073016,
          "capacity": 523.0080000087619
      },
      {
          "time_stamp_utc_ms": 1736217960000,
          "time": "2025-01-07T02:48:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1736218260000,
          "time": "2025-01-07T02:53:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1736218560000,
          "time": "2025-01-07T02:58:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1736218860000,
          "time": "2025-01-07T03:03:00Z",
          "energy": 42.33600000105798,
          "capacity": 508.0320000126958
      },
      {
          "time_stamp_utc_ms": 1736219160000,
          "time": "2025-01-07T03:08:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1736219460000,
          "time": "2025-01-07T03:13:00Z",
          "energy": 42.62400000076741,
          "capacity": 511.4880000092089
      },
      {
          "time_stamp_utc_ms": 1736219760000,
          "time": "2025-01-07T03:18:00Z",
          "energy": 41.47199999913573,
          "capacity": 497.66399998962885
      },
      {
          "time_stamp_utc_ms": 1736220060000,
          "time": "2025-01-07T03:23:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1736220360000,
          "time": "2025-01-07T03:28:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1736220660000,
          "time": "2025-01-07T03:33:00Z",
          "energy": 42.24000000022352,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1736220960000,
          "time": "2025-01-07T03:38:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1736221260000,
          "time": "2025-01-07T03:43:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1736221560000,
          "time": "2025-01-07T03:48:00Z",
          "energy": 43.295999999158084,
          "capacity": 519.551999989897
      },
      {
          "time_stamp_utc_ms": 1736221860000,
          "time": "2025-01-07T03:53:00Z",
          "energy": 42.33600000105798,
          "capacity": 508.0320000126958
      },
      {
          "time_stamp_utc_ms": 1736222160000,
          "time": "2025-01-07T03:58:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1736222460000,
          "time": "2025-01-07T04:03:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1736222760000,
          "time": "2025-01-07T04:08:00Z",
          "energy": 33.69599999953061,
          "capacity": 404.3519999943674
      },
      {
          "time_stamp_utc_ms": 1736223060000,
          "time": "2025-01-07T04:13:00Z",
          "energy": 33.88800000026822,
          "capacity": 406.6560000032187
      },
      {
          "time_stamp_utc_ms": 1736223360000,
          "time": "2025-01-07T04:18:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1736223660000,
          "time": "2025-01-07T04:23:00Z",
          "energy": 36.191999999806285,
          "capacity": 434.3039999976754
      },
      {
          "time_stamp_utc_ms": 1736223960000,
          "time": "2025-01-07T04:28:00Z",
          "energy": 37.2480000006035,
          "capacity": 446.976000007242
      },
      {
          "time_stamp_utc_ms": 1736224260000,
          "time": "2025-01-07T04:33:00Z",
          "energy": 35.519999999552965,
          "capacity": 426.2399999946356
      },
      {
          "time_stamp_utc_ms": 1736224560000,
          "time": "2025-01-07T04:38:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1736224860000,
          "time": "2025-01-07T04:43:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1736225160000,
          "time": "2025-01-07T04:48:00Z",
          "energy": 36.0,
          "capacity": 432.0
      },
      {
          "time_stamp_utc_ms": 1736225460000,
          "time": "2025-01-07T04:53:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1736225760000,
          "time": "2025-01-07T04:58:00Z",
          "energy": 35.80799999926239,
          "capacity": 429.6959999911487
      },
      {
          "time_stamp_utc_ms": 1736226060000,
          "time": "2025-01-07T05:03:00Z",
          "energy": 36.67200000118464,
          "capacity": 440.06400001421576
      },
      {
          "time_stamp_utc_ms": 1736226360000,
          "time": "2025-01-07T05:08:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1736226660000,
          "time": "2025-01-07T05:13:00Z",
          "energy": 36.480000000447035,
          "capacity": 437.7600000053644
      },
      {
          "time_stamp_utc_ms": 1736226960000,
          "time": "2025-01-07T05:18:00Z",
          "energy": 37.5359999993816,
          "capacity": 450.4319999925792
      },
      {
          "time_stamp_utc_ms": 1736227260000,
          "time": "2025-01-07T05:23:00Z",
          "energy": 36.67200000025332,
          "capacity": 440.06400000303984
      },
      {
          "time_stamp_utc_ms": 1736227560000,
          "time": "2025-01-07T05:28:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1736227860000,
          "time": "2025-01-07T05:33:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1736228160000,
          "time": "2025-01-07T05:38:00Z",
          "energy": 37.05599999986589,
          "capacity": 444.6719999983907
      },
      {
          "time_stamp_utc_ms": 1736228460000,
          "time": "2025-01-07T05:43:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1736228760000,
          "time": "2025-01-07T05:48:00Z",
          "energy": 36.47999999951571,
          "capacity": 437.75999999418855
      },
      {
          "time_stamp_utc_ms": 1736229060000,
          "time": "2025-01-07T05:53:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1736229360000,
          "time": "2025-01-07T05:58:00Z",
          "energy": 36.95999999996275,
          "capacity": 443.51999999955297
      },
      {
          "time_stamp_utc_ms": 1736229660000,
          "time": "2025-01-07T06:03:00Z",
          "energy": 37.44000000040978,
          "capacity": 449.28000000491744
      },
      {
          "time_stamp_utc_ms": 1736229960000,
          "time": "2025-01-07T06:08:00Z",
          "energy": 37.15199999883771,
          "capacity": 445.8239999860525
      },
      {
          "time_stamp_utc_ms": 1736230260000,
          "time": "2025-01-07T06:13:00Z",
          "energy": 37.63200000114739,
          "capacity": 451.58400001376873
      },
      {
          "time_stamp_utc_ms": 1736230560000,
          "time": "2025-01-07T06:18:00Z",
          "energy": 36.47999999858439,
          "capacity": 437.7599999830127
      },
      {
          "time_stamp_utc_ms": 1736230860000,
          "time": "2025-01-07T06:23:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1736231160000,
          "time": "2025-01-07T06:28:00Z",
          "energy": 36.38399999961257,
          "capacity": 436.60799999535084
      },
      {
          "time_stamp_utc_ms": 1736231460000,
          "time": "2025-01-07T06:33:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1736231760000,
          "time": "2025-01-07T06:38:00Z",
          "energy": 38.208000000566244,
          "capacity": 458.496000006795
      },
      {
          "time_stamp_utc_ms": 1736232060000,
          "time": "2025-01-07T06:43:00Z",
          "energy": 37.247999999672174,
          "capacity": 446.9759999960661
      },
      {
          "time_stamp_utc_ms": 1736232360000,
          "time": "2025-01-07T06:48:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1736232660000,
          "time": "2025-01-07T06:53:00Z",
          "energy": 36.864000000059605,
          "capacity": 442.36800000071526
      },
      {
          "time_stamp_utc_ms": 1736232960000,
          "time": "2025-01-07T06:58:00Z",
          "energy": 37.43999999947846,
          "capacity": 449.2799999937415
      },
      {
          "time_stamp_utc_ms": 1736233260000,
          "time": "2025-01-07T07:03:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1736233560000,
          "time": "2025-01-07T07:08:00Z",
          "energy": 37.63200000114739,
          "capacity": 451.58400001376873
      },
      {
          "time_stamp_utc_ms": 1736233860000,
          "time": "2025-01-07T07:13:00Z",
          "energy": 36.959999999031425,
          "capacity": 443.5199999883771
      },
      {
          "time_stamp_utc_ms": 1736234160000,
          "time": "2025-01-07T07:18:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1736234460000,
          "time": "2025-01-07T07:23:00Z",
          "energy": 36.96000000089407,
          "capacity": 443.5200000107289
      },
      {
          "time_stamp_utc_ms": 1736234760000,
          "time": "2025-01-07T07:28:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1736235060000,
          "time": "2025-01-07T07:33:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1736235360000,
          "time": "2025-01-07T07:38:00Z",
          "energy": 37.536000000312924,
          "capacity": 450.43200000375515
      },
      {
          "time_stamp_utc_ms": 1736235660000,
          "time": "2025-01-07T07:43:00Z",
          "energy": 38.015999998897314,
          "capacity": 456.19199998676777
      },
      {
          "time_stamp_utc_ms": 1736235960000,
          "time": "2025-01-07T07:48:00Z",
          "energy": 38.112000001594424,
          "capacity": 457.3440000191331
      },
      {
          "time_stamp_utc_ms": 1736236260000,
          "time": "2025-01-07T07:53:00Z",
          "energy": 39.071999998763204,
          "capacity": 468.8639999851585
      },
      {
          "time_stamp_utc_ms": 1736236560000,
          "time": "2025-01-07T07:58:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1736236860000,
          "time": "2025-01-07T08:03:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1736237160000,
          "time": "2025-01-07T08:08:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1736237460000,
          "time": "2025-01-07T08:13:00Z",
          "energy": 37.72800000105053,
          "capacity": 452.73600001260644
      },
      {
          "time_stamp_utc_ms": 1736237760000,
          "time": "2025-01-07T08:18:00Z",
          "energy": 37.34399999957532,
          "capacity": 448.1279999949038
      },
      {
          "time_stamp_utc_ms": 1736238060000,
          "time": "2025-01-07T08:23:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1736238360000,
          "time": "2025-01-07T08:28:00Z",
          "energy": 37.631999999284744,
          "capacity": 451.58399999141693
      },
      {
          "time_stamp_utc_ms": 1736238660000,
          "time": "2025-01-07T08:33:00Z",
          "energy": 37.34400000050664,
          "capacity": 448.12800000607973
      },
      {
          "time_stamp_utc_ms": 1736238960000,
          "time": "2025-01-07T08:38:00Z",
          "energy": 38.015999998897314,
          "capacity": 456.19199998676777
      },
      {
          "time_stamp_utc_ms": 1736239260000,
          "time": "2025-01-07T08:43:00Z",
          "energy": 37.824000000953674,
          "capacity": 453.88800001144415
      },
      {
          "time_stamp_utc_ms": 1736239560000,
          "time": "2025-01-07T08:48:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1736239860000,
          "time": "2025-01-07T08:53:00Z",
          "energy": 37.919999999925494,
          "capacity": 455.039999999106
      },
      {
          "time_stamp_utc_ms": 1736240160000,
          "time": "2025-01-07T08:58:00Z",
          "energy": 37.53600000124425,
          "capacity": 450.432000014931
      },
      {
          "time_stamp_utc_ms": 1736240460000,
          "time": "2025-01-07T09:03:00Z",
          "energy": 38.49599999934435,
          "capacity": 461.95199999213224
      },
      {
          "time_stamp_utc_ms": 1736240760000,
          "time": "2025-01-07T09:08:00Z",
          "energy": 37.15199999883771,
          "capacity": 445.8239999860525
      },
      {
          "time_stamp_utc_ms": 1736241060000,
          "time": "2025-01-07T09:13:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1736241360000,
          "time": "2025-01-07T09:18:00Z",
          "energy": 37.631999999284744,
          "capacity": 451.58399999141693
      },
      {
          "time_stamp_utc_ms": 1736241660000,
          "time": "2025-01-07T09:23:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1736241960000,
          "time": "2025-01-07T09:28:00Z",
          "energy": 37.82399999909103,
          "capacity": 453.88799998909235
      },
      {
          "time_stamp_utc_ms": 1736242260000,
          "time": "2025-01-07T09:33:00Z",
          "energy": 38.496000001206994,
          "capacity": 461.95200001448393
      },
      {
          "time_stamp_utc_ms": 1736242560000,
          "time": "2025-01-07T09:38:00Z",
          "energy": 36.76799999922514,
          "capacity": 441.2159999907017
      },
      {
          "time_stamp_utc_ms": 1736242860000,
          "time": "2025-01-07T09:43:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1736243160000,
          "time": "2025-01-07T09:48:00Z",
          "energy": 37.2480000006035,
          "capacity": 446.976000007242
      },
      {
          "time_stamp_utc_ms": 1736243460000,
          "time": "2025-01-07T09:53:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1736243760000,
          "time": "2025-01-07T09:58:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1736244060000,
          "time": "2025-01-07T10:03:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1736244360000,
          "time": "2025-01-07T10:08:00Z",
          "energy": 41.66400000080466,
          "capacity": 499.968000009656
      },
      {
          "time_stamp_utc_ms": 1736244660000,
          "time": "2025-01-07T10:13:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1736244960000,
          "time": "2025-01-07T10:18:00Z",
          "energy": 43.295999999158084,
          "capacity": 519.551999989897
      },
      {
          "time_stamp_utc_ms": 1736245260000,
          "time": "2025-01-07T10:23:00Z",
          "energy": 42.816000000573695,
          "capacity": 513.7920000068843
      },
      {
          "time_stamp_utc_ms": 1736245560000,
          "time": "2025-01-07T10:28:00Z",
          "energy": 44.06399999931455,
          "capacity": 528.7679999917746
      },
      {
          "time_stamp_utc_ms": 1736245860000,
          "time": "2025-01-07T10:33:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1736246160000,
          "time": "2025-01-07T10:38:00Z",
          "energy": 46.17600000184029,
          "capacity": 554.1120000220835
      },
      {
          "time_stamp_utc_ms": 1736246460000,
          "time": "2025-01-07T10:43:00Z",
          "energy": 44.447999998927116,
          "capacity": 533.3759999871254
      },
      {
          "time_stamp_utc_ms": 1736246760000,
          "time": "2025-01-07T10:48:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1736247060000,
          "time": "2025-01-07T10:53:00Z",
          "energy": 45.21599999908358,
          "capacity": 542.5919999890029
      },
      {
          "time_stamp_utc_ms": 1736247360000,
          "time": "2025-01-07T10:58:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1736247660000,
          "time": "2025-01-07T11:03:00Z",
          "energy": 45.02400000113994,
          "capacity": 540.2880000136793
      },
      {
          "time_stamp_utc_ms": 1736247960000,
          "time": "2025-01-07T11:08:00Z",
          "energy": 47.61599999945611,
          "capacity": 571.3919999934733
      },
      {
          "time_stamp_utc_ms": 1736248260000,
          "time": "2025-01-07T11:13:00Z",
          "energy": 44.6399999987334,
          "capacity": 535.6799999848008
      },
      {
          "time_stamp_utc_ms": 1736248560000,
          "time": "2025-01-07T11:18:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1736248860000,
          "time": "2025-01-07T11:23:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1736249160000,
          "time": "2025-01-07T11:28:00Z",
          "energy": 45.312000000849366,
          "capacity": 543.7440000101924
      },
      {
          "time_stamp_utc_ms": 1736249460000,
          "time": "2025-01-07T11:33:00Z",
          "energy": 46.56000000052154,
          "capacity": 558.7200000062585
      },
      {
          "time_stamp_utc_ms": 1736249760000,
          "time": "2025-01-07T11:38:00Z",
          "energy": 47.23199999984354,
          "capacity": 566.7839999981225
      },
      {
          "time_stamp_utc_ms": 1736250060000,
          "time": "2025-01-07T11:43:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1736250360000,
          "time": "2025-01-07T11:48:00Z",
          "energy": 48.287999998778105,
          "capacity": 579.4559999853373
      },
      {
          "time_stamp_utc_ms": 1736250660000,
          "time": "2025-01-07T11:53:00Z",
          "energy": 45.7920000012964,
          "capacity": 549.5040000155568
      },
      {
          "time_stamp_utc_ms": 1736250960000,
          "time": "2025-01-07T11:58:00Z",
          "energy": 47.61599999945611,
          "capacity": 571.3919999934733
      },
      {
          "time_stamp_utc_ms": 1736251260000,
          "time": "2025-01-07T12:03:00Z",
          "energy": 47.519999999552965,
          "capacity": 570.2399999946356
      },
      {
          "time_stamp_utc_ms": 1736251560000,
          "time": "2025-01-07T12:08:00Z",
          "energy": 47.04000000003725,
          "capacity": 564.480000000447
      },
      {
          "time_stamp_utc_ms": 1736251860000,
          "time": "2025-01-07T12:13:00Z",
          "energy": 48.480000000447035,
          "capacity": 581.7600000053644
      },
      {
          "time_stamp_utc_ms": 1736252160000,
          "time": "2025-01-07T12:18:00Z",
          "energy": 47.61599999945611,
          "capacity": 571.3919999934733
      },
      {
          "time_stamp_utc_ms": 1736252460000,
          "time": "2025-01-07T12:23:00Z",
          "energy": 48.00000000093132,
          "capacity": 576.0000000111759
      },
      {
          "time_stamp_utc_ms": 1736252760000,
          "time": "2025-01-07T12:28:00Z",
          "energy": 48.76799999922514,
          "capacity": 585.2159999907018
      },
      {
          "time_stamp_utc_ms": 1736253060000,
          "time": "2025-01-07T12:33:00Z",
          "energy": 47.71200000029057,
          "capacity": 572.5440000034869
      },
      {
          "time_stamp_utc_ms": 1736253360000,
          "time": "2025-01-07T12:38:00Z",
          "energy": 48.95999999996275,
          "capacity": 587.519999999553
      },
      {
          "time_stamp_utc_ms": 1736253660000,
          "time": "2025-01-07T12:43:00Z",
          "energy": 48.96000000089407,
          "capacity": 587.520000010729
      },
      {
          "time_stamp_utc_ms": 1736253960000,
          "time": "2025-01-07T12:48:00Z",
          "energy": 48.671999999322,
          "capacity": 584.0639999918641
      },
      {
          "time_stamp_utc_ms": 1736254260000,
          "time": "2025-01-07T12:53:00Z",
          "energy": 50.20799999963492,
          "capacity": 602.4959999956192
      },
      {
          "time_stamp_utc_ms": 1736254560000,
          "time": "2025-01-07T12:58:00Z",
          "energy": 48.575999999418855,
          "capacity": 582.9119999930264
      },
      {
          "time_stamp_utc_ms": 1736254860000,
          "time": "2025-01-07T13:03:00Z",
          "energy": 50.1120000006631,
          "capacity": 601.3440000079572
      },
      {
          "time_stamp_utc_ms": 1736255160000,
          "time": "2025-01-07T13:08:00Z",
          "energy": 49.2480000006035,
          "capacity": 590.9760000072421
      },
      {
          "time_stamp_utc_ms": 1736255460000,
          "time": "2025-01-07T13:13:00Z",
          "energy": 48.38399999961257,
          "capacity": 580.607999995351
      },
      {
          "time_stamp_utc_ms": 1736255760000,
          "time": "2025-01-07T13:18:00Z",
          "energy": 49.631999999284744,
          "capacity": 595.5839999914169
      },
      {
          "time_stamp_utc_ms": 1736256060000,
          "time": "2025-01-07T13:23:00Z",
          "energy": 48.5760000012815,
          "capacity": 582.9120000153781
      },
      {
          "time_stamp_utc_ms": 1736256360000,
          "time": "2025-01-07T13:28:00Z",
          "energy": 49.247999999672174,
          "capacity": 590.9759999960661
      },
      {
          "time_stamp_utc_ms": 1736256660000,
          "time": "2025-01-07T13:33:00Z",
          "energy": 49.247999999672174,
          "capacity": 590.9759999960661
      },
      {
          "time_stamp_utc_ms": 1736256960000,
          "time": "2025-01-07T13:38:00Z",
          "energy": 49.82400000002235,
          "capacity": 597.8880000002683
      },
      {
          "time_stamp_utc_ms": 1736257260000,
          "time": "2025-01-07T13:43:00Z",
          "energy": 50.7839999999851,
          "capacity": 609.4079999998212
      },
      {
          "time_stamp_utc_ms": 1736257560000,
          "time": "2025-01-07T13:48:00Z",
          "energy": 49.92000000085682,
          "capacity": 599.0400000102818
      },
      {
          "time_stamp_utc_ms": 1736257860000,
          "time": "2025-01-07T13:53:00Z",
          "energy": 49.72799999918789,
          "capacity": 596.7359999902546
      },
      {
          "time_stamp_utc_ms": 1736258160000,
          "time": "2025-01-07T13:58:00Z",
          "energy": 50.01599999982864,
          "capacity": 600.1919999979438
      },
      {
          "time_stamp_utc_ms": 1736258460000,
          "time": "2025-01-07T14:03:00Z",
          "energy": 49.247999999672174,
          "capacity": 590.9759999960661
      },
      {
          "time_stamp_utc_ms": 1736258760000,
          "time": "2025-01-07T14:08:00Z",
          "energy": 49.824000000953674,
          "capacity": 597.8880000114441
      },
      {
          "time_stamp_utc_ms": 1736259060000,
          "time": "2025-01-07T14:13:00Z",
          "energy": 49.34399999957532,
          "capacity": 592.1279999949038
      },
      {
          "time_stamp_utc_ms": 1736259360000,
          "time": "2025-01-07T14:18:00Z",
          "energy": 49.247999999672174,
          "capacity": 590.9759999960661
      },
      {
          "time_stamp_utc_ms": 1736259660000,
          "time": "2025-01-07T14:23:00Z",
          "energy": 50.1120000006631,
          "capacity": 601.3440000079572
      },
      {
          "time_stamp_utc_ms": 1736259960000,
          "time": "2025-01-07T14:28:00Z",
          "energy": 48.28799999970943,
          "capacity": 579.4559999965132
      },
      {
          "time_stamp_utc_ms": 1736260260000,
          "time": "2025-01-07T14:33:00Z",
          "energy": 49.05599999986589,
          "capacity": 588.6719999983907
      },
      {
          "time_stamp_utc_ms": 1736260560000,
          "time": "2025-01-07T14:38:00Z",
          "energy": 49.15199999976903,
          "capacity": 589.8239999972284
      },
      {
          "time_stamp_utc_ms": 1736260860000,
          "time": "2025-01-07T14:43:00Z",
          "energy": 48.096000000834465,
          "capacity": 577.1520000100136
      },
      {
          "time_stamp_utc_ms": 1736261160000,
          "time": "2025-01-07T14:48:00Z",
          "energy": 49.15199999883771,
          "capacity": 589.8239999860526
      },
      {
          "time_stamp_utc_ms": 1736261460000,
          "time": "2025-01-07T14:53:00Z",
          "energy": 49.152000000700355,
          "capacity": 589.8240000084044
      },
      {
          "time_stamp_utc_ms": 1736261760000,
          "time": "2025-01-07T14:58:00Z",
          "energy": 48.191999999806285,
          "capacity": 578.3039999976755
      },
      {
          "time_stamp_utc_ms": 1736262060000,
          "time": "2025-01-07T15:03:00Z",
          "energy": 49.247999999672174,
          "capacity": 590.9759999960661
      },
      {
          "time_stamp_utc_ms": 1736262360000,
          "time": "2025-01-07T15:08:00Z",
          "energy": 48.480000000447035,
          "capacity": 581.7600000053644
      },
      {
          "time_stamp_utc_ms": 1736262660000,
          "time": "2025-01-07T15:13:00Z",
          "energy": 50.975999999791384,
          "capacity": 611.7119999974966
      },
      {
          "time_stamp_utc_ms": 1736262960000,
          "time": "2025-01-07T15:18:00Z",
          "energy": 48.864000000059605,
          "capacity": 586.3680000007153
      },
      {
          "time_stamp_utc_ms": 1736263260000,
          "time": "2025-01-07T15:23:00Z",
          "energy": 51.26399999950081,
          "capacity": 615.1679999940097
      },
      {
          "time_stamp_utc_ms": 1736263560000,
          "time": "2025-01-07T15:28:00Z",
          "energy": 49.92000000085682,
          "capacity": 599.0400000102818
      },
      {
          "time_stamp_utc_ms": 1736263860000,
          "time": "2025-01-07T15:33:00Z",
          "energy": 49.631999999284744,
          "capacity": 595.5839999914169
      },
      {
          "time_stamp_utc_ms": 1736264160000,
          "time": "2025-01-07T15:38:00Z",
          "energy": 48.19200000073761,
          "capacity": 578.3040000088513
      },
      {
          "time_stamp_utc_ms": 1736264460000,
          "time": "2025-01-07T15:43:00Z",
          "energy": 49.919999999925494,
          "capacity": 599.039999999106
      },
      {
          "time_stamp_utc_ms": 1736264760000,
          "time": "2025-01-07T15:48:00Z",
          "energy": 47.808000000193715,
          "capacity": 573.6960000023246
      },
      {
          "time_stamp_utc_ms": 1736265060000,
          "time": "2025-01-07T15:53:00Z",
          "energy": 48.959999999031425,
          "capacity": 587.5199999883772
      },
      {
          "time_stamp_utc_ms": 1736265360000,
          "time": "2025-01-07T15:58:00Z",
          "energy": 47.80800000112504,
          "capacity": 573.6960000135005
      },
      {
          "time_stamp_utc_ms": 1736265660000,
          "time": "2025-01-07T16:03:00Z",
          "energy": 46.94400000013411,
          "capacity": 563.3280000016093
      },
      {
          "time_stamp_utc_ms": 1736265960000,
          "time": "2025-01-07T16:08:00Z",
          "energy": 45.40799999888986,
          "capacity": 544.8959999866784
      },
      {
          "time_stamp_utc_ms": 1736266260000,
          "time": "2025-01-07T16:13:00Z",
          "energy": 43.488000000827014,
          "capacity": 521.8560000099242
      },
      {
          "time_stamp_utc_ms": 1736266560000,
          "time": "2025-01-07T16:18:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1736266860000,
          "time": "2025-01-07T16:23:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1736267160000,
          "time": "2025-01-07T16:28:00Z",
          "energy": 43.39199999906123,
          "capacity": 520.7039999887347
      },
      {
          "time_stamp_utc_ms": 1736267460000,
          "time": "2025-01-07T16:33:00Z",
          "energy": 44.928000001236796,
          "capacity": 539.1360000148416
      },
      {
          "time_stamp_utc_ms": 1736267760000,
          "time": "2025-01-07T16:38:00Z",
          "energy": 45.023999999277294,
          "capacity": 540.2879999913275
      },
      {
          "time_stamp_utc_ms": 1736268060000,
          "time": "2025-01-07T16:43:00Z",
          "energy": 44.544000000692904,
          "capacity": 534.528000008315
      },
      {
          "time_stamp_utc_ms": 1736268360000,
          "time": "2025-01-07T16:48:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1736268660000,
          "time": "2025-01-07T16:53:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1736268960000,
          "time": "2025-01-07T16:58:00Z",
          "energy": 44.15999999921769,
          "capacity": 529.9199999906123
      },
      {
          "time_stamp_utc_ms": 1736269260000,
          "time": "2025-01-07T17:03:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1736269560000,
          "time": "2025-01-07T17:08:00Z",
          "energy": 45.79200000036508,
          "capacity": 549.5040000043809
      },
      {
          "time_stamp_utc_ms": 1736269860000,
          "time": "2025-01-07T17:13:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1736270160000,
          "time": "2025-01-07T17:18:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1736270460000,
          "time": "2025-01-07T17:23:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1736270760000,
          "time": "2025-01-07T17:28:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1736271060000,
          "time": "2025-01-07T17:33:00Z",
          "energy": 43.48799999989569,
          "capacity": 521.8559999987483
      },
      {
          "time_stamp_utc_ms": 1736271360000,
          "time": "2025-01-07T17:38:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1736271660000,
          "time": "2025-01-07T17:43:00Z",
          "energy": 42.33600000105798,
          "capacity": 508.0320000126958
      },
      {
          "time_stamp_utc_ms": 1736271960000,
          "time": "2025-01-07T17:48:00Z",
          "energy": 43.39199999906123,
          "capacity": 520.7039999887347
      },
      {
          "time_stamp_utc_ms": 1736272260000,
          "time": "2025-01-07T17:53:00Z",
          "energy": 44.44800000078976,
          "capacity": 533.3760000094773
      },
      {
          "time_stamp_utc_ms": 1736272560000,
          "time": "2025-01-07T17:58:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1736272860000,
          "time": "2025-01-07T18:03:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1736273160000,
          "time": "2025-01-07T18:08:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1736273460000,
          "time": "2025-01-07T18:13:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1736273760000,
          "time": "2025-01-07T18:18:00Z",
          "energy": 43.48799999896437,
          "capacity": 521.8559999875724
      },
      {
          "time_stamp_utc_ms": 1736274060000,
          "time": "2025-01-07T18:23:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1736274360000,
          "time": "2025-01-07T18:28:00Z",
          "energy": 40.3199999993667,
          "capacity": 483.8399999924004
      },
      {
          "time_stamp_utc_ms": 1736274660000,
          "time": "2025-01-07T18:33:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1736274960000,
          "time": "2025-01-07T18:38:00Z",
          "energy": 39.84000000078231,
          "capacity": 478.08000000938773
      },
      {
          "time_stamp_utc_ms": 1736275260000,
          "time": "2025-01-07T18:43:00Z",
          "energy": 42.239999999292195,
          "capacity": 506.87999999150634
      },
      {
          "time_stamp_utc_ms": 1736275560000,
          "time": "2025-01-07T18:48:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1736275860000,
          "time": "2025-01-07T18:53:00Z",
          "energy": 43.200000000186265,
          "capacity": 518.4000000022352
      },
      {
          "time_stamp_utc_ms": 1736276160000,
          "time": "2025-01-07T18:58:00Z",
          "energy": 43.967999999411404,
          "capacity": 527.6159999929368
      },
      {
          "time_stamp_utc_ms": 1736276460000,
          "time": "2025-01-07T19:03:00Z",
          "energy": 42.62400000076741,
          "capacity": 511.4880000092089
      },
      {
          "time_stamp_utc_ms": 1736276760000,
          "time": "2025-01-07T19:08:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1736277060000,
          "time": "2025-01-07T19:13:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1736277360000,
          "time": "2025-01-07T19:18:00Z",
          "energy": 38.687999999150634,
          "capacity": 464.25599998980766
      },
      {
          "time_stamp_utc_ms": 1736277660000,
          "time": "2025-01-07T19:23:00Z",
          "energy": 41.18400000128895,
          "capacity": 494.20800001546746
      },
      {
          "time_stamp_utc_ms": 1736277960000,
          "time": "2025-01-07T19:28:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1736278260000,
          "time": "2025-01-07T19:33:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1736278560000,
          "time": "2025-01-07T19:38:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1736278860000,
          "time": "2025-01-07T19:43:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1736279160000,
          "time": "2025-01-07T19:48:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1736279460000,
          "time": "2025-01-07T19:53:00Z",
          "energy": 42.24000000022352,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1736279760000,
          "time": "2025-01-07T19:58:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1736280060000,
          "time": "2025-01-07T20:03:00Z",
          "energy": 42.71999999973923,
          "capacity": 512.6399999968708
      },
      {
          "time_stamp_utc_ms": 1736280360000,
          "time": "2025-01-07T20:08:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1736280660000,
          "time": "2025-01-07T20:13:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1736280960000,
          "time": "2025-01-07T20:18:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1736281260000,
          "time": "2025-01-07T20:23:00Z",
          "energy": 44.06400000024587,
          "capacity": 528.7680000029504
      },
      {
          "time_stamp_utc_ms": 1736281560000,
          "time": "2025-01-07T20:28:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1736281860000,
          "time": "2025-01-07T20:33:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1736282160000,
          "time": "2025-01-07T20:38:00Z",
          "energy": 44.06399999931455,
          "capacity": 528.7679999917746
      },
      {
          "time_stamp_utc_ms": 1736282460000,
          "time": "2025-01-07T20:43:00Z",
          "energy": 44.64000000059605,
          "capacity": 535.6800000071527
      },
      {
          "time_stamp_utc_ms": 1736282760000,
          "time": "2025-01-07T20:48:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1736283060000,
          "time": "2025-01-07T20:53:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1736283360000,
          "time": "2025-01-07T20:58:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1736283660000,
          "time": "2025-01-07T21:03:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1736283960000,
          "time": "2025-01-07T21:08:00Z",
          "energy": 46.4640000006184,
          "capacity": 557.5680000074208
      },
      {
          "time_stamp_utc_ms": 1736284260000,
          "time": "2025-01-07T21:13:00Z",
          "energy": 46.84799999836832,
          "capacity": 562.17599998042
      },
      {
          "time_stamp_utc_ms": 1736284560000,
          "time": "2025-01-07T21:18:00Z",
          "energy": 46.56000000145286,
          "capacity": 558.7200000174344
      },
      {
          "time_stamp_utc_ms": 1736284860000,
          "time": "2025-01-07T21:23:00Z",
          "energy": 46.65600000042468,
          "capacity": 559.8720000050962
      },
      {
          "time_stamp_utc_ms": 1736285160000,
          "time": "2025-01-07T21:28:00Z",
          "energy": 47.4239999987185,
          "capacity": 569.087999984622
      },
      {
          "time_stamp_utc_ms": 1736285460000,
          "time": "2025-01-07T21:33:00Z",
          "energy": 47.71200000029057,
          "capacity": 572.5440000034869
      },
      {
          "time_stamp_utc_ms": 1736285760000,
          "time": "2025-01-07T21:38:00Z",
          "energy": 47.32799999974668,
          "capacity": 567.9359999969602
      },
      {
          "time_stamp_utc_ms": 1736286060000,
          "time": "2025-01-07T21:43:00Z",
          "energy": 47.61599999945611,
          "capacity": 571.3919999934733
      },
      {
          "time_stamp_utc_ms": 1736286360000,
          "time": "2025-01-07T21:48:00Z",
          "energy": 46.752000000327826,
          "capacity": 561.0240000039339
      },
      {
          "time_stamp_utc_ms": 1736286660000,
          "time": "2025-01-07T21:53:00Z",
          "energy": 48.096000000834465,
          "capacity": 577.1520000100136
      },
      {
          "time_stamp_utc_ms": 1736286960000,
          "time": "2025-01-07T21:58:00Z",
          "energy": 46.07999999914318,
          "capacity": 552.9599999897183
      },
      {
          "time_stamp_utc_ms": 1736287260000,
          "time": "2025-01-07T22:03:00Z",
          "energy": 47.42400000151247,
          "capacity": 569.0880000181496
      },
      {
          "time_stamp_utc_ms": 1736287560000,
          "time": "2025-01-07T22:08:00Z",
          "energy": 47.903999999165535,
          "capacity": 574.8479999899864
      },
      {
          "time_stamp_utc_ms": 1736287860000,
          "time": "2025-01-07T22:13:00Z",
          "energy": 47.23199999984354,
          "capacity": 566.7839999981225
      },
      {
          "time_stamp_utc_ms": 1736288160000,
          "time": "2025-01-07T22:18:00Z",
          "energy": 47.61600000131875,
          "capacity": 571.392000015825
      },
      {
          "time_stamp_utc_ms": 1736288460000,
          "time": "2025-01-07T22:23:00Z",
          "energy": 47.135999999940395,
          "capacity": 565.6319999992847
      },
      {
          "time_stamp_utc_ms": 1736288760000,
          "time": "2025-01-07T22:28:00Z",
          "energy": 46.847999999299645,
          "capacity": 562.1759999915957
      },
      {
          "time_stamp_utc_ms": 1736289060000,
          "time": "2025-01-07T22:33:00Z",
          "energy": 47.231999998912215,
          "capacity": 566.7839999869466
      },
      {
          "time_stamp_utc_ms": 1736289360000,
          "time": "2025-01-07T22:38:00Z",
          "energy": 47.80800000112504,
          "capacity": 573.6960000135005
      },
      {
          "time_stamp_utc_ms": 1736289660000,
          "time": "2025-01-07T22:43:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1736289960000,
          "time": "2025-01-07T22:48:00Z",
          "energy": 46.94399999920279,
          "capacity": 563.3279999904335
      },
      {
          "time_stamp_utc_ms": 1736290260000,
          "time": "2025-01-07T22:53:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1736290560000,
          "time": "2025-01-07T22:58:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1736290860000,
          "time": "2025-01-07T23:03:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1736291160000,
          "time": "2025-01-07T23:08:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1736291460000,
          "time": "2025-01-07T23:13:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1736291760000,
          "time": "2025-01-07T23:18:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1736292060000,
          "time": "2025-01-07T23:23:00Z",
          "energy": 45.311999999918044,
          "capacity": 543.7439999990166
      },
      {
          "time_stamp_utc_ms": 1736292360000,
          "time": "2025-01-07T23:28:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1736292660000,
          "time": "2025-01-07T23:33:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1736292960000,
          "time": "2025-01-07T23:38:00Z",
          "energy": 45.696000000461936,
          "capacity": 548.3520000055432
      },
      {
          "time_stamp_utc_ms": 1736293260000,
          "time": "2025-01-07T23:43:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1736293560000,
          "time": "2025-01-07T23:48:00Z",
          "energy": 45.119999999180436,
          "capacity": 541.4399999901652
      },
      {
          "time_stamp_utc_ms": 1736293860000,
          "time": "2025-01-07T23:53:00Z",
          "energy": 44.928000001236796,
          "capacity": 539.1360000148416
      },
      {
          "time_stamp_utc_ms": 1736294160000,
          "time": "2025-01-07T23:58:00Z",
          "energy": 45.21599999908358,
          "capacity": 542.5919999890029
      },
      {
          "time_stamp_utc_ms": 1736294460000,
          "time": "2025-01-08T00:03:00Z",
          "energy": 44.832000001333654,
          "capacity": 537.9840000160038
      },
      {
          "time_stamp_utc_ms": 1736294760000,
          "time": "2025-01-08T00:08:00Z",
          "energy": 45.31199999898672,
          "capacity": 543.7439999878407
      },
      {
          "time_stamp_utc_ms": 1736295060000,
          "time": "2025-01-08T00:13:00Z",
          "energy": 45.21599999908358,
          "capacity": 542.5919999890029
      },
      {
          "time_stamp_utc_ms": 1736295360000,
          "time": "2025-01-08T00:18:00Z",
          "energy": 45.02400000113994,
          "capacity": 540.2880000136793
      },
      {
          "time_stamp_utc_ms": 1736295660000,
          "time": "2025-01-08T00:23:00Z",
          "energy": 45.98399999924004,
          "capacity": 551.8079999908806
      },
      {
          "time_stamp_utc_ms": 1736295960000,
          "time": "2025-01-08T00:28:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1736296260000,
          "time": "2025-01-08T00:33:00Z",
          "energy": 45.407999999821186,
          "capacity": 544.8959999978543
      },
      {
          "time_stamp_utc_ms": 1736296560000,
          "time": "2025-01-08T00:38:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1736296860000,
          "time": "2025-01-08T00:43:00Z",
          "energy": 46.07999999914318,
          "capacity": 552.9599999897183
      },
      {
          "time_stamp_utc_ms": 1736297160000,
          "time": "2025-01-08T00:48:00Z",
          "energy": 44.928000001236796,
          "capacity": 539.1360000148416
      },
      {
          "time_stamp_utc_ms": 1736297460000,
          "time": "2025-01-08T00:53:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1736297760000,
          "time": "2025-01-08T00:58:00Z",
          "energy": 44.83199999947101,
          "capacity": 537.9839999936521
      },
      {
          "time_stamp_utc_ms": 1736298060000,
          "time": "2025-01-08T01:03:00Z",
          "energy": 45.696000000461936,
          "capacity": 548.3520000055432
      },
      {
          "time_stamp_utc_ms": 1736298360000,
          "time": "2025-01-08T01:08:00Z",
          "energy": 45.696000000461936,
          "capacity": 548.3520000055432
      },
      {
          "time_stamp_utc_ms": 1736298660000,
          "time": "2025-01-08T01:13:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1736298960000,
          "time": "2025-01-08T01:18:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1736299260000,
          "time": "2025-01-08T01:23:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1736299560000,
          "time": "2025-01-08T01:28:00Z",
          "energy": 45.40799999888986,
          "capacity": 544.8959999866784
      },
      {
          "time_stamp_utc_ms": 1736299860000,
          "time": "2025-01-08T01:33:00Z",
          "energy": 46.56000000052154,
          "capacity": 558.7200000062585
      },
      {
          "time_stamp_utc_ms": 1736300160000,
          "time": "2025-01-08T01:38:00Z",
          "energy": 45.79200000036508,
          "capacity": 549.5040000043809
      },
      {
          "time_stamp_utc_ms": 1736300460000,
          "time": "2025-01-08T01:43:00Z",
          "energy": 45.696000000461936,
          "capacity": 548.3520000055432
      },
      {
          "time_stamp_utc_ms": 1736300760000,
          "time": "2025-01-08T01:48:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1736301060000,
          "time": "2025-01-08T01:53:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1736301360000,
          "time": "2025-01-08T01:58:00Z",
          "energy": 41.472000000067055,
          "capacity": 497.66400000080466
      },
      {
          "time_stamp_utc_ms": 1736301660000,
          "time": "2025-01-08T02:03:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1736301960000,
          "time": "2025-01-08T02:08:00Z",
          "energy": 41.95200000051409,
          "capacity": 503.42400000616914
      },
      {
          "time_stamp_utc_ms": 1736302260000,
          "time": "2025-01-08T02:13:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1736302560000,
          "time": "2025-01-08T02:18:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1736302860000,
          "time": "2025-01-08T02:23:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1736303160000,
          "time": "2025-01-08T02:28:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1736303460000,
          "time": "2025-01-08T02:33:00Z",
          "energy": 40.51200000010431,
          "capacity": 486.14400000125175
      },
      {
          "time_stamp_utc_ms": 1736303760000,
          "time": "2025-01-08T02:38:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1736304060000,
          "time": "2025-01-08T02:43:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1736304360000,
          "time": "2025-01-08T02:48:00Z",
          "energy": 40.99199999962002,
          "capacity": 491.9039999954403
      },
      {
          "time_stamp_utc_ms": 1736304660000,
          "time": "2025-01-08T02:53:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1736304960000,
          "time": "2025-01-08T02:58:00Z",
          "energy": 41.08800000138581,
          "capacity": 493.05600001662975
      },
      {
          "time_stamp_utc_ms": 1736305260000,
          "time": "2025-01-08T03:03:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1736305560000,
          "time": "2025-01-08T03:08:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1736305860000,
          "time": "2025-01-08T03:13:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1736306160000,
          "time": "2025-01-08T03:18:00Z",
          "energy": 38.87999999895692,
          "capacity": 466.5599999874831
      },
      {
          "time_stamp_utc_ms": 1736306460000,
          "time": "2025-01-08T03:23:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1736306760000,
          "time": "2025-01-08T03:28:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1736307060000,
          "time": "2025-01-08T03:33:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1736307360000,
          "time": "2025-01-08T03:38:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1736307660000,
          "time": "2025-01-08T03:43:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1736307960000,
          "time": "2025-01-08T03:48:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1736308260000,
          "time": "2025-01-08T03:53:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1736308560000,
          "time": "2025-01-08T03:58:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1736308860000,
          "time": "2025-01-08T04:03:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1736309160000,
          "time": "2025-01-08T04:08:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1736309460000,
          "time": "2025-01-08T04:13:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1736309760000,
          "time": "2025-01-08T04:18:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1736310060000,
          "time": "2025-01-08T04:23:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1736310360000,
          "time": "2025-01-08T04:28:00Z",
          "energy": 37.631999999284744,
          "capacity": 451.58399999141693
      },
      {
          "time_stamp_utc_ms": 1736310660000,
          "time": "2025-01-08T04:33:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1736310960000,
          "time": "2025-01-08T04:38:00Z",
          "energy": 39.264000001363456,
          "capacity": 471.1680000163615
      },
      {
          "time_stamp_utc_ms": 1736311260000,
          "time": "2025-01-08T04:43:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1736311560000,
          "time": "2025-01-08T04:48:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1736311860000,
          "time": "2025-01-08T04:53:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1736312160000,
          "time": "2025-01-08T04:58:00Z",
          "energy": 38.49599999934435,
          "capacity": 461.95199999213224
      },
      {
          "time_stamp_utc_ms": 1736312460000,
          "time": "2025-01-08T05:03:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1736312760000,
          "time": "2025-01-08T05:08:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1736313060000,
          "time": "2025-01-08T05:13:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1736313360000,
          "time": "2025-01-08T05:18:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1736313660000,
          "time": "2025-01-08T05:23:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1736313960000,
          "time": "2025-01-08T05:28:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1736314260000,
          "time": "2025-01-08T05:33:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1736314560000,
          "time": "2025-01-08T05:38:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1736314860000,
          "time": "2025-01-08T05:43:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1736315160000,
          "time": "2025-01-08T05:48:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1736315460000,
          "time": "2025-01-08T05:53:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1736315760000,
          "time": "2025-01-08T05:58:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1736316060000,
          "time": "2025-01-08T06:03:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1736316360000,
          "time": "2025-01-08T06:08:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1736316660000,
          "time": "2025-01-08T06:13:00Z",
          "energy": 38.68800000101328,
          "capacity": 464.25600001215935
      },
      {
          "time_stamp_utc_ms": 1736316960000,
          "time": "2025-01-08T06:18:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1736317260000,
          "time": "2025-01-08T06:23:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1736317560000,
          "time": "2025-01-08T06:28:00Z",
          "energy": 38.783999999053776,
          "capacity": 465.4079999886454
      },
      {
          "time_stamp_utc_ms": 1736317860000,
          "time": "2025-01-08T06:33:00Z",
          "energy": 38.880000000819564,
          "capacity": 466.56000000983477
      },
      {
          "time_stamp_utc_ms": 1736318160000,
          "time": "2025-01-08T06:38:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1736318460000,
          "time": "2025-01-08T06:43:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1736318760000,
          "time": "2025-01-08T06:48:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1736319060000,
          "time": "2025-01-08T06:53:00Z",
          "energy": 40.511999999172986,
          "capacity": 486.1439999900758
      },
      {
          "time_stamp_utc_ms": 1736319360000,
          "time": "2025-01-08T06:58:00Z",
          "energy": 39.84000000078231,
          "capacity": 478.08000000938773
      },
      {
          "time_stamp_utc_ms": 1736319660000,
          "time": "2025-01-08T07:03:00Z",
          "energy": 40.3199999993667,
          "capacity": 483.8399999924004
      },
      {
          "time_stamp_utc_ms": 1736319960000,
          "time": "2025-01-08T07:08:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1736320260000,
          "time": "2025-01-08T07:13:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1736320560000,
          "time": "2025-01-08T07:18:00Z",
          "energy": 41.37599999830127,
          "capacity": 496.5119999796152
      },
      {
          "time_stamp_utc_ms": 1736320860000,
          "time": "2025-01-08T07:23:00Z",
          "energy": 40.99200000055134,
          "capacity": 491.9040000066161
      },
      {
          "time_stamp_utc_ms": 1736321160000,
          "time": "2025-01-08T07:28:00Z",
          "energy": 39.84000000078231,
          "capacity": 478.08000000938773
      },
      {
          "time_stamp_utc_ms": 1736321460000,
          "time": "2025-01-08T07:33:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1736321760000,
          "time": "2025-01-08T07:38:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1736322060000,
          "time": "2025-01-08T07:43:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1736322360000,
          "time": "2025-01-08T07:48:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1736322660000,
          "time": "2025-01-08T07:53:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1736322960000,
          "time": "2025-01-08T07:58:00Z",
          "energy": 39.84000000078231,
          "capacity": 478.08000000938773
      },
      {
          "time_stamp_utc_ms": 1736323260000,
          "time": "2025-01-08T08:03:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1736323560000,
          "time": "2025-01-08T08:08:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1736323860000,
          "time": "2025-01-08T08:13:00Z",
          "energy": 40.2240000013262,
          "capacity": 482.68800001591444
      },
      {
          "time_stamp_utc_ms": 1736324160000,
          "time": "2025-01-08T08:18:00Z",
          "energy": 38.2079999987036,
          "capacity": 458.4959999844432
      },
      {
          "time_stamp_utc_ms": 1736324460000,
          "time": "2025-01-08T08:23:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1736324760000,
          "time": "2025-01-08T08:28:00Z",
          "energy": 38.687999999150634,
          "capacity": 464.25599998980766
      },
      {
          "time_stamp_utc_ms": 1736325060000,
          "time": "2025-01-08T08:33:00Z",
          "energy": 38.59200000111014,
          "capacity": 463.10400001332164
      },
      {
          "time_stamp_utc_ms": 1736325360000,
          "time": "2025-01-08T08:38:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1736325660000,
          "time": "2025-01-08T08:43:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1736325960000,
          "time": "2025-01-08T08:48:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1736326260000,
          "time": "2025-01-08T08:53:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1736326560000,
          "time": "2025-01-08T08:58:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1736326860000,
          "time": "2025-01-08T09:03:00Z",
          "energy": 40.12799999862909,
          "capacity": 481.5359999835491
      },
      {
          "time_stamp_utc_ms": 1736327160000,
          "time": "2025-01-08T09:08:00Z",
          "energy": 42.432000000961125,
          "capacity": 509.1840000115335
      },
      {
          "time_stamp_utc_ms": 1736327460000,
          "time": "2025-01-08T09:13:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1736327760000,
          "time": "2025-01-08T09:18:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1736328060000,
          "time": "2025-01-08T09:23:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1736328360000,
          "time": "2025-01-08T09:28:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1736328660000,
          "time": "2025-01-08T09:33:00Z",
          "energy": 38.783999999053776,
          "capacity": 465.4079999886454
      },
      {
          "time_stamp_utc_ms": 1736328960000,
          "time": "2025-01-08T09:38:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1736329260000,
          "time": "2025-01-08T09:43:00Z",
          "energy": 39.552000001072884,
          "capacity": 474.6240000128746
      },
      {
          "time_stamp_utc_ms": 1736329560000,
          "time": "2025-01-08T09:48:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1736329860000,
          "time": "2025-01-08T09:53:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1736330160000,
          "time": "2025-01-08T09:58:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1736330460000,
          "time": "2025-01-08T10:03:00Z",
          "energy": 46.36799999978393,
          "capacity": 556.4159999974072
      },
      {
          "time_stamp_utc_ms": 1736330760000,
          "time": "2025-01-08T10:08:00Z",
          "energy": 46.463999999687076,
          "capacity": 557.5679999962449
      },
      {
          "time_stamp_utc_ms": 1736331060000,
          "time": "2025-01-08T10:13:00Z",
          "energy": 47.52000000048429,
          "capacity": 570.2400000058115
      },
      {
          "time_stamp_utc_ms": 1736331360000,
          "time": "2025-01-08T10:18:00Z",
          "energy": 46.080000000074506,
          "capacity": 552.9600000008941
      },
      {
          "time_stamp_utc_ms": 1736331660000,
          "time": "2025-01-08T10:23:00Z",
          "energy": 47.71199999935925,
          "capacity": 572.543999992311
      },
      {
          "time_stamp_utc_ms": 1736331960000,
          "time": "2025-01-08T10:28:00Z",
          "energy": 46.752000000327826,
          "capacity": 561.0240000039339
      },
      {
          "time_stamp_utc_ms": 1736332260000,
          "time": "2025-01-08T10:33:00Z",
          "energy": 47.808000000193715,
          "capacity": 573.6960000023246
      },
      {
          "time_stamp_utc_ms": 1736332560000,
          "time": "2025-01-08T10:38:00Z",
          "energy": 48.38399999961257,
          "capacity": 580.607999995351
      },
      {
          "time_stamp_utc_ms": 1736332860000,
          "time": "2025-01-08T10:43:00Z",
          "energy": 48.19200000073761,
          "capacity": 578.3040000088513
      },
      {
          "time_stamp_utc_ms": 1736333160000,
          "time": "2025-01-08T10:48:00Z",
          "energy": 48.671999999322,
          "capacity": 584.0639999918641
      },
      {
          "time_stamp_utc_ms": 1736333460000,
          "time": "2025-01-08T10:53:00Z",
          "energy": 49.536000000312924,
          "capacity": 594.4320000037552
      },
      {
          "time_stamp_utc_ms": 1736333760000,
          "time": "2025-01-08T10:58:00Z",
          "energy": 48.38400000054389,
          "capacity": 580.6080000065267
      },
      {
          "time_stamp_utc_ms": 1736334060000,
          "time": "2025-01-08T11:03:00Z",
          "energy": 49.72799999918789,
          "capacity": 596.7359999902546
      },
      {
          "time_stamp_utc_ms": 1736334360000,
          "time": "2025-01-08T11:08:00Z",
          "energy": 50.11199999973178,
          "capacity": 601.3439999967815
      },
      {
          "time_stamp_utc_ms": 1736334660000,
          "time": "2025-01-08T11:13:00Z",
          "energy": 50.01600000075996,
          "capacity": 600.1920000091195
      },
      {
          "time_stamp_utc_ms": 1736334960000,
          "time": "2025-01-08T11:18:00Z",
          "energy": 51.16800000052899,
          "capacity": 614.0160000063479
      },
      {
          "time_stamp_utc_ms": 1736335260000,
          "time": "2025-01-08T11:23:00Z",
          "energy": 51.64799999911338,
          "capacity": 619.7759999893606
      },
      {
          "time_stamp_utc_ms": 1736335560000,
          "time": "2025-01-08T11:28:00Z",
          "energy": 51.16800000052899,
          "capacity": 614.0160000063479
      },
      {
          "time_stamp_utc_ms": 1736335860000,
          "time": "2025-01-08T11:33:00Z",
          "energy": 51.83999999985099,
          "capacity": 622.0799999982119
      },
      {
          "time_stamp_utc_ms": 1736336160000,
          "time": "2025-01-08T11:38:00Z",
          "energy": 51.55199999921024,
          "capacity": 618.6239999905229
      },
      {
          "time_stamp_utc_ms": 1736336460000,
          "time": "2025-01-08T11:43:00Z",
          "energy": 51.84000000078231,
          "capacity": 622.0800000093878
      },
      {
          "time_stamp_utc_ms": 1736336760000,
          "time": "2025-01-08T11:48:00Z",
          "energy": 51.64799999911338,
          "capacity": 619.7759999893606
      },
      {
          "time_stamp_utc_ms": 1736337060000,
          "time": "2025-01-08T11:53:00Z",
          "energy": 52.32000000029802,
          "capacity": 627.8400000035764
      },
      {
          "time_stamp_utc_ms": 1736337330000,
          "time": "2025-01-08T11:58:00Z",
          "energy": 46.84800000023097,
          "capacity": 624.6400000030795
      },
      {
          "time_stamp_utc_ms": 1736337630166,
          "time": "2025-01-08T12:03:00Z",
          "energy": 52.71999999973923,
          "capacity": 632.2901400541871
      },
      {
          "time_stamp_utc_ms": 1736337960000,
          "time": "2025-01-08T12:08:00Z",
          "energy": 57.200000000186265,
          "capacity": 624.3140423598905
      },
      {
          "time_stamp_utc_ms": 1736338260000,
          "time": "2025-01-08T12:13:00Z",
          "energy": 52.416000000201166,
          "capacity": 628.9920000024141
      },
      {
          "time_stamp_utc_ms": 1736338560000,
          "time": "2025-01-08T12:18:00Z",
          "energy": 53.56800000090152,
          "capacity": 642.8160000108184
      },
      {
          "time_stamp_utc_ms": 1736338860000,
          "time": "2025-01-08T12:23:00Z",
          "energy": 52.3199999993667,
          "capacity": 627.8399999924004
      },
      {
          "time_stamp_utc_ms": 1736339160000,
          "time": "2025-01-08T12:28:00Z",
          "energy": 53.855999999679625,
          "capacity": 646.2719999961555
      },
      {
          "time_stamp_utc_ms": 1736339460000,
          "time": "2025-01-08T12:33:00Z",
          "energy": 52.416000000201166,
          "capacity": 628.9920000024141
      },
      {
          "time_stamp_utc_ms": 1736339760000,
          "time": "2025-01-08T12:38:00Z",
          "energy": 53.5679999999702,
          "capacity": 642.8159999996424
      },
      {
          "time_stamp_utc_ms": 1736340060000,
          "time": "2025-01-08T12:43:00Z",
          "energy": 52.799999999813735,
          "capacity": 633.5999999977649
      },
      {
          "time_stamp_utc_ms": 1736340360000,
          "time": "2025-01-08T12:48:00Z",
          "energy": 53.27999999932945,
          "capacity": 639.3599999919535
      },
      {
          "time_stamp_utc_ms": 1736340660000,
          "time": "2025-01-08T12:53:00Z",
          "energy": 52.51200000103563,
          "capacity": 630.1440000124276
      },
      {
          "time_stamp_utc_ms": 1736340960000,
          "time": "2025-01-08T12:58:00Z",
          "energy": 53.37600000016391,
          "capacity": 640.512000001967
      },
      {
          "time_stamp_utc_ms": 1736341260000,
          "time": "2025-01-08T13:03:00Z",
          "energy": 51.93599999975413,
          "capacity": 623.2319999970496
      },
      {
          "time_stamp_utc_ms": 1736341560000,
          "time": "2025-01-08T13:08:00Z",
          "energy": 53.8559999987483,
          "capacity": 646.2719999849797
      },
      {
          "time_stamp_utc_ms": 1736341860000,
          "time": "2025-01-08T13:13:00Z",
          "energy": 52.2240000013262,
          "capacity": 626.6880000159144
      },
      {
          "time_stamp_utc_ms": 1736342160000,
          "time": "2025-01-08T13:18:00Z",
          "energy": 53.37599999923259,
          "capacity": 640.5119999907912
      },
      {
          "time_stamp_utc_ms": 1736342460000,
          "time": "2025-01-08T13:23:00Z",
          "energy": 52.99200000055134,
          "capacity": 635.9040000066161
      },
      {
          "time_stamp_utc_ms": 1736342760000,
          "time": "2025-01-08T13:28:00Z",
          "energy": 53.08799999952316,
          "capacity": 637.0559999942781
      },
      {
          "time_stamp_utc_ms": 1736343060000,
          "time": "2025-01-08T13:33:00Z",
          "energy": 53.472000000067055,
          "capacity": 641.6640000008047
      },
      {
          "time_stamp_utc_ms": 1736343360000,
          "time": "2025-01-08T13:38:00Z",
          "energy": 53.088000000454485,
          "capacity": 637.0560000054538
      },
      {
          "time_stamp_utc_ms": 1736343660000,
          "time": "2025-01-08T13:43:00Z",
          "energy": 51.64799999911338,
          "capacity": 619.7759999893606
      },
      {
          "time_stamp_utc_ms": 1736343960000,
          "time": "2025-01-08T13:48:00Z",
          "energy": 53.28000000119209,
          "capacity": 639.3600000143052
      },
      {
          "time_stamp_utc_ms": 1736344260000,
          "time": "2025-01-08T13:53:00Z",
          "energy": 51.74399999901652,
          "capacity": 620.9279999881983
      },
      {
          "time_stamp_utc_ms": 1736344560000,
          "time": "2025-01-08T13:58:00Z",
          "energy": 51.93600000068545,
          "capacity": 623.2320000082256
      },
      {
          "time_stamp_utc_ms": 1736344860000,
          "time": "2025-01-08T14:03:00Z",
          "energy": 52.41599999926984,
          "capacity": 628.9919999912381
      },
      {
          "time_stamp_utc_ms": 1736345160000,
          "time": "2025-01-08T14:08:00Z",
          "energy": 50.7839999999851,
          "capacity": 609.4079999998212
      },
      {
          "time_stamp_utc_ms": 1736345460000,
          "time": "2025-01-08T14:13:00Z",
          "energy": 50.208000000566244,
          "capacity": 602.4960000067949
      },
      {
          "time_stamp_utc_ms": 1736345760000,
          "time": "2025-01-08T14:18:00Z",
          "energy": 49.82400000002235,
          "capacity": 597.8880000002683
      },
      {
          "time_stamp_utc_ms": 1736346060000,
          "time": "2025-01-08T14:23:00Z",
          "energy": 50.208000000566244,
          "capacity": 602.4960000067949
      },
      {
          "time_stamp_utc_ms": 1736346360000,
          "time": "2025-01-08T14:28:00Z",
          "energy": 49.91999999899417,
          "capacity": 599.0399999879301
      },
      {
          "time_stamp_utc_ms": 1736346660000,
          "time": "2025-01-08T14:33:00Z",
          "energy": 51.35999999940395,
          "capacity": 616.3199999928474
      },
      {
          "time_stamp_utc_ms": 1736346960000,
          "time": "2025-01-08T14:38:00Z",
          "energy": 48.768000001087785,
          "capacity": 585.2160000130535
      },
      {
          "time_stamp_utc_ms": 1736347260000,
          "time": "2025-01-08T14:43:00Z",
          "energy": 50.49600000027567,
          "capacity": 605.9520000033081
      },
      {
          "time_stamp_utc_ms": 1736347560000,
          "time": "2025-01-08T14:48:00Z",
          "energy": 48.191999999806285,
          "capacity": 578.3039999976755
      },
      {
          "time_stamp_utc_ms": 1736347860000,
          "time": "2025-01-08T14:53:00Z",
          "energy": 49.919999999925494,
          "capacity": 599.039999999106
      },
      {
          "time_stamp_utc_ms": 1736348160000,
          "time": "2025-01-08T14:58:00Z",
          "energy": 49.72799999918789,
          "capacity": 596.7359999902546
      },
      {
          "time_stamp_utc_ms": 1736348460000,
          "time": "2025-01-08T15:03:00Z",
          "energy": 49.72800000011921,
          "capacity": 596.7360000014306
      },
      {
          "time_stamp_utc_ms": 1736348760000,
          "time": "2025-01-08T15:08:00Z",
          "energy": 49.152000000700355,
          "capacity": 589.8240000084044
      },
      {
          "time_stamp_utc_ms": 1736349060000,
          "time": "2025-01-08T15:13:00Z",
          "energy": 50.01599999982864,
          "capacity": 600.1919999979438
      },
      {
          "time_stamp_utc_ms": 1736349360000,
          "time": "2025-01-08T15:18:00Z",
          "energy": 48.864000000059605,
          "capacity": 586.3680000007153
      },
      {
          "time_stamp_utc_ms": 1736349660000,
          "time": "2025-01-08T15:23:00Z",
          "energy": 49.05600000079721,
          "capacity": 588.6720000095667
      },
      {
          "time_stamp_utc_ms": 1736349960000,
          "time": "2025-01-08T15:28:00Z",
          "energy": 47.61599999945611,
          "capacity": 571.3919999934733
      },
      {
          "time_stamp_utc_ms": 1736350260000,
          "time": "2025-01-08T15:33:00Z",
          "energy": 46.7519999993965,
          "capacity": 561.023999992758
      },
      {
          "time_stamp_utc_ms": 1736350560000,
          "time": "2025-01-08T15:38:00Z",
          "energy": 47.13600000087172,
          "capacity": 565.6320000104606
      },
      {
          "time_stamp_utc_ms": 1736350860000,
          "time": "2025-01-08T15:43:00Z",
          "energy": 46.7519999993965,
          "capacity": 561.023999992758
      },
      {
          "time_stamp_utc_ms": 1736351160000,
          "time": "2025-01-08T15:48:00Z",
          "energy": 48.28800000064075,
          "capacity": 579.456000007689
      },
      {
          "time_stamp_utc_ms": 1736351460000,
          "time": "2025-01-08T15:53:00Z",
          "energy": 47.13599999900907,
          "capacity": 565.6319999881089
      },
      {
          "time_stamp_utc_ms": 1736351760000,
          "time": "2025-01-08T15:58:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1736352060000,
          "time": "2025-01-08T16:03:00Z",
          "energy": 44.44799999985844,
          "capacity": 533.3759999983013
      },
      {
          "time_stamp_utc_ms": 1736352360000,
          "time": "2025-01-08T16:08:00Z",
          "energy": 46.752000000327826,
          "capacity": 561.0240000039339
      },
      {
          "time_stamp_utc_ms": 1736352660000,
          "time": "2025-01-08T16:13:00Z",
          "energy": 46.84800000116229,
          "capacity": 562.1760000139475
      },
      {
          "time_stamp_utc_ms": 1736352960000,
          "time": "2025-01-08T16:18:00Z",
          "energy": 48.09599999897182,
          "capacity": 577.1519999876618
      },
      {
          "time_stamp_utc_ms": 1736353260000,
          "time": "2025-01-08T16:23:00Z",
          "energy": 46.847999999299645,
          "capacity": 562.1759999915957
      },
      {
          "time_stamp_utc_ms": 1736353560000,
          "time": "2025-01-08T16:28:00Z",
          "energy": 48.096000000834465,
          "capacity": 577.1520000100136
      },
      {
          "time_stamp_utc_ms": 1736353860000,
          "time": "2025-01-08T16:33:00Z",
          "energy": 46.368000000715256,
          "capacity": 556.4160000085831
      },
      {
          "time_stamp_utc_ms": 1736354160000,
          "time": "2025-01-08T16:38:00Z",
          "energy": 46.943999998271465,
          "capacity": 563.3279999792577
      },
      {
          "time_stamp_utc_ms": 1736354460000,
          "time": "2025-01-08T16:43:00Z",
          "energy": 47.23200000077486,
          "capacity": 566.7840000092983
      },
      {
          "time_stamp_utc_ms": 1736354760000,
          "time": "2025-01-08T16:48:00Z",
          "energy": 46.368000000715256,
          "capacity": 556.4160000085831
      },
      {
          "time_stamp_utc_ms": 1736355060000,
          "time": "2025-01-08T16:53:00Z",
          "energy": 47.519999999552965,
          "capacity": 570.2399999946356
      },
      {
          "time_stamp_utc_ms": 1736355360000,
          "time": "2025-01-08T16:58:00Z",
          "energy": 47.71200000029057,
          "capacity": 572.5440000034869
      },
      {
          "time_stamp_utc_ms": 1736355660000,
          "time": "2025-01-08T17:03:00Z",
          "energy": 46.65599999949336,
          "capacity": 559.8719999939203
      },
      {
          "time_stamp_utc_ms": 1736355960000,
          "time": "2025-01-08T17:08:00Z",
          "energy": 47.04000000003725,
          "capacity": 564.480000000447
      },
      {
          "time_stamp_utc_ms": 1736356260000,
          "time": "2025-01-08T17:13:00Z",
          "energy": 47.80799999926239,
          "capacity": 573.6959999911487
      },
      {
          "time_stamp_utc_ms": 1736356560000,
          "time": "2025-01-08T17:18:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1736356860000,
          "time": "2025-01-08T17:23:00Z",
          "energy": 45.791999999433756,
          "capacity": 549.5039999932052
      },
      {
          "time_stamp_utc_ms": 1736357160000,
          "time": "2025-01-08T17:28:00Z",
          "energy": 44.44800000078976,
          "capacity": 533.3760000094773
      },
      {
          "time_stamp_utc_ms": 1736357460000,
          "time": "2025-01-08T17:33:00Z",
          "energy": 46.847999999299645,
          "capacity": 562.1759999915957
      },
      {
          "time_stamp_utc_ms": 1736357760000,
          "time": "2025-01-08T17:38:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1736358060000,
          "time": "2025-01-08T17:43:00Z",
          "energy": 45.984000001102686,
          "capacity": 551.8080000132322
      },
      {
          "time_stamp_utc_ms": 1736358360000,
          "time": "2025-01-08T17:48:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1736358660000,
          "time": "2025-01-08T17:53:00Z",
          "energy": 45.119999999180436,
          "capacity": 541.4399999901652
      },
      {
          "time_stamp_utc_ms": 1736358960000,
          "time": "2025-01-08T17:58:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1736359260000,
          "time": "2025-01-08T18:03:00Z",
          "energy": 46.55999999959022,
          "capacity": 558.7199999950826
      },
      {
          "time_stamp_utc_ms": 1736359560000,
          "time": "2025-01-08T18:08:00Z",
          "energy": 46.56000000052154,
          "capacity": 558.7200000062585
      },
      {
          "time_stamp_utc_ms": 1736359860000,
          "time": "2025-01-08T18:13:00Z",
          "energy": 47.71200000029057,
          "capacity": 572.5440000034869
      },
      {
          "time_stamp_utc_ms": 1736360160000,
          "time": "2025-01-08T18:18:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1736360460000,
          "time": "2025-01-08T18:23:00Z",
          "energy": 46.4640000006184,
          "capacity": 557.5680000074208
      },
      {
          "time_stamp_utc_ms": 1736360760000,
          "time": "2025-01-08T18:28:00Z",
          "energy": 46.65599999949336,
          "capacity": 559.8719999939203
      },
      {
          "time_stamp_utc_ms": 1736361060000,
          "time": "2025-01-08T18:33:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1736361360000,
          "time": "2025-01-08T18:38:00Z",
          "energy": 46.65599999949336,
          "capacity": 559.8719999939203
      },
      {
          "time_stamp_utc_ms": 1736361660000,
          "time": "2025-01-08T18:43:00Z",
          "energy": 45.312000000849366,
          "capacity": 543.7440000101924
      },
      {
          "time_stamp_utc_ms": 1736361960000,
          "time": "2025-01-08T18:48:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1736362260000,
          "time": "2025-01-08T18:53:00Z",
          "energy": 41.56800000090152,
          "capacity": 498.8160000108183
      },
      {
          "time_stamp_utc_ms": 1736362560000,
          "time": "2025-01-08T18:58:00Z",
          "energy": 42.52799999900162,
          "capacity": 510.33599998801947
      },
      {
          "time_stamp_utc_ms": 1736362860000,
          "time": "2025-01-08T19:03:00Z",
          "energy": 40.8960000006482,
          "capacity": 490.7520000077784
      },
      {
          "time_stamp_utc_ms": 1736363160000,
          "time": "2025-01-08T19:08:00Z",
          "energy": 41.567999999038875,
          "capacity": 498.81599998846656
      },
      {
          "time_stamp_utc_ms": 1736363460000,
          "time": "2025-01-08T19:13:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1736363760000,
          "time": "2025-01-08T19:18:00Z",
          "energy": 39.74400000087917,
          "capacity": 476.92800001055
      },
      {
          "time_stamp_utc_ms": 1736364060000,
          "time": "2025-01-08T19:23:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1736364360000,
          "time": "2025-01-08T19:28:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1736364660000,
          "time": "2025-01-08T19:33:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1736364960000,
          "time": "2025-01-08T19:38:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1736365260000,
          "time": "2025-01-08T19:43:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1736365560000,
          "time": "2025-01-08T19:48:00Z",
          "energy": 41.760000000707805,
          "capacity": 501.1200000084937
      },
      {
          "time_stamp_utc_ms": 1736365860000,
          "time": "2025-01-08T19:53:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1736366160000,
          "time": "2025-01-08T19:58:00Z",
          "energy": 40.89599999971688,
          "capacity": 490.7519999966026
      },
      {
          "time_stamp_utc_ms": 1736366460000,
          "time": "2025-01-08T20:03:00Z",
          "energy": 42.24000000115484,
          "capacity": 506.8800000138581
      },
      {
          "time_stamp_utc_ms": 1736366760000,
          "time": "2025-01-08T20:08:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1736367060000,
          "time": "2025-01-08T20:13:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1736367360000,
          "time": "2025-01-08T20:18:00Z",
          "energy": 42.4320000000298,
          "capacity": 509.1840000003577
      },
      {
          "time_stamp_utc_ms": 1736367660000,
          "time": "2025-01-08T20:23:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1736367960000,
          "time": "2025-01-08T20:28:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1736368260000,
          "time": "2025-01-08T20:33:00Z",
          "energy": 40.8960000006482,
          "capacity": 490.7520000077784
      },
      {
          "time_stamp_utc_ms": 1736368560000,
          "time": "2025-01-08T20:38:00Z",
          "energy": 40.704000000841916,
          "capacity": 488.448000010103
      },
      {
          "time_stamp_utc_ms": 1736368860000,
          "time": "2025-01-08T20:43:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1736369160000,
          "time": "2025-01-08T20:48:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1736369460000,
          "time": "2025-01-08T20:53:00Z",
          "energy": 43.39199999999255,
          "capacity": 520.7039999999106
      },
      {
          "time_stamp_utc_ms": 1736369760000,
          "time": "2025-01-08T20:58:00Z",
          "energy": 42.81599999871105,
          "capacity": 513.7919999845326
      },
      {
          "time_stamp_utc_ms": 1736370060000,
          "time": "2025-01-08T21:03:00Z",
          "energy": 44.256000000052154,
          "capacity": 531.0720000006258
      },
      {
          "time_stamp_utc_ms": 1736370360000,
          "time": "2025-01-08T21:08:00Z",
          "energy": 47.040000000968575,
          "capacity": 564.4800000116229
      },
      {
          "time_stamp_utc_ms": 1736370660000,
          "time": "2025-01-08T21:13:00Z",
          "energy": 45.791999999433756,
          "capacity": 549.5039999932052
      },
      {
          "time_stamp_utc_ms": 1736370960000,
          "time": "2025-01-08T21:18:00Z",
          "energy": 45.696000000461936,
          "capacity": 548.3520000055432
      },
      {
          "time_stamp_utc_ms": 1736371260000,
          "time": "2025-01-08T21:23:00Z",
          "energy": 47.61599999945611,
          "capacity": 571.3919999934733
      },
      {
          "time_stamp_utc_ms": 1736371560000,
          "time": "2025-01-08T21:28:00Z",
          "energy": 45.79200000036508,
          "capacity": 549.5040000043809
      },
      {
          "time_stamp_utc_ms": 1736371860000,
          "time": "2025-01-08T21:33:00Z",
          "energy": 45.40800000075251,
          "capacity": 544.8960000090301
      },
      {
          "time_stamp_utc_ms": 1736372160000,
          "time": "2025-01-08T21:38:00Z",
          "energy": 46.27199999894947,
          "capacity": 555.2639999873937
      },
      {
          "time_stamp_utc_ms": 1736372460000,
          "time": "2025-01-08T21:43:00Z",
          "energy": 44.73600000049919,
          "capacity": 536.8320000059904
      },
      {
          "time_stamp_utc_ms": 1736372760000,
          "time": "2025-01-08T21:48:00Z",
          "energy": 46.36799999978393,
          "capacity": 556.4159999974072
      },
      {
          "time_stamp_utc_ms": 1736373060000,
          "time": "2025-01-08T21:53:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1736373360000,
          "time": "2025-01-08T21:58:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1736373660000,
          "time": "2025-01-08T22:03:00Z",
          "energy": 47.80799999926239,
          "capacity": 573.6959999911487
      },
      {
          "time_stamp_utc_ms": 1736373960000,
          "time": "2025-01-08T22:08:00Z",
          "energy": 47.61600000038743,
          "capacity": 571.3920000046492
      },
      {
          "time_stamp_utc_ms": 1736374260000,
          "time": "2025-01-08T22:13:00Z",
          "energy": 47.04000000003725,
          "capacity": 564.480000000447
      },
      {
          "time_stamp_utc_ms": 1736374560000,
          "time": "2025-01-08T22:18:00Z",
          "energy": 47.03999999910593,
          "capacity": 564.4799999892712
      },
      {
          "time_stamp_utc_ms": 1736374860000,
          "time": "2025-01-08T22:23:00Z",
          "energy": 46.752000000327826,
          "capacity": 561.0240000039339
      },
      {
          "time_stamp_utc_ms": 1736375160000,
          "time": "2025-01-08T22:28:00Z",
          "energy": 46.94400000013411,
          "capacity": 563.3280000016093
      },
      {
          "time_stamp_utc_ms": 1736375460000,
          "time": "2025-01-08T22:33:00Z",
          "energy": 47.04000000003725,
          "capacity": 564.480000000447
      },
      {
          "time_stamp_utc_ms": 1736375760000,
          "time": "2025-01-08T22:38:00Z",
          "energy": 46.368000000715256,
          "capacity": 556.4160000085831
      },
      {
          "time_stamp_utc_ms": 1736376060000,
          "time": "2025-01-08T22:43:00Z",
          "energy": 47.32799999881536,
          "capacity": 567.9359999857843
      },
      {
          "time_stamp_utc_ms": 1736376360000,
          "time": "2025-01-08T22:48:00Z",
          "energy": 46.36799999978393,
          "capacity": 556.4159999974072
      },
      {
          "time_stamp_utc_ms": 1736376660000,
          "time": "2025-01-08T22:53:00Z",
          "energy": 47.040000000968575,
          "capacity": 564.4800000116229
      },
      {
          "time_stamp_utc_ms": 1736376960000,
          "time": "2025-01-08T22:58:00Z",
          "energy": 47.71199999935925,
          "capacity": 572.543999992311
      },
      {
          "time_stamp_utc_ms": 1736377260000,
          "time": "2025-01-08T23:03:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1736377560000,
          "time": "2025-01-08T23:08:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1736377860000,
          "time": "2025-01-08T23:13:00Z",
          "energy": 45.79200000036508,
          "capacity": 549.5040000043809
      },
      {
          "time_stamp_utc_ms": 1736378160000,
          "time": "2025-01-08T23:18:00Z",
          "energy": 44.92799999937415,
          "capacity": 539.1359999924898
      },
      {
          "time_stamp_utc_ms": 1736378460000,
          "time": "2025-01-08T23:23:00Z",
          "energy": 46.080000000074506,
          "capacity": 552.9600000008941
      },
      {
          "time_stamp_utc_ms": 1736378760000,
          "time": "2025-01-08T23:28:00Z",
          "energy": 44.639999999664724,
          "capacity": 535.6799999959767
      },
      {
          "time_stamp_utc_ms": 1736379060000,
          "time": "2025-01-08T23:33:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1736379360000,
          "time": "2025-01-08T23:38:00Z",
          "energy": 45.7920000012964,
          "capacity": 549.5040000155568
      },
      {
          "time_stamp_utc_ms": 1736379660000,
          "time": "2025-01-08T23:43:00Z",
          "energy": 45.215999998152256,
          "capacity": 542.5919999778271
      },
      {
          "time_stamp_utc_ms": 1736379960000,
          "time": "2025-01-08T23:48:00Z",
          "energy": 46.368000000715256,
          "capacity": 556.4160000085831
      },
      {
          "time_stamp_utc_ms": 1736380260000,
          "time": "2025-01-08T23:53:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1736380560000,
          "time": "2025-01-08T23:58:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1736380860000,
          "time": "2025-01-09T00:03:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1736381160000,
          "time": "2025-01-09T00:08:00Z",
          "energy": 46.94400000013411,
          "capacity": 563.3280000016093
      },
      {
          "time_stamp_utc_ms": 1736381460000,
          "time": "2025-01-09T00:13:00Z",
          "energy": 45.50399999972433,
          "capacity": 546.047999996692
      },
      {
          "time_stamp_utc_ms": 1736381760000,
          "time": "2025-01-09T00:18:00Z",
          "energy": 47.135999999940395,
          "capacity": 565.6319999992847
      },
      {
          "time_stamp_utc_ms": 1736382060000,
          "time": "2025-01-09T00:23:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1736382360000,
          "time": "2025-01-09T00:28:00Z",
          "energy": 46.84800000116229,
          "capacity": 562.1760000139475
      },
      {
          "time_stamp_utc_ms": 1736382660000,
          "time": "2025-01-09T00:33:00Z",
          "energy": 46.847999999299645,
          "capacity": 562.1759999915957
      },
      {
          "time_stamp_utc_ms": 1736382960000,
          "time": "2025-01-09T00:38:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1736383260000,
          "time": "2025-01-09T00:43:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1736383560000,
          "time": "2025-01-09T00:48:00Z",
          "energy": 46.36799999978393,
          "capacity": 556.4159999974072
      },
      {
          "time_stamp_utc_ms": 1736383860000,
          "time": "2025-01-09T00:53:00Z",
          "energy": 45.79200000036508,
          "capacity": 549.5040000043809
      },
      {
          "time_stamp_utc_ms": 1736384160000,
          "time": "2025-01-09T00:58:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1736384460000,
          "time": "2025-01-09T01:03:00Z",
          "energy": 46.65599999949336,
          "capacity": 559.8719999939203
      },
      {
          "time_stamp_utc_ms": 1736384760000,
          "time": "2025-01-09T01:08:00Z",
          "energy": 47.135999999940395,
          "capacity": 565.6319999992847
      },
      {
          "time_stamp_utc_ms": 1736385060000,
          "time": "2025-01-09T01:13:00Z",
          "energy": 48.19200000073761,
          "capacity": 578.3040000088513
      },
      {
          "time_stamp_utc_ms": 1736385360000,
          "time": "2025-01-09T01:18:00Z",
          "energy": 46.65600000042468,
          "capacity": 559.8720000050962
      },
      {
          "time_stamp_utc_ms": 1736385660000,
          "time": "2025-01-09T01:23:00Z",
          "energy": 48.09599999897182,
          "capacity": 577.1519999876618
      },
      {
          "time_stamp_utc_ms": 1736385960000,
          "time": "2025-01-09T01:28:00Z",
          "energy": 48.28799999970943,
          "capacity": 579.4559999965132
      },
      {
          "time_stamp_utc_ms": 1736386260000,
          "time": "2025-01-09T01:33:00Z",
          "energy": 46.752000000327826,
          "capacity": 561.0240000039339
      },
      {
          "time_stamp_utc_ms": 1736386560000,
          "time": "2025-01-09T01:38:00Z",
          "energy": 47.99999999906868,
          "capacity": 575.9999999888241
      },
      {
          "time_stamp_utc_ms": 1736386860000,
          "time": "2025-01-09T01:43:00Z",
          "energy": 47.040000000968575,
          "capacity": 564.4800000116229
      },
      {
          "time_stamp_utc_ms": 1736387160000,
          "time": "2025-01-09T01:48:00Z",
          "energy": 47.61600000038743,
          "capacity": 571.3920000046492
      },
      {
          "time_stamp_utc_ms": 1736387460000,
          "time": "2025-01-09T01:53:00Z",
          "energy": 46.7519999993965,
          "capacity": 561.023999992758
      },
      {
          "time_stamp_utc_ms": 1736387760000,
          "time": "2025-01-09T01:58:00Z",
          "energy": 42.24000000022352,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1736388060000,
          "time": "2025-01-09T02:03:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1736388360000,
          "time": "2025-01-09T02:08:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1736388660000,
          "time": "2025-01-09T02:13:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1736388960000,
          "time": "2025-01-09T02:18:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1736389260000,
          "time": "2025-01-09T02:23:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1736389560000,
          "time": "2025-01-09T02:28:00Z",
          "energy": 40.70399999991059,
          "capacity": 488.4479999989272
      },
      {
          "time_stamp_utc_ms": 1736389860000,
          "time": "2025-01-09T02:33:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1736390160000,
          "time": "2025-01-09T02:38:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1736390460000,
          "time": "2025-01-09T02:43:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1736390760000,
          "time": "2025-01-09T02:48:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1736391060000,
          "time": "2025-01-09T02:53:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1736391360000,
          "time": "2025-01-09T02:58:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1736391660000,
          "time": "2025-01-09T03:03:00Z",
          "energy": 39.552000001072884,
          "capacity": 474.6240000128746
      },
      {
          "time_stamp_utc_ms": 1736391960000,
          "time": "2025-01-09T03:08:00Z",
          "energy": 37.82400000002235,
          "capacity": 453.8880000002683
      },
      {
          "time_stamp_utc_ms": 1736392260000,
          "time": "2025-01-09T03:13:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1736392560000,
          "time": "2025-01-09T03:18:00Z",
          "energy": 37.63200000114739,
          "capacity": 451.58400001376873
      },
      {
          "time_stamp_utc_ms": 1736392860000,
          "time": "2025-01-09T03:23:00Z",
          "energy": 38.49599999934435,
          "capacity": 461.95199999213224
      },
      {
          "time_stamp_utc_ms": 1736393160000,
          "time": "2025-01-09T03:28:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1736393460000,
          "time": "2025-01-09T03:33:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1736393760000,
          "time": "2025-01-09T03:38:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1736394060000,
          "time": "2025-01-09T03:43:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1736394360000,
          "time": "2025-01-09T03:48:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1736394660000,
          "time": "2025-01-09T03:53:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1736394960000,
          "time": "2025-01-09T03:58:00Z",
          "energy": 37.63200000021607,
          "capacity": 451.58400000259286
      },
      {
          "time_stamp_utc_ms": 1736395260000,
          "time": "2025-01-09T04:03:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1736395560000,
          "time": "2025-01-09T04:08:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1736395860000,
          "time": "2025-01-09T04:13:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1736396160000,
          "time": "2025-01-09T04:18:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1736396460000,
          "time": "2025-01-09T04:23:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1736396760000,
          "time": "2025-01-09T04:28:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1736397060000,
          "time": "2025-01-09T04:33:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1736397360000,
          "time": "2025-01-09T04:38:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1736397660000,
          "time": "2025-01-09T04:43:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1736397960000,
          "time": "2025-01-09T04:48:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1736398260000,
          "time": "2025-01-09T04:53:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1736398560000,
          "time": "2025-01-09T04:58:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1736398860000,
          "time": "2025-01-09T05:03:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1736399160000,
          "time": "2025-01-09T05:08:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1736399460000,
          "time": "2025-01-09T05:13:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1736399760000,
          "time": "2025-01-09T05:18:00Z",
          "energy": 39.16800000052899,
          "capacity": 470.0160000063479
      },
      {
          "time_stamp_utc_ms": 1736400060000,
          "time": "2025-01-09T05:23:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1736400360000,
          "time": "2025-01-09T05:28:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1736400660000,
          "time": "2025-01-09T05:33:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1736400960000,
          "time": "2025-01-09T05:38:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1736401260000,
          "time": "2025-01-09T05:43:00Z",
          "energy": 38.30399999860674,
          "capacity": 459.6479999832809
      },
      {
          "time_stamp_utc_ms": 1736401560000,
          "time": "2025-01-09T05:48:00Z",
          "energy": 38.59200000111014,
          "capacity": 463.10400001332164
      },
      {
          "time_stamp_utc_ms": 1736401860000,
          "time": "2025-01-09T05:53:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1736402160000,
          "time": "2025-01-09T05:58:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1736402460000,
          "time": "2025-01-09T06:03:00Z",
          "energy": 38.78400000091642,
          "capacity": 465.40800001099706
      },
      {
          "time_stamp_utc_ms": 1736402760000,
          "time": "2025-01-09T06:08:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1736403060000,
          "time": "2025-01-09T06:13:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1736403360000,
          "time": "2025-01-09T06:18:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1736403660000,
          "time": "2025-01-09T06:23:00Z",
          "energy": 40.3199999993667,
          "capacity": 483.8399999924004
      },
      {
          "time_stamp_utc_ms": 1736403960000,
          "time": "2025-01-09T06:28:00Z",
          "energy": 38.59200000111014,
          "capacity": 463.10400001332164
      },
      {
          "time_stamp_utc_ms": 1736404260000,
          "time": "2025-01-09T06:33:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1736404560000,
          "time": "2025-01-09T06:38:00Z",
          "energy": 38.59199999924749,
          "capacity": 463.10399999096995
      },
      {
          "time_stamp_utc_ms": 1736404860000,
          "time": "2025-01-09T06:43:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1736405160000,
          "time": "2025-01-09T06:48:00Z",
          "energy": 39.167999998666346,
          "capacity": 470.0159999839962
      },
      {
          "time_stamp_utc_ms": 1736405460000,
          "time": "2025-01-09T06:53:00Z",
          "energy": 39.3600000012666,
          "capacity": 472.3200000151992
      },
      {
          "time_stamp_utc_ms": 1736405760000,
          "time": "2025-01-09T06:58:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1736406060000,
          "time": "2025-01-09T07:03:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1736406360000,
          "time": "2025-01-09T07:08:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1736406660000,
          "time": "2025-01-09T07:13:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1736406960000,
          "time": "2025-01-09T07:18:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1736407260000,
          "time": "2025-01-09T07:23:00Z",
          "energy": 40.032000000588596,
          "capacity": 480.3840000070632
      },
      {
          "time_stamp_utc_ms": 1736407560000,
          "time": "2025-01-09T07:28:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1736407860000,
          "time": "2025-01-09T07:33:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1736408160000,
          "time": "2025-01-09T07:38:00Z",
          "energy": 38.59200000111014,
          "capacity": 463.10400001332164
      },
      {
          "time_stamp_utc_ms": 1736408460000,
          "time": "2025-01-09T07:43:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1736408760000,
          "time": "2025-01-09T07:48:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1736409060000,
          "time": "2025-01-09T07:53:00Z",
          "energy": 39.07200000062585,
          "capacity": 468.8640000075102
      },
      {
          "time_stamp_utc_ms": 1736409360000,
          "time": "2025-01-09T07:58:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1736409660000,
          "time": "2025-01-09T08:03:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1736409960000,
          "time": "2025-01-09T08:08:00Z",
          "energy": 39.16799999959767,
          "capacity": 470.0159999951721
      },
      {
          "time_stamp_utc_ms": 1736410260000,
          "time": "2025-01-09T08:13:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1736410560000,
          "time": "2025-01-09T08:18:00Z",
          "energy": 39.84000000078231,
          "capacity": 478.08000000938773
      },
      {
          "time_stamp_utc_ms": 1736410860000,
          "time": "2025-01-09T08:23:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1736411160000,
          "time": "2025-01-09T08:28:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1736411460000,
          "time": "2025-01-09T08:33:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1736411760000,
          "time": "2025-01-09T08:38:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1736412060000,
          "time": "2025-01-09T08:43:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1736412360000,
          "time": "2025-01-09T08:48:00Z",
          "energy": 39.455999999307096,
          "capacity": 473.4719999916852
      },
      {
          "time_stamp_utc_ms": 1736412660000,
          "time": "2025-01-09T08:53:00Z",
          "energy": 39.648000000976026,
          "capacity": 475.7760000117123
      },
      {
          "time_stamp_utc_ms": 1736412960000,
          "time": "2025-01-09T08:58:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1736413260000,
          "time": "2025-01-09T09:03:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1736413560000,
          "time": "2025-01-09T09:08:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1736413860000,
          "time": "2025-01-09T09:13:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1736414160000,
          "time": "2025-01-09T09:18:00Z",
          "energy": 40.127999999560416,
          "capacity": 481.535999994725
      },
      {
          "time_stamp_utc_ms": 1736414460000,
          "time": "2025-01-09T09:23:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1736414760000,
          "time": "2025-01-09T09:28:00Z",
          "energy": 40.12800000049174,
          "capacity": 481.5360000059009
      },
      {
          "time_stamp_utc_ms": 1736415060000,
          "time": "2025-01-09T09:33:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1736415360000,
          "time": "2025-01-09T09:38:00Z",
          "energy": 39.26399999950081,
          "capacity": 471.1679999940098
      },
      {
          "time_stamp_utc_ms": 1736415660000,
          "time": "2025-01-09T09:43:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1736415960000,
          "time": "2025-01-09T09:48:00Z",
          "energy": 38.78400000091642,
          "capacity": 465.40800001099706
      },
      {
          "time_stamp_utc_ms": 1736416260000,
          "time": "2025-01-09T09:53:00Z",
          "energy": 40.895999998785555,
          "capacity": 490.75199998542666
      },
      {
          "time_stamp_utc_ms": 1736416560000,
          "time": "2025-01-09T09:58:00Z",
          "energy": 46.4640000006184,
          "capacity": 557.5680000074208
      },
      {
          "time_stamp_utc_ms": 1736416860000,
          "time": "2025-01-09T10:03:00Z",
          "energy": 47.61599999945611,
          "capacity": 571.3919999934733
      },
      {
          "time_stamp_utc_ms": 1736417160000,
          "time": "2025-01-09T10:08:00Z",
          "energy": 48.57600000035018,
          "capacity": 582.9120000042021
      },
      {
          "time_stamp_utc_ms": 1736417460000,
          "time": "2025-01-09T10:13:00Z",
          "energy": 49.631999999284744,
          "capacity": 595.5839999914169
      },
      {
          "time_stamp_utc_ms": 1736417760000,
          "time": "2025-01-09T10:18:00Z",
          "energy": 49.53600000124425,
          "capacity": 594.432000014931
      },
      {
          "time_stamp_utc_ms": 1736418060000,
          "time": "2025-01-09T10:23:00Z",
          "energy": 49.82399999815971,
          "capacity": 597.8879999779166
      },
      {
          "time_stamp_utc_ms": 1736418360000,
          "time": "2025-01-09T10:28:00Z",
          "energy": 48.57600000035018,
          "capacity": 582.9120000042021
      },
      {
          "time_stamp_utc_ms": 1736418660000,
          "time": "2025-01-09T10:33:00Z",
          "energy": 50.40000000037253,
          "capacity": 604.8000000044703
      },
      {
          "time_stamp_utc_ms": 1736418960000,
          "time": "2025-01-09T10:38:00Z",
          "energy": 50.592000000178814,
          "capacity": 607.1040000021458
      },
      {
          "time_stamp_utc_ms": 1736419260000,
          "time": "2025-01-09T10:43:00Z",
          "energy": 50.1120000006631,
          "capacity": 601.3440000079572
      },
      {
          "time_stamp_utc_ms": 1736419560000,
          "time": "2025-01-09T10:48:00Z",
          "energy": 50.687999999150634,
          "capacity": 608.2559999898077
      },
      {
          "time_stamp_utc_ms": 1736419860000,
          "time": "2025-01-09T10:53:00Z",
          "energy": 50.20799999963492,
          "capacity": 602.4959999956192
      },
      {
          "time_stamp_utc_ms": 1736420160000,
          "time": "2025-01-09T10:58:00Z",
          "energy": 51.45600000023842,
          "capacity": 617.472000002861
      },
      {
          "time_stamp_utc_ms": 1736420460000,
          "time": "2025-01-09T11:03:00Z",
          "energy": 52.032000000588596,
          "capacity": 624.3840000070633
      },
      {
          "time_stamp_utc_ms": 1736420760000,
          "time": "2025-01-09T11:08:00Z",
          "energy": 51.07200000062585,
          "capacity": 612.8640000075102
      },
      {
          "time_stamp_utc_ms": 1736421060000,
          "time": "2025-01-09T11:13:00Z",
          "energy": 52.895999998785555,
          "capacity": 634.7519999854267
      },
      {
          "time_stamp_utc_ms": 1736421360000,
          "time": "2025-01-09T11:18:00Z",
          "energy": 50.68800000101328,
          "capacity": 608.2560000121593
      },
      {
          "time_stamp_utc_ms": 1736421660000,
          "time": "2025-01-09T11:23:00Z",
          "energy": 52.03199999965727,
          "capacity": 624.3839999958873
      },
      {
          "time_stamp_utc_ms": 1736421960000,
          "time": "2025-01-09T11:28:00Z",
          "energy": 52.12800000049174,
          "capacity": 625.536000005901
      },
      {
          "time_stamp_utc_ms": 1736422260000,
          "time": "2025-01-09T11:33:00Z",
          "energy": 52.60799999907613,
          "capacity": 631.2959999889135
      },
      {
          "time_stamp_utc_ms": 1736422560000,
          "time": "2025-01-09T11:38:00Z",
          "energy": 52.32000000029802,
          "capacity": 627.8400000035764
      },
      {
          "time_stamp_utc_ms": 1736422860000,
          "time": "2025-01-09T11:43:00Z",
          "energy": 53.183999999426305,
          "capacity": 638.2079999931158
      },
      {
          "time_stamp_utc_ms": 1736423160000,
          "time": "2025-01-09T11:48:00Z",
          "energy": 53.37600000016391,
          "capacity": 640.512000001967
      },
      {
          "time_stamp_utc_ms": 1736423460000,
          "time": "2025-01-09T11:53:00Z",
          "energy": 54.24000000022352,
          "capacity": 650.8800000026823
      },
      {
          "time_stamp_utc_ms": 1736423760000,
          "time": "2025-01-09T11:58:00Z",
          "energy": 54.911999999545515,
          "capacity": 658.9439999945463
      },
      {
          "time_stamp_utc_ms": 1736424060000,
          "time": "2025-01-09T12:03:00Z",
          "energy": 53.75999999977648,
          "capacity": 645.1199999973178
      },
      {
          "time_stamp_utc_ms": 1736424360000,
          "time": "2025-01-09T12:08:00Z",
          "energy": 54.816000000573695,
          "capacity": 657.7920000068843
      },
      {
          "time_stamp_utc_ms": 1736424660000,
          "time": "2025-01-09T12:13:00Z",
          "energy": 54.33600000012666,
          "capacity": 652.03200000152
      },
      {
          "time_stamp_utc_ms": 1736424960000,
          "time": "2025-01-09T12:18:00Z",
          "energy": 53.75999999977648,
          "capacity": 645.1199999973178
      },
      {
          "time_stamp_utc_ms": 1736425260000,
          "time": "2025-01-09T12:23:00Z",
          "energy": 54.33600000105798,
          "capacity": 652.0320000126958
      },
      {
          "time_stamp_utc_ms": 1736425560000,
          "time": "2025-01-09T12:28:00Z",
          "energy": 54.71999999880791,
          "capacity": 656.6399999856949
      },
      {
          "time_stamp_utc_ms": 1736425860000,
          "time": "2025-01-09T12:33:00Z",
          "energy": 55.6800000006333,
          "capacity": 668.1600000075996
      },
      {
          "time_stamp_utc_ms": 1736426160000,
          "time": "2025-01-09T12:38:00Z",
          "energy": 55.39199999999255,
          "capacity": 664.7039999999106
      },
      {
          "time_stamp_utc_ms": 1736426460000,
          "time": "2025-01-09T12:43:00Z",
          "energy": 54.81599999964237,
          "capacity": 657.7919999957086
      },
      {
          "time_stamp_utc_ms": 1736426760000,
          "time": "2025-01-09T12:48:00Z",
          "energy": 55.58400000073016,
          "capacity": 667.0080000087619
      },
      {
          "time_stamp_utc_ms": 1736427060000,
          "time": "2025-01-09T12:53:00Z",
          "energy": 54.52799999900162,
          "capacity": 654.3359999880195
      },
      {
          "time_stamp_utc_ms": 1736427360000,
          "time": "2025-01-09T12:58:00Z",
          "energy": 54.527999999932945,
          "capacity": 654.3359999991955
      },
      {
          "time_stamp_utc_ms": 1736427660000,
          "time": "2025-01-09T13:03:00Z",
          "energy": 55.00800000037998,
          "capacity": 660.0960000045598
      },
      {
          "time_stamp_utc_ms": 1736427960000,
          "time": "2025-01-09T13:08:00Z",
          "energy": 54.81599999964237,
          "capacity": 657.7919999957086
      },
      {
          "time_stamp_utc_ms": 1736428260000,
          "time": "2025-01-09T13:13:00Z",
          "energy": 56.16000000014901,
          "capacity": 673.9200000017881
      },
      {
          "time_stamp_utc_ms": 1736428560000,
          "time": "2025-01-09T13:18:00Z",
          "energy": 55.39200000092387,
          "capacity": 664.7040000110865
      },
      {
          "time_stamp_utc_ms": 1736428860000,
          "time": "2025-01-09T13:23:00Z",
          "energy": 54.623999998904765,
          "capacity": 655.4879999868572
      },
      {
          "time_stamp_utc_ms": 1736429160000,
          "time": "2025-01-09T13:28:00Z",
          "energy": 54.04800000041723,
          "capacity": 648.5760000050069
      },
      {
          "time_stamp_utc_ms": 1736429460000,
          "time": "2025-01-09T13:33:00Z",
          "energy": 55.39199999999255,
          "capacity": 664.7039999999106
      },
      {
          "time_stamp_utc_ms": 1736429760000,
          "time": "2025-01-09T13:38:00Z",
          "energy": 55.10400000028312,
          "capacity": 661.2480000033975
      },
      {
          "time_stamp_utc_ms": 1736430060000,
          "time": "2025-01-09T13:43:00Z",
          "energy": 56.16000000014901,
          "capacity": 673.9200000017881
      },
      {
          "time_stamp_utc_ms": 1736430360000,
          "time": "2025-01-09T13:48:00Z",
          "energy": 53.567999999038875,
          "capacity": 642.8159999884666
      },
      {
          "time_stamp_utc_ms": 1736430660000,
          "time": "2025-01-09T13:53:00Z",
          "energy": 54.816000000573695,
          "capacity": 657.7920000068843
      },
      {
          "time_stamp_utc_ms": 1736430960000,
          "time": "2025-01-09T13:58:00Z",
          "energy": 54.33600000012666,
          "capacity": 652.03200000152
      },
      {
          "time_stamp_utc_ms": 1736431260000,
          "time": "2025-01-09T14:03:00Z",
          "energy": 54.14399999845773,
          "capacity": 649.7279999814929
      },
      {
          "time_stamp_utc_ms": 1736431560000,
          "time": "2025-01-09T14:08:00Z",
          "energy": 54.62400000169873,
          "capacity": 655.4880000203848
      },
      {
          "time_stamp_utc_ms": 1736431860000,
          "time": "2025-01-09T14:13:00Z",
          "energy": 52.511999999172986,
          "capacity": 630.1439999900758
      },
      {
          "time_stamp_utc_ms": 1736432160000,
          "time": "2025-01-09T14:18:00Z",
          "energy": 52.416000000201166,
          "capacity": 628.9920000024141
      },
      {
          "time_stamp_utc_ms": 1736432460000,
          "time": "2025-01-09T14:23:00Z",
          "energy": 52.032000000588596,
          "capacity": 624.3840000070633
      },
      {
          "time_stamp_utc_ms": 1736432760000,
          "time": "2025-01-09T14:28:00Z",
          "energy": 50.87999999988824,
          "capacity": 610.5599999986589
      },
      {
          "time_stamp_utc_ms": 1736433060000,
          "time": "2025-01-09T14:33:00Z",
          "energy": 50.975999999791384,
          "capacity": 611.7119999974966
      },
      {
          "time_stamp_utc_ms": 1736433360000,
          "time": "2025-01-09T14:38:00Z",
          "energy": 51.360000000335276,
          "capacity": 616.3200000040233
      },
      {
          "time_stamp_utc_ms": 1736433660000,
          "time": "2025-01-09T14:43:00Z",
          "energy": 52.22399999946356,
          "capacity": 626.6879999935627
      },
      {
          "time_stamp_utc_ms": 1736433960000,
          "time": "2025-01-09T14:48:00Z",
          "energy": 52.22400000039488,
          "capacity": 626.6880000047387
      },
      {
          "time_stamp_utc_ms": 1736434260000,
          "time": "2025-01-09T14:53:00Z",
          "energy": 50.303999999538064,
          "capacity": 603.6479999944569
      },
      {
          "time_stamp_utc_ms": 1736434560000,
          "time": "2025-01-09T14:58:00Z",
          "energy": 50.688000000081956,
          "capacity": 608.2560000009835
      },
      {
          "time_stamp_utc_ms": 1736434860000,
          "time": "2025-01-09T15:03:00Z",
          "energy": 50.688000000081956,
          "capacity": 608.2560000009835
      },
      {
          "time_stamp_utc_ms": 1736435160000,
          "time": "2025-01-09T15:08:00Z",
          "energy": 49.63200000021607,
          "capacity": 595.5840000025929
      },
      {
          "time_stamp_utc_ms": 1736435460000,
          "time": "2025-01-09T15:13:00Z",
          "energy": 50.01599999982864,
          "capacity": 600.1919999979438
      },
      {
          "time_stamp_utc_ms": 1736435760000,
          "time": "2025-01-09T15:18:00Z",
          "energy": 49.536000000312924,
          "capacity": 594.4320000037552
      },
      {
          "time_stamp_utc_ms": 1736436060000,
          "time": "2025-01-09T15:23:00Z",
          "energy": 48.480000000447035,
          "capacity": 581.7600000053644
      },
      {
          "time_stamp_utc_ms": 1736436360000,
          "time": "2025-01-09T15:28:00Z",
          "energy": 49.05599999986589,
          "capacity": 588.6719999983907
      },
      {
          "time_stamp_utc_ms": 1736436660000,
          "time": "2025-01-09T15:33:00Z",
          "energy": 47.32799999974668,
          "capacity": 567.9359999969602
      },
      {
          "time_stamp_utc_ms": 1736436960000,
          "time": "2025-01-09T15:38:00Z",
          "energy": 47.80799999926239,
          "capacity": 573.6959999911487
      },
      {
          "time_stamp_utc_ms": 1736437260000,
          "time": "2025-01-09T15:43:00Z",
          "energy": 49.05600000079721,
          "capacity": 588.6720000095667
      },
      {
          "time_stamp_utc_ms": 1736437560000,
          "time": "2025-01-09T15:48:00Z",
          "energy": 48.28799999970943,
          "capacity": 579.4559999965132
      },
      {
          "time_stamp_utc_ms": 1736437860000,
          "time": "2025-01-09T15:53:00Z",
          "energy": 47.32799999974668,
          "capacity": 567.9359999969602
      },
      {
          "time_stamp_utc_ms": 1736438160000,
          "time": "2025-01-09T15:58:00Z",
          "energy": 47.424000000581145,
          "capacity": 569.0880000069737
      },
      {
          "time_stamp_utc_ms": 1736438460000,
          "time": "2025-01-09T16:03:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1736438760000,
          "time": "2025-01-09T16:08:00Z",
          "energy": 47.42399999964982,
          "capacity": 569.0879999957979
      },
      {
          "time_stamp_utc_ms": 1736439060000,
          "time": "2025-01-09T16:13:00Z",
          "energy": 46.27200000081211,
          "capacity": 555.2640000097454
      },
      {
          "time_stamp_utc_ms": 1736439360000,
          "time": "2025-01-09T16:18:00Z",
          "energy": 45.69599999953061,
          "capacity": 548.3519999943675
      },
      {
          "time_stamp_utc_ms": 1736439660000,
          "time": "2025-01-09T16:23:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1736439960000,
          "time": "2025-01-09T16:28:00Z",
          "energy": 44.83200000040233,
          "capacity": 537.9840000048281
      },
      {
          "time_stamp_utc_ms": 1736440260000,
          "time": "2025-01-09T16:33:00Z",
          "energy": 45.791999999433756,
          "capacity": 549.5039999932052
      },
      {
          "time_stamp_utc_ms": 1736440560000,
          "time": "2025-01-09T16:38:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1736440860000,
          "time": "2025-01-09T16:43:00Z",
          "energy": 44.92799999937415,
          "capacity": 539.1359999924898
      },
      {
          "time_stamp_utc_ms": 1736441160000,
          "time": "2025-01-09T16:48:00Z",
          "energy": 45.40800000075251,
          "capacity": 544.8960000090301
      },
      {
          "time_stamp_utc_ms": 1736441460000,
          "time": "2025-01-09T16:53:00Z",
          "energy": 44.735999998636544,
          "capacity": 536.8319999836385
      },
      {
          "time_stamp_utc_ms": 1736441760000,
          "time": "2025-01-09T16:58:00Z",
          "energy": 43.29600000102073,
          "capacity": 519.5520000122488
      },
      {
          "time_stamp_utc_ms": 1736442060000,
          "time": "2025-01-09T17:03:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1736442360000,
          "time": "2025-01-09T17:08:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1736442660000,
          "time": "2025-01-09T17:13:00Z",
          "energy": 43.583999999798834,
          "capacity": 523.007999997586
      },
      {
          "time_stamp_utc_ms": 1736442960000,
          "time": "2025-01-09T17:18:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1736443260000,
          "time": "2025-01-09T17:23:00Z",
          "energy": 43.0080000013113,
          "capacity": 516.0960000157356
      },
      {
          "time_stamp_utc_ms": 1736443560000,
          "time": "2025-01-09T17:28:00Z",
          "energy": 43.1039999993518,
          "capacity": 517.2479999922216
      },
      {
          "time_stamp_utc_ms": 1736443860000,
          "time": "2025-01-09T17:33:00Z",
          "energy": 42.04799999948591,
          "capacity": 504.5759999938309
      },
      {
          "time_stamp_utc_ms": 1736444160000,
          "time": "2025-01-09T17:38:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1736444460000,
          "time": "2025-01-09T17:43:00Z",
          "energy": 42.24000000022352,
          "capacity": 506.88000000268227
      },
      {
          "time_stamp_utc_ms": 1736444760000,
          "time": "2025-01-09T17:48:00Z",
          "energy": 41.472000000067055,
          "capacity": 497.66400000080466
      },
      {
          "time_stamp_utc_ms": 1736445060000,
          "time": "2025-01-09T17:53:00Z",
          "energy": 41.855999999679625,
          "capacity": 502.2719999961555
      },
      {
          "time_stamp_utc_ms": 1736445360000,
          "time": "2025-01-09T17:58:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1736445660000,
          "time": "2025-01-09T18:03:00Z",
          "energy": 40.2240000013262,
          "capacity": 482.68800001591444
      },
      {
          "time_stamp_utc_ms": 1736445960000,
          "time": "2025-01-09T18:08:00Z",
          "energy": 41.37599999923259,
          "capacity": 496.51199999079114
      },
      {
          "time_stamp_utc_ms": 1736446260000,
          "time": "2025-01-09T18:13:00Z",
          "energy": 40.799999999813735,
          "capacity": 489.5999999977649
      },
      {
          "time_stamp_utc_ms": 1736446560000,
          "time": "2025-01-09T18:18:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1736446860000,
          "time": "2025-01-09T18:23:00Z",
          "energy": 41.85600000061095,
          "capacity": 502.2720000073314
      },
      {
          "time_stamp_utc_ms": 1736447160000,
          "time": "2025-01-09T18:28:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1736447460000,
          "time": "2025-01-09T18:33:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1736447760000,
          "time": "2025-01-09T18:38:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1736448060000,
          "time": "2025-01-09T18:43:00Z",
          "energy": 40.99199999962002,
          "capacity": 491.9039999954403
      },
      {
          "time_stamp_utc_ms": 1736448360000,
          "time": "2025-01-09T18:48:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1736448660000,
          "time": "2025-01-09T18:53:00Z",
          "energy": 41.18400000035763,
          "capacity": 494.20800000429153
      },
      {
          "time_stamp_utc_ms": 1736448960000,
          "time": "2025-01-09T18:58:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1736449260000,
          "time": "2025-01-09T19:03:00Z",
          "energy": 42.239999999292195,
          "capacity": 506.87999999150634
      },
      {
          "time_stamp_utc_ms": 1736449560000,
          "time": "2025-01-09T19:08:00Z",
          "energy": 40.60800000093877,
          "capacity": 487.2960000112653
      },
      {
          "time_stamp_utc_ms": 1736449860000,
          "time": "2025-01-09T19:13:00Z",
          "energy": 42.33600000012666,
          "capacity": 508.03200000152
      },
      {
          "time_stamp_utc_ms": 1736450160000,
          "time": "2025-01-09T19:18:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1736450460000,
          "time": "2025-01-09T19:23:00Z",
          "energy": 40.99199999962002,
          "capacity": 491.9039999954403
      },
      {
          "time_stamp_utc_ms": 1736450760000,
          "time": "2025-01-09T19:28:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1736451060000,
          "time": "2025-01-09T19:33:00Z",
          "energy": 41.376000001095235,
          "capacity": 496.5120000131429
      },
      {
          "time_stamp_utc_ms": 1736451360000,
          "time": "2025-01-09T19:38:00Z",
          "energy": 41.47199999913573,
          "capacity": 497.66399998962885
      },
      {
          "time_stamp_utc_ms": 1736451660000,
          "time": "2025-01-09T19:43:00Z",
          "energy": 41.75999999977648,
          "capacity": 501.1199999973178
      },
      {
          "time_stamp_utc_ms": 1736451960000,
          "time": "2025-01-09T19:48:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1736452260000,
          "time": "2025-01-09T19:53:00Z",
          "energy": 42.91200000047684,
          "capacity": 514.944000005722
      },
      {
          "time_stamp_utc_ms": 1736452560000,
          "time": "2025-01-09T19:58:00Z",
          "energy": 41.183999999426305,
          "capacity": 494.2079999931157
      },
      {
          "time_stamp_utc_ms": 1736452860000,
          "time": "2025-01-09T20:03:00Z",
          "energy": 42.432000000961125,
          "capacity": 509.1840000115335
      },
      {
          "time_stamp_utc_ms": 1736453160000,
          "time": "2025-01-09T20:08:00Z",
          "energy": 42.81599999964237,
          "capacity": 513.7919999957085
      },
      {
          "time_stamp_utc_ms": 1736453460000,
          "time": "2025-01-09T20:13:00Z",
          "energy": 43.10400000028312,
          "capacity": 517.2480000033975
      },
      {
          "time_stamp_utc_ms": 1736453760000,
          "time": "2025-01-09T20:18:00Z",
          "energy": 44.735999999567866,
          "capacity": 536.8319999948144
      },
      {
          "time_stamp_utc_ms": 1736454060000,
          "time": "2025-01-09T20:23:00Z",
          "energy": 44.54399999976158,
          "capacity": 534.527999997139
      },
      {
          "time_stamp_utc_ms": 1736454360000,
          "time": "2025-01-09T20:28:00Z",
          "energy": 43.29600000008941,
          "capacity": 519.5520000010729
      },
      {
          "time_stamp_utc_ms": 1736454660000,
          "time": "2025-01-09T20:33:00Z",
          "energy": 42.816000000573695,
          "capacity": 513.7920000068843
      },
      {
          "time_stamp_utc_ms": 1736454960000,
          "time": "2025-01-09T20:38:00Z",
          "energy": 42.33599999919534,
          "capacity": 508.03199999034405
      },
      {
          "time_stamp_utc_ms": 1736455260000,
          "time": "2025-01-09T20:43:00Z",
          "energy": 43.488000000827014,
          "capacity": 521.8560000099242
      },
      {
          "time_stamp_utc_ms": 1736455560000,
          "time": "2025-01-09T20:48:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1736455860000,
          "time": "2025-01-09T20:53:00Z",
          "energy": 42.527999999932945,
          "capacity": 510.3359999991954
      },
      {
          "time_stamp_utc_ms": 1736456160000,
          "time": "2025-01-09T20:58:00Z",
          "energy": 45.024000000208616,
          "capacity": 540.2880000025035
      },
      {
          "time_stamp_utc_ms": 1736456460000,
          "time": "2025-01-09T21:03:00Z",
          "energy": 45.2160000000149,
          "capacity": 542.5920000001789
      },
      {
          "time_stamp_utc_ms": 1736456760000,
          "time": "2025-01-09T21:08:00Z",
          "energy": 45.696000000461936,
          "capacity": 548.3520000055432
      },
      {
          "time_stamp_utc_ms": 1736457060000,
          "time": "2025-01-09T21:13:00Z",
          "energy": 46.27199999894947,
          "capacity": 555.2639999873937
      },
      {
          "time_stamp_utc_ms": 1736457360000,
          "time": "2025-01-09T21:18:00Z",
          "energy": 47.23199999984354,
          "capacity": 566.7839999981225
      },
      {
          "time_stamp_utc_ms": 1736457660000,
          "time": "2025-01-09T21:23:00Z",
          "energy": 46.08000000100583,
          "capacity": 552.9600000120699
      },
      {
          "time_stamp_utc_ms": 1736457960000,
          "time": "2025-01-09T21:28:00Z",
          "energy": 48.575999999418855,
          "capacity": 582.9119999930264
      },
      {
          "time_stamp_utc_ms": 1736458260000,
          "time": "2025-01-09T21:33:00Z",
          "energy": 46.752000000327826,
          "capacity": 561.0240000039339
      },
      {
          "time_stamp_utc_ms": 1736458560000,
          "time": "2025-01-09T21:38:00Z",
          "energy": 46.7519999993965,
          "capacity": 561.023999992758
      },
      {
          "time_stamp_utc_ms": 1736458860000,
          "time": "2025-01-09T21:43:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1736459160000,
          "time": "2025-01-09T21:48:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1736459460000,
          "time": "2025-01-09T21:53:00Z",
          "energy": 45.88800000026822,
          "capacity": 550.6560000032187
      },
      {
          "time_stamp_utc_ms": 1736459760000,
          "time": "2025-01-09T21:58:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1736460060000,
          "time": "2025-01-09T22:03:00Z",
          "energy": 46.94399999920279,
          "capacity": 563.3279999904335
      },
      {
          "time_stamp_utc_ms": 1736460360000,
          "time": "2025-01-09T22:08:00Z",
          "energy": 49.05599999986589,
          "capacity": 588.6719999983907
      },
      {
          "time_stamp_utc_ms": 1736460660000,
          "time": "2025-01-09T22:13:00Z",
          "energy": 47.71200000029057,
          "capacity": 572.5440000034869
      },
      {
          "time_stamp_utc_ms": 1736460960000,
          "time": "2025-01-09T22:18:00Z",
          "energy": 48.09599999990314,
          "capacity": 577.1519999988378
      },
      {
          "time_stamp_utc_ms": 1736461260000,
          "time": "2025-01-09T22:23:00Z",
          "energy": 48.57600000035018,
          "capacity": 582.9120000042021
      },
      {
          "time_stamp_utc_ms": 1736461560000,
          "time": "2025-01-09T22:28:00Z",
          "energy": 47.61599999945611,
          "capacity": 571.3919999934733
      },
      {
          "time_stamp_utc_ms": 1736461860000,
          "time": "2025-01-09T22:33:00Z",
          "energy": 48.00000000093132,
          "capacity": 576.0000000111759
      },
      {
          "time_stamp_utc_ms": 1736462160000,
          "time": "2025-01-09T22:38:00Z",
          "energy": 46.55999999959022,
          "capacity": 558.7199999950826
      },
      {
          "time_stamp_utc_ms": 1736462460000,
          "time": "2025-01-09T22:43:00Z",
          "energy": 46.84800000116229,
          "capacity": 562.1760000139475
      },
      {
          "time_stamp_utc_ms": 1736462760000,
          "time": "2025-01-09T22:48:00Z",
          "energy": 46.65599999856204,
          "capacity": 559.8719999827446
      },
      {
          "time_stamp_utc_ms": 1736463060000,
          "time": "2025-01-09T22:53:00Z",
          "energy": 47.424000000581145,
          "capacity": 569.0880000069737
      },
      {
          "time_stamp_utc_ms": 1736463360000,
          "time": "2025-01-09T22:58:00Z",
          "energy": 46.56000000052154,
          "capacity": 558.7200000062585
      },
      {
          "time_stamp_utc_ms": 1736463660000,
          "time": "2025-01-09T23:03:00Z",
          "energy": 47.90399999823421,
          "capacity": 574.8479999788105
      },
      {
          "time_stamp_utc_ms": 1736463960000,
          "time": "2025-01-09T23:08:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1736464260000,
          "time": "2025-01-09T23:13:00Z",
          "energy": 47.23199999984354,
          "capacity": 566.7839999981225
      },
      {
          "time_stamp_utc_ms": 1736464560000,
          "time": "2025-01-09T23:18:00Z",
          "energy": 45.98400000017136,
          "capacity": 551.8080000020564
      },
      {
          "time_stamp_utc_ms": 1736464860000,
          "time": "2025-01-09T23:23:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1736465160000,
          "time": "2025-01-09T23:28:00Z",
          "energy": 45.791999999433756,
          "capacity": 549.5039999932052
      },
      {
          "time_stamp_utc_ms": 1736465460000,
          "time": "2025-01-09T23:33:00Z",
          "energy": 46.56000000145286,
          "capacity": 558.7200000174344
      },
      {
          "time_stamp_utc_ms": 1736465760000,
          "time": "2025-01-09T23:38:00Z",
          "energy": 45.31199999898672,
          "capacity": 543.7439999878407
      },
      {
          "time_stamp_utc_ms": 1736466060000,
          "time": "2025-01-09T23:43:00Z",
          "energy": 46.75200000125915,
          "capacity": 561.0240000151098
      },
      {
          "time_stamp_utc_ms": 1736466360000,
          "time": "2025-01-09T23:48:00Z",
          "energy": 44.735999998636544,
          "capacity": 536.8319999836385
      },
      {
          "time_stamp_utc_ms": 1736466660000,
          "time": "2025-01-09T23:53:00Z",
          "energy": 46.4640000006184,
          "capacity": 557.5680000074208
      },
      {
          "time_stamp_utc_ms": 1736466960000,
          "time": "2025-01-09T23:58:00Z",
          "energy": 46.080000000074506,
          "capacity": 552.9600000008941
      },
      {
          "time_stamp_utc_ms": 1736467260000,
          "time": "2025-01-10T00:03:00Z",
          "energy": 46.463999999687076,
          "capacity": 557.5679999962449
      },
      {
          "time_stamp_utc_ms": 1736467560000,
          "time": "2025-01-10T00:08:00Z",
          "energy": 46.847999999299645,
          "capacity": 562.1759999915957
      },
      {
          "time_stamp_utc_ms": 1736467860000,
          "time": "2025-01-10T00:13:00Z",
          "energy": 46.17600000090897,
          "capacity": 554.1120000109076
      },
      {
          "time_stamp_utc_ms": 1736468160000,
          "time": "2025-01-10T00:18:00Z",
          "energy": 46.94400000013411,
          "capacity": 563.3280000016093
      },
      {
          "time_stamp_utc_ms": 1736468460000,
          "time": "2025-01-10T00:23:00Z",
          "energy": 46.847999999299645,
          "capacity": 562.1759999915957
      },
      {
          "time_stamp_utc_ms": 1736468760000,
          "time": "2025-01-10T00:28:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1736469060000,
          "time": "2025-01-10T00:33:00Z",
          "energy": 47.52000000048429,
          "capacity": 570.2400000058115
      },
      {
          "time_stamp_utc_ms": 1736469360000,
          "time": "2025-01-10T00:38:00Z",
          "energy": 46.27199999988079,
          "capacity": 555.2639999985695
      },
      {
          "time_stamp_utc_ms": 1736469660000,
          "time": "2025-01-10T00:43:00Z",
          "energy": 45.7920000012964,
          "capacity": 549.5040000155568
      },
      {
          "time_stamp_utc_ms": 1736469960000,
          "time": "2025-01-10T00:48:00Z",
          "energy": 46.36799999885261,
          "capacity": 556.4159999862314
      },
      {
          "time_stamp_utc_ms": 1736470260000,
          "time": "2025-01-10T00:53:00Z",
          "energy": 45.50400000065565,
          "capacity": 546.0480000078678
      },
      {
          "time_stamp_utc_ms": 1736470560000,
          "time": "2025-01-10T00:58:00Z",
          "energy": 46.7519999993965,
          "capacity": 561.023999992758
      },
      {
          "time_stamp_utc_ms": 1736470860000,
          "time": "2025-01-10T01:03:00Z",
          "energy": 46.463999999687076,
          "capacity": 557.5679999962449
      },
      {
          "time_stamp_utc_ms": 1736471160000,
          "time": "2025-01-10T01:08:00Z",
          "energy": 46.56000000052154,
          "capacity": 558.7200000062585
      },
      {
          "time_stamp_utc_ms": 1736471460000,
          "time": "2025-01-10T01:13:00Z",
          "energy": 47.519999999552965,
          "capacity": 570.2399999946356
      },
      {
          "time_stamp_utc_ms": 1736471760000,
          "time": "2025-01-10T01:18:00Z",
          "energy": 46.17599999997765,
          "capacity": 554.1119999997318
      },
      {
          "time_stamp_utc_ms": 1736472060000,
          "time": "2025-01-10T01:23:00Z",
          "energy": 46.84800000023097,
          "capacity": 562.1760000027716
      },
      {
          "time_stamp_utc_ms": 1736472360000,
          "time": "2025-01-10T01:28:00Z",
          "energy": 47.424000000581145,
          "capacity": 569.0880000069737
      },
      {
          "time_stamp_utc_ms": 1736472660000,
          "time": "2025-01-10T01:33:00Z",
          "energy": 47.13599999900907,
          "capacity": 565.6319999881089
      },
      {
          "time_stamp_utc_ms": 1736472960000,
          "time": "2025-01-10T01:38:00Z",
          "energy": 47.040000000968575,
          "capacity": 564.4800000116229
      },
      {
          "time_stamp_utc_ms": 1736473260000,
          "time": "2025-01-10T01:43:00Z",
          "energy": 48.191999999806285,
          "capacity": 578.3039999976755
      },
      {
          "time_stamp_utc_ms": 1736473560000,
          "time": "2025-01-10T01:48:00Z",
          "energy": 47.808000000193715,
          "capacity": 573.6960000023246
      },
      {
          "time_stamp_utc_ms": 1736473860000,
          "time": "2025-01-10T01:53:00Z",
          "energy": 47.90400000009686,
          "capacity": 574.8480000011623
      },
      {
          "time_stamp_utc_ms": 1736474160000,
          "time": "2025-01-10T01:58:00Z",
          "energy": 41.95199999958277,
          "capacity": 503.4239999949932
      },
      {
          "time_stamp_utc_ms": 1736474460000,
          "time": "2025-01-10T02:03:00Z",
          "energy": 41.5679999999702,
          "capacity": 498.8159999996424
      },
      {
          "time_stamp_utc_ms": 1736474760000,
          "time": "2025-01-10T02:08:00Z",
          "energy": 41.37600000016391,
          "capacity": 496.51200000196695
      },
      {
          "time_stamp_utc_ms": 1736475060000,
          "time": "2025-01-10T02:13:00Z",
          "energy": 41.08799999952316,
          "capacity": 493.055999994278
      },
      {
          "time_stamp_utc_ms": 1736475360000,
          "time": "2025-01-10T02:18:00Z",
          "energy": 39.55199999921024,
          "capacity": 474.6239999905229
      },
      {
          "time_stamp_utc_ms": 1736475660000,
          "time": "2025-01-10T02:23:00Z",
          "energy": 41.47200000099838,
          "capacity": 497.6640000119806
      },
      {
          "time_stamp_utc_ms": 1736475960000,
          "time": "2025-01-10T02:28:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1736476260000,
          "time": "2025-01-10T02:33:00Z",
          "energy": 40.99199999962002,
          "capacity": 491.9039999954403
      },
      {
          "time_stamp_utc_ms": 1736476560000,
          "time": "2025-01-10T02:38:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1736476860000,
          "time": "2025-01-10T02:43:00Z",
          "energy": 40.416000000201166,
          "capacity": 484.99200000241404
      },
      {
          "time_stamp_utc_ms": 1736477160000,
          "time": "2025-01-10T02:48:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1736477460000,
          "time": "2025-01-10T02:53:00Z",
          "energy": 39.64799999911338,
          "capacity": 475.7759999893606
      },
      {
          "time_stamp_utc_ms": 1736477760000,
          "time": "2025-01-10T02:58:00Z",
          "energy": 39.83999999985099,
          "capacity": 478.07999999821186
      },
      {
          "time_stamp_utc_ms": 1736478060000,
          "time": "2025-01-10T03:03:00Z",
          "energy": 38.78400000091642,
          "capacity": 465.40800001099706
      },
      {
          "time_stamp_utc_ms": 1736478360000,
          "time": "2025-01-10T03:08:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1736478660000,
          "time": "2025-01-10T03:13:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1736478960000,
          "time": "2025-01-10T03:18:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1736479260000,
          "time": "2025-01-10T03:23:00Z",
          "energy": 37.72799999918789,
          "capacity": 452.73599999025464
      },
      {
          "time_stamp_utc_ms": 1736479560000,
          "time": "2025-01-10T03:28:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1736479860000,
          "time": "2025-01-10T03:33:00Z",
          "energy": 38.40000000037253,
          "capacity": 460.8000000044704
      },
      {
          "time_stamp_utc_ms": 1736480160000,
          "time": "2025-01-10T03:38:00Z",
          "energy": 39.93599999975413,
          "capacity": 479.23199999704957
      },
      {
          "time_stamp_utc_ms": 1736480460000,
          "time": "2025-01-10T03:43:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1736480760000,
          "time": "2025-01-10T03:48:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1736481060000,
          "time": "2025-01-10T03:53:00Z",
          "energy": 38.592000000178814,
          "capacity": 463.1040000021458
      },
      {
          "time_stamp_utc_ms": 1736481360000,
          "time": "2025-01-10T03:58:00Z",
          "energy": 38.39999999944121,
          "capacity": 460.79999999329453
      },
      {
          "time_stamp_utc_ms": 1736481660000,
          "time": "2025-01-10T04:03:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1736481960000,
          "time": "2025-01-10T04:08:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1736482260000,
          "time": "2025-01-10T04:13:00Z",
          "energy": 38.303999999538064,
          "capacity": 459.6479999944568
      },
      {
          "time_stamp_utc_ms": 1736482560000,
          "time": "2025-01-10T04:18:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1736482860000,
          "time": "2025-01-10T04:23:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1736483160000,
          "time": "2025-01-10T04:28:00Z",
          "energy": 38.20799999963492,
          "capacity": 458.4959999956191
      },
      {
          "time_stamp_utc_ms": 1736483460000,
          "time": "2025-01-10T04:33:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1736483760000,
          "time": "2025-01-10T04:38:00Z",
          "energy": 38.30400000046939,
          "capacity": 459.6480000056327
      },
      {
          "time_stamp_utc_ms": 1736484060000,
          "time": "2025-01-10T04:43:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1736484360000,
          "time": "2025-01-10T04:48:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1736484660000,
          "time": "2025-01-10T04:53:00Z",
          "energy": 38.01600000075996,
          "capacity": 456.19200000911957
      },
      {
          "time_stamp_utc_ms": 1736484960000,
          "time": "2025-01-10T04:58:00Z",
          "energy": 39.45600000023842,
          "capacity": 473.472000002861
      },
      {
          "time_stamp_utc_ms": 1736485260000,
          "time": "2025-01-10T05:03:00Z",
          "energy": 38.49599999934435,
          "capacity": 461.95199999213224
      },
      {
          "time_stamp_utc_ms": 1736485560000,
          "time": "2025-01-10T05:08:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1736485860000,
          "time": "2025-01-10T05:13:00Z",
          "energy": 38.87999999988824,
          "capacity": 466.55999999865895
      },
      {
          "time_stamp_utc_ms": 1736486160000,
          "time": "2025-01-10T05:18:00Z",
          "energy": 38.1120000006631,
          "capacity": 457.3440000079573
      },
      {
          "time_stamp_utc_ms": 1736486460000,
          "time": "2025-01-10T05:23:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1736486760000,
          "time": "2025-01-10T05:28:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1736487060000,
          "time": "2025-01-10T05:33:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1736487360000,
          "time": "2025-01-10T05:38:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1736487660000,
          "time": "2025-01-10T05:43:00Z",
          "energy": 40.22400000039488,
          "capacity": 482.6880000047386
      },
      {
          "time_stamp_utc_ms": 1736487960000,
          "time": "2025-01-10T05:48:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1736488260000,
          "time": "2025-01-10T05:53:00Z",
          "energy": 40.80000000074506,
          "capacity": 489.6000000089407
      },
      {
          "time_stamp_utc_ms": 1736488560000,
          "time": "2025-01-10T05:58:00Z",
          "energy": 39.167999998666346,
          "capacity": 470.0159999839962
      },
      {
          "time_stamp_utc_ms": 1736488860000,
          "time": "2025-01-10T06:03:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1736489160000,
          "time": "2025-01-10T06:08:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1736489460000,
          "time": "2025-01-10T06:13:00Z",
          "energy": 40.511999999172986,
          "capacity": 486.1439999900758
      },
      {
          "time_stamp_utc_ms": 1736489760000,
          "time": "2025-01-10T06:18:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1736490060000,
          "time": "2025-01-10T06:23:00Z",
          "energy": 38.49600000027567,
          "capacity": 461.9520000033081
      },
      {
          "time_stamp_utc_ms": 1736490360000,
          "time": "2025-01-10T06:28:00Z",
          "energy": 39.264000001363456,
          "capacity": 471.1680000163615
      },
      {
          "time_stamp_utc_ms": 1736490660000,
          "time": "2025-01-10T06:33:00Z",
          "energy": 40.511999999172986,
          "capacity": 486.1439999900758
      },
      {
          "time_stamp_utc_ms": 1736490960000,
          "time": "2025-01-10T06:38:00Z",
          "energy": 39.839999998919666,
          "capacity": 478.07999998703605
      },
      {
          "time_stamp_utc_ms": 1736491260000,
          "time": "2025-01-10T06:43:00Z",
          "energy": 40.41600000113249,
          "capacity": 484.99200001358986
      },
      {
          "time_stamp_utc_ms": 1736491560000,
          "time": "2025-01-10T06:48:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1736491860000,
          "time": "2025-01-10T06:53:00Z",
          "energy": 39.64799999818206,
          "capacity": 475.77599997818476
      },
      {
          "time_stamp_utc_ms": 1736492160000,
          "time": "2025-01-10T06:58:00Z",
          "energy": 39.74400000087917,
          "capacity": 476.92800001055
      },
      {
          "time_stamp_utc_ms": 1736492460000,
          "time": "2025-01-10T07:03:00Z",
          "energy": 38.880000000819564,
          "capacity": 466.56000000983477
      },
      {
          "time_stamp_utc_ms": 1736492760000,
          "time": "2025-01-10T07:08:00Z",
          "energy": 39.071999998763204,
          "capacity": 468.8639999851585
      },
      {
          "time_stamp_utc_ms": 1736493060000,
          "time": "2025-01-10T07:13:00Z",
          "energy": 39.264000000432134,
          "capacity": 471.1680000051856
      },
      {
          "time_stamp_utc_ms": 1736493360000,
          "time": "2025-01-10T07:18:00Z",
          "energy": 38.7839999999851,
          "capacity": 465.40799999982124
      },
      {
          "time_stamp_utc_ms": 1736493660000,
          "time": "2025-01-10T07:23:00Z",
          "energy": 38.880000000819564,
          "capacity": 466.56000000983477
      },
      {
          "time_stamp_utc_ms": 1736493960000,
          "time": "2025-01-10T07:28:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1736494260000,
          "time": "2025-01-10T07:33:00Z",
          "energy": 39.071999999694526,
          "capacity": 468.86399999633437
      },
      {
          "time_stamp_utc_ms": 1736494560000,
          "time": "2025-01-10T07:38:00Z",
          "energy": 39.6480000000447,
          "capacity": 475.77600000053644
      },
      {
          "time_stamp_utc_ms": 1736494860000,
          "time": "2025-01-10T07:43:00Z",
          "energy": 38.976000000722706,
          "capacity": 467.7120000086725
      },
      {
          "time_stamp_utc_ms": 1736495160000,
          "time": "2025-01-10T07:48:00Z",
          "energy": 39.743999999947846,
          "capacity": 476.92799999937415
      },
      {
          "time_stamp_utc_ms": 1736495460000,
          "time": "2025-01-10T07:53:00Z",
          "energy": 40.41599999926984,
          "capacity": 484.9919999912381
      },
      {
          "time_stamp_utc_ms": 1736495760000,
          "time": "2025-01-10T07:58:00Z",
          "energy": 39.360000000335276,
          "capacity": 472.3200000040233
      },
      {
          "time_stamp_utc_ms": 1736496060000,
          "time": "2025-01-10T08:03:00Z",
          "energy": 39.74399999901652,
          "capacity": 476.92799998819834
      },
      {
          "time_stamp_utc_ms": 1736496360000,
          "time": "2025-01-10T08:08:00Z",
          "energy": 39.74400000087917,
          "capacity": 476.92800001055
      },
      {
          "time_stamp_utc_ms": 1736496660000,
          "time": "2025-01-10T08:13:00Z",
          "energy": 38.688000000081956,
          "capacity": 464.25600000098353
      },
      {
          "time_stamp_utc_ms": 1736496960000,
          "time": "2025-01-10T08:18:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1736497260000,
          "time": "2025-01-10T08:23:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1736497560000,
          "time": "2025-01-10T08:28:00Z",
          "energy": 39.55200000014156,
          "capacity": 474.62400000169873
      },
      {
          "time_stamp_utc_ms": 1736497860000,
          "time": "2025-01-10T08:33:00Z",
          "energy": 39.26399999856949,
          "capacity": 471.1679999828339
      },
      {
          "time_stamp_utc_ms": 1736498160000,
          "time": "2025-01-10T08:38:00Z",
          "energy": 38.400000002235174,
          "capacity": 460.8000000268221
      },
      {
          "time_stamp_utc_ms": 1736498460000,
          "time": "2025-01-10T08:43:00Z",
          "energy": 40.51199999824166,
          "capacity": 486.14399997889996
      },
      {
          "time_stamp_utc_ms": 1736498760000,
          "time": "2025-01-10T08:48:00Z",
          "energy": 39.93600000068545,
          "capacity": 479.23200000822544
      },
      {
          "time_stamp_utc_ms": 1736499060000,
          "time": "2025-01-10T08:53:00Z",
          "energy": 40.32000000029802,
          "capacity": 483.84000000357634
      },
      {
          "time_stamp_utc_ms": 1736499360000,
          "time": "2025-01-10T08:58:00Z",
          "energy": 41.66399999987334,
          "capacity": 499.9679999984801
      },
      {
          "time_stamp_utc_ms": 1736499660000,
          "time": "2025-01-10T09:03:00Z",
          "energy": 40.60800000000745,
          "capacity": 487.29600000008946
      },
      {
          "time_stamp_utc_ms": 1736499960000,
          "time": "2025-01-10T09:08:00Z",
          "energy": 40.22399999946356,
          "capacity": 482.6879999935627
      },
      {
          "time_stamp_utc_ms": 1736500260000,
          "time": "2025-01-10T09:13:00Z",
          "energy": 40.41600000113249,
          "capacity": 484.99200001358986
      },
      {
          "time_stamp_utc_ms": 1736500560000,
          "time": "2025-01-10T09:18:00Z",
          "energy": 38.687999999150634,
          "capacity": 464.25599998980766
      },
      {
          "time_stamp_utc_ms": 1736500860000,
          "time": "2025-01-10T09:23:00Z",
          "energy": 40.03199999965727,
          "capacity": 480.3839999958873
      },
      {
          "time_stamp_utc_ms": 1736501160000,
          "time": "2025-01-10T09:28:00Z",
          "energy": 38.30400000140071,
          "capacity": 459.6480000168085
      },
      {
          "time_stamp_utc_ms": 1736501460000,
          "time": "2025-01-10T09:33:00Z",
          "energy": 38.975999999791384,
          "capacity": 467.71199999749666
      },
      {
          "time_stamp_utc_ms": 1736501760000,
          "time": "2025-01-10T09:38:00Z",
          "energy": 38.87999999895692,
          "capacity": 466.5599999874831
      },
      {
          "time_stamp_utc_ms": 1736502060000,
          "time": "2025-01-10T09:43:00Z",
          "energy": 39.35999999940395,
          "capacity": 472.3199999928475
      },
      {
          "time_stamp_utc_ms": 1736502360000,
          "time": "2025-01-10T09:48:00Z",
          "energy": 40.89600000157952,
          "capacity": 490.75200001895433
      },
      {
          "time_stamp_utc_ms": 1736502660000,
          "time": "2025-01-10T09:53:00Z",
          "energy": 42.239999999292195,
          "capacity": 506.87999999150634
      },
      {
          "time_stamp_utc_ms": 1736502960000,
          "time": "2025-01-10T09:58:00Z",
          "energy": 46.65599999949336,
          "capacity": 559.8719999939203
      },
      {
          "time_stamp_utc_ms": 1736503260000,
          "time": "2025-01-10T10:03:00Z",
          "energy": 49.2480000006035,
          "capacity": 590.9760000072421
      },
      {
          "time_stamp_utc_ms": 1736503560000,
          "time": "2025-01-10T10:08:00Z",
          "energy": 48.768000001087785,
          "capacity": 585.2160000130535
      },
      {
          "time_stamp_utc_ms": 1736503860000,
          "time": "2025-01-10T10:13:00Z",
          "energy": 48.76799999829382,
          "capacity": 585.2159999795258
      },
      {
          "time_stamp_utc_ms": 1736504160000,
          "time": "2025-01-10T10:18:00Z",
          "energy": 48.57600000035018,
          "capacity": 582.9120000042021
      },
      {
          "time_stamp_utc_ms": 1736504460000,
          "time": "2025-01-10T10:23:00Z",
          "energy": 49.247999999672174,
          "capacity": 590.9759999960661
      },
      {
          "time_stamp_utc_ms": 1736504760000,
          "time": "2025-01-10T10:28:00Z",
          "energy": 49.824000000953674,
          "capacity": 597.8880000114441
      },
      {
          "time_stamp_utc_ms": 1736505060000,
          "time": "2025-01-10T10:33:00Z",
          "energy": 50.303999999538064,
          "capacity": 603.6479999944569
      },
      {
          "time_stamp_utc_ms": 1736505360000,
          "time": "2025-01-10T10:38:00Z",
          "energy": 50.688000000081956,
          "capacity": 608.2560000009835
      },
      {
          "time_stamp_utc_ms": 1736505660000,
          "time": "2025-01-10T10:43:00Z",
          "energy": 51.93599999975413,
          "capacity": 623.2319999970496
      },
      {
          "time_stamp_utc_ms": 1736505960000,
          "time": "2025-01-10T10:48:00Z",
          "energy": 51.16799999959767,
          "capacity": 614.015999995172
      },
      {
          "time_stamp_utc_ms": 1736506260000,
          "time": "2025-01-10T10:53:00Z",
          "energy": 50.208000000566244,
          "capacity": 602.4960000067949
      },
      {
          "time_stamp_utc_ms": 1736506560000,
          "time": "2025-01-10T10:58:00Z",
          "energy": 50.87999999988824,
          "capacity": 610.5599999986589
      },
      {
          "time_stamp_utc_ms": 1736506860000,
          "time": "2025-01-10T11:03:00Z",
          "energy": 50.01599999982864,
          "capacity": 600.1919999979438
      },
      {
          "time_stamp_utc_ms": 1736507160000,
          "time": "2025-01-10T11:08:00Z",
          "energy": 52.51200000010431,
          "capacity": 630.1440000012518
      },
      {
          "time_stamp_utc_ms": 1736507460000,
          "time": "2025-01-10T11:13:00Z",
          "energy": 51.6480000000447,
          "capacity": 619.7760000005364
      },
      {
          "time_stamp_utc_ms": 1736507760000,
          "time": "2025-01-10T11:18:00Z",
          "energy": 51.45600000023842,
          "capacity": 617.472000002861
      },
      {
          "time_stamp_utc_ms": 1736508060000,
          "time": "2025-01-10T11:23:00Z",
          "energy": 51.264000000432134,
          "capacity": 615.1680000051856
      },
      {
          "time_stamp_utc_ms": 1736508360000,
          "time": "2025-01-10T11:28:00Z",
          "energy": 51.167999998666346,
          "capacity": 614.0159999839963
      },
      {
          "time_stamp_utc_ms": 1736508660000,
          "time": "2025-01-10T11:33:00Z",
          "energy": 52.032000000588596,
          "capacity": 624.3840000070633
      },
      {
          "time_stamp_utc_ms": 1736508960000,
          "time": "2025-01-10T11:38:00Z",
          "energy": 53.37600000016391,
          "capacity": 640.512000001967
      },
      {
          "time_stamp_utc_ms": 1736509260000,
          "time": "2025-01-10T11:43:00Z",
          "energy": 53.56800000090152,
          "capacity": 642.8160000108184
      },
      {
          "time_stamp_utc_ms": 1736509560000,
          "time": "2025-01-10T11:48:00Z",
          "energy": 53.75999999884516,
          "capacity": 645.119999986142
      },
      {
          "time_stamp_utc_ms": 1736509860000,
          "time": "2025-01-10T11:53:00Z",
          "energy": 54.24000000022352,
          "capacity": 650.8800000026823
      },
      {
          "time_stamp_utc_ms": 1736510160000,
          "time": "2025-01-10T11:58:00Z",
          "energy": 53.27999999932945,
          "capacity": 639.3599999919535
      },
      {
          "time_stamp_utc_ms": 1736510460000,
          "time": "2025-01-10T12:03:00Z",
          "energy": 53.088000000454485,
          "capacity": 637.0560000054538
      },
      {
          "time_stamp_utc_ms": 1736510760000,
          "time": "2025-01-10T12:08:00Z",
          "energy": 51.83999999985099,
          "capacity": 622.0799999982119
      },
      {
          "time_stamp_utc_ms": 1736511060000,
          "time": "2025-01-10T12:13:00Z",
          "energy": 53.472000000067055,
          "capacity": 641.6640000008047
      },
      {
          "time_stamp_utc_ms": 1736511360000,
          "time": "2025-01-10T12:18:00Z",
          "energy": 53.95199999958277,
          "capacity": 647.4239999949932
      },
      {
          "time_stamp_utc_ms": 1736511660000,
          "time": "2025-01-10T12:23:00Z",
          "energy": 53.664000001735985,
          "capacity": 643.9680000208318
      },
      {
          "time_stamp_utc_ms": 1736511960000,
          "time": "2025-01-10T12:28:00Z",
          "energy": 55.19999999925494,
          "capacity": 662.3999999910594
      },
      {
          "time_stamp_utc_ms": 1736512260000,
          "time": "2025-01-10T12:33:00Z",
          "energy": 54.144000000320375,
          "capacity": 649.7280000038446
      },
      {
          "time_stamp_utc_ms": 1736512560000,
          "time": "2025-01-10T12:38:00Z",
          "energy": 54.04799999948591,
          "capacity": 648.5759999938309
      },
      {
          "time_stamp_utc_ms": 1736512860000,
          "time": "2025-01-10T12:43:00Z",
          "energy": 53.472000000067055,
          "capacity": 641.6640000008047
      },
      {
          "time_stamp_utc_ms": 1736513160000,
          "time": "2025-01-10T12:48:00Z",
          "energy": 53.088000000454485,
          "capacity": 637.0560000054538
      },
      {
          "time_stamp_utc_ms": 1736513460000,
          "time": "2025-01-10T12:53:00Z",
          "energy": 53.183999999426305,
          "capacity": 638.2079999931158
      },
      {
          "time_stamp_utc_ms": 1736513760000,
          "time": "2025-01-10T12:58:00Z",
          "energy": 53.56800000090152,
          "capacity": 642.8160000108184
      },
      {
          "time_stamp_utc_ms": 1736514060000,
          "time": "2025-01-10T13:03:00Z",
          "energy": 53.8559999987483,
          "capacity": 646.2719999849797
      },
      {
          "time_stamp_utc_ms": 1736514360000,
          "time": "2025-01-10T13:08:00Z",
          "energy": 56.25600000098348,
          "capacity": 675.0720000118018
      },
      {
          "time_stamp_utc_ms": 1736514660000,
          "time": "2025-01-10T13:13:00Z",
          "energy": 58.07999999914318,
          "capacity": 696.9599999897183
      },
      {
          "time_stamp_utc_ms": 1736514960000,
          "time": "2025-01-10T13:18:00Z",
          "energy": 56.06400000024587,
          "capacity": 672.7680000029504
      },
      {
          "time_stamp_utc_ms": 1736515260000,
          "time": "2025-01-10T13:23:00Z",
          "energy": 53.75999999977648,
          "capacity": 645.1199999973178
      },
      {
          "time_stamp_utc_ms": 1736515560000,
          "time": "2025-01-10T13:28:00Z",
          "energy": 53.56800000090152,
          "capacity": 642.8160000108184
      },
      {
          "time_stamp_utc_ms": 1736515860000,
          "time": "2025-01-10T13:33:00Z",
          "energy": 53.08799999952316,
          "capacity": 637.0559999942781
      },
      {
          "time_stamp_utc_ms": 1736516160000,
          "time": "2025-01-10T13:38:00Z",
          "energy": 52.80000000074506,
          "capacity": 633.6000000089407
      },
      {
          "time_stamp_utc_ms": 1736516460000,
          "time": "2025-01-10T13:43:00Z",
          "energy": 51.74399999901652,
          "capacity": 620.9279999881983
      },
      {
          "time_stamp_utc_ms": 1736516760000,
          "time": "2025-01-10T13:48:00Z",
          "energy": 54.04799999948591,
          "capacity": 648.5759999938309
      },
      {
          "time_stamp_utc_ms": 1736517060000,
          "time": "2025-01-10T13:53:00Z",
          "energy": 53.85600000061095,
          "capacity": 646.2720000073315
      },
      {
          "time_stamp_utc_ms": 1736517360000,
          "time": "2025-01-10T13:58:00Z",
          "energy": 53.66399999987334,
          "capacity": 643.9679999984801
      },
      {
          "time_stamp_utc_ms": 1736517660000,
          "time": "2025-01-10T14:03:00Z",
          "energy": 52.8960000006482,
          "capacity": 634.7520000077784
      },
      {
          "time_stamp_utc_ms": 1736517960000,
          "time": "2025-01-10T14:08:00Z",
          "energy": 52.22399999946356,
          "capacity": 626.6879999935627
      },
      {
          "time_stamp_utc_ms": 1736518260000,
          "time": "2025-01-10T14:13:00Z",
          "energy": 50.87999999988824,
          "capacity": 610.5599999986589
      },
      {
          "time_stamp_utc_ms": 1736518560000,
          "time": "2025-01-10T14:18:00Z",
          "energy": 51.35999999940395,
          "capacity": 616.3199999928474
      },
      {
          "time_stamp_utc_ms": 1736518860000,
          "time": "2025-01-10T14:23:00Z",
          "energy": 49.53600000124425,
          "capacity": 594.432000014931
      },
      {
          "time_stamp_utc_ms": 1736519160000,
          "time": "2025-01-10T14:28:00Z",
          "energy": 51.360000000335276,
          "capacity": 616.3200000040233
      },
      {
          "time_stamp_utc_ms": 1736519460000,
          "time": "2025-01-10T14:33:00Z",
          "energy": 50.11199999973178,
          "capacity": 601.3439999967815
      },
      {
          "time_stamp_utc_ms": 1736519760000,
          "time": "2025-01-10T14:38:00Z",
          "energy": 51.16799999959767,
          "capacity": 614.015999995172
      },
      {
          "time_stamp_utc_ms": 1736520060000,
          "time": "2025-01-10T14:43:00Z",
          "energy": 49.82399999909103,
          "capacity": 597.8879999890924
      },
      {
          "time_stamp_utc_ms": 1736520360000,
          "time": "2025-01-10T14:48:00Z",
          "energy": 49.05600000079721,
          "capacity": 588.6720000095667
      },
      {
          "time_stamp_utc_ms": 1736520660000,
          "time": "2025-01-10T14:53:00Z",
          "energy": 50.01599999982864,
          "capacity": 600.1919999979438
      },
      {
          "time_stamp_utc_ms": 1736520960000,
          "time": "2025-01-10T14:58:00Z",
          "energy": 49.72800000011921,
          "capacity": 596.7360000014306
      },
      {
          "time_stamp_utc_ms": 1736521260000,
          "time": "2025-01-10T15:03:00Z",
          "energy": 48.480000000447035,
          "capacity": 581.7600000053644
      },
      {
          "time_stamp_utc_ms": 1736521560000,
          "time": "2025-01-10T15:08:00Z",
          "energy": 48.95999999996275,
          "capacity": 587.519999999553
      },
      {
          "time_stamp_utc_ms": 1736521860000,
          "time": "2025-01-10T15:13:00Z",
          "energy": 47.519999999552965,
          "capacity": 570.2399999946356
      },
      {
          "time_stamp_utc_ms": 1736522160000,
          "time": "2025-01-10T15:18:00Z",
          "energy": 48.191999999806285,
          "capacity": 578.3039999976755
      },
      {
          "time_stamp_utc_ms": 1736522460000,
          "time": "2025-01-10T15:23:00Z",
          "energy": 48.38399999961257,
          "capacity": 580.607999995351
      },
      {
          "time_stamp_utc_ms": 1736522760000,
          "time": "2025-01-10T15:28:00Z",
          "energy": 48.57600000035018,
          "capacity": 582.9120000042021
      },
      {
          "time_stamp_utc_ms": 1736523060000,
          "time": "2025-01-10T15:33:00Z",
          "energy": 48.096000000834465,
          "capacity": 577.1520000100136
      },
      {
          "time_stamp_utc_ms": 1736523360000,
          "time": "2025-01-10T15:38:00Z",
          "energy": 45.12000000011176,
          "capacity": 541.4400000013412
      },
      {
          "time_stamp_utc_ms": 1736523660000,
          "time": "2025-01-10T15:43:00Z",
          "energy": 46.46399999875575,
          "capacity": 557.5679999850692
      },
      {
          "time_stamp_utc_ms": 1736523960000,
          "time": "2025-01-10T15:48:00Z",
          "energy": 45.312000000849366,
          "capacity": 543.7440000101924
      },
      {
          "time_stamp_utc_ms": 1736524260000,
          "time": "2025-01-10T15:53:00Z",
          "energy": 45.59999999962747,
          "capacity": 547.1999999955298
      },
      {
          "time_stamp_utc_ms": 1736524560000,
          "time": "2025-01-10T15:58:00Z",
          "energy": 44.928000000305474,
          "capacity": 539.1360000036658
      },
      {
          "time_stamp_utc_ms": 1736524860000,
          "time": "2025-01-10T16:03:00Z",
          "energy": 45.60000000055879,
          "capacity": 547.2000000067055
      },
      {
          "time_stamp_utc_ms": 1736525160000,
          "time": "2025-01-10T16:08:00Z",
          "energy": 43.77599999960512,
          "capacity": 525.3119999952614
      },
      {
          "time_stamp_utc_ms": 1736525460000,
          "time": "2025-01-10T16:13:00Z",
          "energy": 44.15999999921769,
          "capacity": 529.9199999906123
      },
      {
          "time_stamp_utc_ms": 1736525760000,
          "time": "2025-01-10T16:18:00Z",
          "energy": 43.00800000037998,
          "capacity": 516.0960000045598
      },
      {
          "time_stamp_utc_ms": 1736526060000,
          "time": "2025-01-10T16:23:00Z",
          "energy": 43.67999999970198,
          "capacity": 524.1599999964237
      }
  ],
  "totalRecords": 8747,
  "maxDemand": 696.9599999897183,
  "minDemand": 270.7200000062585,
  "avgDemand": 476.5235037654583,
  "totalEnergy": 360732.71999999974
}